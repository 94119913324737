import React, { useEffect, useRef, useState } from "react";
import TechilaLogo from "../../../src/assets/images/companyBenchImage/Techila-Logo-1.png";

import {
  Col,
  Row,
  Container,
  Button,
  Card,
  CardBody,
  Table,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Badge,
} from "reactstrap";

import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { log_debug } from "../../apiHelpers/logger";
import { formatDate } from "../../helpers/date";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDispatch } from "react-redux";
import { postSendInvoiceEmail } from "../../Partner/PartnerSlice";
import { toast } from "react-toastify";
import Preloader from "../../components/Common/Preloader";

const InvoiceDetailsCanvas = ({ isOpen, toggle, onClose, data }) => {
  const [headerTab, setHeaderTab] = useState("1");
  const [PartnerInvoiceRowData, setPartnerInvoiceRowData] = useState([]);
  log_debug("PartnerInvoiceRowData", PartnerInvoiceRowData);

  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  const offcanvasStyle = {
    width: "65%", // You can set a default width or use the provided width
  };
  useEffect(() => {
    setPartnerInvoiceRowData(data);
  }, [data]);

  //Print the Invoice
  const printInvoice = () => {
    const printContents = document.getElementById("invoiceToPrint").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const printCanvas = async () => {
    try {
      const canvas = await html2canvas(componentRef.current);
      if (canvas) {
        const dataUrl = canvas.toDataURL("image/jpeg");
        console.log("dataUrl", dataUrl); // Logging the data URL for debugging

        const printWindow = window.open("", "_blank", "width=800,height=600");
        if (printWindow) {
          printWindow.document.open();
          printWindow.document.write(
            "<html><head><title>Company Bench</title></head><body>",
          );
          printWindow.document.write(
            `<img src="${dataUrl}" style="width:100%;"/>`,
          );
          printWindow.document.write("</body></html>");
          printWindow.document.close();

          printWindow.onload = () => {
            printWindow.print();
            printWindow.close();
          };
        } else {
          console.error("Failed to open print window");
        }
      } else {
        console.error("Failed to generate canvas");
      }
    } catch (error) {
      console.error("Error printing canvas:", error);
    }
  };

  const convertToWords = (num) => {
    const units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const teens = [
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if (num === 0) return "zero";
    if (num < 10) return units[num];
    if (num < 20) return teens[num - 10];
    if (num < 100)
      return (
        tens[Math.floor(num / 10)] +
        (num % 10 !== 0 ? " " + units[num % 10] : "")
      );
    if (num < 1000)
      return (
        units[Math.floor(num / 100)] +
        " hundred" +
        (num % 100 !== 0 ? " " + convertToWords(num % 100) : "")
      );
    if (num < 1000000)
      return (
        convertToWords(Math.floor(num / 1000)) +
        " thousand" +
        (num % 1000 !== 0 ? " " + convertToWords(num % 1000) : "")
      );
    if (num < 1000000000)
      return (
        convertToWords(Math.floor(num / 1000000)) +
        " million" +
        (num % 1000000 !== 0 ? " " + convertToWords(num % 1000000) : "")
      );
    if (num < 1000000000000)
      return (
        convertToWords(Math.floor(num / 1000000000)) +
        " billion" +
        (num % 1000000000 !== 0 ? " " + convertToWords(num % 1000000000) : "")
      );

    return "number is too large";
  };

  function handleInvoiceCanvas() {
    toggle();
  }

  const generatePDF = async () => {
    log_debug("entered generate pdf");
    setIsLoading(true);
    try {
      // Capture UI as PDF
      const canvas = await html2canvas(componentRef.current);
      const pdfData = canvas.toDataURL("image/JPEG");

      const pdf = new jsPDF();
      // Calculate dimensions of the content
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = pdf.internal.pageSize.getHeight();
      const imgDataWidth = canvas.width;
      const imgDataHeight = canvas.height;

      // Calculate scaling factors to fit content to page
      const scaleFactor = Math.min(
        imgWidth / imgDataWidth,
        imgHeight / imgDataHeight,
      );

      // Calculate new dimensions for the content
      const scaledWidth = imgDataWidth * scaleFactor;
      const scaledHeight = imgDataHeight * scaleFactor;

      // Calculate position to center content on the page
      // const x = (imgWidth - scaledWidth) / 2; // Center horizontally
      // const y = (imgHeight - scaledHeight) / 2; // Center vertically

      const x = (imgWidth - scaledWidth) / 2; // Center horizontally
      const y = 0; // Start from top

      // Add image to PDF with adjusted size and position
      pdf.addImage(pdfData, "JPEG", x, y, scaledWidth, scaledHeight);

      //  pdf.output('dataurlnewwindow');
      //  pdf.save("download.pdf");
      const blob = pdf.output("blob");

      // Call API to send PDF
      const formData = new FormData();
      formData.append("toEmail", "pravesh_h_shukla@yopmail.com");
      formData.append("invoice", blob, "CB_Invoice.pdf");
      //log_debug("formData",formData);

      dispatch(postSendInvoiceEmail({ body: formData })).then((response) => {
        if (response.payload.success === true) {
          setIsLoading(false);
          toast.success("Email Sent successfully");
        } else {
          setIsLoading(false);
          toast.warning("Failed to send Email");
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {isLoading && <Preloader />}
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        //toggle={handleInvoiceCanvas}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom " toggle={toggle}>
          <h5 className="text-primary">
            Invoice Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Present comprehensive details
              for the specifically selected Invoice.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* <Row>
            <Col xl={12}>
              <div>
                <CardHeader className="align-items-center d-flex my-2">
                  <div className="flex-shrink-0">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classNames({
                            active: headerTab === "1",
                          })}
                          onClick={() => {
                            toggleHeader("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Invoice details
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>
                <div className="card-body">
                  <TabContent className="text-muted" activeTab={headerTab}>
                    <TabPane tabId="1">tab1</TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row> */}
          <Container fluid>
            {/* Render Breadcrumbs */}
            <div className="row col-lg-12">
              <div className="col-lg-12">
                <>
                  <div className="row">
                    <div className="col-lg-12" ref={componentRef}>
                      <Row id="invoiceToPrint">
                        <Col lg="12 " className="my-2">
                          <Card>
                            <CardBody>
                              <div className="invoice-title">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1">
                                    <div className="mb-4">
                                      <img
                                        src={TechilaLogo}
                                        alt=""
                                        height="54"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="mb-4">
                                      <h4 className="float-end font-size-16 text-end">
                                        TAX INVOICE <br />
                                        Invoice#:{" "}
                                        {PartnerInvoiceRowData &&
                                          PartnerInvoiceRowData &&
                                          PartnerInvoiceRowData?.short_guid}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <Row>
                                  <div className="col-6">
                                    <h5>
                                      Techila Global Services Ltd <br />{" "}
                                      <span className="font-size-14">
                                        (Formerly known as Techila Global
                                        Services Pvt. Ltd.)
                                      </span>{" "}
                                    </h5>
                                    <address>
                                      Unit No 402, Summer Court, Magarpatta
                                      City, Hadapsar, Pune
                                      <br />
                                      Maharashtra India 411028
                                      <br />
                                      411013
                                    </address>
                                    <p>
                                      CIN : U74999PN2016PTC164268 <br />
                                      GST : 27AAFCT7266F1Z2
                                    </p>
                                  </div>

                                  <div className="col-6">
                                    <h5 className="float-end font-size-16 text-end ">
                                      Balance Due <br />₹
                                      {PartnerInvoiceRowData &&
                                        PartnerInvoiceRowData?.amount}
                                      /-
                                    </h5>
                                  </div>
                                </Row>
                              </div>
                              <hr className="my-4" />
                              <Row>
                                <Col sm="6">
                                  <div>
                                    <h5 className="font-size-15 mb-3">
                                      Billed To:
                                    </h5>
                                    <h5>CB Accounts</h5>

                                    <address>
                                      402, SUMMER COURT
                                      <br />
                                      Magarpatta, Hadapsar
                                      <br />
                                      Pune, Maharashtra 411028
                                    </address>
                                    {/* <p>GSTIN 29AAACP7879D1Z0</p> */}
                                  </div>
                                </Col>
                                <Col sm="6" className="my-2 ">
                                  <div className="">
                                    <div className="d-flex mt-4">
                                      <h5 className="font-size-15 mx-2 ">
                                        Invoice Date:
                                      </h5>
                                      <p className="mb-1">
                                        {formatDate(
                                          PartnerInvoiceRowData &&
                                            PartnerInvoiceRowData?.invoice_date,
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-1">
                                    <h5 className="font-size-15 mx-2">
                                      Terms :
                                    </h5>
                                    <p className="mb-1">
                                      {PartnerInvoiceRowData &&
                                        PartnerInvoiceRowData?.terms}
                                    </p>
                                  </div>
                                  <div className="d-flex mt-1">
                                    <h5 className="font-size-15 mx-2">
                                      Due Date :
                                    </h5>
                                    <p className="mb-1">
                                      {formatDate(
                                        PartnerInvoiceRowData &&
                                          PartnerInvoiceRowData?.due_date,
                                      )}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <div className="py-2 mt-3">
                                <h3 className="font-size-15 fw-bold">
                                  Order summary
                                </h3>
                              </div>
                              <div className=" ">
                                <div className="table-responsive">
                                  <Table className="w-100 table table-striped">
                                    <thead className="table bg-dark">
                                      <tr>
                                        <th>Sr. No</th>
                                        <th>Candidate Name</th>
                                        <th>Designation</th>
                                        <th>Start Date </th>
                                        <th>End Date </th>
                                        <th>Rate</th>
                                        <th></th>
                                        <th
                                          className="text-end"
                                          // style={{ width: "120px" }}
                                        >
                                          Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>1</td>
                                        <td>
                                          {PartnerInvoiceRowData &&
                                            PartnerInvoiceRowData?.name}
                                        </td>
                                        <td>
                                          <p className="font-size-15 mb-1">
                                            {PartnerInvoiceRowData &&
                                              PartnerInvoiceRowData?.designation}
                                          </p>
                                          {/* <p className="font-size-13 text-muted mb-0">
                                            Recruitment Service
                                          </p> */}
                                        </td>
                                        <td>
                                          {formatDate(
                                            PartnerInvoiceRowData &&
                                              PartnerInvoiceRowData?.start_date,
                                          )}
                                        </td>
                                        <td>
                                          {formatDate(
                                            PartnerInvoiceRowData &&
                                              PartnerInvoiceRowData?.end_date,
                                          )}
                                        </td>
                                        <td>
                                          {PartnerInvoiceRowData &&
                                            PartnerInvoiceRowData?.rate}
                                        </td>
                                        <td></td>
                                        <td className="text-end">
                                          {" "}
                                          <i className=" mdi mdi-currency-inr"></i>
                                          {PartnerInvoiceRowData &&
                                            PartnerInvoiceRowData?.amount}
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <td>2</td>
                                        <td>Wasim</td>
                                        <td>
                                          <p className="font-size-15 mb-1">
                                            Recruitment Service
                                          </p>
                                          <p className="font-size-13 text-muted mb-0">
                                            Recruitment Service
                                          </p>
                                        </td>
                                        <td>16/02/2024</td>
                                        <td>16/04/2024</td>
                                        <td>399.00</td>
                                        <td></td>
                                        <td className="text-end">
                                          {" "}
                                          <i className=" mdi mdi-currency-inr"></i>
                                          399.00
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>3</td>
                                        <td>Pratik M</td>
                                        <td>
                                          <p className="font-size-15 mb-1">
                                            Recruitment Service
                                          </p>
                                          <p className="font-size-13 text-muted mb-0">
                                            Recruitment Service
                                          </p>
                                        </td>
                                        <td>16/02/2024</td>
                                        <td>16/04/2024</td>
                                        <td>499.00</td>
                                        <td></td>
                                        <td className="text-end">
                                          {" "}
                                          <i className=" mdi mdi-currency-inr"></i>
                                          499.00
                                        </td>
                                      </tr> */}
                                      <tr>
                                        <td></td>
                                        <td>
                                          <p></p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={6}></td>
                                        <td>Sub Total</td>
                                        <td className="text-end">
                                          <i className=" mdi mdi-currency-inr"></i>
                                          {PartnerInvoiceRowData &&
                                            (
                                              PartnerInvoiceRowData?.amount -
                                              (
                                                PartnerInvoiceRowData?.amount -
                                                PartnerInvoiceRowData?.amount /
                                                  (1 + 18 / 100)
                                              ).toFixed(2)
                                            ).toFixed(2)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={6}></td>
                                        <td>
                                          <p className="mb-0">IGST (18%)</p>
                                        </td>
                                        <td className=" text-end">
                                          <i className=" mdi mdi-currency-inr"></i>
                                          {PartnerInvoiceRowData &&
                                            (
                                              PartnerInvoiceRowData?.amount -
                                              PartnerInvoiceRowData?.amount /
                                                (1 + 18 / 100)
                                            ).toFixed(2)}
                                        </td>
                                      </tr>
                                      <tr className="border-0">
                                        <td className="border-0"></td>
                                        <td className="border-0"></td>
                                        <td className="border-0"></td>
                                        <td className="border-0"></td>
                                        <td className="border-0"></td>
                                        <td className="border-0"></td>
                                        <td className="border-0">
                                          <strong>Total</strong>
                                        </td>

                                        <td className="border-0 text-end">
                                          <strong className="m-0">
                                            <i className=" mdi mdi-currency-inr"></i>
                                            {PartnerInvoiceRowData &&
                                              PartnerInvoiceRowData?.amount}
                                            /-
                                          </strong>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          {/* <strong>Total in words:</strong> */}
                                        </td>
                                        <td colSpan={3} className="text-end">
                                          <strong>
                                            {" "}
                                            {convertToWords(
                                              PartnerInvoiceRowData &&
                                                PartnerInvoiceRowData?.amount,
                                            )}{" "}
                                            rupees
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>

                    <div className="d-print-none mt-4">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printCanvas}
                          title="Print Invoice"
                          className="btn btn-success me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        <Button
                          //to="#"
                          color="primary"
                          onClick={generatePDF}
                          className="btn btn-primary w-md "
                          title="Send Invoice to Email"
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </Container>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default InvoiceDetailsCanvas;

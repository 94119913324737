// CustomOffcanvas.js
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Offcanvas,
  TabContent,
  OffcanvasHeader,
  OffcanvasBody,
  TabPane,
  UncontrolledDropdown,
  Button,
} from "reactstrap";

import AssessmentInviteForm from "../form/AssessmentInviteForm";

// Custom Components

export function AssessmentInvitationCanvas({ isOpen, toggle, onClose }) {
  const offcanvasStyle = {
    width: `50%`,
    // zoom: '80%',
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          {/* <p className="text-light">Candidate Details</p> */}
          <h5 className="text-primary">
            Assessment Invitation &nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Apply for a new assessment
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* <span className="text-success">{content}</span> */}
          <div className="row pt-2">
            <div className="row">
              <div className="col-md-12 px-5">
                <AssessmentInviteForm userType={"individual"} />
              </div>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default AssessmentInvitationCanvas;

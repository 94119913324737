// import React, { useEffect, useState } from "react";
// import {
//   Col,
//   Row,
//   Button,
//   Badge,
//   Card,
//   CardBody,
//   CardTitle,
//   Offcanvas,
//   OffcanvasHeader,
//   OffcanvasBody,
//   UncontrolledDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
//   CardHeader,
// } from "reactstrap";
// import { FaRegQuestionCircle } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import dayjs from "dayjs";
// import {
//   IndividualDetail,
//   CandidatureHistory,
//   JobDetail,
// } from "../PartnerSlice";
// import { dateyyyymmdd_tohtml } from "../../helpers/date";
// import TIQcard from "../../customComponents/offcanvas/offcanvasComponents/TIQcard";
// import joblogo from "../../assets/images/companyBenchImage/jobgray1.jpg";
// import TimelineComponent from "../../customComponents/timelineComponents/TimeLineComponent";
// import CanvasPartnerJobDetails from "../../customComponents/offcanvas/CanvasPartnerJobDetails";

// function OfferDetailsCanvas({ isOpen, toggle, onClose, data, content }) {
//   const dispatch = useDispatch();

//   const [offerData, setOfferData] = useState(null);
//   const [jobGuid, setJobGuid] = useState({});

//   const [individualDetail, setIndividualDetail] = useState({});
//   const [jobDetail, setJobDetail] = useState({});
//   const [candidatureHistory, setCandidatureHistory] = useState([]);

//   const [isJobCanvas, setIsJobCanvas] = useState(false);

//   const partnerStore = useSelector((state) => state.partnerSlice);

//   const offcanvasStyle = {
//     width: "50%", // You can set a default width or use the provided width
//   };

//   useEffect(() => {
//     let off_data = {};
//     if (data.individual_guid) {
//       (off_data.guid = data.offer_id),
//         (off_data.indv_guid = data.individual_guid),
//         (off_data.job_guid = data.jobdescription_guid),
//         (off_data.indv_job_guid = data.individualjobmapping_guid),
//         (off_data.status = data.status);
//     } else if (data.guid) {
//       (off_data.guid = data.offer_guid),
//         (off_data.indv_guid = data.guid),
//         (off_data.job_guid = data.jobdescription_guid),
//         (off_data.indv_job_guid = data.indv_job_map_guid),
//         (off_data.status = data.offer_status);
//     }
//     setOfferData(off_data);
//   }, [data]);

//   useEffect(() => {
//     // let indv_guid = ''
//     // if(offerData?.individual_guid){
//     //   indv_guid = offerData.individual_guid;
//     // }else if(offerData?.guid){
//     //   indv_guid = offerData.guid
//     // }
//     dispatch(IndividualDetail(offerData?.indv_guid));
//     dispatch(CandidatureHistory(offerData?.indv_job_guid));
//     dispatch(JobDetail(offerData?.job_guid));
//   }, [offerData]);

//   useEffect(() => {
//     if (partnerStore.status === "idle") {
//       if (
//         partnerStore.data?.IndividualDetail?.success &&
//         partnerStore.data.IndividualDetail.data?.individual
//       ) {
//         setIndividualDetail(
//           partnerStore.data.IndividualDetail.data?.individual,
//         );
//       }
//       if (
//         partnerStore.data?.CandidatureHistory?.success &&
//         partnerStore.data.CandidatureHistory.data?.individual_history
//       ) {
//         if (candidatureHistory.length == 0) {
//           setCandidatureHistory(
//             partnerStore.data.CandidatureHistory.data?.individual_history,
//           );
//         }
//       }
//       if (
//         partnerStore.data?.JobDetail?.success &&
//         partnerStore.data.JobDetail.data?.jobdescription
//       ) {
//         setJobDetail(partnerStore.data.JobDetail.data?.jobdescription);
//       }
//     }
//   }, [partnerStore]);

//   const getInitials = (fullName) => {
//     return fullName
//       ?.split(" ")
//       .map((name) => name[0])
//       .join("")
//       .toUpperCase();
//   };

//   const handleJob = (e) => {
//     e.preventDefault();
//     // setJobGuid(guid);
//     setIsJobCanvas(true);
//   };

//   const closeJobCanvas = () => {
//     setIsJobCanvas(false);
//   };

//   return (
//     <>
//       <Offcanvas
//         direction="end"
//         isOpen={isOpen}
//         className="custom-offcanvas"
//         contentClassName="custom-offcanvas-content"
//         onClose={onClose}
//         style={offcanvasStyle}
//       >
//         <OffcanvasHeader className="border-bottom" toggle={toggle}>
//           <h5 className="text-primary">
//             Offer Details&nbsp;&nbsp;
//             <span className="font-size-12 text-muted mt-2">
//               <i className="fas fa-info-circle" /> Show Details of selected
//               offers.
//             </span>
//           </h5>
//         </OffcanvasHeader>
//         <OffcanvasBody>
//           {/* {content} */}
//           <Row className="p-2">
//             {/* <div className="pt-2 mb-0"> */}
//             {/* <div className="row "> */}
//             <div className="col-md-6 p-0">
//               <div className="col-sm order-2 order-sm-1 card h-100 px-2 p-2">
//                 <div className="d-flex align-items-start mt-3 mt-sm-0">
//                   <div className="flex-shrink-0">
//                     <div className="avatar-xl mx-auto mb-0 me-2 ms-2 mt-1">
//                       <span
//                         className="avatar-title bg-primary text-white font-size-24 p-3 display-3 m-0 rounded-circle"
//                         style={{ width: "65px", height: "65px" }}
//                       >
//                         {getInitials(individualDetail?.full_name)}
//                       </span>
//                     </div>
//                     {/* <div className="avatar-xl me-3">
//                         <img
//                           src={avatar}
//                           alt=""
//                           className="img-fluid rounded-circle d-block border border-secondary"
//                         />
//                       </div> */}
//                   </div>
//                   <div className="flex-grow-1">
//                     {/* <h5 className="font-size-16 mb-2">{data.full_name}
//                     <Badge color={data.status === "contracted"?"success":"warning"} className="float-end font-size-12 ">{data.status}</Badge>
//                     </h5> */}
//                     <div>
//                       {/* <h5 className="font-size-14 mb-1">
//                           {data && data.full_name}
//                         </h5> */}
//                       <h5 className="font-size-16">
//                         {individualDetail.full_name}
//                         {/* <Badge
//                             color={
//                               data.status === "contracted"
//                                 ? "success"
//                                 : "warning"
//                             }
//                             className="float-end font-size-12 float-end p-1"
//                           >
//                             {individualDetail?.status}
//                           </Badge> */}
//                         <Badge
//                           className={`p-1 text-white font-size-12 float-end ${
//                             offerData?.status === "started"
//                               ? "bg-warning"
//                               : offerData?.status === "accepted"
//                                 ? "bg-success"
//                                 : offerData?.status === "rejected"
//                                   ? "bg-danger"
//                                   : "" // Default color if status is not recognized
//                           }`}
//                         >
//                           {offerData?.status}
//                         </Badge>
//                       </h5>
//                       {/* <p className="text-muted font-size-13  mb-1">
//                           {data.professional_role ? data.professional_role : 'NA'}
//                         </p> */}
//                       {/* <h5 className="font-size-14 text-muted mb-2">/h5> */}
//                       <h5 className="text-muted font-size-13 mb-1">
//                         <i className="mdi mdi-briefcase me-1 text-primary align-middle"></i>
//                         {offerData?.guid?.split("-")[0].toUpperCase()}-
//                         {offerData?.guid?.split("-")[1].toUpperCase()}
//                       </h5>
//                       <div className="d-flex flex-wrap align-items-start text-muted font-size-13">
//                         {individualDetail.contact_number && (
//                           <div>
//                             <i className="mdi mdi-phone me-1 text-primary align-middle"></i>
//                             {individualDetail.contact_number}
//                           </div>
//                         )}
//                         <div>
//                           <i className="mdi mdi-email me-1 text-primary align-middle"></i>
//                           {individualDetail?.email}
//                         </div>
//                       </div>

//                       <div className="d-flex flex-wrap align-items-start text-muted font-size-13 mt-2">
//                         <div>
//                           <span>
//                             {individualDetail &&
//                               individualDetail?.skills?.map((skills, index) => (
//                                 <Link
//                                   key={index}
//                                   to="#"
//                                   className="badge bg-primary-subtle text-primary mx-1 p-1"
//                                 >
//                                   {skills}
//                                 </Link>
//                               ))}
//                           </span>
//                         </div>
//                       </div>
//                       {/* <div>
//                           <p>{renderSummary()}</p>
//                         </div> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-6 p-0 ps-3">
//               <div className="col-sm order-2 order-sm-1 card h-100 px-2 p-2">
//                 <div className="d-flex align-items-start">
//                   <div className="flex-shrink-0">
//                     <div className="avatar-xl me-3">
//                       <img
//                         src={joblogo}
//                         alt=""
//                         className="img-fluid rounded-circle d-block "
//                       />
//                     </div>
//                   </div>
//                   <div className="flex-grow-1">
//                     <div className="col-sm-auto order-1 order-sm-2">
//                       <Badge className="bg-success p-1 font-size-12 float-end">
//                         {jobDetail?.status}
//                       </Badge>
//                     </div>
//                     <div>
//                       <Link
//                         to="#"
//                         onClick={(e) => {
//                           handleJob(e);
//                         }}
//                         className="font-size-16 mb-2"
//                       >
//                         {jobDetail?.title}
//                       </Link>
//                       {/* <h5 className="font-size-16 mb-1">
//                             Invitation {jobDetail?.createdAt}
//                           </h5> */}

//                       <h5 className="text-muted font-size-13 mt-1 mb-1">
//                         <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>{" "}
//                         {jobDetail?.location}
//                       </h5>

//                       <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14 mt-2">
//                         <div>
//                           <span>
//                             {jobDetail?.skills?.map((skill, index) => (
//                               <Link
//                                 key={index}
//                                 to="#"
//                                 className="badge bg-primary-subtle text-primary mx-1 p-1"
//                                 // className="badge bg-primary  mx-1 p-1"
//                               >
//                                 {skill}
//                               </Link>
//                             ))}
//                           </span>
//                         </div>
//                       </div>
//                       <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13 mt-1">
//                         <div>
//                           <i className="mdi mdi-account-details me-1 text-primary align-middle"></i>
//                           <span>
//                             {jobDetail?.description?.substring(0, 100)}...
//                             {/* <Link to="#">See more</Link> */}
//                           </span>
//                         </div>
//                         {/* <div className="d-flex align-items-start justify-content-end my-3">
//                                 <Link
//                                   to="#"
//                                   className="btn btn-primary btn-sm m-1"
//                                 >
//                                   Download JD <i className="fas fa-download ms-1"/>
//                                 </Link>

//                                 <Link
//                                   to="#"
//                                   className="btn btn-secondary btn-sm m-1"
//                                 >
//                                   Generate Resume
//                                   <i className="far fa-file-alt ms-1"/>
//                                 </Link>
//                               </div> */}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               {/* <TIQcard/> */}
//             </div>
//             <div className="mt-0 mb-0 text-muted">
//               <hr />
//             </div>
//           </Row>
//           <h5 className="text-primary">
//             Individual Offer History&nbsp;&nbsp;
//             <span className="font-size-12 text-muted mt-2">
//               <i className="fas fa-info-circle" /> Timeline of an individual for
//               an offer.
//             </span>
//           </h5>
//           {/* </div> */}
//           {/* </div> */}
//           {/* <Card className="mb-0">
//               <CardBody className="p-0">
//                 <div className="list-group list-group-flush">
//                   {offerData && (
//                     <div className="list-group-item list-group-item-action">
//                       <div className="d-flex align-items-center">
//                         <div className="avatar-xm mx-auto mb-0 me-3 mt-1">
//                           <span
//                             className="avatar-title bg-primary text-white font-size-24 p-3 display-3 m-0 rounded-circle"
//                             style={{ width: "65px", height: "65px" }}
//                           >
//                             {getInitials(offerData?.full_name)}
//                           </span>
//                         </div>
//                         <div className="flex-grow-1 mt-0">
//                           <div className="d-flex justify-content-between mt-1">
//                             <Link to="#" className="font-size-14">
//                               {offerData?.full_name}
//                             </Link>
//                             <span className="badge bg-primary font-size-13 me-1">
//                               {offerData?.offer_status}
//                             </span>
//                           </div>
//                           <p className="font-size-12">
//                             {offerData?.offer_createdAt
//                               ? dayjs(offerData?.offer_createdAt).format(
//                                   "DD-MM-YYYY",
//                                 )
//                               : "--"}
//                           </p>
//                           <div className="mt-2">
//                             <Link
//                               to="#"
//                               onClick={(e) => {
//                                 handleJob(e, offerData?.jobdescription_guid);
//                               }}
//                               className="font-size-12"
//                             >
//                               {offerData?.jobdescription_guid
//                                 ?.substring(0, 8)
//                                 .toUpperCase()}
//                             </Link>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </CardBody>
//             </Card> */}
//           {candidatureHistory && candidatureHistory.length > 0 && (
//             <TimelineComponent historydata={candidatureHistory} />
//           )}
//           {/* <Col className="col-lg-12 border shadow rounded my-1 p-4">
//               <div className="row rounded p-2">
//                 <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
//                   <div className="">
//                     <CardBody className="">
//                       <Row className="align-items-center">
//                         <Col className="col-lg-6 col-md-6 col-sm-12 mb-2">
//                           <p>
//                             <strong>Job Title</strong> :{" "}
//                           </p>
//                           <p>
//                             <strong>Company Name</strong> :{" "}
//                           </p>
//                           <p>
//                             <strong>Candidate Name</strong> :{" "}
//                           </p>
//                         </Col>
//                       </Row>
//                     </CardBody>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 col-md-6 col-sm-12">
//                   <Row className="text-end">
//                     <Col className="col-12 mb-2">
//                       <Link to={"#"} className="btn-mycandi">
//                         {" "}
//                         &nbsp;&nbsp;&nbsp;Pending&nbsp;&nbsp;&nbsp;
//                       </Link>
//                     </Col>
//                   </Row>
//                 </div>
//               </div>
//               <div className="col-lg-12">
//                 <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
//                   <Link to={"#"} className="btn-mycandi bg-success ">
//                     {" "}
//                     Accept
//                   </Link>
//                   <Link to={"#"} className="btn-mycandibtn2 mx-2 bg-danger">
//                     {" "}
//                     Reject
//                   </Link>
//                 </div>
//               </div>
//             </Col> */}
//         </OffcanvasBody>
//       </Offcanvas>
//       {isJobCanvas && (
//         <CanvasPartnerJobDetails
//           Alljobsstatus={true}
//           width={50}
//           placement="end"
//           isOpen={isJobCanvas}
//           toggle={() => setIsJobCanvas(!isJobCanvas)}
//           onclose={closeJobCanvas}
//           data={jobDetail}
//         />
//       )}
//     </>
//   );
// }

// export default OfferDetailsCanvas;

import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Badge,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
} from "reactstrap";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  IndividualDetail,
  CandidatureHistory,
  JobDetail,
} from "../PartnerSlice";
import { dateyyyymmdd_tohtml } from "../../helpers/date";
import TIQcard from "../../customComponents/offcanvas/offcanvasComponents/TIQcard";
import joblogo from "../../assets/images/companyBenchImage/jobgray1.jpg";
import TimelineComponent from "../../customComponents/timelineComponents/TimeLineComponent";
import CanvasPartnerJobDetails from "../../customComponents/offcanvas/CanvasPartnerJobDetails";
import FunnelProgressBar from "../../components/Common/FunnelProgressBar";
import { log_debug } from "../../apiHelpers/logger";

function OfferDetailsCanvas({ isOpen, toggle, onClose, data, content }) {
  const dispatch = useDispatch();

  const [offerData, setOfferData] = useState(null);
  const [jobGuid, setJobGuid] = useState({});

  const [individualDetail, setIndividualDetail] = useState({});
  const [jobDetail, setJobDetail] = useState({});
  const [candidatureHistory, setCandidatureHistory] = useState([]);

  const [isJobCanvas, setIsJobCanvas] = useState(false);

  const partnerStore = useSelector((state) => state.partnerSlice);

  const offcanvasStyle = {
    width: "50%", // You can set a default width or use the provided width
  };

  useEffect(() => {
    let off_data = {};
    if (data.individual_guid) {
      (off_data.guid = data.offer_id),
        (off_data.indv_guid = data.individual_guid),
        (off_data.job_guid = data.jobdescription_guid),
        (off_data.indv_job_guid = data.individualjobmapping_guid),
        (off_data.status = data.status);
    } else if (data.guid) {
      (off_data.guid = data.offer_guid),
        (off_data.indv_guid = data.guid),
        (off_data.job_guid = data.jobdescription_guid),
        (off_data.indv_job_guid = data.indv_job_map_guid),
        (off_data.status = data.offer_status);
    }
    setOfferData(off_data);
    log_debug("offer details canvas data", data);
  }, [data]);

  useEffect(() => {
    // let indv_guid = ''
    // if(offerData?.individual_guid){
    //   indv_guid = offerData.individual_guid;
    // }else if(offerData?.guid){
    //   indv_guid = offerData.guid
    // }
    dispatch(IndividualDetail(offerData?.indv_guid));
    dispatch(CandidatureHistory(offerData?.indv_job_guid));

    dispatch(JobDetail(offerData?.job_guid));
  }, [offerData]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data?.IndividualDetail?.success &&
        partnerStore.data.IndividualDetail.data?.individual
      ) {
        setIndividualDetail(
          partnerStore.data.IndividualDetail.data?.individual,
        );
      }
      if (
        partnerStore.data?.CandidatureHistory?.success &&
        partnerStore.data.CandidatureHistory.data?.individual_history
      ) {
        if (candidatureHistory.length == 0) {
          setCandidatureHistory(
            partnerStore.data.CandidatureHistory.data?.individual_history,
          );
        }
      }
      if (
        partnerStore.data?.JobDetail?.success &&
        partnerStore.data.JobDetail.data?.jobdescription
      ) {
        setJobDetail(partnerStore.data.JobDetail.data?.jobdescription);
      }
    }
  }, [partnerStore]);

  const getInitials = (fullName) => {
    return fullName
      ?.split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  };

  const handleJob = (e) => {
    e.preventDefault();
    // setJobGuid(guid);
    setIsJobCanvas(true);
  };

  const closeJobCanvas = () => {
    setIsJobCanvas(false);
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          <h4 className=" ">
            Offer details{"   "}
            <span className="h6">
              <FaRegQuestionCircle className="text-danger" /> Show Details of
              selected offers
            </span>
          </h4>
          <FunnelProgressBar candidatureHistory={candidatureHistory} />
          {/* <div className="hstack">
          <h5 className="text-primary">
            Offer details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Details of selected offer </span>
          </h5>
           <div className="hstack">
    <a href="#" className={`funnel ${candidatureHistory.length>0 && candidatureHistory && candidatureHistory[0].status && candidatureHistory[0].status.includes('invited') ? 'bg-successs' : ''}`}>New</a>
    <a href="#" className={`funnel ${candidatureHistory.length>0 && candidatureHistory &&candidatureHistory[0].status && candidatureHistory[0].status.includes('l1') ? 'bg-successs' : ''}`}>L1 Interview</a>
    <a href="#" className={`funnel ${candidatureHistory.length>0 && candidatureHistory && candidatureHistory[0].status && candidatureHistory[0].status.includes('l2') ? 'bg-successs' : ''}`}>L2 Interview</a>
    <a href="#" className={`funnel ${candidatureHistory.length>0 && candidatureHistory && candidatureHistory[0].status && candidatureHistory[0].status.includes('offer') ? 'bg-successs' : ''}`}> Offer </a>
    <a href="#" className={`funnel ${candidatureHistory.length>0 && candidatureHistory[0].status && candidatureHistory[0].status.includes('contract') ? 'bg-successs' : ''}`}> Contract </a>
</div> */}
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* {content} */}
          <Row className="p-2">
            {/* <div className="pt-2 mb-0"> */}
            {/* <div className="row "> */}
            <div className="col-md-6 p-0">
              <div className="col-sm order-2 order-sm-1 card h-100 px-2 p-2">
                <div className="d-flex align-items-start mt-3 mt-sm-0">
                  <div className="flex-shrink-0">
                    <div className="avatar-xl mx-auto mb-0 me-2 ms-2 mt-1">
                      <span
                        className="avatar-title bg-primary text-white font-size-24 p-3 display-3 m-0 rounded-circle"
                        style={{ width: "65px", height: "65px" }}
                      >
                        {getInitials(individualDetail?.full_name)}
                      </span>
                    </div>
                    {/* <div className="avatar-xl me-3">
                        <img
                          src={avatar}
                          alt=""
                          className="img-fluid rounded-circle d-block border border-secondary"
                        />
                      </div> */}
                  </div>
                  <div className="flex-grow-1">
                    {/* <h5 className="font-size-16 mb-2">{data.full_name}
                    <Badge color={data.status === "contracted"?"success":"warning"} className="float-end font-size-12 ">{data.status}</Badge>
                    </h5> */}
                    <div>
                      {/* <h5 className="font-size-14 mb-1">
                          {data && data.full_name}
                        </h5> */}
                      <h5 className="font-size-16">
                        {individualDetail.full_name}
                        {/* <Badge
                            color={
                              data.status === "contracted"
                                ? "success"
                                : "warning"
                            }
                            className="float-end font-size-12 float-end p-1"
                          >
                            {individualDetail?.status}
                          </Badge> */}
                        <Badge
                          className={`p-1 text-white font-size-12 float-end ${
                            offerData?.status === "started"
                              ? "bg-warning"
                              : offerData?.status === "accepted"
                                ? "bg-success"
                                : offerData?.status === "rejected"
                                  ? "bg-danger"
                                  : "" // Default color if status is not recognized
                          }`}
                        >
                          {offerData?.status}
                        </Badge>
                      </h5>
                      {/* <p className="text-muted font-size-13  mb-1">
                          {data.professional_role ? data.professional_role : 'NA'}
                        </p> */}
                      {/* <h5 className="font-size-14 text-muted mb-2">/h5> */}
                      <h5 className="text-muted font-size-13 mb-1">
                        <i className="mdi mdi-briefcase me-1 text-primary align-middle"></i>
                        {offerData?.guid?.split("-")[0].toUpperCase()}-
                        {offerData?.guid?.split("-")[1].toUpperCase()}
                      </h5>
                      <div className="d-flex flex-wrap align-items-start text-muted font-size-13">
                        {individualDetail.contact_number && (
                          <div>
                            <i className="mdi mdi-phone me-1 text-primary align-middle"></i>
                            {individualDetail.contact_number}
                          </div>
                        )}
                        <div>
                          <i className="mdi mdi-email me-1 text-primary align-middle"></i>
                          {individualDetail?.email}
                        </div>
                      </div>

                      <div className="d-flex flex-wrap align-items-start text-muted font-size-13 mt-2">
                        <div>
                          <span>
                            {individualDetail &&
                              individualDetail?.skills?.map((skills, index) => (
                                <Link
                                  key={index}
                                  to="#"
                                  className="badge bg-primary rounded-pill px-3 p-1 mx-1"
                                >
                                  {skills}
                                </Link>
                              ))}
                          </span>
                        </div>
                      </div>
                      {/* <div>
                          <p>{renderSummary()}</p>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 ps-3">
              <div className="col-sm order-2 order-sm-1 card h-100 px-2 p-2">
                <div className="d-flex align-items-start">
                  <div className="flex-shrink-0">
                    <div className="avatar-xl me-3">
                      <img
                        src={joblogo}
                        alt=""
                        className="img-fluid rounded-circle d-block "
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="col-sm-auto order-1 order-sm-2">
                      <Badge className="bg-success p-1 font-size-12 float-end">
                        {jobDetail?.status}
                      </Badge>
                    </div>
                    <div>
                      <Link
                        to="#"
                        onClick={(e) => {
                          handleJob(e);
                        }}
                        className="font-size-16 mb-2"
                      >
                        {jobDetail?.title}
                      </Link>
                      {/* <h5 className="font-size-16 mb-1">
                            Invitation {jobDetail?.createdAt}
                          </h5> */}

                      <h5 className="text-muted font-size-13 mt-1 mb-1">
                        <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>{" "}
                        {jobDetail?.location}
                      </h5>

                      <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14 mt-2">
                        <div>
                          <span>
                            {jobDetail?.skills?.map((skill, index) => (
                              <Link
                                key={index}
                                to="#"
                                className="badge bg-primary rounded-pill px-3 p-1 mx-1"
                                // className="badge bg-primary  mx-1 p-1"
                              >
                                {skill}
                              </Link>
                            ))}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13 mt-1">
                        <div>
                          <i className="mdi mdi-account-details me-1 text-primary align-middle"></i>
                          <span>
                            {jobDetail?.description?.substring(0, 100)}...
                            {/* <Link to="#">See more</Link> */}
                          </span>
                        </div>
                        {/* <div className="d-flex align-items-start justify-content-end my-3">
                                <Link
                                  to="#"
                                  className="btn btn-primary btn-sm m-1"
                                >
                                  Download JD <i className="fas fa-download ms-1"/>
                                </Link>

                                <Link
                                  to="#"
                                  className="btn btn-secondary btn-sm m-1"
                                >
                                  Generate Resume
                                  <i className="far fa-file-alt ms-1"/>
                                </Link>
                              </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <TIQcard/> */}
            </div>
            <div className="mt-0 mb-0 text-muted">
              <hr />
            </div>
          </Row>
          {/* <h5 className="text-primary">
            Individual Offer History&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Timeline of an individual for
              an offer.
            </span>
          </h5> */}
          {/* </div> */}
          {/* </div> */}
          {/* <Card className="mb-0">
              <CardBody className="p-0">
                <div className="list-group list-group-flush">
                  {offerData && (
                    <div className="list-group-item list-group-item-action">
                      <div className="d-flex align-items-center">
                        <div className="avatar-xm mx-auto mb-0 me-3 mt-1">
                          <span
                            className="avatar-title bg-primary text-white font-size-24 p-3 display-3 m-0 rounded-circle"
                            style={{ width: "65px", height: "65px" }}
                          >
                            {getInitials(offerData?.full_name)}
                          </span>
                        </div>
                        <div className="flex-grow-1 mt-0">
                          <div className="d-flex justify-content-between mt-1">
                            <Link to="#" className="font-size-14">
                              {offerData?.full_name}
                            </Link>
                            <span className="badge bg-primary font-size-13 me-1">
                              {offerData?.offer_status}
                            </span>
                          </div>
                          <p className="font-size-12">
                            {offerData?.offer_createdAt
                              ? dayjs(offerData?.offer_createdAt).format(
                                  "DD-MM-YYYY",
                                )
                              : "--"}
                          </p>
                          <div className="mt-2">
                            <Link
                              to="#"
                              onClick={(e) => {
                                handleJob(e, offerData?.jobdescription_guid);
                              }}
                              className="font-size-12"
                            >
                              {offerData?.jobdescription_guid
                                ?.substring(0, 8)
                                .toUpperCase()}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card> */}
          {candidatureHistory && candidatureHistory.length > 0 && (
            <TimelineComponent
              historydata={candidatureHistory}
              type={"candidatureHistory"}
            />
          )}
          {/* <Col className="col-lg-12 border shadow rounded my-1 p-4">
              <div className="row rounded p-2">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                  <div className="">
                    <CardBody className="">
                      <Row className="align-items-center">
                        <Col className="col-lg-6 col-md-6 col-sm-12 mb-2">
                          <p>
                            <strong>Job Title</strong> :{" "}
                          </p>
                          <p>
                            <strong>Company Name</strong> :{" "}
                          </p>
                          <p>
                            <strong>Candidate Name</strong> :{" "}
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Row className="text-end">
                    <Col className="col-12 mb-2">
                      <Link to={"#"} className="btn-mycandi">
                        {" "}
                        &nbsp;&nbsp;&nbsp;Pending&nbsp;&nbsp;&nbsp;
                      </Link>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
                  <Link to={"#"} className="btn-mycandi bg-success ">
                    {" "}
                    Accept
                  </Link>
                  <Link to={"#"} className="btn-mycandibtn2 mx-2 bg-danger">
                    {" "}
                    Reject
                  </Link>
                </div>
              </div>
            </Col> */}
        </OffcanvasBody>
      </Offcanvas>
      {isJobCanvas && (
        <CanvasPartnerJobDetails
          Alljobsstatus={true}
          width={50}
          placement="end"
          isOpen={isJobCanvas}
          toggle={() => setIsJobCanvas(!isJobCanvas)}
          onclose={closeJobCanvas}
          data={jobDetail}
        />
      )}
    </>
  );
}

export default OfferDetailsCanvas;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
} from "reactstrap";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { contracts } from "../data/Data";
import { getPartnerIndividualContracts } from "./PartnerIndividualSlice/PartnerIndividualSlice";
import { log_debug } from "../apiHelpers/logger";
import { dateyyyymmdd_tohtml, getDatesOfWeek } from "../helpers/date";
import { Link } from "react-router-dom";
import StatusBadge from "../customComponents/utilities/StatusBadge";
import ContractDetailCanvas from "../Partner/pagesComponents/ContractDetailCanvas";
// import ContractDetailCanvas from "../customComponents/offcanvas/ContractDetailCanvas";

function PartnerIndividualContracts() {
  const dispatch = useDispatch();
  const partnerindividualstore = useSelector(
    (state) => state.partnerindividualslice,
  );

  const [contractdata, setContractData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [contractinfo, setContractinfo] = useState({});

  const handleContract = (row) => {
    setContractinfo(row);
    setIsOpen(true);
  };

  const closeOffcanvas = () => {
    setIsOpen(false);
  };

  const columns = [
    {
      Header: "Contract Id",
      accessor: "contract_id",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleContract(row.original);
          }}
        >
          {row.original?.contract_id?.split("-")[0].toUpperCase()}-
          {row.original?.contract_id?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Job Role",
      accessor: "job_role",
    },
    // {
    //   Header: "Billing Cycle",
    //   accessor: "billing_cycle",
    // },
    {
      Header: "Start date",
      accessor: "started_at",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "End date",
      accessor: "ended_at",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex gap-1">
          {/* <p className="font-size-12 m-0"></p> */}
          <StatusBadge status={row.values.status} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    dispatch(getPartnerIndividualContracts({ filtervalue: "" })).then((res) => {
      const data = res.payload.data;
      log_debug("response contracts", data);
      setTotalCount(data?.totalCount);
      setContractData(data?.contracts);
      setIsLoading(false);
    });
  }, []);

  const handlePageChange = (newPage) => {
    setcurrentPage(newPage);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Contracts"
            Name="Contracts"
          />
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <CardBody>
                  <Row>
                    <div className="col-12 col-lg-6 col-md-6 d-sm-flex" />
                    <div className="col-12 col-lg-6 col-md-6">
                      <Col md={12}>
                        <CardTitle className="text-end fs-6 pt-3">
                          Total Contracts : {totalCount}
                        </CardTitle>
                      </Col>
                    </div>
                  </Row>
                </CardBody>
                <div>
                  <Card>
                    <CardBody>
                      <CustomTable
                        tableDatas={contractdata}
                        columns={columns}
                        data={contractdata}
                        hideCheckbox={true}
                        totalCount={totalCount}
                        isLoading={isLoading}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {isOpen && (
          <ContractDetailCanvas
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            onClose={closeOffcanvas}
            data={contractinfo}
            userType={"partnerIndividual"}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default PartnerIndividualContracts;

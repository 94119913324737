import React from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  CardText,
  TabContent,
} from "reactstrap";
// Editable
import Breadcrumb from "../components/Common/Breadcrumb";
import Timesheetcomponent from "../customComponents/timesheet/timesheet";
import PartnerIndividualApprovedTimesheets from "../Partner_Individual/PartnerIndividualApprovedTimesheets";
import PartnerIndividualRejectedTimesheets from "../Partner_Individual/PartnerIndividualRejectedTimesheets";
import { useState } from "react";
import classnames from "classnames";
import { getIndividualGuid } from "../apiConfig/globalvariables";
import { Iguid } from "../apiConfig/axiosDev";

const IndividualTimesheets = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Home"
            breadcrumbItem="Timesheets"
            Name="Timesheets"
          />
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs-custom text-center">
                <NavItem className="w-25">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-none d-sm-block">All Timesheets</span>
                  </NavLink>
                </NavItem>
                <NavItem className="w-25">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Approved Timesheets
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem className="w-25">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    <span className="d-none d-sm-block">
                      Rejected Timesheets
                    </span>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "8",
                        })}
                        onClick={() => {
                          toggle1("8");
                        }}
                      >
                        Settings
                      </NavLink>
                    </NavItem> */}
              </Nav>
              <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        <Timesheetcomponent
                          individualguid={getIndividualGuid() || Iguid}
                        />
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <PartnerIndividualApprovedTimesheets />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <PartnerIndividualRejectedTimesheets />
                    </Col>
                  </Row>
                </TabPane>

                {/* <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            Trust fund seitan letterpress, keytar raw denim
                            keffiyeh etsy art party before they sold out master
                            cleanse gluten-free squid scenester freegan cosby
                            sweater. Fanny pack portland seitan DIY, art party
                            locavore wolf cliche high life echo park Austin.
                            Cred vinyl keffiyeh DIY salvia PBR, banh mi before
                            they sold out farm-to-table VHS viral locavore cosby
                            sweater. Lomo wolf viral, mustache readymade
                            thundercats keffiyeh craft beer marfa ethical. Wolf
                            salvia freegan, sartorial keffiyeh echo park vegan.
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane> */}
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IndividualTimesheets;

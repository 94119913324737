import React, { useEffect, useState } from "react";
import { FiEdit, FiTrash2, FiUserPlus } from "react-icons/fi";
import {
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
  CardText,
  UncontrolledTooltip,
  Alert,
  Button,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import classnames from "classnames";
// import SingleResumeUpload from "../../customComponents/fileupload/SingleResumeUpload";
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "../../../src/assets/css/styleCompanyBench.css";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import CandidateDetailsCard from "./CandidateDetailsCard";
import CandidateDetailsProfilesCanvas from "./CandidateDetailsProfilesCanvas";
import CandidateDetailsWorkExperience from "./CandidateDetailsWorkExperience";
import CandidateDetailsWorkExperienceCanvas from "./CandidateDetailsWorkExperienceCanvas";
import CandidateDetailsEducation from "./CandidateDetailsEducation";
import CandidateDetailsEducationCanvas from "./CandidateDetailsEducationCanvas";
import { useDispatch, useSelector } from "react-redux";
import {
  CandidateResumeAddEditData,
  CandidateResumeProfileEdit,
  addCandidateResume,
  candidatesOverview,
} from "../PartnerSlice";
import CandidateDetailsCertificate from "./CandidateDetailsCertificate";
import { log_debug } from "../../apiHelpers/logger";
import AddCandidateDetailsEducation from "./AddCandidateDetailsEducation";
import AddCandidateDetailsWorkExperience from "./AddCandidateDetailsWorkExperience";
import AddCandidateDetailsCertification from "./AddCandidateDetailsCertification";
import CandidateDetailsCertificateCanvas from "./CandidateDetailsCertificateCanvas";
import Toast from "../../customComponents/Toast";
import { toast_messages } from "../../data/cb_title";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomBreadcrumb from "../../customComponents/breadcrumb/CustomBreadcrumb";

function NewCandidateResumeDetails() {
  const [showToast, setShowToast] = useState(false);

  const [toastData, setToastData] = useState({
    message: "",
    variant: "",
    position: "",
    open: null,
  });

  const location = useLocation();
  const individualGuid = location.state?.individualGuid || [];
  const individualResumes = location.state?.individualResumes || [];

  const [individualData1, setIndividualData1] = useState([]);
  const [individualData2, setIndividualData2] = useState([]);
  const [individualData3, setIndividualData3] = useState([]);
  const [individualData4, setIndividualData4] = useState([]);

  const [allResumesData, setAllResumesData] = useState({});
  const [Candidate, setCandidate] = useState([]);
  log_debug("infodata", Candidate);
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [verticalActiveTab1, setverticalActiveTab1] = useState("2");
  const [verticalActiveTab2, setverticalActiveTab2] = useState("2");
  const [verticalActiveTab3, setverticalActiveTab3] = useState("2");
  const [verticalActiveTab4, setverticalActiveTab4] = useState("2");
  // const resumelength = localStorage.getItem("uploadedFileslength");
  const resumelength = individualResumes.length;
  log_debug("resumelength", resumelength);

  // --------------------Add card start ----------------
  const [IsAddOpenWorkTab1, setIsAddOpenWorkTab1] = useState(false);
  const [IsAddOpenWorkTab2, setIsAddOpenWorkTab2] = useState(false);
  const [IsAddOpenWorkTab3, setIsAddOpenWorkTab3] = useState(false);
  const [IsAddOpenWorkTab4, setIsAddOpenWorkTab4] = useState(false);

  const [IsAddOpenEducationTab1, setIsAddOpenEducationTab1] = useState(false);
  const [IsAddOpenEducationTab2, setIsAddOpenEducationTab2] = useState(false);
  const [IsAddOpenEducationTab3, setIsAddOpenEducationTab3] = useState(false);
  const [IsAddOpenEducationTab4, setIsAddOpenEducationTab4] = useState(false);

  const [IsAddOpenCertificationTab1, setIsAddOpenCertificationTab1] =
    useState(false);
  const [IsAddOpenCertificationTab2, setIsAddOpenCertificationTab2] =
    useState(false);
  const [IsAddOpenCertificationTab3, setIsAddOpenCertificationTab3] =
    useState(false);
  const [IsAddOpenCertificationTab4, setIsAddOpenCertificationTab4] =
    useState(false);

  //Work experience
  const handleAddWorkTab1 = () => {
    setIsAddOpenWorkTab1(true);
  };
  const handleAddWorkTab2 = () => {
    setIsAddOpenWorkTab2(true);
  };
  const handleAddWorkTab3 = () => {
    setIsAddOpenWorkTab3(true);
  };
  const handleAddWorkTab4 = () => {
    setIsAddOpenWorkTab4(true);
  };

  //Education details
  const handleAddEducationTab1 = () => {
    setIsAddOpenEducationTab1(true);
  };
  const handleAddEducationTab2 = () => {
    setIsAddOpenEducationTab2(true);
  };
  const handleAddEducationTab3 = () => {
    setIsAddOpenEducationTab3(true);
  };
  const handleAddEducationTab4 = () => {
    setIsAddOpenEducationTab4(true);
  };

  //Certification details
  const handleAddCertificationTab1 = () => {
    setIsAddOpenCertificationTab1(true);
  };
  const handleAddCertificationTab2 = () => {
    setIsAddOpenCertificationTab2(true);
  };
  const handleAddCertificationTab3 = () => {
    setIsAddOpenCertificationTab3(true);
  };
  const handleAddCertificationTab4 = () => {
    setIsAddOpenCertificationTab4(true);
  };

  // --------------------Edit card start----------------
  const [IsEditOpenProfileTab1, setIsEditOpenProfileTab1] = useState(false);
  const [IsEditOpenProfileTab2, setIsEditOpenProfileTab2] = useState(false);
  const [IsEditOpenProfileTab3, setIsEditOpenProfileTab3] = useState(false);
  const [IsEditOpenProfileTab4, setIsEditOpenProfileTab4] = useState(false);

  const [IsEditOpenWorkTab1, setIsEditOpenWorkTab1] = useState(false);
  const [IsEditOpenWorkTab2, setIsEditOpenWorkTab2] = useState(false);
  const [IsEditOpenWorkTab3, setIsEditOpenWorkTab3] = useState(false);
  const [IsEditOpenWorkTab4, setIsEditOpenWorkTab4] = useState(false);

  const [IsEditOpenEducationTab1, setIsEditOpenEducationTab1] = useState(false);
  const [IsEditOpenEducationTab2, setIsEditOpenEducationTab2] = useState(false);
  const [IsEditOpenEducationTab3, setIsEditOpenEducationTab3] = useState(false);
  const [IsEditOpenEducationTab4, setIsEditOpenEducationTab4] = useState(false);

  const [IsEditOpenCertificationTab1, setIsEditOpenCertificationTab1] =
    useState(false);
  const [IsEditOpenCertificationTab2, setIsEditOpenCertificationTab2] =
    useState(false);
  const [IsEditOpenCertificationTab3, setIsEditOpenCertificationTab3] =
    useState(false);
  const [IsEditOpenCertificationTab4, setIsEditOpenCertificationTab4] =
    useState(false);

  //profile
  const handleEditProfileTab1 = () => {
    setIsEditOpenProfileTab1(true);
  };
  const handleEditProfileTab2 = () => {
    setIsEditOpenProfileTab2(true);
  };
  const handleEditProfileTab3 = () => {
    setIsEditOpenProfileTab3(true);
  };
  const handleEditProfileTab4 = () => {
    setIsEditOpenProfileTab4(true);
  };

  //Work experience
  const handleEditWorkTab1 = () => {
    setIsEditOpenWorkTab1(true);
  };
  const handleEditWorkTab2 = () => {
    setIsEditOpenWorkTab2(true);
  };
  const handleEditWorkTab3 = () => {
    setIsEditOpenWorkTab3(true);
  };
  const handleEditWorkTab4 = () => {
    setIsEditOpenWorkTab4(true);
  };

  //Education
  const handleEditEducationTab1 = () => {
    setIsEditOpenEducationTab1(true);
  };
  const handleEditEducationTab2 = () => {
    setIsEditOpenEducationTab2(true);
  };
  const handleEditEducationTab3 = () => {
    setIsEditOpenEducationTab3(true);
  };
  const handleEditEducationTab4 = () => {
    setIsEditOpenEducationTab4(true);
  };

  //certification
  const handleEditCertificationTab1 = () => {
    setIsEditOpenCertificationTab1(true);
  };
  const handleEditCertificationTab2 = () => {
    setIsEditOpenCertificationTab2(true);
  };
  const handleEditCertificationTab3 = () => {
    setIsEditOpenCertificationTab3(true);
  };
  const handleEditCertificationTab4 = () => {
    setIsEditOpenCertificationTab4(true);
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };
  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };
  const toggleVerticaltab1 = (tab) => {
    if (verticalActiveTab1 !== tab) {
      setverticalActiveTab1(tab);
    }
  };
  const toggleVerticalTab2 = (tab) => {
    if (verticalActiveTab2 !== tab) {
      setverticalActiveTab2(tab);
    }
  };
  const toggleVerticalTab3 = (tab) => {
    if (verticalActiveTab3 !== tab) {
      setverticalActiveTab3(tab);
    }
  };
  const toggleVerticalTab4 = (tab) => {
    if (verticalActiveTab4 !== tab) {
      setverticalActiveTab4(tab);
    }
  };

  const initialData = [
    {
      id: 1,
      name: "John Doe",
      email: "john@example.com",
      address: "123 Main St",
      summary:
        "The candidate is a Salesforce Developer with 24 years of experience and is proficient in Go, JavaScript, Java.",
    },
    // {
    //   id: 2,
    //   name: "Jane Doe",
    //   email: "jane@example.com",
    //   address: "456 Oak Ave",
    //   summary:
    //     "The candidate is a Salesforce Developer with 24 years of experience and is proficient in Go, JavaScript, Java.",
    // },
    // Add more data as needed
  ];
  const WorkInitialData = [
    {
      id: 1,
      companyName: "ABC Corp",
      position: "Senior Software Engineer",
      startDate: "2018-05-01",
      endDate: "2022-12-31",
      responsibilities: [
        "Designed and implemented scalable software solutions.",
        "Collaborated with cross-functional teams to deliver high-quality products.",
        "Mentored junior developers and conducted code reviews.",
      ],
    },
    {
      id: 2,
      companyName: "XYZ Tech",
      position: "Software Developer",
      startDate: "2015-03-15",
      endDate: "2018-04-30",
      responsibilities: [
        "Developed and maintained web applications using React and Node.js.",
        "Participated in agile development processes.",
        "Worked closely with UI/UX designers to implement user-friendly interfaces.",
      ],
    },
    // Add more work experience entries as needed
  ];
  const EducationInitialData = [
    {
      id: 1,
      institution: "University of ABC",
      degree: "Bachelor of Science in Computer Science",
      major: "Computer Science",
      graduationDate: "2015-05-31",
      honors: "Summa Cum Laude",
    },
    {
      id: 2,
      institution: "XYZ Institute of Technology",
      degree: "Master of Science in Software Engineering",
      major: "Software Engineering",
      graduationDate: "2018-12-15",
      honors: "Magna Cum Laude",
    },
    // Add more education entries as needed
  ];

  const [data, setData] = useState(initialData);
  const [Workdata, setWorkdata] = useState(WorkInitialData);
  const [Edudata, setEdudata] = useState(EducationInitialData);
  const [editingIdPersonal, setEditingIdPersonal] = useState(null);
  const [editingIdExperience, setEditingIdExperience] = useState(null);
  const [editingIdEducation, setEditingIdEducation] = useState(null);
  // -------------------Tab1-------------------

  // profile
  const [CandidateUserTabProfile1, setCandidateUserTabProfile1] = useState([]);
  const [CandidateUserTabProfile2, setCandidateUserTabProfile2] = useState([]);
  const [CandidateUserTabProfile3, setCandidateUserTabProfile3] = useState([]);
  const [CandidateUserTabProfile4, setCandidateUserTabProfile4] = useState([]);

  // work exp
  const [CandidateUserTabWorkExp1, setCandidateUserTabWorkExp1] = useState([]);
  const [CandidateUserTabWorkExp2, setCandidateUserTabWorkExp2] = useState({});

  const [CandidateUserTabWorkExp3, setCandidateUserTabWorkExp3] = useState([]);
  const [CandidateUserTabWorkExp4, setCandidateUserTabWorkExp4] = useState([]);

  // Education
  const [CandidateUserEducationTab1, setCandidateUserEducationTab1] = useState(
    [],
  );
  const [CandidateUserEducationTab2, setCandidateUserEducationTab2] = useState(
    [],
  );
  const [CandidateUserEducationTab3, setCandidateUserEducationTab3] = useState(
    [],
  );
  const [CandidateUserEducationTab4, setCandidateUserEducationTab4] = useState(
    [],
  );

  //Certification
  const [CandidateUserCertification1, setCandidateUserCertification1] =
    useState([]);
  const [CandidateUserCertification2, setCandidateUserCertification2] =
    useState([]);
  const [CandidateUserCertification3, setCandidateUserCertification3] =
    useState([]);
  const [CandidateUserCertification4, setCandidateUserCertification4] =
    useState([]);

  const [CandidateUserTab1, setCandidateUserTab1] = useState([]);

  // let educationdataTab1 = CandidateUserTab1.educational_qualifications;
  // let workExperienceTab1 = CandidateUserTab1.work_experiences;
  // let skillTab1 = CandidateUserTab1.programming_languages;

  // -------------------Tab2-------------------
  const [CandidateUserTab2, setCandidateUserTab2] = useState([]);
  // let educationdataTab2 = CandidateUserTab2.educational_qualifications;
  // let workExperienceTab2 = CandidateUserTab2.work_experiences;
  // let skillTab2 = CandidateUserTab2.programming_languages;

  // -------------------Tab3-------------------
  const [CandidateUserTab3, setCandidateUserTab3] = useState([]);
  // let educationdataTab3 = CandidateUserTab3.educational_qualifications;
  // let workExperienceTab3 = CandidateUserTab3.work_experiences;
  // let skillTab3 = CandidateUserTab3.programming_languages;

  // -------------------Tab4-------------------
  const [CandidateUserTab4, setCandidateUserTab4] = useState([]);
  log_debug("&&CandidateUserTab4", CandidateUserTab4);
  // let educationdataTab4 = CandidateUserTab4.educational_qualifications;
  // let workExperienceTab4 = CandidateUserTab4.work_experiences;
  // let skillTab4 = CandidateUserTab4.programming_languages;

  useEffect(() => {
    if (individualGuid?.length > 0) {
      dispatch(candidatesOverview(individualGuid));
    }
  }, [individualGuid]);

  // ==========================Tab 1===============================

  // Profile details
  let professionalRoleTab1 = CandidateUserTab1?.resume?.professional_role
    ? CandidateUserTab1?.resume?.professional_role
    : "";
  let addressTab1 = CandidateUserTab1?.address
    ? CandidateUserTab1?.address
    : "";
  let summaryTab1 = CandidateUserTab1?.summary
    ? `${CandidateUserTab1?.summary}`
    : "";
  let ExperiencesTab1 = CandidateUserTab1?.overall_experience
    ? `${CandidateUserTab1?.overall_experience}`
    : "";

  useEffect(() => {
    const resumeData = {
      guid: CandidateUserTab1?.guid,
      full_name: CandidateUserTab1?.full_name || "",
      date_of_birth: CandidateUserTab1?.date_of_birth,
      email_id: CandidateUserTab1?.email_id,
      contact_no: CandidateUserTab1?.contact_number?.toString(),
      professional_role: CandidateUserTab1?.role,
      skills:
        CandidateUserTab1?.skills?.length > 0
          ? CandidateUserTab1?.skills
          : CandidateUserTab1?.resume?.programming_languages || [],
      // programming_languages: [...new Set(CandidateUserTab1?.programming_languages)] || [],
      marital_status: CandidateUserTab1?.marital_status || "",
      availability: CandidateUserTab1?.availability || "",
      address: addressTab1,
      summary: summaryTab1,
      overall_experience: ExperiencesTab1,
    };

    const workExpdata = {
      guid: CandidateUserTab1 && CandidateUserTab1?.guid,
      workRoleTab1: CandidateUserTab1?.resume?.work_experiences || [],
      // workSkillTab1: CandidateUserTab1?.resume?.programming_languages,
      // workRoleTab1: CandidateUserTab1?.resume?.work_experiences,
    };

    const education = {
      guid: CandidateUserTab1 && CandidateUserTab1?.guid,
      collegeName: CandidateUserTab1?.resume?.educational_qualifications || [],
    };

    const certificate = {
      guid: CandidateUserTab1 && CandidateUserTab1?.guid,
      certifications: CandidateUserTab1?.resume?.certifications || [],
    };

    setCandidateUserTabProfile1(resumeData);
    setCandidateUserTabWorkExp1(workExpdata);
    setCandidateUserEducationTab1(education);
    setCandidateUserCertification1(certificate);
  }, [CandidateUserTab1]);

  // ==========================Tab 2===============================

  // Profile Details
  let professionalRoleTab2 = CandidateUserTab2?.resume?.professional_role
    ? CandidateUserTab2?.resume?.professional_role
    : "";
  let addressTab2 = CandidateUserTab2?.address
    ? CandidateUserTab2?.address
    : "";
  let summaryTab2 = CandidateUserTab2?.resume?.summary
    ? `${CandidateUserTab2?.resume?.summary}`
    : "";
  let ExperiencesTab2 = CandidateUserTab2?.resume?.overall_experience
    ? `${CandidateUserTab2?.resume?.overall_experience}`
    : "";

  useEffect(() => {
    const resumeData = {
      guid: CandidateUserTab2?.guid,
      full_name: CandidateUserTab2?.full_name || "",
      date_of_birth: CandidateUserTab2?.date_of_birth,
      email_id: CandidateUserTab2?.email_id,
      contact_no: CandidateUserTab2?.contact_number?.toString(),
      skills:
        CandidateUserTab2?.skills?.length > 0
          ? CandidateUserTab2?.skills
          : CandidateUserTab2?.resume?.programming_languages || [],
      professional_role: professionalRoleTab2,
      marital_status: CandidateUserTab2?.marital_status || "",
      availability: CandidateUserTab2?.availability || "",
      address: addressTab2,
      summary: summaryTab2,
      overall_experience: ExperiencesTab2,
    };
    const workExpdata = {
      guid: CandidateUserTab2 && CandidateUserTab2?.guid,
      workRoleTab1: CandidateUserTab2?.resume?.work_experiences || [],
    };

    const education = {
      guid: CandidateUserTab2 && CandidateUserTab2?.guid,
      collegeName: CandidateUserTab2?.resume?.educational_qualifications || [],
    };

    const certificate = {
      guid: CandidateUserTab2 && CandidateUserTab2?.guid,
      certifications: CandidateUserTab2?.resume?.certifications || [],
    };
    setCandidateUserTabProfile2(resumeData);
    setCandidateUserTabWorkExp2(workExpdata);
    setCandidateUserEducationTab2(education);
    setCandidateUserCertification2(certificate);
  }, [CandidateUserTab2]);

  // ==========================Tab 3===============================

  // Profile Details
  let professionalRoleTab3 = CandidateUserTab3?.resume?.professional_role
    ? CandidateUserTab3?.resume?.professional_role
    : "";
  let addressTab3 = CandidateUserTab3?.address
    ? CandidateUserTab3?.address
    : "";
  let summaryTab3 = CandidateUserTab3?.resume?.summary
    ? `${CandidateUserTab3.resume?.summary}`
    : "";
  let ExperiencesTab3 = CandidateUserTab3?.resume?.overall_experience
    ? `${CandidateUserTab3.resume?.overall_experience}`
    : "";

  useEffect(() => {
    const resumeData = {
      guid: CandidateUserTab3?.guid,
      full_name: CandidateUserTab3?.full_name || "",
      date_of_birth: CandidateUserTab3?.date_of_birth,
      email_id: CandidateUserTab3?.email_id,
      contact_no: CandidateUserTab3?.contact_number?.toString(),
      skills:
        CandidateUserTab3?.skills?.length > 0
          ? CandidateUserTab3?.skills
          : CandidateUserTab3?.resume?.programming_languages || [],
      professional_role: professionalRoleTab3,
      marital_status: CandidateUserTab3?.marital_status || "",
      availability: CandidateUserTab3?.availability || "",
      address: addressTab3,
      summary: summaryTab3,
      overall_experience: ExperiencesTab3,
    };

    const workExpdata = {
      guid: CandidateUserTab3 && CandidateUserTab3?.guid,
      workRoleTab1: CandidateUserTab3?.resume?.work_experiences || [],
    };

    const education = {
      guid: CandidateUserTab3 && CandidateUserTab3?.guid,
      collegeName: CandidateUserTab3?.resume?.educational_qualifications,
    };
    const certificate = {
      guid: CandidateUserTab3 && CandidateUserTab3?.guid,
      certifications: CandidateUserTab3?.resume?.certifications || [],
    };

    setCandidateUserTabProfile3(resumeData);
    setCandidateUserTabWorkExp3(workExpdata);
    setCandidateUserEducationTab3(education);
    setCandidateUserCertification3(certificate);
  }, [CandidateUserTab3]);

  // ==========================Tab 3===============================

  // Profile Details
  let professionalRoleTab4 = CandidateUserTab4?.resume?.professional_role
    ? CandidateUserTab4?.resume?.professional_role
    : "";
  let addressTab4 = CandidateUserTab4?.address
    ? CandidateUserTab4?.address
    : "";
  let summaryTab4 = CandidateUserTab4?.resume?.summary
    ? `${CandidateUserTab4.resume?.summary}`
    : "";
  let ExperiencesTab4 = CandidateUserTab4?.resume?.overall_experience
    ? `${CandidateUserTab4.resume?.overall_experience}`
    : "";

  useEffect(() => {
    const resumeData = {
      guid: CandidateUserTab4?.guid,
      full_name: CandidateUserTab4?.full_name,
      date_of_birth: CandidateUserTab4?.date_of_birth,
      email_id: CandidateUserTab4?.email_id,
      contact_no: CandidateUserTab4?.contact_number?.toString(),
      skills:
        CandidateUserTab4?.skills?.length > 0
          ? CandidateUserTab4?.skills
          : CandidateUserTab4?.resume?.programming_languages || [],
      marital_status: CandidateUserTab4?.marital_status || "",
      availability: CandidateUserTab4?.availability || "",
      professional_role: professionalRoleTab4,
      address: addressTab4,
      summary: summaryTab4,
      overall_experience: ExperiencesTab4,
    };
    const workExpdata = {
      guid: CandidateUserTab4 && CandidateUserTab4?.guid,
      workRoleTab1: CandidateUserTab4?.resume?.work_experiences || [],
    };

    const education = {
      guid: CandidateUserTab4 && CandidateUserTab4?.guid,
      collegeName: CandidateUserTab4?.resume?.educational_qualifications || [],
    };

    const certificate = {
      guid: CandidateUserTab4 && CandidateUserTab4?.guid,
      certifications: CandidateUserTab4?.resume?.certifications || [],
    };

    setCandidateUserTabProfile4(resumeData);
    setCandidateUserTabWorkExp4(workExpdata);
    setCandidateUserEducationTab4(education);
    setCandidateUserCertification4(certificate);
  }, [CandidateUserTab4]);

  let resumeformData = localStorage.getItem("formData");
  log_debug("resumeformData", resumeformData);
  // const resumelengthpartnerStore = localStorage.getItem("uploadedFileslength");
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);

  // personal details
  const handleEditpersonal = (id) => {
    setEditingIdPersonal(id);
  };

  // work experience
  const handleEditexperience = (id) => {
    setEditingIdExperience(id);
  };

  // Education details
  const handleEditEducation = (id) => {
    setEditingIdEducation(id);
  };

  // ---------------------comman  delete function start------------------

  const handleDelete = (id) => {
    // personal details
    const newData = data.filter((item) => item.id !== id);
    setData(newData);

    // work experience
    const newWorkExpData = Workdata.filter((item) => item.id !== id);
    setWorkdata(newWorkExpData);

    // Education
    const newMyEduData = Edudata.filter((item) => item.id !== id);
    setWorkdata(newMyEduData);
  };

  // ---------------------comman  delete function end------------------

  const handleSave = (id, name, email, address) => {
    // personal details
    const newData = data.map((item) =>
      item.id === id ? { ...item, name, email, address } : item,
    );
    setData(newData);
    setEditingIdPersonal(null);
  };

  const handleWorkExperienceSave = (
    id,
    companyName,
    position,
    startDate,
    responsibilities,
  ) => {
    // woek experience
    const newworkexperienceData = Workdata.map((item) =>
      item.id === id
        ? { ...item, companyName, position, startDate, responsibilities }
        : item,
    );
    setWorkdata(newworkexperienceData);
    setEditingIdExperience(null);
  };

  const handleEducationSave = (
    id,
    institution,
    degree,
    major,
    graduationDate,
  ) => {
    // woek experience
    const newEducationData = Edudata.map((item) =>
      item.id === id
        ? { ...item, institution, degree, major, graduationDate }
        : item,
    );
    setEdudata(newEducationData);
    setEditingIdEducation(null);
  };

  const handleCancel = () => {
    setEditingIdPersonal(null);
    setEditingIdEducation(null);
    setEditingIdExperience(null);
  };

  const closeOffcanvas = () => {
    setEditingIdPersonal(null);
    setEditingIdEducation(null);
    setEditingIdExperience(null);
  };
  // useEffect(() => {
  //   dispatch(addCandidateResume(resumeformData));
  // }, []);

  // useEffect(() => {
  //   dispatch(addCandidateResume(resumeformData));
  // }, [resumelength >= 1]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.CandidateResumeProfileEditData?.success === true) {
        setCandidate(
          partnerStore.data.CandidateResumeProfileEditData?.data.individual,
        );
      }
      if (partnerStore.data.candidatesOverviewdata?.success === true) {
        setCandidateUserTab1(
          partnerStore.data.candidatesOverviewdata?.data?.individual_details[0],
        );
        log_debug("setcandidateusertab1data343", CandidateUserTab1);
      }
    }
  }, [partnerStore]);

  useEffect(() => {
    if (CandidateUserTab1 && !CandidateUserTab1.guid) {
      setCandidateUserTab1(
        individualResumes &&
          individualResumes[0]?.data?.data?.resumes[0]?.individual,
      );
    }
    setCandidateUserTab2(
      individualResumes &&
        individualResumes[1]?.data?.data?.resumes[0]?.individual,
    );
    setCandidateUserTab3(
      individualResumes &&
        individualResumes[2]?.data?.data?.resumes[0]?.individual,
    );
    setCandidateUserTab4(
      individualResumes &&
        individualResumes[3]?.data?.data?.resumes[0]?.individual,
    );
  }, [individualResumes]);

  useEffect(() => {
    // Check the available data and set the default tab accordingly
    if (
      resumelength >= 1 &&
      individualResumes[0]?.data?.data?.resumes[0]?.individual
    ) {
      setCustomActiveTab("1");
    } else if (
      resumelength >= 2 &&
      individualResumes[1]?.data?.data?.resumes[0]?.individual
    ) {
      setCustomActiveTab("2");
    } else if (
      resumelength >= 3 &&
      individualResumes[2]?.data?.data?.resumes[0]?.individual
    ) {
      setCustomActiveTab("3");
    } else if (
      resumelength === 4 &&
      individualResumes[3]?.data?.data?.resumes[0]?.individual
    ) {
      setCustomActiveTab("4");
    }
  }, [resumelength, individualResumes]);

  useEffect(() => {
    if (individualResumes && individualResumes.length >= 1) {
      toast.success("Candidates Profile Has been created successfully.");
      // toast.success(
      //   `You have uploaded ${individualResumes.length} candidate profile${
      //     individualResumes.length > 1 ? "s" : ""
      //   }! If your candidate${
      //     individualResumes.length > 1 ? "s are" : " is"
      //   } not added, it may be because they are affiliated with another partner.`,
      //   {
      //     toastId: "customWidthSuccess",
      //     position: toast.POSITION.TOP_CENTER,
      //     autoClose: 8000, // Adjust the duration as needed
      //     style: { width: "600px" }, // Inline style for width
      //   }
      // );
    }
  }, [individualResumes]);

  log_debug("individualResumes", individualResumes);
  log_debug("CandidateUserTab1", CandidateUserTab1);
  log_debug("CandidateUserTab2", CandidateUserTab2);
  log_debug("CandidateUserTab3", CandidateUserTab3);
  log_debug("CandidateUserTab4", CandidateUserTab4);

  // const hasResumeData = (resumeData) => {
  //   // Assuming resumeData is an object
  //   return (
  //     resumeData &&
  //     Object.values(resumeData).some(data => data !== undefined && data !== null)
  //   );
  // };

  // useEffect(() => {
  //   if (
  //     partnerStore.status === "idle" &&
  //     partnerStore.data.CandidateResumeData?.success === true
  //   ) {
  //     const resumes = partnerStore.data.CandidateResumeData?.data.resumes;

  //     if (resumes && resumes.length > 0) {
  //       const candidateUserTabs = [
  //         setCandidateUserTab1,
  //         setCandidateUserTab2,
  //         setCandidateUserTab3,
  //         setCandidateUserTab4,
  //       ];

  //       for (
  //         let i = 0;
  //         i < Math.min(resumes.length, candidateUserTabs.length);
  //         i++
  //       ) {
  //         candidateUserTabs[i](resumes[i]?.individual);
  //         log_debug(`State ${i + 1} value:`, resumes[i]?.individual);
  //       }
  //     }
  //   }
  // }, [partnerStore, allResumesData]);

  // useEffect(() => {
  //   if (
  //     partnerStore.status === "idle" &&
  //     partnerStore.data.CandidateResumeData?.success === true
  //   ) {
  //     const resumelengthpartnerStore =
  //       parseInt(localStorage.getItem("uploadedFileslength"), 10) || 0;

  //     const setFullNameForTab = (tabNumber) => {
  //       const fullName =
  //         partnerStore.data.CandidateResumeData?.data.resumes?.[
  //           `resume_${tabNumber}`
  //         ]?.individual?.full_name;
  //       return fullName
  //         ? setCandidateUserTabFullName(tabNumber, fullName)
  //         : null;
  //     };

  //     for (let i = 1; i <= Math.min(resumelengthpartnerStore, 4); i++) {
  //       setFullNameForTab(i);
  //     }
  //   }
  // }, [partnerStore]);

  // const setCandidateUserTabFullName = (tabNumber, fullName) => {
  //   switch (tabNumber) {
  //     case 1:
  //       setCandidateUserTab1(fullName);
  //       break;
  //     case 2:
  //       setCandidateUserTab2(fullName);
  //       break;
  //     case 3:
  //       setCandidateUserTab3(fullName);
  //       break;
  //     case 4:
  //       setCandidateUserTab4(fullName);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // ================Edit operation================

  const handleSaveChangesProfileTab1 = (editedData) => {
    log_debug("editeddata3948", editedData);
    // dispatch(candidatesOverview(individualGuid));
    // Implement the logic to save edited data
    setCandidateUserTabProfile1(editedData);
    dispatch(
      CandidateResumeProfileEdit({
        individual: {
          guid: editedData.guid,
          address: editedData.address,
          contact_number: editedData.contact_no,
          date_of_birth: editedData.date_of_birth,
          email_id: editedData.email_id,
          full_name: editedData.full_name,
          overall_experience: editedData.overall_experience,
          role: editedData.professional_role,
          skills: editedData.skills,
          summary: editedData.summary,
        },
      }),
    )
      .then(() => {
        toast.success(toast_messages.edit_profile, {
          autoClose: 3000,
        });
        dispatch(
          CandidateResumeAddEditData({
            resume: {
              guid: CandidateUserTab1?.resume?.guid,
              summary: editedData.summary,
            },
          }),
        );

        // setToastData((prevToastData) => ({
        //   ...prevToastData,
        //   message: toast_messages.edit_profile,
        //   variant: "success",
        //   position: "top-right",
        //   open: true,
        // }));
        // setTimeout(() => {
        //   setToastData({});
        // }, 3000);
      })
      .catch((error) => {});
    setIsEditOpenProfileTab1(false);
  };
  const handleSaveChangesProfileTab2 = (editedData) => {
    // Implement the logic to save edited data
    setCandidateUserTabProfile2(editedData);
    dispatch(
      CandidateResumeProfileEdit({
        individual: {
          guid: editedData.guid,
          address: editedData.address,
          contact_number: editedData.contact_no,
          date_of_birth: editedData.date_of_birth,
          email_id: editedData.email_id,
          full_name: editedData.full_name,
          overall_experience: editedData.overall_experience,
          role: editedData.professional_role,
          skills: editedData.skills,
          summary: editedData.summary,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_profile, {
        autoClose: 3000,
      });
      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab2?.resume?.guid,
            summary: editedData.summary,
          },
        }),
      );
      // setToastData((prevToastData) => ({
      //   ...prevToastData,
      //   message: toast_messages.edit_profile,
      //   variant: "success",
      //   position: "top-right",
      //   open: true,
      // }));
      // setTimeout(() => {
      //   setToastData({});
      // }, 3000);
    });
    setIsEditOpenProfileTab2(false);
  };
  const handleSaveChangesProfileTab3 = (editedData) => {
    // Implement the logic to save edited data
    setCandidateUserTabProfile3(editedData);
    dispatch(
      CandidateResumeProfileEdit({
        individual: {
          guid: editedData.guid,
          address: editedData.address,
          contact_number: editedData.contact_no,
          date_of_birth: editedData.date_of_birth,
          email_id: editedData.email_id,
          full_name: editedData.full_name,
          overall_experience: editedData.overall_experience,
          role: editedData.professional_role,
          skills: editedData.skills,
          summary: editedData.summary,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_profile, {
        autoClose: 3000,
      });
      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab3?.resume?.guid,
            summary: editedData.summary,
          },
        }),
      );
      // setToastData((prevToastData) => ({
      //   ...prevToastData,
      //   message: toast_messages.edit_profile,
      //   variant: "success",
      //   position: "top-right",
      //   open: true,
      // }));
      // setTimeout(() => {
      //   setToastData({});
      // }, 3000);
    });
    setIsEditOpenProfileTab3(false);
  };
  const handleSaveChangesProfileTab4 = (editedData) => {
    log_debug("editeddata4905", editedData);
    // Implement the logic to save edited data
    setCandidateUserTabProfile4(editedData);
    dispatch(
      CandidateResumeProfileEdit({
        individual: {
          guid: editedData.guid,
          address: editedData.address,
          contact_number: editedData.contact_no,
          date_of_birth: editedData.date_of_birth,
          email_id: editedData.email_id,
          full_name: editedData.full_name,
          overall_experience: editedData.overall_experience,
          role: editedData.professional_role,
          skills: editedData.skills,
          summary: editedData.summary,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_profile, {
        autoClose: 3000,
      });
      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab4?.resume?.guid,
            summary: editedData.summary,
          },
        }),
      );
      // setToastData((prevToastData) => ({
      //   ...prevToastData,
      //   message: toast_messages.edit_profile,
      //   variant: "success",
      //   position: "top-right",
      //   open: true,
      // }));
      // setTimeout(() => {
      //   setToastData({});
      // }, 3000);
    });
    setIsEditOpenProfileTab4(false);
  };

  const handleSaveChangesWorkTab1 = (
    editedData,
    guid,
    editedDataskill,
    editedDatasummary,
  ) => {
    let workExpdata = {
      guid: CandidateUserTab1?.resume?.guid,
      workRoleTab1: editedData && editedData,
      // workSkillTab1: editedDataskill
      //   ? editedDataskill
      //   : CandidateUserTab1?.resume?.programming_languages,
      // summary: editedDatasummary,
    };
    dispatch(
      CandidateResumeAddEditData({
        resume: {
          guid: CandidateUserTab1?.resume?.guid,
          work_experiences: editedData,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_work_experience, {
        autoClose: 3000,
      });
      // setToastData((prevToastData) => ({
      //   ...prevToastData,
      //   message: toast_messages.edit_work_experience,
      //   variant: "success",
      //   position: "top-right",
      //   open: true,
      // }));
      // setTimeout(() => {
      //   setToastData({});
      // }, 3000);
    });
    setCandidateUserTabWorkExp1(workExpdata);
    setIsEditOpenWorkTab1(false);
  };

  const handleSaveChangesWorkTab2 = (
    editedData,
    guid,
    editedDataskill,
    editedDatasummary,
  ) => {
    let workExpdata = {
      guid: CandidateUserTab2?.resume?.guid,
      workRoleTab1: editedData && editedData,
      // workSkillTab1: editedDataskill
      //   ? editedDataskill
      //   : CandidateUserTab2?.resume?.programming_languages,
      // summary: editedDatasummary,
    };
    dispatch(
      CandidateResumeAddEditData({
        resume: {
          guid: CandidateUserTab2?.resume?.guid,
          work_experiences: editedData,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_work_experience, {
        autoClose: 3000,
      });
      // setToastData((prevToastData) => ({
      //   ...prevToastData,
      //   message: toast_messages.edit_work_experience,
      //   variant: "success",
      //   position: "top-right",
      //   open: true,
      // }));
      // setTimeout(() => {
      //   setToastData({});
      // }, 3000);
    });
    setCandidateUserTabWorkExp2(workExpdata);
    setIsEditOpenWorkTab2(false);
  };

  const handleSaveChangesWorkTab3 = (
    editedData,
    guid,
    editedDataskill,
    editedDatasummary,
  ) => {
    let workExpdata = {
      guid: CandidateUserTab3?.resume?.guid,
      workRoleTab1: editedData && editedData,
      // workSkillTab1: editedDataskill
      //   ? editedDataskill
      //   : CandidateUserTab3?.resume?.programming_languages,
      // summary: editedDatasummary,
    };
    dispatch(
      CandidateResumeAddEditData({
        resume: {
          guid: CandidateUserTab3?.resume?.guid,
          work_experiences: editedData,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_work_experience, {
        autoClose: 3000,
      });
    });
    setCandidateUserTabWorkExp3(workExpdata);
    setIsEditOpenWorkTab3(false);
  };

  const handleSaveChangesWorkTab4 = (
    editedData,
    guid,
    editedDataskill,
    editedDatasummary,
  ) => {
    let workExpdata = {
      guid: CandidateUserTab4?.resume?.guid,
      workRoleTab1: editedData && editedData,
      // workSkillTab1: editedDataskill
      //   ? editedDataskill
      //   : CandidateUserTab4?.resume?.programming_languages,
      // summary: editedDatasummary,
    };
    dispatch(
      CandidateResumeAddEditData({
        resume: {
          guid: CandidateUserTab4?.resume?.guid,
          work_experiences: editedData,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_work_experience, {
        autoClose: 3000,
      });
    });
    setCandidateUserTabWorkExp4(workExpdata);
    setIsEditOpenWorkTab4(false);
  };

  const handleSaveChangesEducationTab1 = (editedData) => {
    const education = {
      guid: CandidateUserTab1?.resume?.guid,
      collegeName: editedData && editedData,
    };
    dispatch(
      CandidateResumeAddEditData({
        resume: {
          guid: CandidateUserTab1?.resume?.guid,
          educational_qualifications: editedData && editedData,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_education, {
        autoClose: 3000,
      });
    });
    setCandidateUserEducationTab1(education);
    setIsEditOpenEducationTab1(false);
  };

  const handleSaveChangesEducationTab2 = (editedData) => {
    const education = {
      guid: CandidateUserTab2?.resume?.guid,
      collegeName: editedData && editedData,
    };
    dispatch(
      CandidateResumeAddEditData({
        resume: {
          guid: CandidateUserTab2?.resume?.guid,
          educational_qualifications: editedData && editedData,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_education, {
        autoClose: 3000,
      });
    });
    setCandidateUserEducationTab2(education);
    log_debug("educationafteredit", education);
    setIsEditOpenEducationTab2(false);
  };
  const handleSaveChangesEducationTab3 = (editedData) => {
    const education = {
      guid: CandidateUserTab3?.resume?.guid,
      collegeName: editedData && editedData,
    };
    dispatch(
      CandidateResumeAddEditData({
        resume: {
          guid: CandidateUserTab3?.resume?.guid,
          educational_qualifications: editedData && editedData,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_education, {
        autoClose: 3000,
      });
    });
    setCandidateUserEducationTab3(education);
    log_debug("educationafteredit", education);
    setIsEditOpenEducationTab3(false);
  };
  const handleSaveChangesEducationTab4 = (editedData) => {
    const education = {
      guid: CandidateUserTab4?.resume?.guid,
      collegeName: editedData && editedData,
    };
    dispatch(
      CandidateResumeAddEditData({
        resume: {
          guid: CandidateUserTab4?.resume?.guid,
          educational_qualifications: editedData && editedData,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_education, {
        autoClose: 3000,
      });
    });
    setCandidateUserEducationTab4(education);
    log_debug("educationafteredit", education);
    setIsEditOpenEducationTab4(false);
  };

  //Edit Certifications
  const handleSaveChangesCertifications = (editedData, tab) => {
    let resume_guid = "";
    if (tab === "tab1") {
      const certificate = {
        guid: CandidateUserTab1 && CandidateUserTab1?.guid,
        certifications: editedData && editedData,
      };
      resume_guid = CandidateUserTab1?.resume?.guid;
      // dispatch(
      //   CandidateResumeAddEditData({
      //     resume: {
      //       guid: CandidateUserTab1?.resume?.guid,
      //       certifications: editedData && editedData,
      //     },
      //   }),
      // );
      setCandidateUserCertification1(certificate);
      setIsEditOpenCertificationTab1(false);
    }
    if (tab === "tab2") {
      const certificate = {
        guid: CandidateUserTab2 && CandidateUserTab2?.guid,
        certifications: editedData && editedData,
      };
      resume_guid = CandidateUserTab2?.resume?.guid;
      setCandidateUserCertification2(certificate);
      setIsEditOpenCertificationTab2(false);
    }
    if (tab === "tab3") {
      const certificate = {
        guid: CandidateUserTab3 && CandidateUserTab3?.guid,
        certifications: editedData && editedData,
      };
      resume_guid = CandidateUserTab3?.resume?.guid;
      setCandidateUserCertification3(certificate);
      setIsEditOpenCertificationTab3(false);
    }
    if (tab === "tab4") {
      const certificate = {
        guid: CandidateUserTab4 && CandidateUserTab4?.guid,
        certifications: editedData && editedData,
      };
      resume_guid = CandidateUserTab3?.resume?.guid;
      setCandidateUserCertification4(certificate);
      setIsEditOpenCertificationTab4(false);
    }
    dispatch(
      CandidateResumeAddEditData({
        resume: {
          guid: resume_guid,
          certifications: editedData && editedData,
        },
      }),
    ).then(() => {
      toast.success(toast_messages.edit_certificate, {
        autoClose: 3000,
      });
    });
  };

  // ================delete operation start==================
  // const handleDeleteProfile = (UserInfo) => {
  //   log_debug("UserInfo",UserInfo)

  //   // Education Details
  //   let profileDelete = [];
  //   profileDelete = CandidateUserTabProfile1?.filter(
  //     (exp) => exp.guid !== UserInfo
  //   );
  //   const resumeData = {
  //     guid: CandidateUserTab1?.guid,
  //     full_name: profileDelete,
  //     date_of_birth: profileDelete,
  //     email_id: profileDelete,
  //     contact_no: profileDelete,
  //     // professional_role: profileDelete,
  //     // address: profileDelete,
  //     // summary: profileDelete,
  //     // overall_experience: ExperiencesTab1,
  //   };

  //   // const education1 = {
  //   //   guid: CandidateUserTab1 && CandidateUserTab1?.guid,
  //   //   collegeName: educationDelete,
  //   // };
  //   log_debug("CandidateUserTabProfile1resume",resumeData)

  //   setCandidateUserTabProfile1(resumeData);

  // };
  const handleDeleteWorkExp = (id, tab) => {
    // Education Details
    let resume_data = {};
    if (tab === "tab1") {
      let workExperienceDeleteTab1 = [];
      workExperienceDeleteTab1 = CandidateUserTabWorkExp1?.workRoleTab1?.filter(
        (exp) => exp._id !== id,
      );
      resume_data = {
        guid: CandidateUserTab1 && CandidateUserTab1?.guid,
        work_experiences: workExperienceDeleteTab1,
      };
      const workExpdata1 = {
        guid: CandidateUserTab1 && CandidateUserTab1?.guid,
        workRoleTab1: workExperienceDeleteTab1,
      };
      // dispatch(
      //   CandidateResumeAddEditData({
      //     resume: {
      //       guid: CandidateUserTab1?.resume?.guid,
      //       work_experiences: workExperienceDeleteTab1,
      //     },
      //   })
      // );
      setCandidateUserTabWorkExp1(workExpdata1);
    }
    // --------------------------------------------------------
    if (tab === "tab2") {
      let workExperienceDeleteTab2 = [];
      workExperienceDeleteTab2 = CandidateUserTabWorkExp2?.workRoleTab1?.filter(
        (exp) => exp._id !== id,
      );
      const workExpdata2 = {
        guid: CandidateUserTab2 && CandidateUserTab2?.guid,
        workRoleTab1: workExperienceDeleteTab2,
      };
      resume_data = {
        guid: CandidateUserTab1 && CandidateUserTab1?.guid,
        work_experiences: workExperienceDeleteTab2,
      };
      setCandidateUserTabWorkExp2(workExpdata2);
    }
    // --------------------------------------------------------
    if (tab === "tab3") {
      let workExperienceDeleteTab3 = [];
      workExperienceDeleteTab3 = CandidateUserTabWorkExp3?.workRoleTab1?.filter(
        (exp) => exp._id !== id,
      );
      const workExpdata3 = {
        guid: CandidateUserTab3 && CandidateUserTab3?.guid,
        workRoleTab1: workExperienceDeleteTab3,
      };
      resume_data = {
        guid: CandidateUserTab1 && CandidateUserTab1?.guid,
        work_experiences: workExperienceDeleteTab3,
      };
      setCandidateUserTabWorkExp3(workExpdata3);
    }
    // --------------------------------------------------
    if (tab === "tab4") {
      let workExperienceDeleteTab4 = [];
      workExperienceDeleteTab4 = CandidateUserTabWorkExp4?.workRoleTab1?.filter(
        (exp) => exp._id !== id,
      );
      const workExpdata4 = {
        guid: CandidateUserTab4 && CandidateUserTab4?.guid,
        workRoleTab1: workExperienceDeleteTab4,
      };
      resume_data = {
        guid: CandidateUserTab1 && CandidateUserTab1?.guid,
        work_experiences: workExperienceDeleteTab4,
      };
      setCandidateUserTabWorkExp4(workExpdata4);
    }
    // --------------------------------------------------
    dispatch(CandidateResumeAddEditData({ resume: resume_data })).then(() => {
      toast.success(toast_messages.delete_work_experience, {
        autoClose: 3000,
      });
    });
  };

  const handleDeleteEducation = (id, tab) => {
    // Education Details
    let resume_data = {};
    if (tab === "tab1") {
      let educationDelete = [];
      educationDelete = CandidateUserEducationTab1?.collegeName?.filter(
        (exp) => exp._id !== id,
      );
      const education1 = {
        guid: CandidateUserTab1 && CandidateUserTab1?.guid,
        collegeName: educationDelete,
      };
      resume_data = {
        guid: CandidateUserTab1?.resume?.guid,
        educational_qualifications: educationDelete,
      };
      setCandidateUserEducationTab1(education1);
    }
    // --------------------------------------------------------------------
    if (tab === "tab2") {
      let educationDelete2 = [];
      educationDelete2 = CandidateUserEducationTab2?.collegeName?.filter(
        (exp) => exp._id !== id,
      );
      const education2 = {
        guid: CandidateUserTab2 && CandidateUserTab2?.guid,
        collegeName: educationDelete2,
      };
      resume_data = {
        guid: CandidateUserTab2?.resume?.guid,
        educational_qualifications: educationDelete2,
      };
      setCandidateUserEducationTab2(education2);
    }
    // --------------------------------------------------------------------
    if (tab === "tab3") {
      let educationDelete3 = [];
      educationDelete3 = CandidateUserEducationTab3?.collegeName?.filter(
        (exp) => exp._id !== id,
      );
      const education3 = {
        guid: CandidateUserTab3 && CandidateUserTab3?.guid,
        collegeName: educationDelete3,
      };
      resume_data = {
        guid: CandidateUserTab3?.resume?.guid,
        educational_qualifications: educationDelete3,
      };
      setCandidateUserEducationTab3(education3);
    }
    // --------------------------------------------------------------------
    if (tab === "tab4") {
      let educationDelete4 = [];
      educationDelete4 = CandidateUserEducationTab4?.collegeName?.filter(
        (exp) => exp._id !== id,
      );
      const education4 = {
        guid: CandidateUserTab4 && CandidateUserTab4?.guid,
        collegeName: educationDelete4,
      };
      resume_data = {
        guid: CandidateUserTab4?.resume?.guid,
        educational_qualifications: educationDelete4,
      };
      setCandidateUserEducationTab4(education4);
    }

    dispatch(CandidateResumeAddEditData({ resume: resume_data })).then(() => {
      toast.success(toast_messages.delete_education, {
        autoClose: 3000,
      });
    });
  };

  const handleDeleteCertification = (id, tab) => {
    let resume_data = {};
    if (tab === "tab1") {
      let remainingCert = [];
      remainingCert = CandidateUserCertification1?.certifications?.filter(
        (cert) => cert._id !== id,
      );
      const certificate1 = {
        guid: CandidateUserTab1 && CandidateUserTab1?.guid,
        certifications: remainingCert,
      };
      resume_data = {
        guid: CandidateUserTab1?.resume?.guid,
        certifications: remainingCert,
      };
      setCandidateUserCertification1(certificate1);
    }
    if (tab === "tab2") {
      let remainingCert = [];
      remainingCert = CandidateUserCertification2?.certifications?.filter(
        (cert) => cert._id !== id,
      );
      const certificate2 = {
        guid: CandidateUserTab2 && CandidateUserTab2?.guid,
        certifications: remainingCert,
      };
      resume_data = {
        guid: CandidateUserTab2?.resume?.guid,
        certifications: remainingCert,
      };
      setCandidateUserCertification2(certificate2);
    }
    if (tab === "tab3") {
      let remainingCert = [];
      remainingCert = CandidateUserCertification3?.certifications?.filter(
        (cert) => cert._id !== id,
      );
      const certificate3 = {
        guid: CandidateUserTab3 && CandidateUserTab3?.guid,
        certifications: remainingCert,
      };
      resume_data = {
        guid: CandidateUserTab3?.resume?.guid,
        certifications: remainingCert,
      };
      setCandidateUserCertification3(certificate3);
    }
    if (tab === "tab4") {
      let remainingCert = [];
      remainingCert = CandidateUserCertification4?.certifications?.filter(
        (cert) => cert._id !== id,
      );
      const certificate4 = {
        guid: CandidateUserTab4 && CandidateUserTab4?.guid,
        certifications: remainingCert,
      };
      resume_data = {
        guid: CandidateUserTab4?.resume?.guid,
        certifications: remainingCert,
      };
      setCandidateUserCertification4(certificate4);
    }

    //send certificate data to the server
    dispatch(CandidateResumeAddEditData({ resume: resume_data })).then(() => {
      toast.success(toast_messages.delete_certificate, {
        autoClose: 3000,
      });
    });
  };

  // ================delete operation end==================

  // ================Add operation start==================

  const handleAddWorkExperienceTab1 = (data) => {
    setCandidateUserTabWorkExp1((prevData) => {
      const updatedData = {
        guid: CandidateUserTab1?.guid,
        workRoleTab1: [...prevData.workRoleTab1, data], // Add the new data to the existing array
      };
      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab1?.resume?.guid,
            work_experiences: updatedData.workRoleTab1,
          },
        }),
      ).then(() => {
        toast.success(toast_messages.add_work_experience, {
          autoClose: 3000,
        });
      });

      return updatedData;
    });
  };
  const handleAddWorkExperienceTab2 = (data) => {
    setCandidateUserTabWorkExp2((prevData) => {
      const updatedData = {
        guid: CandidateUserTab2?.guid,
        workRoleTab1: [...prevData.workRoleTab1, data], // Add the new data to the existing array
      };
      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab2?.resume?.guid,
            work_experiences: updatedData.workRoleTab1,
          },
        }),
      ).then(() => {
        toast.success(toast_messages.add_work_experience, {
          autoClose: 3000,
        });
      });
      return updatedData;
    });
  };
  const handleAddWorkExperienceTab3 = (data) => {
    setCandidateUserTabWorkExp3((prevData) => {
      const updatedData = {
        guid: CandidateUserTab3?.guid,
        workRoleTab1: [...prevData.workRoleTab1, data], // Add the new data to the existing array
      };
      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab3?.resume?.guid,
            work_experiences: updatedData.workRoleTab1,
          },
        }),
      ).then(() => {
        toast.success(toast_messages.add_work_experience, {
          autoClose: 3000,
        });
      });
      return updatedData;
    });
  };
  const handleAddWorkExperienceTab4 = (data) => {
    setCandidateUserTabWorkExp4((prevData) => {
      const updatedData = {
        guid: CandidateUserTab4?.guid,
        workRoleTab1: [...prevData.workRoleTab1, data], // Add the new data to the existing array
      };
      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab4?.resume?.guid,
            work_experiences: updatedData.workRoleTab1,
          },
        }),
      ).then(() => {
        toast.success(toast_messages.add_work_experience, {
          autoClose: 3000,
        });
      });
      return updatedData;
    });
  };

  const handleAddEducationDetailsTab1 = (data) => {
    setCandidateUserEducationTab1((prevData) => {
      const updatedData = {
        guid: CandidateUserTab1?.guid,
        collegeName: [...prevData?.collegeName, data], // Add the new data to the existing array
      };
      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab1?.resume?.guid,
            educational_qualifications: updatedData.collegeName,
          },
        }),
      ).then(() => {
        toast.success(toast_messages.add_education, {
          autoClose: 3000,
        });
      });
      return updatedData;
    });
  };
  const handleAddEducationDetailsTab2 = (data) => {
    setCandidateUserEducationTab2((prevData) => {
      const updatedData = {
        guid: CandidateUserTab2?.guid,
        collegeName: [...prevData?.collegeName, data], // Add the new data to the existing array
      };

      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab2?.resume?.guid,
            educational_qualifications: updatedData.collegeName,
          },
        }),
      ).then(() => {
        toast.success(toast_messages.add_education, {
          autoClose: 3000,
        });
      });
      return updatedData;
    });
  };
  const handleAddEducationDetailsTab3 = (data) => {
    setCandidateUserEducationTab3((prevData) => {
      const updatedData = {
        guid: CandidateUserTab3?.guid,
        collegeName: [...prevData?.collegeName, data], // Add the new data to the existing array
      };
      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab3?.resume?.guid,
            educational_qualifications: updatedData.collegeName,
          },
        }),
      ).then(() => {
        toast.success(toast_messages.add_education, {
          autoClose: 3000,
        });
      });
      return updatedData;
    });
  };
  const handleAddEducationDetailsTab4 = (data) => {
    setCandidateUserEducationTab4((prevData) => {
      const updatedData = {
        guid: CandidateUserTab4?.guid,
        collegeName: [...prevData?.collegeName, data], // Add the new data to the existing array
      };

      dispatch(
        CandidateResumeAddEditData({
          resume: {
            guid: CandidateUserTab4?.resume?.guid,
            educational_qualifications: updatedData.collegeName,
          },
        }),
      ).then(() => {
        toast.success(toast_messages.add_education, {
          autoClose: 3000,
        });
      });
      return updatedData;
    });
  };
  // ================Add operation end==================
  //Add certification
  const handleAddCertificationTabData = (data, tab) => {
    if (tab === "tab1") {
      setCandidateUserCertification1((prevData) => {
        const updatedData = {
          guid: CandidateUserTab1?.guid,
          certifications: [...prevData?.certifications, data], // Add the new data to the existing array
        };
        dispatch(
          CandidateResumeAddEditData({
            resume: {
              guid: CandidateUserTab1?.resume?.guid,
              certifications: updatedData.certifications,
            },
          }),
        ).then(() => {
          toast.success(toast_messages.add_certificate, {
            autoClose: 3000,
          });
        });
        return updatedData;
      });
    }
    if (tab === "tab2") {
      setCandidateUserCertification2((prevData) => {
        const updatedData = {
          guid: CandidateUserTab2?.guid,
          certifications: [...prevData?.certifications, data], // Add the new data to the existing array
        };
        dispatch(
          CandidateResumeAddEditData({
            resume: {
              guid: CandidateUserTab2?.resume?.guid,
              certifications: updatedData.certifications,
            },
          }),
        ).then(() => {
          toast.success(toast_messages.add_certificate, {
            autoClose: 3000,
          });
        });
        return updatedData;
      });
    }
    if (tab === "tab3") {
      setCandidateUserCertification3((prevData) => {
        const updatedData = {
          guid: CandidateUserTab3?.guid,
          certifications: [...prevData?.certifications, data], // Add the new data to the existing array
        };
        dispatch(
          CandidateResumeAddEditData({
            resume: {
              guid: CandidateUserTab3?.resume?.guid,
              certifications: updatedData.certifications,
            },
          }),
        ).then(() => {
          toast.success(toast_messages.add_certificate, {
            autoClose: 3000,
          });
        });
        return updatedData;
      });
    }
    if (tab === "tab4") {
      setCandidateUserCertification4((prevData) => {
        const updatedData = {
          guid: CandidateUserTab4?.guid,
          certifications: [...prevData?.certifications, data], // Add the new data to the existing array
        };
        dispatch(
          CandidateResumeAddEditData({
            resume: {
              guid: CandidateUserTab4?.resume?.guid,
              certifications: updatedData.certifications,
            },
          }),
        ).then(() => {
          toast.success(toast_messages.add_certificate, {
            autoClose: 3000,
          });
        });
        return updatedData;
      });
    }
  };

  const breadcrumbValues = ["Candidates", "Candidate Details"];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="row col-lg-12">
            <div className="col-lg-12">
              <CustomBreadcrumb values={breadcrumbValues} />
            </div>
          </div>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    {resumelength >= 1 &&
                      individualResumes[0]?.data?.data?.resumes[0]
                        ?.individual && (
                        <div className="col-lg-3">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="fas fa-home"></i>
                              </span>

                              {resumelength >= 1 && (
                                <span
                                  className="d-none d-sm-block"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#5156BE",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {CandidateUserTab1?.full_name}
                                </span>
                              )}
                            </NavLink>
                          </NavItem>
                        </div>
                      )}
                    {resumelength >= 2 &&
                      individualResumes[1]?.data?.data?.resumes[0]
                        ?.individual && (
                        <div className="col-lg-3">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2");
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-user"></i>
                              </span>

                              {resumelength >= 2 && (
                                <span
                                  className="d-none d-sm-block"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#5156BE",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {CandidateUserTab2?.full_name}
                                </span>
                              )}
                            </NavLink>
                          </NavItem>
                        </div>
                      )}
                    {resumelength >= 3 &&
                      individualResumes[2]?.data?.data?.resumes[0]
                        ?.individual && (
                        <div className="col-lg-3">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "3",
                              })}
                              onClick={() => {
                                toggleCustom("3");
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-envelope"></i>
                              </span>

                              {resumelength >= 3 && (
                                <span
                                  className="d-none d-sm-block"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#5156BE",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {CandidateUserTab3?.full_name}
                                </span>
                              )}
                            </NavLink>
                          </NavItem>
                        </div>
                      )}
                    {resumelength == 4 &&
                      individualResumes[3]?.data?.data?.resumes[0]
                        ?.individual && (
                        <div className="col-lg-3">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "4",
                              })}
                              onClick={() => {
                                toggleCustom("4");
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="fas fa-cog"></i>
                              </span>
                              {resumelength >= 4 && (
                                <span
                                  className="d-none d-sm-block"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#5156BE",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {CandidateUserTab4?.full_name}
                                </span>
                              )}
                            </NavLink>
                          </NavItem>
                        </div>
                      )}
                  </Nav>
                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <div id="progrss-wizard" className="twitter-bs-wizard">
                          <Row>
                            <Col lg={1}>
                              <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                <Nav vertical className="form-vertical">
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab1 === "1",
                                      })}
                                      onClick={() => {
                                        toggleVerticaltab1("1");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="resume"
                                      >
                                        <i className="mdi mdi-file-account-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="resume"
                                        >
                                          Resume
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab1 === "2",
                                      })}
                                      onClick={() => {
                                        toggleVerticaltab1("2");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="ProfileDetails"
                                      >
                                        <i className="mdi mdi-badge-account-horizontal-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="ProfileDetails"
                                        >
                                          Profile Details
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab1 === "3",
                                      })}
                                      onClick={() => {
                                        toggleVerticaltab1("3");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="WorkExperience"
                                      >
                                        <i className="mdi mdi-briefcase-account-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="WorkExperience"
                                        >
                                          Work Experience
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab1 === "4",
                                      })}
                                      onClick={() => {
                                        toggleVerticaltab1("4");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="Education"
                                      >
                                        <i className="mdi-book-education-outline mdi font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Education"
                                        >
                                          Education
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab1 === "5",
                                      })}
                                      onClick={() => {
                                        toggleVerticaltab1("5");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="Certifications"
                                      >
                                        <i className="mdi mdi-certificate-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Certifications"
                                        >
                                          Certifications
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </ul>
                            </Col>
                            <Col lg={11} className="mt-3">
                              <TabContent
                                activeTab={verticalActiveTab1}
                                className="twitter-bs-wizard-tab-content ms-4 pt-0"
                              >
                                <TabPane tabId="1">
                                  <div className="d-flex justify-content-between ">
                                    <h5 className="font-size-16 text-primary">
                                      Resume:
                                    </h5>
                                  </div>
                                  <Row className="mt-2">
                                    <Col className="col-lg-12">
                                      {/* <SingleResumeUpload /> */}
                                      <h3>Coming Soon...</h3>
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                  <Row>
                                    <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                      <Row className="col-lg-12">
                                        <Col className="col-lg-6 col-md-12">
                                          <h5 className="font-size-16 mt-2 text-primary">
                                            Profile Details :
                                          </h5>
                                        </Col>
                                        <Col className="col-lg-6 col-md-12 text-end">
                                          <Button
                                            color="primary"
                                            type="button"
                                            className="text-white my-2 rounded"
                                            onClick={(e) => {
                                              handleEditProfileTab1(e);
                                            }}
                                          >
                                            <FiEdit className="mx-1" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <Row className="col-lg-12">
                                        <Col md={12}>
                                          <CandidateDetailsCard
                                            data={
                                              CandidateUserTabProfile1 &&
                                              CandidateUserTabProfile1
                                            }
                                            // handleDeleteProfile={
                                            //   handleDeleteProfile
                                            // }
                                          />
                                        </Col>
                                        {IsEditOpenProfileTab1 && (
                                          <CandidateDetailsProfilesCanvas
                                            isOpen={IsEditOpenProfileTab1}
                                            toggle={() =>
                                              setIsEditOpenProfileTab1(
                                                !IsEditOpenProfileTab1,
                                              )
                                            }
                                            handleSave={
                                              handleSaveChangesProfileTab1
                                            }
                                            data={
                                              CandidateUserTabProfile1 &&
                                              CandidateUserTabProfile1
                                            }
                                          />
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                  <Row>
                                    <Col className="col-lg-12">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Work Experience:
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end p-0">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2 mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddWorkTab1(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              disabled={
                                                CandidateUserTabWorkExp1
                                                  ?.workRoleTab1?.length === 0
                                              }
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditWorkTab1(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <Col md={12}>
                                        <CandidateDetailsWorkExperience
                                          data={
                                            CandidateUserTabWorkExp1 &&
                                            CandidateUserTabWorkExp1
                                          }
                                          handleDeleteWorkExp={(id) =>
                                            handleDeleteWorkExp(id, "tab1")
                                          }
                                        />
                                      </Col>
                                      {IsAddOpenWorkTab1 && (
                                        <AddCandidateDetailsWorkExperience
                                          handleSave={
                                            handleAddWorkExperienceTab1
                                          }
                                          isOpen={IsAddOpenWorkTab1}
                                          toggle={() =>
                                            setIsAddOpenWorkTab1(
                                              !IsAddOpenWorkTab1,
                                            )
                                          }
                                        />
                                      )}
                                      {/* Gandu ka code */}
                                      {IsEditOpenWorkTab1 && (
                                        <CandidateDetailsWorkExperienceCanvas
                                          isOpen={IsEditOpenWorkTab1}
                                          data={
                                            CandidateUserTabWorkExp1 &&
                                            CandidateUserTabWorkExp1
                                          }
                                          // editedData={editedData}
                                          handleSave={(editedData) =>
                                            handleSaveChangesWorkTab1(
                                              editedData,
                                            )
                                          }
                                          // formData={editedData}
                                          toggle={() =>
                                            setIsEditOpenWorkTab1(
                                              !IsEditOpenWorkTab1,
                                            )
                                          }
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                  <Row>
                                    <Col className="col-lg-12">
                                      <div className="d-flex justify-content-between  align-items-center">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Education Details :
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddEducationTab1(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              disabled={
                                                CandidateUserEducationTab1?.collegeName ||
                                                CandidateUserEducationTab1
                                                  ?.collegeName?.length === 0
                                              }
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditEducationTab1(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <Row className="col-lg-12">
                                        <Col md={12}>
                                          <CandidateDetailsEducation
                                            data={
                                              CandidateUserEducationTab1
                                                ? CandidateUserEducationTab1
                                                : []
                                            }
                                            handleDeleteEducation={(id) =>
                                              handleDeleteEducation(id, "tab1")
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      {IsAddOpenEducationTab1 && (
                                        <AddCandidateDetailsEducation
                                          handleSave={
                                            handleAddEducationDetailsTab1
                                          }
                                          isOpen={IsAddOpenEducationTab1}
                                          toggle={() =>
                                            setIsAddOpenEducationTab1(
                                              !IsAddOpenEducationTab1,
                                            )
                                          }
                                        />
                                      )}
                                      {IsEditOpenEducationTab1 && (
                                        <CandidateDetailsEducationCanvas
                                          isOpen={IsEditOpenEducationTab1}
                                          data={
                                            CandidateUserEducationTab1 &&
                                            CandidateUserEducationTab1
                                          }
                                          // editedData={editedData}
                                          handleSave={(editedData) =>
                                            handleSaveChangesEducationTab1(
                                              editedData,
                                            )
                                          }
                                          // formData={editedData}
                                          toggle={() =>
                                            setIsEditOpenEducationTab1(
                                              !IsEditOpenEducationTab1,
                                            )
                                          }
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="5">
                                  <Row>
                                    <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                      <Row className="col-lg-12">
                                        <Col className="col-lg-6 col-md-12">
                                          <h5 className="font-size-16 mt-2 text-primary">
                                            Certification :
                                          </h5>
                                        </Col>
                                        <Col className="col-lg-6 col-md-12 text-end">
                                          <Button
                                            color="success"
                                            type="button"
                                            className="text-white my-2  mx-1 rounded"
                                            onClick={(e) => {
                                              handleAddCertificationTab1(e);
                                            }}
                                          >
                                            <FiUserPlus className="mx-1" />
                                            Add
                                          </Button>
                                          <Button
                                            color="primary"
                                            type="button"
                                            disabled={
                                              CandidateUserCertification1
                                                ?.certifications?.length === 0
                                            }
                                            className="text-white my-2 rounded"
                                            onClick={(e) => {
                                              handleEditCertificationTab1(e);
                                            }}
                                          >
                                            <FiEdit className="mx-1" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Row>

                                  <Row>
                                    <Col className="col-lg-12">
                                      <Row className="col-lg-12">
                                        <Col md={12}>
                                          {
                                            <CandidateDetailsCertificate
                                              data={
                                                CandidateUserCertification1
                                                  ? CandidateUserCertification1
                                                  : []
                                              }
                                              handleDeleteCertification={(id) =>
                                                handleDeleteCertification(
                                                  id,
                                                  "tab1",
                                                )
                                              }
                                              // onEdit={handleEditEducation}
                                              // onDelete={handleDelete}
                                            />
                                          }
                                        </Col>
                                        {IsAddOpenCertificationTab1 && (
                                          <AddCandidateDetailsCertification
                                            handleSave={(data) =>
                                              handleAddCertificationTabData(
                                                data,
                                                "tab1",
                                              )
                                            }
                                            isOpen={IsAddOpenCertificationTab1}
                                            toggle={() =>
                                              setIsAddOpenCertificationTab1(
                                                !IsAddOpenCertificationTab1,
                                              )
                                            }
                                          />
                                        )}
                                        {IsEditOpenCertificationTab1 && (
                                          <CandidateDetailsCertificateCanvas
                                            isOpen={IsEditOpenCertificationTab1}
                                            data={
                                              CandidateUserCertification1 &&
                                              CandidateUserCertification1
                                            }
                                            // editedData={editedData}
                                            handleSave={(editedData) =>
                                              handleSaveChangesCertifications(
                                                editedData,
                                                "tab1",
                                              )
                                            }
                                            // formData={editedData}
                                            toggle={() =>
                                              setIsEditOpenCertificationTab1(
                                                !IsEditOpenCertificationTab1,
                                              )
                                            }
                                          />
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </TabPane>

                    <TabPane tabId="2">
                      <Row>
                        <div id="progrss-wizard" className="twitter-bs-wizard">
                          <Row>
                            <Col lg={1}>
                              <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                <Nav vertical className="form-vertical">
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab2 === "1",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab2("1");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="resume"
                                      >
                                        <i className="mdi mdi-file-account-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="resume"
                                        >
                                          Resume
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab2 === "2",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab2("2");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="ProfileDetails"
                                      >
                                        <i className="mdi mdi-badge-account-horizontal-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="ProfileDetails"
                                        >
                                          Profile Details
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab2 === "3",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab2("3");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="WorkExperience"
                                      >
                                        <i className="mdi mdi-briefcase-account-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="WorkExperience"
                                        >
                                          Work Experience
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab2 === "4",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab2("4");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="Education"
                                      >
                                        <i className="mdi-book-education-outline mdi font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Education"
                                        >
                                          Education
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab2 === "5",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab2("5");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="Certifications"
                                      >
                                        <i className="mdi mdi-certificate-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Certifications"
                                        >
                                          Certifications
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </ul>
                            </Col>
                            <Col lg={11} className="mt-3">
                              <TabContent
                                activeTab={verticalActiveTab2}
                                className="twitter-bs-wizard-tab-content ms-4 pt-0"
                              >
                                <TabPane tabId="1">
                                  <div className="d-flex justify-content-between ">
                                    <h5 className="font-size-16 text-primary">
                                      Resume:
                                    </h5>
                                  </div>
                                  <Row className="mt-2">
                                    <Col className="col-lg-12">
                                      {/* <SingleResumeUpload /> */}
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                  <Row>
                                    <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                      <Row className="col-lg-12">
                                        <Col className="col-lg-6 col-md-12">
                                          <h5 className="font-size-16 mt-2 text-primary">
                                            Profile Details :
                                          </h5>
                                        </Col>
                                        <Col className="col-lg-6 col-md-12 text-end">
                                          <Button
                                            color="primary"
                                            type="button"
                                            className="text-white my-2 mx-4 rounded"
                                            onClick={(e) => {
                                              handleEditProfileTab2(e);
                                            }}
                                          >
                                            <FiEdit className="mx-1" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Row>
                                  <Row className="col-lg-12">
                                    <Col md={12}>
                                      <CandidateDetailsCard
                                        data={
                                          CandidateUserTabProfile2 &&
                                          CandidateUserTabProfile2
                                        }
                                      />
                                    </Col>
                                    {IsEditOpenProfileTab2 && (
                                      <CandidateDetailsProfilesCanvas
                                        isOpen={IsEditOpenProfileTab2}
                                        toggle={() =>
                                          setIsEditOpenProfileTab2(
                                            !IsEditOpenProfileTab2,
                                          )
                                        }
                                        handleSave={
                                          handleSaveChangesProfileTab2
                                        }
                                        data={
                                          CandidateUserTabProfile2 &&
                                          CandidateUserTabProfile2
                                        }
                                      />
                                    )}
                                  </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                  <Row>
                                    <Col className="col-lg-12">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Work Experience:
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddWorkTab2(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditWorkTab2(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <Col md={12}>
                                        <CandidateDetailsWorkExperience
                                          data={
                                            CandidateUserTabWorkExp2 &&
                                            CandidateUserTabWorkExp2
                                          }
                                          handleDeleteWorkExp={(id) =>
                                            handleDeleteWorkExp(id, "tab2")
                                          }
                                        />
                                      </Col>
                                    </Col>
                                    {IsAddOpenWorkTab2 && (
                                      <AddCandidateDetailsWorkExperience
                                        isOpen={IsAddOpenWorkTab2}
                                        handleSave={handleAddWorkExperienceTab2}
                                        toggle={() =>
                                          setIsAddOpenWorkTab2(
                                            !IsAddOpenWorkTab2,
                                          )
                                        }
                                      />
                                    )}
                                    {IsEditOpenWorkTab2 && (
                                      <CandidateDetailsWorkExperienceCanvas
                                        isOpen={IsEditOpenWorkTab2}
                                        data={
                                          CandidateUserTabWorkExp2 &&
                                          CandidateUserTabWorkExp2
                                        }
                                        // editedData={editedData}
                                        handleSave={(editedData) =>
                                          handleSaveChangesWorkTab2(editedData)
                                        }
                                        // formData={editedData}
                                        toggle={() =>
                                          setIsEditOpenWorkTab2(
                                            !IsEditOpenWorkTab2,
                                          )
                                        }
                                      />
                                    )}
                                  </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                  <Row>
                                    <Col className="col-lg-12">
                                      <div className="d-flex justify-content-between  align-items-center">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Education Details :
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddEducationTab2(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditEducationTab2(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="col-lg-12">
                                    <Col md={12}>
                                      <CandidateDetailsEducation
                                        data={
                                          CandidateUserEducationTab2 &&
                                          CandidateUserEducationTab2
                                        }
                                        handleDeleteEducation={(id) =>
                                          handleDeleteEducation(id, "tab2")
                                        }
                                      />
                                    </Col>
                                    {IsAddOpenEducationTab2 && (
                                      <AddCandidateDetailsEducation
                                        handleSave={
                                          handleAddEducationDetailsTab2
                                        }
                                        isOpen={IsAddOpenEducationTab2}
                                        toggle={() =>
                                          setIsAddOpenEducationTab2(
                                            !IsAddOpenEducationTab2,
                                          )
                                        }
                                      />
                                    )}
                                    {IsEditOpenEducationTab2 && (
                                      <CandidateDetailsEducationCanvas
                                        isOpen={IsEditOpenEducationTab2}
                                        data={
                                          CandidateUserEducationTab2 &&
                                          CandidateUserEducationTab2
                                        }
                                        // editedData={editedData}
                                        handleSave={(editedData) =>
                                          handleSaveChangesEducationTab2(
                                            editedData,
                                          )
                                        }
                                        // formData={editedData}
                                        toggle={() =>
                                          setIsEditOpenEducationTab2(
                                            !IsEditOpenEducationTab2,
                                          )
                                        }
                                      />
                                    )}
                                  </Row>
                                </TabPane>
                                <TabPane tabId="5">
                                  <Row>
                                    <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                      <Row className="col-lg-12">
                                        <Col className="col-lg-6 col-md-12">
                                          <h5 className="font-size-16 mt-2 text-primary">
                                            Certification :
                                          </h5>
                                        </Col>
                                        <Col className="col-lg-6 col-md-12 text-end">
                                          <Button
                                            color="success"
                                            type="button"
                                            className="text-white my-2  mx-1 rounded"
                                            onClick={(e) => {
                                              handleAddCertificationTab2(e);
                                            }}
                                          >
                                            <FiUserPlus className="mx-1" />
                                            Add
                                          </Button>
                                          <Button
                                            color="primary"
                                            type="button"
                                            className="text-white my-2 rounded"
                                            onClick={(e) => {
                                              handleEditCertificationTab2(e);
                                            }}
                                          >
                                            <FiEdit className="mx-1" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Row>

                                  <Row>
                                    <Col className="col-lg-12">
                                      <Row className="col-lg-12">
                                        <Col md={12}>
                                          <CandidateDetailsCertificate
                                            data={
                                              CandidateUserCertification2
                                                ? CandidateUserCertification2
                                                : []
                                            }
                                            handleDeleteCertification={(id) =>
                                              handleDeleteCertification(
                                                id,
                                                "tab2",
                                              )
                                            }
                                          />
                                        </Col>
                                        {IsAddOpenCertificationTab2 && (
                                          <AddCandidateDetailsCertification
                                            handleSave={(data) =>
                                              handleAddCertificationTabData(
                                                data,
                                                "tab2",
                                              )
                                            }
                                            isOpen={IsAddOpenCertificationTab2}
                                            toggle={() =>
                                              setIsAddOpenCertificationTab2(
                                                !IsAddOpenCertificationTab2,
                                              )
                                            }
                                          />
                                        )}
                                        {IsEditOpenCertificationTab2 && (
                                          <CandidateDetailsCertificateCanvas
                                            isOpen={IsEditOpenCertificationTab2}
                                            data={
                                              CandidateUserCertification2 &&
                                              CandidateUserCertification2
                                            }
                                            // editedData={editedData}
                                            handleSave={(editedData) =>
                                              handleSaveChangesCertifications(
                                                editedData,
                                                "tab2",
                                              )
                                            }
                                            // formData={editedData}
                                            toggle={() =>
                                              setIsEditOpenCertificationTab2(
                                                !IsEditOpenCertificationTab2,
                                              )
                                            }
                                          />
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </TabPane>

                    <TabPane tabId="3">
                      <Row>
                        <div id="progrss-wizard" className="twitter-bs-wizard">
                          <Row>
                            <Col lg={1}>
                              <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                <Nav vertical className="form-vertical">
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab3 === "1",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab3("1");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="resume"
                                      >
                                        <i className="mdi mdi-file-account-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="resume"
                                        >
                                          Resume
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab3 === "2",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab3("2");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="ProfileDetails"
                                      >
                                        <i className="mdi mdi-badge-account-horizontal-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="ProfileDetails"
                                        >
                                          Profile Details
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab3 === "3",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab3("3");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="WorkExperience"
                                      >
                                        <i className="mdi mdi-briefcase-account-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="WorkExperience"
                                        >
                                          Work Experience
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab3 === "4",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab3("4");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="Education"
                                      >
                                        <i className="mdi-book-education-outline mdi font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Education"
                                        >
                                          Education
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab3 === "5",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab3("5");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="Certifications"
                                      >
                                        <i className="mdi mdi-certificate-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Certifications"
                                        >
                                          Certifications
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </ul>
                            </Col>
                            <Col lg={11} className="mt-3">
                              <TabContent
                                activeTab={verticalActiveTab3}
                                className="twitter-bs-wizard-tab-content ms-4 pt-0"
                              >
                                <TabPane tabId="1">
                                  <div className="d-flex justify-content-between ">
                                    <h5 className="font-size-16 text-primary">
                                      Resume:
                                    </h5>
                                  </div>
                                  <Row className="mt-2">
                                    <Col className="col-lg-12">
                                      {/* <SingleResumeUpload /> */}
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                  <Row>
                                    <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                      <Row className="col-lg-12">
                                        <Col className="col-lg-6 col-md-12">
                                          <h5 className="font-size-16 mt-2 text-primary">
                                            Profile Details :
                                          </h5>
                                        </Col>
                                        <Col className="col-lg-6 col-md-12 text-end">
                                          <Button
                                            color="primary"
                                            type="button"
                                            className="text-white my-2 mx-4 rounded"
                                            onClick={(e) => {
                                              handleEditProfileTab3(e);
                                            }}
                                          >
                                            <FiEdit className="mx-1" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Row>

                                  <Row className="col-lg-12">
                                    <Col md={12}>
                                      <CandidateDetailsCard
                                        data={
                                          CandidateUserTabProfile3 &&
                                          CandidateUserTabProfile3
                                        }
                                        //  address={formattedAddress}
                                        summary=""
                                        onEdit={handleEditpersonal}
                                        onDelete={handleDelete}
                                      />
                                    </Col>
                                    {IsEditOpenProfileTab3 && (
                                      <CandidateDetailsProfilesCanvas
                                        isOpen={IsEditOpenProfileTab3}
                                        toggle={() =>
                                          setIsEditOpenProfileTab3(
                                            !IsEditOpenProfileTab3,
                                          )
                                        }
                                        handleSave={
                                          handleSaveChangesProfileTab3
                                        }
                                        data={
                                          CandidateUserTabProfile3 &&
                                          CandidateUserTabProfile3
                                        }
                                      />
                                    )}
                                  </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                  <Row>
                                    <Col className="col-lg-12">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Work Experience:
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddWorkTab3(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditWorkTab3(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <Col md={12}>
                                        <CandidateDetailsWorkExperience
                                          data={
                                            CandidateUserTabWorkExp3 &&
                                            CandidateUserTabWorkExp3
                                          }
                                          handleDeleteWorkExp={(id) =>
                                            handleDeleteWorkExp(id, "tab3")
                                          }
                                        />
                                      </Col>
                                      {IsAddOpenWorkTab3 && (
                                        <AddCandidateDetailsWorkExperience
                                          handleSave={
                                            handleAddWorkExperienceTab3
                                          }
                                          isOpen={IsAddOpenWorkTab3}
                                          toggle={() =>
                                            setIsAddOpenWorkTab3(
                                              !IsAddOpenWorkTab3,
                                            )
                                          }
                                        />
                                      )}
                                      {IsEditOpenWorkTab3 && (
                                        <CandidateDetailsWorkExperienceCanvas
                                          isOpen={IsEditOpenWorkTab3}
                                          data={
                                            CandidateUserTabWorkExp3 &&
                                            CandidateUserTabWorkExp3
                                          }
                                          // editedData={editedData}
                                          handleSave={(editedData) =>
                                            handleSaveChangesWorkTab3(
                                              editedData,
                                            )
                                          }
                                          // formData={editedData}
                                          toggle={() =>
                                            setIsEditOpenWorkTab3(
                                              !IsEditOpenWorkTab3,
                                            )
                                          }
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                  <Row>
                                    <Col className="col-lg-12">
                                      <div className="d-flex justify-content-between  align-items-center">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Education Details :
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddEducationTab3(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditEducationTab3(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="col-lg-12">
                                    <Col md={12}>
                                      <CandidateDetailsEducation
                                        data={
                                          CandidateUserEducationTab3 &&
                                          CandidateUserEducationTab3
                                        }
                                        handleDeleteEducation={(id) =>
                                          handleDeleteEducation(id, "tab3")
                                        }
                                      />
                                    </Col>
                                    {IsAddOpenEducationTab3 && (
                                      <AddCandidateDetailsEducation
                                        handleSave={
                                          handleAddEducationDetailsTab3
                                        }
                                        isOpen={IsAddOpenEducationTab3}
                                        toggle={() =>
                                          setIsAddOpenEducationTab3(
                                            !IsAddOpenEducationTab3,
                                          )
                                        }
                                      />
                                    )}
                                    {IsEditOpenEducationTab3 && (
                                      <CandidateDetailsEducationCanvas
                                        isOpen={IsEditOpenEducationTab3}
                                        data={
                                          CandidateUserEducationTab3 &&
                                          CandidateUserEducationTab3
                                        }
                                        // editedData={editedData}
                                        handleSave={(editedData) =>
                                          handleSaveChangesEducationTab3(
                                            editedData,
                                          )
                                        }
                                        // formData={editedData}
                                        toggle={() =>
                                          setIsEditOpenEducationTab3(
                                            !IsEditOpenEducationTab3,
                                          )
                                        }
                                      />
                                    )}
                                  </Row>
                                </TabPane>
                                <TabPane tabId="5">
                                  <Row>
                                    <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                      <Row className="col-lg-12">
                                        <Col className="col-lg-6 col-md-12">
                                          <h5 className="font-size-16 mt-2 text-primary">
                                            Certification :
                                          </h5>
                                        </Col>
                                        <Col className="col-lg-6 col-md-12 text-end">
                                          <Button
                                            color="success"
                                            type="button"
                                            className="text-white my-2  mx-1 rounded"
                                            onClick={(e) => {
                                              handleAddCertificationTab3(e);
                                            }}
                                          >
                                            <FiUserPlus className="mx-1" />
                                            Add
                                          </Button>
                                          <Button
                                            color="primary"
                                            type="button"
                                            className="text-white my-2 rounded"
                                            onClick={(e) => {
                                              handleEditCertificationTab3(e);
                                            }}
                                          >
                                            <FiEdit className="mx-1" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <Row className="col-lg-12">
                                        <Col md={12}>
                                          <CandidateDetailsCertificate
                                            data={
                                              CandidateUserCertification3
                                                ? CandidateUserCertification3
                                                : []
                                            }
                                            handleDeleteCertification={(id) =>
                                              handleDeleteCertification(
                                                id,
                                                "tab3",
                                              )
                                            }
                                          />
                                        </Col>
                                        {IsAddOpenCertificationTab3 && (
                                          <AddCandidateDetailsCertification
                                            handleSave={(data) =>
                                              handleAddCertificationTabData(
                                                data,
                                                "tab3",
                                              )
                                            }
                                            isOpen={IsAddOpenCertificationTab3}
                                            toggle={() =>
                                              setIsAddOpenCertificationTab3(
                                                !IsAddOpenCertificationTab3,
                                              )
                                            }
                                          />
                                        )}
                                        {IsEditOpenCertificationTab1 && (
                                          <CandidateDetailsCertificateCanvas
                                            isOpen={IsEditOpenCertificationTab3}
                                            data={
                                              CandidateUserCertification3 &&
                                              CandidateUserCertification3
                                            }
                                            // editedData={editedData}
                                            handleSave={(editedData) =>
                                              handleSaveChangesCertifications(
                                                editedData,
                                                "tab3",
                                              )
                                            }
                                            // formData={editedData}
                                            toggle={() =>
                                              setIsEditOpenCertificationTab3(
                                                !IsEditOpenCertificationTab3,
                                              )
                                            }
                                          />
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </TabPane>

                    <TabPane tabId="4">
                      <Row>
                        <div id="progrss-wizard" className="twitter-bs-wizard">
                          <Row>
                            <Col lg={1}>
                              <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                <Nav vertical className="form-vertical">
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab4 === "1",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab4("1");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="resume"
                                      >
                                        <i className="mdi mdi-file-account-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="resume"
                                        >
                                          Resume
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab4 === "2",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab4("2");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="ProfileDetails"
                                      >
                                        <i className="mdi mdi-badge-account-horizontal-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="ProfileDetails"
                                        >
                                          Profile Details
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab4 === "3",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab4("3");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="WorkExperience"
                                      >
                                        <i className="mdi mdi-briefcase-account-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="WorkExperience"
                                        >
                                          Work Experience
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab4 === "4",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab4("4");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="Education"
                                      >
                                        <i className="mdi-book-education-outline mdi font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Education"
                                        >
                                          Education
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab4 === "5",
                                      })}
                                      onClick={() => {
                                        toggleVerticalTab4("5");
                                      }}
                                    >
                                      <div
                                        className="step-icon"
                                        data-bs-toggle="tooltip"
                                        id="Certifications"
                                      >
                                        <i className="mdi mdi-certificate-outline font-size-24"></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Certifications"
                                        >
                                          Certifications
                                        </UncontrolledTooltip>
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </ul>
                            </Col>
                            <Col lg={11} className="mt-3">
                              <TabContent
                                activeTab={verticalActiveTab4}
                                className="twitter-bs-wizard-tab-content ms-4 pt-0"
                              >
                                <TabPane tabId="1">
                                  <div className="d-flex justify-content-between ">
                                    <h5 className="font-size-16 text-primary">
                                      Resume:
                                    </h5>
                                  </div>
                                  <Row className="mt-2">
                                    <Col className="col-lg-12">
                                      {/* <SingleResumeUpload /> */}
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                  <Row>
                                    <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                      <Row className="col-lg-12">
                                        <Col className="col-lg-6 col-md-12">
                                          <h5 className="font-size-16 mt-2 text-primary">
                                            Profile Details :
                                          </h5>
                                        </Col>
                                        <Col className="col-lg-6 col-md-12 text-end">
                                          <Button
                                            color="primary"
                                            type="button"
                                            className="text-white my-2 mx-4 rounded"
                                            onClick={(e) => {
                                              handleEditProfileTab4(e);
                                            }}
                                          >
                                            <FiEdit className="mx-1" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Row>
                                  <Row className="col-lg-12">
                                    <Col md={12}>
                                      <CandidateDetailsCard
                                        data={
                                          CandidateUserTabProfile4 &&
                                          CandidateUserTabProfile4
                                        }
                                      />
                                    </Col>
                                    {IsEditOpenProfileTab4 && (
                                      <CandidateDetailsProfilesCanvas
                                        isOpen={IsEditOpenProfileTab4}
                                        toggle={() =>
                                          setIsEditOpenProfileTab4(
                                            !IsEditOpenProfileTab4,
                                          )
                                        }
                                        handleSave={
                                          handleSaveChangesProfileTab4
                                        }
                                        data={
                                          CandidateUserTabProfile4 &&
                                          CandidateUserTabProfile4
                                        }
                                      />
                                    )}
                                  </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                  <Row>
                                    <Col className="col-lg-12">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Work Experience:
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddWorkTab4(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditWorkTab4(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <Col md={12}>
                                        <CandidateDetailsWorkExperience
                                          data={
                                            CandidateUserTabWorkExp4 &&
                                            CandidateUserTabWorkExp4
                                          }
                                          handleDeleteWorkExp={(id) =>
                                            handleDeleteWorkExp(id, "tab4")
                                          }
                                        />
                                      </Col>
                                      {IsAddOpenWorkTab4 && (
                                        <AddCandidateDetailsWorkExperience
                                          handleSave={
                                            handleAddWorkExperienceTab4
                                          }
                                          isOpen={IsAddOpenWorkTab4}
                                          toggle={() =>
                                            setIsAddOpenWorkTab4(
                                              !IsAddOpenWorkTab4,
                                            )
                                          }
                                        />
                                      )}
                                      {IsEditOpenWorkTab4 && (
                                        <CandidateDetailsWorkExperienceCanvas
                                          isOpen={IsEditOpenWorkTab4}
                                          data={
                                            CandidateUserTabWorkExp4 &&
                                            CandidateUserTabWorkExp4
                                          }
                                          // editedData={editedData}
                                          handleSave={(editedData) =>
                                            handleSaveChangesWorkTab4(
                                              editedData,
                                            )
                                          }
                                          // formData={editedData}
                                          toggle={() =>
                                            setIsEditOpenWorkTab4(
                                              !IsEditOpenWorkTab4,
                                            )
                                          }
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                  <Row>
                                    <Col className="col-lg-12">
                                      <div className="d-flex justify-content-between  align-items-center">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Education Details :
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddEducationTab4(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditEducationTab4(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="col-lg-12">
                                    <Col md={12}>
                                      <CandidateDetailsEducation
                                        data={
                                          CandidateUserEducationTab4 &&
                                          CandidateUserEducationTab4
                                        }
                                        handleDeleteEducation={(id) =>
                                          handleDeleteEducation(id, "tab4")
                                        }
                                      />
                                    </Col>
                                    {IsAddOpenEducationTab4 && (
                                      <AddCandidateDetailsEducation
                                        handleSave={
                                          handleAddEducationDetailsTab4
                                        }
                                        isOpen={IsAddOpenEducationTab4}
                                        toggle={() =>
                                          setIsAddOpenEducationTab4(
                                            !IsAddOpenEducationTab4,
                                          )
                                        }
                                      />
                                    )}
                                    {IsEditOpenEducationTab4 && (
                                      <CandidateDetailsEducationCanvas
                                        isOpen={IsEditOpenEducationTab4}
                                        data={
                                          CandidateUserEducationTab4 &&
                                          CandidateUserEducationTab4
                                        }
                                        // editedData={editedData}
                                        handleSave={(editedData) =>
                                          handleSaveChangesEducationTab4(
                                            editedData,
                                          )
                                        }
                                        // formData={editedData}
                                        toggle={() =>
                                          setIsEditOpenEducationTab4(
                                            !IsEditOpenEducationTab4,
                                          )
                                        }
                                      />
                                    )}
                                  </Row>
                                </TabPane>
                                <TabPane tabId="5">
                                  <Row>
                                    <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                      <Row className="col-lg-12">
                                        <Col className="col-lg-6 col-md-12">
                                          <h5 className="font-size-16 mt-2 text-primary">
                                            Certification :
                                          </h5>
                                        </Col>
                                        <Col className="col-lg-6 col-md-12 text-end">
                                          <Button
                                            color="success"
                                            type="button"
                                            className="text-white my-2  mx-1 rounded"
                                            onClick={(e) => {
                                              handleAddCertificationTab4(e);
                                            }}
                                          >
                                            <FiUserPlus className="mx-1" />
                                            Add
                                          </Button>
                                          <Button
                                            color="primary"
                                            type="button"
                                            className="text-white my-2 rounded"
                                            onClick={(e) => {
                                              handleEditCertificationTab4(e);
                                            }}
                                          >
                                            <FiEdit className="mx-1" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <Row className="col-lg-12">
                                        <Col md={12}>
                                          <CandidateDetailsCertificate
                                            data={
                                              CandidateUserCertification4
                                                ? CandidateUserCertification4
                                                : []
                                            }
                                            handleDeleteCertification={(id) =>
                                              handleDeleteCertification(
                                                id,
                                                "tab4",
                                              )
                                            }
                                          />
                                        </Col>
                                        {IsAddOpenCertificationTab4 && (
                                          <AddCandidateDetailsCertification
                                            handleSave={(data) =>
                                              handleAddCertificationTabData(
                                                data,
                                                "tab4",
                                              )
                                            }
                                            isOpen={IsAddOpenCertificationTab4}
                                            toggle={() =>
                                              setIsAddOpenCertificationTab4(
                                                !IsAddOpenCertificationTab4,
                                              )
                                            }
                                          />
                                        )}
                                        {IsEditOpenCertificationTab4 && (
                                          <CandidateDetailsCertificateCanvas
                                            isOpen={IsEditOpenCertificationTab4}
                                            data={
                                              CandidateUserCertification4 &&
                                              CandidateUserCertification4
                                            }
                                            // editedData={editedData}
                                            handleSave={(editedData) =>
                                              handleSaveChangesCertifications(
                                                editedData,
                                                "tab4",
                                              )
                                            }
                                            // formData={editedData}
                                            toggle={() =>
                                              setIsEditOpenCertificationTab4(
                                                !IsEditOpenCertificationTab4,
                                              )
                                            }
                                          />
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {toastData.open && (
        <Toast
          message={toastData?.message}
          type={toastData?.variant}
          position={toastData?.position}
          isOpen={toastData?.open}
        />
      )}
    </React.Fragment>
  );
}

export default NewCandidateResumeDetails;

import React, { useEffect, useState } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import upload from "../../src/assets/images/companyBenchImage/icons8-invoice.png";
import { Iguid, Pguid } from "../apiConfig/axiosDev";
import { getPartnerGuid } from "../apiConfig/globalvariables";

import {
  CreateInvoice,
  createInvoiceCandidatedropdown,
  createInvoiceEmail,
} from "./PartnerSlice";
import { useDispatch, useSelector } from "react-redux";
import { log_debug } from "../apiHelpers/logger";
import { formatDate } from "../helpers/date";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddInvoice = () => {
  const breadcrumbValues = ["Invoice", "Add Invoice"];
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const [EmailId, setEmailId] = useState("");
  const [namefield, setname] = useState([]);
  log_debug("EmailId", EmailId);
  const [
    createInvoicecandidateropdowndata,
    setcreateInvoicecandidateropdowndata,
  ] = useState([]);
  log_debug(
    "createInvoicecandidateropdowndata",
    createInvoicecandidateropdowndata,
  );

  const [AutoFieldData, setAutoFieldData] = useState([]);
  log_debug("AutoFieldData", AutoFieldData);
  const BillingOption = ["manually", "monthly", "quarterly", "annual"];
  const [billingCycleinfo, setbillingCycleinfo] = useState([]);
  const [billingCycle, setBillingCycle] = useState("notselected");
  const [daysdata, setDaysdata] = useState("");
  const [subtotal, setsubtotal] = useState(0);
  const [gst, setgst] = useState(0);
  const [total, settotal] = useState(0);
  const navigate = useNavigate();

  const [isDaysEditable, setIsDaysEditable] = useState(true); // Set to true initially
  // let totalAmountvalue = (
  //   AutoFieldData ? (AutoFieldData?.contract_value * daysdata * 0.18 +
  //   AutoFieldData?.contract_value * daysdata) : (subtotal * 1 * 0.18 +
  //     subtotal * 1)
  // ).toFixed(2);
  //  const sub_total_data = AutoFieldData
  //    ? (AutoFieldData?.contract_value * daysdata)
  //    : (subtotal * 1);
  // let Gst_data = AutoFieldData
  //   ? (AutoFieldData?.contract_value * daysdata * 0.18).toFixed(2)
  //   : (subtotal * 1 * 0.18).toFixed(2);
  let start_date_data = AutoFieldData
    ? formatDate(AutoFieldData?.started_at)
    : "";
  let end_date_data = AutoFieldData ? formatDate(AutoFieldData?.ended_at) : "";
  let rate_data = AutoFieldData ? AutoFieldData?.contract_value : "";
  // Assuming AutoFieldData.started_at is your date

  // const [invoicedatefield, setinvoicedatefield] = useState([])
  // const [rolefield, setrolefield] = useState([])
  // const [startdatefield, setstartdatefield] = useState([])
  // const [enddatefield, setenddatefield] = useState([])
  // const [duedatefield, setduedatefield] = useState([])
  // const [amountfield, setamountfield] = useState([])
  // const [billingCyclefield, setbillingCyclefield] = useState([])
  // const [termsfield, settermsfield] = useState([])
  // const [ratefields, setratefields] = useState([])
  // const [streetfields, setstreetfields] = useState([])
  // const [cityfield, setcityfield] = useState([])
  // const [countryfield, setcountryfield] = useState([])

  const onSubmit = (values, { resetForm }) => {
    const rate = parseFloat(values.rate);
    dispatch(
      CreateInvoice({
        invoice: {
          partner_guid: getPartnerGuid() || Pguid,
          email_from: values.email_from,
          email_to: values.email_to,
          invoice_date: values.Invoice_date,
          due_date: values.due_date,
          terms: values.terms,
          name: namefield.full_name,
          email: namefield.email,
          role: AutoFieldData && AutoFieldData?.individuals?.designation,
          start_date: start_date_data,
          end_date: end_date_data,
          rate: rate_data,
          billing_cycle: billingCycle,
          billing_days: daysdata,
          sub_total: subtotal,
          gst: gst,
          amount: total,
        },
      }),
    );
    resetForm();
    toast.success("Invoice created successfully");
    setTimeout(() => {
      if (AutoFieldData) {
        navigate("/invoice");
      }
    }, 2000);
  };
  useEffect(() => {
    const userDataString = localStorage.getItem("userdata");
    const userData = JSON.parse(userDataString);
    const emailinfo = userData.email_id;
    if (emailinfo) {
      setEmailId(emailinfo);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email_from: EmailId && EmailId,
      email_to: "contact@techilaservices.com",
      Invoice_date: "",
      due_date: "",
      terms: "",
      name: "",
      email: "",
      role: "",
      start_date: "",
      end_date: "",
      rate: "",
      billing_cycle: "",
      billing_days: "",
      sub_total: "",
      gst: "",
      amount: "",
    },
    validationSchema: Yup.object({
      // candidateName: Yup.string().required("Required"),
      // email: Yup.string().email("Invalid email address").required("Required"),
      // Invoice_date: Yup.string().required("Required"),
      // start_date: Yup.string().required("Required"),
      // end_date: Yup.string().required("Required"),
      // role: Yup.string().required("Required"),
      // billing_cycle: Yup.string().required("Required"),
      // terms: Yup.string().required("Required"),
      // due_date: Yup.string().required("Required"),
      // amount: Yup.string().required("Required"),
      // rate: Yup.string().required("Required"),
      // street: Yup.string().required("Required"),
      // city: Yup.string().required("Required"),
      // country: Yup.string().required("Required"),
      // days: Yup.number()
      //   .integer("Please enter a whole number") // Ensure value is an integer
      //   .required("Required") // Make the field required
      //   .min(1, "Minimum value is 1") // Set minimum value
      //   .max(365, "Maximum value is 365"), // Set maximum value
    }),
    onSubmit,
  });

  useEffect(() => {
    dispatch(createInvoiceCandidatedropdown());
  }, []);

  useEffect(() => {
    log_debug("candidateNamecandidateName", formik.values.candidateName);
    dispatch(
      createInvoiceEmail({
        Iguiddata: namefield.guid,
      }),
    );
  }, [namefield]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore?.data?.createInvoiceCandidatedropdowndata?.success === true
      ) {
        setcreateInvoicecandidateropdowndata(
          partnerStore?.data?.createInvoiceCandidatedropdowndata?.data
            ?.individuals,
        );
      }
      if (partnerStore?.data?.createInvoiceEmaildata?.success === true) {
        setAutoFieldData(
          partnerStore?.data?.createInvoiceEmaildata?.data.individuals[0],
        );
      }
    }
  }, [partnerStore]);

  useEffect(() => {
    setsubtotal(AutoFieldData ? AutoFieldData?.contract_value : 0);
  }, [AutoFieldData]);

  useEffect(() => {
    setsubtotal(AutoFieldData ? AutoFieldData?.contract_value * daysdata : 0);
  }, [daysdata]);

  const handleBillingCycleChange = (e) => {
    const selectedBillingCycle = e.target.value;
    setBillingCycle(selectedBillingCycle);

    // Update days based on selected billing cycle
    switch (selectedBillingCycle) {
      // case "manually":
      //   setDaysdata(1);
      //   break;
      case "monthly":
        setDaysdata(30);
        break;
      case "quarterly":
        setDaysdata(90);
        break;
      case "annual":
        setDaysdata(365);
        break;
      default:
        setDaysdata(""); // Clear days if none selected
        break;
    }
    // Enable days field if billing cycle is selected
    setIsDaysEditable(true);
  };

  const handleDaysChange = (e) => {
    // Update days value
    setDaysdata(e.target.value);
  };

  const handleSubTotalChange = (e) => {
    setsubtotal(e.target.value);
    // setgst(e.target.value*0.18.toFixed(2));
    // settotal(parseFloat(e.target.value)+parseFloat(e.target.value*0.18.toFixed(2)));
  };
  useEffect(() => {
    setgst((subtotal * 0.18).toFixed(2));
    settotal(parseFloat(subtotal) + parseFloat(gst));
  }, [subtotal, gst]);

  // const calculateTotalAmount = () => {
  //   // const rate = AutoFieldData?.contract_value;
  //   // const days = daysdata;
  //   // const gstPercentage = formik.values.gstPercentage;

  //   // if (rate && days && gstPercentage) {
  //   //   const gstAmount = (rate * gstPercentage) / 100;
  //   //   const totalAmount = rate * days + gstAmount;
  //   //   formik.setFieldValue("amount", totalAmount.toFixed(2));
  //   // }
  //   const total = parseFloat(subtotal);
  //   const totalgst = gst;
  //   log_debug("type of total", typeof(total))
  //   return total+gst;
  // };

  // useEffect(() => {
  //   calculateTotalAmount();
  // }, [AutoFieldData?.contract_value, daysdata, formik.values.gstPercentage,subtotal]);

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div>
              <div className="col-lg-12">
                <CustomBreadcrumb values={breadcrumbValues} />
                <Col className="col-lg-12 col-md-12 col-sm-12 ">
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card
                        style={{
                          width: "100%",
                          height: "auto",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <CardHeader className="text-center bg-primary-subtle font-size-22">
                          <img
                            src={upload}
                            style={{ width: "60px" }}
                            alt="Dropzone Image"
                          />{" "}
                          <strong className="text-white">Create Invoice</strong>
                        </CardHeader>
                        <CardBody>
                          <form onSubmit={formik.handleSubmit}>
                            {/* <p className="pb-1">
                              <strong>To -</strong>
                            </p> */}

                            <Row className="border-bottom pb-2">
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="email_from">Email From</label>
                                <input
                                  id="email_from"
                                  name="email_from"
                                  type="email"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={EmailId}
                                  disabled
                                />
                                {formik.touched.email_from &&
                                formik.errors.email_from ? (
                                  <div className="text-danger">
                                    {formik.errors.email_from}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="email_to">Email To</label>
                                <input
                                  id="email_to"
                                  name="email_to"
                                  type="email"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"contact@techilaservices.com"}
                                  disabled
                                />
                                {formik.touched.email_to &&
                                formik.errors.email_to ? (
                                  <div className="text-danger">
                                    {formik.errors.email_to}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="Invoice_date">
                                  Invoice Date
                                </label>
                                <input
                                  id="Invoice_date"
                                  name="Invoice_date"
                                  type="date"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.Invoice_date}
                                />
                                {formik.touched.Invoice_date &&
                                formik.errors.Invoice_date ? (
                                  <div className="text-danger">
                                    {formik.errors.Invoice_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="due_date">Due date</label>
                                <input
                                  id="due_date"
                                  name="due_date"
                                  type="date"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.due_date}
                                />
                                {formik.touched.due_date &&
                                formik.errors.due_date ? (
                                  <div className="text-danger">
                                    {formik.errors.due_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="terms">Terms</label>
                                <input
                                  id="terms"
                                  name="terms"
                                  type="text"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.terms}
                                />
                                {formik.touched.terms && formik.errors.terms ? (
                                  <div className="text-danger">
                                    {formik.errors.terms}
                                  </div>
                                ) : null}
                              </div>

                              {/* <div className="form-group col-4 mb-2">
                                <label htmlFor="address_line_to_one">
                                  Address line 1
                                </label>
                                <input
                                  id="address_line_to_one"
                                  name="address_line_to_one"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"Unit No 402, Summer Court"}
                                  disabled
                                />
                                {formik.touched.address_line_to_one &&
                                formik.errors.address_line_to_one ? (
                                  <div className="text-danger">
                                    {formik.errors.address_line_to_one}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="address_line_to_two">
                                  Address line 2
                                </label>
                                <input
                                  id="address_line_to_two"
                                  name="address_line_to_two"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"Magarpatta City, Hadapsar"}
                                  disabled
                                />
                                {formik.touched.address_line_to_two &&
                                formik.errors.address_line_to_two ? (
                                  <div className="text-danger">
                                    {formik.errors.address_line_to_two}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="city_to">City</label>
                                <input
                                  id="city_to"
                                  name="city_to"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"Pune"}
                                  disabled
                                />
                                {formik.touched.city_to && formik.errors.city_to ? (
                                  <div className="text-danger">
                                    {formik.errors.city_to}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="state_to">State</label>
                                <input
                                  id="state_to"
                                  name="state_to"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"Maharashtra"}
                                  disabled
                                />
                                {formik.touched.state_to && formik.errors.state_to ? (
                                  <div className="text-danger">
                                    {formik.errors.state_to}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="country_to">Country</label>
                                <input
                                  id="country_to"
                                  name="country_to"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"India"}
                                  disabled
                                />
                                {formik.touched.country_to &&
                                formik.errors.country_to ? (
                                  <div className="text-danger">
                                    {formik.errors.country_to}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="zip_code_to">zip</label>
                                <input
                                  id="zip_code_to"
                                  name="zip_code_to"
                                  type="number"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"411013"}
                                  disabled
                                />
                                {formik.touched.zip_code_to &&
                                formik.errors.zip_code_to ? (
                                  <div className="text-danger">
                                    {formik.errors.zip_code_to}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4">
                                <label htmlFor="gst_number_from">GST Number</label>
                                <input
                                  id="gst_number_from"
                                  name="gst_number_from"
                                  type="text" // GST number includes alphabets and numbers
                                  className="form-control bg-light "
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"27AAFCT7266F1Z2"}
                                  disabled
                                />
                                {formik.touched.gst_number_from &&
                                formik.errors.gst_number_from ? (
                                  <div className="text-danger">
                                    {formik.errors.gst_number_from}
                                  </div>
                                ) : null}
                              </div> */}
                            </Row>
                            {/* <p className="pb-1">
                              <strong>From -</strong>
                            </p>
                            <Row className="border-bottom pb-2">
                            
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="address_line_from_one">
                                  Address line 1
                                </label>
                                <input
                                  id="address_line_from_one"
                                  name="address_line_from_one"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"247/4, keshav nagar"}
                                  disabled
                                />
                                {formik.touched.address_line_from_one &&
                                formik.errors.address_line_from_one ? (
                                  <div className="text-danger">
                                    {formik.errors.address_line_from_one}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="address_line_from_two">
                                  Address line 2
                                </label>
                                <input
                                  id="address_line_from_two"
                                  name="address_line_from_two"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"Magarpatta City, Hadapsar"}
                                  disabled
                                />
                                {formik.touched.address_line_from_two &&
                                formik.errors.address_line_from_two ? (
                                  <div className="text-danger">
                                    {formik.errors.address_line_from_two}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="city_from">City</label>
                                <input
                                  id="city_from"
                                  name="city_from"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"Pune"}
                                  disabled
                                />
                                {formik.touched.city_from && formik.errors.city_from ? (
                                  <div className="text-danger">
                                    {formik.errors.city_from}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="state_from">State</label>
                                <input
                                  id="state_from"
                                  name="state_from"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"Maharashtra"}
                                  disabled
                                />
                                {formik.touched.state_from && formik.errors.state_from ? (
                                  <div className="text-danger">
                                    {formik.errors.state_from}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="country_from">Country</label>
                                <input
                                  id="country_from"
                                  name="country_from"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"India"}
                                  disabled
                                />
                                {formik.touched.country_from &&
                                formik.errors.country_from ? (
                                  <div className="text-danger">
                                    {formik.errors.country_from}
                                  </div>
                                ) : null}
                              </div>

                              <div className="form-group col-4 mb-2">
                                <label htmlFor="zip_code_from">zip</label>
                                <input
                                  id="zip_code_from"
                                  name="zip_code_from"
                                  type="number"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"411013"}
                                  disabled
                                />
                                {formik.touched.zip_code_from &&
                                formik.errors.zip_code_from ? (
                                  <div className="text-danger">
                                    {formik.errors.zip_code_from}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="gst_number_from">GST Number</label>
                                <input
                                  id="gst_number_from"
                                  name="gst_number_from"
                                  type="text" // GST number includes alphabets and numbers
                                  className="form-control bg-light "
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={""}
                                  disabled
                                />
                                {formik.touched.gst_number_from &&
                                formik.errors.gst_number_from ? (
                                  <div className="text-danger">
                                    {formik.errors.gst_number_from}
                                  </div>
                                ) : null}
                              </div>
                            </Row> */}
                            <p className="pb-1">
                              <strong>Candidate details -</strong>
                            </p>
                            <Row className="border-bottom pb-2">
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="candidateName">Name</label>
                                <select
                                  type="select"
                                  required
                                  name="candidateName"
                                  className="form-control "
                                  onBlur={formik.handleBlur}
                                  value={namefield.full_name}
                                  onChange={(e) => {
                                    const selectedOption =
                                      createInvoicecandidateropdowndata.find(
                                        (item) =>
                                          item.full_name === e.target.value,
                                      );
                                    log_debug("selectedOption", selectedOption);
                                    setname({
                                      full_name: selectedOption?.full_name,
                                      guid: selectedOption?.guid,
                                      email: selectedOption.email,
                                      // role: selectedOption
                                      //   ? selectedOption.role
                                      //   : "",
                                    });
                                  }}
                                >
                                  <option value="" hidden>
                                    select candidate
                                  </option>
                                  {createInvoicecandidateropdowndata.map(
                                    (item) => (
                                      <option
                                        key={item.id}
                                        value={item.full_name}
                                      >
                                        {item.full_name}
                                      </option>
                                    ),
                                  )}
                                </select>
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="email">Email</label>
                                <input
                                  id="email"
                                  name="email"
                                  type="email"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={namefield.email}
                                  disabled
                                />
                                {/* {formik.touched.email && formik.errors.email ? (
                                  <div className="text-danger">
                                    {formik.errors.email}
                                  </div>
                                ) : null} */}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="role">Role</label>
                                <input
                                  id="role"
                                  name="role"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={
                                    AutoFieldData
                                      ? AutoFieldData?.individuals?.designation
                                      : ""
                                  }
                                  disabled
                                />
                                {formik.touched.role && formik.errors.role ? (
                                  <div className="text-danger">
                                    {formik.errors.role}
                                  </div>
                                ) : null}
                              </div>
                            </Row>
                            <p className="pb-1">
                              <strong>Contract Details -</strong>
                            </p>
                            <Row className="border-bottom pb-2">
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="start_date">Start Date</label>
                                <input
                                  id="start_date"
                                  name="start_date"
                                  type="test"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={start_date_data}
                                  disabled
                                />
                                {formik.touched.start_date &&
                                formik.errors.start_date ? (
                                  <div className="text-danger">
                                    {formik.errors.start_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="end_date">End Date</label>
                                <input
                                  id="end_date"
                                  name="end_date"
                                  type="test"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={end_date_data}
                                  disabled
                                />
                                {formik.touched.end_date &&
                                formik.errors.end_date ? (
                                  <div className="text-danger">
                                    {formik.errors.end_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="rate">Rate</label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-currency-inr"></i>
                                  </span>

                                  <input
                                    id="rate"
                                    name="rate"
                                    type="text"
                                    className="form-control bg-light"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={rate_data}
                                    disabled
                                  />
                                </div>

                                {formik.touched.rate && formik.errors.rate ? (
                                  <div className="text-danger">
                                    {formik.errors.rate}
                                  </div>
                                ) : null}
                              </div>

                              <div className="form-group col-4 mb-2">
                                <label htmlFor="billing_cycle">
                                  Billing Cycle
                                </label>
                                <select
                                  type="select"
                                  name="billing_cycle"
                                  //required
                                  className="form-control"
                                  value={billingCycle}
                                  onChange={handleBillingCycleChange}
                                >
                                  <option value="" hidden>
                                    Select Billing Cycle
                                  </option>
                                  {BillingOption.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="days">Billing Days</label>
                                <input
                                  id="days"
                                  name="days"
                                  type="number"
                                  min="1" // Minimum number of days
                                  max="365" // Maximum number of days
                                  step="1"
                                  className="form-control"
                                  onChange={handleDaysChange} // Use handleDaysChange to update days value
                                  value={daysdata}
                                  disabled={
                                    billingCycle == "monthly" ||
                                    billingCycle == "quarterly" ||
                                    billingCycle == "annual" ||
                                    billingCycle == "notselected"
                                  } // Disable if billingCycle has a value
                                />
                                {formik.touched.days && formik.errors.days ? (
                                  <div className="text-danger">
                                    {formik.errors.days}
                                  </div>
                                ) : null}
                              </div>
                              {/* <div className="form-group col-4 mb-2">
                                <label htmlFor="sub_total">Sub-Total</label>
                                <input
                                  id="sub_total"
                                  name="sub_total"
                                  type="number"
                                  min="1"
                                  max="365"
                                  step="1"
                                  className="form-control bg-light"
                                  onChange={""} // Use handleDaysChange to update days value
                                  value={AutoFieldData?.contract_value * daysdata}
                                  disabled
                                />
                                {formik.touched.sub_total &&
                                formik.errors.sub_total ? (
                                  <div className="text-danger">
                                    {formik.errors.sub_total}
                                  </div>
                                ) : null}
                              </div> */}
                            </Row>
                            <p className="pb-1">
                              <strong>Amount Details -</strong>
                            </p>
                            <Row className=" pb-2">
                              {/* <div className="form-group col-4 mb-2">
                                <label htmlFor="gstPercentage">GST 18%</label>
                                <select
                                  id="gstPercentage"
                                  name="gstPercentage"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.gstPercentage}
                                >
                                  <option value="" hidden>
                                    Select GST Percentage
                                  </option>
                                  <option value="5">5</option>
                                  <option value="12">12</option>
                                  <option value="18">18</option>
                                  <option value="28">28</option>
                                </select>
                                {formik.touched.gstPercentage &&
                                formik.errors.gstPercentage ? (
                                  <div className="text-danger">
                                    {formik.errors.gstPercentage}
                                  </div>
                                ) : null}
                              </div> */}
                              <Row>
                                <div className="form-group row mb-2">
                                  <label
                                    htmlFor="sub_total"
                                    className="col-2 col-form-label"
                                  >
                                    Sub-total
                                  </label>
                                  <div className="col-sm-6">
                                    <div className="input-group">
                                      <span className="input-group-text">
                                        <i className="mdi mdi-currency-inr"></i>
                                      </span>
                                      <input
                                        id="sub_total"
                                        name="sub_total"
                                        type="text"
                                        className="form-control bg-light"
                                        onChange={handleSubTotalChange}
                                        onBlur={formik.handleBlur}
                                        value={subtotal} // Add .toFixed(2) to format the number to 2 decimal places
                                      />
                                    </div>
                                    {formik.touched.sub_total &&
                                    formik.errors.sub_total ? (
                                      <div className="text-danger">
                                        {formik.errors.sub_total}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Row>

                              <Row>
                                <div className="form-group row mb-2">
                                  <label
                                    htmlFor="gst"
                                    className="col-2 col-form-label"
                                  >
                                    IGST18 (18%){" "}
                                  </label>
                                  <div className="col-sm-6">
                                    <div className="input-group">
                                      <span className="input-group-text">
                                        <i className="mdi mdi-currency-inr"></i>
                                      </span>
                                      <input
                                        id="gst"
                                        name="gst"
                                        type="text"
                                        className="form-control bg-light"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={gst}
                                        disabled
                                      />
                                    </div>
                                    {formik.touched.gst && formik.errors.gst ? (
                                      <div className="text-danger">
                                        {formik.errors.gst}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                <div className="row">
                                  <div className="col-2 mb-2">
                                    <label htmlFor="amount">Total</label>
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <div className="input-group">
                                      <span className="input-group-text">
                                        <i className="mdi mdi-currency-inr"></i>
                                      </span>
                                      <input
                                        id="amount"
                                        name="amount"
                                        type="number"
                                        className="form-control bg-light"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={total}
                                        disabled
                                      />
                                    </div>
                                    {formik.touched.amount &&
                                    formik.errors.amount ? (
                                      <div className="text-danger">
                                        {formik.errors.amount}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Row>
                            </Row>
                            <div className="text-center">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default AddInvoice;

import React, { useEffect, useState } from "react";
import joblogo from "../../assets/images/companyBenchImage/jobgray1.jpg";

import classnames from "classnames";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardHeader,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Badge,
} from "reactstrap";
//Import Breadcrumb

import { Link } from "react-router-dom";

import "./canvas.css";

// Custom Components
import AssessmentInviteForm from "../form/AssessmentInviteForm";
import TIQcard from "./offcanvasComponents/TIQcard";
import TimelineComponent from "../timelineComponents/TimeLineComponent";
import { useDispatch, useSelector } from "react-redux";
import { or_unique } from "../../helpers/utils";
import { IndividualDetail, JobDetail } from "../../Partner/PartnerSlice";
import { log_debug } from "../../apiHelpers/logger";
import FunnelProgressBar from "../../components/Common/FunnelProgressBar";
import CanvasJobDescription from "./CanvasJobdescription";
import { CandidatureHistory } from "../../Client/ClientSlice";
import JobOverview from "./JobOverviewCanvas";

function CanvasInterview({
  userType,
  menuOption,
  isOpen,
  onClose,
  toggle,
  data,
  width,
}) {
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const clientStore = useSelector((state) => state.clientSlice);
  log_debug("datadatadata", data);
  const [candidatureHistory, setCandidatureHistory] = useState([]);
  const [individualDetail, setIndividualDetail] = useState({});
  const [jobDetail, setJobDetail] = useState({});
  const [isJobCanvas, setIsJobCanvas] = useState(false);
  const [headerTab, setHeaderTab] = useState("1");
  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  const offcanvasStyle = {
    width: `${width}%`,
    padding: "15px",
  };

  const getInitials = (fullName) => {
    return fullName
      ?.split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  };

  // useEffect(() => {
  //   dispatch(interviewHistory(data.individual_guid));
  // }, []);
  useEffect(() => {
    log_debug("recieved data", data?.individualjobmapping_guid);
    dispatch(IndividualDetail(data?.individual_guid));
    dispatch(CandidatureHistory(data?.individualjobmapping_guid));
    dispatch(JobDetail(data?.jobdescription_guid));
  }, [data]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore?.data?.IndividualDetail?.success === true) {
        setIndividualDetail(
          partnerStore?.data?.IndividualDetail?.data?.individual,
        );
      }
      if (partnerStore?.data?.CandidatureHistory?.success === true) {
        setCandidatureHistory(
          partnerStore?.data?.CandidatureHistory?.data?.individual_history,
        );
      }
      // if (partnerStore?.data?.CandidatureHistory?.success === true) {
      //   setCandidatureHistory(
      //     partnerStore?.data?.CandidatureHistory?.data?.individual_history,
      //   );
      // }

      if (partnerStore?.data?.JobDetail?.success === true) {
        setJobDetail(partnerStore?.data?.JobDetail?.data?.jobdescription);
      }
    }
  }, [partnerStore]);

  useEffect(() => {
    if (clientStore.status === "idle") {
      if (clientStore.data.CandidatureHistory?.success === true) {
        setCandidatureHistory(
          clientStore.data.CandidatureHistory?.data?.individual_history,
        );
      }
    }
  }, [clientStore]);

  const handleJob = (job) => {
    setJobDetail(job);
    setIsJobCanvas(true);
  };

  const closeJobCanvas = () => {
    setIsJobCanvas(false);
  };

  return (
    // <>
    //   <Offcanvas
    //     direction="end"
    //     isOpen={isOpen}
    //     className="custom-offcanvas"
    //     contentClassName="custom-offcanvas-content"
    //     onClose={onClose}
    //     style={offcanvasStyle}
    //   >
    //     <OffcanvasHeader className="border-bottom" toggle={toggle}>
    //       {/* <p className="text-light">Candidate Details</p> */}
    //       <h5 className="text-primary">
    //         Interview Details&nbsp;&nbsp;
    //         <span className="font-size-12 text-muted mt-2">
    //           <i className="fas fa-info-circle" />
    //           Interview details displayed. Detailed information about interview
    //           for selected Job.
    //         </span>
    //       </h5>
    //     </OffcanvasHeader>
    //     <OffcanvasBody>
    //       {/* TIQ score card to display based on scenario */}
    //       <Card className="col-md-6">
    //         <div className="row p-2">
    //           <div className="row">
    //             <div className="col-md-12">
    //               <div className="d-flex">
    //                 <div className="flex-grow-1 my-2">
    //                   <h5 className="font-size-16 mb-1 w-100">
    //                    {userType === "individual" ? (data.title):(data.jobdescriptions.title)}
    //                     <Badge
    //                       color={
    //                         data.status === "selected"
    //                           ? "success-subtle text-success"
    //                           : "warning-subtle text-warning"
    //                       }
    //                       className="p-1 m-1 font-size-12 float-end"
    //                     >
    //                       {data.status}
    //                     </Badge>
    //                   </h5>
    //                   <h6>
    //                     <small className="text-muted">
    //                       <strong>Type: </strong>
    //                       {data&&data.type.toUpperCase()}
    //                     </small>
    //                   </h6>
    //                   <h6>
    //                     <small className="text-muted">
    //                       <strong>Location: </strong>
    //                       {data&&data.location.toUpperCase()}
    //                     </small>
    //                   </h6>
    //                   {/* <h5>
    //                   <small className="text-muted">Interviewers</small>
    //                 </h5> */}

    //                   {/* <Badge
    //                   color=""
    //                   className="bg-primary-subtle text-primary text-opacity-75 m-1 font-size-12"
    //                 >
    //                   Interviews
    //                 </Badge> */}
    //                   {/* <div className="mt-2">
    //                     <Link to="#" className="btn btn-primary btn-sm">
    //                       Update <i className=" fas fa-edit ms-1"></i>
    //                     </Link>
    //                   </div> */}
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </Card>

    //       <Row>
    //         <Col xl={12}>
    //           <div>
    //             <CardHeader className="align-items-center d-flex mt-2">
    //               <div className="flex-shrink-0">
    //                 <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
    //                   <NavItem>
    //                     <NavLink
    //                       href="#"
    //                       className={classnames({
    //                         active: headerTab === "1",
    //                       })}
    //                       onClick={() => {
    //                         toggleHeader("1");
    //                       }}
    //                     >
    //                       <span className="d-block d-sm-none">
    //                         <i className="far fa-envelope"></i>
    //                       </span>
    //                       <span className="d-none d-sm-block">Overview</span>
    //                     </NavLink>
    //                   </NavItem>
    //                   <NavItem>
    //                     <NavLink
    //                       href="#"
    //                       className={classnames({
    //                         active: headerTab === "2",
    //                       })}
    //                       onClick={() => {
    //                         toggleHeader("2");
    //                       }}
    //                     >
    //                       <span className="d-block d-sm-none">
    //                         <i className="far fa-envelope"></i>
    //                       </span>
    //                       <span className="d-none d-sm-block">
    //                         Previous Rounds
    //                       </span>
    //                     </NavLink>
    //                   </NavItem>
    //                   <NavItem>
    //                     <NavLink
    //                       href="#"
    //                       className={classnames({
    //                         active: headerTab === "3",
    //                       })}
    //                       onClick={() => {
    //                         toggleHeader("3");
    //                       }}
    //                     >
    //                       <span className="d-block d-sm-none">
    //                         <i className="far fa-envelope"></i>
    //                       </span>
    //                       <span className="d-none d-sm-block">
    //                         Interviewed Candidates
    //                       </span>
    //                     </NavLink>
    //                   </NavItem>
    //                   <NavItem>
    //                     <NavLink
    //                       href="#"
    //                       className={classnames({
    //                         active: headerTab === "4",
    //                       })}
    //                       onClick={() => {
    //                         toggleHeader("4");
    //                       }}
    //                     >
    //                       <span className="d-block d-sm-none">
    //                         <i className="far fa-envelope"></i>
    //                       </span>
    //                       <span className="d-none d-sm-block">History</span>
    //                     </NavLink>
    //                   </NavItem>
    //                 </Nav>
    //               </div>
    //             </CardHeader>

    //             <div className="card-body">
    //               <TabContent className="text-muted" activeTab={headerTab}>
    //                 {/* Content for Tab1 */}
    //                 <TabPane tabId="1">
    //                   <p>Overview todo...</p>
    //                 </TabPane>
    //                 <TabPane tabId="2">
    //                   <p>Previous rounds todo...</p>
    //                 </TabPane>
    //                 <TabPane tabId="3">
    //                   <p>Interviewed Candidates todo...</p>
    //                 </TabPane>
    //                 <TabPane tabId="4">
    //                   <p>History todo...</p>
    //                 </TabPane>
    //               </TabContent>
    //             </div>
    //           </div>
    //         </Col>
    //       </Row>
    //     </OffcanvasBody>
    //   </Offcanvas>
    // </>
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom " toggle={toggle}>
          <h5 className="text-primary">
            Interview Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Show Details of selected
              interview.
            </span>
          </h5>
          {candidatureHistory.length > 0 && (
            <FunnelProgressBar candidatureHistory={candidatureHistory} />
          )}
        </OffcanvasHeader>

        <OffcanvasBody>
          <Row className="p-2">
            <div className="col-md-6">
              <div className="col-sm order-2 order-sm-1 card h-100 px-3 p-2">
                <div className="d-flex align-items-start mt-3 mt-sm-0">
                  <div className="flex-shrink-0">
                    <div className="avatar-xl mx-auto mb-0 me-3 mt-1">
                      <span
                        className="avatar-title bg-primary text-white font-size-24 p-3 display-3 m-0 rounded-circle"
                        style={{ width: "65px", height: "65px" }}
                      >
                        {getInitials(individualDetail.full_name)}
                      </span>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <h5 className="font-size-16 ">
                        {individualDetail.full_name}
                        <Badge className="bg-success-subtle p-1 text-success font-size-12 float-end">
                          {data?.status}
                        </Badge>
                      </h5>

                      <p className="text-muted font-size-13 mb-1">
                        <i className="mdi mdi-office-building-marker-outline me-1 text-primary align-middle"></i>
                        {data.company_name ? data.company_name : "-"}
                      </p>

                      <div className="d-flex flex-wrap align-items-start text-muted font-size-13">
                        {individualDetail.contact_number && (
                          <div>
                            <i className="mdi mdi-phone me-1 text-primary align-middle"></i>
                            {individualDetail.contact_number}
                          </div>
                        )}
                        <div>
                          <i className="mdi mdi-email me-1 text-primary align-middle"></i>
                          {individualDetail?.email}
                        </div>
                      </div>

                      <div className="d-flex flex-wrap align-items-start text-muted font-size-13 mt-2">
                        <div>
                          <span>
                            {individualDetail &&
                              individualDetail.skills &&
                              individualDetail.skills.length > 0 &&
                              or_unique(individualDetail.skills).map(
                                (skills, index) => (
                                  <Link
                                    key={index}
                                    to="#"
                                    className="badge bg-primary-subtle text-primary mx-1 p-1"
                                  >
                                    {skills}
                                  </Link>
                                ),
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-sm order-2 order-sm-1 card h-100 px-3 p-2">
                <div className="d-flex align-items-start">
                  <div className="flex-shrink-0">
                    <div className="avatar-xl me-3">
                      <img
                        src={joblogo}
                        alt=""
                        className="img-fluid rounded-circle d-block "
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="col-sm-auto order-1 order-sm-2">
                      <Badge className="bg-success-subtle p-1 text-success font-size-12 float-end">
                        {jobDetail?.status}
                      </Badge>
                    </div>
                    <div>
                      <Link
                        to="#"
                        onClick={() => {
                          handleJob(jobDetail);
                        }}
                        className="font-size-16 mb-2"
                      >
                        {jobDetail?.title}
                      </Link>

                      <h5 className="text-muted font-size-13 mt-1 mb-1">
                        <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>{" "}
                        {jobDetail?.location}
                      </h5>

                      <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14 mt-2">
                        <div>
                          <span>
                            {jobDetail?.skills?.map((skill, index) => (
                              <Link
                                key={index}
                                to="#"
                                className="badge bg-primary-subtle text-primary mx-1 p-1"
                                // className="badge bg-primary  mx-1 p-1"
                              >
                                {skill}
                              </Link>
                            ))}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13 mt-1">
                        <div>
                          <i className="mdi mdi-account-details me-1 text-primary align-middle"></i>
                          <span>
                            {jobDetail?.description?.substring(0, 80)}...
                            {/* <Link to="#">See more</Link> */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <TIQcard/> */}
            </div>
            <div className="mt-0 mb-0 text-muted">
              <hr />
            </div>
          </Row>

          {candidatureHistory && (
            <TimelineComponent
              historydata={candidatureHistory}
              type="candidatureHistory"
            />
          )}
        </OffcanvasBody>
      </Offcanvas>
      {isJobCanvas && (
        <JobOverview
          usertype={userType}
          menuOption="jobs"
          width={60}
          isOpen={isJobCanvas}
          toggle={() => setIsJobCanvas(!isJobCanvas)}
          onClose={closeJobCanvas}
          data={jobDetail}
        />
      )}
    </>
  );
}

export default CanvasInterview;

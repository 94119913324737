import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              <Link to={"https://companybench.com"} target="_blank">
                {new Date().getFullYear()} © Companybench.
              </Link>{" "}
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                All rights reserved.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";

import CustomTable from "../../../customComponents/reactTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAppliedJobs,
  getDashboardInterviews,
  getDashboardOffers,
} from "../../individualSlice";
import { Tags } from "../../../pages/Contacts/ContactList/contactlistCol";
import { Link } from "react-router-dom";
import CanvasJobDescription from "../../../customComponents/offcanvas/CanvasJobdescription";
import dayjs from "dayjs";
import CanvasInterview from "../../../customComponents/offcanvas/CanvasInterviewDetails";
import OfferDetailsCanvas from "../../../customComponents/offcanvas/OfferDetailsCanvas";
import { JobDetail } from "../../../Client/ClientSlice";
import JobOverview from "../../../customComponents/offcanvas/JobOverviewCanvas";
import StatusBadge from "../../../customComponents/utilities/StatusBadge";

const IndividualDashboardTabs = ({ startdate, enddate }) => {
  const dispatch = useDispatch();
  const individualStore = useSelector((state) => state.individualSlice);
  const [AppliedJobs, setAppliedJobs] = useState([]);
  const [Interviews, setInterviews] = useState([]);
  const [Offers, setOffers] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [isInterviewCanvasOpen, setInterviewCanvasOpen] = useState(false);
  const [isOfferCanvasOpen, setisOfferCanvasOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [displaytext, setdisplaytext] = useState("");
  const [loading, setloading] = useState(false);
  const [isJobCanvasOpen, setJobCanvasOpen] = useState(false);
  const [ApliedJobscurrentPage, setAppliedJobsCurrentPage] = useState(0);
  const [InterviewscurrentPage, setInterviewsCurrentPage] = useState(0);
  const [OfferscurrentPage, setOffersCurrentPage] = useState(0);
  const [AppliedJobsCount, setAppliedJobsCount] = useState(0);
  const [InterviewsCount, setInterviewsCount] = useState(0);
  const [OffersCount, setOffersCount] = useState(0);

  const [headerTab, setHeaderTab] = useState("1");

  //meta title

  const [activeTab, setactiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  const closeInterviewCanvas = () => {
    setInterviewCanvasOpen(false);
  };

  const closeOfferCanvas = () => {
    setOffcanvasOpen(false);
  };

  const JobName = (job) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedJob(job);
    setOffcanvasOpen(true);
  };

  const [selectedInterview, setselectedInterview] = useState(null);
  const handleInterviews = (job) => {
    setselectedInterview(job);
    setInterviewCanvasOpen(true);
  };

  const [selectedOffer, setSelectedOffer] = useState(null);
  const handleOffers = (offer) => {
    setSelectedOffer(offer);
    setisOfferCanvasOpen(true);
  };

  const handleJob = (job) => {
    // Do something with the selected candidate, e.g., fetch details
    dispatch(JobDetail(job?.jobdescription_guid)).then((response) => {
      if (response.payload.success === true) {
        setSelectedJob(response.payload.data?.jobdescription);
        setJobCanvasOpen(true);
      }
    });
  };

  const closeJobCanvas = () => {
    setJobCanvasOpen(false);
  };

  const cloumnsAppliedJobs = [
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            JobName(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Required Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    {
      Header: "Job Posted",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <StatusBadge status={row.values.status} />
      ),
    },
  ];

  const columnsInterviews = [
    {
      Header: "Interview Id",
      accessor: "guid",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleInterviews(row.original);
          }}
        >
          {row.original?.guid?.split("-")[0].toUpperCase()}-
          {row.original?.guid?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleJob(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Type",
      accessor: "location",
      Cell: ({ value }) => <>{value ? value.toUpperCase() : "-"}</>,
    },


    {
      Header: "Scheduled Date",
      accessor: "scheduled_at",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex gap-1">
          <StatusBadge
            status={row.values.status}
            type={row.original.type.toUpperCase()}
          />
        </div>
      ),
    },
  ];

  const columnsOffers = [
    {
      Header: "Offer Id",
      accessor: "guid",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleOffers(row.original);
          }}
        >
          {row.original?.guid?.split("-")[0].toUpperCase()}-
          {row.original?.guid?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Offer Date",
      accessor: "createdAt",

      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <StatusBadge status={row.values.status} />
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getDashboardAppliedJobs({
        fromDate: startdate,
        toDate: enddate,
        offset: ApliedJobscurrentPage,
      }),
    );
  }, [ApliedJobscurrentPage, startdate, enddate]);

  useEffect(() => {
    dispatch(
      getDashboardInterviews({
        fromDate: startdate,
        toDate: enddate,
        offset: InterviewscurrentPage,
      }),
    );
  }, [InterviewscurrentPage, startdate, enddate]);

  useEffect(() => {
    dispatch(
      getDashboardOffers({
        fromDate: startdate,
        toDate: enddate,
        offset: OfferscurrentPage,
      }),
    );
  }, [OfferscurrentPage, startdate, enddate]);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.DashboardAppliedJobs?.success === true) {
        setAppliedJobs(
          individualStore.data.DashboardAppliedJobs?.data?.jobDescriptions,
        );
        setAppliedJobsCount(
          individualStore.data.DashboardAppliedJobs?.data?.totalCount,
        );
      }
      if (individualStore.data.DashboardInterviews?.success === true) {
        setInterviews(
          individualStore.data.DashboardInterviews?.data?.interviews,
        );
        setInterviewsCount(
          individualStore.data.DashboardInterviews?.data?.totalCount,
        );
      }
      if (individualStore.data.DashboardOffers?.success === true) {
        setOffers(individualStore.data.DashboardOffers?.data?.offers);
      }
      setOffersCount(individualStore.data.DashboardOffers?.data?.totalCount);
      setloading(false);
    } else if (individualStore.status === "loading") {
      setloading(true);
    } else if (individualStore.status === "failed") {
      setdisplaytext("Failed to fetch data.....");
    }
  }, [
    individualStore.data.DashboardAppliedJobs,
    individualStore.data.DashboardInterviews,
    individualStore.data.DashboardOffers,
  ]);

  const handleAppliedJobsPageChange = (newPage) => {
    setAppliedJobsCurrentPage(newPage);
  };

  const handleInterviewsPageChange = (newPage) => {
    setInterviewsCurrentPage(newPage);
  };

  const handleOffersPageChange = (newPage) => {
    setOffersCurrentPage(newPage);
  };

  return (
    <Row>
      <Col xl={12}>
        <div>
          <CardHeader className="align-items-center d-flex my-2">
            <div className="flex-shrink-0">
              <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "1",
                    })}
                    onClick={() => {
                      toggleHeader("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="d-none d-sm-block">
                      Applied Jobs{" "}
                      <Badge color="primary">{AppliedJobsCount}</Badge>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "2",
                    })}
                    onClick={() => {
                      toggleHeader("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">
                      Interviews{" "}
                      <Badge color="primary">{InterviewsCount}</Badge>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "3",
                    })}
                    onClick={() => {
                      toggleHeader("3");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">
                      Offers <Badge color="primary">{OffersCount}</Badge>
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </CardHeader>

          <div className="card-body">
            <TabContent className="text-muted" activeTab={headerTab}>
              <TabPane tabId="1">
                <>
                  <CustomTable
                    columns={cloumnsAppliedJobs}
                    shortlist={true}
                    redirectTo="/individual-applied"
                    data={AppliedJobs}
                    hideCheckbox={true}
                    isLoading={loading}
                    currentPage={ApliedJobscurrentPage}
                    onPageChange={handleAppliedJobsPageChange}
                    totalCount={AppliedJobsCount}
                  />
                </>
              </TabPane>
              <TabPane tabId="2">
                <>
                  <CustomTable
                    columns={columnsInterviews}
                    shortlist={true}
                    redirectTo="/individual-interviews"
                    data={Interviews}
                    hideCheckbox={true}
                    isLoading={loading}
                    currentPage={InterviewscurrentPage}
                    onPageChange={handleInterviewsPageChange}
                    totalCount={InterviewsCount}
                  />
                </>
              </TabPane>
              <TabPane tabId="3">
                <>
                  <CustomTable
                    columns={columnsOffers}
                    shortlist={true}
                    redirectTo="/individual-offers"
                    data={Offers}
                    hideCheckbox={true}
                    isLoading={loading}
                    currentPage={OfferscurrentPage}
                    onPageChange={handleOffersPageChange}
                    totalCount={OffersCount}
                  />
                </>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Col>
      {isOffcanvasOpen && (
        <CanvasJobDescription
          usertype="individual"
          menuOption="appliedjobs"
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={selectedJob}
        />
      )}

      {isInterviewCanvasOpen && (
        <CanvasInterview
          userType="individual"
          menuOption="appliedjobs"
          width={60}
          isOpen={isInterviewCanvasOpen}
          toggle={() => setInterviewCanvasOpen(!isInterviewCanvasOpen)}
          onClose={closeInterviewCanvas}
          data={selectedInterview}
        />
      )}

      {isOfferCanvasOpen && (
        <OfferDetailsCanvas
          width={60}
          isOpen={isOfferCanvasOpen}
          toggle={() => setisOfferCanvasOpen(!isOfferCanvasOpen)}
          onClose={closeOfferCanvas}
          data={selectedOffer}
        />
      )}

      {isJobCanvasOpen && (
        <JobOverview
          width={60}
          data={selectedJob}
          isOpen={isJobCanvasOpen}
          toggle={() => setJobCanvasOpen(!isJobCanvasOpen)}
          onClose={closeJobCanvas}
          showApply={false}
        />
      )}
    </Row>
  );
};

export default IndividualDashboardTabs;

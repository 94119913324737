import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import validator from "validator";
import { useDispatch } from "react-redux";
import { log_debug } from "../../../apiHelpers/logger";
import {
  dateyyyymmdd_tohtml,
  validateStartEndDate,
} from "../../../helpers/date";
import {
  getIndividualProfileDetails,
  postIndividualResumeUpdate,
} from "../../individualSlice";

const CanvasEditIndiWorkExp = ({
  isOpen,
  toggle,
  formData,
  handleSave,
  data,
}) => {
  const dispatch = useDispatch();
  const [guid, setGuid] = useState([]);
  const [editedData, setEditedData] = useState([]);
  log_debug("editedDatawork", data);
  const [editedDataskill, setEditedDataskill] = useState([]);
  const [editedDatasummary, setEditedDatasummary] = useState([]);

  const [errors, setErrors] = useState({});

  log_debug("editedDatasummary", editedDatasummary);

  useEffect(() => {
    setGuid(data?.guid || []);
    setEditedData(data?.work_experiences);
    // setEditedDataskill(data?.workSkillTab1 || []);
    // setEditedDatasummary(data?.summary || []);
  }, [data]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${year}`;
  };

  const offcanvasStyle = {
    width: "45%",
  };
  const handleFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    // Handle other fields
    setEditedData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [fieldName]: value };
      return newData;
    });
  };

  const handleValidateSave = () => {
    let errors = {};
    editedData.forEach((workExperience, index) => {
      // Initialize errors for the current work experience
      let experienceErrors = {};
      // let project_details = workExperience?.project_details || '';
      let company_name = workExperience?.company_name || "";
      let role = workExperience?.role || "";
      // let designation = workExperience?.designation || '';
      let from_date = workExperience?.from_date || "";
      let to_date = workExperience?.to_date || "";

      // Set errors for the current field in the current work experience
      experienceErrors["company_name"] = validator.isEmpty(company_name)
        ? "Company name is required"
        : "";
      experienceErrors["role"] = validator.isEmpty(role)
        ? "Role is required"
        : "";
      // experienceErrors['designation'] = validator.isEmpty(designation) ? 'Designation is required' : '';
      // experienceErrors['project_details'] = validator.isEmpty(project_details) ? 'Project details are required' : '';
      experienceErrors["from_date"] = validator.isEmpty(from_date)
        ? "Start date is required"
        : "";
      experienceErrors["to_date"] = validator.isEmpty(to_date)
        ? "End date is required"
        : "";

      // if (!validator.isLength(project_details, { min: 0, max: 1000 })) {
      //     experienceErrors['project_details'] = 'Project details are Too large';
      // }
      if (!validator.isLength(company_name, { min: 0, max: 60 })) {
        experienceErrors["company_name"] = "Please enter valid company name";
      }
      if (!validator.isLength(role, { min: 2, max: 40 })) {
        experienceErrors["role"] = "Please enter valid role";
      }
      if (from_date) {
        if (!validator.isDate(dateyyyymmdd_tohtml(from_date))) {
          experienceErrors["from_date"] = "Please enter valid start date";
        }
      }

      if (to_date) {
        if (!validator.isDate(dateyyyymmdd_tohtml(to_date))) {
          experienceErrors["to_date"] = "Please enter valid end date";
        }
      }

      if (!validateStartEndDate(from_date, to_date)) {
        experienceErrors["from_date"] =
          "Start Date can not be greater than End Date";
      }
      // Set errors for the current work experience in the main errors object
      errors[index] = experienceErrors;
    });

    setErrors(errors);
    if (
      Object.values(errors).some((experienceErrors) =>
        Object.values(experienceErrors).some((error) => error !== ""),
      )
    ) {
      return;
    }
    const resumeDatas = {
      resume: {
        work_experiences: [...editedData],
      },
    };

    // dispatch(postIndividualResumeUpdate({resume_guid:data.guid , addedData:resumeDatas}))
    dispatch(
      postIndividualResumeUpdate({
        resume_guid: data.guid,
        addedData: resumeDatas,
      }),
    )
      .then(() => {
        // The first action has been dispatched and, assuming it resolved, we can now dispatch another.
        dispatch(getIndividualProfileDetails());
      })
      .catch((error) => {
        // Handle any errors from the first dispatch here
        console.error("Failed to update individual resume:", error);
      });

    toggle();

    // handleSave(editedData);
  };

  // ========================this is coman function========================

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          Edit Work Experience
        </OffcanvasHeader>
        <OffcanvasBody>
          {editedData.map((workExperience, index) => (
            <React.Fragment key={index}>
              <Form>
                <Row className="mb-3">
                  <Label className="col-sm-3 col-form-label" for="company_name">
                    Company Name <span className="text-danger">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      name="company_name"
                      value={workExperience?.company_name}
                      onChange={(e) =>
                        handleFieldChange(e, index, "company_name")
                      }
                    />
                    {errors[index]?.company_name && (
                      <small className="text-danger">
                        {errors[index]?.company_name}
                      </small>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label className="col-sm-3 col-form-label" for="role">
                    Role <span className="text-danger">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="text"
                      name="role"
                      value={workExperience?.role}
                      onChange={(e) => handleFieldChange(e, index, "role")}
                    />
                    {errors[index]?.role && (
                      <small className="text-danger">
                        {errors[index]?.role}
                      </small>
                    )}
                  </Col>
                </Row>
                {/* <Row>
                  <Label for="skills">Skills</Label>
                  <Input
                    type="text"
                    name="skills"
                    value={editedDataskill.join(", ")}
                    onChange={(e) => handleFieldChange(e, index, "skills")}
                  />
                </Row> */}
                <Row className="mb-3">
                  <Label className="col-sm-3 col-form-label" for="from_date">
                    From Date <span className="text-danger">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="date"
                      name="from_date"
                      value={
                        workExperience?.from_date &&
                        dateyyyymmdd_tohtml(workExperience.from_date)
                      }
                      onChange={(e) => handleFieldChange(e, index, "from_date")}
                    />
                    {errors[index]?.from_date && (
                      <small className="text-danger">
                        {errors[index]?.from_date}
                      </small>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label className="col-sm-3 col-form-label" for="to_date">
                    To date <span className="text-danger">*</span>
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="date"
                      name="to_date"
                      value={
                        workExperience?.to_date &&
                        dateyyyymmdd_tohtml(workExperience.to_date)
                      }
                      onChange={(e) => handleFieldChange(e, index, "to_date")}
                    />
                    {errors[index]?.to_date && (
                      <small className="text-danger">
                        {errors[index]?.to_date}
                      </small>
                    )}
                  </Col>
                </Row>
                {/* <FormGroup>
                  <Label for="summary">Summary</Label>
                  <Input
                    type="textarea"
                    name="summary"
                    value={editedDatasummary}
                    onChange={(e) => handleFieldChange(e, index, "summary")}
                  />
                </FormGroup> */}
              </Form>
              <hr />
            </React.Fragment>
          ))}

          <Button color="primary" onClick={() => handleValidateSave()}>
            Update
          </Button>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default CanvasEditIndiWorkExp;

import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Container,
  CardHeader,
  Badge,
} from "reactstrap";
import "../../src/assets/css/styleCompanyBench.css";

import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../components/Common/Breadcrumb";
import dayjs from "dayjs";
import CustomTable from "../customComponents/reactTable/CustomTable.js";
import { Offer, getClientDashboardCount } from "./ClientSlice";
import { Link } from "react-router-dom";
import AdvanceFilter from "../customComponents/advance filter/AdvanceFilter.js";
import OfferDetailsCanvas from "../customComponents/offcanvas/OfferDetailsCanvas.js";
import Preloader from "../components/Common/Preloader.js";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar.js";
import maskSurname from "../helpers/maskename.js";
import moment from "moment";
// import OfferDetailsCanvas from "../Partner/pagesComponents/OfferDetailsCanvas.js";

function ClientOffers({ canvasopen }) {
  const dispatch = useDispatch();
  const clientstore = useSelector((state) => state.clientSlice);

  const [clientOffers, setClientOffers] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  //   const [NewContact, setNewContact] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [isRight, setIsRight] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [RecordCount, setRecordCount] = useState(0);
  const [filtervalue, setFiltervalue] = useState("");
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getClientDashboardCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    setOffcanvasOpen(true);
  };

  const AddNewContarct = () => {
    // setNewContact(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };
  const closeOffcanvasContract = () => {
    // setNewContact(false);
  };

  const columns = [
    {
      Header: "Offer ID",
      accessor: "offer_id",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleOfferClick(row.original);
          }}
        >
          {row.original?.offer_id?.split("-")[0].toUpperCase()}-
          {row.original?.offer_id?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            //candidateName(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar fullName={row.original.candidate.charAt(0)} />
            <span style={{ marginTop: "5px" }}>
              {maskSurname(row.original.candidate)}
            </span>
          </div>
        </Link>
      ),
      width: 100,
    },
    {
      Header: "Job Title",
      accessor: "job_title",
      width: 100,
      // Cell: ({ value }) => value.join(', '),
    },
    {
      Header: "Status",
      accessor: "status",
      width: 200,

      Cell: ({ value }) => (
        <Badge
          color={
            value === "accepted"
              ? "success rounded-pill"
              : "danger rounded-pill"
          }
          className="m-1 font-size-12"
        >
          {value}
        </Badge>
      ),
    },
  ];
  // const yourDynamicHeaderData = {
  //   full_name: "full_name",
  //   email: "email",
  //   contact_no: "contact_no",
  //   skills: "skills",
  //   prefered_locations: "prefered_locations",
  //   experience: "experience",
  // };

  // const [formdata, setFormdata] = useState({ newTitle: "", newContent: "" });

  const [Location, setLocation] = useState([]);
  const [JobRole, setJobRole] = useState([]);

  const handleFilterChange = (option) => {
    if (option && option.location) {
      const locationLabels = option.location.map((location) => location.label);
      setLocation(locationLabels);
    }

    if (option && option.jobRole) {
      const jobRoleLabels = option.jobRole.map((jobRole) => jobRole.label);
      setJobRole(jobRoleLabels);
    }
  };

  // useEffect(() => {
  //   dispatch(
  //     Offer({
  //       limit: 100,
  //       offset: currentPage,
  //     }),
  //   );
  // }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        Offer({
          offset: currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [currentPage, filtervalue]);

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.Offer?.success === true) {
        setClientOffers(clientstore.data.Offer?.data?.individuals);
        setRecordCount(clientstore.data.Offer?.data?.totalCount);
        // const jobrole = AppliedCandidates.map((jobs) => jobs.jobdescriptions);
        // setJobdata(jobrole);
      }
      setisLoading(false);
    } else if (clientstore.status === "loading") {
      setisLoading(true);
    }
  }, [clientstore.data.Offer]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setisLoading(true);
    setFiltervalue(newValue);
  };

  return (
    <React.Fragment>
      {canvasopen ? (
        <CustomTable
          // tableDatas={clientOffers}
          // headerData={yourDynamicHeaderData}
          columns={columns}
          data={clientOffers}
          isLoading={isLoading}
          hideCheckbox={true}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          ShowPagination={
            clientOffers && clientOffers.length > 10 ? true : false
          }
          totalCount={RecordCount}
        />
      ) : (
        <div className="page-content">
          {isLoading && <Preloader />}
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Offers" Name="Offers" />
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    {/* <Card
                    className=" pt-3"
                  > */}
                    <CardBody>
                      <Row>
                        {/* <AdvanceFilter onFilterChange={handleFilterChange} /> */}
                        <div className="col-12 col-lg-12 col-md-6">
                          <Col md={12}>
                            <CardTitle className="text-end fs-6 pt-3">
                              Total Offers : {RecordCount}
                            </CardTitle>
                          </Col>
                        </div>
                      </Row>
                    </CardBody>
                    {/* </Card> */}
                  </div>
                  <div>
                    <Card className="">
                      <CardBody>
                        <CustomTable
                          // tableDatas={clientOffers}
                          // headerData={yourDynamicHeaderData}
                          columns={columns}
                          data={clientOffers}
                          isLoading={isLoading}
                          hideCheckbox={true}
                          currentPage={currentPage}
                          onPageChange={handlePageChange}
                          ShowPagination={
                            clientOffers && clientOffers.length > 10
                              ? true
                              : false
                          }
                          totalCount={RecordCount}
                          onChange={handleValueChange}
                          globalsearch={true}
                        />
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
      {/* <div className="page-content">
        {isLoading && <Preloader />}
        <Container fluid>
          {/* Render Breadcrumbs *
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Interviews"
            Name="Interviews"
          />
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <Card
                    className=" pt-3"
                  > *
                  <CardBody>
                    <Row>
                      {/* <AdvanceFilter onFilterChange={handleFilterChange} /> 
                      <div className="col-12 col-lg-12 col-md-6">
                        <Col md={12}>
                          <CardTitle className="text-end fs-6 pt-3">
                            Total Interviews : {clientOffers.length}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                  {/* </Card> 
                </div>
                <div>
                  <Card className="">
                    <CardBody>
                      <CustomTable
                        // tableDatas={clientOffers}
                        // headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={clientOffers}
                        isLoading={isLoading}
                        hideCheckbox={true}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        ShowPagination={
                          clientOffers && clientOffers.length > 10
                            ? true
                            : false
                        }
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div> */}

      {isOffcanvasOpen && (
        <OfferDetailsCanvas
          placement="end"
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={selectedOffer}
          userType={"client"}
        />
      )}
      {/* {NewContact && (
        <OffersNewContractCanvas
          placement="end"
          isOpen={NewContact}
          toggle={() => setNewContact(!NewContact)}
          onClose={closeOffcanvasContract}
        />
      )} */}
    </React.Fragment>
  );
}

export default ClientOffers;

// CustomOffcanvas.js
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { toast, ToastContainer } from "react-toastify";
// import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { FiDownload, FiEdit, FiFileText, FiPlus } from "react-icons/fi";
import { FaBusinessTime } from "react-icons/fa6";
import avatar from "../../assets/images/companyBenchImage/userprofilegray.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Offcanvas,
  TabContent,
  OffcanvasHeader,
  OffcanvasBody,
  TabPane,
  UncontrolledDropdown,
  Button,
  Badge,
} from "reactstrap";
// import { jobs } from "../data/Data";
import CountUp from "react-countup";
import { Link, useNavigate } from "react-router-dom";
import "../../Partner/partner.css";
import "../../../src/assets/css/styleCompanyBench.css";
import CustomTable from "../reactTable/CustomTable";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  AssessmentIndividualCandi,
  BGVIndividualCandi,
  CandidateAssessmentInvitation,
  CandidateDetailscanvasContract,
  CandidateDetailscanvasInterview,
  CandidateDetailscanvasOffer,
  CandidateHistory,
  DashboardCounter,
  InvitationIndividualCandidate,
  MachingIndividualCandi,
  assessmentBehavioural,
  assessmentCoding,
  assessmentMCQ,
  candidatesOverview,
  dummyAssessment,
  machingJobApplyJob,
  myCandiDetailsShortlistedRole,
  shortlistedIndividualCandidate,
} from "../../Partner/PartnerSlice";
import InitiateBGVOffcanvas from "../../Partner/pagesComponents/InitiateBGVOffcanvas";
import { FaRegQuestionCircle } from "react-icons/fa";
import DetailsCanvas from "./CanvasJobdescription";
// Custom Components
import AssessmentInviteForm from "../form/AssessmentInviteForm";
import TIQcard from "../offcanvas/offcanvasComponents/TIQcard";
import CanvasAssessment from "./CanvasAssessment";
import CanvasPartnerJobDetails from "./CanvasPartnerJobDetails";
import TimeLineComponent from "../timelineComponents/TimeLineComponent";
import Overview from "../../Client/Components/Overview/Overview";
import ShortlistedJobs from "../../Client/Components/Canvas Tables/ShortlistedJobs";
import InvitedJobs from "../../Client/Components/Canvas Tables/InvitedJobs";
import MatchingJobs from "../../Client/Components/Canvas Tables/MatchingJobs";
import { Tags } from "../../pages/Contacts/ContactList/contactlistCol";
import { Pguid } from "../../apiConfig/axiosDev";
import NodataFoundTable from "../utilities/NodataFoundTable";
import { log_debug } from "../../apiHelpers/logger";
import { useFormik } from "formik";
import * as Yup from "yup";
import { titles } from "../../data/cb_title";
import {
  getCandidateInvitedJobs,
  getCandidateMatchingJobs,
  getCandidateShortlistedJobs,
} from "../../Client/ClientSlice";
import { dateyyyymmdd_tohtml } from "../../helpers/date";
import CandidateStatusBadge from "../utilities/CandidateStatusBadge";
import JobsBadge from "../utilities/JobsBadge";
import { getPartnerGuid } from "../../apiConfig/globalvariables";
import GenerateResumeCanvas from "./GenerateResumeCanvas";
import moment from "moment";
import Preloader from "../../components/Common/Preloader";
export function CanvasCandidate({
  isOpen,
  toggle,
  onClose,
  data,
  usertype,
  menuOption,
}) {
  const {
    matching_job_title,
    matching_job_sub_title,
    assessment_form_title,
    assessment_form_sub_title,
  } = titles.en[usertype];

  const [JobGuid, SetJobGuid] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageinvite, setCurrentPageinvite] = useState(0);
  const [currentPagematching, setCurrentPagematching] = useState(0);
  const [currentPageassessment, setCurrentPageassessment] = useState(0);
  const [currentPageinterview, setCurrentPageinterview] = useState(0);
  const [currentPageoffer, setCurrentPageoffer] = useState(0);
  const [currentPagecontract, setCurrentPagecontract] = useState(0);
  const [InvitedJobscurrentpage, setInvitedJobscurrentpage] = useState(0);
  // const [ShortlistedJobscurrentpage, setShortlistedJobscurrentpage] =
  //   useState(0);
  // const [MatchingJobscurrentpage, setMatchingJobscurrentpage] = useState(0);

  const [currentPage1, setCurrentPage1] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [currentPage3, setCurrentPage3] = useState(0);

  const [InvitedCount, setInvitedCount] = useState(0);
  const [ShortlistedCount, setShortlistedCount] = useState(0);
  const [MatchingJobsCount, setMatchingJobsCount] = useState(0);

  // ================assessment====================
  const [AssessmentType, setAssessmentType] = useState([]);
  const [mcqDetails, setMcqDetails] = useState([]);
  log_debug("mcqDetailsmcqDetailsmcqDetails", data);
  const [appliedRows, setAppliedRows] = useState([]);

  const [codingDetails, setCodingDetails] = useState([]);
  const [behaviouralDetails, setBehaviouralDetails] = useState([]);
  // ================assessment====================
  const [CandidateHistoryinfo, setCandidateHistoryinfo] = useState([]); // candidate history
  const [JobRoleOptions, setJobRoleOptions] = useState([]);
  const [isRoleSelected, setIsRoleSelected] = useState(false); // New state variable4

  //candidate overview
  const [candidatedata, setcandidatedata] = useState([]);
  const [CandidateOverviewinfo, setCandidateOverviewinfo] = useState([]);
  const [showFullSummary, setShowFullSummary] = useState(false);
  const [headerTab, setHeaderTab] = useState("1");

  const [isOffcanvasOpenShortlisted, setOffcanvasOpenShortlisted] =
    useState(false);
  const [isOffcanvasOpenInvitation, setOffcanvasOpenInvitation] =
    useState(false);
  const [isOffcanvasOpenMatching, setOffcanvasOpenMatching] = useState(false);

  const [selectedCandidateShortlisted, setSelectedCandidateShortlisted] =
    useState(null);
  const [selectedCandidateInvitation, setSelectedCandidateInvitation] =
    useState(null);
  const [selectedCandidateMatching, setSelectedCandidateMatching] =
    useState(null);

  const [isOffcanvasOpenAssessment, setOffcanvasOpenAssessment] =
    useState(false);
  const [isOffcanvasOpenBGV, setisOffcanvasOpenBGV] = useState(false);

  const [selectedCandidateBGV, setSelectedCandidateBGV] = useState(null);

  const [
    selectedCandidateAssessmentsData,
    setSelectedCandidateAssessmentsData,
  ] = useState([]);

  const [MyCandidateDetailsShortlisted, setMyCandidateDetailsShortlisted] =
    useState([]);
  const [Allsortlistcount, setAllsortlistcount] = useState([]);
  const [Allinvitationcount, setAllinvitationcount] = useState([]);
  const [AllMatchtcount, setAllMatchtcount] = useState([]);
  const [Allassessmentcount, setAllassessmentcount] = useState([]);
  const [Allinterviewcount, setAllinterviewcount] = useState([]);
  const [Allinoffercount, setAllinoffercount] = useState([]);
  const [Allcontractcount, setAllcontractcount] = useState([]);
  log_debug("Allsortlistcount", Allsortlistcount);

  const [isloadingshortlist, setisloadingshortlist] = useState(true);
  const [isloadingInvitation, setisloadingInvitation] = useState(true);
  const [isloadingMatching, setisloadingMatching] = useState(true);
  const [isloadingAssessment, setisloadingAssessment] = useState(true);
  const [isloadingInterview, setisloadingInterview] = useState(true);
  const [isloadingOffer, setisloadingOffer] = useState(true);
  const [isloadingContract, setisloadingContract] = useState(true);

  const [MyCandidateDetailsInvitation, setMyCandidateDetailsInvitation] =
    useState([]);
  const [MyCandidateDetailsMatching, setMyCandidateDetailsMatching] = useState(
    [],
  );
  const [JobAPply, setJobApply] = useState([]);
  const [CandidateDetailsIntervivew, setCandidateDetailsIntervivew] = useState(
    [],
  );
  const [CandidateDetailsOffer, setCandidateDetailsOffer] = useState([]);
  const [PartnerGuid, setPartnerGuid] = useState([]);
  const [CandidateDetailsContract, setCandidateDetailsContract] = useState([]);
  const [AssessmentInvite, setAssessmentInvite] = useState([]);
  const [val, setVal] = useState(false);
  const [
    MyCandidateDetailsMatchingRefresh,
    setMyCandidateDetailsMatchingRefresh,
  ] = useState([]);
  const [MyCandidateDetailsassessment, setMyCandidateDetailsassessment] =
    useState([]);
  const [MyCandidateDetailsBGV, setMyCandidateDetailsBGV] = useState([]);
  log_debug("MyCandidateDetailsBGV", MyCandidateDetailsBGV);

  const [isGenerateResumeCanvasOpen, setisGenerateResumeCanvasOpen] =
    useState(false);
  const [SelectedCandidateResume, setSelectedCandidateResume] = useState([]);
  const [DownloadActiveStatus, setDownloadActiveStatus] = useState(false);

  const defaultEndDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 90);

  const [formattedStartDate, setformattedStartDate] = useState(
    // moment(defaultStartDate).format("YYYY-MM-DD"),
    moment(defaultStartDate).format("YYYY-MM-DD"),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  const [DisableApply, setDisableApply] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const clientStore = useSelector((state) => state.clientSlice);
  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };
  const offcanvasStyle = {
    width: `70%`,
    // zoom: '100%',
  };

  const candidateNameShortlisted = (guid) => {
    setSelectedCandidateShortlisted(guid);
    setOffcanvasOpenShortlisted(true);
  };
  const candidateNameInvitation = (guid) => {
    setSelectedCandidateInvitation(guid);
    setOffcanvasOpenInvitation(true);
  };
  const candidateNameMatching = (guid) => {
    setSelectedCandidateMatching(guid);
    setOffcanvasOpenMatching(true);
  };

  const candidateNameNew = (guid) => {
    setSelectedCandidateBGV(guid);
    setisOffcanvasOpenBGV(true);
  };

  const candidateAssessment = (guid) => {
    setSelectedCandidateAssessmentsData(guid);
    setOffcanvasOpenAssessment(true);
  };

  const closeOffcanvasShortlisted = () => {
    setOffcanvasOpenShortlisted(false);
    // setisOffcanvasOpenBGV(false);
  };

  const closeOffcanvasinvitation = () => {
    setOffcanvasOpenInvitation(false);
    // setisOffcanvasOpenBGV(false);
  };

  const closeOffcanvasMatching = () => {
    setOffcanvasOpenMatching(false);
    // setisOffcanvasOpenBGV(false);
  };

  const closeOffcanvasBGV = () => {
    setisOffcanvasOpenBGV(false);
  };

  const closeOffcanvasAssessment = () => {
    setOffcanvasOpenAssessment(false);
  };

  // const handleJobClick = (row) => {
  //   setForceRefresh(!forceRefresh);
  //   // Check if the row is already applied
  //   if (!appliedRows.includes(row.id)) {
  //     setAppliedRows((prevAppliedRows) => [...prevAppliedRows, row.id]);
  //   }

  //   // Dispatch the apply job action
  //   dispatch(
  //     machingJobApplyJob({
  //       individualjobmapping: {
  //         individual_guid: [data && data.guid],
  //         partner_guid: Pguid,
  //         jobdescription_guid: [row.original.guid],
  //       },
  //     }),
  //   );

  //   // Wait for the MachingIndividualCandi API call to complete
  //   dispatch(MachingIndividualCandi(data.guid));

  //   // After the API call is complete, update the state and re-render the table
  //   if (partnerStore.status === "idle" && partnerStore.data.MachingIndividualCandiRecords?.success === true) {
  //     setMyCandidateDetailsMatchingRefresh(partnerStore.data.MachingIndividualCandiRecords?.data.matchingJobDescriptions);
  //   }
  // };
  useEffect(() => {
    setPartnerGuid(Pguid);
  }, []);

  const handleJobClick = (row) => {
    // setVal(true)
    setDisableApply(true);
    dispatch(
      machingJobApplyJob({
        individualjobmapping: {
          individual_guid: [data && data.guid],
          partner_guid: getPartnerGuid() || Pguid,
          jobdescription_guid: [row.original.guid],
          // jobdescription_guid: [row.original.guid],
        },
      }),
    ).then((response) => {
      log_debug("response apply job", response);
      if (response.payload.success === true) {
        dispatch(
          MachingIndividualCandi({
            Indguid: data.guid,
            currentPage: currentPagematching
              ? currentPagematching
              : currentPagematching,
          }),
        );
        setTimeout(() => {
          // dispatch(MachingIndividualCandi(data.guid));

          // dispatch(shortlistedIndividualCandidate(data.guid));
          dispatch(
            shortlistedIndividualCandidate({
              Indguid: data.guid,
              currentPage: currentPage ? currentPage : currentPage,
            }),
          );
        }, 2000);

        dispatch(
          DashboardCounter({
            from_date: formattedStartDate,
            to_date: formattedEndDate,
          }),
        );
        toast.success("Job applied successfully!");
        setDisableApply(false);
      }
    });
  };

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data.MachingIndividualCandiRecords?.success === true &&
        partnerStore.data.machingJobApplyJobRecords?.success === true
      ) {
        setMyCandidateDetailsMatching(
          partnerStore.data.MachingIndividualCandiRecords?.data
            .matchingJobDescriptions,
        );
      }
      if (
        partnerStore.data.shortlistedIndividualCandidateRecords?.success ===
        true
      ) {
        setMyCandidateDetailsShortlisted(
          partnerStore.data.shortlistedIndividualCandidateRecords?.data
            ?.jobDescriptions,
        );
      }
    }
  }, [JobAPply]);

  const formik = useFormik({
    initialValues: {
      full_name: data.full_name,
      email_id: data.email,
      title: "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Name is required"),
      email_id: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      title: Yup.string().required("Role is required"),
    }),
    onSubmit: ({ full_name, email_id, title }) => {
      dispatch(
        CandidateAssessmentInvitation({
          full_name: full_name,
          email_id: email_id,
          role: title,
          individual_guid: data.guid,
        }),
      );
      toast.success('Assessment invitation sent successfully!"'); // Adjust the message as needed
    },
  });

  const columnsShortlisted = [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateNameShortlisted(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Skills",
      accessor: "skills",
      // width: 100,
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    {
      Header: "Locations",
      accessor: "location",
      Cell: ({ row }) => (
        <div className="">
          {row.original.location.length >= 1
            ? row.original.location.join(", ")
            : "-"}
        </div>
      ),
    },

    {
      Header: "Shortlisted Date",
      accessor: "shortlisted_date",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <JobsBadge status={value} />,
    },
  ];
  const columnsInvitation = [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateNameInvitation(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    // {
    //   Header: "Summary",
    //   accessor: "summary",
    // //   width: 100,
    // },
    {
      Header: "Locations",
      accessor: "location",
      Cell: ({ row }) => (
        <div className="">
          {row.original.location.length >= 1
            ? row.original.location.join(", ")
            : "-"}
        </div>
      ),
      // // width: 200,
    },
    {
      Header: "Invited Date",
      accessor: "invited_date",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Skills",
      accessor: "skills",
      // // width: 100,
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <JobsBadge status={value} />,
    },
  ];
  const columnsMatching = [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateNameMatching(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    // {
    //   Header: "Summary",
    //   accessor: "summary",
    // //   width: 100,
    // },
    {
      Header: "Locations",
      accessor: "location",
      Cell: ({ row }) => (
        <div className="">
          {row.original.location.length >= 1
            ? row.original.location.join(", ")
            : "-"}
        </div>
      ),
      // width: 200,
    },
    {
      Header: "Skills",
      accessor: "skills",
      // width: 100,
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <JobsBadge status={value} />,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <>
          <Link to={"#"} className="text-white">
            <Button
              color={appliedRows.includes(row.id) ? "primary" : "primary"}
              // disabled={appliedRows.includes(row.id)}
              disabled={DisableApply}
              onClick={() => handleJobClick(row)}
            >
              {/* {appliedRows.includes(row.id) ? "Applied" : "Apply"} */}
              <i className=" fas fa-paper-plane fs-6"></i>&nbsp; Apply
            </Button>
          </Link>
          {/* <ToastContainer /> */}
        </>
      ),
    },
  ];
  const Assessmentcolumns = [
    {
      Header: "Role",
      accessor: "role",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateAssessment(row.original);
          }}
        >
          {row.values.role}
        </Link>
      ),
    },

    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <span
          className={` rounded-pill px-3 p-1 badge ${
            value === "completed" ? "bg-success" : "bg-warning"
          }`}
        >
          {value}
        </span>
      ),
    },
  ];
  // const BGVcolumns = [
  //   {
  //     Header: "Type",
  //     accessor: "type",
  //     // Cell: ({ row }) => (
  //     //   <Link
  //     //     to={"#"}
  //     //     onClick={()=>{candidateName(row.original.guid)}}
  //     //   >
  //     //     {row.values.type}
  //     //   </Link>
  //     // ),
  //   },
  //   {
  //     Header: "Verifier",
  //     accessor: "verifier",
  // //     width: 100,
  //   },
  //   {
  //     Header: "Initiate Date",
  //     accessor: "createdAt",
  //     Cell: (props) => {
  //       const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
  //       return <span>{tidyDate}</span>;
  //     },
  //   },
  //   {
  //     Header: "Completed Date",
  //     accessor: "updatedAt",
  //     Cell: (props) => {
  //       const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
  //       return <span>{tidyDate}</span>;
  //     },
  //   },
  //   {
  //     Header: "Status",
  //     accessor: "status",
  //   },
  // ];

  const Interviewcolumn = [
    {
      Header: "Interview Id",
      accessor: "interviewer_guids",
      // Cell: ({ row }) => (
      //   <Link
      //     to={"#"}
      //     onClick={() => {
      //       // candidateName(row.original.id);
      //     }}
      //   >
      //     {row.values.interviewer_guids}
      //   </Link>
      // ),
    },
    {
      Header: "Location",
      accessor: "location",
      // width: 100,
    },

    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "scheduled Date",
      accessor: "scheduled_at",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Feedback",
      accessor: "feedback",
      // width: 100,
    },
    {
      Header: "Status",
      accessor: "status",
      // width: 200,
      // Cell: (cellProps) => {
      //   return <Tags {...cellProps} />;
      // },
    },
  ];
  const OfferCanvasColumn = [
    {
      Header: "Offer Id",
      accessor: "offer_id",
      // Cell: ({ row }) => (
      //   <Link
      //     to={"#"}
      //     onClick={() => {
      //       // candidateName(row.original.id);
      //     }}
      //   >
      //     {row.values.interviewer_guids}
      //   </Link>
      // ),
    },
    {
      Header: "Candidate",
      accessor: "candidate_name",
      // width: 100,
    },
    {
      Header: "Title",
      accessor: "title",
      // width: 100,
    },
    {
      Header: "Date Generated",
      accessor: "date_generated",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  const ContractCanvasColumn = [
    {
      Header: "Contract  Id",
      accessor: "contract_guid",
      // Cell: ({ row }) => (
      //   <Link
      //     to={"#"}
      //     onClick={() => {
      //       // candidateName(row.original.id);
      //     }}
      //   >
      //     {row.values.interviewer_guids}
      //   </Link>
      // ),
    },
    {
      Header: "Candidate",
      accessor: "candidate_name",
    },

    {
      Header: "Start Date",
      accessor: "started_at",
    },
    {
      Header: "Status",
      accessor: "Status",
      // width: 100,
    },
  ];

  useEffect(() => {
    if (usertype === "partner" || usertype === "recruitment") {
      // dispatch(shortlistedIndividualCandidate(data.guid));
      // dispatch(InvitationIndividualCandidate(data.guid));

      // dispatch(AssessmentIndividualCandi(data.guid));
      dispatch(BGVIndividualCandi(data.guid));
      // dispatch(CandidateDetailscanvasInterview(data.guid));
      // dispatch(CandidateDetailscanvasOffer(data.guid));
      // dispatch(CandidateDetailscanvasContract(data.guid));
      dispatch(myCandiDetailsShortlistedRole());
    }
  }, []);

  useEffect(() => {
    if (usertype === "partner" || usertype === "recruitment") {
      dispatch(
        shortlistedIndividualCandidate({
          Indguid: data.guid,
          currentPage: currentPage ? currentPage : currentPage,
        }),
      );
    }
  }, [currentPage]);

  useEffect(() => {
    if (usertype === "partner") {
      dispatch(
        InvitationIndividualCandidate({
          Indguid: data.guid,
          currentPage: currentPageinvite
            ? currentPageinvite
            : currentPageinvite,
        }),
      );
    }
  }, [currentPageinvite]);

  useEffect(() => {
    if (usertype === "partner" || usertype === "recruitment") {
      dispatch(
        MachingIndividualCandi({
          Indguid: data.guid,
          currentPage: currentPagematching
            ? currentPagematching
            : currentPagematching,
        }),
      );
    }
  }, [currentPagematching]);

  useEffect(() => {
    if (usertype === "partner") {
      dispatch(
        AssessmentIndividualCandi({
          Indguid: data.guid,
          currentPage: currentPageassessment
            ? currentPageassessment
            : currentPageassessment,
        }),
      );
    }
  }, [currentPageassessment]);

  useEffect(() => {
    if (usertype === "partner" || usertype === "recruitment") {
      dispatch(
        CandidateDetailscanvasOffer({
          Indguid: data.guid,
          currentPage: currentPageoffer ? currentPageoffer : currentPageoffer,
        }),
      );
    }
  }, [currentPageoffer]);

  useEffect(() => {
    if (usertype === "partner") {
      dispatch(
        CandidateDetailscanvasContract({
          Indguid: data.guid,
          currentPage: currentPagecontract
            ? currentPagecontract
            : currentPagecontract,
        }),
      );
    }
  }, [currentPagecontract]);

  useEffect(() => {
    if (usertype === "partner") {
      dispatch(
        CandidateDetailscanvasInterview({
          Indguid: data.guid,
          currentPage: currentPageinterview
            ? currentPageinterview
            : currentPageinterview,
        }),
      );
    }
  }, [currentPageinterview]);

  useEffect(() => {
    if (usertype === "partner" || usertype === "recruitment") {
      dispatch(candidatesOverview(data.guid));
    }
    dispatch(CandidateHistory(data.guid));
  }, [data]);

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    setIsRoleSelected(!!formik.values.title);
  }, [formik.values.title]);

  // useEffect(() => {
  //   if (selectedCandidateAssessmentsData.guid != "") {
  //     dispatch(dummyAssessment(selectedCandidateAssessmentsData.guid));
  //     log_debug(
  //       "selectedCandidateAssessmentsData.guid",
  //       selectedCandidateAssessmentsData.guid
  //     );
  //   }
  // }, [selectedCandidateAssessmentsData]);

  // useEffect(() => {
  //   const fetchAssessments = async () => {
  //     try {
  //       // Assuming you have already set the assessments in the state
  //       const assessments = AssessmentType;

  //       assessments.forEach((assessment) => {
  //         const { type, guid } = assessment;

  //         switch (type) {
  //           case "mcq":
  //             dispatch(assessmentMCQ(guid));
  //             break;
  //           case "coding":
  //             dispatch(assessmentCoding(guid));
  //             break;
  //           case "behavioural":
  //             dispatch(assessmentBehavioural(guid));
  //             break;
  //           default:
  //             console.error(`Unknown assessment type: ${assessment.type}`);
  //         }
  //       });
  //     } catch (error) {
  //       // Handle errors
  //       console.error("Error fetching assessments", error);
  //     }
  //   };

  //   fetchAssessments();
  // }, [AssessmentType]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data.shortlistedIndividualCandidateRecords?.success ===
        true
      ) {
        setMyCandidateDetailsShortlisted(
          partnerStore.data.shortlistedIndividualCandidateRecords?.data
            ?.jobDescriptions,
        );
        setAllsortlistcount(
          partnerStore.data.shortlistedIndividualCandidateRecords?.data
            ?.totalCount,
        );
        setisloadingshortlist(false);
      }
      if (
        partnerStore.data.InvitationIndividualCandidateRecords?.success === true
      ) {
        setMyCandidateDetailsInvitation(
          partnerStore.data.InvitationIndividualCandidateRecords?.data
            ?.jobDescriptions,
        );
        setAllinvitationcount(
          partnerStore.data.InvitationIndividualCandidateRecords?.data
            ?.totalCount,
        );
        setisloadingInvitation(false);
      }
      if (partnerStore.data.MachingIndividualCandiRecords?.success === true) {
        setMyCandidateDetailsMatching(
          partnerStore.data.MachingIndividualCandiRecords?.data
            .matchingJobDescriptions,
        );
        setAllMatchtcount(
          partnerStore.data.MachingIndividualCandiRecords?.data?.totalCount,
        );
        setisloadingMatching(false);
      }
      if (
        partnerStore.data.AssessmentIndividualCandiRecords?.success === true
      ) {
        setMyCandidateDetailsassessment(
          partnerStore.data.AssessmentIndividualCandiRecords?.data.assessments,
        );
        setAllassessmentcount(
          partnerStore.data.AssessmentIndividualCandiRecords?.data?.totalCount,
        );
        setisloadingAssessment(false);
      }
      if (partnerStore.data.BGVIndividualCandiRecords?.success === true) {
        setMyCandidateDetailsBGV(
          partnerStore.data.BGVIndividualCandiRecords?.data
            .backgroundverifications,
        );
      }
      // if (partnerStore.data.dummyAssessmentdata?.success === true) {
      //   setAssessmentType(
      //     partnerStore.data.dummyAssessmentdata?.data.assessments
      //   );
      // }
      // if (partnerStore.data.assessmentMCQdata?.success === true) {
      //   setMcqDetails(
      //     partnerStore.data.assessmentMCQdata?.data.assessmentquestions
      //   );
      // }
      // if (partnerStore.data.assessmentCodingdata?.success === true) {
      //   setCodingDetails(
      //     partnerStore.data.assessmentCodingdata?.data.assessmentquestions
      //   );
      // }
      // if (partnerStore.data.assessmentBehaviouraldata?.success === true) {
      //   setBehaviouralDetails(
      //     partnerStore.data.assessmentBehaviouraldata?.data.assessmentquestions
      //   );
      // }
      if (partnerStore.data.partnerdata?.success === true) {
        setcandidatedata(partnerStore.data.partnerdata?.data?.individuals);
      }
      if (partnerStore.data.candidatesOverviewdata?.success === true) {
        setCandidateOverviewinfo(
          partnerStore.data.candidatesOverviewdata?.data?.individual_details,
        );
      }
      if (partnerStore.data.CandidateHistorydata?.success === true) {
        setCandidateHistoryinfo(
          partnerStore.data.CandidateHistorydata?.data.individualhistory,
        );
      }
      if (
        partnerStore.data.myCandiDetailsShortlistedRoleRecords?.success === true
      ) {
        setJobRoleOptions(
          partnerStore.data.myCandiDetailsShortlistedRoleRecords?.data
            ?.enumjobroles,
        );
        log_debug("JobRole data:", JobRoleOptions);
      }
      if (partnerStore.data.machingJobApplyJobRecords?.success === true) {
        setJobApply(
          partnerStore.data.machingJobApplyJobRecords?.data
            .individualjobmapping,
        );
      }
      if (
        partnerStore.data.CandidateDetailscanvasInterviewdata?.success === true
      ) {
        setCandidateDetailsIntervivew(
          partnerStore.data.CandidateDetailscanvasInterviewdata?.data
            .interview_details,
        );
        setAllinterviewcount(
          partnerStore.data.CandidateDetailscanvasInterviewdata?.data
            .totalCount,
        );
        setisloadingInterview(false);
      }

      if (partnerStore.data.CandidateDetailscanvasOfferdata?.success === true) {
        setCandidateDetailsOffer(
          partnerStore.data.CandidateDetailscanvasOfferdata?.data
            ?.offer_details,
        );
        setAllinoffercount(
          partnerStore.data.CandidateDetailscanvasOfferdata?.data?.totalCount,
        );
        setisloadingOffer(false);
      }

      if (
        partnerStore.data.CandidateDetailscanvasContractdata?.success === true
      ) {
        setCandidateDetailsContract(
          partnerStore.data.CandidateDetailscanvasContractdata?.data
            ?.contract_details,
        );
        setAllcontractcount(
          partnerStore.data.CandidateDetailscanvasContractdata?.data
            ?.totalCount,
        );
        setisloadingContract(false);
      }
      if (
        partnerStore.data.CandidateAssessmentInvitationdata?.success === true
      ) {
        setAssessmentInvite(
          partnerStore.data.CandidateAssessmentInvitationdata?.data?.message,
        );
      }
    }
  }, [partnerStore]);

  useEffect(() => {
    SetJobGuid(data.guid);
  }, []);

  //parameters for client Tables
  const [ClientInvitedJobs, setClientInvitedJobs] = useState([]);
  const [ClientShortlistedJobs, setClientShortlistedJobs] = useState([]);
  const [ClientMatchingJobs, setClientMatchingJobs] = useState([]);

  //Client Api Calls for table
  useEffect(() => {
    if (usertype === "client") {
      if (
        menuOption !== "allcandidates" &&
        menuOption !== "shortlisted" &&
        menuOption !== "appliedcandidates"
      ) {
        dispatch(
          getCandidateInvitedJobs({
            guid: data?.guid,
            currentPage: currentPage1 ? currentPage1 : 0,
          }),
        );
      }
    }
  }, [currentPage1]);

  useEffect(() => {
    if (usertype === "client") {
      if (
        menuOption !== "allcandidates" &&
        menuOption !== "invitations" &&
        menuOption !== "appliedcandidates"
      ) {
        dispatch(
          getCandidateShortlistedJobs({
            guid: data?.guid,
            currentPage: currentPage2 ? currentPage2 : 0,
          }),
        );
      }
    }
  }, [currentPage2]);

  useEffect(() => {
    if (usertype === "client") {
      dispatch(
        getCandidateMatchingJobs({
          guid: data?.guid,
          currentPage: currentPage3 ? currentPage3 : 0,
        }),
      );
    }
  }, [currentPage3]);

  useEffect(() => {
    if (clientStore.status === "idle") {
      if (clientStore.data.InvitedJobs?.success === true) {
        setClientInvitedJobs(
          clientStore.data.InvitedJobs?.data?.jobdescriptions,
        );
        setInvitedCount(clientStore.data.InvitedJobs?.data?.totalCount);
      }
      if (clientStore.data.ShortlistedJobs?.success === true) {
        setClientShortlistedJobs(
          clientStore.data.ShortlistedJobs?.data?.jobdescriptions,
        );
        setShortlistedCount(clientStore.data.ShortlistedJobs?.data?.totalCount);
      }
      if (clientStore.data.CandidateMatchingJobs?.success === true) {
        setClientMatchingJobs(
          clientStore.data.CandidateMatchingJobs?.data?.jobdescription,
        );
        setMatchingJobsCount(
          clientStore.data.CandidateMatchingJobs?.data?.totalCount,
        );
      }
    }
  }, [clientStore]);

  const handleAction = (e) => {
    // Assuming 'value' contains the data you want to pass
    const dataToPass = {
      CandidateOverviewinfo: CandidateOverviewinfo,
      data: data,
    };

    const candidatedata = {
      candidates: {
        data: {
          resumes: [data]
        }
      }
    };

    // Use 'to' and 'state' props to navigate and pass data
    e.preventDefault();
    navigate("/candidatedetails", { state: { individualResumes: candidatedata } });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handlePageChangeInvite = (newPage) => {
    setCurrentPageinvite(newPage);
  };
  const handlePageChangeMatch = (newPage) => {
    setCurrentPagematching(newPage);
  };
  const handlePageChangeAssessment = (newPage) => {
    setCurrentPageassessment(newPage);
  };
  const handlePageChangeInterview = (newPage) => {
    setCurrentPageinterview(newPage);
  };
  const handlePageChangeOffer = (newPage) => {
    setCurrentPageoffer(newPage);
  };
  const handlePageChangeContract = (newPage) => {
    setCurrentPagecontract(newPage);
  };

  //Client pagination logic
  const handleInvitedPageChangeClient = (newPage) => {
    setCurrentPage1(newPage);
  };

  const handleShortlistedPageChangeclient = (newPage) => {
    setCurrentPage2(newPage);
  };

  const handleMatchingJobsPageChangeClient = (newPage) => {
    setCurrentPage3(newPage);
  };

  const closeGenerateResumeCanvas = () => {
    setisGenerateResumeCanvasOpen(false);
  };

  const handleGenerateResume = (data) => {
    setisGenerateResumeCanvasOpen(true);
    setSelectedCandidateResume(data);
    setDownloadActiveStatus(false);
  };

  const handleDownloadResume = (data) => {
    setisGenerateResumeCanvasOpen(true);
    setSelectedCandidateResume(data);
    setDownloadActiveStatus(true);
  };

  return (
    <>
      {DisableApply && <Preloader />}
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          {/* <p className="text-light">Candidate Details</p> */}
          <h5 className="text-primary">
            Candidate Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Present comprehensive details
              for the specifically selected candidate.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="row pt-2">
            <div className="row ">
              <div
                className="col-md-6 mx-3 p-3 card"
                style={{ backgroundColor: "#F2F2F2" }}
              >
                <div className="col-sm order-2 order-sm-1">
                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                    <div className="flex-shrink-0">
                      <div className="avatar-xl me-3">
                        <img
                          src={avatar}
                          alt=""
                          className="img-fluid rounded-circle d-block border border-secondary"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      {/* <h5 className="font-size-16 mb-2">{data.full_name}
                    <Badge color={data.status === "contracted"?"success":"warning"} className="float-end font-size-12 ">{data.status}</Badge>
                    </h5> */}

                      <div>
                        {/* <h5 className="font-size-14 mb-1">
                          {data && data.full_name}
                        </h5> */}
                        <h5 className="font-size-16 ">
                          {data.full_name}
                          <div className="float-end font-size-12 ">
                            <CandidateStatusBadge status={data.status} />
                          </div>
                          {/* <Badge
                            color={
                              data.status === "shortlisted"
                                ? "info rounded-pill"
                                : data.status === "contracted"
                                  ? "success rounded-pill"
                                  : data.status === "on_bench"
                                    ? "warning rounded-pill"
                                    : "secondary rounded-pill" // Set a default color if status doesn't match any condition
                            }
                            className="float-end font-size-12 "
                          >
                            {data.status}
                          </Badge> */}
                        </h5>
                        {/* <p className="text-muted font-size-13  mb-1">
                          {data.professional_role ? data.professional_role : 'NA'}
                        </p> */}

                        {/* <p className="text-muted font-size-13  mb-1">
                          <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>
                          {data.city ? data.city : "NA"}
                        </p> */}

                        <div className=" flex-wrap align-items-start text-muted font-size-13">
                          {data.contact_number ? (
                            <div>
                              <i className="mdi mdi-phone me-1 text-primary align-middle w-100"></i>
                              <span className="mb-1">
                                {data.contact_number}
                              </span>
                            </div>
                          ) : (
                            <strong> -- </strong>
                          )}
                          <div>
                            <i className="mdi mdi-email me-1 text-primary align-middle w-100"></i>
                            {data.email}
                          </div>
                        </div>

                        <div className="d-flex flex-wrap align-items-start   text-muted font-size-13 mt-1">
                          <div>
                            <span>
                              {data &&
                                data?.skills?.map((skills, index) => (
                                  <Link
                                    key={index}
                                    to="#"
                                    className="badge bg-primary rounded-pill mx-1 p-1"
                                  >
                                    {skills}
                                  </Link>
                                ))}
                            </span>
                          </div>
                        </div>
                        {/* <div>
                          <p>{renderSummary()}</p>
                        </div> */}
                        <div className="mt-2">
                          <Link
                            to="#"
                            onClick={() => handleGenerateResume(data)}
                            className="btn btn-primary btn-sm m-1"
                            hidden
                          >
                            Gen Resume <i className="fas fa-magic ms-1" />
                          </Link>

                          <Link
                            to="#"
                            onClick={() => handleDownloadResume(data)}
                            className="btn btn-primary btn-sm m-1"
                          >
                            Download Resume{" "}
                            <i className="fas fa-download ms-1" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <TIQcard
                // title="TIQ Score"
                // Name=""
                // score={90}
                // candidatestatus={props.data.status}
                // menuOption={props.menuOption}
                />{" "}
              </div> */}
            </div>
          </div>
          <Row>
            <Col xl={12}>
              <div>
                <CardHeader className="align-items-center d-flex my-2">
                  {/* <h4 className="card-title mb-0 flex-grow-1">
                    Card Header Tabs
                  </h4> */}
                  <div className="flex-shrink-0">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      {(usertype === "partner" ||
                        usertype === "recruitment") && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "1",
                            })}
                            onClick={() => {
                              toggleHeader("1");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">Overview </span>
                          </NavLink>
                        </NavItem>
                      )}
                      {(usertype === "partner" ||
                        usertype === "recruitment") && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "2",
                            })}
                            onClick={() => {
                              toggleHeader("2");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              {/* {usertype === "recruitment" && "Shortlisted "} */}
                              Shortlisted Jobs{" "}
                              <Badge color="primary">
                                {(Allsortlistcount ? Allsortlistcount : 0)
                                  .toString()
                                  .padStart(2, "0")}
                              </Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                      {usertype === "partner" && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "3",
                            })}
                            onClick={() => {
                              toggleHeader("3");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Invitation{" "}
                              <Badge color="primary">
                                {(MyCandidateDetailsInvitation
                                  ? MyCandidateDetailsInvitation?.length
                                  : 0
                                )
                                  .toString()
                                  .padStart(2, "0")}
                              </Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                      {(usertype === "partner" ||
                        usertype === "recruitment") && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "4",
                            })}
                            onClick={() => {
                              toggleHeader("4");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Matching Jobs{" "}
                              <Badge color="primary">
                                {(AllMatchtcount ? AllMatchtcount : 0)
                                  .toString()
                                  .padStart(2, "0")}
                              </Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                      {usertype === "partner" && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "5",
                            })}
                            onClick={() => {
                              toggleHeader("5");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Assessment{" "}
                              <Badge color="primary">
                                {(MyCandidateDetailsassessment
                                  ? MyCandidateDetailsassessment?.length
                                  : 0
                                )
                                  .toString()
                                  .padStart(2, "0")}
                              </Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                      {usertype === "partner" && (
                        <NavItem>
                          {/* <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "6",
                            })}
                            onClick={() => {
                              toggleHeader("6");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              BGV <Badge color="primary">Comming soon</Badge>
                            </span>
                          </NavLink> */}
                        </NavItem>
                      )}
                      {usertype === "partner" && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "7",
                            })}
                            onClick={() => {
                              toggleHeader("7");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Interview{" "}
                              <Badge color="primary">
                                {(CandidateDetailsIntervivew
                                  ? CandidateDetailsIntervivew?.length
                                  : 0
                                )
                                  .toString()
                                  .padStart(2, "0")}
                              </Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                      {usertype === "partner" && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "8",
                            })}
                            onClick={() => {
                              toggleHeader("8");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Offer{" "}
                              <Badge color="primary">
                                {(CandidateDetailsOffer
                                  ? CandidateDetailsOffer?.length
                                  : 0
                                )
                                  .toString()
                                  .padStart(2, "0")}
                              </Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                      {usertype === "partner" && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "9",
                            })}
                            onClick={() => {
                              toggleHeader("9");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Contract <Badge color="primary">00</Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                      {/* Client Tabs */}
                      {usertype === "client" && (
                        <>
                          <NavItem>
                            <NavLink
                              href="#"
                              className={classnames({
                                active: headerTab === "1",
                              })}
                              onClick={() => {
                                toggleHeader("1");
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-envelope"></i>
                              </span>
                              <span className="d-none d-sm-block">
                                Overview
                              </span>
                            </NavLink>
                          </NavItem>

                          {(menuOption === "shortlisted" ||
                            menuOption === "matchingcandidates") && (
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({
                                  active: headerTab === "2",
                                })}
                                onClick={() => {
                                  toggleHeader("2");
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-envelope"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Shortlisted Jobs{" "}
                                  <Badge color="primary">
                                    {ClientShortlistedJobs &&
                                      ClientShortlistedJobs.length}
                                  </Badge>
                                </span>
                              </NavLink>
                            </NavItem>
                          )}

                          {(menuOption === "invitations" ||
                            menuOption === "matchingcandidates") && (
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({
                                  active: headerTab === "3",
                                })}
                                onClick={() => {
                                  toggleHeader("3");
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-envelope"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Invited Jobs{" "}
                                  <Badge color="primary">
                                    {ClientInvitedJobs &&
                                      ClientInvitedJobs.length}
                                  </Badge>
                                </span>
                              </NavLink>
                            </NavItem>
                          )}

                          <NavItem>
                            <NavLink
                              href="#"
                              className={classnames({
                                active: headerTab === "4",
                              })}
                              onClick={() => {
                                toggleHeader("4");
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="far fa-envelope"></i>
                              </span>
                              <span className="d-none d-sm-block">
                                Matching Jobs{" "}
                                <Badge color="primary">
                                  {MatchingJobsCount}
                                </Badge>
                              </span>
                            </NavLink>
                          </NavItem>

                          {/* <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "5",
                          })}
                          onClick={() => {
                            toggleHeader("5");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">Interviews</span>
                        </NavLink>
                      </NavItem> */}
                        </>
                      )}
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "10",
                          })}
                          onClick={() => {
                            toggleHeader("10");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">History</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>

                <div className="card-body">
                  <TabContent className="text-muted" activeTab={headerTab}>
                    <TabPane tabId="1">
                      {/* To be converted to component */}
                      {usertype === "client" && <Overview guid={data.guid} />}
                      {(usertype === "partner" ||
                        usertype === "recruitment") && (
                        <div className="card">
                          <div className="card-body">
                            {/* <div className="float-end">
                              <Link
                                to={"/candidatedetails"}
                                color="primary"
                                type="button"
                                className=" btn btn-primary text-white my-2 rounded "
                                onClick={(e) => {
                                  handleAction(e);
                                }}
                              >
                                <FiEdit className="mx-1" />
                                {/* Edit *
                              </Link>
                            </div> */}
                            {CandidateOverviewinfo &&
                              CandidateOverviewinfo.map(
                                (Candidateinfo, index) => (
                                  <div key={index} className="col-12">
                                    <div className="pt-3">
                                      <h5 className="font-size-15">
                                        Summary :
                                      </h5>
                                      <div className="">
                                        <p className="mb-2">
                                          {/* <i className="mdi mdi-circle-medium me-1 text-success align-middle" /> */}
                                          {Candidateinfo?.summary
                                            ? Candidateinfo.summary
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="pt-3">
                                      <h5 className="font-size-15">
                                        Experience :{" "}
                                      </h5>
                                      <div className="">
                                        <ul className="list-unstyled mb-0">
                                          {Candidateinfo?.resume?.work_experiences.map(
                                            (experience, expIndex) => (
                                              <li
                                                className="font-size-14"
                                                key={expIndex}
                                              >
                                                <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                                <strong className="text-muted ">
                                                  {experience.role}&nbsp;&nbsp;
                                                </strong>
                                                <p className="mx-3 font-size-14">
                                                  - {experience.company_name}{" "}
                                                  &nbsp;&nbsp;
                                                  <em>
                                                    {new Date(
                                                      experience.from_date,
                                                    ).toLocaleDateString()}{" "}
                                                    <strong className="text-primary">
                                                      -
                                                    </strong>{" "}
                                                    {new Date(
                                                      experience.to_date,
                                                    ).toLocaleDateString()}
                                                  </em>
                                                </p>
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="pt-3">
                                      <h5 className="font-size-15">
                                        {" "}
                                        Education :{" "}
                                      </h5>
                                      <div className="">
                                        <ul className="list-unstyled mb-0">
                                          {Candidateinfo?.resume?.educational_qualifications.map(
                                            (education, eduIndex) => (
                                              <li
                                                className="font-size-14"
                                                key={eduIndex}
                                              >
                                                <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                                <strong className="text-muted ">
                                                  {education.degree_name}
                                                  &nbsp;&nbsp;
                                                </strong>
                                                <p className="mx-3 font-size-14">
                                                  -{" "}
                                                  {
                                                    education.college_or_university_name
                                                  }{" "}
                                                  &nbsp;&nbsp;{" "}
                                                  <em>
                                                    {new Date(
                                                      education.from_date,
                                                    ).toLocaleDateString()}
                                                    <strong className="text-primary">
                                                      {" "}
                                                      -{" "}
                                                    </strong>
                                                    {new Date(
                                                      education.to_date,
                                                    ).toLocaleDateString()}
                                                  </em>
                                                </p>
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ),
                              )}
                          </div>
                          {/* end card body */}
                        </div>
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      {usertype === "client" && (
                        <ShortlistedJobs
                          data={ClientShortlistedJobs}
                          RecordCount={ShortlistedCount}
                          handlePageChange={handleShortlistedPageChangeclient}
                          currentPage={currentPage1}
                        />
                      )}

                      {(usertype === "partner" ||
                        usertype === "recruitment") && (
                        <>
                          <Row>
                            <CustomTable
                              totalCount={Allsortlistcount && Allsortlistcount}
                              columns={columnsShortlisted}
                              data={MyCandidateDetailsShortlisted}
                              hideCheckbox={true}
                              currentPage={currentPage}
                              isLoading={isloadingshortlist}
                              onPageChange={handlePageChange}
                            />
                          </Row>
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId="3">
                      {usertype === "client" && (
                        <InvitedJobs
                          data={ClientInvitedJobs}
                          RecordCount={InvitedCount}
                          handlePageChange={handleInvitedPageChangeClient}
                          currentPage={currentPage2}
                        />
                      )}

                      {usertype === "partner" && (
                        <p className="mb-0">
                          {/* It has survived not only five centuries, but also the leap
                      into electronic typesetting, remaining essentially
                      unchanged. It was popularised in the 1960s with the
                      release of Letraset sheets containing */}
                          <CustomTable
                            currentPage={currentPageinvite}
                            onPageChange={handlePageChangeInvite}
                            totalCount={
                              Allinvitationcount && Allinvitationcount
                            }
                            columns={columnsInvitation}
                            isLoading={isloadingInvitation}
                            data={MyCandidateDetailsInvitation}
                            hideCheckbox={true}
                          />
                        </p>
                      )}
                    </TabPane>
                    <TabPane tabId="4">
                      <p className="mb-0"></p>
                      {usertype === "client" && (
                        <MatchingJobs
                          data={ClientMatchingJobs}
                          status={data.status}
                          individualguid={data.guid}
                          RecordCount={MatchingJobsCount}
                          handlePageChange={handleMatchingJobsPageChangeClient}
                          currentPage={currentPage3}
                        />
                      )}

                      {(usertype === "partner" ||
                        usertype === "recruitment") && (
                        <>
                          {/* <Row>
                            <Col className="col-12 text-end">
                              <button type="button" className="btn btn-primary">
                                <FaBusinessTime /> Apply Job
                              </button>
                            </Col>
                          </Row> */}
                          <Row>
                            <h5 className="text-primary font-size-14 mt-1">
                              {matching_job_title}&nbsp;&nbsp;
                              <span className="font-size-12 text-muted mb-2">
                                <i className="fas fa-info-circle" />
                                {matching_job_sub_title}
                              </span>
                            </h5>
                            {/* {forceRefresh ? (
                              <CustomTable
                                JobGuid={JobGuid}
                                ApplymultiJobs={true}
                                columns={columnsMatching}
                                data={MyCandidateDetailsMatchingRefresh}
                              />
                            ) : (
                              <CustomTable
                                JobGuid={JobGuid}
                                ApplymultiJobs={true}
                                columns={columnsMatching}
                                data={MyCandidateDetailsMatching}
                              />
                            )} */}
                            <CustomTable
                              currentPage={currentPagematching}
                              onPageChange={handlePageChangeMatch}
                              totalCount={AllMatchtcount && AllMatchtcount}
                              isLoading={isloadingMatching}
                              JobGuid={JobGuid}
                              ApplymultiJobs={true}
                              columns={columnsMatching}
                              data={
                                MyCandidateDetailsMatching &&
                                MyCandidateDetailsMatching
                              }
                            />
                          </Row>
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId="5">
                      {usertype === "partner" && (
                        <>
                          <Row>
                            <CustomTable
                              currentPage={currentPageassessment}
                              onPageChange={handlePageChangeAssessment}
                              totalCount={
                                Allassessmentcount && Allassessmentcount
                              }
                              columns={Assessmentcolumns}
                              data={MyCandidateDetailsassessment}
                              isLoading={isloadingAssessment}
                              hideCheckbox={true}
                            />
                          </Row>
                          <hr />
                          <Row className="mycandiAssessmentform mt-5 px-5 mb-5 d-flex align-items-center justify-content-center">
                            <h5 className="font-size-14 text-primary">
                              {assessment_form_title}&nbsp;&nbsp;
                              <span className="font-size-12 text-muted">
                                {" "}
                                <i className="fas fa-info-circle" />
                                {assessment_form_sub_title}
                              </span>
                            </h5>
                            <Col className="col-lg-8">
                              <form
                                onSubmit={formik.handleSubmit}
                                className="container mt-5"
                              >
                                <div className="mb-3">
                                  <label
                                    htmlFor="full_name"
                                    className="form-label"
                                  >
                                    Name:
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    id="full_name"
                                    name="full_name"
                                    className={`form-control ${
                                      formik.touched.full_name &&
                                      formik.errors.full_name
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.full_name}
                                    style={{
                                      backgroundColor: "#f2f2f2",
                                    }}
                                  />
                                  {formik.touched.full_name &&
                                    formik.errors.full_name && (
                                      <div className="invalid-feedback">
                                        {formik.errors.full_name}
                                      </div>
                                    )}
                                </div>

                                <div className="mb-3">
                                  <label
                                    htmlFor="email_id"
                                    className="form-label"
                                  >
                                    Email ID:
                                  </label>
                                  <input
                                    type="email_id"
                                    disabled
                                    id="email_id"
                                    name="email_id"
                                    className={`form-control ${
                                      formik.touched.email_id &&
                                      formik.errors.email_id
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email_id}
                                    style={{
                                      backgroundColor: "#f2f2f2",
                                    }}
                                  />
                                  {formik.touched.email_id &&
                                    formik.errors.email_id && (
                                      <div className="invalid-feedback">
                                        {formik.errors.email_id}
                                      </div>
                                    )}
                                </div>

                                <div className="mb-3">
                                  <label htmlFor="title" className="form-label">
                                    title:
                                  </label>
                                  <select
                                    id="title"
                                    name="title"
                                    className={`form-select ${
                                      formik.touched.title &&
                                      formik.errors.title
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.title}
                                  >
                                    <option value="" hidden>
                                      Select Role
                                    </option>
                                    {JobRoleOptions &&
                                      JobRoleOptions.map((option, index) => (
                                        <option
                                          key={index}
                                          value={option.title}
                                        >
                                          {option.title}
                                        </option>
                                      ))}
                                  </select>
                                  {formik.touched.title &&
                                    formik.errors.title && (
                                      <div className="invalid-feedback">
                                        {formik.errors.title}
                                      </div>
                                    )}
                                </div>

                                <div className="mb-3">
                                  <button
                                    type="submit"
                                    // className="btn btn-primary"
                                    className={`btn btn-primary btn-sm ${
                                      isRoleSelected ? "" : "disabled"
                                    }`}
                                    disabled={!isRoleSelected}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </Col>
                          </Row>
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId="6">
                      {usertype === "partner" && (
                        <>
                          {/* {MyCandidateDetailsBGV.length >= 1 ? (
                            <Row>
                              <CustomTable
                                columns={BGVcolumns}
                                data={MyCandidateDetailsBGV}
                              />
                            </Row>
                          ) : (
                            
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '30vh' }}>
  <h4 className="text-muted">Coming soon</h4>
</div>

                          )} */}
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId="7">
                      <Row>
                        <CustomTable
                          currentPage={currentPageinterview}
                          onPageChange={handlePageChangeInterview}
                          totalCount={Allinterviewcount && Allinterviewcount}
                          columns={Interviewcolumn}
                          data={
                            CandidateDetailsIntervivew &&
                            CandidateDetailsIntervivew
                          }
                          isLoading={isloadingInterview}
                          hideCheckbox={true}
                        />
                      </Row>
                    </TabPane>
                    <TabPane tabId="8">
                      <Row>
                        <CustomTable
                          currentPage={currentPageoffer}
                          onPageChange={handlePageChangeOffer}
                          totalCount={Allinoffercount && Allinoffercount}
                          columns={OfferCanvasColumn}
                          data={CandidateDetailsOffer && CandidateDetailsOffer}
                          hideCheckbox={true}
                          isLoading={isloadingOffer}
                        />
                      </Row>
                    </TabPane>
                    <TabPane tabId="9">
                      <Row>
                        <CustomTable
                          currentPage={currentPagecontract}
                          onPageChange={handlePageChangeContract}
                          totalCount={Allcontractcount && Allcontractcount}
                          columns={ContractCanvasColumn}
                          data={
                            CandidateDetailsContract && CandidateDetailsContract
                          }
                          isLoading={isloadingContract}
                          hideCheckbox={true}
                        />
                      </Row>
                    </TabPane>
                    <TabPane tabId="10">
                      <TimeLineComponent
                        historydata={
                          CandidateHistoryinfo && CandidateHistoryinfo
                        }
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
      {isOffcanvasOpenBGV && (
        <InitiateBGVOffcanvas
          placement="end"
          isOpen={isOffcanvasOpenBGV}
          toggle={() => setisOffcanvasOpenBGV(!isOffcanvasOpenBGV)}
          onClose={closeOffcanvasBGV}
          content={`Details for candidate with guid: ${selectedCandidateBGV}`}
        />
      )}

      {/* this off canvas is used for shortlisted, invitation and Matching jobs */}
      {isOffcanvasOpenShortlisted && (
        <CanvasPartnerJobDetails
          RecrutmentMatchingstatus={true}
          shortlistedstatus={true}
          placement="end"
          isOpen={isOffcanvasOpenShortlisted}
          toggle={() =>
            setOffcanvasOpenShortlisted(!isOffcanvasOpenShortlisted)
          }
          onclose={closeOffcanvasShortlisted}
          data={selectedCandidateShortlisted}
        />
      )}
      {isOffcanvasOpenInvitation && (
        <CanvasPartnerJobDetails
          invitationstatus={true}
          placement="end"
          isOpen={isOffcanvasOpenInvitation}
          toggle={() => setOffcanvasOpenInvitation(!isOffcanvasOpenInvitation)}
          onclose={closeOffcanvasinvitation}
          data={selectedCandidateInvitation}
        />
      )}
      {isOffcanvasOpenMatching && (
        <CanvasPartnerJobDetails
          usertype={"recruitment"}
          Matchingstatus={true}
          placement="end"
          isOpen={isOffcanvasOpenMatching}
          toggle={() => setOffcanvasOpenMatching(!isOffcanvasOpenMatching)}
          onclose={closeOffcanvasMatching}
          indi_guid={data.guid}
          data={selectedCandidateMatching}
        />
      )}

      {/* this off canvas is used for assessment */}
      {isOffcanvasOpenAssessment && (
        <CanvasAssessment
          usertype="partner"
          menuOption="assessment"
          width={50}
          placement="end"
          isOpen={isOffcanvasOpenAssessment}
          toggle={() => setOffcanvasOpenAssessment(!isOffcanvasOpenAssessment)}
          onclose={closeOffcanvasAssessment}
          //data={[mcqDetails, codingDetails, behaviouralDetails]}
          // selectedCandidateAssessmentsData={selectedCandidateAssessmentsData}
          // mcqDetails={mcqDetails}
          // codingDetails={codingDetails}
          // behaviouralDetails={behaviouralDetails}
          showTIQCard={false}
          guid={selectedCandidateAssessmentsData.guid}
        />
      )}
      {isGenerateResumeCanvasOpen && (
        <GenerateResumeCanvas
          width={60}
          isOpen={isGenerateResumeCanvasOpen}
          toggle={() =>
            setisGenerateResumeCanvasOpen(!isGenerateResumeCanvasOpen)
          }
          onClose={closeGenerateResumeCanvas}
          data={SelectedCandidateResume}
          downloadstatus={DownloadActiveStatus}
          usertype={usertype}
        />
      )}
    </>
  );
}

export default CanvasCandidate;

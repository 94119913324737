import axios from "axios";
import { baseURL, common, baseURLassess } from "./axiosDev";

export const sendGetRestRequestAssessment = async function (api_ep) {
  try {
    const response = await axios.get(baseURLassess + api_ep, {
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data",
        service_ref: "b8ilcgos",
      },
      data: "",
    });
    return response;
  } catch (error) {
    // log("Error in sendGetRestRequest:", error);
    throw error;
  }
};

export const sendGetRestRequest = async function (api_ep) {
  try {
    const response = await axios.get(baseURL + api_ep, {
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data",
        service_ref: common.service_ref,
      },
      data: "",
    });
    return response;
  } catch (error) {
    // log("Error in sendGetRestRequest:", error);
    throw error;
  }
};

export const sendGetRestRequestJson = async function (api_ep) {
  try {
    const response = await axios.get(baseURL + api_ep, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        service_ref: common.service_ref,
      },
      data: "",
    });
    return response;
  } catch (error) {
    // log("Error in sendGetRestRequest:", error);
    throw error;
  }
};

export const sendPostRestRequest = async function (api_ep, req_body) {
  try {
    const response = await axios.post(baseURL + api_ep, req_body, {
      method: "post",
      headers: {
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        service_ref: common.service_ref,
      },
    });
    return response;
  } catch (error) {
    console.error("Error in sendPostRestRequest:", error);
    throw error;
  }
};

export const sendPostRestRequestAppJson = async function (api_ep, req_body) {
  try {
    const response = await axios.post(baseURL + api_ep, req_body, {
      method: "post",
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        service_ref: common.service_ref,
      },
    });
    return response;
  } catch (error) {
    // log("Error in sendPostRestRequestAppJson:", error);

    throw error;
  }
};

export const generate_guid = function () {
  return common.guid_format.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

import React from 'react'
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
  } from "reactstrap";
  import { Link } from "react-router-dom";
  import { FiEdit, FiTrash2 } from "react-icons/fi";
  
  //import images
  import avatar from "../../assets/images/users/avatar-2.jpg";
  import collage from "../../assets/images/companyBenchImage/mumbaiUniversity.png";
import { formatDate } from '../../helpers/date';

function CandidateEducationDetails({data,handleDeleteEdu}) {
  const handleDeleteEducation = (index) => {
    handleDeleteEdu(index)
  }
  return (
    <React.Fragment>
      <Row>
        <Col xl={12} lg={12}>
          {data?.length > 0 ? (
            data?.map((edu, i) => (
              <Card key={i} style={{ backgroundColor: "ghostwhite" }}>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div className="flex-shrink-0">
                          <div className="avatar-xl me-3">
                            <img
                              src={collage}
                              alt=""
                              className="img-fluid rounded-circle d-block border border-secondary"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              {edu.degree_name}
                            </h5>
                            <h5 className="text-muted font-size-13 mb-1">
                              {edu.college_or_university_name}
                            </h5>
                            <h5 className="text-muted font-size-13 mb-1">
                              {edu.from_date ? formatDate(edu.from_date) : "-"} -{" "}
                              {formatDate(edu.to_date)}
                            </h5>
                            {/* <h5 className="text-dark font-size-13 mb-1">
                              <strong>Grade</strong>{" "}
                              <span className="text-muted">- 9.5 CGPA</span>
                            </h5> */}

                            {/* <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-13 mt-3">
                              <div>
                                <i className="mdi mdi-bookmark me-1 text-primary align-middle"></i>{" "}
                                <span className=" text-muted">
                                  Information Technology
                                </span>
                              </div>
                            </div> */}
                            {/* <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                              <div>
                                <i className="mdi mdi-account-details me-1 text-primary align-middle"></i>
                                <span>
                                  Experienced Full Stack Developer with
                                  proficiency in diverse technologies, seeking
                                  new challenges...
                                  <Link to="#">See more</Link>
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-auto order-1 order-sm-2">
                      <div className="d-flex align-items-start justify-content-end gap-2">
                        <ButtonGroup>
                          <Button
                            color="danger"
                            onClick={() => handleDeleteEducation(i)}
                          >
                            <FiTrash2 /> Delete
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            ))
          ) : (
            <Alert
              color="info"
              className="alert-label-icon label-arrow rounded-2"
            >
              No education details found. Click the 'Add' button to include your
              education details and highlight your expertise. 🏆📜
              <i className="mdi mdi-alert-circle-outline label-icon"></i>
            </Alert>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CandidateEducationDetails
import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { log_debug } from "../../apiHelpers/logger";

const MyCandidatesDetailsFrom = () => {
  const [isEditing, setEditing] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [formData, setFormData] = useState({
    // Section 1
    profilePicture: null,
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    location: null,
    summary: "",

    // Section 2
    resumeFile: null,
    monthlyBudget: "",
    totalExperience: "",
    status: null,
    skills: [],
    previousSkills: "",
  });
  const location = useLocation();
  // Check if the URL contains '/addcandidate/show'
  const isShowComponent = location.pathname.includes("/addcandidate");

  const handleEdit = () => {
    setEditing(true);
  };

  const handleUpdate = () => {
    // Perform update logic (e.g., API call, state update, etc.)
    // For simplicity, let's just log the updated form data
    log_debug("Updated Form Data:", formData);

    // After updating, switch back to non-editing mode
    setEditing(false);
  };

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      //   ...prevData,
      [name]: value,
    }));
  };

  const handleLocationChange = (selectedOption) => {
    handleInputChange("location", selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    handleInputChange("status", selectedOption);
  };

  const handleSkillsChange = (selectedOptions) => {
    handleInputChange("skills", selectedOptions);
  };

  const onDrop = (acceptedFiles) => {
    handleInputChange("resumeFile", acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".pdf",
    multiple: false,
    onDragEnter: () => setHovered(true),
    onDragLeave: () => setHovered(false),
  });

  const locationOptions = [
    { value: "location1", label: "Location 1" },
    { value: "location2", label: "Location 2" },
    // Add more location options as needed
  ];

  const statusOptions = [
    { value: "status1", label: "Status 1" },
    { value: "status2", label: "Status 2" },
    // Add more status options as needed
  ];

  const skillOptions = [
    { value: "skill1", label: "Skill 1" },
    { value: "skill2", label: "Skill 2" },
    // Add more skill options as needed
  ];

  const dropzoneStyles = {
    container: {
      border: "2px dashed #ccc",
      padding: "20px",
      textAlign: "center",
      cursor: "pointer",
    },
    containerHover: {
      borderColor: "#6c757d",
    },
    message: {
      fontSize: "16px",
      color: "#6c757d",
    },
    input: {
      display: "none",
    },
  };

  return (
    <Card>
      <CardHeader className="text-end p-3">
        {isEditing ? (
          <>
            <Button color="success" onClick={handleUpdate}>
              Update
            </Button>
          </>
        ) : (
          <>
            {
              <Button color="primary" onClick={handleEdit}>
                Edit
              </Button>
            }
          </>
        )}
      </CardHeader>
      <CardBody>
        <Form>
          {/* Section 1 */}
          <Row>
            <Col lg={6}>
              {/* <FormGroup>
            <Label for="resumeFile">Profile Picture Upload</Label>
            <div {...getRootProps()} style={isHovered ? dropzoneStyles.containerHover : dropzoneStyles.container}>
              <input {...getInputProps()} disabled={!isEditing} style={dropzoneStyles.input} />
              <p style={dropzoneStyles.message}>
                Drag 'n' drop a PDF file here, or click to select one
              </p>
            </div>
          </FormGroup> */}
              <FormGroup>
                <Label for="profilePicture">Profile Picture Upload</Label>
                <Input
                  className="btn btn-primary"
                  type="file"
                  id="profilePicture"
                  name="profilePicture"
                  onChange={(e) =>
                    handleInputChange("profilePicture", e.target.files[0])
                  }
                  disabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={(e) =>
                    handleInputChange("firstName", e.target.value)
                  }
                  disabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={(e) =>
                    handleInputChange("lastName", e.target.value)
                  }
                  disabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  disabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="designation">Designation</Label>
                <Input
                  type="text"
                  id="designation"
                  name="designation"
                  value={formData.designation}
                  onChange={(e) =>
                    handleInputChange("designation", e.target.value)
                  }
                  disabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="location">Location (Searchable Dropdown)</Label>
                <Select
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleLocationChange}
                  options={locationOptions}
                  isDisabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="summary">Summary</Label>
                <Input
                  type="textarea"
                  id="summary"
                  name="summary"
                  value={formData.summary}
                  onChange={(e) => handleInputChange("summary", e.target.value)}
                  disabled={!isEditing}
                />
              </FormGroup>
            </Col>

            <Col lg={6}>
              <FormGroup>
                <Label for="resumeFile">File (Resume PDF) Upload</Label>
                <div
                  {...getRootProps()}
                  style={
                    isHovered
                      ? dropzoneStyles.containerHover
                      : dropzoneStyles.container
                  }
                >
                  <input
                    {...getInputProps()}
                    disabled={!isEditing}
                    style={dropzoneStyles.input}
                  />
                  <p style={dropzoneStyles.message}>
                    Drag 'n' drop a PDF file here, or click to select one
                  </p>
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="monthlyBudget">Monthly Budget (INR)</Label>
                <Input
                  type="text"
                  id="monthlyBudget"
                  name="monthlyBudget"
                  value={formData.monthlyBudget}
                  onChange={(e) =>
                    handleInputChange("monthlyBudget", e.target.value)
                  }
                  disabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="totalExperience">Total Experience</Label>
                <Input
                  type="text"
                  id="totalExperience"
                  name="totalExperience"
                  value={formData.totalExperience}
                  onChange={(e) =>
                    handleInputChange("totalExperience", e.target.value)
                  }
                  disabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="status">Status (Searchable Dropdown)</Label>
                <Select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleStatusChange}
                  options={statusOptions}
                  isDisabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="skills">Skills (React-Multiselect Dropdown)</Label>
                <Select
                  id="skills"
                  name="skills"
                  value={formData.skills}
                  onChange={handleSkillsChange}
                  options={skillOptions}
                  isMulti
                  isDisabled={!isEditing}
                />
              </FormGroup>
              <FormGroup>
                <Label for="previousSkills">Previous Skills</Label>
                <Input
                  type="textarea"
                  id="previousSkills"
                  name="previousSkills"
                  value={formData.previousSkills}
                  onChange={(e) =>
                    handleInputChange("previousSkills", e.target.value)
                  }
                  disabled={!isEditing}
                />
              </FormGroup>
            </Col>
          </Row>

          {/* Section 2 */}
        </Form>
      </CardBody>
    </Card>
  );
};

export default MyCandidatesDetailsFrom;

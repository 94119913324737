import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment/moment";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardHeader,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Badge,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "./canvas.css";
import TIQcard from "./offcanvasComponents/TIQcard";
import Assessment from "../../individual/Components/AssessmentForm";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { log_debug } from "../../apiHelpers/logger";
import AssessmentDetailsMCQCanvas from "./AssessmentDetailsMCQCanvas";
import AssessmentDetailsCodingModal from "./AssessmentDetailsCodingModal";
import AssessmentDetailsBehaviouralModal from "./AssessmentDetailsBehaviouralModal";
import { useDispatch, useSelector } from "react-redux";
import {
  assessmentBehavioural,
  assessmentCoding,
  assessmentMCQ,
  dummyAssessment,
} from "../../Partner/PartnerSlice";
import Preloader from "../../components/Common/Preloader";
import { getTIQscore } from "../../individual/individualSlice";
import NoDataFound from "../utilities/NoData";

function CanvasAssessment({
  isOpen,
  toggle,
  onClose,
  menuOption,
  showTIQCard,
  //mcqDetails,
  //codingDetails,
  //behaviouralDetails,
  guid,

  data,
}) {
  // const [mcqDetailsData, setmcqDetailsData] = useState(mcqDetails);
  const [OffcanvasOpenMCQ, setOffcanvasOpenMCQ] = useState(false);
  const [headerTab, setHeaderTab] = useState("1");
  log_debug("dataForModal2", data);

  // ---------modal-----------
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [dataForModal1, setDataForModal1] = useState([]);
  const [dataForModal2, setDataForModal2] = useState([]);

  //--------bahaviour-------
  const [storemcqDetails, setStoreMcqDetails] = useState("");

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const individualStore = useSelector((state) => state.individualSlice);
  const [loading, setloading] = useState(false);

  const offcanvasStyle = {
    width: `55%`,
    zoom: "90%",
  };

  const [AssessmentType, setAssessmentType] = useState([]);
  const [mcqDetails, setMcqDetails] = useState([]);
  const [codingDetails, setCodingDetails] = useState([]);
  const [behaviouralDetails, setBehaviouralDetails] = useState([]);
  const [tiqScore, settiqScore] = useState([]);

  useEffect(() => {
    dispatch(getTIQscore(guid));
    dispatch(dummyAssessment(guid));
    if (guid) {
      log_debug("recieved assessment guid", guid);
    }
  }, [guid, data]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.dummyAssessmentdata?.success === true) {
        setAssessmentType(
          partnerStore.data.dummyAssessmentdata?.data.assessments,
        );
      }
      if (partnerStore.data.assessmentMCQdata?.success === true) {
        setMcqDetails(
          partnerStore.data.assessmentMCQdata?.data.assessmentquestions,
        );
      }
      if (partnerStore.data.assessmentCodingdata?.success === true) {
        setCodingDetails(
          partnerStore.data.assessmentCodingdata?.data.assessmentquestions,
        );
      }
      if (partnerStore.data.assessmentBehaviouraldata?.success === true) {
        setBehaviouralDetails(
          partnerStore.data.assessmentBehaviouraldata?.data.assessmentquestions,
        );
      }
      setloading(false);
    } else if (partnerStore.status === "loading") {
      setloading(true);
    }
  }, [partnerStore]);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.TIQscore?.success === true) {
        settiqScore(individualStore.data.TIQscore?.data?.assessmentsession);
      }
      setloading(false);
    } else if (individualStore.status === "loading") {
      setloading(true);
    }
  }, [individualStore]);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        //Assuming you have already set the assessments in the state
        const assessments = AssessmentType;

        assessments.forEach((assessment) => {
          const { type, guid } = assessment;

          switch (type) {
            case "mcq":
              dispatch(assessmentMCQ(guid));
              break;
            case "coding":
              dispatch(assessmentCoding(guid));
              break;
            case "behavioural":
              dispatch(assessmentBehavioural(guid));
              break;
            default:
              console.error(`Unknown assessment type: ${assessment.type}`);
          }
        });
      } catch (error) {
        //Handle errors
        console.error("Error fetching assessments", error);
      }
    };

    fetchAssessments();
  }, [AssessmentType]);

  const openOffcanvasMCQ = (mcq_details) => {
    setOffcanvasOpenMCQ(true);
    setStoreMcqDetails(mcq_details);
  };
  const closeOffcanvasMCQ = () => {
    setOffcanvasOpenMCQ(false);
  };

  const toggleModal1 = (data) => {
    setModal1(!modal1);
    setDataForModal1(data);
  };

  const toggleModal2 = (behavioural_data) => {
    setModal2(!modal2);
    setDataForModal2(behavioural_data);
  };

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  useEffect(() => {
    log_debug("tiq score", tiqScore);
  }, [tiqScore]);

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          {loading && <Preloader />}
          <h5 className="text-primary mt-1">
            Assessment Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" />
              Assessment Details displayed. Relevant information for selected
              assesment.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* TIQ score card to display based on scenario */}
          {tiqScore && showTIQCard && (
            <div className="row pt-2">
              {/* tiq card component to display the tiq score and job title */}
              <div className="col-md-6">
                <TIQcard
                  //title={props.cardTitle}
                  //jobname={displayName}
                  data={tiqScore}
                  assessmentguid={guid}
                  // behavioural={behaviouralDetails[0]}
                  // coding={codingDetails[0]}
                  // mcq={mcqDetails[0]}
                  //candidatestatus={props.data.status}
                  menuOption="assessment"
                />
              </div>
            </div>
          )}
          {/* Tabs section */}

          <Row>
            <Col xl={12}>
              <div>
                <CardHeader className="align-items-center d-flex my-4">
                  <div className="flex-shrink-0">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "1",
                          })}
                          onClick={() => {
                            toggleHeader("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">MCQ</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "2",
                          })}
                          onClick={() => {
                            toggleHeader("2");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>

                          <span className="d-none d-sm-block">Coding</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "3",
                          })}
                          onClick={() => {
                            toggleHeader("3");
                          }}
                        >
                          <span className="d-none d-sm-block">Behavioural</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>

                <div className="card-body">
                  <TabContent className="text-muted" activeTab={headerTab}>
                    {/* Content for Tab1 */}
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <div>
                              {mcqDetails && mcqDetails.length > 0 ? (
                                mcqDetails.map((item, index) => (
                                  <div
                                    className="border border-2 py-2 px-3 mb-2"
                                    key={index}
                                  >
                                    <Row>
                                      <Col lg={9}>
                                        <div className="flex-grow-1 mb-1">
                                          <h5 className="font-size-14 mb-1">
                                            {item.question}
                                          </h5>
                                          <p className="text-muted">
                                            <p className="font-size-12">
                                              <em>Submitted Answer:</em>
                                              &nbsp;&nbsp;
                                              <span className="font-size-14">
                                                {item.submitted_answer}
                                              </span>
                                            </p>
                                          </p>
                                          <div>
                                            <Link
                                              to="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                openOffcanvasMCQ(item);
                                              }}
                                              className="text-primary mb-2"
                                            >
                                              Read more{" "}
                                              <i className="mdi mdi-arrow-right" />
                                            </Link>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={3} className="">
                                        <div className="hstack d-block">
                                          <span
                                            className={`badge bg-${
                                              item.status === "skipped"
                                                ? "warning"
                                                : item.status === "incorrect"
                                                  ? "danger"
                                                  : "success"
                                            }-subtle text-muted float-end m-1`}
                                          >
                                            {" "}
                                            {item.status}
                                          </span>
                                          &nbsp;
                                          <span className="badge bg-primary-subtle text-primary float-end m-1">
                                            {item.expertise}
                                          </span>
                                          &nbsp;
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))
                              ) : (
                                <NoDataFound />
                              )}
                            </div>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    {/* Content for Tab2 */}
                    <TabPane tabId="2">
                      <Col sm="12">
                        <CardText className="mb-0">
                          {codingDetails && codingDetails.length > 0 ? (
                            codingDetails.map((innerCode, index) => (
                              <div key={index}>
                                <div className="flex-grow-1 mb-3 ">
                                  <div className="border border-2 px-2 py-2">
                                    <div className="d-flex align-items-start">
                                      <div className="flex-grow-1 overflow-hidden">
                                        <h5 className="font-size-15 text-truncate">
                                          <a href="#" className="text-body">
                                            {innerCode.question}
                                          </a>
                                        </h5>
                                        <p className="font-size-13 text-muted mb-0">
                                          {moment(innerCode.createdAt).format(
                                            "DD MMM , YYYY",
                                          )}
                                        </p>
                                      </div>
                                      <div className="flex-shrink-0 ms-2">
                                        <div
                                          className=""
                                          style={{
                                            width: "50px",
                                            height: "40px",
                                          }}
                                        >
                                          <CircularProgressbarWithChildren
                                            styles={{
                                              root: {}, // Adjust these values
                                              path: {
                                                stroke: "#5156be",
                                                strokeLinecap: "round",
                                                transition:
                                                  "stroke-dashoffset 0.5s ease 0s",
                                                transform: "rotate(0.25turn)",
                                                transformOrigin:
                                                  "center center",
                                                pathColor: "#74788d",
                                              },
                                              trail: {
                                                stroke: "#d6d6d6",
                                                strokeLinecap: "round",
                                                transform: "rotate(0.25turn)",
                                                transformOrigin:
                                                  "center center",
                                              },
                                            }}
                                            value={(
                                              (innerCode.score /
                                                innerCode.max_score) *
                                              100
                                            ).toFixed(0)}
                                          >
                                            <div style={{ fontSize: 11 }}>
                                              <strong>
                                                {(
                                                  (innerCode.score /
                                                    innerCode.max_score) *
                                                  100
                                                ).toFixed(0)}
                                                %
                                              </strong>
                                            </div>
                                          </CircularProgressbarWithChildren>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="pt-1">
                                      <ul className="list-inline">
                                        {innerCode.skills.map(
                                          (skill, index) => (
                                            <li
                                              className="list-inline-item me-3"
                                              key={index}
                                            >
                                              <a
                                                href="javascript: void(0);"
                                                className="text-muted"
                                              >
                                                <i className="bx bx-purchase-tag-alt align-middle text-muted me-1" />{" "}
                                                {skill}
                                              </a>
                                            </li>
                                          ),
                                        )}
                                      </ul>
                                      <p className="text-muted">
                                        {innerCode &&
                                          innerCode.code_result &&
                                          innerCode.code_result.summary}
                                      </p>
                                      <div>
                                        <a
                                          href="javascript: void(0);"
                                          onClick={() => {
                                            toggleModal1(innerCode);
                                          }}
                                          className="text-primary"
                                        >
                                          Read more{" "}
                                          <i className="mdi mdi-arrow-right" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <NoDataFound />
                          )}
                        </CardText>
                      </Col>
                    </TabPane>

                    {/* Content for tab 3 */}
                    <TabPane tabId="3">
                      {behaviouralDetails && behaviouralDetails.length > 0 ? (
                        behaviouralDetails.map((behavioural, index) => (
                          <div key={index}>
                            <div className="flex-grow-1 mb-3">
                              <div className="border border-2 px-2 py-2">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="font-size-15 text-truncate">
                                      <a href="#" className="text-body">
                                        {behavioural.question}
                                      </a>
                                    </h5>
                                    <p className="font-size-13 text-muted mb-0">
                                      {moment(behavioural.createdAt).format(
                                        "DD MMM , YYYY",
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div
                                      className=""
                                      style={{
                                        width: "50px",
                                        height: "40px",
                                      }}
                                    >
                                      <CircularProgressbarWithChildren
                                        styles={{
                                          root: {}, // Adjust these values
                                          path: {
                                            stroke: "#5156be",
                                            strokeLinecap: "round",
                                            transition:
                                              "stroke-dashoffset 0.5s ease 0s",
                                            transform: "rotate(0.25turn)",
                                            transformOrigin: "center center",
                                            pathColor: "#74788d",
                                          },
                                          trail: {
                                            stroke: "#d6d6d6",
                                            strokeLinecap: "round",
                                            transform: "rotate(0.25turn)",
                                            transformOrigin: "center center",
                                          },
                                        }}
                                        value={(
                                          (behavioural.score /
                                            behavioural.max_score) *
                                          100
                                        ).toFixed(0)}
                                      >
                                        <div style={{ fontSize: 11 }}>
                                          <strong>
                                            {(
                                              (behavioural.score /
                                                behavioural.max_score) *
                                              100
                                            ).toFixed(0)}
                                            %
                                          </strong>
                                        </div>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                  </div>
                                </div>
                                <div className="pt-1">
                                  <ul className="list-inline">
                                    {behavioural.skills.map((skill, index) => (
                                      <li
                                        className="list-inline-item me-3"
                                        key={index}
                                      >
                                        <a
                                          href="javascript: void(0);"
                                          className="text-muted"
                                        >
                                          <i className="bx bx-purchase-tag-alt align-middle text-muted me-1" />{" "}
                                          {skill}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                  <p className="text-muted">
                                    {behavioural &&
                                      behavioural.video_transcript_result &&
                                      behavioural.video_transcript_result
                                        .summary}
                                  </p>
                                  <div>
                                    <a
                                      href="javascript: void(0);"
                                      onClick={() => {
                                        toggleModal2(behavioural);
                                      }}
                                      className="text-primary"
                                    >
                                      Read more{" "}
                                      <i className="mdi mdi-arrow-right" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <NoDataFound />
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
          <Row></Row>
        </OffcanvasBody>
      </Offcanvas>
      {OffcanvasOpenMCQ && (
        <AssessmentDetailsMCQCanvas
          isOpen={OffcanvasOpenMCQ}
          toggle={() => setOffcanvasOpenMCQ(!OffcanvasOpenMCQ)}
          onclose={closeOffcanvasMCQ}
          mcqDetails={storemcqDetails}
        />
      )}
      <AssessmentDetailsCodingModal
        isOpen={modal1}
        toggle={() => toggleModal1(toggleModal1)}
        codeSnippet={dataForModal1}
        modalClassName="modal-1"
      />
      <AssessmentDetailsBehaviouralModal
        isOpen={modal2}
        toggle={() => toggleModal2(toggleModal2)}
        videoData={dataForModal2}
        modalClassName="modal-2"
      />
    </>
  );
}

export default CanvasAssessment;

import React, { useEffect, useState } from "react";
import { CardBody, Container, Row, Col } from "reactstrap";
import { log_debug } from "../../apiHelpers/logger";
import { FaUserAlt } from "react-icons/fa";
import { titles } from "../../data/cb_title";
import historyEmpty from "../../../src/assets/images/companyBenchImage/historyEmpty.svg";
import HistoryEmpty from "../utilities/HistoryEmpty";

const TimelineComponent = ({ historydata, type }) => {
  const { candidature_history_subtitle } = titles.en.common;

  const [history, setHistory] = useState([]);
  log_debug("historyhistory", history);

  useEffect(() => {
    log_debug("timeline data", historydata);
    setHistory(historydata);
    log_debug("history data", history);
  }, [historydata]);

  const padWithZero = (value) => (value <= 9 ? `0${value}` : `${value}`);

  // Function to format the date to display day and month
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = padWithZero(date.getDate());
    const month = padWithZero(date.getMonth());
    const year = date.getFullYear();

    return { day, month, year };
  };
  function capitalizeWords(str) {
    return str
      ? str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
      : "";
  }
  return (
    <Container fluid className="text-left">
      <Row>
        <Col lg={12}>
          <CardBody>
            <Row className="justify-content-start">
              <Col xl={12}>
                {type === "candidatureHistory" && (
                  // <h5 className="text-primary">
                  //   Candidature History&nbsp;&nbsp;
                  <span className="font-size-14 text-muted mt-2">
                    <i className="fas fa-info-circle" />
                    {candidature_history_subtitle}
                  </span>
                  // </h5>
                )}
                <div className="timeline">
                  {historydata && historydata?.length >= 1 ? (
                    <div className="timeline-container">
                      <div className="timeline-end">
                        <p className="font-size-10">End</p>
                      </div>
                      {history &&
                        history.map((event, index) => (
                          <div
                            className={
                              index % 2 === 0
                                ? "timeline-continue"
                                : "timeline-continue alt"
                            }
                            key={index}
                          >
                            {index % 2 === 0 ? (
                              <Row className="timeline-right">
                                <Col md={6}>
                                  <div className="timeline-icon">
                                    <FaUserAlt className="text-primary h5 mb-0" />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="timeline-box">
                                    {/* <div className="timeline-date bg-primary text-center rounded">
                                <h3 className="text-white mb-0"></h3>
                                <p className="mb-0 text-white-50">{formatDate(event.createdAt).month}</p>
                                <p className="mb-0 text-white-50">{formatDate(event.createdAt).year}</p>
                              </div> */}
                                    <div className="event-content">
                                      <div className="timeline-text">
                                        <h3
                                          className={
                                            event.status === "on_bench"
                                              ? "font-size-18 text-warning"
                                              : "font-size-18 text-success"
                                          }
                                        >
                                          {capitalizeWords(event.status)}
                                          {/* {event.status === "on_bench"
                                        ? "on bench"
                                        : event.status} */}
                                          {/* {event.status.replace(/_/g, " ")} */}
                                        </h3>
                                        <em className="mb-0 mt-2 emt-1 text-muted ">
                                          {formatDate(event.createdAt).year}-
                                          {formatDate(event.createdAt).month}-
                                          {formatDate(event.createdAt).day}
                                        </em>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <Row className="timeline-left">
                                {/* <Col md={6} className="d-md-none d-block">
                              <div className="timeline-icon">
                              <FaUserAlt className="text-primary h5 mb-0" />
                              </div>
                            </Col> */}
                                <Col md={6}>
                                  <div className="timeline-box">
                                    {/* <div className="timeline-date bg-primary text-center rounded">
                                    <h3 className="text-white mb-0">25</h3>
                                    <p className="mb-0 text-white-50">June</p>
                                  </div> */}
                                    <div className="event-content">
                                      <div className="timeline-text">
                                        <h3
                                          className={
                                            event.status === "on_bench"
                                              ? "font-size-18 text-warning"
                                              : "font-size-18 text-success"
                                          }
                                        >
                                          {capitalizeWords(event.status)}
                                          {/* {event.status === "on_bench"
                                        ? "on bench"
                                        : event.status} */}
                                        </h3>
                                        <em className="mb-0 mt-2 emt-1 text-muted ">
                                          {formatDate(event.createdAt).year}-
                                          {formatDate(event.createdAt).month}-
                                          {formatDate(event.createdAt).day}
                                        </em>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6} className="d-md-block d-none">
                                  <div className="timeline-icon">
                                    <FaUserAlt className="text-primary h5 mb-0" />
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </div>
                        ))}

                      <div className="timeline-start">
                        <p className="font-size-10">Start</p>
                      </div>
                    </div>
                  ) : (
                    <div className="overflow-hidden bg ">
                      <Row>
                        <HistoryEmpty />
                      </Row>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Col>
      </Row>
    </Container>
  );
};

export default TimelineComponent;

import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../components/Common/Breadcrumb";
import MyCandidatesDetailsFrom from "./pagesComponents/MyCandidatesDetailsFrom";
import { Link } from "react-router-dom";

function MyCandidatesDetails() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="My Candidates Details"
            breadcrumbItem="My Candidates Details"
          />
          <div>
            <div className="col-lg-12">
              <Row className="d-flex">
                <Col>
                  <Link className="btn btn btn-outline-primary m-3">
                    Matching Jobs : 0{" "}
                  </Link>
                  <Link className="btn btn btn-outline-primary m-3">
                    Interviews : 0
                  </Link>
                </Col>
                <Col className="text-end">
                  <Link className="btn btn-info m-3" to={"/mycandidate"}>
                    Back
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <MyCandidatesDetailsFrom />
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default MyCandidatesDetails;

import React, { useEffect, useState } from "react";
import {
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import validator from "validator";
import { log_debug } from "../../../apiHelpers/logger";
import {
  dateyyyymmdd_tohtml,
  validateStartEndDate,
} from "../../../helpers/date";
import {
  getIndividualProfileDetails,
  postIndividualResumeUpdate,
} from "../../individualSlice";
import { useDispatch, useSelector } from "react-redux";

const CanvasEditIndiEducation = ({
  data,
  handleSave,
  isOpen,
  toggle,
  onClose,
}) => {
  const [guid, setGuid] = useState([]);
  const [editedData, setEditedData] = useState([]);
  log_debug("editedDataEdu", editedData);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setGuid(data?.guid || []);
    setEditedData(data.educational_qualifications);
  }, [data]);

  const offcanvasStyle = {
    width: "45%", // we can set a default width or use the provided width
  };

  const handleFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    setEditedData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [fieldName]: value };
      return newData;
    });
    // if (fieldName === "skills") {
    //   // Handle skills field separately
    //   const skillsArray = value.split(",").map((skill) => skill.trim());
    //   setEditedDataskill(skillsArray);
    // } else if (fieldName === "summary") {
    //   // Handle summary field separately
    //   setEditedDatasummary(value);
    // } else {
    //   // Handle other fields
    //   setEditedData((prevData) => {
    //     const newData = [...prevData];
    //     newData[index] = { ...newData[index], [fieldName]: value };
    //     return newData;
    //   });
    // }
  };

  const handleValidateSave = () => {
    let errors = {};
    editedData.forEach((education, index) => {
      // Initialize errors for the current work experience
      let eduErrors = {};
      // let project_details = education?.project_details || '';
      let college_or_university_name =
        education?.college_or_university_name || "";
      let degree_name = education?.degree_name || "";
      // let designation = education?.designation || '';
      let from_date = education?.from_date || "";
      let to_date = education?.to_date || "";

      // Set errors for the current field in the current work experience
      eduErrors["college_or_university_name"] = validator.isEmpty(
        college_or_university_name,
      )
        ? "Company name is required"
        : "";
      eduErrors["degree_name"] = validator.isEmpty(degree_name)
        ? "Degree name is required"
        : "";
      // eduErrors['designation'] = validator.isEmpty(designation) ? 'Designation is required' : '';
      // eduErrors['project_details'] = validator.isEmpty(project_details) ? 'Project details are required' : '';
      eduErrors["from_date"] = validator.isEmpty(from_date)
        ? "Start date is required"
        : "";
      eduErrors["to_date"] = validator.isEmpty(to_date)
        ? "End date is required"
        : "";

      // if (!validator.isLength(project_details, { min: 0, max: 1000 })) {
      //     eduErrors['project_details'] = 'Project details are Too large';
      // }
      if (
        !validator.isLength(college_or_university_name, { min: 2, max: 60 })
      ) {
        eduErrors["college_or_university_name"] =
          "Please enter valid company name";
      }
      if (!validator.isLength(degree_name, { min: 2, max: 60 })) {
        eduErrors["degree_name"] = "Please enter valid degree name";
      }
      if (from_date) {
        if (!validator.isDate(dateyyyymmdd_tohtml(from_date))) {
          eduErrors["from_date"] = "Please enter valid start date";
        }
      }

      if (to_date) {
        if (!validator.isDate(dateyyyymmdd_tohtml(to_date))) {
          eduErrors["to_date"] = "Please enter valid end date";
        }
      }

      if (!validateStartEndDate(from_date, to_date)) {
        eduErrors["from_date"] = "Start Date can not be greater than End Date";
      }
      // Set errors for the current work experience in the main errors object
      errors[index] = eduErrors;
    });
    const resumeDatas = {
      resume: {
        educational_qualifications: [...editedData],
      },
    };

    // dispatch(postIndividualResumeUpdate({resume_guid:data.guid , addedData:resumeDatas}))
    dispatch(
      postIndividualResumeUpdate({
        resume_guid: data.guid,
        addedData: resumeDatas,
      }),
    )
      .then(() => {
        // The first action has been dispatched and, assuming it resolved, we can now dispatch another.
        dispatch(getIndividualProfileDetails());
      })
      .catch((error) => {
        // Handle any errors from the first dispatch here
        console.error("Failed to update individual resume:", error);
      });

    setErrors(errors);
    if (
      Object.values(errors).some((eduErrors) =>
        Object.values(eduErrors).some((error) => error !== ""),
      )
    ) {
      return;
    }
    toggle();
    // handleSave(editedData);
  };

  return (
    <Offcanvas
      direction="end"
      isOpen={isOpen}
      className="custom-offcanvas"
      contentClassName="custom-offcanvas-content"
      onClose={onClose}
      style={offcanvasStyle}
    >
      <OffcanvasHeader toggle={toggle} className="border-bottom">
        <p className="text-muted">Education Details</p>
      </OffcanvasHeader>
      <OffcanvasBody>
        {editedData.map((edu, index) => (
          <React.Fragment key={index}>
            <Form>
              <Row className="mb-3">
                <Label
                  className="col-sm-3 col-form-label"
                  for="college_or_university_name"
                >
                  University Name <span className="text-danger">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="college_or_university_name"
                    value={edu?.college_or_university_name}
                    onChange={(e) =>
                      handleFieldChange(e, index, "college_or_university_name")
                    }
                  />
                  {errors[index]?.college_or_university_name && (
                    <small className="text-danger">
                      {errors[index]?.college_or_university_name}
                    </small>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Label className="col-sm-3 col-form-label" for="degree_name">
                  Degree Name <span className="text-danger">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="degree_name"
                    value={edu?.degree_name}
                    onChange={(e) => handleFieldChange(e, index, "degree_name")}
                  />
                  {errors[index]?.degree_name && (
                    <small className="text-danger">
                      {errors[index]?.degree_name}
                    </small>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Label className="col-sm-3 col-form-label" for="from_date">
                  From Date <span className="text-danger">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="date"
                    name="from_date"
                    value={edu?.from_date && dateyyyymmdd_tohtml(edu.from_date)}
                    onChange={(e) => handleFieldChange(e, index, "from_date")}
                  />
                  {errors[index]?.from_date && (
                    <small className="text-danger">
                      {errors[index]?.from_date}
                    </small>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Label className="col-sm-3 col-form-label" for="to_date">
                  To Date <span className="text-danger">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="date"
                    name="to_date"
                    value={edu?.to_date && dateyyyymmdd_tohtml(edu.to_date)}
                    onChange={(e) => handleFieldChange(e, index, "to_date")}
                  />
                  {errors[index]?.to_date && (
                    <small className="text-danger">
                      {errors[index]?.to_date}
                    </small>
                  )}
                </Col>
              </Row>
            </Form>
            <hr />
          </React.Fragment>
        ))}

        <Button color="primary" onClick={() => handleValidateSave()}>
          Update
        </Button>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default CanvasEditIndiEducation;

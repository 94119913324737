import React, { useEffect, useState } from "react";
import { CardBody, Container, Row, Col } from "reactstrap";
import { FaBriefcase, FaUserAlt } from "react-icons/fa";
import { log_debug } from "../../../apiHelpers/logger";
import HistoryEmpty from "../../utilities/HistoryEmpty";

const JobTimelineComponent = ({ historydata }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    log_debug("timeline data", historydata);
    setHistory(historydata || []);
  }, [historydata]);

  const padWithZero = (value) => (value <= 9 ? `0${value}` : `${value}`);

  // Function to format the date to display day and month
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = padWithZero(date.getDate());
    const month = padWithZero(date.getMonth());
    const year = date.getFullYear();

    return { day, month, year };
  };
  function capitalizeWords(str) {
    return str
      ? str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
      : "";
  }
  return (
    <Container fluid className="text-left">
      <Row>
        {history.length === 0 ? (
          <HistoryEmpty />
        ) : (
          <Col lg={12}>
            <CardBody>
              <Row className="justify-content-start">
                <Col xl={12}>
                  <div className="timeline">
                    <div className="timeline-container">
                      <div className="timeline-end">
                        <p className="font-size-10">End</p>
                      </div>
                      <div className="timeline-continue">
                        <Row className="timeline-right">
                          <Col md={6}>
                            <div className="timeline-icon">
                              <FaBriefcase className="text-primary h5 mb-0" />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="timeline-box">
                              {/* <div className="timeline-date bg-primary text-center rounded">
                                <h3 className="text-white mb-0"></h3>
                                <p className="mb-0 text-white-50">{formatDate(event.createdAt).month}</p>
                                <p className="mb-0 text-white-50">{formatDate(event.createdAt).year}</p>
                              </div> */}
                              <div className="event-content">
                                <div className="timeline-text">
                                  <h3
                                    className={
                                      history.status === "on_bench"
                                        ? "font-size-18 text-warning"
                                        : "font-size-18 text-success"
                                    }
                                  >
                                    {capitalizeWords(history.status)}
                                    {/* {event.status === "on_bench"
                                        ? "on bench"
                                        : event.status} */}
                                    {/* {event.status.replace(/_/g, " ")} */}
                                  </h3>
                                  <em className="mb-0 mt-2 emt-1 text-muted ">
                                    {formatDate(history.createdAt).day}-
                                    {formatDate(history.createdAt).month}-
                                    {formatDate(history.createdAt).year}
                                  </em>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="timeline-start">
                        <p className="font-size-10">Start</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default JobTimelineComponent;

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { log_debug } from "../../apiHelpers/logger";
import "../../../src/assets/css/styleCompanyBench.css";
import validator from "validator";
import { dateyyyymmdd_tohtml, validateStartEndDate } from "../../helpers/date";

const AddCandidateDetailsCertification = ({ isOpen, toggle, handleSave }) => {
  const offcanvasStyle = {
    width: "40%",
  };
  const [validationErrors, setValidationErrors] = useState({});
  const [editedData, setEditedData] = useState({});

  const [newCertification, setNewCertification] = useState({
    CertificationDate: "",
    CertificateName: "",
    Status: "",
  });
  const handleInputChange = (fieldName, value) => {
    // Validation checks
    let errors = { ...validationErrors };
    switch (fieldName) {
      case "CertificationDate":
        errors[fieldName] = validator.isEmpty(value)
          ? "Please fill this field"
          : "";
        break;
      case "CertificateName":
        errors[fieldName] = validator.isEmpty(value)
          ? "Please fill this field"
          : "";
        break;
      case "Status":
        errors[fieldName] = validator.isEmpty(value)
          ? "Please fill this field"
          : "";
        break;
      default:
        break;
    }
    setValidationErrors(errors);

    setNewCertification({
      ...newCertification,
      [fieldName]: value,
    });
  };

  // Handler for toggling the Offcanvas
  const handleToggleCanvas = (e) => {
    e.preventDefault();
    // toggleRightCanvas();
    // // Clear validation errors when the component is closed
    // setValidationErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation before submission
    let errors = {};
    for (const key in newCertification) {
      if (newCertification.hasOwnProperty(key)) {
        const value = newCertification[key];
        // errors[key] = validator.isEmpty(value) ? `${key.split('_')} is required ` : '';
        errors[key] = validator.isEmpty(value) ? `Please fill this field` : "";
        if (
          !validator.isDate(
            dateyyyymmdd_tohtml(newCertification["CertificationDate"]),
            new Date(),
          )
        ) {
          errors["CertificationDate"] = "Please enter valid certification date";
        }
        if (
          !validateStartEndDate(
            newCertification["CertificationDate"],
            new Date().toISOString(),
          )
        ) {
          errors["CertificationDate"] =
            "Certification issued date can not be greater than today";
        }
      }
    }
    setValidationErrors(errors);
    if (Object.values(errors).some((error) => error !== "")) {
      // If there are validation errors, do not submit
      return;
    }

    handleSave(newCertification);
    toggle();
    //   const updatedData = { ...editedData };

    //   if (!updatedData.resume.certifications) {
    //       updatedData.resume.certifications = [];
    //   }

    //   const updatedCertification = [...updatedData.resume.certifications, newCertification];
    //   updatedData.resume.certifications = updatedCertification;
    //   setEditedData(updatedData);

    // You can now use formData to send the data to your server or perform any other actions.
    // const apiEndpointResume = ep.assessment + "/v1/resume/" + data.resume.guid;
    // const requestBodyResume = {
    //     resume: {
    //         guid: editedData.resume.guid,
    //         certifications: editedData.resume.certifications,
    //     },
    // };
    // api_post(null, apiEndpointResume, requestBodyResume, (error, responseData) => {
    //     if (error) {

    //     } else {
    //         log_debug(null, 'handleSubmit', 'add certification responseData::' + JSON.stringify(responseData));
    //         onSubmit(editedData);
    //         toggleRightCanvas();
    //     }
    // });
  };

  return (
    <>
      <Offcanvas
        isOpen={isOpen}
        direction="end"
        // toggle={toggle}
        style={offcanvasStyle}
      >
        <OffcanvasHeader toggle={toggle}>Add Certification</OffcanvasHeader>
        <OffcanvasBody>
          <Col lg={12} className="ms-lg-auto">
            <div className="mt-4 mt-lg-0">
              <Form>
                <div>
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-CertificateName-input"
                      className="col-sm-3 col-form-label"
                    >
                      Certificate Name <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        className="form-control"
                        name="CertificateName"
                        type="text"
                        id="horizontal-CertificateName-input"
                        placeholder="Enter Certificate name"
                        onChange={(e) =>
                          handleInputChange("CertificateName", e.target.value)
                        }
                      />
                      {validationErrors.CertificateName && (
                        <small className="text-danger">
                          {validationErrors.CertificateName}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-CertificationDate-input"
                      className="col-sm-3 col-form-label"
                    >
                      Certificate Date <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="date"
                        name="CertificationDate"
                        className="form-control"
                        id="horizontal-CertificationDate-input"
                        placeholder="Enter issued date"
                        onChange={(e) =>
                          handleInputChange("CertificationDate", e.target.value)
                        }
                      />
                      {validationErrors.CertificationDate && (
                        <small className="text-danger">
                          {validationErrors.CertificationDate}
                        </small>
                      )}
                    </Col>
                  </Row>
                  {/* <Row className="mb-4">
                                      <Label htmlFor="horizontal-issued_by-input" className="col-sm-3 col-form-label">Issued By <span className='text-danger'>*</span></Label>
                                      <Col sm={9}>
                                          <Input className="form-control" name='issued_by' type="text" id="horizontal-issued_by-input" placeholder="Enter issuer name" onChange={(e) => handleInputChange('issued_by', e.target.value)} />
                                          {validationErrors.issued_by && <small className="text-danger">{validationErrors.issued_by}</small>}
                                      </Col>
                                  </Row> */}
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-Status-input"
                      className="col-sm-3 col-form-label"
                    >
                      Status <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        className="form-control"
                        name="CertificateName"
                        type="text"
                        id="horizontal-Status-input"
                        placeholder="Enter Status"
                        onChange={(e) =>
                          handleInputChange("Status", e.target.value)
                        }
                      />
                      {validationErrors.Status && (
                        <small className="text-danger">
                          {validationErrors.Status}
                        </small>
                      )}
                    </Col>
                  </Row>
                </div>
                <Row className="justify-content-center">
                  <Col sm={12}>
                    <div className="d-flex justify-content-start">
                      <button
                        type="submit"
                        className="btn btn-primary w-md"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default AddCandidateDetailsCertification;

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardHeader,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Badge,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "./canvas.css";
import Assessment from "../../individual/Components/AssessmentForm";

// Custom Components
import AssessmentInviteForm from "../form/AssessmentInviteForm";
import TIQcard from "../offcanvas/offcanvasComponents/TIQcard";
import CustomTable from "../reactTable/CustomTable";
import { shortlistedjobscolumnsdata } from "../../data/Data";
import {
  alljobscolumnsdata,
  alljobsheaders,
  interviewsheaders,
  interviewscolumnsdata,
} from "../../data/TableData";
import briefcase from "../../assets/images/companyBenchImage/jobgray1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getMatchingJobs } from "../../individual/individualSlice";
import { Tags } from "../../pages/Contacts/ContactList/contactlistCol";
import InvitedCandidates from "../../Client/Components/Canvas Tables/InvitedCandidates";
import ShortlistedCandidates from "../../Client/Components/Canvas Tables/ShortlistedCandidates";
import MatchingCandidates from "../../Client/Components/Canvas Tables/MatchingCandidates";
import JobOverviewTab from "./offcanvasComponents/JobOverview";

function JobOverview({ width, data, isOpen, toggle, onClose, showApply }) {
  const [ShowTiqCard, setShowTiqCard] = useState(false);

  const [headerTab, setHeaderTab] = useState("1");
  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  const offcanvasStyle = {
    width: `${width}%`,
    // zoom: "80%",
    padding: "15px",
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          {/* <p className="text-light">Candidate Details</p> */}
          <h5 className="text-primary">
            Job Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Job details displayed.
              Relevant information for selected position.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* TIQ score card to display based on scenario */}
          {ShowTiqCard ? (
            <div className="row pt-2 ">
              <div className="row">
                <div className="col-md-6 card">
                  <div className="d-flex">
                    <div className="flex-grow-1 my-2">
                      <h5 className="font-size-16 mb-1">
                        {data.title}{" "}
                        <Badge className="bg-success-subtle p-1 text-success rounded-pill m-1 font-size-12 float-end">
                          {data.status}
                        </Badge>
                      </h5>
                      <h5>
                        <small className="text-muted font-size-12">
                          {data.location.join(", ")}
                        </small>
                      </h5>
                      {/* {data.skills.map((skill, index) => (
                        <Badge
                          key={index}
                          color=""
                          className="bg-primary-subtle text-primary rounded-pill text-opacity-75 m-1 font-size-12"
                        >
                          {skill}
                        </Badge>
                      ))} */}
                      {/* <p className="mt-2 font-size-11 text-muted">
                        {data.description}
                      </p> */}
                      {showApply && (
                        <div className="mt-2">
                          ={" "}
                          <Link to="#" className="btn btn-primary btn-sm m-1">
                            Apply <i className=" fas fa-paper-plane ms-1"></i>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* tiq card component to display the tiq score and job title */}
              </div>
            </div>
          ) : (
            <Row>
              <Card>
                <div className="col-sm order-2 order-sm-1 p-2">
                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                    <div className="flex-shrink-0">
                      <div className="avatar-xl me-3">
                        <img
                          src={briefcase}
                          alt=""
                          className="img-fluid rounded-circle d-block "
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <div className="col-sm-auto order-1 order-sm-2">
                        <Badge
                          className="p-1 m-1 font-size-12 float-end"
                          color={
                            data && data.status === "active"
                              ? "success rounded-pill"
                              : "danger rounded-pill"
                          }
                        >
                          {data.status}
                        </Badge>
                      </div>
                      <div>
                        <h5 className="font-size-16 mb-1">{data.title}</h5>
                        {/* <h5 className="font-size-16 mb-1">
                             Invitation {data.createdAt}
                            </h5> */}

                        <h5 className="text-muted font-size-13 ">
                          <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>{" "}
                          {data.location.join(", ")}
                        </h5>

                        <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14">
                          <div>
                            <span>
                              {data.skills.map((skill, index) => (
                                <Link
                                  key={index}
                                  to="#"
                                  className="badge bg-primary rounded-pill  m-1 font-size-12"
                                >
                                  {skill}
                                </Link>
                              ))}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                          {/* <div>
                            {/* {responsibilities.join(", ")} 
                            <i className="mdi mdi-account-details me-1 text-primary align-middle"></i>
                            {/* <span>
                              {data.summary}
                              {/* <Link to="#">See more</Link> 
                            </span> 
                          </div> */}
                          {showApply && (
                            <div className="mt-2">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm m-1"
                              >
                                Apply{" "}
                                <i className=" fas fa-paper-plane ms-1"></i>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Row>
          )}

          <Row>
            <Col xl={12}>
              <div>
                <CardHeader className="align-items-center d-flex mt-2">
                  <div className="flex-shrink-0">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      {/* Tab 1 */}

                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "1",
                          })}
                          onClick={() => {
                            toggleHeader("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">Overview</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>

                {/* tabs content starting from here */}
                <div className="card-body">
                  <TabContent className="text-muted" activeTab={headerTab}>
                    {/* Content for Tab1 */}
                    <TabPane tabId="1">
                      <JobOverviewTab data={data} />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default JobOverview;

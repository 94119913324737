import React from "react";
import { Col, Container, Row } from "reactstrap";
// import nodatafound from "../../../src/assets/images/companyBenchImage/nodatafound.png";
import NodataFound from "../../../src/assets/images/companyBenchImage/nodatafound.svg";
import addcandidateimage from "../../../src/assets/images/companyBenchImage/undraw_interview_re_e5jn.svg";

import { Link } from "react-router-dom";

function PostJobButton() {
  return (
    <Row>
      <Col
        className="col-12 d-flex justify-content-center align-items-center w-100 m-0 p-0"
        style={{ height: 350 }}
      >
        <div className="text-center ">
          <img
            src={addcandidateimage}
            style={{ width: "100px" }}
            alt="Dropzone Image"
            className="py-3 d-block mx-auto" // Center the image horizontally
          />
          <h2 style={{ fontWeight: 700 }} className="text-muted ">
            No Jobs posted yet
          </h2>
          <p>Create a job to start hiring the best talent</p>
          <Link
            to={"/client/post-job"}
            className="btn bg-primary text-white fs-4"
          >
            <i className="fas fa-paper-plane"></i> Post Job
          </Link>
        </div>
      </Col>
    </Row>
  );
}

export default PostJobButton;

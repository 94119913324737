// import React, { useState } from "react";
// import {
//   FormGroup,
//   Label,
//   Col,
//   Row,
//   Card,
//   CardBody,
//   CardTitle,
// } from "reactstrap";
// import Select from "react-select";

// const DropdownWithTwoField = ({
//   label,
//   options,
//   placeholder,
//   onSelectChange,
// }) => {
//   const [selectedOptions, setSelectedOptions] = useState([]);

//   const handleSelectChange = (selectedOptions) => {
//     setSelectedOptions(selectedOptions);
//     onSelectChange(selectedOptions);
//   };

//   return (
//     <FormGroup row>
//       <Col md={12}>
//         <Select
//           options={options}
//           placeholder={placeholder}
//           value={selectedOptions}
//           onChange={handleSelectChange}
//           isMulti
//           isSearchable
//           isOptionDisabled={() => selectedOptions.length >= 3}
//         />
//       </Col>
//     </FormGroup>
//   );
// };

// export default DropdownWithTwoField;

import React, { useState } from "react";
import {
  FormGroup,
  Label,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import Select from "react-select";

const DropdownWithTwoField = ({
  label,
  options,
  placeholder,
  onSelectChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    onSelectChange(selectedOptions);
  };

  return (
    <FormGroup row>
      <Col md={12}>
        <Select
          options={options}
          placeholder={placeholder}
          value={selectedOptions}
          onChange={handleSelectChange}
          isMulti
          isSearchable
          isOptionDisabled={() => selectedOptions.length >= 5}
        />
      </Col>
    </FormGroup>
  );
};

export default DropdownWithTwoField;

// CustomOffcanvas.js
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Offcanvas,
  TabContent,
  OffcanvasHeader,
  OffcanvasBody,
  TabPane,
  UncontrolledDropdown,
  Button,
  Table,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";
import axios from "axios";
import CBlogo from "../../../src/assets/images/companyBenchImage/Company-bench-v2.png";
import { useDispatch } from "react-redux";
import { GenerateResume, SaveGenerateResume } from "../../Partner/PartnerSlice";
import { log_debug } from "../../apiHelpers/logger";
import Preloader from "../../components/Common/Preloader";
import jsPDF from "jspdf";
import { dateyyyymmdd_tohtml } from "../../helpers/date";
import { toast, ToastContainer } from "react-toastify";

// Custom Components

export function GenerateResumeCanvas({
  isOpen,
  toggle,
  onClose,
  data,
  downloadstatus,
  usertype,
}) {
  const offcanvasStyle = {
    width: `70%`,
    // zoom: '80%',
  };

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [ResumeData, setResumeData] = useState([]);

  useEffect(() => {
    log_debug("recieved resume candidate guid", data);
    setIsLoading(true);
    dispatch(
      GenerateResume({
        IndividualGuid: data?.guid,
        maskstatus: usertype === "client" ? true : false,
      }),
    )
      .then((response) => {
        log_debug("response", response.payload.data?.individual[0]);
        if (response.payload.success === true) {
          setResumeData(response.payload.data?.individual[0]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // Handle error here
        console.error("Error:", error);
        setIsLoading(false); // You may want to set isLoading to false in case of error too
      });
  }, [data]);

  const componentRef = useRef(null);

  const generatePDF = async () => {
    log_debug("entered generate pdf");
    try {
      // Capture UI as PDF
      const canvas = await html2canvas(componentRef.current);
      const pdfData = canvas.toDataURL("image/JPEG");
      // const pdfFile = new File([pdfBlob], 'CB_Resume.pdf', { type: 'application/pdf' });

      const pdf = new jsPDF({
        orientation: "portrait", // or 'landscape'
        unit: "mm",
        format: "a4",
      });
      // Calculate dimensions of the content
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = pdf.internal.pageSize.getHeight();
      const imgDataWidth = canvas.width;
      const imgDataHeight = canvas.height;

      // Calculate scaling factors to fit content to page
      const scaleFactor = Math.min(
        imgWidth / imgDataWidth,
        imgHeight / imgDataHeight,
      );

      // Calculate new dimensions for the content
      const scaledWidth = imgDataWidth * scaleFactor;
      const scaledHeight = imgDataHeight * scaleFactor;

      // Calculate position to center content on the page
      const x = 0; // Left edge of the page
      const y = 10; // Top edge of the page

      // Add image to PDF with adjusted size and position
      pdf.addImage(pdfData, "JPEG", x, y, scaledWidth, scaledHeight);
      // pdf.addImage(pdfData, 'JPEG', 0, 0);
      // pdf.output('dataurlnewwindow');
      //pdf.save("download.pdf");
      const blob = pdf.output("blob");
      // Call API to send PDF
      const formData = new FormData();
      // formData.append('resume', pdfFile);
      formData.append("resume", blob, "CB_Resume.pdf");

      setIsLoading(true);
      dispatch(
        SaveGenerateResume({ IndividualGuid: data?.guid, body: formData }),
      )
        .then((response) => {
          if (response.payload.success === true) {
            if (downloadstatus) {
              setIsLoading(false);
              setisDisabled(true);
              const link = document.createElement("a");
              link.href = response.payload?.data?._s3_url?.Location;
              link.setAttribute("download", "CB_Resume.pdf"); // Use a default filename or get it dynamically if available
              document.body.appendChild(link);
              link.click();
              link.remove();
            } else {
              setIsLoading(false);
              setisDisabled(true);
              toast.success("Resume saved successfully");
            }
          } else {
            setIsLoading(false);
            setisDisabled(true);
            toast.warning("Resume saved successfully");
          }
        })
        .catch((error) => {
          // Handle error here
          console.error("Error:", error);
          setIsLoading(false); // You may want to set isLoading to false in case of error too
        });

      console.log("PDF sent successfully!");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {isLoading && <Preloader />}
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          {/* <p className="text-light">Candidate Details</p> */}
          <h5 className="text-primary">
            Resume &nbsp; &nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Generate and Download
              candidate resume
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {ResumeData && (
                    <div
                      className="invoice-title w-100 text-center "
                      id="resumeToPrint"
                      ref={componentRef}
                    >
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1 ">
                          <div className="mb-4 ">
                            <img src={CBlogo} alt="Company Bench" height="40" />
                          </div>
                        </div>
                      </div>
                      <div className="mx-2">
                        <address>
                          <h2>{ResumeData?.full_name}</h2>
                          <p className="fw-bold opacity-75">
                            Email: {ResumeData?.email}
                            <br />
                            Phone: {ResumeData?.contact_number}
                            <br />
                            Address:{" "}
                            {`${ResumeData?.address?.street},${ResumeData?.address?.city},`}
                            <br />
                            {`${ResumeData?.address?.country}`}
                            <br />
                          </p>
                          <div>
                            <h4>
                              {ResumeData?.role && `Role - ${ResumeData?.role}`}
                            </h4>
                          </div>
                        </address>
                        <div>
                          <h4 className=" bg-info bg-opacity-25 px-2 py-1">
                            Summary
                          </h4>
                          <p>{ResumeData?.summary}</p>
                        </div>
                        <div className="mb-3">
                          <h4 className=" bg-info bg-opacity-25 px-2 py-1">
                            Primary Skills
                          </h4>
                          {ResumeData?.skills &&
                            ResumeData?.skills.map((skill, index) => (
                              <Badge
                                key={index}
                                color="primary rounded-pill opacity-50 mx-1 font-size-12"
                              >
                                {skill}
                              </Badge>
                            ))}
                          {/* <ul className="fs-5">
                            {ResumeData?.skills && ResumeData?.skills.map((skill,index) =>
                              <li key={index}>{skill}</li>
                            )}
                            
                        </ul> */}
                        </div>
                        <div className="">
                          {ResumeData?.individual_resume?.certifications
                            .length != 0 && (
                            <div className="">
                              <h4 className=" bg-info bg-opacity-25 px-2 py-1">
                                Certifications
                              </h4>
                              <Table className="table table-bordered mt-2 mb-4">
                                <thead className="table-light">
                                  <tr>
                                    <th>Certification Name</th>
                                    <th>Completion Date</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ResumeData?.individual_resume
                                    ?.certifications &&
                                    ResumeData?.individual_resume?.certifications.map(
                                      (certificate, index) => (
                                        <tr key={index}>
                                          <td>
                                            {certificate?.CertificateName}
                                          </td>
                                          <td>
                                            {certificate?.CertificationDate}
                                          </td>
                                          <td>{certificate?.Status}</td>
                                        </tr>
                                      ),
                                    )}
                                </tbody>
                              </Table>
                            </div>
                          )}
                          <div className="">
                            <h4 className=" bg-info bg-opacity-25 px-2 py-1">
                              Employment History
                            </h4>
                            <div className="table-responsive">
                              <Table className="table table-bordered mt-2 mb-4">
                                <thead className="table-light">
                                  <tr>
                                    <th>Company Name</th>
                                    <th>Role</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ResumeData?.individual_resume
                                    ?.work_experiences &&
                                    ResumeData?.individual_resume?.work_experiences.map(
                                      (experience, index) => (
                                        <tr key={index}>
                                          <td>{experience?.company_name}</td>
                                          <td>{experience?.role}</td>
                                          <td>
                                            {dateyyyymmdd_tohtml(
                                              experience?.from_date,
                                            )}
                                          </td>
                                          <td>
                                            {dateyyyymmdd_tohtml(
                                              experience?.to_date,
                                            )}
                                          </td>
                                        </tr>
                                      ),
                                    )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          <div className="">
                            <h4 className=" bg-info bg-opacity-25 px-2 py-1">
                              Education History
                            </h4>
                            <div className="table-responsive">
                              <Table className="table table-bordered mt-2 mb-4">
                                <thead className="table-light">
                                  <tr>
                                    <th>School/College</th>
                                    <th>Degree</th>
                                    <th>Start Year</th>
                                    <th>End Year</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ResumeData?.individual_resume
                                    ?.educational_qualifications &&
                                    ResumeData?.individual_resume?.educational_qualifications.map(
                                      (education, index) => (
                                        <tr key={index}>
                                          <td>
                                            {
                                              education?.college_or_university_name
                                            }
                                          </td>
                                          <td>{education?.degree_name}</td>
                                          <td>
                                            {dateyyyymmdd_tohtml(
                                              education?.from_date,
                                            )}
                                          </td>
                                          <td>
                                            {dateyyyymmdd_tohtml(
                                              education?.to_date,
                                            )}
                                          </td>
                                        </tr>
                                      ),
                                    )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="text-center">
                    <Button onClick={generatePDF} disabled={isDisabled}>
                      {downloadstatus ? "Download" : "Save"}
                      {/* <a href="https://cb-public-125605.s3.ap-south-1.amazonaws.com/8ri4dbkv/resumes/de8f542b-Pratik%20Mahandule%20Resume.pdf" download="resume.pdf">Download</a> */}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default GenerateResumeCanvas;

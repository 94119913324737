import React from "react";
import CustomTable from "../../../customComponents/reactTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { Badge } from "reactstrap";
import { log_debug } from "../../../apiHelpers/logger";
import CanvasCandidate from "../../../customComponents/offcanvas/CanvasCandidate";
import { Link } from "react-router-dom";
import CustomAvatar from "../../../customComponents/CustomAvatar/CustomAvatar";
import { Tags } from "../../../pages/Contacts/ContactList/contactlistCol";
import dayjs from "dayjs";
import NoDataFound from "../../../customComponents/utilities/NoData";
import CandidateStatusBadge from "../../../customComponents/utilities/CandidateStatusBadge";
import truncateName from "../../../helpers/truncate_name";

function InvitedCandidates({
  data,
  RecordCount,
  currentPage,
  handlePageChange,
}) {
  const [CandidateData, setCandidateData] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);

  const candidatedetails = (data) => {
    setCandidateData(data);
    setOffcanvasOpen(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  //   useEffect(() => {
  // log_debug("recieved current page",currentPage)
  //   },[currentPage])

  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidatedetails(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar fullName={row.values.full_name.charAt(0)} />
            <span style={{ marginTop: "5px" }}>
              {" "}
              {truncateName(row.values.full_name)}
            </span>
          </div>
        </Link>
      ),
    },
    {
      Header: "Location",
      accessor: "prefered_locations",
      Cell: ({ value }) => value.join(", "),
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: ({ row }) => (

      //   row.values.skills.map((skill, index) => (
      //     <Badge key={index}  className="bg-secondary-subtle text-secondary rounded-pill m-1 font-size-12">
      //       {skill}
      //     </Badge>
      //   ))

      //),
      // Cell: (props) =>
      //   const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
      //   return <span>{tidyDate}</span>;
      // },
    },
    {
      Header: "Invited on",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      // Cell: ({ row }) => (
      //   <Badge
      //     color={
      //       row.values.status === "contracted"
      //         ? "success  rounded-pill "
      //         : "warning  rounded-pill"
      //     }
      //   >
      //     {row.values.status}
      //   </Badge>
      // ),
      Cell: ({ value }) => <CandidateStatusBadge status={value} />,
    },
    // {
    //   Header: "Actions",
    //   accessor: "",
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  return (
    <>
      <CustomTable
        tableDatas={data}
        headerData={[]}
        columns={columns}
        data={data}
        hideCheckbox={true}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalCount={RecordCount}
      />

      {isOffcanvasOpen && (
        <CanvasCandidate
          usertype="client"
          menuOption="overview"
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={CandidateData}
        />
      )}
    </>
  );
}

export default InvitedCandidates;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ClientGuid } from "../apiConfig/axiosDev";
import {
  sendGetRestRequest,
  sendGetRestRequestJson,
  sendPostRestRequest,
  sendPostRestRequestAppJson,
} from "../apiConfig/axiosInstance";
import { log_debug } from "../apiHelpers/logger";
import { getClientGuid } from "../apiConfig/globalvariables";

const initialState = {
  data: {},
  status: "idle",
  loading: false,
  error: "",
};

// // To get list of my candidate
// export const mycandidates = createAsyncThunk("partner/individual", async () => {
//   const response = await sendGetRestRequest(`v1/partner/${guid}/individual`);
//   return response.data;
// });

// addCandidatesLocation dropdown
export const getLocations = createAsyncThunk("Client/Locations", async () => {
  const response = await sendGetRestRequest(`v1/enumcity?limit=1200`);
  return response.data;
});
// addCandidatesSkills dropdown
export const getJobRoles = createAsyncThunk("Client/Jobroles", async () => {
  const response = await sendGetRestRequest(`v1/enumskill?limit=1200`);
  return response.data;
});

// Client Jobs
export const ClientAllJobs = createAsyncThunk(
  "Client/clientAlljobs",
  async ({ offset, skills, locations, filtervalue }) => {
    log_debug("Client slice guid", ClientGuid);

    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/jobdescription?limit=10&offset=${offset}&skills=${skills}&locations=${locations}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Job Description
export const getJobDescription = createAsyncThunk(
  "Client/JobDescription",
  async (guid) => {
    const response = await sendGetRestRequest(`v1/jobdescription/${guid}`);
    return response.data;
  },
);

//Matching Candidates
export const getMatchingCandidates = createAsyncThunk(
  "Client/MatchingCandidates",
  async ({ offset, skills, locations, filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual?limit=10&offset=${offset}&skills=${skills}&locations=${locations}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Candidates details for shortlisted,invited,applied
export const getCandidates = createAsyncThunk(
  "Client/Candidates",
  async ({ status, offset, skills, locations, filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual?status=${status}&limit=10&offset=${offset}&skills=${skills}&locations=${locations}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//All Candidates
export const getAllCandidates = createAsyncThunk(
  "Client/AllCandidates",
  async ({ skills, locations, offset, filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individuals?skills=${skills}&locations=${locations}&limit=10&offset=${offset}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Enum Skills
export const getSkills = createAsyncThunk("Client/Skills", async () => {
  const response = await sendGetRestRequest(`v1/enumskill?limit=1200`);
  return response.data;
});
//Client Profile Details
export const getProfileDetails = createAsyncThunk(
  "Client/getProfileDetails",
  async () => {
    const response = await sendGetRestRequest(
      `v1/client/${getClientGuid() || ClientGuid}`,
    );
    return response.data;
  },
);

//Invited candidates
export const getInvitedCandidates = createAsyncThunk(
  "Client/InvitedCandidates",
  async ({ guid, offset }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual/jobdescription/${guid}?limit=10&status=invited&offset=${offset}`,
    );
    return response.data;
  },
);

//shortlisted candidates
export const getShortlistedCandidates = createAsyncThunk(
  "Client/ShortlistedCandidates",
  async ({ guid, offset }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual/jobdescription/${guid}?limit=10&status=shortlisted&offset=${offset}`,
    );
    return response.data;
  },
);

//generate responsibilities and summary
export const postJobDescription = createAsyncThunk(
  "Client/PostJobDescription",
  async (formdata) => {
    log_debug("recieved form data", formdata);
    const response = await sendPostRestRequestAppJson(
      `v1/jobdescription/generate/job-description`,
      formdata,
    );
    return response.data;
  },
);
//update client profile details
export const postProfileDetails = createAsyncThunk(
  "Client/postProfileDetails",
  async (formdata) => {
    log_debug("recieved form data", formdata);
    const response = await sendPostRestRequestAppJson(
      `v1/client/${getClientGuid() || ClientGuid}`,
      formdata,
    );
    return response.data;
  },
);

//Overview
export const getOverview = createAsyncThunk("Client/Overview", async (guid) => {
  const response = await sendGetRestRequest(
    `v1/client/${getClientGuid() || ClientGuid}/individual/${guid}`,
  );
  return response.data;
});

//Interviews
export const getInterviews = createAsyncThunk(
  "Client/Interviews",
  async ({ offset, filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual/interview?limit=10&offset=${offset}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Shortlisted jobs based on individual
export const getCandidateShortlistedJobs = createAsyncThunk(
  "Client/Candidate/ShortlistedJobs",
  async ({ guid, currentPage }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual/${guid}?status=shortlisted&limit=10&offset=${currentPage}`,
    );
    return response.data;
  },
);

//Invited jobs based on individual
export const getCandidateInvitedJobs = createAsyncThunk(
  "Client/Candidate/InvitedJobs",
  async ({ guid, currentPage }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual/${guid}?status=invited&limit=10&offset=${currentPage}`,
    );
    return response.data;
  },
);

//post job
export const postJob = createAsyncThunk("Client/PostJob", async (data) => {
  log_debug("recieved form data", data);
  const response = await sendPostRestRequestAppJson(`v1/jobdescription/`, data);
  return response.data;
});

//Matching candidates based on job
export const getJobMatchingCandidates = createAsyncThunk(
  "Client/Job/Candidates",
  async ({ guid, offset }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual/jobdescription/${guid}?limit=10&offset=${offset}`,
    );
    return response.data;
  },
);

//Candidate matching jobs
export const getCandidateMatchingJobs = createAsyncThunk(
  "Client/Candidate/MatchingJobs",
  async ({ guid, currentPage }) => {
    log_debug("recived api matchingjobs guid", guid);
    const response = await sendGetRestRequestJson(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual/${guid}/jobdescription?limit=10&offset=${currentPage}`,
    );
    return response.data;
  },
);

//Client dashboard count
export const getClientDashboardCount = createAsyncThunk(
  "Client/DashboardCount",
  async ({ fromDate, toDate }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/dashboard/count?from_date=${fromDate}&to_date=${toDate}`,
    );
    return response.data;
  },
);

//Client Offers
export const Offer = createAsyncThunk(
  "client/Offer",
  async ({ offset, filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/offer?limit=10&offset=${offset}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

export const CandidatureHistory = createAsyncThunk(
  "client/CandidatureHistory",
  async (ind_job_his) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual_history/${ind_job_his}`,
    );
    return response.data;
  },
);

//Client graph count
export const getClientGraphCount = createAsyncThunk(
  "Client/GraphCount",
  async ({ fromDate, toDate }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/dashboard/graph?from_date=${fromDate}&to_date=${toDate}`,
    );
    return response.data;
  },
);

//ClientContract
export const Contract = createAsyncThunk(
  "client/Contract",
  async ({ offset, filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/client_contract?limit=10&offset=${offset}&searchText=${filtervalue}`,
    );
    return response.data;
  },
);

export const ContractHistory = createAsyncThunk(
  "client/ContractHistory",
  async (ConGuid) => {
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/client_contract_history/${ConGuid}`,
    );
    return response.data;
  },
);

export const IndividualDetail = createAsyncThunk(
  "client/IndividualDetail",
  async (indvGuid) => {
    const response = await sendGetRestRequest(`v1/individual/${indvGuid}`);
    return response.data;
  },
);

export const JobDetail = createAsyncThunk(
  "client/JobDetail",
  async (jobGuid) => {
    const response = await sendGetRestRequest(`v1/jobdescription/${jobGuid}`);
    return response.data;
  },
);

//Dashboard MyJobs table
export const getDashboardMyJobs = createAsyncThunk(
  "client/DashboardMyJobs",
  async ({ startDate, endDate, currentPage }) => {
    log_debug("dashboard my jobs recieved date", startDate);
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/jobdescription?from_date=${startDate}&to_date=${endDate}&limit=10&offset=${currentPage}`,
    );
    return response.data;
  },
);

//Dashboard Invitations table
export const getDashboardInvitations = createAsyncThunk(
  "client/DashboardInvitations",
  async ({ startDate, endDate, currentPage }) => {
    log_debug("dashboard Invitations recieved date", startDate);
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual?status=invited&from_date=${startDate}&to_date=${endDate}&limit=10&offset=${currentPage}`,
    );
    return response.data;
  },
);

//Dashboard Interviews table
export const getDashboardInterviews = createAsyncThunk(
  "client/DashboardInterviews",
  async ({ startDate, endDate, currentPage }) => {
    log_debug("dashboard interviews recieved date", startDate);
    const response = await sendGetRestRequest(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/individual/interview?from_date=${startDate}&to_date=${endDate}&limit=10&offset=${currentPage}`,
    );
    return response.data;
  },
);

//Dashboard Offers table
export const getDashboardOffers = createAsyncThunk(
  "client/DashboardOffers",
  async ({ startDate, endDate, currentPage }) => {
    log_debug("dashboard Offers recieved date", startDate);
    const response = await sendGetRestRequestJson(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/offer?from_date=${startDate}&to_date=${endDate}&limit=10&offset=${currentPage}`,
    );
    return response.data;
  },
);

//Dashboard Contracts table
export const getDashboardContracts = createAsyncThunk(
  "client/DashboardContracts",
  async ({ startDate, endDate, currentPage }) => {
    log_debug("dashboard Contracts recieved date", startDate);
    const response = await sendGetRestRequestJson(
      `v1/client/${
        getClientGuid() || ClientGuid
      }/client_contract?from_date=${startDate}&to_date=${endDate}&limit=10&offset=${currentPage}`,
    );
    return response.data;
  },
);

//Client Invite Multiple Candidates based on job
export const postClientInviteMultipleCandidatesJob = createAsyncThunk(
  "Client/JobInviteMultipleCandidate",
  async ({ formdata }) => {
    const response = await sendPostRestRequestAppJson(
      `v1/client/${getClientGuid() || ClientGuid}/send_job_invite`,
      formdata,
    );
    return response.data;
  },
);
//Client Invite Multiple Candidates based on job
export const postEditJobDescription = createAsyncThunk(
  "Clientjob/description",
  async ({ job_guid, job_description }) => {
    const response = await sendPostRestRequestAppJson(
      `v1/jobdescription/${job_guid.guid}`,
      job_description,
    );
    return response.data;
  },
);
export const RoleBasedSkill = createAsyncThunk(
  "client/RoleBasedSkill",
  async (role) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${getClientGuid() || ClientGuid}/role/${role}/get_skills`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching role-based skills:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

//Client Invite Multiple Candidates based on job
export const postClientInviteMultipleJob = createAsyncThunk(
  "Client/JobInviteMultipleJobs",
  async ({ formdata }) => {
    const response = await sendPostRestRequestAppJson(
      `v1/client/${getClientGuid() || ClientGuid}/send_job_invite_indv`,
      formdata,
    );
    return response.data;
  },
);

const ClientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ClientAllJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ClientAllJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.clientjobs = action.payload;
      })
      .addCase(ClientAllJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.clientjobs = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getJobDescription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getJobDescription.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.jobdescription = action.payload;
      })
      .addCase(getJobDescription.rejected, (state, action) => {
        state.status = "idle";
        state.data.jobdescription = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getMatchingCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMatchingCandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.matchingcandidates = action.payload;
      })
      .addCase(getMatchingCandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.matchingcandidates = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getLocations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.locations = action.payload;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.status = "idle";
        state.data.locations = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getJobRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getJobRoles.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.jobroles = action.payload;
      })
      .addCase(getJobRoles.rejected, (state, action) => {
        state.status = "idle";
        state.data.jobroles = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.candidatesdata = action.payload;
      })
      .addCase(getCandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.candidatesdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getAllCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.allcandidates = action.payload;
      })
      .addCase(getAllCandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.allcandidates = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getSkills.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSkills.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.skills = action.payload;
      })
      .addCase(getSkills.rejected, (state, action) => {
        state.status = "idle";
        state.data.skills = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(getProfileDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProfileDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.getProfileDetailsData = action.payload;
      })
      .addCase(getProfileDetails.rejected, (state, action) => {
        state.status = "idle";
        state.data.getProfileDetailsData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getInvitedCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInvitedCandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.invitedcandidates = action.payload;
      })
      .addCase(getInvitedCandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.invitedcandidates = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getShortlistedCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getShortlistedCandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.shortlistedcandidates = action.payload;
      })
      .addCase(getShortlistedCandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.shortlistedcandidates = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(postJobDescription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postJobDescription.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.jobdescription = action.payload;
      })
      .addCase(postJobDescription.rejected, (state, action) => {
        state.status = "idle";
        state.data.jobdescription = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(postProfileDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postProfileDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.getProfileDetailsData = action.payload;
      })
      .addCase(postProfileDetails.rejected, (state, action) => {
        state.status = "idle";
        state.data.getProfileDetailsData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getOverview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOverview.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.overview = action.payload;
      })
      .addCase(getOverview.rejected, (state, action) => {
        state.status = "idle";
        state.data.overview = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getInterviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInterviews.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.interviews = action.payload;
      })
      .addCase(getInterviews.rejected, (state, action) => {
        state.status = "idle";
        state.data.interviews = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getCandidateShortlistedJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCandidateShortlistedJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ShortlistedJobs = action.payload;
      })
      .addCase(getCandidateShortlistedJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.ShortlistedJobs = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getCandidateInvitedJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCandidateInvitedJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.InvitedJobs = action.payload;
      })
      .addCase(getCandidateInvitedJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.InvitedJobs = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(postJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postJob.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.PostJob = action.payload;
      })
      .addCase(postJob.rejected, (state, action) => {
        state.status = "idle";
        state.data.PostJob = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getJobMatchingCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getJobMatchingCandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.JobMatchingCandidates = action.payload;
      })
      .addCase(getJobMatchingCandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.JobMatchingCandidates = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getCandidateMatchingJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCandidateMatchingJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidateMatchingJobs = action.payload;
      })
      .addCase(getCandidateMatchingJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidateMatchingJobs = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(Offer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(Offer.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.Offer = action.payload;
      })
      .addCase(Offer.rejected, (state, action) => {
        state.status = "idle";
        state.data.Offer = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(Contract.pending, (state) => {
        state.status = "loading";
      })
      .addCase(Contract.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.Contract = action.payload;
      })
      .addCase(Contract.rejected, (state, action) => {
        state.status = "idle";
        state.data.Contract = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CandidatureHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CandidatureHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidatureHistory = action.payload;
      })
      .addCase(CandidatureHistory.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidatureHistory = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(ContractHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ContractHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ContractHistorydata = action.payload;
      })
      .addCase(ContractHistory.rejected, (state, action) => {
        state.status = "idle";
        state.data.ContractHistorydata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(IndividualDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(IndividualDetail.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.IndividualDetail = action.payload;
      })
      .addCase(IndividualDetail.rejected, (state, action) => {
        state.status = "idle";
        state.data.IndividualDetail = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(JobDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(JobDetail.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.JobDetail = action.payload;
      })
      .addCase(JobDetail.rejected, (state, action) => {
        state.status = "idle";
        state.data.JobDetail = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getClientDashboardCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getClientDashboardCount.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ClientDashboardCount = action.payload;
      })
      .addCase(getClientDashboardCount.rejected, (state, action) => {
        state.status = "idle";
        state.data.ClientDashboardCount = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getClientGraphCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getClientGraphCount.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ClientGraphCount = action.payload;
      })
      .addCase(getClientGraphCount.rejected, (state, action) => {
        state.status = "idle";
        state.data.ClientGraphCount = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getDashboardMyJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardMyJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardMyJobs = action.payload;
      })
      .addCase(getDashboardMyJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardMyJobs = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getDashboardInvitations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardInvitations.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardInvitations = action.payload;
      })
      .addCase(getDashboardInvitations.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardInvitations = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getDashboardInterviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardInterviews.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardInterviews = action.payload;
      })
      .addCase(getDashboardInterviews.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardInterviews = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getDashboardOffers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardOffers.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardOffers = action.payload;
      })
      .addCase(getDashboardOffers.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardOffers = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getDashboardContracts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardContracts.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardContracts = action.payload;
      })
      .addCase(getDashboardContracts.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardContracts = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(postClientInviteMultipleCandidatesJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        postClientInviteMultipleCandidatesJob.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.data.jobMultipleInviteCandidates = action.payload;
        },
      )
      .addCase(
        postClientInviteMultipleCandidatesJob.rejected,
        (state, action) => {
          state.status = "idle";
          state.data.jobMultipleInviteCandidates = {
            status: "failed ",
            message: `${action.error.name} : ${action.error.message}`,
          };
        },
      )
      .addCase(postEditJobDescription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postEditJobDescription.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.jobMultipleInviteCandidates = action.payload;
      })
      .addCase(postEditJobDescription.rejected, (state, action) => {
        state.status = "idle";
        state.data.jobMultipleInviteCandidates = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(RoleBasedSkill.pending, (state) => {
        state.status = "loading";
      })
      .addCase(RoleBasedSkill.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.RoleBasedSkill = action.payload;
      })
      .addCase(RoleBasedSkill.rejected, (state, action) => {
        state.status = "idle";
        state.data.RoleBasedSkill = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(postClientInviteMultipleJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postClientInviteMultipleJob.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.invitejob = action.payload;
      })
      .addCase(postClientInviteMultipleJob.rejected, (state, action) => {
        state.status = "idle";
        state.data.invitejob = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      });
  },
});

export default ClientSlice.reducer;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
  Button,
} from "reactstrap";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import { lineStyle, dropdownOptions, columns, jobs } from "../data/Data";
import DropDownHeader from "../customComponents/DropDownHeader";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { Link } from "react-router-dom";

import dayjs from "dayjs";
import { getClientDashboardCount, getMatchingCandidates } from "./ClientSlice";
import AdvanceFilter from "../customComponents/advance filter/AdvanceFilter";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import CanvasCandidate from "../customComponents/offcanvas/CanvasCandidate";
import { log_debug } from "../apiHelpers/logger";
import Preloader from "../components/Common/Preloader";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar";
import { FiInbox } from "react-icons/fi";
import CandidateStatusBadge from "../customComponents/utilities/CandidateStatusBadge";
import moment from "moment";

function MatchingCandidates() {
  const dispatch = useDispatch();
  const clientstore = useSelector((state) => state.clientSlice);
  const [MatchingCandidates, setMatchingCandidates] = useState([]);
  const [CandidateData, setCandidateData] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [RecordCount, setRecordCount] = useState(0);
  const [filtervalue, setFiltervalue] = useState("");
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getClientDashboardCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  // useEffect(() => {
  //   dispatch(
  //     getMatchingCandidates({
  //       limit: 100,
  //       offset: currentPage,
  //     }),
  //   );
  // }, []);

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.matchingcandidates?.success === true) {
        setMatchingCandidates(
          clientstore.data.matchingcandidates?.data?.individuals,
        );
        setRecordCount(clientstore.data.matchingcandidates?.data?.totalCount);
        log_debug("Matching Candidates", MatchingCandidates);
      }
      setisLoading(false);
    } else if (clientstore.status === "loading") {
      setisLoading(true);
    }
  }, [clientstore.data.matchingcandidates]);

  const yourDynamicHeaderData = {
    title: "Name",
    location: "Location",
    skills: "Skills",
    status: "Status",
  };

  const candidatedetails = (data) => {
    setCandidateData(data);
    setOffcanvasOpen(true);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidatedetails(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar fullName={row.values.full_name.charAt(0)} />
            <span style={{ marginTop: "5px" }}> {row.values.full_name}</span>
          </div>
        </Link>
      ),
    },
    {
      Header: "Prefered Location",
      accessor: "prefered_locations",
      Cell: ({ value }) => (value.length === 0 ? "-" : value.join(", ")),
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },

      //   Cell: ({ row }) => (

      //     row.values.skills.map((skill, index) => (
      //       <Badge key={index}  className="bg-secondary-subtle text-secondary rounded-pill m-1 font-size-12">
      //         {skill}
      //       </Badge>
      //     ))

      // ),

      // Cell: (props) =>
      //   const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
      //   return <span>{tidyDate}</span>;
      // },
    },
    {
      Header: "Created on",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      // Cell: ({ row }) => (
      //   <Badge
      //     color={
      //       row.values.status === "active"
      //         ? "success text-white rounded-pill"
      //         : "warning text-white rounded-pill"
      //     }
      //   >
      //     {row.values.status}
      //   </Badge>
      // ),
      Cell: ({ value }) => <CandidateStatusBadge status={value} />,
    },
    // {
    //   Header: "Actions",
    //   accessor: "",
    //   Cell: ({ row }) => (
    //     <>
    //       <div className="m-auto">

    //           <Button
    //             color="primary"
    //             className="p-1"
    //             // color={appliedRows.includes(row.id) ? "success" : "primary"}
    //             // disabled={appliedRows.includes(row.id)}
    //             // onClick={() => handleButtonClick(row)}
    //           >
    //             {/* <span>
    //               <i className="bx bx-mail-send mx-1" />
    //             </span> */}
    //             <span><FiInbox/> Invite</span>
    //             {/* {appliedRows.includes(row.id) ? "Applied" : "Apply"} */}
    //           </Button>
    //       </div>
    //     </>
    //   ),
    // },
    // {
    //   Header: "Actions",
    //   accessor: "actions",
    //   Cell: ({ row }) => (
    //     <>
    //       <Link to={"#"} className="text-white">
    //         {/* <Button
    //           color="primary"
    //           variant="primary"
    //           className=""
    //           id="applyMultipleJobButton"
    //           onClick={() => {
    //             candidateName2(row.original);

    //           }}
    //         >
    //           Apply
    //         </Button> */}
    //         <Button
    //         color="primary"
    //         className="p-1"
    //           // color={appliedRows.includes(row.id) ? "success" : "primary"}
    //           // disabled={appliedRows.includes(row.id)}
    //           // onClick={() => handleButtonClick(row)}
    //         >
    //           Invite
    //           {/* {appliedRows.includes(row.id) ? "Applied" : "Apply"} */}
    //         </Button>
    //       </Link>
    //     </>
    //   ),
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  const [Location, setLocation] = useState([]);
  const [JobRole, setJobRole] = useState([]);

  const handleFilterChange = (option) => {
    if (option && option.location) {
      const locationLabels = option.location.map((location) => location.label);
      setLocation(locationLabels);
    }

    if (option && option.jobRole) {
      const jobRoleLabels = option.jobRole.map((jobRole) => jobRole.label);
      setJobRole(jobRoleLabels);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        getMatchingCandidates({
          offset: currentPage,
          skills: JobRole,
          locations: Location,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [currentPage, Location, JobRole, filtervalue]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setisLoading(true);
    setFiltervalue(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Preloader />}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Matching Candidates"
            Name="Matching Candidates"
          />
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <Card
                    className=" pt-3"
                  > */}
                  <CardBody>
                    <Row>
                      <AdvanceFilter onFilterChange={handleFilterChange} />
                      <div className="col-12 col-lg-6 col-md-6">
                        <Col md={12}>
                          <CardTitle className="text-end fs-6 pt-3">
                            Total Candidates : {RecordCount}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                  {/* </Card> */}
                </div>
                <div>
                  <Card className="">
                    <CardBody>
                      <CustomTable
                        tableDatas={MatchingCandidates}
                        headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={MatchingCandidates}
                        isLoading={isLoading}
                        //multipleInvite={true}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        ShowPagination={
                          MatchingCandidates && MatchingCandidates.length > 10
                            ? true
                            : false
                        }
                        hideCheckbox={true}
                        totalCount={RecordCount}
                        onChange={handleValueChange}
                        globalsearch={true}
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <CanvasCandidate
          usertype="client"
          menuOption="matchingcandidates"
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={CandidateData}
        />
      )}
    </React.Fragment>
  );
}

export default MatchingCandidates;

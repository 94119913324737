function maskSurname(fullName) {
  // Split the full name into name and surname
  const nameParts = fullName.split(" ");

  // If the full name doesn't consist of at least two parts (name and surname), return the original full name
  if (nameParts.length < 2) {
    return fullName;
  }

  // Extract the surname from the last part of the full name
  const surname = nameParts.pop();

  // Determine the length of the surname
  const surnameLength = surname.length;

  // Replace each character of the surname with an asterisk
  const maskedSurname = "*".repeat(surnameLength);

  // Concatenate the name and the masked surname back together
  const maskedFullName = nameParts.join(" ") + " " + maskedSurname;

  return maskedFullName;
}

export default maskSurname;

import React, { useState } from "react";
import CustomTable from "../../../customComponents/reactTable/CustomTable";
import { useEffect } from "react";
import { Tags } from "../../../pages/Contacts/ContactList/contactlistCol";
import { getJobDescription } from "../../ClientSlice";
import { useDispatch } from "react-redux";
import Preloader from "../../../components/Common/Preloader";
import JobOverview from "../../../customComponents/offcanvas/JobOverviewCanvas";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Badge } from "reactstrap";

function ShortlistedJobs({ data, RecordCount, currentPage, handlePageChange }) {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [JobDescription, setJobDescription] = useState([]);

  const jobdescription = (guid) => {
    setloading(true);
    dispatch(getJobDescription(guid)).then((response) => {
      if (response.payload?.success === true) {
        setJobDescription(response.payload?.data?.jobdescription);
        setloading(false);
        setOffcanvasOpen(true);
      }
    });
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  const columns = [
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            jobdescription(row.original.guid);
          }}
        >
          {row.values.title}
        </Link>
      ),
      //   Cell: ({ row }) => (
      //     <Link
      //       to={"#"}
      //       onClick={() => {
      //         Job(row.original);
      //       }}
      //     >
      //       {row.values.title}
      //     </Link>
      //   ),
    },

    {
      Header: "Location",
      accessor: "location",
      Cell: ({ value }) => value.join(", "),
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    {
      Header: "Created on",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <Badge
          color={
            row.values.status === "active"
              ? "success text-white rounded-pill"
              : "warning text-white rounded-pill"
          }
        >
          {row.values.status}
        </Badge>
      ),
    },

    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  return (
    <>
      {loading && <Preloader />}
      <CustomTable
        tableDatas={data}
        headerData={[]}
        columns={columns}
        data={data}
        hideCheckbox={true}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalCount={RecordCount}
      />

      {isOffcanvasOpen && (
        <JobOverview
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={JobDescription}
        />
      )}
    </>
  );
}

export default ShortlistedJobs;

import React, { useEffect, useState } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import CustomBreadcrumb from "../../customComponents/breadcrumb/CustomBreadcrumb";
import upload from "../../../src/assets/images/companyBenchImage/contract.png";
import { useSelector, useDispatch } from "react-redux";
import {
  AddContractSubmit,
  ContractOfferList,
  CreateContractAotofillForm,
} from "../PartnerSlice";
import { log_debug } from "../../apiHelpers/logger";
import { getPartnerGuid } from "../../apiConfig/globalvariables";
import { Pguid } from "../../apiConfig/axiosDev";

const AddContract = () => {
  // Mock data for dropdown options
  const offerOptions = ["Offer 1", "Offer 2", "Offer 3"];
  const candidateOptions = ["Candidate 1", "Candidate 2", "Candidate 3"];
  const statusOptions = ["pending", "accepted", "started", "ended"];
  const BillingOption = ["monthly", "quarterly", "annual", "onetime"];
  const breadcrumbValues = ["Contracts", "Add Contract"];
  const [Offerdropdowndata, setOfferdropdowndata] = useState([]);
  const [offerAutofilldata, setofferAutofilldata] = useState([]);
  const [locationdropdown, setlocationdropdown] = useState([]);
  const [offerID, setofferID] = useState([]);
  const [locationfield, setlocationfield] = useState([]);
  const [StartDateinfo, setStartDateinfo] = useState([]);
  const [endDateinfo, setendDateinfo] = useState([]);
  const [statusinfo, setstatusinfo] = useState([]);
  const [valueinfo, setvalueinfo] = useState([]);
  const [billingCycleinfo, setbillingCycleinfo] = useState([]);

  log_debug("locationdropdown", locationdropdown);

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);

  useEffect(() => {
    dispatch(ContractOfferList());
    // dispatch(CreateContractAotofillForm({
    //   OfferID: offerID
    // }))
  }, []);

  useEffect(() => {
    dispatch(
      CreateContractAotofillForm({
        OfferID: offerID,
      }),
    );
  }, [offerID]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore?.data?.ContractOfferListdata?.success === true) {
        setOfferdropdowndata(
          partnerStore?.data?.ContractOfferListdata?.data?.offers,
        );
      }
      if (
        partnerStore?.data?.CreateContractAotofillFormdata?.success === true
      ) {
        setofferAutofilldata(
          partnerStore?.data?.CreateContractAotofillFormdata?.data
            ?.offer_details,
        );
        setlocationdropdown(
          partnerStore?.data?.CreateContractAotofillFormdata?.data
            ?.offer_details[0]?.location,
        );
      }
    }
  }, [partnerStore]);

  const validationSchema = Yup.object().shape({
    offerId: Yup.string().required("Offer ID is required"),
    location: Yup.string().required("Location is required"),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date().required("End Date is required"),
    status: Yup.string().required("Status is required"),
    valuesdata: Yup.number().required("Value is required"),
    billingCycle: Yup.string().required("Billing Cycle is required"),
  });

  const initialValues = {
    offerId: "",
    candidateName: "",
    designation: "",
    location: locationdropdown,
    startDate: "",
    endDate: "",
    status: "",
    valuesdata: "",
    billingCycle: "",
  };

  const onSubmit = (values, { resetForm }) => {
    dispatch(
      AddContractSubmit({
        partnercontract: {
          partner_guid: getPartnerGuid() || Pguid,
          client_guid: offerAutofilldata
            ? offerAutofilldata[0].client_guid
            : "",
          location: locationfield,
          started_at: StartDateinfo,
          ended_at: endDateinfo,
          status: statusinfo,
          contract_value: valueinfo,
          billing_cycle: billingCycleinfo,
          individuals: [
            {
              guid: offerAutofilldata
                ? offerAutofilldata[0].individual_guid
                : "",
              designation: offerAutofilldata[0]?.designation,
              jobdescription_guid: offerAutofilldata
                ? offerAutofilldata[0].jobdescription_guid
                : "",
              offer_guid: offerAutofilldata
                ? offerAutofilldata[0].offer_guid
                : "",
            },
          ],
        },
      }),
    );
    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div>
              <div className="col-lg-12">
                <CustomBreadcrumb values={breadcrumbValues} />
                <Col className="col-lg-12 col-md-12 col-sm-12 ">
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card
                        style={{
                          width: "80%",
                          height: "auto",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <CardHeader className="text-center bg-primary-subtle font-size-22">
                          <img
                            src={upload}
                            style={{ width: "60px" }}
                            alt="Dropzone Image"
                          />{" "}
                          <strong className="text-white">
                            Create Contract
                          </strong>
                        </CardHeader>
                        <CardBody>
                          <form onSubmit={formik.handleSubmit}>
                            <Row className="mb-3">
                              <Col>
                                <div className="form-group">
                                  <label htmlFor="offerId">Offer ID</label>
                                  <select
                                    type="select"
                                    required
                                    name="offerId"
                                    className="form-control"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.offerID}
                                    onChange={(e) => setofferID(e.target.value)}
                                  >
                                    <option value="" hidden>
                                      Select Offer ID
                                    </option>
                                    {Offerdropdowndata.map((item, index) => (
                                      <option key={index} value={item.guid}>
                                        {" "}
                                        {item.guid}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col>
                                <div className="form-group">
                                  <label htmlFor="candidateName">
                                    Candidate Name
                                  </label>
                                  <input
                                    type="text"
                                    required
                                    name="candidateName"
                                    className="form-control"
                                    value={offerAutofilldata[0]?.fullname || ""}
                                    disabled
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col>
                                <div className="form-group">
                                  <label htmlFor="designation">
                                    Designation
                                  </label>
                                  <input
                                    type="text"
                                    required
                                    name="designation"
                                    className="form-control"
                                    value={
                                      offerAutofilldata[0]?.designation || ""
                                    }
                                    disabled
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="form-group">
                                  <label htmlFor="location">location</label>
                                  <select
                                    type="select"
                                    name="location"
                                    required
                                    className="form-control"
                                    value={formik.location}
                                    onChange={(e) =>
                                      setlocationfield(e.target.value)
                                    }
                                  >
                                    {/* <option value="" hidden>
                                      Select location
                                    </option> */}
                                    {locationdropdown.map((option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col>
                                <div className="form-group">
                                  <label htmlFor="startDate">Start Date</label>
                                  <input
                                    type="date"
                                    required
                                    name="startDate"
                                    className="form-control"
                                    value={formik.startDate}
                                    onChange={(e) =>
                                      setStartDateinfo(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="form-group">
                                  <label htmlFor="endDate">End Date</label>
                                  <input
                                    type="date"
                                    name="endDate"
                                    className="form-control"
                                    value={formik.endDate}
                                    onChange={(e) =>
                                      setendDateinfo(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col>
                                <div className="form-group">
                                  <label htmlFor="status">Status</label>
                                  <select
                                    type="select"
                                    required
                                    name="status"
                                    className="form-control"
                                    value={formik.status}
                                    onChange={(e) =>
                                      setstatusinfo(e.target.value)
                                    }
                                  >
                                    <option value="" hidden>
                                      Select Status
                                    </option>
                                    {statusOptions.map((option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col>
                                <div className="form-group">
                                  <label htmlFor="valuesdata">Value</label>
                                  <input
                                    type="number"
                                    name="valuesdata"
                                    required
                                    className="form-control"
                                    value={formik.valuesdata}
                                    onChange={(e) =>
                                      setvalueinfo(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col className="col-lg-6">
                                <div className="form-group">
                                  <label htmlFor="billingCycle">
                                    Billing Cycle
                                  </label>
                                  <select
                                    type="select"
                                    name="billingCycle"
                                    required
                                    className="form-control"
                                    value={formik.billingCycle}
                                    onChange={(e) =>
                                      setbillingCycleinfo(e.target.value)
                                    }
                                  >
                                    <option value="" hidden>
                                      Select Billing Cycle
                                    </option>
                                    {BillingOption.map((option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <div className="text-center">
                              <button
                                type="submit"
                                disabled={Offerdropdowndata.length < 1}
                                className="btn btn-primary"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default AddContract;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Progress,
  Badge,
} from "reactstrap";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import { lineStyle, dropdownOptions, columns, jobs } from "../data/Data";
import DropDownHeader from "../customComponents/DropDownHeader";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { Link } from "react-router-dom";
import {
  getIndividualJobRoles,
  getIndividualJobs,
  getIndividualLocations,
} from "./individualSlice";
import CanvasJobdescription from "../customComponents/offcanvas/CanvasJobdescription";
import AdvanceFilter from "../customComponents/advance filter/AdvanceFilter";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { log_debug } from "../apiHelpers/logger";
import dayjs from "dayjs";

function IndividualJobs() {
  const individualStore = useSelector((state) => state.individualSlice);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const dispatch = useDispatch();
  const [JobsData, setJobsData] = useState([]);
  const [LocationOptions, setLocationOptions] = useState([]);
  const [JobRoleOptions, setJobRoleOptions] = useState([]);

  const yourDynamicHeaderData = {
    title: "Title",
    location: "Location",
    score: "Relevance",
  };

  const Job = (job) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedJob(job);
    setOffcanvasOpen(true);
  };

  const columns = [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            Job(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    // {
    //   Header: "Status",
    //   accessor: "status",
    // },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Relevance",
      accessor: "score",

      Cell: ({ value }) => (
        <div style={{ width: "35px", height: "35px", margin: "auto" }}>
          <CircularProgressbarWithChildren
            styles={{
              root: {}, // Adjust these values
              path: {
                stroke: "#5156be",
                strokeLinecap: "round",
                transition: "stroke-dashoffset 0.5s ease 0s",
                transform: "rotate(0.25turn)",
                transformOrigin: "center center",
                pathColor: "#74788d",
              },
              trail: {
                stroke: "#d6d6d6",
                strokeLinecap: "round",
                transform: "rotate(0.25turn)",
                transformOrigin: "center center",
              },
            }}
            value={value}
          >
            <div style={{ fontSize: 10 }}>
              <strong>{Math.floor(value)}%</strong>
            </div>
          </CircularProgressbarWithChildren>
          {/* <Progress
            style={{
              height: "15px",
            }}
            value={value}
          >
            {" "}
            {value}%{" "}
          </Progress> */}
        </div>
      ),
    },
    {
      Header: "Job Posted",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <Badge
          color={
            row.values.status === "active"
              ? "success-subtle text-success rounded-pill bg-opacity-50"
              : "warning-subtle text-warning rounded-pill"
          }
        >
          {row.values.status}
        </Badge>
      ),
    },

    // Add more columns as needed
  ];

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  useEffect(() => {
    dispatch(getIndividualJobs());
  }, []);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.jobsData?.success === true) {
        setJobsData(individualStore.data.jobsData?.data?.jobdescriptions);
        log_debug("jobs", JobsData);
      }
    }
  }, [individualStore]);

  const [Location, setLocation] = useState([]);
  const [JobRole, setJobRole] = useState([]);

  const handleFilterChange = (option) => {
    if (option && option.location) {
      const locationLabels = option.location.map((location) => location.label);
      setLocation(locationLabels);
    }

    if (option && option.jobRole) {
      const jobRoleLabels = option.jobRole.map((jobRole) => jobRole.label);
      setJobRole(jobRoleLabels);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Jobs" Name="Jobs" />
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <Card
                    className="border-light bg-light pt-3"
                  > */}
                  <CardBody>
                    <Row>
                      <AdvanceFilter onFilterChange={handleFilterChange} />
                      <div className="col-12 col-lg-6 col-md-6">
                        <Col md={12}>
                          <CardTitle className="text-end fs-6 pt-3">
                            Total Jobs : {JobsData && JobsData.length}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                  {/* </Card> */}
                </div>
                <div>
                  <Card>
                    <CardBody>
                      <CustomTable
                        tableDatas={JobsData}
                        headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={JobsData}
                        hideCheckbox={true}
                      />
                    </CardBody>
                  </Card>
                  {/* <DataTable title="All Jobs" columns={columns} data={jobs} /> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <CanvasJobdescription
          usertype="individual"
          menuOption="jobs"
          width={60}
          placement="end"
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onclose={closeOffcanvas}
          data={selectedJob}
        />
      )}
    </React.Fragment>
  );
}

export default IndividualJobs;

let baseURL;
let serviceRef = ""; // Default service_ref

if (window.location.hostname.includes("companybench.com")) {
  baseURL = "https://q7n88rwv8b.execute-api.ap-south-1.amazonaws.com/prod/";
  serviceRef = "3xu173ad";
} else {
  baseURL = "https://q7n88rwv8b.execute-api.ap-south-1.amazonaws.com/dev/";
  // baseURL = "192.168.40.115:7002/";
  serviceRef = "8ri4dbkv";
}

// // export const baseURL = "http://13.127.115.121:7002/";
export const baseURLassess = "http://13.127.115.121:8001/";

// // baseUrl.js
// export const baseURL =
//   "https://q7n88rwv8b.execute-api.ap-south-1.amazonaws.com/dev/";

// // export const baseURLassess =
// //   "https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest/";

export const Pguidid = "part-f3bddd69-a1da-1f0d-87d2-104f91cfa267";

export let Pguid = localStorage.getItem("guid");
export let Iguid = localStorage.getItem("guid");
//log_debug("axios Iguid",localStorage.getItem('guid'))
export const Jguid = "jbd-6fd427cb-6afa-1144-b09c-55cbe8eb9554";
export let ClientGuid = localStorage.getItem("guid");
//export const ClientGuid = "cli-a9231095-3ed8-1070-9369-48d29fd0530d";

// export const baseURLs = {
//   identity: "http://13.127.115.121:8001/",
//   api: "http://13.127.115.121:7002",

//   // identity: "https://qoyj2nxyal.execute-api.ap-south-1.amazonaws.com/latest",
//   // entity: "https://l08nloaza1.execute-api.ap-south-1.amazonaws.com/latest",
//   // assessment: "https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest",
//   // crawler: "https://l08nloaza1.execute-api.ap-south-1.amazonaws.com/latest",
//   // resumeparsing: "https://files.talentiq.app",
//   // webapp: "https://me.talentiq.app/",
// };

export const common = {
  app_version: "0.0.1",
  service_ref: serviceRef,
  guid_format: "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
  short_guid_format: "xxxxxxxx",
  log_enabled: window.location.hostname.includes("companybench.com")
    ? false
    : true,
};

export { baseURL };

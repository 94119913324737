import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
  Button,
} from "reactstrap";

import CustomTable from "../customComponents/reactTable/CustomTable";
import {
  getPartnerIndividualApprovedTimesheet,
  getPartnerIndividualContracts,
} from "./PartnerIndividualSlice/PartnerIndividualSlice";
import { log_debug } from "../apiHelpers/logger";
import {
  dateyyyymmdd_tohtml,
  getDatesOfWeek,
  getFirstAndLastDayOfWeek,
} from "../helpers/date";
import { Link } from "react-router-dom";
import StatusBadge from "../customComponents/utilities/StatusBadge";
import ContractDetailCanvas from "../Partner/pagesComponents/ContractDetailCanvas";
import DisplayDates from "./Components/DisplayDates";
import Timesheetcomponent from "../customComponents/timesheet/timesheet";
import RejectedTimesheetcomponent from "../customComponents/timesheet/rejected_timesheet";
import TimesheetCanvas from "../customComponents/offcanvas/EditTimesheetCanvas";
// import ContractDetailCanvas from "../customComponents/offcanvas/ContractDetailCanvas";

function PartnerIndividualRejectedTimesheets() {
  const dispatch = useDispatch();
  const partnerindividualstore = useSelector(
    (state) => state.partnerindividualslice,
  );

  const [ApprovedData, setApprovedData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [contractinfo, setContractinfo] = useState({});
  const [selectedTimesheet, setSelectedTimesheet] = useState([]);
  const [Firstday, setFirstday] = useState("");
  const [Lastday, setLastday] = useState("");

  const handleContract = (row) => {
    setContractinfo(row);
    setIsOpen(true);
  };

  const closeOffcanvas = () => {
    setIsOpen(false);
  };

  const handleRejectedTimesheet = (data) => {
    const weekdetails = getDatesOfWeek(data.week, data.year);
    const { firstDay, lastDay } = getFirstAndLastDayOfWeek(weekdetails);
    log_debug("firstday", firstDay);
    setFirstday(firstDay);
    setLastday(lastDay);
    setSelectedTimesheet(data);
    setIsOpen(true);
  };

  const columns = [
    {
      Header: "Timesheet Period",
      accessor: "",
      Cell: ({ row }) => <DisplayDates row={row.original} />,
    },
    {
      Header: "Date Submitted",
      accessor: "createdAt",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Billable",
      accessor: "",
    },
    {
      Header: "Non-Billable",
      accessor: "",
      //Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Total",
      accessor: "",
      //Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex gap-1">
          {/* <p className="font-size-12 m-0"></p> */}
          <Badge color="danger" className="rounded-pill">
            {row.values.status}
          </Badge>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "",
      Cell: ({ row }) => (
        <>
          <Link to={"#"} className="text-white">
            <Button
              color="primary"
              className="p-0 px-2 py-1"
              // color={appliedRows.includes(row.id) ? "success" : "primary"}
              // disabled={appliedRows.includes(row.id)}
              onClick={() => handleRejectedTimesheet(row.original)}
            >
              {/* <i className="mdi mdi-email-send mx-1 btn-lg"></i> */}
              Edit
            </Button>
          </Link>
        </>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    try {
      dispatch(getPartnerIndividualApprovedTimesheet("rejected")).then(
        (res) => {
          log_debug("approved timesheet data", res.payload);
          if (res.payload.success === true) {
            setIsLoading(false);
            const data = res.payload.data?.timesheets;
            setApprovedData(data);
          } else if (res.payload.success === false) {
            setIsLoading(false);
            setApprovedData([]);
          }
        },
      );
    } catch (error) {
      log_debug("Error loading data", error);
    }

    //log_debug("984735jkhf",partnerindividualstore)
  }, []);

  //  useEffect(() => {
  //    if(partnerindividualstore.status === "idle" && partnerindividualstore.data.partnerindividualapprovedtimesheet?.success === true){
  //      //log_debug("partnerindividualstore rejected data",partnerindividualstore.data.partnerindividualapprovedtimesheet);
  //      setApprovedData(partnerindividualstore.data.partnerindividualapprovedtimesheet?.data?.timesheets);
  //      setIsLoading(false);
  //    }else if(partnerindividualstore.status === "loading"){
  //     setIsLoading(true);
  //    }
  //  },[partnerindividualstore.data.partnerindividualapprovedtimesheet])

  const handlePageChange = (newPage) => {
    setcurrentPage(newPage);
  };

  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <CustomTable
                    tableDatas={ApprovedData}
                    columns={columns}
                    data={ApprovedData}
                    hideCheckbox={true}
                    totalCount={totalCount}
                    isLoading={isLoading}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOpen && (
        <TimesheetCanvas
          width={70}
          placement="end"
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          onclose={closeOffcanvas}
          data={selectedTimesheet}
          monday={Firstday}
        />
      )}
    </React.Fragment>
  );
}

export default PartnerIndividualRejectedTimesheets;

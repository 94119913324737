import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Col,
  Row,
  Button,
  Form,
  Label,
  Input,
} from "reactstrap";
import {
  postEditJobDescription,
  getLocations,
  getSkills,
  ClientAllJobs,
  getDashboardMyJobs,
} from "../../../Client/ClientSlice";

const CanvasJobDescEdit = ({
  isOpen,
  toggle,
  onClose,
  data,
  onSubmit,
  rowIndex,
}) => {
  const dispatch = useDispatch();
  const StoreCityCountry = useSelector((state) => state.clientSlice);

  const { ...restData } = data;
  const [validationErrors, setValidationErrors] = useState({});
  const [allIndianLocations, setAllIndianLocations] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [jobDescription, setJobDescription] = useState({
    ...restData,
    ...data,
  });

  useEffect(() => {
    setEditedData(data);
    if (data?.skills) {
      let tempSkill = data?.skills.map((skill) => ({
        label: skill,
        value: skill,
      }));
      setSelectedSkills(tempSkill);
    }
    if (data?.location) {
      let tempLoc = data?.location.map((locs) => ({
        label: locs,
        value: locs,
      }));

      setSelectedLocations(tempLoc);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getSkills());
  }, []);
  useEffect(() => {
    if (StoreCityCountry.status === "idle") {
      if (StoreCityCountry?.data?.locations?.success === true) {
        const allIndianCities =
          StoreCityCountry?.data?.locations?.data?.enumcitys
            .filter((data) => data.country === "India")
            .map((locs) => ({
              label: locs.title,
              value: locs.title,
            }));
        setAllIndianLocations(allIndianCities);

        // setAllCitiesOfCountry(
        //   StoreCityCountry?.data?.countryCities?.data?.enumcitys,
        // );
      }
      if (StoreCityCountry.data.skills?.success === true) {
        // setAllSkills(StoreCityCountry.data.skills?.data?.enumskills);
        const skillOptions = StoreCityCountry.data.skills.data.enumskills.map(
          (skill) => ({
            label: skill.title, // Adjust 'name' if the actual property is different
            value: skill.title, // Adjust 'name' if the actual property is different
          }),
        );
        setAllSkills(skillOptions);
      }
    }
  }, [StoreCityCountry]);

  const handleSkills = (skill) => {
    let editData = { ...editedData };
    editData = { ...editData, skills: skill.map((sk) => sk.label) };
    setEditedData(editData);
    setSelectedSkills(skill);
  };
  const handleLocations = (loc) => {
    let editData = { ...editedData };
    editData = { ...editData, location: loc.map((sk) => sk.label) };
    setEditedData(editData);
    setSelectedLocations(loc);
  };

  const offcanvasStyle = {
    width: " 50%",
    // zoom: "80%",
  };

  const handleInputChange = (field, value1) => {
    // Validation checks
    let updatedErrors = { ...validationErrors };
    let currentErrors = {};

    switch (field) {
      case "title":
        currentErrors[field] = validator.isEmpty(value1)
          ? "title is required"
          : "";
        break;

      case "summary":
        currentErrors[field] = validator.isEmpty(value1)
          ? "summary is required"
          : "";
        break;
      case "description":
        currentErrors[field] = validator.isEmpty(value1)
          ? "description is required"
          : "";

      default:
        break;
    }
    setValidationErrors(currentErrors);

    setJobDescription({
      ...jobDescription,
      [field]: value1,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { ...restProfileData } = data;
    // Validation before submission
    let errors = {};
    for (const key in data) {
      if (jobDescription.hasOwnProperty(key)) {
        const value = jobDescription[key];
        if (key === "title") {
          errors[key] = validator.isEmpty(value) ? `Job Title is required` : "";
        } else if (key === "contact_person") {
          errors[key] = validator.isEmpty(value)
            ? `contact person is required`
            : "";
        } else if (key === "summary") {
          errors[key] = validator.isEmpty(value)
            ? "summary is required"
            : !validator.isLength(value, { min: 0, max: 1000 })
              ? `Character Length Exceeded`
              : "";
        } else if (key === "description") {
          errors[key] = validator.isEmpty(value)
            ? "description is required"
            : !validator.isLength(value, { min: 0, max: 1000 })
              ? `Character Length Exceeded`
              : "";
        }
        if (selectedLocations.length === 0) {
          errors["locations"] = "Please select at least one location";
        }
        // Validate skills separately since it's not a direct property of jobDescription
        if (selectedSkills.length === 0) {
          errors["skills"] = "Please select at least one skill";
        }
        //  else if (key === "skills") {
        //   // Assuming 'value' is the array of selected skills
        //   errors[key] =
        //     Array.isArray(value) && value.length === 0
        //       ? "Please select at least one skill"
        //       : "";
        // }
        // else if (key === "locations") {
        //   // Assuming 'value' is the array of selected skills
        //   errors[key] =
        //     Array.isArray(value) && value.length === 0
        //       ? "Please select at least one location"
        //       : "";
        // }
      }
    }

    setValidationErrors(errors);

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (hasErrors) {
      return;
    }

    // Create the updated job des object with the updated address
    const jobdescriptions = {
      job_guid: { guid: data.guid },
      job_description: {
        jobdescription: {
          ...data,
          title: jobDescription.title,
          description: jobDescription.description,
          location: selectedLocations.map((locs) => locs.value),
          summary: jobDescription.summary,
          skills: selectedSkills.map((skill) => skill.value),
        },
      },
    };
    // },
    // };
    // dispatch(
    //   ClientAllJobs({
    //     limit: 100,
    //     offset: 0,
    //   }),
    // );

    dispatch(postEditJobDescription(jobdescriptions))
      .then((data) => {
        // Close the canvas after dispatching
        toggle();
        onSubmit(jobdescriptions, rowIndex);
      })
      .catch((error) => {
        // Handle error if needed
      });

    dispatch(
      getDashboardMyJobs({
        startDate: moment(new Date().setDate(new Date().getDate() - 90)).format(
          "YYYY-MM-DD",
        ),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        currentPage: 0,
      }),
    );
  };
  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          <h5 className="text-primary">
            Edit Job Description &nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Present comprehensive
              details.
            </span>
          </h5>
        </OffcanvasHeader>

        <OffcanvasBody>
          <Row>
            <Col xl={12}>
              <div>
                <Form onSubmit={handleSubmit}>
                  {/* <Form > */}
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="company_name"
                    >
                      Title <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="title"
                        value={jobDescription.title}
                        onChange={(e) =>
                          handleInputChange("title", e.target.value)
                        }
                      />
                      {validationErrors.title && (
                        <small className="text-danger">
                          {validationErrors.title}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="date_of_birth"
                    >
                      Posted on <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="date"
                        name="createdAt"
                        value={moment(jobDescription.createdAt).format(
                          "YYYY-MM-DD",
                        )}
                        onChange={(e) =>
                          handleInputChange("createdAt", e.target.value)
                        }
                      />
                      {validationErrors.createdAt && (
                        <small className="text-danger">
                          {validationErrors.createdAt}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label className="col-sm-3 col-form-label" for="email_id">
                      Skills <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Select
                        className="basic-multi-select"
                        name="skills"
                        placeholder="Select Skills"
                        options={allSkills}
                        onChange={handleSkills}
                        value={selectedSkills}
                        isMulti
                        isSearchable
                        isOptionDisabled={() => selectedSkills.length > 6}
                        // components={makeAnimated()}
                        // value={editedData?.skills}
                      ></Select>
                      {validationErrors.skills && (
                        <small className="text-danger">
                          {validationErrors.skills}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label className="col-sm-3 col-form-label" for="linkedIn">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="textarea"
                        name="description"
                        value={jobDescription.description}
                        onChange={(e) =>
                          handleInputChange("description", e.target.value)
                        }
                      />
                      {validationErrors.linkedIn && (
                        <small className="text-danger">
                          {validationErrors.description}
                        </small>
                      )}
                    </Col>
                  </Row>
                  {/* <Row className="mb-3">
                    <Label className="col-sm-3 col-form-label" for="website">
                      Requirements
                    </Label>
                                      <Col sm={9}>
                      {data.requirements.map((requirement, index) => (
                        <Input
                          key={index}
                          type="text"
                          name={`requirement_${index}`}
                          value={requirement}
                          readOnly // Make the input read-only
                          style={{ marginBottom: '5px' }} // Add some margin between lines
                        />
                      ))}
                    </Col>
                  </Row> */}

                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="contact_person"
                    >
                      Location <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      {/* <Input
                        type="select"
                        name="location"
                        value={jobDescription.location}
                        onChange={(e) =>
                          handleInputChange("location", e.target.value)
                        }
                      >
                        <option value="" hidden>Select location</option>
                        {allIndianLocations.map((city) => (
                          <option key={city.title} value={city.title}>
                            {city.title}
                          </option>
                        ))}
                      </Input>
                      {validationErrors.location && (
                        <small className="text-danger">
                          {validationErrors.location}
                        </small>
                      )} */}
                      <Select
                        className="basic-multi-select"
                        name="locations"
                        placeholder="Select Locations"
                        options={allIndianLocations}
                        onChange={handleLocations}
                        value={selectedLocations}
                        isMulti
                        isSearchable
                        isOptionDisabled={() => selectedLocations.length > 6}
                        // components={makeAnimated()}
                        // value={editedData?.skills}
                      ></Select>
                      {validationErrors.locations && (
                        <small className="text-danger">
                          {validationErrors.locations}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="college_or_university_name"
                    >
                      Summary <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="textarea"
                        name="summary"
                        value={data.summary}
                        onChange={(e) =>
                          handleInputChange("summary", e.target.value)
                        }
                      />
                      {validationErrors.summary && (
                        <small className="text-danger">
                          {validationErrors.summary}
                        </small>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button type="submit" color="primary">
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default CanvasJobDescEdit;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
} from "reactstrap";

import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import { lineStyle, dropdownOptions, columns, jobs } from "../data/Data";
import DropDownHeader from "../customComponents/DropDownHeader";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { Link } from "react-router-dom";
import {
  getIndividualInvited,
  getIndividualJobRoles,
  getIndividualLocations,
  getJobsCount,
} from "./individualSlice";
import dayjs from "dayjs";
import AdvanceFilter from "../customComponents/advance filter/AdvanceFilter";
import CanvasJobDescription from "../customComponents/offcanvas/CanvasJobdescription";
import Preloader from "../components/Common/Preloader";
import { log_debug } from "../apiHelpers/logger";
import StatusBadge from "../customComponents/utilities/StatusBadge";
import moment from "moment";

function IndividualInvitations() {
  const individualStore = useSelector((state) => state.individualSlice);
  const jobstatus = "invited";
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const dispatch = useDispatch();
  const [invitedJob, setInvitedJob] = useState([]);
  const [LocationOptions, setLocationOptions] = useState([]);
  const [JobRoleOptions, setJobRoleOptions] = useState([]);
  const [isPreloader, setisPreloader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [RecordCount, setRecordCount] = useState(0);
  const [filtervalue, setFiltervalue] = useState("");
  const [TableLoading, setTableLoading] = useState(false);
  const defaultStartDate = new Date();

  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getJobsCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  const yourDynamicHeaderData = {
    title: "Title",
    location: "Location",
    invited_date: "Invitation Date",
    status: "Status",
  };

  const JobName = (job) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedJob(job);
    setOffcanvasOpen(true);
  };

  const columns = [
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            JobName(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ value }) => (value.length === 0 ? "-" : value.join(", ")),
    },
    {
      Header: "Invitation Date",
      accessor: "invited_date",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",

      Cell: ({ value }) => (
        <StatusBadge status={value} />
        // <Badge
        //   color={
        //     value === "invited" || value === "active"
        //       ? "success rounded-pill "
        //       : "warning rounded-pill "
        //   }
        //   className="m-1 font-size-12"
        // >
        //   {value}
        // </Badge>
      ),
    },
    // {
    //   Header: "Actions",
    //   accessor: "",
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  // useEffect(() => {
  //   dispatch(
  //     getIndividualInvited({
  //       jobstatus: jobstatus,
  //       limit: 100,
  //       offset: currentPage,
  //     }),
  //   );
  // }, []);

  // useEffect(() => {
  //   dispatch(
  //     getIndividualInvited({
  //       jobstatus: jobstatus,
  //       limit: 100,
  //       offset: currentPage,
  //       skills:"",
  //       locations:""
  //     }),
  //   );
  // }, []);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.invitedData?.success === true) {
        setInvitedJob(individualStore.data.invitedData?.data?.jobDescriptions);
        setRecordCount(individualStore.data.invitedData?.data?.totalCount);
        setisPreloader(false);
        setTableLoading(false);
        log_debug("invited jobs", invitedJob);
      } else if (individualStore.status === "loading") {
        setisPreloader(true);
        setTableLoading(true);
      }
    }
  }, [individualStore.data.invitedData]);

  const [Location, setLocation] = useState([]);
  const [JobRole, setJobRole] = useState([]);

  const handleFilterChange = (option) => {
    if (option && option.location) {
      const locationLabels = option.location.map((location) => location.label);
      setLocation(locationLabels);
    }

    if (option && option.jobRole) {
      const jobRoleLabels = option.jobRole.map((jobRole) => jobRole.label);
      setJobRole(jobRoleLabels);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        getIndividualInvited({
          jobstatus: jobstatus,
          //limit: 100,
          offset: currentPage,
          skills: JobRole,
          locations: Location,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [currentPage, Location, JobRole, filtervalue]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setTableLoading(true);
    setFiltervalue(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Invitations"
            Name="Invitations"
          />
          {isPreloader && <Preloader status={true} />}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <Card
                    className="border-light bg-light pt-3"
                  > */}
                  <CardBody>
                    <Row>
                      <AdvanceFilter onFilterChange={handleFilterChange} />
                      <div className="col-12 col-lg-6 col-md-6">
                        <Col md={12}>
                          <CardTitle className="text-end fs-6 pt-3">
                            Total Jobs : {RecordCount}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                  {/* </Card> */}
                </div>
                <div>
                  <Card>
                    <CardBody>
                      <CustomTable
                        tableDatas={invitedJob}
                        headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={invitedJob}
                        hideCheckbox={true}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        isLoading={TableLoading}
                        ShowPagination={
                          invitedJob && invitedJob.length > 10 ? true : false
                        }
                        totalCount={RecordCount}
                        onChange={handleValueChange}
                        globalsearch={true}
                      />
                    </CardBody>
                  </Card>
                  {/* <DataTable title="All Jobs" columns={columns} data={jobs} /> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <CanvasJobDescription
          usertype="individual"
          menuOption="invitation"
          width={60}
          placement="end"
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onclose={closeOffcanvas}
          data={selectedJob}
        />
      )}
    </React.Fragment>
  );
}

export default IndividualInvitations;

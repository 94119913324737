import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getIndividualAssessments,
  getJobRoles,
  postAssessmentInvite,
} from "../../individual/individualSlice";
import { log_debug } from "../../apiHelpers/logger";
import { AuthContext } from "../../App";
import { titles } from "../../data/cb_title";
import { toast } from "react-toastify";
import Preloader from "../../components/Common/Preloader";

const AssessmentInviteForm = ({ userType }) => {
  const { assessment_form_title, assessment_form_sub_title } =
    titles.en[userType];

  const [isRoleSelected, setIsRoleSelected] = useState(false); // New state variable4
  const { userData } = useContext(AuthContext);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      full_Name: userData && userData.full_name,
      email_id: userData && userData.email_id,
      role: "",
    },
    validationSchema: Yup.object({
      full_Name: Yup.string().required("Full Name is required"),
      email_id: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      role: Yup.string().required("Role is required"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      // Handle form submission logic here
      log_debug("Form submitted:", values);
      dispatch(postAssessmentInvite({ formdata: values })).then((response) => {
        if (response.payload?.success === true) {
          setDisabled(false);
          toast.success(response.payload?.data?.message);
          dispatch(getIndividualAssessments());
        } else if (response.payload?.success === false) {
          setDisabled(false);
          toast.error(`Error: ${response.payload?.error?.message}`);
        }
      });
    },
  });

  const individualStore = useSelector((state) => state.individualSlice);

  const [JobRoleOptions, setJobRoleOptions] = useState([]);

  useEffect(() => {
    dispatch(getJobRoles());
  }, []);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.jobroles?.success === true) {
        setJobRoleOptions(individualStore.data.jobroles?.data?.enumjobroles);
        log_debug("JobRole data:", JobRoleOptions);
      }
    }
  }, [individualStore]);

  useEffect(() => {
    setIsRoleSelected(!!formik.values.role);
  }, [formik.values.role]);
  return (
    <>
      <h5 className="font-size-14 text-primary">
        {assessment_form_title}&nbsp;&nbsp;
        <span className="font-size-12 text-muted">
          {" "}
          <i className="fas fa-info-circle" />
          {assessment_form_sub_title}
        </span>
      </h5>
      {disabled && <Preloader />}
      <form
        className="border border-2 px-5 py-5"
        onSubmit={(e) => {
          e.preventDefault();
          // Check if the role is selected before submitting the form
          formik.handleSubmit(e);
        }}
      >
        <div className="row mb-2">
          <label
            htmlFor="horizontal-firstname-input"
            className="col-sm-3 col-form-label font-size-14"
          >
            Full Name
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              className="form-control bg-light"
              id="horizontal-firstname-input"
              placeholder="Enter your First Name"
              value={userData && userData.full_name}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <label
            htmlFor="horizontal-email-input"
            className="col-sm-3 col-form-label"
          >
            Email
          </label>
          <div className="col-sm-6">
            <input
              type="email"
              className="form-control bg-light"
              id="horizontal-email-input"
              placeholder="Enter your Email"
              value={userData && userData.email_id}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <label
            htmlFor="horizontal-assessment-role-input"
            className="col-sm-3 col-form-label font-size-14"
          >
            Select Role
          </label>
          <div className="col-sm-6">
            <select
              className={`form-select ${
                formik.touched.role && formik.errors.role ? "is-invalid" : ""
              }`}
              id="role"
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
              required
            >
              <option value="" hidden>
                Select Role
              </option>
              {JobRoleOptions.map((option, index) => (
                <option key={index} value={option.title}>
                  {option.title}
                </option>
              ))}
              {/* Add more roles as needed */}
            </select>
          </div>
        </div>

        <div className="row justify-content-start">
          <div className="col-sm-9">
            <div>
              <button
                type="submit"
                className={`btn btn-primary btn-sm ${
                  isRoleSelected ? "" : "disabled"
                }`}
                disabled={!isRoleSelected || disabled}
              >
                Submit <i className="mdi mdi-arrow-right ms-1"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AssessmentInviteForm;

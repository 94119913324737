import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardHeader,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Badge,
  Spinner,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "./canvas.css";
import Assessment from "../../individual/Components/AssessmentForm";

// Custom Components
import AssessmentInviteForm from "../form/AssessmentInviteForm";
import TIQcard from "../offcanvas/offcanvasComponents/TIQcard";
import CustomTable from "../reactTable/CustomTable";
import { shortlistedjobscolumnsdata } from "../../data/Data";
import {
  alljobscolumnsdata,
  alljobsheaders,
  interviewsheaders,
  interviewscolumnsdata,
} from "../../data/TableData";
import briefcase from "../../assets/images/companyBenchImage/jobgray1.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  JobHistory,
  getJobsCount,
  getMatchingJobs,
  postIndividualMultipleJobApply,
} from "../../individual/individualSlice";
import { Tags } from "../../pages/Contacts/ContactList/contactlistCol";
import InvitedCandidatesTable from "../../Client/Components/Canvas Tables/InvitedCandidates";
import ShortlistedCandidates from "../../Client/Components/Canvas Tables/ShortlistedCandidates";
import MatchingCandidates from "../../Client/Components/Canvas Tables/MatchingCandidates";
import JobOverview from "./JobOverviewCanvas";
import JobOverviewTab from "./offcanvasComponents/JobOverview";
import TimelineComponent from "../timelineComponents/TimeLineComponent";
import Preloader from "../../components/Common/Preloader";
import { log_debug } from "../../apiHelpers/logger";
import JobTimelineComponent from "./offcanvasComponents/JobHistoryTimeline";
import { titles } from "../../data/cb_title";
import {
  getInvitedCandidates,
  getJobMatchingCandidates,
  getShortlistedCandidates,
} from "../../Client/ClientSlice";
import { Iguid } from "../../apiConfig/axiosDev";
import { getIndividualGuid } from "../../apiConfig/globalvariables";
import { toast } from "react-toastify";
import ClientOffers from "../../Client/ClientOffers";
import StatusBadge from "../utilities/StatusBadge";
import moment from "moment";

function CanvasJobDescription(props) {
  const [displayName, setName] = useState("");
  const dispatch = useDispatch();
  const individualstore = useSelector((state) => state.individualSlice);
  const clientStore = useSelector((state) => state.clientSlice);
  const [candidatureHistory, setCandidatureHistory] = useState([]);

  const [headerTab, setHeaderTab] = useState("1");
  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  const offcanvasStyle = {
    width: `${props.width}%`,
    // zoom: "80%",
    padding: "15px",
  };

  //State variables to display the buttons and cards according to persona
  const [ShowApply, setShowApply] = useState(false);
  const [ShowTiqCard, setShowTiqCard] = useState(false);
  const [ShowDownloadJd, setShowDownloadJd] = useState(false);
  const [ShowGenerateResume, setShowGenerateResume] = useState(false);
  const [ShowAssessmentTab, setShowAssessmentTab] = useState(false);
  const [ShowMatchingJobsTab, setShowMatchingJobsTab] = useState(false);

  //client buttons and tabs
  const [ShowMatchingCandidatesTab, setShowMatchingCandidatesTab] =
    useState(false);

  const [ShowPostJob, setShowPostJob] = useState(false);
  const [ShowOverview, setShowOverview] = useState(false);
  const [ShowInterviewTab, setShowInterviewTab] = useState(false);
  const [ShowInvitedCandidatesTab, setShowInvitedCandidatesTab] =
    useState(false);
  const [ShowShortlistedCandidatesTab, setShowShortlistedCandidatesTab] =
    useState(false);
  const [ShowOfferReleased, setShowOfferReleased] = useState(false);
  const [ShowClientMatchingCandidates, setShowClientMatchingCandidatesTab] =
    useState(false);

  const [showJobHistory, setshowJobHistory] = useState(false);
  const [loading, setloading] = useState(false);
  const [isApplied, setisApplied] = useState(false);
  const defaultStartDate = new Date();

  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  const data = props.data;
  const isOpen = props.isOpen;
  const toggle = props.toggle;
  const onClose = props.onClose;
  const usertype = props.usertype;
  const menuOption = props.menuOption;

  const { matching_job_sub_title } = titles.en[props.usertype];

  //   conditions to change the tiq jobrole name bases on scenario
  useEffect(() => {
    if (usertype === "individual") {
      if (menuOption === "appliedjobs") {
        setShowOverview(true);
        //setShowDownloadJd(true);
        //setShowGenerateResume(true);
        //setShowTiqCard(true);
        setShowAssessmentTab(true);
        //setShowMatchingJobsTab(true);
        setshowJobHistory(true);
      }
      if (menuOption === "invitation") {
        setShowOverview(true);
        //setShowDownloadJd(true);
        //setShowGenerateResume(true);
        //setShowTiqCard(true);
        setShowAssessmentTab(true);
        //setShowMatchingJobsTab(true);
        setshowJobHistory(true);
      }
      if (menuOption === "jobs") {
        setShowOverview(true);
        //setShowApply(true);
        //setShowDownloadJd(true);
        //setShowGenerateResume(true);
        //setShowTiqCard(true);
        setShowAssessmentTab(true);
        //setShowMatchingJobsTab(true);
        setshowJobHistory(true);
      }
      if (menuOption === "matchingjobs") {
        setShowOverview(true);
        setShowApply(true);
        //setShowDownloadJd(true);
        //setShowGenerateResume(true);
        //setShowTiqCard(true);
        setShowAssessmentTab(true);
        //setShowMatchingJobsTab(true);
        setshowJobHistory(true);
      }
    }

    if (usertype === "partner") {
      setShowMatchingCandidatesTab(true);
    }

    if (usertype === "client") {
      if (menuOption === "jobs") {
        setShowOverview(true);
        //setShowInterviewTab(true);
        setShowInvitedCandidatesTab(true);
        setShowShortlistedCandidatesTab(true);
        setShowOfferReleased(true);
        setShowClientMatchingCandidatesTab(true);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(JobHistory(data.guid));
  }, [data]);

  const [matchingjobsdata, setmatchingjobsdata] = useState([]);
  // useEffect(() => {
  //   dispatch(getMatchingJobs());
  // },[])

  useEffect(() => {
    if (individualstore.status === "idle") {
      if (individualstore.data.JobHistory?.success === true) {
        setCandidatureHistory(
          individualstore.data.JobHistory?.data?.jobdescriptionhistory,
        );
        setloading(false);
      }
    } else if (individualstore.status === "loading") {
      setloading(true);
    }
  }, [individualstore.data.JobHistory]);

  useEffect(() => {
    log_debug("Job History", candidatureHistory);
  }, [candidatureHistory]);

  const commonheaders = [];

  const [isOverviewCanvasOpen, setOverviewCanvas] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const jobdescription = (job) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedJob(job);
    setOverviewCanvas(true);
  };

  const closeOverviewCanvas = () => {
    setOverviewCanvas(false);
  };

  const columnsdata = [
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            jobdescription(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Required Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: (props) =>
      //   const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
      //   return <span>{tidyDate}</span>;
      // },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <Badge
          color={
            row.values.status === "active"
              ? "success rounded-pill "
              : "danger  rounded-pill "
          }
        >
          {row.values.status}
        </Badge>
      ),
    },
    {
      Header: "Actions",
      accessor: "",
      Cell: ({ row }) => (
        <>
          <Link to={"#"} className="text-white">
            <Button
              color="primary"
              className="p-1"
              // color={appliedRows.includes(row.id) ? "success" : "primary"}
              // disabled={appliedRows.includes(row.id)}
              // onClick={() => handleButtonClick(row)}
            >
              Apply
              {/* {appliedRows.includes(row.id) ? "Applied" : "Apply"} */}
            </Button>
          </Link>
        </>
      ),
    },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
  ];

  //parameters for client Tables
  const [ClientInvitedCandidates, setClientInvitedCandidates] = useState([]);
  const [ClientShortlistedCandidates, setClientShortlistedCandidates] =
    useState([]);
  const [ClientMatchingCandidates, setClientMatchingCandidates] = useState([]);
  const [InvitedCount, setInvitedCount] = useState(0);
  const [ShortlistedCount, setShortlistedCount] = useState(0);
  const [MatchingCandidatesCount, setMatchingCandidatesCount] = useState(0);
  const [InvitedCandidatescurrentpage, setInvitedCandidatescurrentpage] =
    useState(0);
  const [
    ShortlistedCandidatescurrentpage,
    setShortlistedCandidatescurrentpage,
  ] = useState(0);
  const [MatchingCandidatescurrentpage, setMatchingCandidatescurrentpage] =
    useState(0);

  //Client Api Calls for table
  useEffect(() => {
    if (usertype === "client") {
      dispatch(
        getInvitedCandidates({
          guid: data.guid,
          offset: InvitedCandidatescurrentpage,
        }),
      );
    }
  }, [InvitedCandidatescurrentpage]);

  useEffect(() => {
    if (usertype === "client") {
      dispatch(
        getShortlistedCandidates({
          guid: data.guid,
          offset: ShortlistedCandidatescurrentpage,
        }),
      );
    }
  }, [ShortlistedCandidatescurrentpage]);

  useEffect(() => {
    if (usertype === "client") {
      dispatch(
        getJobMatchingCandidates({
          guid: data.guid,
          offset: MatchingCandidatescurrentpage,
        }),
      );
    }
  }, [MatchingCandidatescurrentpage]);

  useEffect(() => {
    if (clientStore.status === "idle") {
      setloading(false);
      if (clientStore.data.invitedcandidates?.success === true) {
        setClientInvitedCandidates(
          clientStore.data.invitedcandidates?.data?.individuals,
        );
        setInvitedCount(clientStore.data.invitedcandidates?.data?.totalCount);
      }
      if (clientStore.data.shortlistedcandidates?.success === true) {
        setClientShortlistedCandidates(
          clientStore.data.shortlistedcandidates?.data?.individuals,
        );
        setShortlistedCount(
          clientStore.data.shortlistedcandidates?.data?.totalCount,
        );
      }
      if (clientStore.data.JobMatchingCandidates?.success === true) {
        setClientMatchingCandidates(
          clientStore.data.JobMatchingCandidates?.data?.individuals,
        );
        setMatchingCandidatesCount(
          clientStore.data.JobMatchingCandidates?.data?.totalCount,
        );
      } else if (clientStore.status === loading) {
        setloading(true);
      }
    }
  }, [
    clientStore,
    clientStore.data.invitedcandidates,
    clientStore.data.shortlistedcandidates,
    clientStore.data.JobMatchingCandidates,
  ]);

  const handleIndividualJobApply = (guid) => {
    setloading(true);
    log_debug("recieved guid", guid);
    dispatch(
      postIndividualMultipleJobApply({
        individualjobmapping: {
          individual_guid: [`${getIndividualGuid() || Iguid}`],
          jobdescription_guid: [guid],
        },
      }),
    ).then((response) => {
      if (response.payload?.success === true) {
        setloading(false);
        setisApplied(true);
        const data = response.payload.data;
        if (data.length != 0) {
          toast.success("Successfully Applied Selected Job", {
            position: "top-right",
            autoClose: 4000, // Close the toast after 5 seconds
            // hideProgressBar: true,
          });
          dispatch(
            getMatchingJobs({
              offset: 0,
              skills: "",
              locations: "",
              filtervalue: "",
            }),
          );
          dispatch(
            getJobsCount({
              fromDate: formattedStartDate,
              toDate: formattedEndDate,
            }),
          );
        } else if (data.length === 0) {
          setloading(false);
          toast.warning("Unable to Apply for the Job", {
            position: "top-right",
            autoClose: 4000, // Close the toast after 5 seconds`
            // hideProgressBar: true,
          });
        }
      }
    });
  };

  const handleInvitedPageChange = (newPage) => {
    setInvitedCandidatescurrentpage(newPage);
  };

  const handleShortlistedPageChange = (newPage) => {
    setShortlistedCandidatescurrentpage(newPage);
  };

  const handleMatchingCandidatesPageChange = (newPage) => {
    setMatchingCandidatescurrentpage(newPage);
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          {loading && <Preloader />}
          {/* <p className="text-light">Candidate Details</p> */}
          <h5 className="text-primary">
            Job Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Job details displayed.
              Relevant information for selected position.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* TIQ score card to display based on scenario */}
          {ShowTiqCard ? (
            <div className="row pt-2 ">
              <div className="row">
                <div className="col-md-6 card">
                  <div className="d-flex">
                    <div className="flex-grow-1 my-2">
                      <h5 className="font-size-16 mb-1">
                        {data.title} <StatusBadge status={data.status} />
                        {/* <Badge className="bg-success p-1  rounded-pill m-1 font-size-12 float-end">
                          {data.status}
                        </Badge> */}
                      </h5>
                      <h5>
                        <small className="text-muted font-size-12">
                          {data.location}
                        </small>
                      </h5>
                      {data.skills.map((skill, index) => (
                        <Badge
                          key={index}
                          color=""
                          className="bg-primary-subtle text-primary rounded-pill text-opacity-75 m-1 font-size-12"
                        >
                          {skill}
                        </Badge>
                      ))}
                      <p className="mt-2 font-size-11 text-muted">
                        {data.description}
                      </p>
                      <div className="mt-2">
                        {ShowApply && (
                          <Link to="#" className="btn btn-primary btn-sm m-1">
                            Apply <i className=" fas fa-paper-plane ms-1"></i>
                          </Link>
                        )}

                        {ShowDownloadJd && (
                          <Link to="#" className="btn btn-primary btn-sm m-1">
                            Download JD <i className="fas fa-download ms-1"></i>
                          </Link>
                        )}

                        {ShowGenerateResume && (
                          <Link to="#" className="btn btn-secondary btn-sm m-1">
                            Generate Resume
                            <i className="far fa-file-alt ms-1"></i>
                          </Link>
                        )}

                        {ShowPostJob && (
                          <Link to="#" className="btn btn-primary btn-sm m-1">
                            Post Job
                            <i className="far fa-file-alt ms-1"></i>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* tiq card component to display the tiq score and job title */}
                <div className="col-md-6">
                  {(usertype === "individual" ||
                    (usertype === "partner" && usertype === "mycandidate")) && (
                    // <TIQcard
                    //   jobname={data.title}
                    //   score={data.score}
                    //   menuOption={menuOption}
                    // />
                    <AssessmentInviteForm />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Row>
              <Card>
                <div className="col-sm order-2 order-sm-1 p-2">
                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                    <div className="flex-shrink-0">
                      <div className="avatar-xl me-3">
                        <img
                          src={briefcase}
                          alt=""
                          className="img-fluid rounded-circle d-block "
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <div className="col-sm-auto order-1 order-sm-2 float-end">
                        <StatusBadge status={data.status} />
                        {/* <Badge
                          color={
                            data &&
                            (data.status === "active" ||
                              data.status === "selected" ||
                              data.status === "invited")
                              ? "success rounded-pill"
                              : data.status === "applied"
                                ? "warning rounded-pill"
                                : "danger rounded-pill"
                          }
                          className="p-1 m-1 font-size-12 float-end"
                        >
                          {data.status}
                        </Badge> */}
                      </div>
                      <div>
                        <h5 className="font-size-16 mb-1">{data.title}</h5>
                        {/* <h5 className="font-size-16 mb-1"> 
                             Invitation {data.createdAt}
                            </h5> */}

                        <h5 className="text-muted font-size-13 ">
                          <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>{" "}
                          {data && data.location.join(", ")}
                        </h5>

                        <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14">
                          <div>
                            <span>
                              {data.skills.map((skill, index) => (
                                <Link
                                  key={index}
                                  to="#"
                                  className="badge bg-primary rounded-pill text-opacity-75 m-1 font-size-12"
                                >
                                  {skill}
                                </Link>
                              ))}
                              {/* <Link
                                    to="#"
                                    className="badge bg-primary-subtle text-primary mx-1 p-1"
                                  >
                                    Photoshop
                                  </Link>
                                  <Link
                                    to="#"
                                    className="badge bg-primary-subtle text-primary mx-1 p-1"
                                  >
                                    illustrator
                                  </Link>
                                  <Link
                                    to="#"
                                    className="badge bg-primary-subtle text-primary mx-1 p-1"
                                  >
                                    HTML
                                  </Link>
                                  <Link
                                    to="#"
                                    className="badge bg-primary-subtle text-primary mx-1 p-1"
                                  >
                                    CSS
                                  </Link>
                                  <Link
                                    to="#"
                                    className="badge bg-primary-subtle text-primary mx-1 p-1"
                                  >
                                    Javascript
                                  </Link>
                                  <Link
                                    to="#"
                                    className="badge bg-primary-subtle text-primary mx-1 p-1"
                                  >
                                    Php
                                  </Link>
                                  <Link
                                    to="#"
                                    className="badge bg-primary-subtle text-primary mx-1 p-1"
                                  >
                                    Python
                                  </Link> */}
                            </span>
                          </div>
                        </div>
                        {/* <div>
                          {/* {responsibilities.join(", ")} 
                          <i className="mdi mdi-account-details me-1 text-primary align-middle"></i>
                          <span>
                            {data.description}
                            {/* <Link to="#">See more</Link> 
                          </span>
                        </div> */}
                        <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                          <div className="mt-2">
                            {ShowApply && (
                              <Button
                                onClick={() =>
                                  handleIndividualJobApply(data.guid)
                                }
                                color={isApplied ? "success" : "primary"}
                                className="btn-sm m-1"
                                disabled={isApplied || loading}
                              >
                                {isApplied ? "Applied" : "Apply"}{" "}
                                {loading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i
                                    className={
                                      isApplied
                                        ? "far fa-check-circle"
                                        : "fas fa-paper-plane ms-1"
                                    }
                                  ></i>
                                )}
                              </Button>
                            )}

                            {ShowDownloadJd && (
                              <Button
                                color="primary"
                                className="btn btn-primary btn-sm m-1"
                                disabled
                              >
                                Download JD{" "}
                                <i className="fas fa-download ms-1"></i>
                              </Button>
                            )}

                            {ShowGenerateResume && (
                              <Button
                                className="btn btn-secondary btn-sm m-1"
                                disabled
                              >
                                Generate Resume
                                <i className="far fa-file-alt ms-1"></i>
                              </Button>
                            )}

                            {ShowPostJob && (
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm m-1"
                              >
                                Post Job
                                <i className="far fa-file-alt ms-1"></i>
                              </Link>
                            )}
                          </div>
                          {/* <div className="d-flex align-items-start justify-content-end ">
                        <Link to="#" className="btn btn-primary btn-sm m-1">
                          Download JD{" "}
                          <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>

                        <Link to="#" className="btn btn-secondary btn-sm m-1">
                          Generate Resume
                          <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Row>
          )}

          <Row>
            <Col xl={12}>
              <div>
                <CardHeader className="align-items-center d-flex mt-2">
                  <div className="flex-shrink-0">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      {/* Tab 1 */}

                      {ShowOverview && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "1",
                            })}
                            onClick={() => {
                              toggleHeader("1");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">Overview</span>
                          </NavLink>
                        </NavItem>
                      )}

                      {/* Tab 2 */}
                      {/* Matching jobs */}
                      {ShowMatchingJobsTab && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "2",
                            })}
                            onClick={() => {
                              toggleHeader("2");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Matching Jobs{" "}
                              <Badge color="primary">
                                {matchingjobsdata && matchingjobsdata.length}
                              </Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}

                      {/* Matching candidates */}
                      {ShowMatchingCandidatesTab && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "2",
                            })}
                            onClick={() => {
                              toggleHeader("2");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Matching Candidates
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ShowInvitedCandidatesTab && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "2",
                            })}
                            onClick={() => {
                              toggleHeader("2");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Invited Candidates{" "}
                              <Badge color="primary">{InvitedCount}</Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}

                      {/* Tab 3 */}
                      {ShowShortlistedCandidatesTab && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "3",
                            })}
                            onClick={() => {
                              toggleHeader("3");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Shortlisted Candidates{" "}
                              <Badge color="primary">{ShortlistedCount}</Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}

                      {ShowAssessmentTab && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "3",
                            })}
                            onClick={() => {
                              toggleHeader("3");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Take Assessment
                            </span>
                          </NavLink>
                        </NavItem>
                      )}

                      {/* Tab 4 */}

                      {ShowClientMatchingCandidates && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "4",
                            })}
                            onClick={() => {
                              toggleHeader("4");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Matching Candidates{" "}
                              <Badge color="primary">
                                {MatchingCandidatesCount}
                              </Badge>
                            </span>
                          </NavLink>
                        </NavItem>
                      )}

                      {/* Tab 5 */}
                      {showJobHistory && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "5",
                            })}
                            onClick={() => {
                              toggleHeader("5");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Job History
                            </span>
                          </NavLink>
                        </NavItem>
                      )}

                      {/* Interviews */}
                      {/* {ShowInterviewTab && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "5",
                            })}
                            onClick={() => {
                              toggleHeader("5");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Interviews
                            </span>
                          </NavLink>
                        </NavItem>
                      )} */}

                      {/* Tab 6 */}
                      {ShowOfferReleased && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: headerTab === "6",
                            })}
                            onClick={() => {
                              toggleHeader("6");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Offer Released
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </div>
                </CardHeader>

                {/* tabs content starting from here */}
                <div className="card-body">
                  <TabContent className="text-muted" activeTab={headerTab}>
                    {/* Content for Tab1 */}
                    <TabPane tabId="1">
                      {ShowOverview && <JobOverviewTab data={data} />}
                    </TabPane>

                    {/* Content for Tab2 */}
                    <TabPane tabId="2">
                      {ShowMatchingJobsTab && (
                        <Row className="mt-3">
                          {/* <h5 className="text-primary">
                            Candidature History&nbsp;&nbsp; */}
                          <span className="font-size-14 text-muted mt-1">
                            {/* <i className="fas fa-info-circle" /> */}
                            {matching_job_sub_title}
                          </span>
                          {/* </h5> */}
                          {/* <p>content for matching jobs</p> */}
                          <CustomTable
                            tableDatas={matchingjobsdata}
                            headerData={commonheaders}
                            columns={columnsdata}
                            data={matchingjobsdata}
                            individualMultipleApply={true}
                          />
                        </Row>
                      )}

                      {ShowInvitedCandidatesTab && (
                        <Row className="mt-3">
                          {/* Client Invited Candidates */}
                          <InvitedCandidatesTable
                            data={ClientInvitedCandidates}
                            RecordCount={InvitedCount}
                            handlePageChange={handleInvitedPageChange}
                            currentPage={InvitedCandidatescurrentpage}
                          />
                        </Row>
                      )}
                    </TabPane>

                    {/* Content for Tab3 */}
                    <TabPane tabId="3">
                      {ShowAssessmentTab && (
                        <Row>
                          <Row className="mycandiassesmentform mt-3">
                            <AssessmentInviteForm userType={"individual"} />
                          </Row>
                        </Row>
                      )}

                      {ShowShortlistedCandidatesTab && (
                        <Row className="mt-3">
                          {/* Client Shortlisted Candidates */}
                          <ShortlistedCandidates
                            data={ClientShortlistedCandidates}
                            RecordCount={ShortlistedCount}
                            handlePageChange={handleShortlistedPageChange}
                            currentPage={ShortlistedCandidatescurrentpage}
                          />
                        </Row>
                      )}
                    </TabPane>

                    {/* Content for Tab4 */}
                    <TabPane tabId="4">
                      {ShowClientMatchingCandidates && (
                        <Row className="mt-3">
                          <MatchingCandidates
                            jobguid={data.guid}
                            data={ClientMatchingCandidates}
                            RecordCount={MatchingCandidatesCount}
                            handlePageChange={
                              handleMatchingCandidatesPageChange
                            }
                            currentPage={MatchingCandidatescurrentpage}
                          />
                        </Row>
                      )}
                    </TabPane>

                    {/* Content for Tab5 */}
                    <TabPane tabId="5">
                      {showJobHistory && (
                        <JobTimelineComponent
                          historydata={candidatureHistory}
                        />
                      )}
                      {ShowInterviewTab && (
                        <Row className="mt-3">
                          <p>Content for Client Interviews</p>
                        </Row>
                      )}
                    </TabPane>

                    {/* Content for Tab6 */}
                    <TabPane tabId="6">
                      {ShowOfferReleased && (
                        <Row className="mt-3">
                          <ClientOffers canvasopen={true} />
                        </Row>
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
      {isOverviewCanvasOpen && (
        <JobOverview
          width={50}
          isOpen={isOverviewCanvasOpen}
          toggle={() => setOverviewCanvas(!isOverviewCanvasOpen)}
          onClose={closeOverviewCanvas}
          data={selectedJob}
        />
      )}
    </>
  );
}

export default CanvasJobDescription;

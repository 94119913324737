import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";

function JobOverviewTab({ data }) {
  return (
    <>
      <Card className="mt-3">
        {/* {OverviewData.map((overview, index) => ( */}
        <div className="card-body">
          <div>
            <div className="pb-3">
              <h5 className="font-size-15">Summary :</h5>
              <div className="">
                <p className="mb-2">{data.summary}</p>
              </div>
            </div>
            {/* <div className=" d-flex">
              <div>
                <h5 className="font-size-15">Required skills :</h5>
              </div>

              <div className="">
                {data.skills.map((skill, index) => (
                  <Link
                    key={index}
                    to="#"
                    className="badge bg-primary rounded-pill text-opacity-75 ms-1 font-size-12"
                  >
                    {skill}
                  </Link>
                ))}
              </div>
            </div> */}
            <div className="pt-3">
              <h5 className="font-size-15">Requirement :</h5>
              {data.requirements.map((requirement, index) => (
                <div key={index} className="">
                  <ul className="list-unstyled mb-0">
                    <li className="font-size-14 mb-2">
                      <div className="d-flex">
                        <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                        <p className="mb-0 pb-0">{requirement}</p>
                      </div>
                      {/* <span className="font-size-13 ms-3">
                          {/* {experience.role} 
                        </span>
                        <span className="font-size-12 text-muted ms-2">
                          {/* <em>
                            {new Date(
                              experience.from_date,
                            ).toLocaleDateString()}{" "}
                            to{" "}
                            {new Date(experience.to_date).toLocaleDateString()}
                          </em> 
                        </span> */}
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* ))} */}
      </Card>
    </>
  );
}

export default JobOverviewTab;

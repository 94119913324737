import React from "react";
import ReactApexChart from "react-apexcharts";
import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap";
import { titles } from "../../data/cb_title";

function ApexChart({ options, series, userType }) {
  const { dashboard_chart_title, dashboard_chart_sub_title } =
    titles.en[userType] || {};

  return (
    <>
      <Card>
        <CardHeader>
          {/* <h4 className="card-title mb-0 ">
            Jobs trajectory graph based on months.
          </h4> */}
          <h5 className="text-primary">
            {dashboard_chart_title}&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> {dashboard_chart_sub_title}.
            </span>
          </h5>
        </CardHeader>
        <CardBody>
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
            className="apex-charts"
          />
        </CardBody>
      </Card>
    </>
  );
}

export default ApexChart;

import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

const OfferAcceptRejectBadge = ({ status }) => {
  const getColor = (status) => {
    switch (status) {
      case "accepted":
        return "success"; // Green color
      case "started":
        return "success"; // Green color
      case "rejected":
        return "danger"; // Red color
      default:
        return "light";
    }
  };

  return (
    <Badge color={getColor(status)} className="rounded-pill px-3 p-1">
      {status}
    </Badge>
  );
};

OfferAcceptRejectBadge.propTypes = {
  status: PropTypes.oneOf(["accept", "reject"]).isRequired,
};

export default OfferAcceptRejectBadge;

import React, { useEffect, useState } from "react";
import {
  Row,
  Badge,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  IndividualDetail,
  JobDetail,
  CandidatureHistory,
} from "../../Client/ClientSlice";
import { IndividualCandidatureHistory } from "../../individual/individualSlice";
import TIQcard from "../../customComponents/offcanvas/offcanvasComponents/TIQcard";
import joblogo from "../../assets/images/companyBenchImage/jobgray1.jpg";
import TimelineComponent from "../../customComponents/timelineComponents/TimeLineComponent";
import { dateyyyymmdd_tohtml } from "../../helpers/date";
import CanvasJobDescription from "./CanvasJobdescription";
import FunnelProgressBar from "../../components/Common/FunnelProgressBar";
import { titles } from "../../data/cb_title";
import JobOverview from "./JobOverviewCanvas";

function OfferDetailsCanvas({ isOpen, toggle, onClose, data, userType }) {
  const { candidature_history_subtitle } = titles.en.common;
  const dispatch = useDispatch();
  const clientstore = useSelector((state) => state.clientSlice);
  const partnerStore = useSelector((state) => state.partnerSlice);
  const individualStore = useSelector((state) => state.individualSlice);

  const [offerData, setOfferData] = useState(null);
  const [individualDetail, setIndividualDetail] = useState({});
  const [jobDetail, setJobDetail] = useState({});
  const [candidatureHistory, setCandidatureHistory] = useState([]);

  const [isJobCanvas, setIsJobCanvas] = useState(false);

  const offcanvasStyle = {
    width: "60%", // You can set a default width or use the provided width
  };

  useEffect(() => {
    let off_data = {};
    if (data.individual_guid) {
      (off_data.guid = data.offer_id || data.guid),
        (off_data.indv_guid = data.individual_guid),
        (off_data.job_guid = data.jobdescription_guid),
        (off_data.indv_job_guid = data.individualjobmapping_guid),
        (off_data.status = data.status);
    } else if (data.guid) {
      (off_data.guid = data.offer_guid),
        (off_data.indv_guid = data.guid),
        (off_data.job_guid = data.jobdescription_guid),
        (off_data.indv_job_guid = data.indv_job_map_guid),
        (off_data.status = data.offer_status);
    }
    setOfferData(off_data);
  }, [data]);

  useEffect(() => {
    if (userType === "client") {
      dispatch(CandidatureHistory(offerData?.indv_job_guid));
    } else if (userType === "individual") {
      dispatch(IndividualCandidatureHistory(offerData?.indv_job_guid));
    }
    dispatch(JobDetail(offerData?.job_guid));
    dispatch(IndividualDetail(offerData?.indv_guid));
  }, [offerData]);

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (
        clientstore.data?.IndividualDetail?.success &&
        clientstore.data.IndividualDetail.data?.individual
      ) {
        setIndividualDetail(clientstore.data.IndividualDetail.data?.individual);
      }
      if (
        clientstore.data?.JobDetail?.success &&
        clientstore.data.JobDetail.data?.jobdescription
      ) {
        setJobDetail(clientstore.data.JobDetail.data?.jobdescription);
      }
      if (
        clientstore.data?.CandidatureHistory?.success &&
        clientstore.data.CandidatureHistory.data?.individual_history
      ) {
        if (candidatureHistory.length == 0) {
          setCandidatureHistory(
            clientstore.data.CandidatureHistory.data?.individual_history,
          );
        }
      }
    }
  }, [clientstore]);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (
        individualStore.data?.IndividualCandidatureHistory?.success &&
        individualStore.data.IndividualCandidatureHistory.data
          ?.individual_history
      ) {
        if (candidatureHistory.length == 0) {
          setCandidatureHistory(
            individualStore.data.IndividualCandidatureHistory.data
              ?.individual_history,
          );
        }
      }
    }
  }, [individualStore]);

  const getInitials = (fullName) => {
    return fullName
      ?.split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  };

  const handleJob = (e) => {
    e.preventDefault();
    setIsJobCanvas(true);
  };

  const closeJobCanvas = () => {
    setIsJobCanvas(false);
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          `
          <h5 className="text-primary">
            Offer Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Show Details of selected
              offers.
            </span>
          </h5>
          <FunnelProgressBar candidatureHistory={candidatureHistory} />
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row className="p-2">
            <div className="col-md-6 p-0">
              <div className="col-sm order-2 order-sm-1 card h-100 px-2 p-2">
                <div className="d-flex align-items-start mt-3 mt-sm-0">
                  <div className="flex-shrink-0">
                    <div className="avatar-xl mx-auto mb-0 me-2 ms-2 mt-1">
                      <span
                        className="avatar-title bg-primary text-white font-size-24 p-3 display-3 m-0 rounded-circle"
                        style={{ width: "65px", height: "65px" }}
                      >
                        {getInitials(individualDetail?.full_name)}
                      </span>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <h5 className="font-size-16">
                        {individualDetail.full_name}
                        {/* <Badge
                            color={
                              data.status === "contracted"
                                ? "success"
                                : "warning"
                            }
                            className="float-end font-size-12 float-end p-1"
                          >
                            {individualDetail?.status}
                          </Badge> */}
                        <Badge
                          className={`p-1 text-white font-size-12 float-end ${
                            offerData?.status === "started"
                              ? "bg-warning"
                              : offerData?.status === "accepted"
                                ? "bg-success"
                                : offerData?.status === "rejected"
                                  ? "bg-danger"
                                  : "" // Default color if status is not recognized
                          }`}
                        >
                          {offerData?.status}
                        </Badge>
                      </h5>
                      <h5 className="text-muted font-size-13 mb-1">
                        <i className="mdi mdi-briefcase me-1 text-primary align-middle"></i>
                        {offerData?.guid?.split("-")[0].toUpperCase()}-
                        {offerData?.guid?.split("-")[1].toUpperCase()}
                      </h5>

                      <h5 className="text-muted font-size-13 mb-1">
                        <i className="mdi mdi-calendar me-1 text-primary align-middle"></i>
                        {offerData?.offer_createdAt
                          ? dateyyyymmdd_tohtml(offerData?.offer_createdAt)
                          : "-"}
                      </h5>
                      <div className="d-flex flex-wrap align-items-start text-muted font-size-13">
                        {individualDetail.contact_number && (
                          <div>
                            <i className="mdi mdi-phone me-1 text-primary align-middle"></i>
                            {individualDetail.contact_number}
                          </div>
                        )}
                        <div>
                          <i className="mdi mdi-email me-1 text-primary align-middle"></i>
                          {individualDetail?.email}
                        </div>
                      </div>

                      <div className="d-flex flex-wrap align-items-start text-muted font-size-13 mt-2">
                        <div>
                          <span>
                            {individualDetail &&
                              individualDetail?.skills?.map((skills, index) => (
                                <Link
                                  key={index}
                                  to="#"
                                  className="badge bg-primary-subtle text-primary mx-1 p-1"
                                >
                                  {skills}
                                </Link>
                              ))}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 ps-3">
              <div className="col-sm order-2 order-sm-1 card h-100 px-2 p-2">
                <div className="d-flex align-items-start">
                  <div className="flex-shrink-0">
                    <div className="avatar-xl me-3">
                      <img
                        src={joblogo}
                        alt=""
                        className="img-fluid rounded-circle d-block "
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="col-sm-auto order-1 order-sm-2">
                      <Badge
                        color={
                          jobDetail?.status === "active"
                            ? "success-subtle text-success rounded-pill"
                            : "danger-subtle text-danger rounded-pill"
                        }
                        className="p-1 font-size-12 float-end"
                      >
                        {jobDetail?.status}
                      </Badge>
                    </div>
                    <div>
                      <Link
                        to="#"
                        onClick={(e) => {
                          handleJob(e);
                        }}
                        className="font-size-16 mb-2"
                      >
                        {jobDetail?.title}
                      </Link>
                      <h5 className="text-muted font-size-13 mt-1 mb-1">
                        <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>{" "}
                        {jobDetail?.location}
                      </h5>

                      <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14 mt-2">
                        <div>
                          <span>
                            {jobDetail?.skills?.map((skill, index) => (
                              <Link
                                key={index}
                                to="#"
                                className="badge bg-primary-subtle text-primary mx-1 p-1"
                              >
                                {skill}
                              </Link>
                            ))}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13 mt-1">
                        <div>
                          <i className="mdi mdi-account-details me-1 text-primary align-middle"></i>
                          <span>
                            {jobDetail?.description?.substring(0, 100)}...
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <TIQcard/> */}
            </div>
            <div className="mt-0 mb-0 text-muted">
              <hr />
            </div>
          </Row>
          {/* <h5 className="text-primary">
            Candidature History&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" />{candidature_history_subtitle}</span>
          </h5> */}

          {candidatureHistory && candidatureHistory.length > 0 && (
            <TimelineComponent
              historydata={candidatureHistory}
              type="candidatureHistory"
            />
          )}
        </OffcanvasBody>
      </Offcanvas>
      {/* {isJobCanvas && (
        <CanvasPartnerJobDetails
          Alljobsstatus={true}
          width={50}
          placement="end"
          isOpen={isJobCanvas}
          toggle={() => setIsJobCanvas(!isJobCanvas)}
          onclose={closeJobCanvas}
          data={jobDetail}
        />
      )} */}
      {isJobCanvas && (
        <JobOverview
          usertype={userType}
          menuOption="jobs"
          width={60}
          isOpen={isJobCanvas}
          toggle={() => setIsJobCanvas(!isJobCanvas)}
          onClose={closeJobCanvas}
          data={jobDetail}
        />
      )}
    </>
  );
}

export default OfferDetailsCanvas;

import React, { useState } from "react";
import {
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import validator from "validator";

import {
  validateStartEndDate,
  dateyyyymmdd_tohtml,
} from "../../../helpers/date";
import {
  getIndividualProfileDetails,
  postIndividualResumeUpdate,
} from "../../individualSlice";
import { useDispatch } from "react-redux";
const AddIndividualWorkExpCanvas = ({ isOpen, toggle, handleSave, data }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    company_name: "",
    role: "",
    from_date: "",
    to_date: "",
  });

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        errors[key] = validator.isEmpty(value) ? "Please fill this field" : "";
        if (
          !validator.isLength(formData["company_name"], { min: 1, max: 50 })
        ) {
          errors["company_name"] = "Please enter valid company name";
        }
        if (!validateStartEndDate(formData["from_date"], formData["to_date"])) {
          errors["from_date"] = "Start Date can not be Greater than End Date";
        }
        if (!validator.isDate(dateyyyymmdd_tohtml(formData["from_date"]))) {
          errors["from_date"] = "Please enter valid start date";
        }
        if (!validator.isDate(dateyyyymmdd_tohtml(formData["to_date"]))) {
          errors["to_date"] = "Please enter valid end date";
        }
        // if (!validator.isLength(formData['project_details'], { min: 0, max: 1000 })) {
        //     errors['project_details'] = 'Project details are Too large';
        // }
      }
    }
    setErrors(errors);
    if (Object.values(errors).some((error) => error !== "")) {
      return;
    }
    const resumeDatas = {
      resume: {
        work_experiences: [...data.work_experiences, formData],
      },
    };

    // dispatch(postIndividualResumeUpdate({resume_guid:data.guid , addedData:resumeDatas}))
    dispatch(
      postIndividualResumeUpdate({
        resume_guid: data.guid,
        addedData: resumeDatas,
      }),
    )
      .then(() => {
        // The first action has been dispatched and, assuming it resolved, we can now dispatch another.
        dispatch(getIndividualProfileDetails());
      })
      .catch((error) => {
        // Handle any errors from the first dispatch here
        console.error("Failed to update individual resume:", error);
      });

    toggle();
  };

  const offcanvasStyle = {
    width: "40%",
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          Add Work Experience
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Label className="col-sm-3 col-form-label" for="company_name">
                Company Name <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="company_name"
                  value={formData.company_name}
                  onChange={(e) => handleChange(e, "company_name")}
                />
                {errors.company_name && (
                  <small className="text-danger">{errors.company_name}</small>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Label className="col-sm-3 col-form-label" for="role">
                Role <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="role"
                  value={formData.role}
                  onChange={(e) => handleChange(e, "role")}
                />
                {errors.role && (
                  <small className="text-danger">{errors.role}</small>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Label className="col-sm-3 col-form-label" for="from_date">
                From Date <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  type="date"
                  name="from_date"
                  value={formData.from_date}
                  onChange={(e) => handleChange(e, "from_date")}
                />
                {errors.from_date && (
                  <small className="text-danger">{errors.from_date}</small>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Label className="col-sm-3 col-form-label" for="to_date">
                To Date <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  type="date"
                  name="to_date"
                  value={formData.to_date}
                  onChange={(e) => handleChange(e, "to_date")}
                />
                {errors.to_date && (
                  <small className="text-danger">{errors.to_date}</small>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default AddIndividualWorkExpCanvas;

import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//import images
import logo from "../../assets/images/loading.gif";

const PageMaintenance = () => {
  return (
    <React.Fragment>
      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="text-center p-5 m-5">
              <img src={logo} alt="" className="text-center w-50" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageMaintenance;

import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";
import { FiUserPlus } from "react-icons/fi";
import { FaBusinessTime } from "react-icons/fa6";
//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images
import giftBox from "../assets/images/giftbox.png";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";
// import withRouter from "../Common/withRouter"
import withRouter from "../components/Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import { Badge } from "reactstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { log_debug } from "../apiHelpers/logger";

const RecrutmentSidebar = (props) => {
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  //   const [Dashboardcounterdata, setDashboardcounterdata] = useState([]);
  //   const [candidatecountsidebar, setcandidatecountsidebar] = useState([]);
  //   const [shortlistedcountsidebar, setshortlistedcountsidebar] = useState([]);
  //   const [InterviewcountSidebar, setInterviewcountSidebar] = useState([]);
  //   const [contractcountsidebar, setcontractcountsidebar] = useState([]);

  //   useEffect(() => {
  //     setcandidatecountsidebar(
  //       Dashboardcounterdata &&
  //         Dashboardcounterdata?.my_candidates?.total_my_candidates,
  //     );
  //     setshortlistedcountsidebar(
  //       Dashboardcounterdata &&
  //         Dashboardcounterdata?.shortlisted_jobs?.total_shortlisted_jobs,
  //     );
  //     setInterviewcountSidebar(
  //       Dashboardcounterdata &&
  //         Dashboardcounterdata?.scheduled_interviews?.total_interviews,
  //     );
  //     setcontractcountsidebar(
  //       Dashboardcounterdata && Dashboardcounterdata?.contracts?.total_contract,
  //     );
  //   }, [Dashboardcounterdata]);

  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  //   useEffect(() => {
  //     if (partnerStore.status === "idle") {
  //       if (partnerStore?.data?.DashboardCounterdata?.success === true) {
  //         setDashboardcounterdata(
  //           partnerStore?.data?.DashboardCounterdata?.data?.dashboard_counts,
  //         );
  //       }
  //     }
  //   }, [partnerStore]);

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link
                to="/recruitee-dashboard"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="home" />
                  <span>{props.t("Dashboard")}</span>
                </div>
                {/* <Badge color="info">5</Badge> */}
              </Link>
            </li>
            <li>
              <Link
                to="/recruitee-candidate"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="users" />
                  <span>{props.t("Candidates")}</span>
                </div>
                {/* <Badge color="primary">
                  {candidatecountsidebar ? candidatecountsidebar : "0"}
                </Badge> */}
              </Link>
            </li>
            <li>
              <Link
                to="/recruitee-matchingjobs"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="calendar" />
                  <span>{props.t("Matching jobs ")}</span>
                </div>
                {/* <Badge color="primary">
                  {shortlistedcountsidebar ? shortlistedcountsidebar : "0"}
                </Badge> */}
              </Link>
            </li>
            <li>
              <Link
                to="/recruitee-shortlistedjob"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="calendar" />
                  <span>{props.t("Applied Jobs")}</span>
                </div>
                {/* <Badge color="primary">
                  {shortlistedcountsidebar ? shortlistedcountsidebar : "0"}
                </Badge> */}
              </Link>
            </li>

            <li>
              <Link
                to="/recruitee-alljobs"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="calendar" />
                  <span>{props.t("All Jobs")}</span>
                </div>
                {/* <Badge color="primary">10</Badge> */}
              </Link>
            </li>

            {/* Add similar structures for other sidebar-menu items */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

RecrutmentSidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(RecrutmentSidebar));

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardHeader,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Badge,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import "./canvas.css";
import TIQcard from "./offcanvasComponents/TIQcard";
import Assessment from "../../individual/Components/AssessmentForm";
import RejectedTimesheetcomponent from "../timesheet/rejected_timesheet";

function TimesheetCanvas({
  isOpen,
  toggle,
  onClose,
  data,
  userType,
  width,
  monday,
}) {
  const [displayName, setName] = useState("");

  const [headerTab, setHeaderTab] = useState("1");
  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  const offcanvasStyle = {
    width: `${width}%`,
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          {/* <p className="text-light">Candidate Details</p> */}
          <h5 className="text-primary">
            Timesheet
            {/* <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" />
              Assessment Details displayed. Relevant information for selected
              assesment.
            </span> */}
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="mt-5">
            <RejectedTimesheetcomponent
              timesheet={data}
              monday={monday}
              userType={userType && userType}
            />
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default TimesheetCanvas;

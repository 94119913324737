import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

import ProfileDetail from "../customComponents/profileDetail/ProfileDetail";
import { useSelector, useDispatch } from "react-redux";
import { getProfileDetails } from "./PartnerSlice";

const PartnerProfileDetails = () => {
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const [profileData, setProfileData] = useState();
  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);
  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore?.data?.ProfileDetails?.success === true) {
        setProfileData(partnerStore?.data?.ProfileDetails?.data?.partner);
      }
    }
  }, [partnerStore]);

  return (
    <React.Fragment>
      {profileData && <ProfileDetail profileData={profileData} />}
    </React.Fragment>
  );
};

export default PartnerProfileDetails;

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import validator from "validator";
import {
  addCandidatesSkills,
  getCountries,
  getCityCountry,
  myCandiDetailsShortlistedRole,
  RoleBasedSkill,
  addCandidatesLocation,
} from "../PartnerSlice";
import { dateyyyymmdd_tohtml } from "../../helpers/date";
import { log_debug } from "../../apiHelpers/logger";

const CandidateEditProfile = ({
  isOpen,
  toggle,
  handleProfileSave,
  data,
}) => {
  log_debug("data329847", data);
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const clientStore = useSelector((state) => state.clientSlice);

  const [errors, setErrors] = useState({});
  const [editedData, setEditedData] = useState({});
  const [allSkills, setAllSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [allCountry, setAllCountries] = useState([]);
  const [allCity, setAllCities] = useState([]);
  const [enumcities, setEnumCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCities, setSelectedCities] = useState([]);
  const [rate, setRate] = useState();
  const [totalExperience, setTotalExperience] = useState();
  const [relevantExperience, setrelevantExperience] = useState();
  const [noticePeriod, setNoticePeriod] = useState();


  const [MyCandidateDetailsRoleDropdown, setMyCandidateDetailsRoleDropdown] =
    useState([]);

  const roleoptions = MyCandidateDetailsRoleDropdown.map((item) => ({
    id: item._id,
    value: item.code,
    label: item.title,
  }));

  

  useEffect(() => {
    setEditedData(data);
    if (data?.skills) {
      let tempSkill = data?.skills.map((skill) => ({
        label: skill,
        value: skill,
      }));
      setSelectedSkills(tempSkill);
    }
  }, [data]);

  useEffect(() => {
    dispatch(myCandiDetailsShortlistedRole());
    dispatch(addCandidatesSkills());
    dispatch(getCountries());
    dispatch(addCandidatesLocation()).then((res) => {
      const data = res?.payload?.data?.enumcitys;
      const cities = data.map((item) => ({
        value: item.code,
        label: item.title,
      })); 
      setEnumCities(cities);
    });
  }, []);

  useEffect(() => {
    dispatch(RoleBasedSkill(editedData?.professional_role));
  }, [editedData?.professional_role]);

  useEffect(() => {
    dispatch(getCityCountry(editedData?.address?.country));
  }, [editedData?.address?.country]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data.myCandiDetailsShortlistedRoleRecords?.success === true
      ) {
        setMyCandidateDetailsRoleDropdown(
          partnerStore.data.myCandiDetailsShortlistedRoleRecords?.data
            .enumjobroles,
        );
      }
      if (partnerStore?.data?.Countries?.success === true) {
        let tempCountry =
          partnerStore?.data?.Countries?.data?.enumcountrys?.map((country) => ({
            id: country._id,
            label: country.title,
            value: country.code,
          }));
        setAllCountries(tempCountry);
      }
      if (partnerStore?.data?.countryCities?.success === true) {
        let tempCity = partnerStore?.data?.countryCities?.data?.enumcitys?.map(
          (city) => ({
            id: city._id,
            label: city.title,
            value: city.code,
          }),
        );
        setAllCities(tempCity);
      }
    }
  }, [
    partnerStore?.data?.countryCities,
    partnerStore?.data?.Countries,
    partnerStore.data.myCandiDetailsShortlistedRoleRecords,
    partnerStore?.data?.Countries,
  ]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data?.CandidatesSkills?.success === true) {
        let tempSkill =
          partnerStore.data?.CandidatesSkills?.data?.enumskills?.map(
            (skill) => ({
              label: skill.title,
              value: skill.code,
            }),
          );
        setAllSkills(tempSkill);
      }
      if (
        partnerStore.data?.RoleBasedSkill?.success === true &&
        editedData?.professional_role
      ) {
        let tempSkill = partnerStore.data?.RoleBasedSkill?.data?.skills?.map(
          (skill) => ({
            label: skill,
            value: skill,
          }),
        );
        setSelectedSkills(tempSkill);
        let editData = editedData;
        editData = { ...editData, skills: tempSkill.map((sk) => sk.label) };
        setEditedData(editData);
      }
    }
  }, [partnerStore.data?.CandidatesSkills, partnerStore.data?.RoleBasedSkill]);
  const handleValidateSave = () => {
    const today = new Date();
    // If you want to format the date as YYYY-MM-DD
    const todayFormatted = today.toISOString().split("T")[0];
    const birthDate = new Date(editedData?.date_of_birth);
    const age = today.getFullYear() - birthDate.getFullYear();

    let dob =
      editedData?.date_of_birth === null ||
      editedData?.date_of_birth === undefined
        ? ""
        : editedData?.date_of_birth;

    let newErrors = {};
    if (validator.isEmpty(editedData?.full_name)) {
      newErrors.full_name = "Name is required";
    } else if (
      !validator.isLength(editedData?.full_name, { min: 3, max: 40 }) ||
      !validator.isAlpha(editedData?.full_name, ["en-IN"], { ignore: " ." })
    ) {
      newErrors.full_name = "Please enter a valid name";
    }
    if (editedData.email_id === undefined || validator.isEmpty(editedData?.email_id)) {
      newErrors.email_id = "Email is required";
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        editedData?.email_id,
      )
    )if (editedData.email_id === undefined || !validator.isLength(editedData?.email_id, { min: 0, max: 50 })) {
      newErrors.email_id = "Email is Too large";
    } else if (editedData.email_id === undefined || !validator.isEmail(editedData?.email_id)) {
        newErrors.email_id = "Please enter valid email";
      }else {
      newErrors.email_id = "Invalid email format";
    }
    
    if (editedData.contact_no === undefined || validator.isEmpty(editedData?.contact_no)) {
      newErrors.contact_no = "Phone is required";
    } else if (!validator.isMobilePhone(editedData?.contact_no)) {
      newErrors.contact_no = "Please enter valid phone";
    } else if (
      editedData?.contact_no.includes("+") &&
      editedData?.contact_no.length !== 13
    ) {
      newErrors.contact_no = "Please enter valid phone";
    } else if (
      !editedData?.contact_no.includes("+") &&
      editedData?.contact_no.length !== 10
    ) {
      newErrors.contact_no = "Please enter valid phone";
    }
    if (validator.isEmpty(editedData?.address?.city)) {
      newErrors["address.city"] = "City is required";
    }
    if (validator.isEmpty(editedData?.address?.country)) {
      newErrors["address.country"] = "Country is required";
    }
    // if (validator.isEmpty(linkedin)) {
    //     newErrors['linkedin_url'] = 'LinkedIn Id is required';
    // }
    // if (!validator.isLength(editedData.linkedin, { min: 0, max: 60 })) {
    //     newErrors['linkedin_url'] = 'LinkedIn ID is Too large';
    // }
    // if (!editedData.linkedin.includes('www.linkedin.com')) {
    //     newErrors['linkedin_url'] = 'Please enter vaild LinkedIn ID';
    // }
    if (editedData?.professional_role == undefined || editedData?.professional_role == "") {
      newErrors.professional_role = "Role is required";
    }
    if (editedData?.skills?.length === 0) {
      newErrors.skills = "Skill is required";
    }
    if (validator.isEmpty(editedData?.summary)) {
      newErrors.summary = "Summary is required";
    } else if (
      !validator.isLength(editedData?.summary, { min: 0, max: 1000 })
    ) {
      newErrors.summary = "Summary is Too large";
    }
    if (validator.isEmpty(dob)) {
      newErrors.date_of_birth = "Date of birth is required";
    } else if (!validator.isDate(dateyyyymmdd_tohtml(dob.toString()))) {
      newErrors.date_of_birth = "Please enter valid date of birth";
    } else if (age < 15 || age > 60) {
      newErrors.date_of_birth =
        "Candidate age must be between 15 to 60 years old";
    } else if (dob) {
      if (
        validator.isAfter(dob.toString(), { comparisonDate: todayFormatted })
      ) {
        newErrors.date_of_birth =
          "Date of Birth can not be greater than today date";
      }
    }
    // if (!validator.isMobilePhone(editedData?.contact_no)) {
    //   newErrors.contact_no = 'Please enter a valid contact number';
    // }
    editedData.rate = Number(rate) || 0;
    editedData.overall_experience = totalExperience;
    editedData.relevant_experience = relevantExperience;
    const preferedlocation = selectedCities.map((city) => city.label);
    editedData.prefered_locations = preferedlocation || [];
    editedData.current_location = selectedCity.label;
    editedData.notice_period = noticePeriod;

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      handleProfileSave(editedData);
    }
  };

  const handleSkills = (skill) => {
    log_debug("skill3432", skill);
    let editData = { ...editedData };
    editData = { ...editData, skills: skill.map((sk) => sk.label) };
    setEditedData(editData);
    setSelectedSkills(skill);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };
    newErrors[name] = "";
    setErrors(newErrors);
    if (name.includes(".")) {
      const [objectName, propertyName] = name.split(".");
      setEditedData((prevData) => ({
        ...prevData,
        [objectName]: { ...prevData[objectName], [propertyName]: value },
      }));
    } else {
      setEditedData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleClose = () => {
    toggle();
    setEditedData(data); // Reset the form fields to initial values on close
  };
  const offcanvasStyle = {
    width: "50%", // You can set a default width or use the provided width
  };

  const handleCurrentLocation = (selectedLocation) => {
    setSelectedCity(selectedLocation);
  }

  const handlePreferedLocation = (selectedOptions) => {
    setSelectedCities(selectedOptions)
  }

  const handleratechange = (e) => {
    setRate(e.target.value);
  }

  const handletotalExperience = (e) => {
    setTotalExperience(e.target.value);
  }

  const handlerelevantExperience = (e) => {
    setrelevantExperience(e.target.value);
  }

  const handleNoticePeriod = (e) => {
    setNoticePeriod(e.target.value)
  }

  return (
    <>
          <Form>
            <Row className="mb-3">
              <Label htmlFor="fullName" className="col-sm-3 col-form-label">
                Full Name <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  className="form-control"
                  id="fullName"
                  placeholder="Enter full name"
                  name="full_name"
                  value={editedData.full_name}
                  onChange={handleChange}
                />
                {errors.full_name && (
                  <small className="text-danger">{errors.full_name}</small>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="contactNo" className="col-sm-3 col-form-label">
                Contact No <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  className="form-control"
                  type="text"
                  id="contactNo"
                  placeholder="Enter contact number"
                  name="contact_no"
                  value={editedData.contact_no}
                  onChange={handleChange}
                />
                {errors.contact_no && (
                  <small className="text-danger">{errors.contact_no}</small>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="email" className="col-sm-3 col-form-label">
                Email <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  className="form-control"
                  type="text"
                  id="email_id"
                  placeholder="Enter your Email"
                  name="email_id"
                  value={editedData.email_id}
                  onChange={handleChange}
                />
                {errors.email_id && (
                  <small className="text-danger">{errors.email_id}</small>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="professionalRole"
                className="col-sm-3 col-form-label"
              >
                Professional Role <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <select
                  className="form-select"
                  id="professionalRole"
                  name="professional_role"
                  onChange={handleChange}
                  // onBlur={formik.handleBlur}
                  value={editedData?.professional_role}
                  required
                >
                  <option selected disabled>
                    Select Role
                  </option>
                  {roleoptions.map((role) => (
                    <option key={role.id} value={role.label}>
                      {role.label}
                    </option>
                  ))}
                </select>
                {errors.professional_role && (
                  <small className="text-danger">
                    {errors.professional_role}
                  </small>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="skills" className="col-sm-3 col-form-label">
                Skills <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Select
                  className="basic-multi-select"
                  name="skills"
                  placeholder="Select Skills"
                  options={allSkills}
                  onChange={handleSkills}
                  value={selectedSkills ? selectedSkills : []}
                  isMulti
                  isSearchable
                  isOptionDisabled={() => selectedSkills.length > 6}
                  components={makeAnimated()}
                  // value={editedData?.skills}
                ></Select>
                {errors.skills && (
                  <small className="text-danger">{errors.skills}</small>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Label className="col-sm-3 col-form-label" htmlFor="address">
                Address <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Row className="mb-3">
                  <Col sm={12}>
                    <Input
                      type="text"
                      id="street"
                      placeholder="Enter street"
                      name="address.street"
                      value={editedData?.address?.street}
                      onChange={handleChange}
                    />
                    {errors["address.street"] && (
                      <small className="text-danger">
                        {errors["address.street"]}
                      </small>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <select
                      className="form-select"
                      name="address.country"
                      onChange={handleChange}
                      value={editedData?.address?.country}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {allCountry?.map((country) => (
                        <option key={country.id} value={country.label}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                    {errors["address.country"] && (
                      <small className="text-danger">
                        {errors["address.country"]}
                      </small>
                    )}
                  </Col>
                  <Col sm={6}>
                    <select
                      className="form-select"
                      name="address.city"
                      onChange={handleChange}
                      value={editedData?.address?.city}
                    >
                      <option value="" disabled>
                        Select City
                      </option>
                      {allCity?.map((city) => (
                        <option key={city.id} value={city.label}>
                          {city.label}
                        </option>
                      ))}
                    </select>
                    {errors["address.city"] && (
                      <small className="text-danger">
                        {errors["address.city"]}
                      </small>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            
            {/* <Row className="mb-3">
              <Label
                htmlFor="marital_status"
                className="col-sm-3 col-form-label"
              >
                Marital Status
              </Label>
              <Col sm={9}>
                <select
                  className="form-select"
                  id="marital_status"
                  placeholder="Enter marital status"
                  name="marital_status"
                  value={editedData.marital_status || ""}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Marital Status
                  </option>
                  <option>Married</option>
                  <option>Unmarried</option>
                </select>
                {/* {errors.marital_status && (
                  <small className="text-danger">{errors.marital_status}</small>
                )} 
              </Col>
            </Row> */}
            


            <Row className="mb-3">
              <Label
                htmlFor="total_experience"
                className="col-sm-3 col-form-label"
              >
                Total Experience
              </Label>
              <Col sm={3}>
                <Input
                type="number"
                min={0}
                max={50}
                onChange={handletotalExperience}
                value={totalExperience}
                />
              </Col>


              <Label
                htmlFor="relevant_experience"
                className="col-sm-3 col-form-label text-end"
              >
                Relevant Experience
              </Label>
              <Col sm={3}>
                <Input
                type="number"
                min={0}
                max={50}
                onChange={handlerelevantExperience}
                value={relevantExperience}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Label
                htmlFor="current_location"
                className="col-sm-3 col-form-label"
              >
                Current Location
              </Label>
              <Col sm={3}>
                <Select
                options={enumcities}
                isMulti={false}
                isSearchable
                onChange={handleCurrentLocation}
                value={selectedCity}
                />
              </Col>


              <Label
                htmlFor="relevant_experience"
                className="col-sm-3 col-form-label text-end"
              >
                Prefered Location
              </Label>
              <Col sm={3}>
              <Select
                options={enumcities}
                isMulti={true}
                isSearchable
                onChange={handlePreferedLocation}
                value={selectedCities}
                isOptionDisabled={() =>selectedCities.length >=5}
                />
              </Col>
            </Row>

            <Row className="mb-3">
            <Label
                htmlFor="rate"
                className="col-sm-3 col-form-label"
              >
                Rate
              </Label>
              <Col sm={3}>
                <Input
                type="number"
                min={0}
                onChange={handleratechange}
                value={rate}
                />
              </Col>


              <Label
                htmlFor="notice_period"
                className="col-sm-3 col-form-label text-end"
              >
                Notice Period (days)
              </Label>
              <Col sm={3}>
                <Input
                type="number"
                min={0}
                max={365}
                onChange={handleNoticePeriod}
                value={noticePeriod}
                />
              </Col>


              
            </Row>

            <Row className="mb-3">
              <Label
                htmlFor="availability"
                className="col-sm-3 col-form-label"
              >
                Availability
              </Label>
              <Col sm={9}>
                <select
                  className="form-select"
                  id="availability"
                  placeholder="Enter Availabilty"
                  name="availability"
                  value={editedData.availability || ""}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Availability
                  </option>
                  <option value="full-time">Full Time</option>
                  <option value="part-time">Part Time</option>
                </select>
                {/* {errors.email_id && (
                  <small className="text-danger">{errors.email_id}</small>
                )} */}
              </Col>
            </Row>


          


            <Row className="mb-3">
              <Label htmlFor="dob" className="col-sm-3 col-form-label">
                Date Of Birth <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  className="form-control"
                  type="date"
                  id="dob"
                  placeholder="Enter Date Of Birth"
                  name="date_of_birth"
                  value={
                    editedData?.date_of_birth &&
                    dateyyyymmdd_tohtml(editedData.date_of_birth)
                  }
                  onChange={handleChange}
                />
                {errors.date_of_birth && (
                  <small className="text-danger">{errors.date_of_birth}</small>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="dob" className="col-sm-3 col-form-label">
                Linkedin URL 
              </Label>
              <Col sm={9}>
                <Input
                  className="form-control"
                  type="text"
                  id="linkedin_url"
                  placeholder="Enter linkedin url"
                  name="linkedin_url"
                  value={
                    editedData?.linkedin_url ? editedData?.linkedin_url : ""
                  }
                  onChange={handleChange}
                />
                {/* {errors.linkedin && (
                  <small className="text-danger">{errors.linkedin}</small>
                )} */}
              </Col>
            </Row>
            <Row className="mb-4">
              <Label htmlFor="summary" className="col-sm-3 col-form-label">
                Summary <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <textarea
                  id="summary"
                  name="summary"
                  className="form-control"
                  rows="4"
                  placeholder="Enter Your Summary"
                  value={editedData.summary || ""}
                  onChange={handleChange}
                ></textarea>
                {errors.summary && (
                  <small className="text-danger text-capitalize">
                    {errors.summary}
                  </small>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button color="primary" onClick={() => handleValidateSave()}>
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
    </>
  );
};

export default CandidateEditProfile;
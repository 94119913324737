import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { Link } from "react-router-dom";
import { getIndividualOffers, getJobsCount } from "./individualSlice";
import dayjs from "dayjs";
import OfferDetailsCanvas from "../customComponents/offcanvas/OfferDetailsCanvas";
import StatusBadge from "../customComponents/utilities/StatusBadge";
import moment from "moment";

function IndividualOffers() {
  const individualStore = useSelector((state) => state.individualSlice);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedOffer, setselectedOffer] = useState(null);
  const dispatch = useDispatch();
  const [InterviewData, setInterviewData] = useState([]);
  const [LocationOptions, setLocationOptions] = useState([]);
  const [JobRoleOptions, setJobRoleOptions] = useState([]);
  const [RecordCount, setRecordCount] = useState(0);
  const [filtervalue, setFiltervalue] = useState("");
  const [TableLoading, setTableLoading] = useState(false);
  const defaultStartDate = new Date();

  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getJobsCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  const yourDynamicHeaderData = {
    _id: "Interveiw id",
    location: "Location",
    status: "Status",
    type: "Type",
    feedback: "Feedback",
  };

  const handleCanvasOffer = (offer) => {
    setselectedOffer(offer);
    setOffcanvasOpen(true);
  };

  const columns = [
    {
      Header: "Offer Id",
      accessor: "guid",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleCanvasOffer(row.original);
          }}
        >
          {row.original?.guid?.split("-")[0].toUpperCase()}-
          {row.original?.guid?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Offer Date",
      accessor: "createdAt",

      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <StatusBadge status={row.values.status} />
        // <Badge
        //   color={
        //     row.values.status === "accepted"
        //       ? "success rounded-pill"
        //       : "warning rounded-pill"
        //   }
        // >
        //   {row.values.status}
        // </Badge>
      ),
    },

    // {
    //   Header: "Status",
    //   accessor: "status",

    //   Cell: ({ value }) => (
    //     <Badge
    //       color={
    //         value === "selected"
    //           ? "success-subtle text-success rounded-pill bg-opacity-50"
    //           : "warning-subtle text-warning rounded-pill bg-opacity-50"
    //       }
    //       className="m-1 font-size-12"
    //     >
    //       {value}
    //     </Badge>
    //   ),
    // },
    // {
    //   Header: "Scheduled Date",
    //   accessor: "scheduled_at",
    //   Cell: (props) => {
    //           const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
    //           return <span>{tidyDate}</span>;
    //         },
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  useEffect(() => {
    // dispatch(getIndividualLocations())
    // dispatch(getIndividualJobRoles())
    const timer = setTimeout(() => {
      dispatch(
        getIndividualOffers({ filtervalue: filtervalue ? filtervalue : "" }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [filtervalue]);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.Offers?.success === true) {
        setInterviewData(individualStore.data.Offers?.data?.offers);
        setRecordCount(individualStore.data.Offers?.data?.totalCount);
        setTableLoading(false);
      } else if (individualStore.data.Offers?.success === false) {
        setTableLoading(false);
      }
    }
    // if(individualStore.status === "idle") {
    //   if(individualStore.data.locations?.success === true) {
    //     setLocationOptions(individualStore.data.locations?.data?.enumlocations);
    //   }
    // }
    // if(individualStore.status === "idle") {
    //   if(individualStore.data.jobroles?.success === true) {
    //     setJobRoleOptions(individualStore.data.jobroles?.data?.enumjobroles);
    //   }
    // }
  }, [individualStore.data.Offers]);

  const handleValueChange = (newValue) => {
    setTableLoading(true);
    setFiltervalue(newValue);
  };

  // const jobroleOptions = JobRoleOptions.map((item) => ({
  //   value: item.code,
  //   label: item.title,
  // }));

  // const locationOptions = LocationOptions.map((item) => ({
  //   value: item.code,
  //   label: item.title,
  // }));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Offers" Name="Offers" />
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <CardBody>
                  <Row>
                    <div className="col-12 col-lg-6 col-md-6 d-sm-flex" />
                    <div className="col-12 col-lg-6 col-md-6">
                      <Col md={12}>
                        <CardTitle className="text-end fs-6 pt-3">
                          Total Offers : {RecordCount}
                        </CardTitle>
                      </Col>
                    </div>
                  </Row>
                </CardBody>
                {/* <div>
                    <Card
                      style={{
                        backgroundColor: "#5156BE",
                        paddingTop: "15px",
                      }}
                    >
                      <CardBody>
                        <Row>
                          <div className="col-12 col-lg-6 col-md-6 d-sm-flex">
                            <div className="col-12 col-lg-6">
                              <DropdownWithTwoField
                                // label="Option 1"
                                options={locationOptions}
                                placeholder="Select locations"
                              />
                            </div>{" "}
                            &nbsp;
                            <div className="col-12 col-lg-6">
                              <DropdownWithTwoField
                                options={jobroleOptions}
                                placeholder="Select Roles"
                              />
                            </div>
                          </div>
                          {/* <div className="col-12 col-lg-6 col-md-6">
                            <Col md={12}>
                              <CardTitle
                                style={{ paddingTop: "10px" }}
                                className="text-end text-white"
                              >
                                <strong> Search Result :</strong>
                              </CardTitle>
                            </Col>
                          </div> 
                        </Row>
                      </CardBody>
                    </Card>
                  </div> */}
                <div>
                  <Card>
                    <CardBody>
                      <CustomTable
                        tableDatas={InterviewData}
                        headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={InterviewData}
                        hideCheckbox={true}
                        ShowPagination={
                          InterviewData && InterviewData.length > 10
                            ? true
                            : false
                        }
                        totalCount={RecordCount}
                        globalsearch={true}
                        onChange={handleValueChange}
                        isLoading={TableLoading}
                        currentPage={0}
                      />
                    </CardBody>
                  </Card>
                  {/* <DataTable title="All Jobs" columns={columns} data={jobs} /> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <OfferDetailsCanvas
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={selectedOffer}
          userType={"individual"}
        />
      )}
    </React.Fragment>
  );
}

export default IndividualOffers;

import React from "react";

const FunnelProgressBar = ({ candidatureHistory }) => {
  return (
    <div className="hstack">
      <a
        href="#"
        className={`funnel ${
          candidatureHistory.length > 0 &&
          candidatureHistory &&
          candidatureHistory[0].status &&
          candidatureHistory[0].status.includes("rejected") &&
          candidatureHistory[0].status.includes("new")
            ? "bg-danger"
            : candidatureHistory.length > 0 &&
                candidatureHistory &&
                candidatureHistory[0].status &&
                candidatureHistory[0].status.includes("new") &&
                !candidatureHistory[0].status.includes("rejected")
              ? "bg-primary"
              : ""
        }`}
      >
        New
      </a>

      <a
        href="#"
        className={`funnel ${
          candidatureHistory.length > 0 &&
          candidatureHistory &&
          candidatureHistory[0].status &&
          candidatureHistory[0].status.includes("rejected") &&
          candidatureHistory[0].status.includes("l1")
            ? "bg-danger"
            : candidatureHistory.length > 0 &&
                candidatureHistory &&
                candidatureHistory[0].status &&
                candidatureHistory[0].status.includes("l1") &&
                !candidatureHistory[0].status.includes("rejected")
              ? "bg-primary"
              : ""
        }`}
      >
        L1 Interview
      </a>

      <a
        href="#"
        className={`funnel ${
          candidatureHistory.length > 0 &&
          candidatureHistory &&
          candidatureHistory[0].status &&
          candidatureHistory[0].status.includes("rejected") &&
          candidatureHistory[0].status.includes("l2")
            ? "bg-danger"
            : candidatureHistory.length > 0 &&
                candidatureHistory &&
                candidatureHistory[0].status &&
                candidatureHistory[0].status.includes("l2") &&
                !candidatureHistory[0].status.includes("rejected")
              ? "bg-primary"
              : ""
        }`}
      >
        L2 Interview
      </a>

      <a
        href="#"
        className={`funnel ${
          candidatureHistory.length > 0 &&
          candidatureHistory &&
          candidatureHistory[0].status &&
          candidatureHistory[0].status.includes("rejected") &&
          candidatureHistory[0].status.includes("offer")
            ? "bg-danger"
            : candidatureHistory.length > 0 &&
                candidatureHistory &&
                candidatureHistory[0].status &&
                candidatureHistory[0].status.includes("offer") &&
                !candidatureHistory[0].status.includes("rejected")
              ? "bg-primary"
              : ""
        }`}
      >
        {" "}
        Offer{" "}
      </a>

      <a
        href="#"
        className={`funnel ${
          candidatureHistory.length > 0 &&
          candidatureHistory[0].status &&
          candidatureHistory[0].status.includes("contract") &&
          candidatureHistory[0].status.includes("rejected")
            ? "bg-danger"
            : candidatureHistory.length > 0 &&
                candidatureHistory &&
                candidatureHistory[0].status &&
                candidatureHistory[0].status.includes("contract") &&
                !candidatureHistory[0].status.includes("rejected")
              ? "bg-primary"
              : ""
        }`}
      >
        {" "}
        Contract{" "}
      </a>
    </div>
  );
};

export default FunnelProgressBar;

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
// import SidebarContent from "./SidebarContent";

import PartnerSidebar from "../../Partner/PartnerSidebar";
import IndividualSidebar from "../../individual/IndividualSidebar";
import ClientSidebar from "../../Client/ClientSidebar";
import { log_debug } from "../../apiHelpers/logger";
import RecrutmentSidebar from "../../Recrutment/RecrutmentSidebar";
import PartnerIndividualSidebar from "../../Partner_Individual/PartnerIndividualSidebar";
import CB_Accounts_Sidebar from "../../CB_Accounts/CB_Accounts_Sidebar";

const Sidebar = (props) => {
  useEffect(() => {
    // Log URL parameters for debugging
    //log_debug("sidebar data");

    log_debug("Persona:", props.type);
  }, [props]);
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {/* {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />} */}
          {/* {props.type !== "condensed2" ? (
            <PartnerSidebar />
          ) : (
            <PartnerSidebar />
          )} */}
          {/* {props.type !== "condensed" ? (
            <IndividualSidebar />
          ) : (
            <IndividualSidebar />
          )} */}
          {props.type === "individual" ? (
            <IndividualSidebar />
          ) : props.type === "partner" ? (
            <PartnerSidebar />
          ) : props.type === "Recruiting_Firm" ? (
            <RecrutmentSidebar />
          ) : props.type === "client" ? (
            <ClientSidebar />
          ) : 
            props.type === "partner_individual" ? (<PartnerIndividualSidebar />)
            :(props.type === "cb_accounts") && <CB_Accounts_Sidebar/>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withTranslation()(Sidebar));

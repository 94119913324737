export const titles = {
  en: {
    individual: {
      dashboard_chart_title: "Your Job Journey",
      dashboard_chart_sub_title: "Track Applications, Shortlists & Interviews",
      matching_job_sub_title:
        "Discover Opportunities Tailored to Your Preferences and Skills",
      assessment_form_title: "Enhance Your Selection Chances",
      assessment_form_sub_title:
        "Take Our Assessment to Showcase Your Skills and Stand Out to potential Employers",
    },
    client: {
      dashboard_chart_title: "Your Activity Overview",
      dashboard_chart_sub_title:
        "Monitor Jobs, Invitations, Interviews, Offers, and Contracts",
    },
    partner: {
      dashboard_chart_title: "Partner Activity Overview",
      dashboard_chart_sub_title:
        "Monitor Partner Engagement, Invitations, Interviews, Offers, and Contracts",
      matching_job_title: "Ideal Job Matches for Your Candidates",
      matching_job_sub_title:
        "Curated Opportunities Aligned with your Candidates' Skills and Preferences",
      assessment_form_title: "Enhance Your Selection Chances",
      assessment_form_sub_title:
        "Take Our Assessment to Showcase Your Skills and Stand Out to potential Employers",
    },
    recruitment: {
      dashboard_chart_title: "Recruiting Firm Activity Overview",
      dashboard_chart_sub_title: "Monitor Recruiting Firm Engagement",
      matching_job_title: "Ideal Job Matches for Your Candidates",
      matching_job_sub_title:
        "Curated Opportunities Aligned with your Candidates' Skills and Preferences",
      assessment_form_title: "Enhance Your Selection Chances",
      assessment_form_sub_title:
        "Take Our Assessment to Showcase Your Skills and Stand Out to potential Employers",
    },
    common: {
      job_overview_title: "",
      job_overview_subtitle: "",
      candidature_history_subtitle:
        "This timeline provides a comprehensive view of the candidate's entire history for this job, from the moment you applied, through interviews, to receiving offers and contracts",
      // Add more common titles and subtitles as needed
    },
  },
};

export const toast_messages = {
  edit_profile: "Profile Details Updated Sucessfully",
  edit_work_experience: "Work Experience Updated Sucessfully",
  edit_education: "Eduaction Updated Sucessfully",
  edit_certificate: "Certificate Updated Sucessfully",

  add_work_experience: "Work Experience Added Sucessfully",
  add_education: "Education Added Sucessfully",
  add_certificate: "Certificate Added Sucessfully",

  delete_work_experience: "Work Experience Deleted Successfully",
  delete_education: "Education Deleted Successfully",
  delete_certificate: "Certificate Deleted Successfully",

  variant_success: "success",
  variant_error: "error",
  variant_warning: "warning",
};

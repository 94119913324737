import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

import ProfileDetails from "../customComponents/profileDetail/ProfileDetail";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetails } from "./ClientSlice";

const ClientProfileDetails = () => {
  const dispatch = useDispatch();
  const clientStore = useSelector((state) => state.clientSlice);

  const [profileData, setProfileData] = useState();

  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);
  useEffect(() => {
    if (clientStore.status === "idle") {
      if (clientStore?.data?.getProfileDetailsData?.success === true) {
        setProfileData(clientStore?.data?.getProfileDetailsData?.data?.client);
      }
    }
  }, [clientStore]);

  return (
    <React.Fragment>
      {profileData && <ProfileDetails profileData={profileData} />}
    </React.Fragment>
  );
};

export default ClientProfileDetails;

import { or_get_ist } from "./utils";
import { common } from "../apiConfig/axiosDev";

export const log_debug = function (React_name, ...messages) {
  if (common.log_enabled) {
    console.log(
      or_get_ist() + " [CB-WEBAPP][" + React_name + "] ",
      ...messages,
    );
  }
};

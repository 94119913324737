import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import ColumnChart from "../pages/Charts/ColumnChart";
import DashboardNavTabs from "../pages/UiElements/DashboardNavTabs";
import DashboardCountCard from "../customComponents/DashboardCountCard";
import ApexChart from "../customComponents/charts/ApexChart";
import PieChart from "../customComponents/charts/PieChart";
import Breadcrumb from "../components/Common/Breadcrumb";
import { DateRangePicker } from "react-date-range";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { getClientDashboardCount, getClientGraphCount } from "./ClientSlice";
import ClientDashboardTabs from "./Components/DashboardComponents/DashboardTabs";
import { titles } from "../data/cb_title";
import { jobs } from "../data/Data";
import { useNavigate } from "react-router-dom";
import { log_debug } from "../apiHelpers/logger";

const userType = "client";
const ClientDashboard = () => {
  document.title = "Company Bench";
  // const { dashboard_chart_title, dashboard_chart_sub_title } = titles.en[userType];

  const defaultStartDate = new Date();
  const defaultEndDate = new Date();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    key: "selection",
  });

  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  const clientStore = useSelector((state) => state.clientSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dashboardData, setdashboardData] = useState([]);
  const [graphData, setgraphData] = useState([]);

  useEffect(() => {
    dispatch(
      getClientDashboardCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );

    dispatch(
      getClientGraphCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, [formattedEndDate]);

  useEffect(() => {
    if (clientStore.status === "idle") {
      if (clientStore.data.ClientDashboardCount?.success === true) {
        setdashboardData(
          clientStore.data.ClientDashboardCount?.data?.dashboard_counts,
        );
      }

      if (clientStore.data.ClientGraphCount?.success === true) {
        setgraphData(clientStore.data.ClientGraphCount?.data?.dashboard_counts);
      }
    }
  }, [
    clientStore.data.ClientDashboardCount,
    clientStore.data.ClientGraphCount,
  ]);

  const { my_jobs, interviews, invitations, offers, contracts } = dashboardData;

  let date = [];
  let myjobs = [];
  let invitation = [];
  let selectedinterviews = [];
  let clientoffers = [];
  let contract = [];

  // Loop through the dashboard_counts array
  graphData.forEach((item) => {
    const formattedDate = moment(item.date).format("MMM-DD");
    date.push(formattedDate);
    myjobs.push(item.my_jobs);
    invitation.push(item.invitations);
    selectedinterviews.push(item.selected_interviews);
    clientoffers.push(item.client_Offers);
    contract.push(item.contracts);
  });

  const pieseries = [44, 55, 41, 17, 15];
  const pieoptions = {
    labels: ["Passed", "Rejected", "Failed", "Cancelled", "Scheduled"],
    colors: ["#5156be", "#bcbfff", "#777aca", "#8085ea", "#a8aada"],
    // colors: ["#2ab57d", "#5156be", "#fd625e", "#4ba6ef", "#ffbf53"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 4,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };
  const series = [
    {
      name: "Jobs",
      data: myjobs,
    },
    {
      name: "Invited Candidates",
      data: invitation,
    },
    {
      name: "Interviews",
      data: selectedinterviews,
    },
    {
      name: "Offers",
      data: clientoffers,
    },
    {
      name: "Contracts",
      data: contract,
    },
  ];
  const apexoptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "75%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#52A9CC", "#2FB6EB", "#0036E1", "#9CE3FF", "#5DBDE3", "7FDBFF"],
    xaxis: {
      categories: date,
      title: {
        text: "Number of Jobs based on Date range",
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Math.round(value); // Rounds the value to an integer
        },
      },
      title: {
        text: "Number of Jobs",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };
  const options = {
    chart: {
      height: 50,
      type: "line",
      toolbar: { show: false },
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#5156be"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };
  const MyJobs = {
    title: "My Jobs",
    value: my_jobs?.total_my_jobs,
    type: "line",
    percentage: my_jobs?.percentage,
    sinceLastWeek: "Since last week",
    // series: [/* Your series data for the chart */],
    series: [],
    statusColor: "success", // Change this to your desired color
    rank: `${my_jobs?.active_my_jobs} Active Jobs`, // Provide a rank value
  };

  const JobInvitationData = {
    title: "Invitations",
    value: invitations?.total_invitations,
    type: "line",
    sinceLastWeek: "Since last week",
    percentage: invitations?.percentage,
    // series: [/* Your series data for the chart */],
    series: [],
    statusColor: "success", // Change this to your desired color
    rank: `${invitations?.shortlisted} Shortlisted`, // Provide a rank value
  };

  const Interviews = {
    title: "Interviews",
    value: interviews?.total_interviews,
    type: "line",
    sinceLastWeek: "Since last week",
    percentage: interviews?.percentage,
    // series: [/* Your series data for the chart */],
    series: [],
    statusColor: "success", // Change this to your desired color
    rank: `${interviews?.selected_interviews} Selected`, // Provide a rank value
  };

  const Offers = {
    title: "Offers",
    value: offers?.total_offers,
    type: "line",
    sinceLastWeek: "Since last week",
    percentage: offers?.percentage,
    // series: [/* Your series data for the chart */],
    series: [],
    statusColor: "success", // Change this to your desired color
    rank: `${offers?.accepted_offers} ${
      offers?.accepted_offers === 1 ? " Accepted" : " Accepted"
    } `, // Provide a rank value
  };

  const Contracts = {
    title: "Contracts",
    value: contracts?.total_contract,
    type: "line",
    sinceLastWeek: "Since last week",
    percentage: contracts?.percentage,
    // series: [/* Your series data for the chart */],
    series: [],
    statusColor: "success", // Change this to your desired color
    rank: `${contracts?.active_contracts} ${
      contracts?.active_contracts === 1 ? "Active Contract" : "Active Contracts"
    }`, // Provide a rank value
  };
  //meta title

  function formatDate(date) {
    // Example: YYYY-MM-DD
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .split("/")
      .reverse()
      .join("-");

    // If the formatted date is in the YYYY-DD-MM format, split it and rearrange it to YYYY-MM-DD
    const parts = formattedDate.split("-");
    return `${parts[0]}-${parts[2]}-${parts[1]}`;
  }

  const datePickerStyle = {
    position: "absolute",
    right: "0%",
    boxShadow: "0px 0px 8px 0px grey",
    zIndex: "1",
    backgroundColor: "#fff",
    padding: "5px",
    borderRadius: "8px",
  };

  const handleButtonClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    setformattedStartDate(
      moment(ranges.selection.startDate).format("YYYY-MM-DD"),
    );

    setformattedEndDate(moment(ranges.selection.endDate).format("YYYY-MM-DD"));

    // log_debug("handle select date",formattedEndDate)
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col>
              <Breadcrumb
                title="Home"
                breadcrumbItem="Dashboard"
                Name="Hi User"
              />
            </Col>
            <Col lg={3}>
              <div>
                <form className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <div>
                      <input
                        type="text"
                        className="form-control  text text-center bg-light"
                        // placeholder={dateRange}
                        value={`${formatDate(
                          selectionRange.startDate,
                        )}${" "} - ${" "}${formatDate(selectionRange.endDate)}`}
                        style={{ cursor: "pointer" }}
                        onClick={handleButtonClick}
                        readOnly
                      />

                      <IconButton
                        className="btn"
                        color="primary"
                        onClick={handleButtonClick}
                      >
                        <i className="align-middle">
                          <CalendarMonthIcon />
                        </i>
                      </IconButton>
                    </div>
                    {/* </button> */}
                  </div>
                </form>

                {showDatePicker && (
                  <div style={datePickerStyle}>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                      maxDate={new Date()}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          {/* <DashboardCountCard /> */}

          <Row>
            {/* {[
              { title: MyJobs.title, ...MyJobs },
              { title: JobInvitationData.title, ...JobInvitationData },
              { title: Interviews.title, ...Interviews },
              { title: Offers.title, ...Offers },
              { title: Contracts.title, ...Contracts },
            ].map((item, index) => (
              <Col key={index} xl={3} md={2} sm={6}>
                <DashboardCountCard
                 CircularProgress={item.}
                  title={item.title}
                  options={options}
                  {...item}
                />
              </Col>
            ))} */}
            <Col xl={3} md={2} sm={6} onClick={() => navigate("/client/jobs")}>
              <DashboardCountCard
                CircularProgress={MyJobs.percentage}
                title={MyJobs.title}
                options={options}
                {...MyJobs}
              />
            </Col>
            <Col
              xl={3}
              md={2}
              sm={6}
              onClick={() => navigate("/client/invitations")}
            >
              <DashboardCountCard
                rank={JobInvitationData.rank}
                CircularProgress={JobInvitationData.percentage}
                title={JobInvitationData.title}
                options={options}
                {...JobInvitationData}
              />
            </Col>

            <Col
              xl={3}
              md={2}
              sm={6}
              onClick={() => navigate("/client/interviews")}
            >
              <DashboardCountCard
                CircularProgress={Interviews.percentage}
                title={Interviews.title}
                options={options}
                //decimal={1}
                {...Interviews}
              />
            </Col>

            <Col
              xl={3}
              md={2}
              sm={6}
              onClick={() => navigate("/client/contracts")}
            >
              <DashboardCountCard
                CircularProgress={Contracts.percentage}
                title={Contracts.title}
                options={options}
                {...Contracts}
              />
            </Col>

            <Col
              xl={3}
              md={2}
              sm={6}
              onClick={() => navigate("/client/offers")}
            >
              <DashboardCountCard
                CircularProgress={Offers.percentage}
                title={Offers.title}
                options={options}
                {...Offers}
              />
            </Col>
          </Row>
          {graphData && graphData.length != 0 && (
            <Row>
              <Col xl={12} className="">
                {/* <h5 className="text-primary">
                {dashboard_chart_title}&nbsp;&nbsp;
                <span className="font-size-12 text-muted mt-2">
                  <i className="fas fa-info-circle" /> {dashboard_chart_sub_title}.
                </span>
              </h5> */}
                <ApexChart
                  options={apexoptions}
                  series={series}
                  userType={userType}
                />
              </Col>
              {/* <Col xl={5}>
              <PieChart options={pieoptions} series={pieseries} />
            </Col>  */}
            </Row>
          )}
          <Card className="p-2">
            <Row>
              <ClientDashboardTabs
                startdate={formattedStartDate}
                enddate={formattedEndDate}
              />
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClientDashboard;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { Link } from "react-router-dom";
import { getIndividualAssessments, getJobsCount } from "./individualSlice";
import CanvasAssessment from "../customComponents/offcanvas/CanvasAssessment";
import { log_debug } from "../apiHelpers/logger";
import dayjs from "dayjs";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import moment from "moment/moment";
import StatusBadge from "../customComponents/utilities/StatusBadge";

function IndividualAssessments() {
  const individualStore = useSelector((state) => state.individualSlice);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const dispatch = useDispatch();
  const [assessmentdata, setAssessmentData] = useState([]);
  const [loading, setloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [RecordCount, setRecordCount] = useState(0);
  const [filtervalue, setFiltervalue] = useState("");
  const [TableLoading, setTableLoading] = useState(false);
  const defaultStartDate = new Date();

  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getJobsCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  const yourDynamicHeaderData = {
    role: "Role",
    score: "Score",
    status: "Status",
  };

  const JobRole = (role) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedRole(role);
    setOffcanvasOpen(true);
  };

  const columns = [
    {
      Header: "Job Title",
      accessor: "role",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            JobRole(row.original);
          }}
        >
          {row.values.role}
        </Link>
      ),
    },
    {
      Header: "Score",
      accessor: "score",
      Cell: ({ row }) =>
        row.original.score && row.original.status === "completed" ? (
          <div style={{ width: "35px", height: "35px" }} className="m-auto">
            {" "}
            <CircularProgressbarWithChildren
              styles={{
                root: {}, // Adjust these values
                path: {
                  stroke: "#5156be",
                  strokeLinecap: "round",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                  pathColor: "#74788d",
                },
                trail: {
                  stroke: "#d6d6d6",
                  strokeLinecap: "round",
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
              }}
              value={(row.original.score / 10) * 100}
            >
              <div style={{ fontSize: 10 }}>
                <strong>{((row.original.score / 10) * 100).toFixed(0)}</strong>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        ) : (
          "-"
        ),
    },
    // {
    //   Header: 'Posted on',
    //   accessor: 'createdAt',
    //   Cell: (props) => {
    //    const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
    //    return <span>{tidyDate}</span>;
    //  },
    // },

    {
      Header: "Start Date",
      accessor: "createdAt",
      Cell: ({ row }) => (
        <span>{moment(row.original.createdAt).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      Header: "End Date",
      accessor: "completedAt",
      Cell: ({ row }) => (
        <span>
          {row.original.status === "completed"
            ? moment(row.original.completedAt).format("YYYY-MM-DD")
            : "-"}
        </span>
      ),
    },
    {
      Header: "Status",
      accessor: "status",

      Cell: ({ value }) => (
        // <Badge
        //   color={
        //     value === "completed" || value === "active"
        //       ? "success rounded-pill "
        //       : "warning rounded-pill "
        //   }
        //   className="m-1 font-size-12"
        // >
        //   {value}
        // </Badge>
        <StatusBadge status={value} />
      ),
    },
    // {
    //   Header: "Jobs",
    //   accessor: "",
    // },
    // {
    //   Header: "Actions",
    //   accessor: "",
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getIndividualAssessments({ filtervalue }));
    }, 700);

    return () => clearTimeout(timer);
  }, [filtervalue]);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.assessmentData?.success === true) {
        setAssessmentData(
          individualStore.data.assessmentData?.data?.assessments,
        );
        setRecordCount(individualStore.data.assessmentData?.data?.totalCount);
        setTableLoading(false);
        log_debug("assessment", assessmentdata);
      }
      setloading(false);
    } else if (individualStore.status === "loading") {
      setloading(true);
      setTableLoading(true);
    }
  }, [individualStore.data.assessmentData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setTableLoading(true);
    setFiltervalue(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Assessments"
            Name="Assessments"
          />
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <CardBody>
                  <Row>
                    <div className="col-12 col-lg-6 col-md-6 d-sm-flex" />
                    <div className="col-12 col-lg-6 col-md-6">
                      <Col md={12}>
                        <CardTitle className="text-end fs-6 pt-3">
                          Total Assessments : {RecordCount}
                        </CardTitle>
                      </Col>
                    </div>
                  </Row>
                </CardBody>

                <div>
                  <Card>
                    <CardBody className="">
                      <CustomTable
                        newAssessment={true}
                        tableDatas={assessmentdata}
                        headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={assessmentdata}
                        hideCheckbox={true}
                        isLoading={TableLoading}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        ShowPagination={
                          assessmentdata && assessmentdata.length > 10
                            ? true
                            : false
                        }
                        totalCount={RecordCount}
                        globalsearch={true}
                        onChange={handleValueChange}
                      />
                    </CardBody>
                  </Card>
                  {/* <DataTable title="All Jobs" columns={columns} data={jobs} /> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <CanvasAssessment
          usertype="individual"
          menuOption="assessment"
          width={60}
          placement="end"
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onclose={closeOffcanvas}
          //data={selectedRole}
          guid={selectedRole.guid}
          data={selectedRole}
          mcqDetails={[]}
          codingDetails={[]}
          behaviouralDetails={[]}
          showTIQCard={true}
        />
      )}
    </React.Fragment>
  );
}

export default IndividualAssessments;

import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Row, Button, Badge } from "reactstrap";
import CountUp from "react-countup";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { log_debug } from "../apiHelpers/logger";
const DashboardCountCard = ({
  title,
  value,
  CircularProgress,
  series,
  statusColor,
  rank,
  key,
  backgroundColor,
  options,
  decimal,
  redirectTo,
  hideCircularBar,
}) => {
  const [decimals, setDecimals] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (decimal > 0) {
      setDecimals(decimal);
    }
  });

  const handleClick = () => {
    if (redirectTo) {
      navigate(redirectTo);
    }
  };

  useEffect(() => {
    log_debug("tiq score ", CircularProgress);
  }, [CircularProgress]);

  return (
    <>
      <Card
        className={
          title === "TIQ Score" || title === "Contracts"
            ? "card-h-100 bg-light shadow-sm"
            : "card-h-100"
        }
        onClick={handleClick}
        style={{ cursor: "pointer", backgroundColor }}
      >
        <CardBody>
          <Row>
            <Col lg={5}>
              <div className="d-flex">
                <div>
                  <span className="mb-4 lh-1 d-block text-truncate">
                    <strong>{title}</strong>
                  </span>
                </div>
                {/* <div className="mx-auto"><button color="link" size="sm" className=" btn btn-outline-primary text-muted  mx-auto">
                  View
                </button>
                </div> */}
              </div>
              <h5 className="mb-1 text-muted">
                <span className="counter-value">
                  <CountUp
                    start={0}
                    end={value}
                    duration={2}
                    separator=""
                    decimals={decimals}
                  />
                </span>
              </h5>
            </Col>
            <Col lg={7}>
              <div>
                <span className="mb-1 lh-1 d-block text-truncate">
                  <Badge className="bg-primary rounded-pill my-1">{rank}</Badge>
                </span>
              </div>

              {!hideCircularBar && (
                <div
                  className="text-end"
                  style={{ width: "70%", height: "50px" }}
                >
                  <div className="d-flex justify-content-end w-50">
                    <div>
                      <CircularProgressbarWithChildren
                        value={CircularProgress}
                        styles={{
                          path: { stroke: "#5156BE" }, // Set the primary color to blue (#007BFF)
                          trail: { stroke: "#d6d6d6" }, // Customize the trail color if needed
                        }}
                      >
                        <div style={{ fontSize: 11 }}>
                          <strong>
                            {CircularProgress ? CircularProgress : 0}%
                          </strong>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default DashboardCountCard;

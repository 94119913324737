import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardText,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DashboardCounter, addCandidateResume } from "./PartnerSlice";
import dropzoneimage2 from "../../src/assets/images/companyBenchImage/icons8-upload-to-the-cloud.gif";
import { log_debug } from "../apiHelpers/logger";
import Spinner from "../customComponents/utilities/Spinner";
import { toast } from "react-toastify";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import moment from "moment";

// // Define a simple Spinner component
// const Spinner = () => (
//   <div style={spinnerContainerStyle}>
//     <div className="spinner-border text-primary" role="status">
//       <span className="sr-only">Loading...</span>
//     </div>
//     <p style={animatedTextStyle}>Resume parsing 1...</p>
//     <p style={animatedTextStyle}>Resume parsing 2...</p>
//     <p style={animatedTextStyle}>Resume parsing 3...</p>
//   </div>
// );

// // Inline styles
// const spinnerContainerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
// };

// const animatedTextStyle = {
//   animation: 'fadeInOut 1.5s infinite',
// };

// // Add corresponding CSS styles to your stylesheet
// // (Include this part in your CSS or SCSS file)
// const globalStyles = `
//   @keyframes fadeInOut {
//     0%, 100% {
//       opacity: 0.5;
//     }
//     50% {
//       opacity: 1;
//     }
//   }
// `;

// // Apply global styles (for keyframes) using a style tag
// const styleTag = document.createElement('style');
// styleTag.type = 'text/css';
// styleTag.appendChild(document.createTextNode(globalStyles));
// document.head.appendChild(styleTag);

function AddCandidate() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [aggregateResponse, setAggregateResponse] = useState({});
  const [aggregateResponses, setAggregateResponses] = useState([]);
  const [individualResumes, setIndividualResumes] = useState([]);
  const [FileUploadAgain, setFileUploadAgain] = useState(false);
  const defaultEndDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 90);

  const [formattedStartDate, setformattedStartDate] = useState(
    // moment(defaultStartDate).format("YYYY-MM-DD"),
    moment(defaultStartDate).format("YYYY-MM-DD"),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      uploadedFiles.length === 4 &&
      uploadedFiles.every((file) => file.status === "success")
    ) {
      setUploading(false);
      const responses = uploadedFiles.reduce((acc, file) => {
        acc[file.name] = file.status;
        return acc;
      }, {});
      setAggregateResponse(responses);
      log_debug("All files uploaded successfully:", responses);
    }
  }, [uploadedFiles]);

  // const uploadFilesSequentially = async (filteredFiles) => {
  //   setUploading(true);

  //   const allResponses = [];

  //   for (const file of filteredFiles) {
  //     try {
  //       const formData = new FormData();
  //       formData.append("resumes", file);

  //       const response = await dispatch(addCandidateResume(formData));

  //       setUploadedFiles((prevFiles) => [
  //         ...prevFiles,
  //         {
  //           name: file.name,
  //           status: "success",
  //         },
  //       ]);

  //       const fileResponse = {
  //         fileName: file.name,
  //         status: "success",
  //         data: response?.payload,
  //       };
  //       allResponses.push(fileResponse);
  //     } catch (error) {
  //       setUploadedFiles((prevFiles) => [
  //         ...prevFiles,
  //         {
  //           name: file.name,
  //           status: "error",
  //         },
  //       ]);

  //       const fileResponse = {
  //         fileName: file.name,
  //         status: "error",
  //         error: error.message,
  //       };
  //       allResponses.push(fileResponse);
  //     }
  //   }

  //  log_debug("Responses for all files:", allResponses);

  //   setAggregateResponses(allResponses);

  //   if (allResponses.length >= filteredFiles.length) {
  //     setIndividualResumes(allResponses);
  //     navigate("/candidatedetails", {
  //       state: { individualResumes: allResponses },
  //     });
  //   }
  // };

  const uploadFilesSequentially = async (filteredFiles) => {
    setUploading(true);

    const allResponses = [];
    let successfulResponsesCount = 0;
    let unsuccessfulResponsesCount = 0;

    for (const file of filteredFiles) {
      try {
        log_debug("recieved add candidate file", file);
        const formData = new FormData();
        formData.append("resumes", file);

        const response = await dispatch(addCandidateResume(formData));

        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            name: file.name,
            status: "success",
          },
        ]);

        const fileResponse = {
          fileName: file.name,
          status: "success",
          candidates : response?.payload,
        };
        allResponses.push(fileResponse);
        successfulResponsesCount++;
      } catch (error) {
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            name: file.name,
            status: "error",
          },
        ]);

        const fileResponse = {
          fileName: file.name,
          status: "error",
          error: error.message,
        };
        allResponses.push(fileResponse);
        unsuccessfulResponsesCount++;
      }
    }

    // Once all files are processed, you can access the aggregated responses
    log_debug("allResponses", allResponses);

    setAggregateResponses(allResponses);

    log_debug(`Successful Responses: ${successfulResponsesCount}`);
    log_debug(`Unsuccessful Responses: ${unsuccessfulResponsesCount}`);

    if (
      // (allResponses.length >= filteredFiles.length) ||
      allResponses[0]?.candidates?.data?.resumes ||
      allResponses[1]?.candidates?.data?.resumes ||
      allResponses[2]?.candidates?.data?.resumes ||
      allResponses[3]?.candidates?.data?.resumes
    ) {
      setIndividualResumes(allResponses);
      dispatch(
        DashboardCounter({
          from_date: formattedStartDate,
          to_date: formattedEndDate,
        }),
      );
      navigate("/candidatedetails", {
        state: { individualResumes: allResponses },
      });
    } else {
      setFileUploadAgain(true);
      setUploading(false);
      if (successfulResponsesCount >= 1) {
        toast.error(
          "This candidate has already been affiliated with another partner",
        );
        // setTimeout(() => {
        //   window.location.reload(); // Reload the entire page
        // }, 5000);
      }
    }
  };

  const onDrop = async (acceptedFiles) => {
    setFileUploadAgain(true);
    const remainingSlots = 1 - uploadedFiles.length;
    const validFiles = acceptedFiles.slice(0, remainingSlots);

    const filteredFiles = validFiles.filter(
      (file) =>
        file.name.endsWith(".pdf") ||
        file.name.endsWith(".docx") ||
        file.name.endsWith(".doc"),
    );

    uploadFilesSequentially(filteredFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf, .docx, .doc",
  });

  const handelresumeckick = () => {
    // if (FileUploadAgain) {
    //   setUploading(!true);
    //   window.location.reload(); // Reload the page
    // }
  };
  const breadcrumbValues = ["Candidates", "Add Candidate"];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div>
            <div className="col-lg-12">
              <CustomBreadcrumb values={breadcrumbValues} />

              <Col className="col-lg-12 col-md-12 col-sm-12 ">
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card
                      style={{
                        width: "60%",
                        height: "auto",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <CardHeader
                        className="text-center bg-primary-subtle font-size-22"
                        style={{ height: "100px" }}
                      >
                        <strong className="text-white">Upload</strong>
                        <span className="text-muted"> files</span>
                      </CardHeader>
                      <CardBody onClick={handelresumeckick}>
                        <CardText className="mb-0">
                          <div className="">
                            <div
                              {...getRootProps()}
                              className={` dropzone ${
                                isDragActive ? "active" : ""
                              }`}
                            >
                              <input {...getInputProps()} />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                {uploading ? (
                                  <Spinner />
                                ) : (
                                  <>
                                    <p style={{ marginBottom: "8px" }}>
                                      <strong>Drag and drop </strong> files here{" "}
                                      <br /> or <strong>browser</strong> to
                                      begin the upload. Accept
                                      .pdf, .docx, .doc Only.
                                    </p>

                                    <img
                                      src={dropzoneimage2}
                                      style={{ width: "70px" }}
                                      alt="Dropzone Image"
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </CardText>
                        <div className="py-4">
                          <p className=" bg-info  px-2 rounded py-3">
                            By uploading this resume, you confirm that you have
                            obtained the necessary consent from the candidate.
                            Please be assured that all information provided will
                            be used solely for recruitment purposes and will not
                            be shared or used elsewhere.
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AddCandidate;

import React, { useState } from "react";
import "./ConsultantCard.css"; // Import CSS file for styling
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom"; // Assuming you're using React Router
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Companybenchlogo from "../../src/assets/images/companyBenchImage/Company-bench-v2.png";
import { log_debug } from "../apiHelpers/logger";
import { dateyyyymmdd_tohtml } from "../helpers/date";

const ConsultantCard = ({ consultant, userType }) => {
  // const {
  //   name,
  //   designation,
  //   workLocation,
  //   term,
  //   startDate,
  //   endDate,
  //   rate,
  //   contractId,
  //   billingCycle,
  //   status,
  // } = consultant;

  log_debug("consultant", consultant);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    // <div className="consultant-card">
    //   <div className="column">
    //     <label htmlFor="contractId">Contract ID</label>
    //     <input
    //       id="contractId"
    //       className="input-field"
    //       type="text"
    //       value={contractId && contractId}
    //       readOnly
    //     />
    //     <label htmlFor="name">Name</label>
    //     <input
    //       id="name"
    //       className="input-field"
    //       type="text"
    //       value={name}
    //       readOnly
    //     />
    //     <label htmlFor="designation">Designation</label>
    //     <input
    //       id="designation"
    //       className="input-field"
    //       type="text"
    //       value={designation}
    //       readOnly
    //     />
    //     <label htmlFor="workLocation">Work Location</label>
    //     <input
    //       id="workLocation"
    //       className="input-field"
    //       type="text"
    //       value={workLocation}
    //       readOnly
    //     />
    //   </div>
    //   <div className="column">
    //     <label htmlFor="status">Status</label>
    //     <input
    //       id="status"
    //       className="input-field"
    //       type="text"
    //       value={status}
    //       readOnly
    //     />
    //     <label htmlFor="startDate">Start Date</label>
    //     <input
    //       id="startDate"
    //       className="input-field"
    //       type="text"
    //       value={startDate}
    //       readOnly
    //     />
    //     <label htmlFor="endDate">End Date</label>
    //     <input
    //       id="endDate"
    //       className="input-field"
    //       type="text"
    //       value={endDate}
    //       readOnly
    //     />
    //   </div>
    //   <div className="column">
    //     <label htmlFor="rate">Rate (INR per Month)</label>
    //     <input
    //       id="rate"
    //       className="input-field"
    //       type="text"
    //       value={rate && rate}
    //       readOnly
    //     />
    //     <label htmlFor="term">Term</label>
    //     <input
    //       id="term"
    //       className="input-field"
    //       type="text"
    //       value={term}
    //       readOnly
    //     />
    //     <label htmlFor="billingCycle">Billing Cycle</label>
    //     <input
    //       id="billingCycle"
    //       className="input-field"
    //       type="text"
    //       value={billingCycle && billingCycle}
    //       readOnly
    //     />
    //   </div>
    // </div>
    <>
      <div className="row consultant-card">
        {/* <div className=" row">
          <div className=" col-11">
            <Link
              to={"#"}
              color="primary"
              type="button"
              className="float-end  btn btn-primary text-white rounded"
             
            >
              <FiEdit className="mx-1" />
            </Link>
          </div>
        </div> */}
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <label htmlFor="contractId">Contract ID </label>
              </div>

              <div className="col-8 d-flex">
                {`:   ${consultant?.contract_id
                  .split("-")[0]
                  .toUpperCase()} - ${consultant?.contract_id
                  .split("-")[1]
                  .toUpperCase()}`}
              </div>
            </div>
            {userType !== "partnerIndividual" && (
              <div className="row">
                <div className="col-4">
                  <label htmlFor="name">Name</label>
                </div>

                <div className="col-8 d-flex">
                  : &nbsp;&nbsp;{consultant?.individuals[0]?.full_name}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-4">
                <label htmlFor="designation">Designation</label>
              </div>

              <div className="col-8 d-flex">
                : &nbsp;&nbsp;
                {(consultant?.individuals[0]?.designation &&
                  consultant?.individuals[0]?.designation) ||
                  consultant?.job_role}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label htmlFor="workLocation">Work Location</label>
              </div>

              <div className="col-8 d-flex">
                : &nbsp;&nbsp;{consultant?.work_location}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label htmlFor="status">Status</label>
              </div>

              <div className="col-8 d-flex">
                : &nbsp;&nbsp;{consultant?.status}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label htmlFor="startDate">Start Date</label>
              </div>

              <div className="col-8 d-flex">
                : &nbsp;&nbsp;{dateyyyymmdd_tohtml(consultant?.started_at)}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label htmlFor="endDate">End Date</label>
              </div>

              <div className="col-8 d-flex">
                : &nbsp;&nbsp;
                {consultant?.ended_at &&
                  dateyyyymmdd_tohtml(consultant?.ended_at)}
              </div>
            </div>
            {userType !== "partnerIndividual" && (
              <div className="row">
                <div className="col-4">
                  <label htmlFor="rate">Rate (In INR)</label>
                </div>

                <div className="col-8 d-flex">
                  : &nbsp;&nbsp;<i className=" mdi mdi-currency-inr"></i>
                  {consultant?.contract_value && consultant?.contract_value}
                </div>
              </div>
            )}

            {userType !== "partnerIndividual" && (
              <div className="row">
                <div className="col-4">
                  <label htmlFor="billingCycle">Billing Cycle</label>
                </div>

                <div className="col-8 d-flex">
                  : &nbsp;&nbsp;
                  {consultant?.billing_cycle && consultant?.billing_cycle}
                </div>
              </div>
            )}
            <div className="row ">
              <div className="col-4">
                <label htmlFor="term">Contract of Employment</label>
              </div>

              <div className="col-8 d-flex ">
                : &nbsp;&nbsp;
                <Link to={"#"} onClick={toggleModal}>
                  Contract of employment
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          style={{ maxWidth: "60%" }}
        >
          <ModalHeader toggle={toggleModal}>
            {/* CONTRACT OF EMPLOYMENT */}
            <div className="text-center">
              <img
                src={Companybenchlogo}
                alt="Companybenchlogo"
                style={{ width: "300px" }}
              />
            </div>
          </ModalHeader>
          <ModalBody
            style={{ height: "500px", overflowY: "auto" }}
            className="mb-4"
          >
            <div className="text-center py-5">
              <h1 className="text-muted  ">
                <strong className="border-bottom">
                  CONTRACT OF EMPLOYMENT
                </strong>
              </h1>
              <h6 className="py-3">Entered into by and between</h6>
              <h5>Company Bench</h5>
              <h5>
                Having its Office at SUMMER COURT, 402, Magarpatta, <br />
                Pune, Maharashtra 411028.
              </h5>
              <h5 className="mt-5">
                (Hereinafter referred to as “Company”). <br className="mt-3" />
                <br />
                AND <br />
                <br />
                (Hereinafter referred to as the “Contractor”).
              </h5>
            </div>
            {/* <p className="text-center text-muted">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------
            </p> */}
            <div className="mt-4">
              <h4 className="text-center text-muted py-4">
                CONTRACTORMENT AGREEMENT
              </h4>

              <p>
                This employment agreement (Agreement) is made at Pune on 8th
                January 2024.
              </p>
              <h5 className="text-muted">BY AND BETWEEN:</h5>
              <ol>
                <p>
                  (1) Company Bench, a company having its Office at SUMMER
                  COURT, 402, Magarpatta, Pune, Maharashtra 411028 (hereinafter
                  referred to as the Employer); and
                </p>
                <p>(2) (hereinafter referred to as Contractor).</p>
              </ol>
              <h5 className="text-muted">WHEREAS:</h5>
              <ol>
                <p>
                  (A) The Employer is engaged in the business of, information
                  technology and consulting, services and software; and
                </p>
                <p>
                  (B) The Contractor has represented to the Employer that the
                  Contractor has the requisite knowledge, expertise, experience
                  and skill to render the services as contemplated under this
                  Agreement.
                </p>
                <p>
                  (C) The Employer wishes to use the experience and knowledge of
                  the Contractor and accordingly, the Employer has offered
                  employment to the Contractor.
                </p>
                <p>
                  (D) The Contractor has accepted such employment with the
                  Employer on the terms and conditions contained in this
                  Agreement.
                </p>
                <p>
                  <strong className="text-muted"> NOW THEREFORE,</strong> the
                  Parties in consideration of the mutual agreements, covenants
                  and other premises set forth herein, intending to be legally
                  bound by the terms thereof, hereby agree as follows:
                </p>
              </ol>
              <h5 className="text-muted">1. INTERPRETATION</h5>
              <ol>
                <p>
                  <strong className="text-muted">1.1 </strong> Reference to any
                  statute or statutory provision includes a reference to that
                  statute or statutory provision as amended, extended or
                  re-enacted or consolidated from time to time and all statutory
                  instruments or orders (including delegated legislation whether
                  by way of rules, notifications, bye-laws, guidelines, as
                  amended, extended or re-enacted or consolidated from time to
                  time);
                </p>
                <p>
                  <strong className="text-muted">1.2</strong> Words elsewhere
                  defined or explained in this Agreement shall have the meaning
                  so ascribed;
                </p>
                <p>
                  <strong className="text-muted">1.3 </strong> Words denoting
                  the singular shall include the plural and vice versa;
                </p>
                <p>
                  <strong className="text-muted">1.4 </strong> Words denoting
                  any gender include all genders; and
                </p>
                <p>
                  <strong className="text-muted">1.5 </strong> The headings in
                  this Agreement are inserted for convenience only and shall be
                  ignored in construing this Agreement.
                </p>
              </ol>
              <ol>
                <strong>
                  The “Contractor” means the strongerson who is deployed in
                  Company Bench for employment.
                </strong>
                <p className="py-3">
                  “Working day” means any day of the week, excluding days
                  declared as an official public holiday in the country where
                  the Contractor is deployed.
                </p>
                <p>
                  “Remuneration” Any payment in money or in kind, or in money
                  and kind made or owing to the Contractor by virtue of his/her
                  employment with the Company.
                </p>

                <p>
                  “Effective date” the date of commencement of the Contractor
                  employment with the above company as stipulated in the
                  employment detail section herein.
                </p>
              </ol>
              <h5 className="text-muted">2. APPOINTMENT</h5>
              <ol>
                <p>
                  <strong className="text-muted">2.1</strong> The Contractor
                  shall commence employment with the Employer as on and be bound
                  by the terms and conditions laid down in the Agreement. The
                  Contractor's designation and reporting relationship may
                  undergo a change from time to time as may be determined by the
                  Employer.
                </p>

                <p>
                  <strong className="text-muted">2.2</strong> The Employer may
                  require the Contractor to undergo a medical examination from
                  time to time and submit a medical report certifying the
                  Contractor's fitness in performing the duties and obligations
                  under this Agreement.
                </p>
              </ol>
              <h5 className="text-muted">3. PROBATION</h5>
              <ol>
                <p>
                  <strong className="text-muted">3.1</strong> The Contractor
                  will initially be on probation for a period of 6 months from
                  the Effective Date.
                </p>

                <p>
                  <strong className="text-muted">3.2</strong> The confirmation
                  of service with the Employer is subject to the Contractor's
                  satisfactory performance during the period of probation. The
                  Employer reserves the right to (i) extend the period of
                  probation and (ii) terminate employment at its sole discretion
                  at any time during the probation period by giving 1 month
                  notice or payment in lieu thereof.
                </p>
              </ol>
              <h5 className="text-muted">4. PLACE OF EMPLOYMENT</h5>
              <ol>
                <p>
                  <strong className="text-muted">4.1</strong> The Contractor's
                  principal place of employment shall be at Pune, India. The
                  Contractor may be required to (i) relocate to other locations
                  in India or abroad; and/or (ii) undertake such travel in and
                  outside India, from time to time, as may be necessary in the
                  interests of the Employer's business.
                </p>
              </ol>
              <h5 className="text-muted">5. DUTIES AND RESPONSIBILITIES</h5>
              <ol>
                <p>
                  <strong className="text-muted">5.1</strong> The Contractor is
                  required to carry out all duties that are normally associated
                  with employment in the Employer or for such Group Companies as
                  the Employer may direct. Group Companies for the purpose of
                  this Agreement include the Employer, its subsidiaries or
                  holding companies and any subsidiary of any holding company
                  that may be formed from time to time.
                </p>
                <p>
                  <strong className="text-muted">5.2</strong> The Contractor
                  agrees to comply with the Employer's decision should it
                  consider it necessary or appropriate to change the
                  Contractor's job title, reporting relationships, job duties
                  and responsibilities, the legal entity that employs the
                  Contractor and the jurisdiction where the Contractor is
                  expected to perform Contractor’s duties (despite location of
                  Contractor’s residence) on the basis of the Contractor's
                  performance or the Employer's business requirements.
                </p>
                <p>
                  <strong className="text-muted">5.3</strong> During the
                  subsistence of this Agreement, the Contractor shall devote
                  full working time, attention and energy to the performance of
                  the duties assigned to the Contractor. The Contractor shall
                  not, whether directly or indirectly, be employed, engaged,
                  concerned or interested in any manner whatsoever in any trade,
                  business or profession other than the business of the Employer
                  or accept any appointment to any office (including
                  directorships) whether for gain or otherwise without the
                  written consent of the Employer. Should the Contractor receive
                  written consent under this clause to conduct any such external
                  activity, the Contractor shall not utilize the assets,
                  resources and time of the Employer for such external
                  activities.The Contractor shall, at all times, be subject to
                  and bound by the policies, rules and regulations of the
                  Employer as may be updated from time to time unilaterally by
                  the Employer or as may be brought to the notice of the
                  Contractor by the Employer.
                </p>
                <p>
                  <strong className="text-muted">5.4 </strong> The Contractor
                  shall not have the right or the authority to make any
                  representation, contract or commitment for or on behalf of the
                  Employer without obtaining the prior written permission of the
                  Employer in this regard. The Contractor shall have no
                  authority, implied or otherwise, to pledge the credit of the
                  Employer. In the event the Employer is held liable for any
                  damage, loss, claim or action arising directly or indirectly
                  from any action of the Contractor in violation of this clause,
                  the Contractor shall indemnify the Employer to the maximum
                  extent permissible under the laws for the time being in force
                  in India.
                </p>
              </ol>
              <h5 className="text-muted">6. WORKING HOURS</h5>
              <ol>
                <p>
                  The Contractor shall work 40 hours per week based on a 5 day
                  working week. If necessary, the Contractor shall work such
                  additional hours as may be necessary for the Contractor to
                  perform the duties effectively and otherwise in accordance
                  with the Employer's policies pertaining to the same.
                </p>
              </ol>
              <h5 className="text-muted">7. COMPENSATION AND BENEFITS</h5>
              <ol>
                <p>
                  <strong className="text-muted">7.1 </strong> In consideration
                  of the services to be rendered by the Contractor to the
                  Employer the Contractor shall receive gross remuneration
                  subject to deduction of tax at source.{" "}
                  <strong>
                    {" "}
                    You will be entitled for CTC as INR per Month (TDS
                    applicable). This remuneration shall be paid to the
                    Contractor by 10th of each month.{" "}
                  </strong>
                  The detailed breakdown of annual gross remuneration and
                  particulars of other emoluments, allowances, benefits are
                  annexed as Schedule 2 hereto. The Contractor will be solely
                  liable for the Contractor’s personal tax liabilities and the
                  Employer makes no assurances that the remuneration has been
                  structured in the most tax efficient manner or tax treatment
                  applicable to certain emoluments, allowances or benefits will
                  continue for the entire term of employment. The breakdown of
                  the remuneration may need to be revised from time to time in
                  keeping with regulatory developments or otherwise, and the
                  Employer will not be liable for any additional tax liability
                  the Contractor may face due to such revisions.
                </p>
                <p>
                  <strong className="text-muted">7.2 </strong>The Contractor's
                  compensation will be reviewed annually by the Employer and any
                  increase will be discretionary and subject to and on the basis
                  of effective performance and Employer results during the
                  period.
                </p>
              </ol>
              <>
                <h5 className="text-muted">8. LEAVE</h5>
                <ol>
                  <p>
                    The Contractor shall be entitled to leave in accordance with
                    the Employer's policy as amended from time to time. No any
                    Paid or Public/National leaves are entitled to you by the
                    employer.
                  </p>
                </ol>
                <h5 className="text-muted">9. EXPENSES</h5>
                <ol>
                  {" "}
                  <p>
                    The Contractor is authorized to incur reasonable and
                    necessary expenses in business related travels, lodging and
                    other expenses in the performance of the duties under this
                    Agreement, provided such expenses are substantiated. The
                    Employer shall pay or reimburse such expenses to the extent
                    the Contractor submits vouchers or other documentation in
                    accordance with the Employer's policy. No personal expenses
                    of the Contractor shall be borne or reimbursed by the
                    Employer.
                  </p>
                </ol>
                <h5 className="text-muted">10. DEDUCTIONS</h5>
                <ol>
                  <p>
                    The Contractor agrees and acknowledges that the Employer
                    may, subject to applicable laws, at any time during the term
                    of employment or cessation thereof, deduct from the
                    Contractor's salary, or final settlement, any amounts owed
                    by the Contractor, including but not limited to, any
                    outstanding loans, advances, overpayments or costs incurred
                    by the Employer due to any damage or loss to Employer
                    property, etc. caused by the Contractor.
                  </p>
                </ol>
                <h5 className="text-muted">11. CONTRACTOR SURVEILLANCE</h5>
                <ol>
                  <p>
                    <strong className="text-muted">11.1</strong> The Contractor
                    agrees and understands that the Employer may use various
                    modes to ensure that the internet, email facilities and
                    other communication systems provided by the Employer are
                    used in an appropriate manner. These may include the
                    scanning, reading, inspection, scrutiny of emails sent and
                    received and web sites visited or created by the Contractor.
                    The Contractor acknowledges that the Contractor does not
                    have any expectation of privacy when using the Employer's
                    resources. For the avoidance of doubt, and for the limited
                    purpose of safeguarding the Employer's confidential and
                    proprietary information, the Employer shall have the right
                    to monitor and any personal email or social media forum that
                    may be accessible to the Contractor from the Employer
                    including but not limited to Gmail, AOL, Hotmail, Yahoo,
                    Facebook, Myspace, Twitter, etc.
                  </p>

                  <p>
                    <strong className="text-muted">11.2</strong> The Employer
                    also reserves the right to monitor its Contractors using
                    various security measures including but not limited to
                    closed circuit television systems. These may be installed on
                    the Employer's premises overtly or covertly to ensure that
                    the Contractors do not participate or propagate any
                    activities which are or could be prejudicial to the
                    Employer's business interests or which could bring it into
                    disrepute.
                  </p>
                </ol>
                <h5 className="text-muted">12. TERM AND TERMINATION</h5>
                <ol>
                  <p>
                    <strong>12.1 Term</strong>
                    <br />
                    The Contractor's employment with the Employer will commence
                    on the Effective Date and shall continue until terminated in
                    accordance with this Clause 12.
                  </p>
                </ol>

                <ol type="a">
                  <strong> 12.2 Termination with immediate effect:</strong>
                  <li>
                    <p>
                      (i) Notwithstanding anything contained in this Agreement,
                      the Employer may terminate the employment contemplated
                      under this Agreement at any time without notice or payment
                      in lieu of notice, for cause including but not limited to:
                      <ol type="A">
                        <li>
                          {" "}
                          repeated failure to comply with lawful directions of
                          the Employer and its officers;
                        </li>
                        <li>
                          {" "}
                          breach of the terms of this Agreement and breach of
                          representations and warranties contained herein;
                        </li>
                        <li> unethical business conduct;</li>
                        <li>
                          {" "}
                          breach of any statutory duty or for any act or
                          omission adversely affecting the goodwill, reputation,
                          credit, operations or business of the Employer;
                        </li>
                        <li>
                          {" "}
                          habitual unauthorized absence or unauthorized absence
                          for a period exceeding [5] days;
                        </li>
                        <li>
                          {" "}
                          fraud, misappropriation or dishonesty in respect of
                          the Employer's property or business;
                        </li>
                        <li>
                          {" "}
                          commission of any act not in conformity with
                          discipline or good behavior or acceptance or offering
                          of illegal gratification;
                        </li>
                        <li>
                          {" "}
                          habitual neglect of work or gross or habitual
                          negligence in performance of the Contractor's duties;
                          and
                        </li>
                        <li>
                          {" "}
                          Unauthorized disclosure of any confidential
                          information of the Employer.
                        </li>
                      </ol>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Voluntary Resignation</strong>
                      <br />
                      The Contractor may voluntarily resign from employment with
                      the Employer at any time on giving prior written notice of
                      1 month to the Employer. Any resignation would have to be
                      accepted by the Employer to become effective. Once
                      accepted, the resignation cannot be withdrawn by the
                      Contractor without the prior written consent of the
                      Employer.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Termination for ill-health</strong>
                      <br />
                      If the Contractor, at any time, is prevented from
                      ill-health or accident or any physical or mental
                      disability from performing his duties hereunder, the
                      Contractor shall inform the Employer and supply it with
                      such details as it may be required and if the Contractor
                      is unable to resume because of ill-health or accident or
                      disability, for a period of more than 1 month or more to
                      perform its duties hereunder, the Employer may forthwith
                      terminate this Agreement.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Termination without Cause</strong>
                      <br />
                      Notwithstanding anything to the contrary herein contained,
                      the Employer shall be entitled to terminate the employment
                      under this Agreement at any time by giving the Contractor
                      1 months' notice in writing or payment of basic salary in
                      lieu thereof.
                      <br />
                      <br />
                      In the event of termination by the Employer under Clause
                      12.2 (d) or in case of a resignation by the Contractor as
                      per Clause 12.2 (b), the Employer may require the
                      Contractor to absent from its premises on garden leave and
                      not participate in the working of the Employer during the
                      unexpired portion of the notice period. During such garden
                      leave the Employer may require the Contractor to have no
                      contact with all or any of the Employer's or Group
                      Companies' agents, Contractors, customers, clients,
                      distributors and suppliers.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Retirement</strong>
                      <br />
                      The retirement age for an Contractor of the Employer is 60
                      years. If the Contractor is still in employment with the
                      Employer at the time the Contractor reaches 60 years, the
                      employment shall terminate on Contractor’s 60th birthday.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Handover</strong>
                      <br />
                      Upon the termination of this Agreement for any reason, the
                      Contractor shall immediately hand over responsibilities to
                      such person nominated for that purpose by the Employer and
                      shall deliver to such person all such papers, documents
                      and other property of the Employer and the Group Companies
                      as may be in the possession, custody, control or power,
                      including but not limited to any phones, computers,
                      vehicles, etc. provided by the Employer.
                    </p>
                  </li>
                </ol>
                <h5 className="text-muted">
                  13. CONFIDENTIALITY AND INTELLECTUAL PROPERTY
                </h5>
                <ol type="a">
                  <p>
                    The Contractor agrees and undertakes that:
                    <br />
                  </p>
                  <li>
                    <p>
                      The Contractor shall not during the term of this Agreement
                      or thereafter, divulge or make use of any trade secret or
                      confidential information concerning the business of the
                      Employer and the Group Companies or any of their dealings,
                      transactions and affairs or any information concerning any
                      of their suppliers, agents, distributors or customers
                      which the Contractor possesses or comes to possess while
                      in the employment of the Employer or which the Contractor
                      may make or discover while in the service of the Employer
                      and that the Contractor shall also use the best endeavors
                      to prevent any other person from doing so.
                    </p>
                  </li>
                  <li>
                    <p>
                      all data, documents, plans, drawings, photographs,
                      reports, statements, correspondence, etc. and technical
                      information, know-how and instructions as well as business
                      details or commercial policies that pass to the Contractor
                      or which come to the Contractor’s knowledge shall be
                      treated as confidential and the Contractor shall be bound
                      to keep secret all such confidential matters including
                      papers and documents, computer floppies, CDs or other
                      media containing the same and shall not disclose,
                      communicate, reproduce or distribute the same or copies
                      thereof to anyone except in the course of the rightful
                      discharge of the duties towards the Employer.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Contractor shall not at any time after the termination
                      of this Agreement in any way make known or divulge to any
                      person, firm or body corporate any of the methods, systems
                      or other information of any kind in relation to the
                      affairs of the Employer or the Group Companies whether
                      such information is or was acquired by the Contractor
                      before commencement of employment with the Employer, in
                      the course of employment hereunder or otherwise.
                    </p>
                  </li>
                  <li>
                    <p>
                      ownership of, and all right, title, and interest in, all
                      work product, improvements, developments, discoveries,
                      proprietary information, trademarks, trade names, logos,
                      artwork, slogans, know-how, processes, methods, trade
                      secrets, source code, application development, designs,
                      drawings, plans, business plans or models, blueprints
                      (whether or not registrable and whether or not design
                      rights subsist in them), utility models, works in which
                      copyright may subsist (including computer software and
                      preparatory and design materials thereof), inventions
                      (whether patentable or not, and whether or not patent
                      protection has been applied for or granted) and all other
                      intellectual property throughout the world, in and for all
                      languages, including but not limited to computer and human
                      languages developed or created from time to time by or for
                      the Employer or the Group Companies by the Contractor,
                      whether before or after commencement of employment with
                      the Employer (the Intellectual Property) shall vest in the
                      Employer.
                    </p>
                  </li>
                  <li>
                    <p>
                      all Intellectual Property created by the Contractor shall
                      be regarded as having been made under a contract of
                      service.
                    </p>
                  </li>
                  <li>
                    <p>
                      in consideration of the employment with the Employer, the
                      Contractor hereby transfers and assigns in favor of the
                      Employer, all rights, title and interest in and to all the
                      Intellectual Property, together with the rights to
                      sublicense or transfer any and all rights assigned
                      hereunder to third parties, in perpetuity. The Contractor
                      agrees that such assignment shall be perpetual, worldwide
                      and royalty free.
                    </p>
                  </li>
                  <li>
                    <p>
                      notwithstanding the provisions of Section 19(4) of the
                      Copyright Act, 1957, such assignment in so far as it
                      relates to copyrightable material shall not lapse nor the
                      rights transferred therein revert to the Contractor, even
                      if the Employer does not exercise the rights under the
                      assignment within a period of one year from the date of
                      assignment. The Contractor further acknowledges and agrees
                      that the Contractor shall waive any right to and shall not
                      raise any objection or claims to the Copyright Board with
                      respect to the assignment, pursuant to Section 19A of the
                      Copyright Act, 1957. The Contractor also agrees to assist
                      and cooperate with the Employer in perfecting the
                      Employer's rights in the Intellectual Property.
                    </p>
                  </li>
                  {/* Other confidentiality clauses */}
                </ol>
                <h5 className="text-muted">14. NON-COMPETITION</h5>
                <ol>
                  <p>
                    The Contractor hereby agrees that, during the duration of
                    the Contractor’s employment under this Agreement and for a
                    period of 6 months thereafter, the Contractor will not,
                    whether in India or elsewhere in the world, compete,
                    directly or indirectly with, or own, manage, operate,
                    control or participate in or be connected with the
                    ownership, management, operation or control of any business
                    that is similar to or in competition with the business of
                    the Employer or any parent company of the Employer.
                  </p>
                  <strong>NON-SOLICITATION</strong> <br />
                  <br />
                  <p>
                    During the period of employment and for 6 months following
                    termination of Contractor’s employment for any reason
                    whatsoever, the Contractor shall not, directly or
                    indirectly, (a) solicit, encourage, or induce or attempt to
                    solicit, encourage, or induce any (i) Contractor, marketing
                    agent, vendor, partner or consultant of the Employer to
                    terminate his employment, agency, contract or consultancy
                    with the Employer, or any (ii) prospective Contractor with
                    whom the Employer has had discussions or negotiations within
                    six months prior to Contractor’s termination of employment,
                    not to establish a relationship with the Employer, (b)
                    induce or attempt to induce any current customer to
                    terminate its relationship with the Employer, or (c) induce
                    any potential customer with whom the <br />
                    Employer has had discussions or negotiations within six
                    months prior to Contractor’s termination of employment not
                    to establish a relationship with the Employer.
                  </p>
                </ol>
                <h5 className="text-muted">
                  15. EQUITABLE REMEDIES AND CONTRACTOR REPRESENTATIONS
                </h5>
                <ol>
                  <p>
                    <strong className="text-muted">15.1 </strong> The Contractor
                    agrees and acknowledges that the restrictions contained in
                    Clauses 12, 13, 14 and 15 are reasonable and necessary for
                    the protection of the business and goodwill of the Employer.
                    The rights and obligations of the Parties under Clauses 12,
                    13, 14 and 15 of the Agreement shall survive the termination
                    of this Agreement and shall not be extinguished by
                    termination of this Agreement.
                  </p>
                  <p>
                    <strong className="text-muted">15.2</strong> The Contractor
                    agrees that any breach or threatened breach of the
                    aforementioned clauses is likely to cause the Employer
                    substantial and irrevocable damage that is difficult to
                    measure and may not be remedied solely by damages.
                    Therefore, in the event of any such breach or threatened
                    breach, the Contractor agrees that the Employer, in addition
                    to such other remedies which may be available, shall have
                    the right to obtain an injunction from a court restraining
                    such a breach or threatened breach and the right to specific
                    performance of the provisions of either such clauses, and
                    the Contractor hereby waives the adequacy of a remedy at law
                    as a defense to such relief.
                  </p>
                  <p>
                    <strong className="text-muted">15.3 </strong> The Contractor
                    agrees and acknowledges that the restrictions contained
                    Clauses 12, 13, 14 and 15 are considered to be reasonable in
                    all the circumstances for the protection of the legitimate
                    interests of the Employer and shall be enforceable
                    independently. While the undertakings and agreements under
                    the clauses are considered by the Employer and the
                    Contractor to be reasonable in all circumstances, if one or
                    more should be held to be invalid as an unreasonable
                    restraint of trade or for any other reason whatsoever by a
                    final adjudication of any tribunal or court of competent
                    jurisdiction, but would have been held valid if part of the
                    wording thereof had been deleted or the period thereof
                    reduced or the range of activities or area dealt with
                    reduced in scope, the said undertakings and agreements shall
                    apply with such modifications as may be necessary to make
                    them valid and effective.
                  </p>
                </ol>
                <h5 className="text-muted">16. WARRANTIES</h5>
                <ol type="a">
                  <p>
                    The Contractor confirms and warrants that:
                    <br />
                  </p>
                  <li>
                    <p>
                      The Contractor has carefully read and fully understands
                      all the provisions of this Agreement.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Contractor has not been arrested, or convicted of, or
                      cautioned for, or charged but not yet tried with any
                      offense or crime, even if the Contractor is subject to a
                      pardon, amnesty, or other similar legal action and there
                      is no lawsuit, arbitration, administrative or other
                      proceeding or governmental investigation pending or, to
                      the best of the Contractor's knowledge, threatened against
                      the Contractor.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Contractor has not been the subject of any adverse
                      court judgment which threatens the Contractor solvency or
                      substantially compromises the Contractor financial
                      security.
                    </p>
                  </li>
                  <li>
                    <p>
                      that the Contractor has all the necessary licenses,
                      permissions, consents, approvals, qualifications and
                      memberships required of the Contractor to perform the
                      duties under this Agreement.
                    </p>
                  </li>
                  <li>
                    <p>
                      by entering into this Agreement or performing any of the
                      obligations under it, the Contractor will not be in breach
                      of any court order or any express or implied terms of any
                      contract or other
                    </p>
                  </li>
                  <li>
                    <p>
                      obligation binding on the Contractor. The Contractor
                      further undertakes to indemnify the Employer against any
                      such claims, costs, damages, liabilities or expenses which
                      the Employer may incur if the Contractor is in breach of
                      any such obligations
                    </p>
                  </li>
                  <li>
                    <p>
                      in the performance of the Contractor’s obligations, the
                      Contractor will not utilize or make available to the
                      Employer any confidential or proprietary information of
                      any third party or violate any obligation with respect to
                      such information.
                    </p>
                  </li>
                  <li>
                    <p>
                      the Contractor has never been suspended, censured or
                      otherwise been subjected to any disciplinary action or
                      other proceeding, litigation or investigation by any state
                      or governmental body or agency or any regulatory authority
                      or self-regulatory organization.
                    </p>
                  </li>
                </ol>
                <h5 className="text-muted">17. DATA PROTECTION</h5>
                <ol>
                  <p>
                    <strong className="text-muted">17.1 </strong> The Contractor
                    confirms that the Contractor has read and understood the
                    Employer's data protection policy and that the Contractor
                    shall comply with the data protection policy when processing
                    personal data in the course of employment including personal
                    data relating to any Contractor, customer, client, supplier
                    or agent of the Employer or any Group Employer.
                  </p>
                  <p>
                    <strong className="text-muted">17.2</strong> The Contractor
                    consents to the Employer collecting, processing and handling
                    data, including personal and sensitive personal data,
                    relating to the Contractor for legal, personnel,
                    administrative and management purposes.
                  </p>
                </ol>
                <h5 className="text-muted">18. NON-WAIVER</h5>
                <ol>
                  <p>
                    No delay, failure or omission on the part of the Employer to
                    exercise any of its powers, rights or remedies under this
                    Agreement will operate as a waiver of them nor will any
                    single or partial exercise of any such powers, rights or
                    remedies preclude any further exercise of them.
                  </p>
                </ol>
                <h5 className="text-muted">19. ADDITIONAL REMEDIES</h5>
                <ol>
                  <p>
                    Notwithstanding anything contained in this Agreement, the
                    Parties acknowledge that in addition to any remedy available
                    to the Employer, whether provided herein or conferred by
                    statute, civil law, common law, custom, trade, or usage, the
                    Employer shall be entitled to obtain an injunction against
                    the Contractor from a civil court of competent jurisdiction.
                  </p>
                </ol>
                <h5 className="text-muted">20. SEVERABILITY</h5>
                <ol>
                  <p>
                    If any provision of this Agreement is held by a court of
                    competent jurisdiction to be illegal, invalid or
                    unenforceable, the remaining provisions shall remain in full
                    force and effect. Any invalid or unenforceable provision of
                    this Agreement shall be replaced with a provision which is
                    valid and enforceable and most nearly reflects the original
                    intent of the invalid or unenforceable provision.
                  </p>
                </ol>
                <h5 className="text-muted">21. INDEMNITY</h5>
                <ol>
                  <p>
                    The Contractor, at all times during the course of the
                    Contractor’s employment in the Employer (and even after the
                    termination of this Agreement with respect to the terms
                    contained herein) agrees to indemnify and keep indemnified
                    the Employer, as the case may be, against all losses,
                    damages, claims, interests, costs, expenses, liabilities,
                    proceedings and demands which the Employer may suffer or
                    incur or which may be made against the Employer as a result
                    of acts or omissions of the Contractor during the course of
                    employment.
                  </p>
                </ol>
                <h5 className="text-muted">22. AMENDMENT</h5>
                <ol>
                  <p>
                    No modification or amendment of this Agreement and no waiver
                    of any of the terms or conditions hereof shall be valid or
                    binding unless made in writing and duly executed by both the
                    Parties.
                  </p>
                </ol>
                <h5 className="text-muted">
                  23. GOVERNING LAW, JURISDICTION AND SETTLEMENT OF DISPUTES
                </h5>
                <ol>
                  <p>
                    This Agreement shall be governed by the laws of India. The
                    courts at [Pune] shall have the exclusive jurisdiction over
                    all disputes or claims between the Contractor and the
                    Employer under this Agreement.
                  </p>
                </ol>
                <h5 className="text-muted">24. NOTICES</h5>
                <ol>
                  <p>
                    All notices or other communications required or permitted to
                    be delivered or given hereunder shall be in writing and
                    shall be delivered by electronic mail or hand or sent by
                    prepaid telex, cable or telecopy, or sent, postage prepaid,
                    by registered, certified or express mail, or reputable
                    courier service and shall be deemed delivered or given when
                    so delivered by electronic communication, hand, telexed,
                    cabled or telecopied, or if mailed, five days after mailing
                    as follows:
                  </p>
                </ol>
                <div className="row text-start mx-3">
                  <div className="col-4">
                    <p>If to the Employer, at :</p>
                  </div>

                  <div className="col-8">
                    <p>
                      Company Bench <br />
                      SUMMER COURT, 402, Magarpatta,
                      <br />
                      Pune, Maharashtra 411028
                    </p>
                  </div>
                </div>
                <div className="row text-start mx-3">
                  <div className="col-12">
                    <strong>If to the Contractor, at:</strong>
                    <p>Address:</p>
                    <h6>ENTIRE AGREEMENT</h6>
                  </div>

                  <div className="col-12">
                    <p>
                      This Agreement and the schedules hereto constitute the
                      entire understanding relating to terms of employment
                      between the Contractor and the Employer and supersedes all
                      prior offers, agreements, statements or representations,
                      written or oral between the Parties. Further, the
                      Contractor acknowledges and agrees that, as of the date of
                      this Agreement, the Contractor has no former claims of any
                      nature, whatsoever against the Employer.
                    </p>
                  </div>
                </div>
                <h5 className="text-muted">25. COUNTERPARTS</h5>
                <ol>
                  <p>
                    This Agreement will be executed in counterparts, each of
                    which shall be deemed an original, but all of which together
                    shall constitute one and the same instrument.
                  </p>
                </ol>
              </>
            </div>
          </ModalBody>
          {/* <ModalFooter>
          <Button color="primary" onClick={toggleModal}>Close</Button>
        </ModalFooter> */}
        </Modal>
      )}
    </>
  );
};

export default ConsultantCard;

import React, { useState, useEffect } from "react";

// Define a simple Spinner component with dynamic messages
const Spinner = () => {
  const [messages, setMessages] = useState([
    "Analyzing your career journey 🚀 Hold tight while we transform your experiences into a stellar resume!",
    "Crafting your professional story ✨ This may take a moment, but the result will be worth the wait!",
    "Decoding your skills and achievements 🕵️‍♀️ Grab a virtual coffee; we'll be done parsing your resume in a jiffy!",
    "Turning your career into wordsmith magic 🎩✨ Meanwhile, imagine your resume as a superhero cape!",
    "Your career's backstage is under the spotlight 🌟 While we work our magic, think of your dream job!",
  ]);

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    // Change the message every 2 seconds
    const intervalId = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 4000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [messages.length]);

  return (
    <div style={spinnerContainerStyle}>
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <p style={animatedTextStyle}>{messages[currentMessageIndex]}</p>
    </div>
  );
};

// Inline styles
const spinnerContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center", // Center text within the container
  height: "20vh", // Adjust the height of the container
};

const animatedTextStyle = {
  animation: "fadeInOut 1.5s infinite",
  marginBottom: "10px", // Adjust margin for spacing
  margin: "20px",
};

// Add corresponding CSS styles to your stylesheet
// (Include this part in your CSS or SCSS file)
const globalStyles = `
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
`;

// Apply global styles (for keyframes) using a style tag
const styleTag = document.createElement("style");
styleTag.type = "text/css";
styleTag.appendChild(document.createTextNode(globalStyles));
document.head.appendChild(styleTag);

export default Spinner;

import React from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
} from "reactstrap";
import { FaRegQuestionCircle } from "react-icons/fa";

function OffersNewContractCanvas({ isOpen, toggle, onClose, content }) {
  const offcanvasStyle = {
    width: "45%", // You can set a default width or use the provided width
  };
  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom " toggle={toggle}>
          <h4 className=" ">New Partner Contract</h4>
          <span className=" h6">
            {" "}
            <FaRegQuestionCircle className="text-danger" />
            Details of New Partner Contract
          </span>
        </OffcanvasHeader>
        <OffcanvasBody>
          {content}
          <Row></Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default OffersNewContractCanvas;

// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Form,
//   FormGroup,
//   Label,
//   Col,
//   Row,
//   Input,
//   Offcanvas,
//   OffcanvasHeader,
//   OffcanvasBody,
// } from "reactstrap";
// import { log_debug } from "../../apiHelpers/logger";

// const AddCandidateDetailsEducation = ({
//   isOpen,
//   toggle,
//   educationData,
//   handleSave,
//   data,
// }) => {

//   const offcanvasStyle = {
//     width: "40%",
//   };

//   return (
//     <>
//       <Offcanvas
//         direction="end"
//         isOpen={isOpen}
//         className="custom-offcanvas"
//         contentClassName="custom-offcanvas-content"
//         style={offcanvasStyle}
//       >
//         <OffcanvasHeader className="border-bottom" toggle={toggle}>
//           Add Education Details
//         </OffcanvasHeader>
//         <OffcanvasBody>
//          <p></p>
//         </OffcanvasBody>
//       </Offcanvas>
//     </>
//   );
// };

// export default AddCandidateDetailsEducation;

import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Row,
} from "reactstrap";
import validator from "validator";
import { validateStartEndDate, dateyyyymmdd_tohtml } from "../../helpers/date";
import { log_debug } from "../../apiHelpers/logger";

const AddCandidateDetailsEducation = ({ isOpen, toggle, handleSave }) => {
  const [errors, setErrors] = useState({});

  const [educationData, seteducationData] = useState({
    degree_name: "",
    college_or_university_name: "",
    from_date: "",
    to_date: "",
  });
  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    let newErrors = { ...errors };
    newErrors[fieldName] = "";
    if (!value) {
      newErrors[fieldName] = "Please fill this field";
    }
    setErrors(newErrors);
    seteducationData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    for (const key in educationData) {
      if (educationData.hasOwnProperty(key)) {
        const value = educationData[key];
        errors[key] = validator.isEmpty(value) ? `Please fill this field` : "";
        if (
          !validator.isLength(educationData["degree_name"], { min: 2, max: 60 })
        ) {
          errors["degree_name"] = "Please enter valid degree name";
        }
        if (
          !validator.isLength(educationData["college_or_university_name"], {
            min: 2,
            max: 60,
          })
        ) {
          errors["college_or_university_name"] =
            "Please enter valid university name";
        }
        if (
          !validator.isDate(dateyyyymmdd_tohtml(educationData["from_date"]))
        ) {
          errors["from_date"] = "Please enter valid start date";
        }
        if (!validator.isDate(dateyyyymmdd_tohtml(educationData["to_date"]))) {
          errors["to_date"] = "Please enter valid end date";
        }
        if (
          !validateStartEndDate(
            educationData["from_date"],
            educationData["to_date"],
          )
        ) {
          errors["from_date"] = "Start Date can not be Greater than End Date";
        }
      }
    }
    setErrors(errors);
    if (Object.values(errors).some((error) => error !== "")) {
      return;
    }
    handleSave(educationData);
    log_debug("educationDatahandelsubmit", educationData);
    toggle();
  };

  const offcanvasStyle = {
    width: "40%",
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          Add Education Details
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Label className="col-sm-3 col-form-label" for="degree_name">
                Degree Name <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="degree_name"
                  value={educationData.degree_name}
                  onChange={(e) => handleChange(e, "degree_name")}
                />
                {errors.degree_name && (
                  <small className="text-danger">{errors.degree_name}</small>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Label
                className="col-sm-3 col-form-label"
                for="college_or_university_name"
              >
                University Name <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="college_or_university_name"
                  value={educationData.college_or_university_name}
                  onChange={(e) =>
                    handleChange(e, "college_or_university_name")
                  }
                />
                {errors.college_or_university_name && (
                  <small className="text-danger">
                    {errors.college_or_university_name}
                  </small>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Label className="col-sm-3 col-form-label" for="from_date">
                From Date <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  type="date"
                  name="from_date"
                  value={educationData.from_date}
                  onChange={(e) => handleChange(e, "from_date")}
                />
                {errors.from_date && (
                  <small className="text-danger">{errors.from_date}</small>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Label className="col-sm-3 col-form-label" for="to_date">
                To Date <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Input
                  type="date"
                  name="to_date"
                  value={educationData.to_date}
                  onChange={(e) => handleChange(e, "to_date")}
                />
                {errors.to_date && (
                  <small className="text-danger">{errors.to_date}</small>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default AddCandidateDetailsEducation;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
  Button,
} from "reactstrap";

import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { Link } from "react-router-dom";
import {
  getIndividualApplied,
  getJobsCount,
  getMatchingJobs,
  postIndividualMultipleJobApply,
} from "./individualSlice";
import dayjs from "dayjs";
import DetailsCanvas from "../customComponents/offcanvas/CanvasJobdescription";
import CanvasInterview from "../customComponents/offcanvas/CanvasInterviewDetails";
import AdvanceFilter from "../customComponents/advance filter/AdvanceFilter";
import Preloader from "../components/Common/Preloader";
import { log_debug } from "../apiHelpers/logger";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Iguid } from "../apiConfig/axiosDev";
import { getIndividualGuid } from "../apiConfig/globalvariables";
import { toast } from "react-toastify";
import StatusBadge from "../customComponents/utilities/StatusBadge";
import moment from "moment";

function IndividualMatchingJobs() {
  const individualStore = useSelector((state) => state.individualSlice);
  const jobstatus = "shortlisted";
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const dispatch = useDispatch();
  const [MatchingJobs, setMatchingJobs] = useState([]);
  const [LocationOptions, setLocationOptions] = useState([]);
  const [JobRoleOptions, setJobRoleOptions] = useState([]);
  const [loading, setloading] = useState(false);
  const [canvasloading, setcanvasloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [RecordCount, setRecordCount] = useState(0);

  const [filtervalue, setFiltervalue] = useState("");
  const defaultStartDate = new Date();

  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getJobsCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  const yourDynamicHeaderData = {
    title: "Title",
    location: "Location",
    createdAt: "Application Date",
    status: "Status",
  };

  const JobName = (job) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedJob(job);
    setOffcanvasOpen(true);
  };

  const handleIndividualSingleJobApply = (data) => {
    log_debug("recieved data ", data);
    setcanvasloading(true);
    const guid = data.original.guid;
    dispatch(
      postIndividualMultipleJobApply({
        individualjobmapping: {
          individual_guid: [`${getIndividualGuid() || Iguid}`],
          jobdescription_guid: [guid],
        },
      }),
    ).then((response) => {
      if (response.payload?.success === true) {
        setcanvasloading(false);
        const data = response.payload.data;
        if (data.length != 0) {
          dispatch(
            getMatchingJobs({
              //limit: 100,
              offset: 0,
              skills: JobRole,
              locations: Location,
              filtervalue: "",
            }),
          );
          dispatch(
            getJobsCount({
              fromDate: formattedStartDate,
              toDate: formattedEndDate,
            }),
          );
          toast.success("Successfully Applied Selected Job", {
            position: "top-right",
            autoClose: 4000, // Close the toast after 5 seconds
            // hideProgressBar: true,
          });
        } else if (data.length === 0) {
          setcanvasloading(false);
          toast.warning("Unable to Apply for the Job", {
            position: "top-right",
            autoClose: 4000, // Close the toast after 5 seconds
            // hideProgressBar: true,
          });
        }
      }
    });
  };

  // useEffect(() => {
  //   if (individualStore.status === "idle") {
  //     setloading(false);
  //   } else if (individualStore.status === "loading") {
  //     setloading(true);
  //   }
  // }, [individualStore]);

  const columns = [
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            JobName(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ value }) => (value.length === 0 ? "-" : value.join(", ")),
    },
    {
      Header: "Required Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    // {
    //   Header: "Relevance",
    //   accessor: "score",

    //   Cell: ({ value }) => (
    //     <div style={{ width: "35px", height: "35px", margin: "auto" }}>
    //       <CircularProgressbarWithChildren
    //         styles={{
    //           root: {}, // Adjust these values
    //           path: {
    //             stroke: "#5156be",
    //             strokeLinecap: "round",
    //             transition: "stroke-dashoffset 0.5s ease 0s",
    //             transform: "rotate(0.25turn)",
    //             transformOrigin: "center center",
    //             pathColor: "#74788d",
    //           },
    //           trail: {
    //             stroke: "#d6d6d6",
    //             strokeLinecap: "round",
    //             transform: "rotate(0.25turn)",
    //             transformOrigin: "center center",
    //           },
    //         }}
    //         value={value}
    //       >
    //         <div style={{ fontSize: 10 }}>
    //           <strong>{Math.floor(value)}%</strong>
    //         </div>
    //       </CircularProgressbarWithChildren>
    //       {/* <Progress
    //         style={{
    //           height: "15px",
    //         }}
    //         value={value}
    //       >
    //         {" "}
    //         {value}%{" "}
    //       </Progress> */}
    //     </div>
    //   ),
    // },
    {
      Header: "Job Posted",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",

      Cell: ({ value }) => (
        <StatusBadge status={value} />
        // <Badge
        //   color={
        //     value === "active" ? "success rounded-pill" : "warning rounded-pill"
        //   }
        //   className="m-1 font-size-12"
        // >
        //   {value}
        // </Badge>
      ),
    },
    {
      Header: "Actions",
      accessor: "",
      Cell: ({ row }) => (
        <>
          <Link to={"#"} className="text-white">
            <Button
              color="primary"
              className="p-2"
              // color={appliedRows.includes(row.id) ? "success" : "primary"}
              // disabled={appliedRows.includes(row.id)}
              onClick={() => handleIndividualSingleJobApply(row)}
              disabled={loading || canvasloading}
            >
              <span>
                <i className=" fas fa-paper-plane fs-6"></i>&nbsp; Apply
              </span>

              {/* {appliedRows.includes(row.id) ? "Applied" : "Apply"} */}
            </Button>
          </Link>
        </>
      ),
    },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  // useEffect(() => {
  //   dispatch(
  //     getMatchingJobs({
  //       limit: 100,
  //       offset: currentPage,
  //     }),
  //   );
  // }, []);

  // useEffect(() => {
  //   dispatch(
  //     getMatchingJobs({
  //       limit: 100,
  //       offset: currentPage,
  //       skills:"",
  //       locations:""
  //     }),
  //   );
  // }, []);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.matchingjobs?.success === true) {
        setMatchingJobs(
          individualStore.data.matchingjobs?.data?.jobdescriptions,
        );
        setRecordCount(individualStore.data.matchingjobs?.data?.totalCount);
        setloading(false);
      }
      log_debug("applied job data", MatchingJobs);
    } else if (individualStore.status === "loading") {
      setloading(true);
    }
  }, [individualStore.data.matchingjobs]);

  const [Location, setLocation] = useState([]);
  const [JobRole, setJobRole] = useState([]);

  const handleFilterChange = (option) => {
    if (option && option.location) {
      const locationLabels = option.location.map((location) => location.label);
      setLocation(locationLabels);
    }

    if (option && option.jobRole) {
      const jobRoleLabels = option.jobRole.map((jobRole) => jobRole.label);
      setJobRole(jobRoleLabels);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        getMatchingJobs({
          //limit: 100,
          offset: currentPage,
          skills: JobRole,
          locations: Location,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [currentPage, Location, JobRole, filtervalue]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setloading(true);
    setFiltervalue(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Matching Jobs"
            Name="Matching Jobs"
          />
          {(loading || canvasloading) && <Preloader status={true} />}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <Card
                    className=" pt-3"
                  > */}
                  <CardBody>
                    <Row>
                      <AdvanceFilter onFilterChange={handleFilterChange} />
                      <div className="col-12 col-lg-6 col-md-6">
                        <Col md={12}>
                          <CardTitle className="text-end fs-6 pt-3">
                            Total Jobs : {RecordCount}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                  {/* </Card> */}
                </div>
                <div>
                  <Card className="">
                    <CardBody>
                      <CustomTable
                        tableDatas={MatchingJobs}
                        headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={MatchingJobs}
                        isLoading={loading}
                        individualMultipleApply={true}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        ShowPagination={
                          MatchingJobs && MatchingJobs.length > 10
                            ? true
                            : false
                        }
                        totalCount={RecordCount}
                        globalsearch={true}
                        onChange={handleValueChange}
                      />
                    </CardBody>
                  </Card>
                  {/* <DataTable title="All Jobs" columns={columns} data={jobs} /> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <>
          <DetailsCanvas
            usertype="individual"
            menuOption="matchingjobs"
            width={60}
            isOpen={isOffcanvasOpen}
            toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
            onClose={closeOffcanvas}
            data={selectedJob}
          />

          {/* <CanvasInterview
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          /> */}
        </>
      )}
    </React.Fragment>
  );
}

export default IndividualMatchingJobs;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";
import { getOverview } from "../../ClientSlice";
import { log_debug } from "../../../apiHelpers/logger";
import NoDataFound from "../../../customComponents/utilities/NoData";

function Overview({ guid }) {
  const clientstore = useSelector((state) => state.clientSlice);
  const dispatch = useDispatch();
  const [OverviewData, setOverviewData] = useState([]);

  useEffect(() => {
    log_debug("Overview guid", guid);
    dispatch(getOverview(guid));
  }, [guid]);

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.overview?.success === true) {
        setOverviewData(clientstore.data.overview?.data?.resumes);
      }
    }
  }, [clientstore]);

  useEffect(() => {
    log_debug("overview data", OverviewData);
  }, [OverviewData]);
  return (
    <>
      <Card className="mt-3">
        {OverviewData && OverviewData ? (
          <div className="card-body">
            <div>
              <div className="pb-3">
                <h5 className="font-size-15">Summary :</h5>
                <div className="">
                  <p className="mb-2">{OverviewData.summary}</p>
                </div>
              </div>
              <div className="">
                <h5 className="font-size-15">Experience :</h5>
                {OverviewData.work_experiences &&
                  OverviewData.work_experiences.map((experience, index) => (
                    <div key={index} className="">
                      <ul className="list-unstyled mb-0">
                        <li className="font-size-14 mb-2">
                          <div className="d-flex">
                            <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                            <p className="mb-0 fw-bold text-muted">
                              {experience.role}
                            </p>
                          </div>
                          <span className="font-size-13 ms-3"></span>
                          {experience.company_name}
                          <span className="font-size-12 text-muted ms-2">
                            <em>
                              {new Date(
                                experience.from_date,
                              ).toLocaleDateString()}{" "}
                              to{" "}
                              {new Date(
                                experience.to_date,
                              ).toLocaleDateString()}
                            </em>
                          </span>
                        </li>
                      </ul>
                    </div>
                  ))}
              </div>
              <div className="pt-3">
                <h5 className="font-size-15">Education :</h5>
                {OverviewData.educational_qualifications &&
                  OverviewData.educational_qualifications.map(
                    (education, index) => (
                      <div key={index} className="">
                        <ul className="list-unstyled">
                          <li className="font-size-14 mb-1">
                            <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                            <span className="fw-bold text-muted">
                              {education.degree_name}
                            </span>
                            <br />
                            <span className="font-size-13 ms-3">
                              {education.college_or_university_name}
                            </span>
                            <span className="font-size-12 text-muted ms-2">
                              <em>
                                {new Date(
                                  education.from_date,
                                ).toLocaleDateString()}{" "}
                                to{" "}
                                {new Date(
                                  education.to_date,
                                ).toLocaleDateString()}
                              </em>
                            </span>
                          </li>
                        </ul>
                      </div>
                    ),
                  )}
              </div>
            </div>
          </div>
        ) : (
          <NoDataFound />
        )}
      </Card>
    </>
  );
}

export default Overview;

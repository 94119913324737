import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Badge,
} from "reactstrap";
import { FaRegQuestionCircle } from "react-icons/fa";
import classNames from "classnames";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../customComponents/reactTable/CustomTable";
// import { IndividualDetail, ContractHistory } from "../PartnerSlice";
import CanvasCandidate from "../../customComponents/offcanvas/CanvasCandidate";
import TimelineComponent from "../../customComponents/timelineComponents/TimeLineComponent";

// import OfferDetailsCanvas from "./OfferDetailsCanvas";
import { log_debug } from "../../apiHelpers/logger";
import joblogo from "../../assets/images/companyBenchImage/jobgray1.jpg";
import { comma_formatter } from "../../helpers/utils";
import { postProfileDetails } from "../../Client/ClientSlice";
import validator from "validator";
import { postPartnerProfileDetails } from "../../Partner/PartnerSlice";
import { getCityCountry } from "../../individual/individualSlice";
import { getCountries } from "../../individual/individualSlice";
const CanvasEditDetail = ({ isOpen, toggle, onClose, data }) => {
  const StoreCountries = useSelector((state) => state.individualSlice);
  const StoreCityCountry = useSelector((state) => state.individualSlice);
  const [allCountries, setAllCountries] = useState([]);
  const [allCitiesOfCountry, setAllCitiesOfCountry] = useState([]);
  let updatedAddress = {
    address_line_1: "",
    address_line_2: "",
    city: "",
    zip: "",
    country: "",
  };
  let selectedCountry = "";
  // Redux dispatch function
  const dispatch = useDispatch();

  const [validationErrors, setValidationErrors] = useState({});

  // Extracting the address object from the data object
  const { address, ...restData } = data;

  // Creating the initial state by merging restData with address and data
  const [profileData, setProfileData] = useState({
    ...restData,
    ...address,
    ...data, // Preserve the original keys and values from data object
  });
  useEffect(() => {
    dispatch(getCountries());
  }, []);
  useEffect(() => {
    if (StoreCountries.status === "idle") {
      if (StoreCountries?.data?.Countries?.success === true) {
        setAllCountries(StoreCountries?.data?.Countries?.data?.enumcountrys);
        // setProfileData(StoreCountries?.data?.Countries?.data);
      }
    }
  }, [StoreCountries]);
  useEffect(() => {
    if (StoreCityCountry.status === "idle") {
      if (StoreCityCountry?.data?.countryCities?.success === true) {
        setAllCitiesOfCountry(
          StoreCityCountry?.data?.countryCities?.data?.enumcitys,
        );
      }
    }
  }, [StoreCityCountry]);

  // Function to handle form input changes
  const handleInputChange = (field, value1) => {
    // Validation checks
    let updatedErrors = { ...validationErrors };
    let currentErrors = {};

    switch (field) {
      case "company_name":
        currentErrors[field] = validator.isEmpty(value1)
          ? "company name is required"
          : "";
        break;
      case "contact_number":
        currentErrors[field] = validator.isEmpty(value1.toString())
          ? "contact number is required"
          : "";
        break;
      case "contact_person":
        currentErrors[field] = validator.isEmpty(value1)
          ? "contact person is required"
          : "";
        break;
      case "email_id":
        currentErrors[field] = validator.isEmpty(value1)
          ? "Email is required"
          : "";
        break;
      case "address_line_1":
        currentErrors[field] = validator.isEmpty(value1)
          ? "value is required"
          : "";
        break;
      case "address_line_2":
        currentErrors[field] = validator.isEmpty(value1)
          ? "value is required"
          : "";
        break;
      case "city":
        currentErrors[field] = validator.isEmpty(value1)
          ? "city is required"
          : "";
        break;
      case "zip":
        currentErrors[field] = validator.isEmpty(value1)
          ? "zip is required"
          : "";
        break;
      case "country":
        selectedCountry = value1;
        dispatch(getCityCountry(selectedCountry));
        currentErrors[field] = validator.isEmpty(value1)
          ? "country is required"
          : "";
        break;
      case "summary":
        currentErrors[field] = validator.isEmpty(value1)
          ? "summary is required"
          : "";
        break;
      case "website":
        currentErrors[field] = validator.isEmpty(value1)
          ? "website is required"
          : "";
        break;
      case "linkedIn":
        currentErrors[field] = validator.isEmpty(value1)
          ? "linked is required"
          : "";
        break;
      default:
        break;
    }
    setValidationErrors(currentErrors);

    setProfileData({
      ...profileData,
      [field]: value1,
    });
  };
  // Define cities and countries as arrays of objects
  // const cities = [
  //   { value: 'New Delhi', label: 'New Delhi' },
  //   { value: 'Mumbai', label: 'Mumbai' },
  //   { value: 'Bangalore', label: 'Bangalore' },
  //   { value: 'Chennai', label: 'Chennai' },
  //   { value: 'Kolkata', label: 'Kolkata' },
  //   // Add more cities as needed
  // ];

  // const countries = [
  //   { value: 'India', label: 'India' },
  //   { value: 'United States', label: 'United States' },
  //   { value: 'United Kingdom', label: 'United Kingdom' },
  //   { value: 'Australia', label: 'Australia' },
  //   // Add more countries as needed
  // ];

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const { address: originalAddress, ...restProfileData } = profileData;
    // Validation before submission
    let errors = {};
    for (const key in profileData) {
      if (profileData.hasOwnProperty(key)) {
        const value = profileData[key];
        if (key === "company_name") {
          errors[key] = validator.isEmpty(value)
            ? `company name is required`
            : "";
        } else if (key === "contact_number") {
          // const phoneNumber = value.toString();
          if (value === null || "" || undefined) {
            errors[key] = "Contact number is required";
          } else if (value !== null || value !== undefined || value !== "") {
            const phoneNumber = value.toString();
            if (!/^\+?\d{1,12}$/.test(phoneNumber)) {
              errors[key] = "Please enter a valid contact number";
            }
          } else {
            errors[key] = ""; // No errors
          }
        } else if (key === "contact_person") {
          errors[key] = validator.isEmpty(value)
            ? `contact person is required`
            : "";
        } else if (key === "email_id") {
          errors[key] = validator.isEmpty(value)
            ? "Email is Required"
            : !validator.isEmail(value, {
                  blacklisted_chars: "-$#~'!%^&*()+={}|/?\"",
                })
              ? `Please Enter Valid Email`
              : "";
        } else if (key === "address_line_1") {
          errors[key] = validator.isEmpty(value) ? `value is required` : "";
        } else if (key === "address_line_2") {
          errors[key] = validator.isEmpty(value) ? `value is required` : "";
        } else if (key === "city") {
          errors[key] = validator.isEmpty(value) ? `city is required` : "";
        }
        if (key === "zip") {
          errors[key] = validator.isEmpty(value)
            ? "Zip is required"
            : !validator.isLength(value, { max: 6 }) ||
                !validator.isNumeric(value)
              ? `Please Enter Valid Zip`
              : "";
        } else if (key === "country") {
          errors[key] = validator.isEmpty(value) ? `country is required` : "";
        } else if (key === "summary") {
          errors[key] = validator.isEmpty(value)
            ? "summary is required"
            : !validator.isLength(value, { min: 0, max: 1000 })
              ? `Character Length Exceeded`
              : "";
        } else if (key === "website") {
          errors[key] = validator.isEmpty(value)
            ? "Website is Required"
            : !validator.isURL(value)
              ? "Please Enter Valid Website URL"
              : "";
        } else if (key === "linkedIn") {
          errors[key] = validator.isEmpty(value)
            ? "linkedIn is Required"
            : !validator.isURL(value) || !value.includes("linkedin.com")
              ? "Please Enter Valid linkedIn Profile URL"
              : "";
        }
      }
    }
    setValidationErrors(errors);

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (hasErrors) {
      return;
    }

    if (profileData.guid.includes("part")) {
      updatedAddress = {
        address_line_1: profileData.address_line_1,
        address_line_2: profileData.address_line_2,
        city: profileData.city,
        zip: profileData.zip,
        country: profileData.country,
      };
      // Create the updated client object with the updated address
      const updatedPartner = {
        partner: {
          company_name: profileData.company_name,
          contact_number: profileData.contact_number,
          contact_person: profileData.contact_person,
          email_id: profileData.email_id,
          address: updatedAddress,
          website: profileData.website,
          linkedIn: profileData.linkedIn,
          summary: profileData.summary,
        },
      };

      dispatch(postPartnerProfileDetails(updatedPartner))
        .then(() => {
          // Close the canvas after dispatching
          toggle();
        })
        .catch((error) => {
          // Handle error if needed
        });
    } else if (profileData.guid.includes("cli")) {
      updatedAddress = {
        address_line_1: profileData.address_line_1,
        address_line_2: profileData.address_line_2,
        city: profileData.city,
        zip: profileData.zip,
        country: profileData.country,
      };
      // Create the updated client object with the updated address
      const updatedClient = {
        client: {
          company_name: profileData.company_name,
          contact_number: profileData.contact_number,
          contact_person: profileData.contact_person,
          email_id: profileData.email_id,
          address: updatedAddress,
          website: profileData.website,
          linkedIn: profileData.linkedIn,
          summary: profileData.summary,
        },
      };

      dispatch(postProfileDetails(updatedClient))
        .then(() => {
          // Close the canvas after dispatching
          toggle();
        })
        .catch((error) => {
          // Handle error if needed
        });
    }

    setProfileData({
      ...restProfileData, // Include the rest of the profileData
      address: updatedAddress, // Update the address object
    });
    // Create the partner object with updated values
    if (profileData.guid.includes("part")) {
      const partner = {
        ...profileData,
        address: {
          ...profileData.address,
          [e.target.name]: e.target.value,
        },
      };
    } else if (profileData.guid.includes("cli")) {
      const client = {
        ...profileData,
        address: {
          ...profileData.address,
          [e.target.name]: e.target.value,
        },
      };
    }
    // Create the client object with updated values
    const client = {
      ...profileData,
      address: {
        ...profileData.address,
        [e.target.name]: e.target.value,
      },
    };
  };

  log_debug("ClientDetailsEdit");

  const offcanvasStyle = {
    width: "45%", // You can set a default width or use the provided width
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom " toggle={toggle}>
          <h5 className="text-primary">
            Edit Profile Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Present comprehensive
              details.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col xl={12}>
              <div>
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="company_name"
                    >
                      Company Name <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="company_name"
                        value={profileData.company_name}
                        onChange={(e) =>
                          handleInputChange("company_name", e.target.value)
                        }
                      />
                      {validationErrors.company_name && (
                        <small className="text-danger">
                          {validationErrors.company_name}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label className="col-sm-3 col-form-label" for="email_id">
                      Email <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="email_id"
                        value={profileData.email_id}
                        onChange={(e) =>
                          handleInputChange("email_id", e.target.value)
                        }
                      />
                      {validationErrors.email_id && (
                        <small className="text-danger">
                          {validationErrors.email_id}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label className="col-sm-3 col-form-label" for="linkedIn">
                      linkedIn <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="linkedIn"
                        value={profileData.linkedIn}
                        onChange={(e) =>
                          handleInputChange("linkedIn", e.target.value)
                        }
                      />
                      {validationErrors.linkedIn && (
                        <small className="text-danger">
                          {validationErrors.linkedIn}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label className="col-sm-3 col-form-label" for="website">
                      Website <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="website"
                        value={profileData.website}
                        onChange={(e) =>
                          handleInputChange("website", e.target.value)
                        }
                      />
                      {validationErrors.website && (
                        <small className="text-danger">
                          {validationErrors.website}
                        </small>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="contact_person"
                    >
                      Contact Person <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="contact_person"
                        value={profileData.contact_person}
                        onChange={(e) =>
                          handleInputChange("contact_person", e.target.value)
                        }
                      />
                      {validationErrors.contact_person && (
                        <small className="text-danger">
                          {validationErrors.contact_person}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="contact_number"
                    >
                      Contact Number <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="contact_number"
                        value={profileData.contact_number}
                        onChange={(e) =>
                          handleInputChange("contact_number", e.target.value)
                        }
                      />
                      {validationErrors.contact_number && (
                        <small className="text-danger">
                          {validationErrors.contact_number}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="college_or_university_name"
                    >
                      Address <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Row className="mb-3">
                        <Col sm={6}>
                          <Input
                            required
                            type="text"
                            placeholder="Address line 1"
                            name="address_line_1"
                            value={profileData.address_line_1}
                            onChange={(e) =>
                              handleInputChange(
                                "address_line_1",
                                e.target.value,
                              )
                            }
                          />
                          {validationErrors.address_line_1 && (
                            <small className="text-danger">
                              {validationErrors.address_line_1}
                            </small>
                          )}
                        </Col>
                        <Col sm={6}>
                          <Input
                            required
                            type="text"
                            placeholder="Address line 2"
                            name="address_line_2"
                            value={profileData.address_line_2}
                            onChange={(e) =>
                              handleInputChange(
                                "address_line_2",
                                e.target.value,
                              )
                            }
                          />
                          {validationErrors.address_line_2 && (
                            <small className="text-danger">
                              {validationErrors.address_line_2}
                            </small>
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        {/* <Col sm={4}>
                          <Input
                            type="text"
                            placeholder="City"
                            name="city"
                            value={profileData.city}
                            onChange={(e) =>
                              handleInputChange("city", e.target.value)
                            }
                          />
                          {validationErrors.city && (
                            <small className="text-danger">
                              {validationErrors.city}
                            </small>
                          )}
                        </Col> */}
                        <Col sm={4}>
                          {/* <Label for="country">Country</Label> */}
                          <Input
                            required
                            type="select"
                            name="country"
                            value={profileData.country}
                            onChange={(e) =>
                              handleInputChange("country", e.target.value)
                            }
                          >
                            <option value="" hidden>
                              Select Country{" "}
                              <span className="text-danger">*</span>
                            </option>
                            {allCountries.map((country) => (
                              <option key={country.title} value={country.title}>
                                {country.title}
                              </option>
                            ))}
                          </Input>
                          {validationErrors.country && (
                            <small className="text-danger">
                              {validationErrors.country}
                            </small>
                          )}
                        </Col>

                        <Col sm={4}>
                          <Input
                            required
                            type="text"
                            placeholder="ZIP"
                            name="zip"
                            value={profileData.zip}
                            onChange={(e) =>
                              handleInputChange("zip", e.target.value)
                            }
                          />
                          {validationErrors.zip && (
                            <small className="text-danger">
                              {validationErrors.zip}
                            </small>
                          )}
                        </Col>
                        <Col sm={4}>
                          {/* <Label for="city">City</Label> */}
                          <Input
                            required
                            type="select"
                            name="city"
                            value={profileData.city}
                            onChange={(e) =>
                              handleInputChange("city", e.target.value)
                            }
                          >
                            <option value="" hidden>
                              Select City
                            </option>
                            {allCitiesOfCountry.map((city) => (
                              <option key={city.title} value={city.title}>
                                {city.title}
                              </option>
                            ))}
                          </Input>
                          {validationErrors.city && (
                            <small className="text-danger">
                              {validationErrors.city}
                            </small>
                          )}
                        </Col>
                        {/* <Col sm={4}>
                          <Input
                            type="text"
                            placeholder="Country"
                            name="country"
                            value={profileData.country}
                            onChange={(e) =>
                              handleInputChange("country", e.target.value)
                            }
                          />
                          {validationErrors.country && (
                            <small className="text-danger">
                              {validationErrors.country}
                            </small>
                          )}
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="college_or_university_name"
                    >
                      Summary <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="textarea"
                        name="summary"
                        value={profileData.summary}
                        onChange={(e) =>
                          handleInputChange("summary", e.target.value)
                        }
                      />
                      {validationErrors.summary && (
                        <small className="text-danger">
                          {validationErrors.summary}
                        </small>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button type="submit" color="primary">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default CanvasEditDetail;

import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";

import CustomTable from "../../../customComponents/reactTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";

import { Tags } from "../../../pages/Contacts/ContactList/contactlistCol";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import {
  getDashboardContracts,
  getDashboardInterviews,
  getDashboardInvitations,
  getDashboardMyJobs,
  getDashboardOffers,
} from "../../ClientSlice";
import { dateyyyymmdd_tohtml } from "../../../helpers/date";
import { comma_formatter } from "../../../helpers/utils";
import CanvasJobDescription from "../../../customComponents/offcanvas/CanvasJobdescription";
import CanvasCandidate from "../../../customComponents/offcanvas/CanvasCandidate";
import CanvasInterview from "../../../customComponents/offcanvas/CanvasInterviewDetails";
import OfferDetailsCanvas from "../../../customComponents/offcanvas/OfferDetailsCanvas";
import ContractDetailCanvas from "../../../customComponents/offcanvas/ContractDetailCanvas";
import CustomAvatar from "../../../customComponents/CustomAvatar/CustomAvatar";
import { FiEdit } from "react-icons/fi";
import CanvasJobDescEdit from "../../../customComponents/offcanvas/clientJob/CanvasJobDescEdit";
import CandidateStatusBadge from "../../../customComponents/utilities/CandidateStatusBadge";

const ClientDashboardTabs = ({ startdate, enddate }) => {
  const dispatch = useDispatch();
  const clientStore = useSelector((state) => state.clientSlice);
  const [MyJobs, setMyJobs] = useState([]);
  const [Invitations, setInvitations] = useState([]);
  const [Interviews, setInterviews] = useState([]);
  const [Offers, setOffers] = useState([]);
  const [Contracts, setContracts] = useState([]);
  const [isMyJobscanvasOpen, setMyJobscanvasOpen] = useState(false);
  const [isInterviewCanvasOpen, setInterviewCanvasOpen] = useState(false);
  const [isOfferCanvasOpen, setOfferCanvasOpen] = useState(false);
  const [isInvitationCanvasOpen, setInvitationCanvasOpen] = useState(false);
  const [isContractCanvasOpen, setContractCanvasOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [jobDesc, setJobDesc] = useState({});
  const [isEditJdOpen, setIsEditJdOpen] = useState(false);
  const [headerTab, setHeaderTab] = useState("1");

  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [currentPage3, setCurrentPage3] = useState(0);
  const [currentPage4, setCurrentPage4] = useState(0);
  const [currentPage5, setCurrentPage5] = useState(0);

  const [MyJobsCount, setMyJobsCount] = useState([]);
  const [InvitationCouont, setInvitationCouont] = useState([]);
  const [InterviewCount, setInterviewCount] = useState([]);
  const [OfferCount, setOfferCount] = useState([]);
  const [ContractCount, setContractCount] = useState([]);

  //meta title

  const [activeTab, setactiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  const [selectedJob, setSelectedJob] = useState([]);
  const handleMyJobs = (selectedjob) => {
    setSelectedJob(selectedjob);
    setMyJobscanvasOpen(true);
  };

  const closeMyJobsCanvas = () => {
    setMyJobscanvasOpen(false);
  };

  const [selectedInvitation, setSelectedInvitation] = useState([]);
  const handleInvitations = (selectedjob) => {
    setSelectedInvitation(selectedjob);
    setInvitationCanvasOpen(true);
  };

  const closeInvitationCanvas = () => {
    setInvitationCanvasOpen(false);
  };

  const [selectedInterview, setselectedInterview] = useState([]);
  const handleInterviews = (interview) => {
    setselectedInterview(interview);
    setInterviewCanvasOpen(true);
  };

  const closeInterviewCanvas = () => {
    setselectedInterview(false);
  };

  const [selectedOffer, setselectedOffer] = useState([]);
  const handleOffers = (offer) => {
    setselectedOffer(offer);
    setOfferCanvasOpen(true);
  };

  const closeOfferCanvas = () => {
    setselectedOffer(false);
  };

  const [selectedContract, setselectedContract] = useState([]);
  const handleContracts = (contract) => {
    setselectedContract(contract);
    setContractCanvasOpen(true);
  };

  const closeContractCanvas = () => {
    setselectedContract(false);
  };

  const handleJdEditAction = (e, JodDescrip) => {
    setJobDesc(JodDescrip);
    setIsEditJdOpen(true);
  };

  const closeEditJdOffcanvas = () => {
    setIsEditJdOpen(false);
  };

  const columnsMyJobs = [
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleMyJobs(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ value }) => value.join(", "),
    },
    {
      Header: "Required Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: ({ row }) => (

      //          row.values.skills.map((skill, index) => (
      //            <Badge key={index}  className="bg-secondary-subtle text-secondary rounded-pill m-1 font-size-12">
      //              {skill}
      //            </Badge>
      //          ))

      //      ),
      // Cell: (props) =>
      //   const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
      //   return <span>{tidyDate}</span>;
      // },
    },

    {
      Header: "Posted on",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) =>
        value ? (
          <Badge
            color={
              value === "active"
                ? "success  rounded-pill "
                : "danger  rounded-pill "
            }
          >
            {value}
          </Badge>
        ) : (
          "-"
        ),
    },
    {
      Header: "Actions",
      accessor: "",
      Cell: ({ row }) => (
        <>
          <div className="text-center">
            <Link
              to={"#"}
              color="primary"
              type="button"
              className="btn btn-primary text-white rounded"
              onClick={(e) => {
                handleJdEditAction(e, row.original);
              }}
            >
              <FiEdit className="mx-1" />
              {/* Edit */}
            </Link>
          </div>
        </>
      ),
    },
  ];

  const columnsInvitations = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleInvitations(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar fullName={row.values.full_name.charAt(0)} />
            <span style={{ marginTop: "5px" }}> {row.values.full_name}</span>
          </div>
        </Link>
      ),
    },
    {
      Header: "Location",
      accessor: "prefered_locations",
      Cell: ({ value }) => value.join(", "),
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: ({ row }) => (

      //   row.values.skills.map((skill, index) => (
      //     <Badge key={index}  className="bg-secondary-subtle text-secondary rounded-pill m-1 font-size-12">
      //       {skill}
      //     </Badge>
      //   ))

      //),
      // Cell: (props) =>
      //   const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
      //   return <span>{tidyDate}</span>;
      // },
    },
    {
      Header: "Invited on",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      // Cell: ({ row }) => (
      //   <Badge
      //     color={
      //       row.values.status === "contracted"
      //         ? "success text-white rounded-pill"
      //         : "warning text-white rounded-pill"
      //     }
      //   >
      //     {row.values.status}
      //   </Badge>
      // ),
      Cell: ({ value }) => <CandidateStatusBadge status={value} />,
    },
    // {
    //   Header: "Actions",
    //   accessor: "",
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  const columnsOffers = [
    {
      Header: "Offer ID",
      accessor: "offer_id",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleOffers(row.original);
          }}
        >
          {row.original?.offer_id?.split("-")[0].toUpperCase()}-
          {row.original?.offer_id?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            //candidateName(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar fullName={row.original.candidate.charAt(0)} />
            <span style={{ marginTop: "5px" }}>{row.original.candidate}</span>
          </div>
        </Link>
      ),
      width: 100,
    },
    {
      Header: "Job Title",
      accessor: "job_title",
      width: 100,
      //Cell: ({ value }) => value.join(', '),
    },
    {
      Header: "Status",
      accessor: "status",
      width: 200,

      Cell: ({ value }) => (
        <Badge
          color={
            value === "accepted"
              ? "success rounded-pill"
              : "danger rounded-pill"
          }
          className="m-1 font-size-12"
        >
          {value}
        </Badge>
      ),
    },
  ];

  const columnsContracts = [
    {
      Header: "Contract",
      accessor: "contract_id",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleContracts(row.original);
          }}
        >
          {row.original?.contract_id?.split("-")[0].toUpperCase()}-
          {row.original?.contract_id?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Candidate",
      accessor: "individuals",
      Cell: ({ row }) => (
        <div key={row.index} className="d-flex">
          {row.original.individuals && row.original.individuals.length > 0
            ? row.original.individuals.map((indv, index) => (
                <React.Fragment key={index}>
                  {indv.full_name}
                  {index < row.original.individuals.length - 1 && ", "}
                </React.Fragment>
              ))
            : //Render something when individuals array is empty
              "-"}
        </div>
      ),
      Cell: ({ value }) => (
        <>
          {value &&
            value.map((skill, index) => (
              <Link
                key={index}
                to={"#"}
                onClick={() => {
                  //candidateName(row.original);
                }}
              >
                <div style={{ display: "flex", alignItems: "top" }}>
                  <CustomAvatar fullName={skill.full_name.charAt(0)} />
                  <span style={{ marginTop: "5px" }}>{skill.full_name}</span>
                </div>
              </Link>
            ))}
        </>
      ),
    },
    // {
    //   Header: "Client",
    //   accessor: "company_name",
    //   width: 100,
    // },

    {
      Header: "Value",
      accessor: "contract_value",
      Cell: ({ value }) => (
        <div>
          <i className=" mdi mdi-currency-inr"></i>
          {comma_formatter(value)}
        </div>
      ),
    },
    {
      Header: "Start Date",
      accessor: "started_at",
      width: 100,
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
      // Cell: ({ row }) => {row.original?.started_at ? (dayjs(row.original.started_at).format("DD-MM-YYYY")) : '-'}
    },
    {
      Header: "End Date",
      accessor: "end_date",
      width: 100,
      Cell: ({ value }) => "-",
    },
    {
      Header: "Status",
      accessor: "status",
      width: 200,

      Cell: ({ value }) => (
        <Badge
          color={
            value === "accepted"
              ? "success rounded-pill"
              : "warning rounded-pill"
          }
          className="m-1 font-size-12"
        >
          {value}
        </Badge>
      ),
    },
  ];

  const columnsInterviews = [
    {
      Header: "Interview Id",
      accessor: "guid",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleInterviews(row.original);
          }}
        >
          {row.original?.guid?.split("-")[0].toUpperCase()}-
          {row.original?.guid?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Candidate",
      accessor: "individuals.full_name",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            //candidateName(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar
              fullName={row.original.individuals.full_name.charAt(0)}
            />
            <span style={{ marginTop: "5px" }}>
              {row.original.individuals.full_name}
            </span>
          </div>
        </Link>
      ),
      // Cell: ({ value }) => value.join(", "),
    },

    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Scheduled Date",
      accessor: "scheduled_at",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex gap-1">
          {/* <p className="font-size-12 m-0"></p> */}
          <Badge
            color={
              row.values.status === "selected"
                ? "success rounded-pill"
                : "warning rounded-pill"
            }
          >
            {row.original.type.toUpperCase()} {row.values.status}
          </Badge>
        </div>
      ),
    },
    // {
    //   Header: "Actions",
    //   accessor: "",
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  useEffect(() => {
    dispatch(
      getDashboardMyJobs({
        startDate: startdate,
        endDate: enddate,
        currentPage: currentPage ? currentPage : 0,
      }),
    );
  }, [startdate, enddate, currentPage]);

  useEffect(() => {
    dispatch(
      getDashboardInvitations({
        startDate: startdate,
        endDate: enddate,
        currentPage: currentPage2 ? currentPage2 : 0,
      }),
    );
  }, [startdate, enddate, currentPage2]);

  useEffect(() => {
    dispatch(
      getDashboardInterviews({
        startDate: startdate,
        endDate: enddate,
        currentPage: currentPage3 ? currentPage3 : 0,
      }),
    );
  }, [startdate, enddate, currentPage3]);

  useEffect(() => {
    dispatch(
      getDashboardOffers({
        startDate: startdate,
        endDate: enddate,
        currentPage: currentPage4 ? currentPage4 : 0,
      }),
    );
  }, [startdate, enddate, currentPage4]);

  useEffect(() => {
    dispatch(
      getDashboardContracts({
        startDate: startdate,
        endDate: enddate,
        currentPage: currentPage5 ? currentPage5 : 0,
      }),
    );
  }, [startdate, enddate, currentPage5]);

  useEffect(() => {
    if (clientStore?.status === "idle") {
      if (clientStore?.data?.DashboardMyJobs?.success === true) {
        setMyJobs(clientStore?.data?.DashboardMyJobs?.data?.jobdescriptions);
        setMyJobsCount(clientStore?.data?.DashboardMyJobs?.data?.totalCount);
      }
      if (clientStore?.data?.DashboardInvitations?.success === true) {
        setInvitations(
          clientStore?.data?.DashboardInvitations?.data?.individuals,
        );
        setInvitationCouont(
          clientStore?.data?.DashboardInvitations?.data?.totalCount,
        );
      }
      if (clientStore?.data?.DashboardInterviews?.success === true) {
        setInterviews(
          clientStore?.data?.DashboardInterviews?.data?.individuals,
        );
        setInterviewCount(
          clientStore?.data?.DashboardInterviews?.data?.totalCount,
        );
      }
      if (clientStore?.data?.DashboardOffers?.success === true) {
        setOffers(clientStore?.data?.DashboardOffers?.data?.individuals);
      }
      setOfferCount(clientStore?.data?.DashboardOffers?.data?.totalCount);
      if (clientStore?.data?.DashboardContracts?.success === true) {
        setContracts(clientStore?.data?.DashboardContracts?.data?.individuals);
      }
      setContractCount(clientStore?.data?.DashboardContracts?.data?.totalCount);
    } else if (clientStore?.status === "loading") {
      setloading(true);
    }
  }, [
    clientStore?.data?.DashboardMyJobs,
    clientStore.data.DashboardInvitations,
    clientStore.data.DashboardInterviewss,
    clientStore.data.DashboardOffers,
    clientStore.data.DashboardContractss,
  ]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageChangeInvitation = (newPage) => {
    setCurrentPage2(newPage);
  };

  const handlePageChangeInterview = (newPage) => {
    setCurrentPage3(newPage);
  };

  const handlePageChangeOffer = (newPage) => {
    setCurrentPage4(newPage);
  };

  const handlePageChangeContract = (newPage) => {
    setCurrentPage5(newPage);
  };

  return (
    <Row>
      <Col xl={12}>
        <div>
          <CardHeader className="align-items-center d-flex my-2">
            <div className="flex-shrink-0">
              <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "1",
                    })}
                    onClick={() => {
                      toggleHeader("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="d-none d-sm-block">
                      My Jobs <Badge color="primary">{MyJobsCount}</Badge>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "2",
                    })}
                    onClick={() => {
                      toggleHeader("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">
                      Invitations{" "}
                      <Badge color="primary">{InvitationCouont}</Badge>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "3",
                    })}
                    onClick={() => {
                      toggleHeader("3");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">
                      Interviews <Badge color="primary">{InterviewCount}</Badge>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "4",
                    })}
                    onClick={() => {
                      toggleHeader("4");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="d-none d-sm-block">
                      Offers <Badge color="primary">{OfferCount}</Badge>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "5",
                    })}
                    onClick={() => {
                      toggleHeader("5");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="d-none d-sm-block">
                      Contracts <Badge color="primary">{ContractCount}</Badge>
                    </span>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "6",
                    })}
                    onClick={() => {
                      toggleHeader("6");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="d-none d-sm-block">
                    Matching Jobs
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({
                      active: headerTab === "7",
                    })}
                    onClick={() => {
                      toggleHeader("7");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="d-none d-sm-block">
                    Jobs Invitations
                    </span>
                  </NavLink>
                </NavItem> */}
              </Nav>
            </div>
          </CardHeader>

          <div className="card-body">
            <TabContent className="text-muted" activeTab={headerTab}>
              <TabPane tabId="1">
                <>
                  <CustomTable
                    columns={columnsMyJobs}
                    data={MyJobs ? MyJobs : ""}
                    shortlist={true}
                    redirectTo="/client/jobs"
                    hideCheckbox={true}
                    // currentPage={0}
                    totalCount={MyJobsCount ? MyJobsCount : 0}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              </TabPane>
              <TabPane tabId="2">
                <>
                  <CustomTable
                    columns={columnsInvitations}
                    shortlist={true}
                    redirectTo="/client/invitations"
                    data={Invitations ? Invitations : ""}
                    hideCheckbox={true}
                    totalCount={InvitationCouont ? InvitationCouont : 0}
                    currentPage={currentPage2}
                    onPageChange={handlePageChangeInvitation}
                  />
                </>
              </TabPane>
              <TabPane tabId="3">
                <>
                  <CustomTable
                    columns={columnsInterviews}
                    shortlist={true}
                    redirectTo="/client/interviews"
                    data={Interviews ? Interviews : ""}
                    hideCheckbox={true}
                    totalCount={InterviewCount ? InterviewCount : 0}
                    currentPage={currentPage3}
                    onPageChange={handlePageChangeInterview}
                  />
                </>
              </TabPane>
              <TabPane tabId="4">
                <CustomTable
                  columns={columnsOffers}
                  data={Offers ? Offers : ""}
                  shortlist={true}
                  hideCheckbox={true}
                  redirectTo="/client/offers"
                  totalCount={OfferCount ? OfferCount : 0}
                  currentPage={currentPage4}
                  onPageChange={handlePageChangeOffer}
                />
              </TabPane>
              <TabPane tabId="5">
                <CustomTable
                  columns={columnsContracts}
                  data={Contracts ? Contracts : ""}
                  shortlist={true}
                  hideCheckbox={true}
                  redirectTo="/client/contracts"
                  totalCount={ContractCount ? ContractCount : 0}
                  currentPage={currentPage5}
                  onPageChange={handlePageChangeContract}
                />
              </TabPane>
              {/* <TabPane tabId="6">
           <CustomTable columns={columnsShortlisted}  data={jobData}/>
            </TabPane>
            <TabPane tabId="7">
           <CustomTable columns={columnsShortlisted}  data={jobData}/>
            </TabPane> */}
            </TabContent>
          </div>
        </div>
      </Col>

      {isMyJobscanvasOpen && (
        <CanvasJobDescription
          usertype="client"
          menuOption="jobs"
          width={60}
          isOpen={isMyJobscanvasOpen}
          toggle={() => setMyJobscanvasOpen(!isMyJobscanvasOpen)}
          onClose={closeMyJobsCanvas}
          data={selectedJob}
        />
      )}

      {isInvitationCanvasOpen && (
        <CanvasCandidate
          usertype="client"
          menuOption="invitations"
          width={60}
          isOpen={isInvitationCanvasOpen}
          toggle={() => setInvitationCanvasOpen(!isInvitationCanvasOpen)}
          onClose={closeInvitationCanvas}
          data={selectedInvitation}
        />
      )}

      {isInterviewCanvasOpen && (
        <CanvasInterview
          userType="client"
          menuOption="appliedcandidates"
          width={60}
          isOpen={isInterviewCanvasOpen}
          toggle={() => setInterviewCanvasOpen(!isInterviewCanvasOpen)}
          onClose={closeInterviewCanvas}
          data={selectedInterview}
        />
      )}

      {isOfferCanvasOpen && (
        <OfferDetailsCanvas
          placement="end"
          isOpen={isOfferCanvasOpen}
          toggle={() => setOfferCanvasOpen(!isOfferCanvasOpen)}
          onClose={closeOfferCanvas}
          data={selectedOffer}
          userType={"client"}
        />
      )}

      {isContractCanvasOpen && (
        <ContractDetailCanvas
          isOpen={isContractCanvasOpen}
          toggle={() => setContractCanvasOpen(!isContractCanvasOpen)}
          onClose={closeContractCanvas}
          data={selectedContract}
          userType={"client"}
        />
      )}

      {isEditJdOpen && (
        <CanvasJobDescEdit
          isOpen={isEditJdOpen}
          toggle={() => setIsEditJdOpen(!isEditJdOpen)}
          onClose={closeEditJdOffcanvas}
          data={jobDesc}
        />
      )}
    </Row>
  );
};

export default ClientDashboardTabs;

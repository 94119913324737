import React, { useEffect, useState } from "react";
import {
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import validator from "validator";
import { log_debug } from "../../apiHelpers/logger";
import { dateyyyymmdd_tohtml, validateStartEndDate } from "../../helpers/date";

const CandidateDetailsCertificateCanvas = ({
  data,
  handleSave,
  isOpen,
  toggle,
  onClose,
}) => {
  const [guid, setGuid] = useState([]);
  const [editedData, setEditedData] = useState([]);
  log_debug("CertificationEdit", editedData);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setGuid(data?.guid || []);
    setEditedData(data || "");
  }, [data]);

  const offcanvasStyle = {
    width: "40%", // we can set a default width or use the provided width
  };

  const handleFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    if (fieldName === "CertificationDate") {
      setEditedData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [fieldName]: new Date(value).toISOString(),
        };
        return newData;
      });
    } else {
      setEditedData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], [fieldName]: value };
        return newData;
      });
    }
  };

  const handleValidateSave = () => {
    let errors = {};
    editedData.forEach((certificate, index) => {
      let certErrors = {};

      let CertificateName = certificate?.CertificateName || "";
      let Status = certificate?.Status || "";
      let CertificationDate = certificate?.CertificationDate || "";

      certErrors["CertificateName"] = validator.isEmpty(CertificateName)
        ? "Certificate name is required"
        : "";
      certErrors["Status"] = validator.isEmpty(Status)
        ? "Status name is required"
        : "";
      certErrors["CertificationDate"] = validator.isEmpty(CertificationDate)
        ? "Certification date is required"
        : "";

      if (!validator.isLength(CertificateName, { min: 2, max: 60 })) {
        certErrors["CertificateName"] = "Please enter valid certificate name";
      }
      if (!validator.isLength(Status, { min: 2, max: 60 })) {
        certErrors["Status"] = "Please enter valid status";
      }
      if (CertificationDate) {
        if (!validator.isDate(dateyyyymmdd_tohtml(CertificationDate))) {
          certErrors["CertificationDate"] = "Please enter valid issued date";
        }
        if (
          !validateStartEndDate(CertificationDate, new Date().toISOString())
        ) {
          certErrors["CertificationDate"] =
            "Certification issued date can not be greater than today";
        }
      }
      errors[index] = certErrors;
    });

    setErrors(errors);
    if (
      Object.values(errors).some((certErrors) =>
        Object.values(certErrors).some((error) => error !== ""),
      )
    ) {
      return;
    }
    handleSave(editedData);
  };

  return (
    <Offcanvas
      direction="end"
      isOpen={isOpen}
      className="custom-offcanvas"
      onClose={onClose}
      style={offcanvasStyle}
    >
      <OffcanvasHeader toggle={toggle} className="border-bottom">
        <p className="text-muted">Certificate Details</p>
      </OffcanvasHeader>
      <OffcanvasBody>
        {editedData.map((cert, index) => (
          <React.Fragment key={index}>
            <Form>
              <Row className="mb-3">
                <Label
                  className="col-sm-3 col-form-label"
                  for="CertificateName"
                >
                  Certificate Name <span className="text-danger">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="CertificateName"
                    value={cert?.CertificateName}
                    onChange={(e) =>
                      handleFieldChange(e, index, "CertificateName")
                    }
                  />
                  {errors[index]?.CertificateName && (
                    <small className="text-danger">
                      {errors[index]?.CertificateName}{" "}
                    </small>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Label className="col-sm-3 col-form-label" for="Status">
                  Status <span className="text-danger">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    name="Status"
                    value={cert?.Status}
                    onChange={(e) => handleFieldChange(e, index, "Status")}
                  />
                  {errors[index]?.Status && (
                    <small className="text-danger">
                      {errors[index]?.Status}
                    </small>
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  className="col-sm-3 col-form-label"
                  for="CertificationDate"
                >
                  Issued Date <span className="text-danger">*</span>
                </Label>
                <Col sm={9}>
                  <Input
                    type="date"
                    name="CertificationDate"
                    value={
                      cert?.CertificationDate &&
                      dateyyyymmdd_tohtml(cert.CertificationDate)
                    }
                    onChange={(e) =>
                      handleFieldChange(e, index, "CertificationDate")
                    }
                  />
                  {errors[index]?.CertificationDate && (
                    <small className="text-danger">
                      {errors[index]?.CertificationDate}
                    </small>
                  )}
                </Col>
              </Row>
              {/* <Row className="mb-3">
                <Label className="col-sm-3 col-form-label" for="to_date">To Date</Label>
                <Col sm={9}>
                <Input
                  type="date"
                  name="to_date"
                  value={cert?.to_date && dateyyyymmdd_tohtml(cert.to_date)}
                  onChange={(e) => handleFieldChange(e, index, "to_date")}
                />
                  {errors[index]?.to_date && (<small className="text-danger">{errors[index]?.to_date}</small>)}
                </Col>
              </Row> */}
            </Form>
            <hr />
          </React.Fragment>
        ))}

        <Button color="primary" onClick={() => handleValidateSave()}>
          Update
        </Button>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default CandidateDetailsCertificateCanvas;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getJobRoles } from "../individualSlice";
import { log_debug } from "../../apiHelpers/logger";

const Assessment = () => {
  const formik = useFormik({
    initialValues: {
      fullName: "test",
      email: "test@yopmail.com",
      role: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      role: Yup.string().required("Role is required"),
    }),
    onSubmit: (values) => {
      // Handle form submission logic here
      log_debug("Form submitted:", values);
    },
  });

  const individualStore = useSelector((state) => state.individualSlice);
  const dispatch = useDispatch();
  const [JobRoleOptions, setJobRoleOptions] = useState([]);

  useEffect(() => {
    dispatch(getJobRoles(1000));
  }, []);

  useEffect(() => {
    if (individualStore.status === "idle") {
      if (individualStore.data.jobroles?.success === true) {
        setJobRoleOptions(individualStore.data.jobroles?.data?.enumjobroles);
        log_debug("JobRole data:", JobRoleOptions);
      }
    }
  }, [individualStore]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="container">
        <div className=" border-primary ">
          <h4>Take Assessment</h4>
          <div className="mb-3 pt-4 d-flex">
            <label htmlFor="fullName" className="form-label m-auto w-50">
              Full Name
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.fullName && formik.errors.fullName
                  ? "is-invalid"
                  : ""
              }`}
              id="fullName"
              name="fullName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={"Test"}
              disabled
            />
            {formik.touched.fullName && formik.errors.fullName ? (
              <div className="invalid-feedback">{formik.errors.fullName}</div>
            ) : null}
          </div>

          <div className="mb-3 d-flex">
            <label htmlFor="email" className="form-label m-auto w-50">
              Email
            </label>
            <input
              type="email"
              className={`form-control ${
                formik.touched.email && formik.errors.email ? "is-invalid" : ""
              }`}
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              disabled
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="mb-3 d-flex">
            <label htmlFor="role" className="form-label m-auto w-50">
              Role
            </label>
            <select
              className={`form-select ${
                formik.touched.role && formik.errors.role ? "is-invalid" : ""
              }`}
              id="role"
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
            >
              <option hidden>Select Role</option>
              {JobRoleOptions.map((option, index) => (
                <option key={index} value={option.code}>
                  {option.title}
                </option>
              ))}
              {/* Add more roles as needed */}
            </select>
            {formik.touched.role && formik.errors.role ? (
              <div className="invalid-feedback p-1">{formik.errors.role}</div>
            ) : null}
          </div>

          <div className="mt-3">
            <button type="submit" className="btn btn-primary ">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Assessment;

import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Container,
  CardHeader,
  Badge,
} from "reactstrap";
import "../../src/assets/css/styleCompanyBench.css";

import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../components/Common/Breadcrumb";
import dayjs from "dayjs";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import CustomTable from "../customComponents/reactTable/CustomTable.js";
import {
  dashboardOffer,
  addCandidatesSkills,
  addCandidatesLocation,
  getFilterOffer,
} from "./PartnerSlice.js";
import "./partner.css";
import { Link } from "react-router-dom";
import OfferDetailsCanvas from "./pagesComponents/OfferDetailsCanvas.js";
import OffersNewContractCanvas from "./pagesComponents/OffersNewContractCanvas.js";
import NodataFoundTable from "../customComponents/utilities/NodataFoundTable.js";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar.js";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb.js";
import OfferAcceptRejectBadge from "../customComponents/utilities/OfferAcceptRejectBadge.js";
// import ComanOffcanvasMyCandi from "../customComponents/ComanOffcanvasMyCandi.js";

function JobOffer() {
  // const [data, setdata] = useState([]);
  // const [AddCandidateSkills, setAddCandidateSkills] = useState([]);
  // const [AddCandidateLocation, setAddCandidateLocation] = useState([]);

  //pagination
  const [currentPage, setCurrentPage] = useState(0);

  const [DashboardJobOffer, setDashboardJobOffer] = useState([]);
  const [Alloffercount, setAlloffercount] = useState([]);
  const [Interviewloading, setInterviewloading] = useState(true);

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [NewContact, setNewContact] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isRight, setIsRight] = useState(false);
  const [filtervalue, setFiltervalue] = useState("");
  const count = DashboardJobOffer.length;

  const candidateName = (offer) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedCandidate(offer);
    setOffcanvasOpen(true);
  };

  const AddNewContarct = () => {
    setNewContact(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };
  const closeOffcanvasContract = () => {
    setNewContact(false);
  };

  // const dispatch = useDispatch();
  // const partnerDataRes = useSelector((state) => state.partnerSlice?.data.partnerdata);
  // console.warn("partnerDataRes",partnerDataRes);

  const columns = [
    {
      Header: "Offer ID",
      accessor: "offer_id",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateName(row.original);
          }}
        >
          {row.original?.offer_id?.split("-")[0].toUpperCase()}-
          {row.original?.offer_id?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ value }) => (
        <div className="d-flex">
          <CustomAvatar fullName={value} />
          <span>{value}</span>
        </div>
      ),
    },
    {
      Header: "Job Title",
      accessor: "job_title",
      width: 100,
      // Cell: ({ value }) => value.join(', '),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <OfferAcceptRejectBadge status={value} />,
    },
  ];
  // const yourDynamicHeaderData = {
  //   full_name: "full_name",
  //   email: "email",
  //   contact_no: "contact_no",
  //   skills: "skills",
  //   prefered_locations: "prefered_locations",
  //   experience: "experience",
  // };

  // const [formdata, setFormdata] = useState({ newTitle: "", newContent: "" });

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        dashboardOffer({
          currentPage: currentPage ? currentPage : currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);
    return () => clearTimeout(timer);
  }, [currentPage, filtervalue]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.dashboardOffer?.success === true) {
        setInterviewloading(false);
        setAlloffercount(partnerStore.data.dashboardOffer?.data?.totalCount);

        setDashboardJobOffer(
          partnerStore.data.dashboardOffer?.data?.individuals,
        );
      } else if (partnerStore.data.dashboardOffer?.success === false) {
        setInterviewloading(false);
        setAlloffercount(0);
        setDashboardJobOffer([]);
      }
      // if (partnerStore.data.CandidatesSkills?.success === true) {
      //   setAddCandidateSkills(
      //     partnerStore.data.CandidatesSkills?.data?.enumskills,
      //   );
      // }
      // if (partnerStore.data.CandidatesLocation?.success === true) {
      //   setAddCandidateLocation(
      //     partnerStore.data.CandidatesLocation?.data?.enumlocations,
      //   );
      // }
    }
  }, [partnerStore.data.dashboardOffer]);

  // const skillOptions = AddCandidateSkills.map((item) => ({
  //   value: item.code,
  //   label: item.title,
  // }));

  // const locationOptions = AddCandidateLocation.map((item) => ({
  //   value: item.code,
  //   label: item.title,
  // }));
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setInterviewloading(true);
    setFiltervalue(newValue);
  };

  const breadcrumbValues = ["JobOffers"];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="row col-lg-12">
            {/* <CustomBreadcrumb values={breadcrumbValues} /> */}

            <div className="col-lg-12">
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <Row>
                        <div className="col-12 col-lg-6 col-md-6 d-sm-flex">
                          <div className="col-12 col-lg-6">
                            {/* <DropdownWithTwoField
                                // label="Option 1"
                                options={skillOptions}
                                placeholder="Select Technology"
                              /> */}
                            <div className="col-lg-12">
                              <CustomBreadcrumb values={breadcrumbValues} />
                            </div>
                          </div>{" "}
                          &nbsp;
                          {/* <div className="col-12 col-lg-6">
                              <DropdownWithTwoField
                                options={locationOptions}
                                placeholder="Select Locations"
                              />
                            </div> */}
                        </div>
                        <div className="col-12 col-lg-6 col-md-6">
                          <Col md={12}>
                            <CardTitle className="page-title text-muted">
                              <span>Total count : </span>
                              <strong>
                                {Alloffercount ? Alloffercount : 0}
                              </strong>
                            </CardTitle>
                          </Col>
                        </div>
                      </Row>
                    </div>
                    <div>
                      <Card>
                        <CardBody>
                          <Row></Row>
                          <CustomTable
                            // tableDatas={MyCandidateList}
                            // headerData={yourDynamicHeaderData}
                            totalCount={Alloffercount}
                            columns={columns}
                            data={DashboardJobOffer}
                            // jobStatus={true}
                            isLoading={Interviewloading}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            hideCheckbox={true}
                            globalsearch={true}
                            onChange={handleValueChange}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </Container>
      </div>

      {isOffcanvasOpen && (
        <OfferDetailsCanvas
          placement="end"
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={selectedCandidate}
        />
      )}
      {NewContact && (
        <OffersNewContractCanvas
          placement="end"
          isOpen={NewContact}
          toggle={() => setNewContact(!NewContact)}
          onClose={closeOffcanvasContract}
        />
      )}
    </React.Fragment>
  );
}

export default JobOffer;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
} from "reactstrap";
import { lineStyle, dropdownOptions, columns, jobs } from "../data/Data";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { Link } from "react-router-dom";

import dayjs from "dayjs";
import AdvanceFilter from "../customComponents/advance filter/AdvanceFilter";
import {
  getCandidates,
  getClientDashboardCount,
  getInterviews,
} from "./ClientSlice";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import CanvasInterview from "../customComponents/offcanvas/CanvasInterviewDetails";
import Preloader from "../components/Common/Preloader";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar";
import maskSurname from "../helpers/maskename";
import moment from "moment";

function ClientInterviews() {
  const dispatch = useDispatch();
  const clientstore = useSelector((state) => state.clientSlice);
  const [Interviews, setInterviews] = useState([]);
  const [CandidateData, setCandidateData] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [RecordCount, setRecordCount] = useState(0);
  const [filtervalue, setFiltervalue] = useState("");
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getClientDashboardCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  const yourDynamicHeaderData = {
    title: "Job Title",
    location: "Location",
    skills: "Required skills",
    status: "Status",
  };

  const candidatedetails = (data) => {
    setCandidateData(data);
    setOffcanvasOpen(true);
  };

  const columns = [
    {
      Header: "Interview Id",
      accessor: "guid",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidatedetails(row.original);
          }}
        >
          {row.original?.guid?.split("-")[0].toUpperCase()}-
          {row.original?.guid?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Candidate",
      accessor: "individuals.full_name",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            //candidateName(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar
              fullName={row.original.individuals.full_name.charAt(0)}
            />
            <span style={{ marginTop: "5px" }}>
              {maskSurname(row.original.individuals.full_name)}
            </span>
          </div>
        </Link>
      ),
    },

    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Scheduled Date",
      accessor: "scheduled_at",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex gap-1">
          {/* <p className="font-size-12 m-0"></p> */}
          <Badge
            color={
              row.values.status === "selected"
                ? "success text-white rounded-pill"
                : "warning text-white rounded-pill"
            }
          >
            {row.original.type.toUpperCase()} {row.values.status}
          </Badge>
        </div>
      ),
    },
    // {
    //   Header: "Actions",
    //   accessor: "",
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  const [Location, setLocation] = useState([]);
  const [JobRole, setJobRole] = useState([]);

  const handleFilterChange = (option) => {
    if (option && option.location) {
      const locationLabels = option.location.map((location) => location.label);
      setLocation(locationLabels);
    }

    if (option && option.jobRole) {
      const jobRoleLabels = option.jobRole.map((jobRole) => jobRole.label);
      setJobRole(jobRoleLabels);
    }
  };

  // useEffect(() => {
  //   dispatch(
  //     getInterviews({
  //       limit: 100,
  //       offset: currentPage,
  //     }),
  //   );
  // }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        getInterviews({
          offset: currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);
    return () => clearTimeout(timer);
  }, [currentPage, filtervalue]);

  const [Jobdata, setJobdata] = useState([]);
  const [Candidate, setCandidate] = useState([]);

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.interviews?.success === true) {
        setInterviews(clientstore.data.interviews?.data.individuals);
        const jobrole = Interviews.map((jobs) => jobs.jobdescriptions);
        setJobdata(jobrole);
        setRecordCount(clientstore.data.interviews?.data?.totalCount);
      }
      setisLoading(false);
    } else if (clientstore.status === "loading") {
      setisLoading(true);
    }
  }, [clientstore.data.interviews]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setisLoading(true);
    setFiltervalue(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Preloader />}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Interviews"
            Name="Interviews"
          />
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <Card
                    className=" pt-3"
                  > */}
                  <CardBody>
                    <Row>
                      {/* <AdvanceFilter onFilterChange={handleFilterChange} /> */}
                      <div className="col-12 col-lg-12 col-md-6">
                        <Col md={12}>
                          <CardTitle className="text-end fs-6 pt-3">
                            Total Interviews : {RecordCount}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                  {/* </Card> */}
                </div>
                <div>
                  <Card className="">
                    <CardBody>
                      <CustomTable
                        tableDatas={Interviews}
                        headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={Interviews}
                        isLoading={isLoading}
                        hideCheckbox={true}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        ShowPagination={
                          Interviews && Interviews.length > 10 ? true : false
                        }
                        totalCount={RecordCount}
                        onChange={handleValueChange}
                        globalsearch={true}
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <CanvasInterview
          userType={"client"}
          menuOption="appliedcandidates"
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={CandidateData}
        />
      )}
    </React.Fragment>
  );
}

export default ClientInterviews;

import React, { useEffect, useState } from "react";

function Preloader(status) {
  const [isPreloader, setisPreloader] = useState(status);

  useEffect(() => {
    // //init body click event fot toggle rightbar
    // document.body.addEventListener("click", hideRightbar, true);

    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
    }
  }, [isPreloader]);
  return (
    <>
      <div className="pace pace-active" id="preloader">
        <div
          className="pace-progress"
          data-progress-text="100%"
          data-progress="99"
          style={{ transform: "translate3d(100%, 0px, 0px)" }}
        >
          <div className="pace-progress-inner"></div>
        </div>
        <div className="pace-activity"></div>
      </div>
    </>
  );
}

export default Preloader;

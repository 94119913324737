import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
// import nodatafound from "../../../src/assets/images/companyBenchImage/nodatafound.png";
import historyEmpty from "../../../src/assets/images/companyBenchImage/historyEmpty.svg";
import { Link } from "react-router-dom";

function HistoryEmpty() {
  return (
    <Row>
      <Col
        className="col-12 d-flex justify-content-center align-items-center w-100 "
        style={{ height: 350 }}
      >
        <div className="text-center ">
          <img
            src={historyEmpty}
            style={{ width: "70px" }}
            alt="Dropzone Image"
            className="py-3 d-block mx-auto" // Center the image horizontally
          />
          <h2 className="text-muted font-size-44">History not available</h2>
          <p className="text-center">
            Unfortunately, there is no history available at the moment for this
            job.
            <br />
            Please check back later or try a different jobs.
          </p>
          {/* <Link to={"/dashboard"} className="btn btn-primary">
            Go to dashboard
          </Link> */}
        </div>
      </Col>
    </Row>
  );
}

export default HistoryEmpty;

import React from "react";
import { log_debug } from "../../apiHelpers/logger";
import { useEffect } from "react";
import {
  formatDatedd_mmm_yyyy,
  getDatesOfWeek,
  getFirstAndLastDayOfWeek,
} from "../../helpers/date";
import { useState } from "react";

function DisplayDates({ row }) {
  const [Firstday, setFirstday] = useState("");
  const [Lastday, setLastday] = useState("");

  useEffect(() => {
    const weekdetails = getDatesOfWeek(row.week, row.year);
    log_debug("recieved week number", row.week);
    const { firstDay, lastDay } = getFirstAndLastDayOfWeek(weekdetails);
    setFirstday(firstDay);
    setLastday(lastDay);
  }, [row]);
  return (
    <div>
      {formatDatedd_mmm_yyyy(Firstday)} - {formatDatedd_mmm_yyyy(Lastday)},{" "}
      {row.year}
    </div>
  );
}

export default DisplayDates;

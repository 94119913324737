import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import logo from "../../../assets/images/tiq_logo.png";

// import logger from "../../../helpers/logger/logger";
import { log_debug } from "../../../apiHelpers/logger";
import { useDispatch, useSelector } from "react-redux";
import { downloadCertificate } from "../../../individual/individualSlice";
import Preloader from "../../../components/Common/Preloader";

function TIQcard({
  jobname,
  data,
  menuOption,
  candidatestatus,
  behavioural,
  coding,
  mcq,
  assessmentguid,
}) {
  // const [tiqscore, setTiqscore] = useState(null);
  const [showpreloader, setshowpreloader] = useState(false);
  const dispatch = useDispatch();
  const individualStore = useSelector((state) => state.individualSlice);
  // useEffect(() => {
  //   if (data.score != null) {
  //     if (data.score >= 10) {
  //       setTiqscore((data.score / 10) * 10);
  //     } else {
  //       setTiqscore(data.score * 10);
  //     }
  //   } else {
  //     setTiqscore(null);
  //   }
  // }, []);
  // log_debug("score", data.score);

  const handleCertificateDownload = (guid) => {
    dispatch(downloadCertificate(guid))
      .then((response) => {
        log_debug("response", response);
        const certificateUrl = response.payload.data?.certificate?.s3_url;

        if (certificateUrl) {
          window.open(certificateUrl);
        } else {
          log_debug("certificate url", certificateUrl);
          console.error("Certificate URL not available");
        }
      })
      .catch((error) => {
        console.error("Error downloading certificate:", error);
      });
  };

  useEffect(() => {
    if (individualStore.status === "loading") {
      setshowpreloader(true);
    } else {
      setshowpreloader(false);
    }
  }, [individualStore]);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12  px-2 card bg-light">
        {showpreloader && <Preloader />}
        {/* <div className="candidateCard  rounded p-2"> */}
        <Card className="bg-light m-0">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="w-100">
                <h5 className="card-title me-2 text-primary">
                  TalentIQ Score&nbsp;
                  <span className="font-size-10">
                    <em>
                      <i className="fas fa-info-circle" /> What is this?
                    </em>
                  </span>
                </h5>
              </div>
              <div className="flex-shrink w-25">
                <p className="font-size-10 text-muted m-0">
                  <span className="ms-2">Powered By </span>
                  <img className="w-100" src={logo} />
                </p>
              </div>
              {/* <div className="ms-auto">
                <select
                  className="form-select form-select-sm"
                  onChange={(e) => onSelectData(e.target.value)}
                >
                  <option value="AP">April</option>
                  <option value="MA">March</option>
                  <option value="FE">February</option>
                  <option value="JA">January</option>
                  <option value="DE">December</option>
                </select>
              </div> */}
            </div>

            <Row className="align-items-center">
              {/* <div className="col-sm"> */}
              {/* <div id="invested-overview" className="apex-charts"> */}
              {/* <ApexRadial InvestedData={InvestedData.data} /> */}
              {/* </div> */}
              {/* </div> */}
              <div className="col-sm align-self-center">
                <div className="mt-1 mt-sm-0">
                  <p className="mb-1 mt-1 text-uppercase font-size-11">
                    {jobname}
                  </p>
                  <div className="" style={{ width: "50px", height: "50px" }}>
                    <CircularProgressbarWithChildren
                      styles={{
                        root: {}, // Adjust these values
                        path: {
                          stroke: "#5156be",
                          strokeLinecap: "round",
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                          pathColor: "#74788d",
                        },
                        trail: {
                          stroke: "#d6d6d6",
                          strokeLinecap: "round",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                      }}
                      value={
                        data && data.score != null ? data.score.tiq_score : 0
                      }
                    >
                      <div style={{ fontSize: 12 }}>
                        {/* <strong>{tiqscore}{tiqscore != null && <>%</>}</strong> */}
                        <strong>
                          {data && data.score
                            ? (data &&
                              data.score.tiq_score &&
                              data.score.tiq_score === 0
                                ? 0
                                : data.score.tiq_score.toFixed(0)
                              ).concat("%")
                            : "-"}
                        </strong>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>

                  {/* <p className="text-muted mb-4">
                    {" "}
                    + 0.0012.23 ( 0.2 % ){" "}
                    <i className="mdi mdi-arrow-up ms-1 text-success"></i>
                  </p> */}

                  <Row className="g-0 mt-3">
                    <Col xs={3}>
                      <div>
                        <p className="mb-1 text-uppercase font-size-11">MCQs</p>
                        <h5 className="font-size-12 text-muted">
                          {data
                            ? data?.score?.mcq?.score
                              ? data?.score?.mcq?.score
                              : "-"
                            : "-"}
                        </h5>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <p className="mb-1 text-uppercase font-size-11 fw-bold">
                          Coding
                        </p>
                        <h5 className="font-size-12 text-muted">
                          {data
                            ? data?.score?.coding?.score
                              ? data?.score?.coding?.score
                              : "-"
                            : "-"}
                        </h5>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <p className="mb-1 text-uppercase font-size-11 fw-bold">
                          Behavioral
                        </p>
                        <h5 className="font-size-12 text-muted">
                          {data
                            ? data?.score?.behavioural?.score
                              ? data?.score?.behavioural?.score
                              : "-"
                            : "-"}
                        </h5>
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-2 d-flex">
                    {/* {menuOption !== "assessment" && (
                      <Link to="#" className="btn btn-secondary btn-sm m-1">
                        View more <i className="far fa-eye ms-1"></i>
                      </Link>
                    )} */}
                    {menuOption === "assessment" && (
                      <Link
                        to="#"
                        onClick={() =>
                          handleCertificateDownload(
                            assessmentguid && assessmentguid,
                          )
                        }
                        className="btn btn-primary btn-sm m-1"
                      >
                        Certificate{" "}
                        <i className="fas fa-file-download ms-1"></i>
                      </Link>
                    )}
                    {/* {menuOption === "assessment" && (
                      <Link to="#" className="btn  btn-sm m-1">
                        Retake <i className="fas fa-brain ms-1"></i>
                      </Link>
                    )} */}
                    {menuOption === "jobs" && (
                      <Link to="#" className="btn btn-primary btn-sm m-1">
                        Take Assessment{" "}
                        <i className="mdi mdi-arrow-right ms-1"></i>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
        {/* </div> */}
      </div>
    </>
  );
}

export default TIQcard;

import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";
import { FiUserPlus } from "react-icons/fi";
import { FaBusinessTime } from "react-icons/fa6";
//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images
import giftBox from "../assets/images/giftbox.png";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";
// import withRouter from "../Common/withRouter"
import withRouter from "../components/Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import { Badge, Button } from "reactstrap";
import { useState } from "react";
import { useSelector } from "react-redux";

const ClientSidebar = (props) => {
  const ref = useRef();
  const clientStore = useSelector((state) => state.clientSlice);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const [counterData, setcounterData] = useState([]);
  const { my_jobs, interviews, invitations, offers, contracts } = counterData;
  useEffect(() => {
    if (clientStore.status === "idle") {
      if (clientStore.data.ClientDashboardCount?.success === true) {
        setcounterData(
          clientStore.data.ClientDashboardCount?.data?.dashboard_counts,
        );
      }
    }
  }, [clientStore.data.ClientDashboardCount]);

  return (
    <React.Fragment>
      <SimpleBar ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/client/dashboard" className="mt-2">
                <FeatherIcon icon="home" /> <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/client/jobs"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="briefcase" />
                  <span>{props.t("My Jobs")}</span>
                </div>
                <Badge color="primary">{my_jobs?.total_my_jobs}</Badge>
              </Link>
            </li>
            <li>
              <Link
                to="/client/matchingcandidates"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="users" />
                  <span>{props.t("Matching Candidates")}</span>
                </div>
                {/* <Badge color="primary">
                  {my_jobs?.total_my_jobs}
                </Badge> */}
              </Link>
            </li>
            <li>
              <Link
                to="/client/appliedcandidates"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="users" />
                  <span>{props.t("Applied Candidates")}</span>
                </div>
                {/* <Badge color="primary">
                  {my_jobs?.total_my_jobs}
                </Badge> */}
              </Link>
            </li>

            <li>
              <Link
                to="/client/shortlistedcandidates"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="users" />
                  <span>{props.t("Shortlisted")}</span>
                </div>
                {/* <Badge color="primary">
                  {my_jobs?.total_my_jobs}
                </Badge> */}
              </Link>
            </li>
            <li>
              <Link
                to="/client/invitations"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="users" />
                  <span>{props.t("Invitations")}</span>
                </div>
                <Badge color="primary">{invitations?.total_invitations}</Badge>
              </Link>
            </li>
            <li>
              <Link
                to="/client/allcandidates"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="users" />
                  <span>{props.t("All Candidates")}</span>
                </div>
                {/* <Badge color="primary">
                  {my_jobs?.total_my_jobs}
                </Badge> */}
              </Link>
            </li>

            <li>
              <Link
                to="/client/interviews"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="folder" />
                  <span>{props.t("Interviews")}</span>
                </div>
                <Badge color="primary">{interviews?.total_interviews}</Badge>
              </Link>
            </li>
            <li>
              <Link
                to="/client/offers"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="award" />
                  <span>{props.t("Offers")}</span>
                </div>
                <Badge color="primary">{offers?.total_offers}</Badge>
              </Link>
            </li>
            <li>
              <Link
                to="/client/contracts"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <FeatherIcon icon="pen-tool" />
                  <span>{props.t("Contracts")}</span>
                </div>
                <Badge color="primary">{contracts?.total_contract}</Badge>
              </Link>
            </li>
            {/* <li>
              <Link to="/client-post-job" className="w-75 m-auto">
                <Button color="primary">
                  <FeatherIcon icon="clipboard" className="text-white" />{" "}
                  <span>{props.t("Post Job")}</span>
                </Button>
              </Link>
            </li> */}
          </ul>
          {/* <div className="card sidebar-alert border-0 text-center mx-4 mb-0 mt-5">
            <div className="card-body">
              <img src={giftBox} alt="" />
              <div className="mt-4">
                <h5 className="alertcard-title font-size-16">
                  Unlimited Access
                </h5>
                <p className="font-size-13">
                  Upgrade your plan from a Free trial, to select ‘Business
                  Plan’.
                </p>
                <a href="#!" className="btn btn-primary mt-2">
                  Upgrade Now
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

ClientSidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ClientSidebar));

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
  Button,
} from "reactstrap";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import { lineStyle, dropdownOptions, columns, jobs } from "../data/Data";
import DropDownHeader from "../customComponents/DropDownHeader";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { Link } from "react-router-dom";
import CanvasJobDescEdit from "../customComponents/offcanvas/clientJob/CanvasJobDescEdit";
import dayjs from "dayjs";
import {
  ClientAllJobs,
  getClientDashboardCount,
  getJobDescription,
} from "./ClientSlice";
import CanvasJobDescription from "../customComponents/offcanvas/CanvasJobdescription";
import AdvanceFilter from "../customComponents/advance filter/AdvanceFilter";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import Preloader from "../components/Common/Preloader";
import { log_debug } from "../apiHelpers/logger";
import { FiEdit } from "react-icons/fi";
import StatusBadge from "../customComponents/utilities/StatusBadge";
import moment from "moment";

function ClientJobs() {
  const dispatch = useDispatch();
  const clientstore = useSelector((state) => state.clientSlice);
  const [ClientJobs, setClientJobs] = useState([]);
  const [JobDescription, setJobDescription] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [isEditJdOpen, setIsEditJdOpen] = useState(false);
  const [isPreloader, setisPreloader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [jobDesc, setJobDesc] = useState({});
  const [RecordCount, setRecordCount] = useState(0);
  const [rowIndex, SetRowIndex] = useState();
  const [filteractive, setFilteractive] = useState(false);
  const [filtervalue, setFiltervalue] = useState("");

  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getClientDashboardCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  let findIndex = "";

  const closeOffcanvas = () => {
    setshowcanvas(false);
    setOffcanvasOpen(false);
  };
  const closeEditJdOffcanvas = () => {
    setIsEditJdOpen(false);
  };
  const handleJdEditAction = (e, JodDescrip, index) => {
    SetRowIndex(index);
    setJobDesc(JodDescrip);
    setIsEditJdOpen(true);
  };

  // useEffect(() => {
  //   dispatch(
  //     ClientAllJobs({
  //       limit: 100,
  //       offset: currentPage,
  //     }),
  //   );
  // }, []);
  // useEffect(() => {
  //   dispatch(
  //     ClientAllJobs({
  //       limit: 100,
  //       offset: currentPage,
  //       skills:"",
  //       locations:""
  //     }),
  //   );
  // }, [currentPage]);
  const handleSubmitData = (submittedData, rowIndex) => {
    // findIndex = ClientJobs.findIndex((job)=>job.guid !== submittedData.job_description.jobdescription.guid)
    // console.log("find index -->", findIndex);
    // tempJobdata = ClientJobs.filter((job)=>job.guid !== submittedData.job_description.jobdescription.guid);

    // tempJobdata.push(submittedData.job_description.jobdescription);
    const tempJobdata = [...ClientJobs]; // Create a copy of ClientJobs array
    tempJobdata[rowIndex] = submittedData.job_description.jobdescription; // Replace the job at findIndex with submitted job data
    setClientJobs(tempJobdata); // Update the state with the modified array
  };

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.clientjobs?.success === true) {
        setClientJobs(clientstore.data.clientjobs?.data?.jobdescriptions);
        setRecordCount(clientstore.data.clientjobs?.data?.totalCount);
        log_debug("client jobs", ClientJobs);
      }
      // if (clientstore.data.jobdescription?.success === true) {
      //   setJobDescription(
      //     clientstore.data.jobdescription?.data?.jobdescription,
      //   );
      //   log_debug("job description", JobDescription);
      // }
      setisPreloader(false);
    } else if (clientstore.status === "loading") {
      setisPreloader(true);
    }
  }, [clientstore.data.clientjobs]);

  const [showcanvas, setshowcanvas] = useState(false);

  // useEffect(() => {
  //   if (showcanvas) {
  //     if (clientstore.data.jobdescription?.success === true) {
  //       setOffcanvasOpen(true);
  //     }
  //   }
  // }, [JobDescription]);

  const yourDynamicHeaderData = {
    title: "Job Title",
    location: "Location",
    skills: "Required skills",
    status: "Status",
  };

  const jobdescription = (data) => {
    setJobDescription(data);
    // dispatch(getJobDescription(guid));
    setOffcanvasOpen(true);
  };

  const columns = [
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            jobdescription(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ value }) => value?.join(", "),
    },
    {
      Header: "Required Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: ({ row }) => (

      //          row.values.skills.map((skill, index) => (
      //            <Badge key={index}  className="bg-secondary-subtle text-secondary rounded-pill m-1 font-size-12">
      //              {skill}
      //            </Badge>
      //          ))

      //      ),
      // Cell: (props) =>
      //   const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
      //   return <span>{tidyDate}</span>;
      // },
    },

    {
      Header: "Posted on",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) =>
        value ? (
          <StatusBadge status={value} />
        ) : (
          // <Badge
          //   color={
          //     value === "active"
          //       ? "success text-white rounded-pill "
          //       : "danger text-white rounded-pill "
          //   }
          // >
          //   {value}
          // </Badge>
          "-"
        ),
    },
    {
      Header: "Actions",
      accessor: "",
      Cell: ({ row }) => (
        <>
          <div className="text-center">
            <Link
              to={"#"}
              color="primary"
              type="button"
              title="Edit Job"
              className=" btn btn-primary text-white rounded"
              onClick={(e) => {
                handleJdEditAction(e, row.original, row.index);
              }}
            >
              <FiEdit className="mx-1" />

              {/* Edit */}
            </Link>
          </div>
        </>
      ),
    },

    // Add more columns as needed
  ];

  // const closeOffcanvas = () => {
  //   setOffcanvasOpen(false);
  // };

  const [Location, setLocation] = useState([]);
  const [JobRole, setJobRole] = useState([]);

  const handleFilterChange = (option) => {
    if (option && option.location) {
      setFilteractive(true);
      const locationLabels = option.location.map((location) => location.label);
      setLocation(locationLabels);
    } else {
      setFilteractive(false);
    }

    if (option && option.jobRole) {
      setFilteractive(true);
      const jobRoleLabels = option.jobRole.map((jobRole) => jobRole.label);
      setJobRole(jobRoleLabels);
    } else {
      setFilteractive(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        ClientAllJobs({
          offset: currentPage,
          skills: JobRole,
          locations: Location,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [currentPage, Location, JobRole, filtervalue]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setisPreloader(true);
    setFiltervalue(newValue);
  };

  return (
    <React.Fragment>
      {isPreloader && <Preloader status={true} />}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="My Jobs" Name="My Jobs" />

          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <Card
                    className=" pt-3"
                  > */}
                  <CardBody>
                    <Row>
                      <AdvanceFilter onFilterChange={handleFilterChange} />
                      <div className="col-12 col-lg-6 col-md-6">
                        <Col md={12}>
                          <CardTitle className="text-end fs-6 pt-3">
                            Total Jobs : {RecordCount}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                  {/* </Card> */}
                </div>
                <div>
                  <Card className="">
                    <CardBody>
                      <CustomTable
                        tableDatas={ClientJobs}
                        showopostjob={
                          ClientJobs
                            ? ClientJobs.length === 0 && !filteractive
                              ? true
                              : false
                            : false
                        }
                        headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={ClientJobs}
                        postjob={true}
                        isLoading={isPreloader}
                        hideCheckbox={true}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        ShowPagination={
                          ClientJobs && ClientJobs.length > 10 ? true : false
                        }
                        totalCount={RecordCount}
                        globalsearch={true}
                        onChange={handleValueChange}
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <CanvasJobDescription
          usertype="client"
          menuOption="jobs"
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={JobDescription}
        />
      )}
      {isEditJdOpen && (
        <CanvasJobDescEdit
          rowIndex={rowIndex}
          isOpen={isEditJdOpen}
          toggle={() => setIsEditJdOpen(!isEditJdOpen)}
          onClose={closeEditJdOffcanvas}
          data={jobDesc}
          onSubmit={handleSubmitData}
        />
      )}
    </React.Fragment>
  );
}

export default ClientJobs;

import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Badge,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getCityCountry,
  postSendAssessmentLink,
} from "../../../individual/individualSlice";
import { getCountries } from "../../../individual/individualSlice";
import { postIndividualProfileDetails } from "../../../individual/individualSlice";
import {
  // RoleBasedSkill,

  addCandidatesSkills,
  myCandiDetailsShortlistedRole,
} from "../../../Partner/PartnerSlice";
import { RoleBasedSkill } from "../../../Client/ClientSlice";
import validator from "validator";
import moment from "moment/moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const IndividualEditDetailsCanvas = ({ isOpen, toggle, onClose, data }) => {
  const StoreCountries = useSelector((state) => state.individualSlice);
  const StoreCityCountry = useSelector((state) => state.individualSlice);
  const partnerStore = useSelector((state) => state.partnerSlice);
  const clientStore = useSelector((state) => state.clientSlice);

  const [allCountries, setAllCountries] = useState([]);
  const [allCitiesOfCountry, setAllCitiesOfCountry] = useState([]);
  const [MyCandidateDetailsRoleDropdown, setMyCandidateDetailsRoleDropdown] =
    useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [presentCountry, setPresentCountry] = useState(data.address.country);
  const [presentCity, setPresentCity] = useState(data.address.city);
  const [presentStreet, setPresentStreet] = useState(data.address.street);

  let updatedAddress = {
    // address_line_1: "",
    // address_line_2: "",
    city: "",
    street: "",
    country: "",
  };

  const roleoptions = MyCandidateDetailsRoleDropdown.map((item) => ({
    id: item._id,
    value: item.code,
    label: item.title,
  }));
  // Redux dispatch function
  // const dispatch = useDispatch();

  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  let selectedCountry = "";
  // Extracting the address object from the data object
  const { address, ...restData } = data;

  // Creating the initial state by merging restData with address and data
  const [profileData, setProfileData] = useState({
    ...restData,
    ...address,
    ...data, // Preserve the original keys and values from data object
  });
  const maritalStatus = ["married", "unmarried"];
  const availabilities = ["full-time", "part-time", "contractual"];

  useEffect(() => {
    if (profileData?.skills) {
      let tempSkill = [...new Set(profileData?.skills)];
      tempSkill = tempSkill.map((skill) => ({
        label: skill,
        value: skill,
      }));
      setSelectedSkills(tempSkill);
    }
    if (profileData?.role) {
      dispatch(RoleBasedSkill(profileData?.role));
    }
  }, [profileData?.role]);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(myCandiDetailsShortlistedRole());
    dispatch(addCandidatesSkills());
  }, []);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data.myCandiDetailsShortlistedRoleRecords?.success === true
      ) {
        setMyCandidateDetailsRoleDropdown(
          partnerStore.data.myCandiDetailsShortlistedRoleRecords?.data
            .enumjobroles,
        );
      }
      if (partnerStore.data?.CandidatesSkills?.success === true) {
        let tempSkill =
          partnerStore.data?.CandidatesSkills?.data?.enumskills?.map(
            (skill) => ({
              label: skill.title,
              value: skill.code,
            }),
          );
        setAllSkills(tempSkill);
      }

      if (
        clientStore.data?.RoleBasedSkill?.success === true &&
        profileData?.role
      ) {
        let tempSkill = clientStore.data?.RoleBasedSkill?.data?.skills?.map(
          (skill) => ({
            label: skill,
            value: skill,
          }),
        );

        setSelectedSkills(tempSkill);
        let editData = profileData;
        editData = { ...editData, skills: tempSkill.map((sk) => sk.label) };
        setProfileData(editData);
      }
    }
  }, [
    partnerStore.data.myCandiDetailsShortlistedRoleRecords,
    partnerStore.data?.CandidatesSkills,
    clientStore.data?.RoleBasedSkill,
  ]);

  // useEffect(() => {
  //   if (clientStore.data?.RoleBasedSkill?.success === true && profileData?.role) {
  //     let tempSkill = clientStore.data?.RoleBasedSkill?.data?.skills?.map(
  //       (skill) => ({
  //         label: skill,
  //         value: skill,
  //       }),
  //     );

  //     console.log("temp skills -->", tempSkill)
  //     setSelectedSkills(tempSkill);
  //     let editData = profileData;
  //     editData = { ...editData, skills: tempSkill.map((sk) => sk.label) };
  //     setProfileData(editData);
  // } }, [clientStore])

  useEffect(() => {
    if (StoreCountries.status === "idle") {
      if (StoreCountries?.data?.Countries?.success === true) {
        setAllCountries(StoreCountries?.data?.Countries?.data?.enumcountrys);
        // setProfileData(StoreCountries?.data?.Countries?.data);
      }
    }
  }, [StoreCountries]);
  useEffect(() => {
    dispatch(getCityCountry(profileData?.address?.country));
  }, []);

  const handleSkills = (skill) => {
    let editData = { ...profileData };
    editData = { ...editData, skills: skill.map((sk) => sk.label) };
    setProfileData(editData);
    setSelectedSkills(skill);
  };

  const handleInputChange = (field, value1) => {
    // Validation checks
    let updatedErrors = { ...validationErrors };
    let currentErrors = {};

    switch (field) {
      case "company_name":
        currentErrors[field] = validator.isEmpty(value1)
          ? "company name is required"
          : "";
        break;
      case "contact_number":
        currentErrors[field] = validator.isEmpty(value1.toString())
          ? "contact number is required"
          : "";
        break;
      case "contact_person":
        currentErrors[field] = validator.isEmpty(value1)
          ? "contact person is required"
          : "";
        break;
      case "email":
        currentErrors[field] = validator.isEmpty(value1)
          ? "Email is required"
          : "";
        break;
      case "city":
        setPresentCity(value1);
        currentErrors[field] = validator.isEmpty(value1)
          ? "city is required"
          : "";
        break;
      // case "marital_status":
      //   currentErrors[field] = validator.isEmpty(value1)
      //     ? "marital status is required"
      //     : "";
      //   break;
      case "street":
        setPresentStreet(value1);
        currentErrors[field] = validator.isEmpty(value1)
          ? "street is required"
          : "";
        break;
      case "country":
        selectedCountry = value1;
        setPresentCountry(value1);
        dispatch(getCityCountry(selectedCountry));
        currentErrors[field] = validator.isEmpty(value1)
          ? "country is required"
          : "";
        break;
      case "summary":
        currentErrors[field] = validator.isEmpty(value1)
          ? "summary is required"
          : "";
        break;
      case "role":
        currentErrors[field] = validator.isEmpty(value1)
          ? "role is required"
          : "";
        break;
      case "availability":
        currentErrors[field] = validator.isEmpty(value1)
          ? "availability is required"
          : "";
        break;
      // case "website":
      //   currentErrors[field] = validator.isEmpty(value1)
      //     ? "website is required"
      //     : "";
      //   break;
      case "linkedin_url":
        currentErrors[field] = validator.isEmpty(value1)
          ? "linked is required"
          : "";
        break;
      case "date_of_birth":
        currentErrors[field] = validator.isEmpty(value1)
          ? "Date of birth is required"
          : "";
        break;
      default:
        break;
    }
    setValidationErrors(currentErrors);

    setProfileData({
      ...profileData,
      [field]: value1,
    });
  };
  useEffect(() => {
    if (StoreCityCountry.status === "idle") {
      if (StoreCityCountry?.data?.countryCities?.success === true) {
        setAllCitiesOfCountry(
          StoreCityCountry?.data?.countryCities?.data?.enumcitys,
        );
      }
    }
  }, [StoreCityCountry]);
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const { address: originalAddress, ...restProfileData } = profileData;
    // Validation before submission
    let errors = {};
    for (const key in profileData) {
      if (profileData.hasOwnProperty(key)) {
        const value = profileData[key];
        if (key === "full_name") {
          errors[key] = validator.isEmpty(value) ? `name is required` : "";
        } else if (key === "contact_number") {
          const phoneNumber = value.toString();
          if (validator.isEmpty(phoneNumber)) {
            errors[key] = "Contact number is required";
          } else if (!/^\+?\d{1,13}$/.test(phoneNumber)) {
            errors[key] =
              "Please enter a valid contact number with maximum 13 digits and optionally starting with +";
          } else {
            errors[key] = ""; // No errors
          }
        } else if (key === "email") {
          errors[key] = validator.isEmpty(value)
            ? "Email is Required"
            : !validator.isEmail(value, {
                  blacklisted_chars: "-$#~'!%^&*()+={}|/?\"",
                })
              ? `Please Enter Valid Email`
              : "";
        } else if (key === "city") {
          errors[key] = validator.isEmpty(value) ? `city is required` : "";
        } else if (key === "street") {
        } else if (key === "role") {
          errors[key] = validator.isEmpty(value) ? `role is required` : "";
        } else if (key === "street") {
          errors[key] = validator.isEmpty(value) ? `street is required` : "";
        } else if (key === "availability") {
          errors[key] = validator.isEmpty(value)
            ? `availability is required`
            : "";
        } else if (key === "summary") {
          errors[key] = validator.isEmpty(value)
            ? "summary is required"
            : !validator.isLength(value, { min: 0, max: 1000 })
              ? `Character Length Exceeded`
              : "";
        } else if (key === "linkedin_url") {
          errors[key] = validator.isEmpty(value)
            ? "linkedIn is Required"
            : !validator.isURL(value) ||
                !value.includes("https://www.linkedin.com")
              ? "Please Enter Valid linkedIn Profile URL"
              : "";
        }
        if (selectedSkills.length === 0) {
          errors["skills"] = "Please select at least one skill";
        }
      }
    }
    setValidationErrors(errors);

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (hasErrors) {
      return;
    }

    updatedAddress = {
      // address_line_1: profileData.address_line_1,
      // address_line_2: profileData.address_line_2,
      city: presentCity || profileData.city,
      street: presentStreet || profileData.street,
      country: presentCountry || profileData.country,
    };
    // Create the updated client object with the updated address
    const updatedIndividual = {
      individual: {
        role: profileData.role,
        full_name: profileData.full_name,
        contact_number: profileData.contact_number,
        // contact_person: profileData.contact_person,
        date_of_birth: profileData.date_of_birth,
        email: profileData.email,
        address: updatedAddress,
        skills: selectedSkills.map((skill) => skill.value),
        // website: profileData.website,
        //marital_status: profileData.marital_status,
        summary: profileData.summary,
        linkedin_url: profileData.linkedin_url,
        availability: profileData.availability,
      },
    };

    const assessmentinvitebody = {
      individual: {
        role: `${profileData && profileData.role}`,
      },
    };
    dispatch(postSendAssessmentLink({ data: assessmentinvitebody }));

    dispatch(postIndividualProfileDetails(updatedIndividual))
      .then(() => {
        // Close the canvas after dispatching
        toggle();
      })
      .catch((error) => {
        // Handle error if needed
      });

    setProfileData({
      ...restProfileData, // Include the rest of the profileData
      address: updatedAddress, // Update the address object
    });
    // Create the partner object with updated values

    // Create the client object with updated values
    const individual = {
      ...profileData,
      address: {
        ...profileData.address,
        [e.target.name]: e.target.value,
      },
    };
  };

  const offcanvasStyle = {
    width: "45%",
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom " toggle={toggle}>
          <h5 className="text-primary">
            Edit Profile Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Present comprehensive
              details.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col xl={12}>
              <div>
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="company_name"
                    >
                      Individual Name <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="full_name"
                        value={profileData.full_name}
                        onChange={(e) =>
                          handleInputChange("full_name", e.target.value)
                        }
                      />
                      {validationErrors.full_name && (
                        <small className="text-danger">
                          {validationErrors.full_name}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label className="col-sm-3 col-form-label" for="email">
                      Email <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="email"
                        value={profileData.email}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                      />
                      {validationErrors.email && (
                        <small className="text-danger">
                          {validationErrors.email}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="professionalRole"
                      className="col-sm-3 col-form-label"
                    >
                      Professional Role <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <select
                        required
                        className="form-select"
                        id="professionalRole"
                        name="role"
                        onChange={(e) =>
                          handleInputChange("role", e.target.value)
                        }
                        // onChange={handleChange}
                        value={profileData?.role}
                      >
                        <option value="" disabled>
                          Select Role
                        </option>
                        {roleoptions.map((role) => (
                          <option key={role.id} value={role.label}>
                            {role.label}
                          </option>
                        ))}
                      </select>
                      {validationErrors.role && (
                        <small className="text-danger">
                          {validationErrors.role}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label htmlFor="skills" className="col-sm-3 col-form-label">
                      Skills <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Select
                        required
                        className="basic-multi-select"
                        name="skills"
                        placeholder="Select Skills"
                        options={allSkills}
                        onChange={handleSkills}
                        value={selectedSkills}
                        isMulti
                        isSearchable
                        isOptionDisabled={() => selectedSkills.length > 6}
                        components={makeAnimated()}
                        // value={editedData?.skills}
                      ></Select>
                      {validationErrors.skills && (
                        <small className="text-danger">
                          {validationErrors.skills}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="linkedin_url"
                    >
                      linkedIn <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="linkedin_url"
                        value={profileData.linkedin_url}
                        onChange={(e) =>
                          handleInputChange("linkedin_url", e.target.value)
                        }
                      />
                      {validationErrors.linkedin_url && (
                        <small className="text-danger">
                          {validationErrors.linkedin_url}
                        </small>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="date_of_birth"
                    >
                      DOB <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="date"
                        name="date_of_birth"
                        value={moment(profileData.date_of_birth).format(
                          "YYYY-MM-DD",
                        )}
                        onChange={(e) =>
                          handleInputChange("date_of_birth", e.target.value)
                        }
                      />
                      {validationErrors.date_of_birth && (
                        <small className="text-danger">
                          {validationErrors.date_of_birth}
                        </small>
                      )}
                    </Col>
                  </Row>
                  {/* <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="company_name"
                    >
                      Availability <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="availability"
                        value={profileData.availability}
                        onChange={(e) =>
                          handleInputChange("availability", e.target.value)
                        }
                      />
                      {validationErrors.availability && (
                        <small className="text-danger">
                          {validationErrors.availability}
                        </small>
                      )}
                    </Col>
                  </Row> */}
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="date_of_birth"
                    >
                      Availability <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      {/* <Label for="country">Country</Label> */}
                      <Input
                        required
                        type="select"
                        name="marital_status"
                        value={profileData.availability}
                        onChange={(e) =>
                          handleInputChange("availability", e.target.value)
                        }
                      >
                        <option value="">Select Availability</option>
                        {availabilities.map((status) => (
                          <option
                            key={status}
                            value={
                              status === "contractual" ? "contracts" : status
                            }
                          >
                            {status}
                          </option>
                        ))}
                      </Input>
                      {validationErrors.availability && (
                        <small className="text-danger">
                          {validationErrors.availability}
                        </small>
                      )}
                    </Col>
                  </Row>
                  {/* <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="date_of_birth"
                    >
                      Marital Status <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      {/* <Label for="country">Country</Label> 
                      <Input
                        required
                        type="select"
                        name="marital_status"
                        value={profileData.marital_status}
                        onChange={(e) =>
                          handleInputChange("marital_status", e.target.value)
                        }
                      >
                        <option value="">Select Marital Status</option>
                        {maritalStatus.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </Input>
                      {validationErrors.marital_status && (
                        <small className="text-danger">
                          {validationErrors.marital_status}
                        </small>
                      )}
                    </Col>
                  </Row> */}

                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="contact_number"
                    >
                      Contact Number <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="text"
                        name="contact_number"
                        value={profileData.contact_number}
                        onChange={(e) =>
                          handleInputChange("contact_number", e.target.value)
                        }
                      />
                      {validationErrors.contact_number && (
                        <small className="text-danger">
                          {validationErrors.contact_number}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="college_or_university_name"
                    >
                      Address <span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Row className="mb-3">
                        <Col sm={4}>
                          {/* <Label for="country">Country</Label> */}
                          <Input
                            required
                            type="select"
                            name="country"
                            value={
                              presentCountry || profileData?.address?.country
                            }
                            onChange={(e) =>
                              handleInputChange("country", e.target.value)
                            }
                          >
                            <option value="" hidden>
                              Select Country
                            </option>
                            {allCountries.map((country) => (
                              <option key={country.title} value={country.title}>
                                {country.title}
                              </option>
                            ))}
                          </Input>
                          {validationErrors.country && (
                            <small className="text-danger">
                              {validationErrors.country}
                            </small>
                          )}
                        </Col>

                        <Col sm={4}>
                          <Input
                            required
                            type="text"
                            placeholder="Street"
                            name="street"
                            value={presentStreet}
                            onChange={(e) =>
                              handleInputChange("street", e.target.value)
                            }
                          />
                          {validationErrors.street && (
                            <small className="text-danger">
                              {validationErrors.street}
                            </small>
                          )}
                        </Col>
                        <Col sm={4}>
                          {/* <Label for="city">City</Label> */}
                          <Input
                            required
                            type="select"
                            name="city"
                            value={presentCity || profileData?.address?.city}
                            onChange={(e) =>
                              handleInputChange("city", e.target.value)
                            }
                          >
                            <option value="" hidden>
                              Select City
                            </option>
                            {allCitiesOfCountry &&
                              allCitiesOfCountry.length > 0 &&
                              allCitiesOfCountry.map((city) => (
                                <option key={city.title} value={city.title}>
                                  {city.title}
                                </option>
                              ))}
                          </Input>
                          {validationErrors.city && (
                            <small className="text-danger">
                              {validationErrors.city}
                            </small>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-sm-3 col-form-label"
                      for="college_or_university_name"
                    >
                      Summary<span className="text-danger">*</span>
                    </Label>
                    <Col sm={9}>
                      <Input
                        required
                        type="textarea"
                        name="summary"
                        value={profileData.summary}
                        onChange={(e) =>
                          handleInputChange("summary", e.target.value)
                        }
                      />
                      {validationErrors.summary && (
                        <small className="text-danger">
                          {validationErrors.summary}
                        </small>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button type="submit" color="primary">
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default IndividualEditDetailsCanvas;

import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumb from "../components/Common/Breadcrumb";
import ClientPostJobForm from "./Components/ClientPostJobForm";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";
import dropzoneimage from "../../src/assets/images/companyBenchImage/cloud-file.gif";
import { log_debug } from "../apiHelpers/logger";

function ClientPostJob() {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    const fileArray = Array.from(acceptedFiles);
    const formData = new FormData();
    fileArray.forEach((file, index) => {
      formData.append(`resumes`, file);
      log_debug("formData-resp", file);
      log_debug("formData-fileArray", formData);
      localStorage.setItem("formData", formData);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf, .docx, .doc", // Specify accepted file formats
  });

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Home" breadcrumbItem="Post-Job" Name="Post-Job" />
        </Container>
        <Row>
          <Col lg={12}>
            <Card className="">
              {/* <CardHeader>
                  <CardTitle className="h4">Custom Tabs</CardTitle>
                  <p className="card-title-desc">Example of custom tabs</p>
                </CardHeader> */}
              <CardBody>
                <Nav tabs className="nav-tabs-custom nav-justified w-25">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block">Fill-Up Form</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Upload Files</span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={customActiveTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="6">
                        <CardText className="mb-0">
                          <ClientPostJobForm />
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <h5>Coming Soon...</h5>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default ClientPostJob;

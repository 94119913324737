import React from "react";

const DetailCard = () => {
  const { jobData } = location.state;
  const { company, designation, location, date, skills } = jobData;

  return (
    <div className="card">
      <div className="card-header">
        <h5>{designation}</h5>
      </div>
      <div className="card-body">
        <p>
          <strong>Company:</strong> {company}
        </p>
        <p>
          <strong>Location:</strong> {location}
        </p>
        <p>
          <strong>Date:</strong> {date}
        </p>
        <p>
          <strong>Skills:</strong>{" "}
          {Array.isArray(skills) ? skills.join(", ") : skills}
        </p>
        <p>
          <strong>Description:</strong> {description}
        </p>
        <p>
          <strong>Requirements:</strong>
          <ul>
            {requirements.map((requirement, index) => (
              <li key={index}>{requirement}</li>
            ))}
          </ul>
        </p>
        <p>
          <strong>Contact:</strong> {contact}
        </p>
      </div>
      <div className="card-footer">
        <a href={`#job-${id}`} onClick={() => handleJobClick(id)}>
          View Details
        </a>
      </div>
    </div>
  );
};

export default DetailCard;

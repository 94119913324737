import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
} from "reactstrap";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { BGVTypeDropdown, BGVTypeDropdownVerifiers } from "../PartnerSlice";
import { log_debug } from "../../apiHelpers/logger";

const initialValues = {
  name: "",
  email: "",
  contactNumber: "",
  bgvType: "",
  verifiers: [{ verifier: "" }],
};

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  contactNumber: Yup.string().required("Required"),
  bgvType: Yup.string().required("Required"),
  verifiers: Yup.array().of(
    Yup.object().shape({
      verifier: Yup.string().required("Required"),
    }),
  ),
});

function InitiateBGVOffcanvas({ isOpen, toggle, onClose, content }) {
  const [BGVTypedata, setBGVTypedata] = useState([]);
  const [BGVTypedataVerify, setBGVTypedataVerify] = useState([]);
  log_debug("BGVTypedataVerify", BGVTypedataVerify);

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);

  const options = BGVTypedata.map((item) => ({
    id: item._id,
    value: item.code,
    label: item.title,
  }));

  const Verifieroption = BGVTypedataVerify.map((item) => ({
    id: item._id,
    value: item.name,
    label: item.name,
  }));

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic
      log_debug(values);
    },
  });

  // const handleAddVerifier = () => {
  //   formik.setFieldValue("verifiers", [
  //     ...formik.values.verifiers,
  //     { verifier: "" },
  //   ]);
  // };

  // const handleRemoveVerifier = (index) => {
  //   const verifiers = [...formik.values.verifiers];
  //   verifiers.splice(index, 1);
  //   formik.setFieldValue("verifiers", verifiers);
  // };
  const offcanvasStyle = {
    width: "45%", // You can set a default width or use the provided width
  };

  useEffect(() => {
    dispatch(BGVTypeDropdown());
    dispatch(BGVTypeDropdownVerifiers());
  }, []);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.BGVTypeDropdownRecords?.success === true) {
        setBGVTypedata(
          partnerStore.data.BGVTypeDropdownRecords?.data?.enumbgvtypes,
        );
      }
      if (partnerStore.data.BGVTypeDropdownVerifiersRecords?.success === true) {
        setBGVTypedataVerify(
          partnerStore.data.BGVTypeDropdownVerifiersRecords?.data
            .backgroundverifiers,
        );
      }
    }
  }, [partnerStore]);

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          <h4 className="">Initiate BGV</h4>
          <span className=" h6">
            {" "}
            <FaRegQuestionCircle className="text-danger" /> Initiate new BGV
            type for candidate
          </span>
        </OffcanvasHeader>
        <OffcanvasBody>
          <span className="text-success">{content}</span>
          <div className="row pt-2">
            <form onSubmit={formik.handleSubmit} className="container mt-3">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger">{formik.errors.name}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-danger">{formik.errors.email}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="contactNumber" className="form-label">
                  Contact Number
                </label>
                <input
                  type="text"
                  id="contactNumber"
                  name="contactNumber"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contactNumber}
                />
                {formik.touched.contactNumber &&
                  formik.errors.contactNumber && (
                    <div className="text-danger">
                      {formik.errors.contactNumber}
                    </div>
                  )}
              </div>

              {/* Repeat similar code for other fields (email, contactNumber, bgvType) */}

              <div className="mb-3">
                <label htmlFor="bgvType" className="form-label">
                  Bgv Type
                </label>
                <select
                  id="bgvType"
                  name="bgvType"
                  className="form-select"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bgvType}
                >
                  <option value="" disabled>
                    Select Bgv Type
                  </option>
                  {options.map((bgvType) => (
                    <option key={bgvType.id} value={bgvType.value}>
                      {bgvType.label}
                    </option>
                  ))}
                </select>

                {formik.touched.bgvType && formik.errors.bgvType && (
                  <div className="text-danger">{formik.errors.bgvType}</div>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">Verifiers</label>
                {formik.values.verifiers.map((verifier, index) => (
                  <div key={index} className="mb-3">
                    <select
                      name={`verifiers[${index}].verifier`}
                      className="form-select"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={verifier.verifier}
                    >
                      <option value="" disabled>
                        Select Verifiers
                      </option>
                      {Verifieroption.map((verify) => (
                        <option key={verify.id} value={verify.value}>
                          {verify.label}
                        </option>
                      ))}
                    </select>
                    {formik.touched.verifiers &&
                      formik.errors.verifiers &&
                      formik.errors.verifiers[index] && (
                        <div className="text-danger">
                          {formik.errors.verifiers[index].verifier}
                        </div>
                      )}
                    {/* {index > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger mt-2"
                        onClick={() => handleRemoveVerifier(index)}
                      >
                        Remove Verifier
                      </button>
                    )} */}
                  </div>
                ))}
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddVerifier}
                >
                  Add Verifier
                </button> */}
              </div>

              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </form>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default InitiateBGVOffcanvas;

import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

const CandidateStatusBadge = ({ status }) => {
  const getColor = (status) => {
    switch (status) {
      case "shortlisted":
        return "success"; // Green background
      case "on_bench":
        return "warning"; // Yellow background
      case "contracted":
        return "primary"; // Primary color background
      default:
        return "light"; // Default background
    }
  };

  return (
    <Badge color={getColor(status)} className="rounded-pill px-3 p-1">
      {status === "on_bench" ? "On Bench" : status}
    </Badge>
  );
};

CandidateStatusBadge.propTypes = {
  status: PropTypes.oneOf(["shortlisted", "on_bench", "contracted"]).isRequired,
};

export default CandidateStatusBadge;

import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Container,
  CardHeader,
  Badge,
} from "reactstrap";
import "../../src/assets/css/styleCompanyBench.css";

import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../components/Common/Breadcrumb";
import dayjs from "dayjs";
import CustomTable from "../customComponents/reactTable/CustomTable.js";
import { Offer, Contract, getClientDashboardCount } from "./ClientSlice";
import { Link } from "react-router-dom";
// import OfferDetailsCanvas from "./pagesComponents/OfferDetailsCanvas.js";
import AdvanceFilter from "../customComponents/advance filter/AdvanceFilter.js";
// import OfferDetailsCanvas from "../customComponents/offcanvas/OfferDetailsCanvas.js";
import OfferDetailsCanvas from "../Partner/pagesComponents/OfferDetailsCanvas.js";
import { comma_formatter } from "../helpers/utils.js";
import { dateyyyymmdd_tohtml } from "../helpers/date.js";
import ContractDetailCanvas from "../customComponents/offcanvas/ContractDetailCanvas.js";
import Preloader from "../components/Common/Preloader.js";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar.js";
import maskSurname from "../helpers/maskename.js";
import moment from "moment";

function ClientContract() {
  const dispatch = useDispatch();
  const clientstore = useSelector((state) => state.clientSlice);

  const [isOpen, setIsOpen] = useState(false);
  const [clientContract, setClientContract] = useState([]);
  const [contract, setContract] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [RecordCount, setRecordCount] = useState(0);
  const [filtervalue, setFiltervalue] = useState("");
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getClientDashboardCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  const handleContract = (row) => {
    setContract(row);
    setIsOpen(true);
  };

  const closeOffcanvas = () => {
    setIsOpen(false);
  };

  const columns = [
    {
      Header: "Contract",
      accessor: "contract_id",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleContract(row.original);
          }}
        >
          {row.original?.contract_id?.split("-")[0].toUpperCase()}-
          {row.original?.contract_id?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Candidate",
      accessor: "individuals",
      Cell: ({ value }) => (
        <>
          {value &&
            value.map((skill, index) => (
              <Link
                key={index}
                to={"#"}
                onClick={() => {
                  //candidateName(row.original);
                }}
              >
                <div style={{ display: "flex", alignItems: "top" }}>
                  <CustomAvatar fullName={skill.full_name.charAt(0)} />
                  <span style={{ marginTop: "5px" }}>
                    {maskSurname(skill.full_name)}
                  </span>
                </div>
              </Link>
            ))}
        </>
      ),
    },
    // {
    //   Header: "Client",
    //   accessor: "company_name",
    //   width: 100,
    // },

    {
      Header: "Value",
      accessor: "contract_value",
      Cell: ({ value }) => (
        <div>
          <i className=" mdi mdi-currency-inr"></i>
          {comma_formatter(value)}
        </div>
      ),
    },
    {
      Header: "Start Date",
      accessor: "started_at",
      width: 100,
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
      // Cell: ({ row }) => {row.original?.started_at ? (dayjs(row.original.started_at).format("DD-MM-YYYY")) : '-'}
    },
    {
      Header: "End Date",
      accessor: "end_date",
      width: 100,
      Cell: ({ value }) => "-",
    },
    {
      Header: "Status",
      accessor: "status",
      width: 200,

      Cell: ({ value }) => (
        <Badge
          color={
            value === "accepted"
              ? "success rounded-pill"
              : "warning rounded-pill"
          }
          className="m-1 font-size-12"
        >
          {value}
        </Badge>
      ),
    },
  ];
  // const yourDynamicHeaderData = {
  //   full_name: "full_name",
  //   email: "email",
  //   contact_no: "contact_no",
  //   skills: "skills",
  //   prefered_locations: "prefered_locations",
  //   experience: "experience",
  // };

  // const [formdata, setFormdata] = useState({ newTitle: "", newContent: "" });

  const [Location, setLocation] = useState([]);
  const [JobRole, setJobRole] = useState([]);

  const handleFilterChange = (option) => {
    if (option && option.location) {
      const locationLabels = option.location.map((location) => location.label);
      setLocation(locationLabels);
    }

    if (option && option.jobRole) {
      const jobRoleLabels = option.jobRole.map((jobRole) => jobRole.label);
      setJobRole(jobRoleLabels);
    }
  };

  // useEffect(() => {
  //   dispatch(
  //     Contract({
  //       limit: 100,
  //       offset: currentPage,
  //     }),
  //   );
  // }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        Contract({
          offset: currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [currentPage, filtervalue]);

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.Contract?.success === true) {
        setClientContract(clientstore.data.Contract?.data?.individuals);
        setRecordCount(clientstore.data.Contract?.data?.totalCount);
        // const jobrole = AppliedCandidates.map((jobs) => jobs.jobdescriptions);
        // setJobdata(jobrole);
      }
      setisLoading(false);
    } else if (clientstore.status === "loading") {
      setisLoading(true);
    }
  }, [clientstore.data.Contract]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setisLoading(true);
    setFiltervalue(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Preloader />}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Contracts"
            Name="Contracts"
          />
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <Card
                    className=" pt-3"
                  > */}
                  <CardBody>
                    <Row>
                      {/* <AdvanceFilter onFilterChange={handleFilterChange} /> */}
                      <div className="col-12 col-lg-12 col-md-6">
                        <Col md={12}>
                          <CardTitle className="text-end fs-6 pt-3">
                            Total Contracts : {RecordCount}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                  {/* </Card> */}
                </div>
                <div>
                  <Card className="">
                    <CardBody>
                      <CustomTable
                        // tableDatas={clientOffers}
                        // headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={clientContract}
                        hideCheckbox={true}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        isLoading={isLoading}
                        ShowPagination={
                          clientContract && clientContract.length > 10
                            ? true
                            : false
                        }
                        totalCount={RecordCount}
                        onChange={handleValueChange}
                        globalsearch={true}
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {isOpen && (
        <ContractDetailCanvas
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          onClose={closeOffcanvas}
          data={contract}
          userType={"client"}
        />
      )}
    </React.Fragment>
  );
}

export default ClientContract;

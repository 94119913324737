import React from "react";

const getInitials = (fullName) => {
  const names = fullName?.split(" ");
  const initials = names
    ?.map((name) => name?.charAt(0))
    ?.slice(0, 2) // Take only the first two letters
    ?.join("")
    ?.toUpperCase();
  return initials;
};

const getColor = () => {
  // You can implement a logic to generate colors based on the initials.
  // This is just a basic example.
  // const colors = ["#3498db", "#e74c3c", "#2ecc71", "#f39c12", "#9b59b6"];
  const colors = ["#5156BE"];
  return colors[Math.floor(Math.random() * colors.length)];
};

const CustomAvatar = ({ fullName }) => {
  const initials = getInitials(fullName);
  const backgroundColor = getColor();

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        color: "white",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
        marginBottom: "10px",
        marginTop: "-4px",
        fontSize: "13px",
      }}
    >
      {initials}
    </div>
  );
};

export default CustomAvatar;

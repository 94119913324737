import React, { useState } from "react";
import {
  Button,
  Input,
  Table,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Tooltip,
  Card,
  CardBody,
  CardHeader,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { log_debug } from "../../apiHelpers/logger";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnerIndividualContract,
  getPartnerIndividualContracts,
  getPartnerIndividualTimesheet,
  postPartnerIndividualTimesheet,
  postPartnerIndividualWeeklyTimesheet,
} from "../../Partner_Individual/PartnerIndividualSlice/PartnerIndividualSlice";
import { getIndividualGuid } from "../../apiConfig/globalvariables";
import { getMondayOfCurrentWeek, getWeekNumber } from "../../helpers/date";
import {
  IndividualActiveContracts,
  getPartnerAdminIndividualContract,
  getPartnerTimesheet,
} from "../../Partner/PartnerSlice";
import { Iguid } from "../../apiConfig/axiosDev";

function Timesheetcomponent({ persona, individualguid }) {
  log_debug("dsfadksjfhiu", individualguid);
  //week days
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const dispatch = useDispatch();
  const partnerindividualstore = useSelector(
    (state) => state.partnerindividualslice,
  );
  const IndividualGuid = getIndividualGuid();
  log_debug("timesheet individual guid", IndividualGuid);

  const [currentWeekDays, setCurrentWeekDays] = useState([
    {
      day: "",
      date: "",
      month: "",
      monthnumber: "",
      year: "",
      week: "",
    },
  ]);
  const [mondayOfCurrentWeek, setMondayOfCurrentWeek] = useState(
    getMondayOfCurrentWeek(),
  );
  const [selectedProject, setSelectedProject] = useState([]);
  const [projectTimes, setProjectTimes] = useState([]);
  const [projectComments, setProjectComments] = useState([]);
  const [contractIds, setContractIds] = useState([]);
  const [contractdata, setContractData] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [billablehours, setBillableHours] = useState(0);
  const [nonbillablehours, setNonBillableHours] = useState(0);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  // Function to generate the days of the current week
  const generateCurrentWeekDays = (monday) => {
    const currentWeekDays = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(monday);
      currentDate.setDate(monday.getDate() + i);
      const week = getWeekNumber(currentDate);
      log_debug("weeeeek number", week);
      currentWeekDays.push({
        day: days[i],
        date: currentDate.getDate(),
        month: currentDate.toLocaleDateString("en-US", { month: "short" }),
        monthnumber: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        week: week,
      });
    }
    log_debug("days of current week", currentWeekDays);
    return currentWeekDays;
  };

  //Function to calculate the total time for single project
  const calculateTotalTime = (projectTime) => {
    const totalMinutes = projectTime.reduce((acc, time) => {
      const hours = parseInt(time, 10) || 0; // If time is falsy, default to 0
      return acc + hours;
    }, 0);
    return totalMinutes;
  };

  //Function to calculate the total time of all the projects
  const calculateOverallTotalTime = (projectTimes, index) => {
    let total = 0;
    for (const times of projectTimes) {
      if (times[index]) {
        total += parseFloat(times[index]);
      }
    }
    return total;
  };
  useEffect(() => {
    log_debug("current days", currentWeekDays);
    if (currentWeekDays.length > 1) {
      dispatchTimesheet();
    }
  }, [currentWeekDays]);

  const dispatchTimesheet = () => {
    setSelectedProject([]);
    setProjectTimes([]);
    setProjectComments([]);
    setContractIds([]);
    persona === "partner_admin"
      ? dispatch(
          getPartnerTimesheet({
            weeknumber: currentWeekDays[currentWeekDays.length - 1].week,
            year: currentWeekDays[currentWeekDays.length - 1].year,
            individualguid: individualguid,
          }),
        ).then((res) => {
          const timesheets = res.payload.data?.timesheets || [];
          log_debug("data length", timesheets.length);
          if (timesheets.length >= 2) {
            setSelectedProject([]);
            setProjectTimes([]);
            setProjectComments([]);
            setBillableHours(0);
            setNonBillableHours(0);
            timesheets.forEach((data) => {
              log_debug("timesheet data", data);
              if (data) {
                log_debug("entered if");
                const status = data?.status;
                setStatusMessage(status);
                if (data?.billableStatus === "billable") {
                  log_debug("dfasdfoe", billablehours);
                  setBillableHours(billablehours + data?.totalHours);
                  setNonBillableHours(nonbillablehours + 0);
                } else {
                  setBillableHours(billablehours + 0);
                  setNonBillableHours(nonbillablehours + data?.totalHours);
                }
                dispatch(
                  getPartnerIndividualContract({
                    contractid: data?.partnerContract,
                    guid: individualguid,
                  }),
                ).then((res) => {
                  const contract = res?.payload?.data?.partnercontract[0];
                  const title = contract.title;
                  const guid = contract.guid;

                  const hours = [
                    data?.monday?.hours ? data?.monday?.hours : 0,
                    data?.tuesday?.hours ? data?.tuesday?.hours : 0,
                    data?.wednesday?.hours ? data?.wednesday?.hours : 0,
                    data?.thursday?.hours ? data?.thursday?.hours : 0,
                    data?.friday?.hours ? data?.friday?.hours : 0,
                    data?.saturday?.hours ? data?.saturday?.hours : 0,
                    data?.sunday?.hours ? data?.sunday?.hours : 0,
                  ];

                  const comments = [
                    data?.monday?.task,
                    data?.tuesday?.task,
                    data?.wednesday?.task,
                    data?.thursday?.task,
                    data?.friday?.task,
                    data?.saturday?.task,
                    data?.sunday?.task,
                  ];

                  log_debug("iouoweuro", hours);

                  setSelectedProject((prevSelectedProject) => [
                    ...prevSelectedProject,
                    title,
                  ]);
                  setContractIds((prevContractIDs) => [
                    ...prevContractIDs,
                    guid,
                  ]);
                  setProjectTimes((prevProjectTimes) => [
                    ...prevProjectTimes,
                    hours,
                  ]);
                  setProjectComments((prevProjectComments) => [
                    ...prevProjectComments,
                    comments,
                  ]);
                  //handleProjectSelect(title,guid,hours,comments);
                });
              } else {
                log_debug("entered else");
                setSelectedProject([]);
                setProjectTimes([]);
                setContractIds([]);
                setProjectComments([]);
                setBillableHours(0);
                setNonBillableHours(0);
              }
            });
          } else if (timesheets.length === 1) {
            setSelectedProject([]);
            setProjectTimes([]);
            setContractIds([]);
            setProjectComments([]);
            setBillableHours(0);
            setNonBillableHours(0);
            const data = res.payload.data?.timesheets[0];
            log_debug("timesheet data", data);
            if (data) {
              log_debug("entered if", data?.partnerContract);
              const status = data?.status;
              setStatusMessage(status);
              if (data?.billableStatus === "billable") {
                setBillableHours(data?.totalHours);
                setNonBillableHours(0);
              } else {
                setBillableHours(0);
                setNonBillableHours(data?.totalHours);
              }
              //fetching contracts
              persona === "partner_admin"
                ? dispatch(
                    getPartnerAdminIndividualContract({
                      contractid: data?.partnerContract,
                      individualguid: individualguid,
                    }),
                  ).then((res) => {
                    const contract = res?.payload?.data?.partnercontract[0];
                    const title = contract && contract.title;
                    const guid = contract && contract.guid;

                    const hours = [
                      data?.monday?.hours ? data?.monday?.hours : 0,
                      data?.tuesday?.hours ? data?.tuesday?.hours : 0,
                      data?.wednesday?.hours ? data?.wednesday?.hours : 0,
                      data?.thursday?.hours ? data?.thursday?.hours : 0,
                      data?.friday?.hours ? data?.friday?.hours : 0,
                      data?.saturday?.hours ? data?.saturday?.hours : 0,
                      data?.sunday?.hours ? data?.sunday?.hours : 0,
                    ];

                    const comments = [
                      data?.monday?.task,
                      data?.tuesday?.task,
                      data?.wednesday?.task,
                      data?.thursday?.task,
                      data?.friday?.task,
                      data?.saturday?.task,
                      data?.sunday?.task,
                    ];

                    log_debug("iouoweuro", hours);

                    setSelectedProject([title]);
                    setContractIds([guid]);
                    setProjectTimes([hours]);
                    setProjectComments([comments]);
                    //handleProjectSelect(title,guid,hours,comments);
                  })
                : dispatch(
                    getPartnerIndividualContract({
                      contractid: data?.partnerContract,
                      guid: individualguid,
                    }),
                  ).then((res) => {
                    const contract = res?.payload?.data?.partnercontract[0];
                    const title = contract && contract.title;
                    const guid = contract && contract.guid;

                    const hours = [
                      data?.monday?.hours ? data?.monday?.hours : 0,
                      data?.tuesday?.hours ? data?.tuesday?.hours : 0,
                      data?.wednesday?.hours ? data?.wednesday?.hours : 0,
                      data?.thursday?.hours ? data?.thursday?.hours : 0,
                      data?.friday?.hours ? data?.friday?.hours : 0,
                      data?.saturday?.hours ? data?.saturday?.hours : 0,
                      data?.sunday?.hours ? data?.sunday?.hours : 0,
                    ];

                    const comments = [
                      data?.monday?.task,
                      data?.tuesday?.task,
                      data?.wednesday?.task,
                      data?.thursday?.task,
                      data?.friday?.task,
                      data?.saturday?.task,
                      data?.sunday?.task,
                    ];

                    log_debug("iouoweuro", hours);

                    setSelectedProject([title]);
                    setContractIds([guid]);
                    setProjectTimes([hours]);
                    setProjectComments([comments]);
                    //handleProjectSelect(title,guid,hours,comments);
                  });
            } else {
              log_debug("entered else");
              setSelectedProject([]);
              setProjectTimes([]);
              setProjectComments([]);
              setContractIds([]);
              setBillableHours(0);
              setNonBillableHours(0);
            }
          } else {
            setSelectedProject([]);
            setProjectTimes([]);
            setProjectComments([]);
            setStatusMessage("");
            setContractIds([]);
            setBillableHours(0);
            setNonBillableHours(0);
          }
        })
      : dispatch(
          getPartnerIndividualTimesheet({
            weeknumber: currentWeekDays[currentWeekDays.length - 1].week,
            year: currentWeekDays[currentWeekDays.length - 1].year,
          }),
        ).then((res) => {
          const timesheets = res.payload.data?.timesheets || [];
          log_debug("data length", timesheets.length);
          if (timesheets.length >= 2) {
            setSelectedProject([]);
            setProjectTimes([]);
            setProjectComments([]);
            setBillableHours(0);
            setNonBillableHours(0);
            timesheets.forEach((data) => {
              log_debug("timesheet data", data);
              if (data) {
                log_debug("entered if");
                const status = data?.status;
                setStatusMessage(status);
                if (data?.billableStatus === "billable") {
                  log_debug("dfasdfoe", billablehours);
                  setBillableHours(billablehours + data?.totalHours);
                  setNonBillableHours(nonbillablehours + 0);
                } else {
                  setBillableHours(billablehours + 0);
                  setNonBillableHours(nonbillablehours + data?.totalHours);
                }
                dispatch(
                  getPartnerIndividualContract({
                    contractid: data?.partnerContract,
                    guid: individualguid,
                  }),
                ).then((res) => {
                  const contract = res?.payload?.data?.partnercontract[0];
                  const title = contract.title;
                  const guid = contract.guid;

                  const hours = [
                    data?.monday?.hours ? data?.monday?.hours : 0,
                    data?.tuesday?.hours ? data?.tuesday?.hours : 0,
                    data?.wednesday?.hours ? data?.wednesday?.hours : 0,
                    data?.thursday?.hours ? data?.thursday?.hours : 0,
                    data?.friday?.hours ? data?.friday?.hours : 0,
                    data?.saturday?.hours ? data?.saturday?.hours : 0,
                    data?.sunday?.hours ? data?.sunday?.hours : 0,
                  ];

                  const comments = [
                    data?.monday?.task,
                    data?.tuesday?.task,
                    data?.wednesday?.task,
                    data?.thursday?.task,
                    data?.friday?.task,
                    data?.saturday?.task,
                    data?.sunday?.task,
                  ];

                  log_debug("iouoweuro", hours);

                  setSelectedProject((prevSelectedProject) => [
                    ...prevSelectedProject,
                    title,
                  ]);
                  setContractIds((prevContractIDs) => [
                    ...prevContractIDs,
                    guid,
                  ]);
                  setProjectTimes((prevProjectTimes) => [
                    ...prevProjectTimes,
                    hours,
                  ]);
                  setProjectComments((prevProjectComments) => [
                    ...prevProjectComments,
                    comments,
                  ]);
                  //handleProjectSelect(title,guid,hours,comments);
                });
              } else {
                log_debug("entered else");
                setSelectedProject([]);
                setProjectTimes([]);
                setContractIds([]);
                setProjectComments([]);
                setBillableHours(0);
                setNonBillableHours(0);
              }
            });
          } else if (timesheets.length === 1) {
            setSelectedProject([]);
            setProjectTimes([]);
            setContractIds([]);
            setProjectComments([]);
            setBillableHours(0);
            setNonBillableHours(0);
            const data = res.payload.data?.timesheets[0];
            log_debug("timesheet data", data);
            if (data) {
              log_debug("entered if", data?.partnerContract);
              const status = data?.status;
              setStatusMessage(status);
              if (data?.billableStatus === "billable") {
                setBillableHours(data?.totalHours);
                setNonBillableHours(0);
              } else {
                setBillableHours(0);
                setNonBillableHours(data?.totalHours);
              }
              dispatch(
                getPartnerIndividualContract({
                  contractid: data?.partnerContract,
                  guid: individualguid,
                }),
              ).then((res) => {
                const contract = res?.payload?.data?.partnercontract[0];
                const title = contract.title;
                const guid = contract.guid;

                const hours = [
                  data?.monday?.hours ? data?.monday?.hours : 0,
                  data?.tuesday?.hours ? data?.tuesday?.hours : 0,
                  data?.wednesday?.hours ? data?.wednesday?.hours : 0,
                  data?.thursday?.hours ? data?.thursday?.hours : 0,
                  data?.friday?.hours ? data?.friday?.hours : 0,
                  data?.saturday?.hours ? data?.saturday?.hours : 0,
                  data?.sunday?.hours ? data?.sunday?.hours : 0,
                ];

                const comments = [
                  data?.monday?.task,
                  data?.tuesday?.task,
                  data?.wednesday?.task,
                  data?.thursday?.task,
                  data?.friday?.task,
                  data?.saturday?.task,
                  data?.sunday?.task,
                ];

                log_debug("iouoweuro", hours);

                setSelectedProject([title]);
                setContractIds([guid]);
                setProjectTimes([hours]);
                setProjectComments([comments]);
                //handleProjectSelect(title,guid,hours,comments);
              });
            } else {
              log_debug("entered else");
              setSelectedProject([]);
              setProjectTimes([]);
              setProjectComments([]);
              setContractIds([]);
              setBillableHours(0);
              setNonBillableHours(0);
            }
          } else {
            setSelectedProject([]);
            setProjectTimes([]);
            setProjectComments([]);
            setStatusMessage("");
            setContractIds([]);
            setBillableHours(0);
            setNonBillableHours(0);
          }
        });
  };

  //  const dispatchTimesheet = () => {
  //    dispatch(
  //      getPartnerIndividualTimesheet({
  //        weeknumber: currentWeekDays[0].week,
  //        monthnumber: currentWeekDays[0].monthnumber,
  //      })
  //    ).then((res) => {
  //      const data = res.payload.data?.timesheets[0];
  //      log_debug("timesheet data", data);
  //      if(data){
  //        log_debug("entered if",data?.partnerContract)
  //        const status = data?.status;
  //      setStatusMessage(status);
  //      dispatch(getPartnerIndividualContract(data?.partnerContract)).then(
  //        (res) => {
  //          const contract = res?.payload?.data?.partnercontract[0];
  //          const title = contract.title;
  //          const guid = contract.guid;

  //          const hours =[
  //            data?.monday?.hours ? data?.monday?.hours : 0,
  //            data?.tuesday?.hours ? data?.tuesday?.hours : 0,
  //            data?.wednesday?.hours ? data?.wednesday?.hours : 0,
  //            data?.thursday?.hours ? data?.thursday?.hours : 0,
  //            data?.friday?.hours ? data?.friday?.hours : 0,
  //            data?.saturday?.hours ? data?.saturday?.hours : 0,
  //            data?.sunday?.hours ? data?.sunday?.hours : 0
  //          ]

  //          const comments = [
  //            data?.monday?.task,
  //            data?.tuesday?.task,
  //            data?.wednesday?.task,
  //            data?.thursday?.task,
  //            data?.friday?.task,
  //            data?.saturday?.task,
  //            data?.sunday?.task,
  //          ]

  //          log_debug("iouoweuro",hours)

  //          setSelectedProject([title]);
  //          setProjectTimes([hours]);
  //          setProjectComments([comments])
  //          //handleProjectSelect(title,guid,hours,comments);
  //        }
  //      );
  //      }else{
  //        log_debug("entered else")
  //        setSelectedProject([]);
  //          setProjectTimes([]);
  //          setProjectComments([])

  //      }

  //    });
  //  };

  // Function to handle previous week button click
  const handlePreviousWeek = () => {
    const previousMonday = new Date(mondayOfCurrentWeek);
    previousMonday.setDate(mondayOfCurrentWeek.getDate() - 7); // Move back 7 days
    setMondayOfCurrentWeek(previousMonday);
    setCurrentWeekDays(generateCurrentWeekDays(previousMonday));
    if (previousMonday.getDate() === getMondayOfCurrentWeek().getDate()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    //dispatchTimesheet();
  };

  // Function to handle next week button click
  const handleNextWeek = () => {
    const nextMonday = new Date(mondayOfCurrentWeek);
    nextMonday.setDate(mondayOfCurrentWeek.getDate() + 7); // Move forward 7 days
    setMondayOfCurrentWeek(nextMonday);
    setCurrentWeekDays(generateCurrentWeekDays(nextMonday));
    log_debug(
      "selected monday",
      nextMonday.getDate() == getMondayOfCurrentWeek().getDate(),
    );
    if (nextMonday.getDate() === getMondayOfCurrentWeek().getDate()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    // dispatchTimesheet();
  };

  //To generate the week days of current week on page load
  useEffect(() => {
    setCurrentWeekDays(generateCurrentWeekDays(mondayOfCurrentWeek));
    // dispatch(getPartnerIndividualContracts()).then((res) => {
    //   const data = res.payload.data;
    //   log_debug("response contracts", data);
    //   setContractData(data?.contracts);
    //   //  setIsLoading(false);
    // });

    dispatch(
      IndividualActiveContracts({
        individualguid:
          persona === "partner_admin"
            ? individualguid
            : getIndividualGuid() || Iguid,
      }),
    ).then((res) => {
      const data = res.payload.data;
      log_debug("response contracts", data);
      setContractData(data?.individualContracts);
      // setIsLoading(false);
    });
  }, []);

  //Function to handle the project time
  const handleTimeChangeForProject = (projectIndex, timeIndex, value) => {
    if (/^\d{1,2}$/.test(value) || value === "") {
      const newProjectTimes = [...projectTimes];
      newProjectTimes[projectIndex][timeIndex] = value;
      setProjectTimes(newProjectTimes);
    }
  };

  // Function to handle project selection
  const handleProjectSelect = (project, contract_id, time, comment) => {
    // Add a new entry in projectTimes for the selected project
    setProjectTimes([...projectTimes, time ? time : Array(7).fill("")]);
    setProjectComments([
      ...projectComments,
      comment ? comment : Array(5).fill(""),
    ]);
    setSelectedProject([...selectedProject, project]);
    setContractIds([...contractIds, contract_id]);
    log_debug("contract id array", contractIds);
  };

  //function to handle the comments of a project
  const handleCommentChangeForProject = (projectIndex, timeIndex, comment) => {
    // Update the comments for the specific project and time
    const updatedProjectComments = [...projectComments];
    updatedProjectComments[projectIndex] =
      updatedProjectComments[projectIndex] || [];
    updatedProjectComments[projectIndex][timeIndex] = comment;

    // Update the state with the updated projectComments
    setProjectComments(updatedProjectComments);
  };

  // Function to handle deleting a project
  const handleDeleteProject = (projectIndex) => {
    log_debug("kdsfasldf", projectIndex);
    const updatedSelectedProjects = [...selectedProject];
    updatedSelectedProjects.splice(projectIndex, 1); // Remove the project at projectIndex

    const updatedProjectTimes = [...projectTimes];
    updatedProjectTimes.splice(projectIndex, 1); // Remove the project time data

    const updatedProjectComments = [...projectComments];
    updatedProjectComments.splice(projectIndex, 1); // Remove the project comment data

    const updatedContractIds = [...contractIds];
    updatedContractIds.splice(projectIndex, 1);

    setSelectedProject(updatedSelectedProjects);
    setProjectTimes(updatedProjectTimes);
    setProjectComments(updatedProjectComments);
    setContractIds(updatedContractIds);
  };
  log_debug("selected projects", selectedProject);
  log_debug("project times", projectTimes);
  log_debug("project comments", projectComments);
  log_debug("contract id", contractIds);

  //Handle submit function
  const handleWeeklySubmitTimesheet = () => {
    const timesheetData = {
      timesheet: [],
    };

    selectedProject.forEach((entry, index) => {
      if (!timesheetData.timesheet[index]) {
        timesheetData.timesheet[index] = {
          individual:
            persona === "partner_admin" ? individualguid : IndividualGuid,
          partner: contractdata[index]?.partner_guid,
          filledBy:
            persona === "partner_admin" ? individualguid : IndividualGuid,
          week: currentWeekDays[currentWeekDays.length - 1].week,
          month: currentWeekDays[currentWeekDays.length - 1].monthnumber,
          year: currentWeekDays[currentWeekDays.length - 1].year,
          partnerContract: contractIds && contractIds[index],
          monday: {
            hours: parseInt(projectTimes[index][0]) || 0,
            task: projectComments[index][0],
          },
          tuesday: {
            hours: parseInt(projectTimes[index][1]) || 0,
            task: projectComments[index][1],
          },
          wednesday: {
            hours: parseInt(projectTimes[index][2]) || 0,
            task: projectComments[index][2],
          },
          thursday: {
            hours: parseInt(projectTimes[index][3]) || 0,
            task: projectComments[index][3],
          },
          friday: {
            hours: parseInt(projectTimes[index][4]) || 0,
            task: projectComments[index][4],
          },
          saturday: {
            hours: parseInt(projectTimes[index][5]) || 0,
            task: projectComments[index][5] || "-",
          },
          sunday: {
            hours: parseInt(projectTimes[index][6]) || 0,
            task: projectComments[index][6] || "-",
          },
        };
      }
    });

    log_debug("weekly timesheet data", timesheetData);
    dispatch(postPartnerIndividualWeeklyTimesheet(timesheetData)).then(
      (res) => {
        const data = res.payload.data?.timesheets;
        if (res.payload?.success === true) {
          toast.success("Timesheet submitted");
          setStatusMessage("pending");
        } else if (res.payload?.success === false) {
          toast.warning(res.payload?.error?.message);
        }
      },
    );
  };

  const handleSubmitTimesheet = () => {
    const timesheetData = {
      timesheet: [],
    };

    selectedProject.forEach((entry, index) => {
      if (!timesheetData.timesheet[index]) {
        timesheetData.timesheet[index] = {
          individual:
            persona === "partner_admin" ? individualguid : IndividualGuid,
          partner: contractdata[index]?.partner_guid,
          filledBy:
            persona === "partner_admin" ? individualguid : IndividualGuid,
          week: currentWeekDays[currentWeekDays.length - 1].week,
          month: currentWeekDays[currentWeekDays.length - 1].monthnumber,
          year: currentWeekDays[currentWeekDays.length - 1].year,
          partnerContract: contractIds && contractIds[index],
          monday: {
            hours: parseInt(projectTimes[index][0]) || 0,
            task: projectComments[index][0],
          },
          tuesday: {
            hours: parseInt(projectTimes[index][1]) || 0,
            task: projectComments[index][1],
          },
          wednesday: {
            hours: parseInt(projectTimes[index][2]) || 0,
            task: projectComments[index][2],
          },
          thursday: {
            hours: parseInt(projectTimes[index][3]) || 0,
            task: projectComments[index][3],
          },
          friday: {
            hours: parseInt(projectTimes[index][4]) || 0,
            task: projectComments[index][4],
          },
          saturday: {
            hours: parseInt(projectTimes[index][5]) || 0,
            task: projectComments[index][5] || "-",
          },
          sunday: {
            hours: parseInt(projectTimes[index][6]) || 0,
            task: projectComments[index][6] || "-",
          },
        };
      }
    });

    log_debug("timesheet data", timesheetData);
    dispatch(postPartnerIndividualTimesheet(timesheetData)).then((res) => {
      const data = res.payload.data?.timesheets;
      if (res.payload?.success === true) {
        toast.success("Timesheet Saved Successfully");
        setStatusMessage("partially_submitted");
      } else if (res.payload?.success === false) {
        toast.warning(res.payload?.error?.message);
      }
    });
  };

  log_debug("selected project", selectedProject);

  return (
    <>
      <Table className="table-striped table-borderedmb-5">
        <thead className="m-auto">
          {/* Section to display the date range filter and the necessary details */}
          <tr>
            <Row className="bg-primary text-white m-0">
              <Col lg={3} className="text-center my-3">
                <Button
                  color="white"
                  className="p-1 btn  waves-effect "
                  onClick={handlePreviousWeek}
                >
                  <i className="bx bx-left-arrow mt-1 text-white" />
                </Button>
                <span className="font-size-12 mx-1 w-100">
                  {currentWeekDays.length > 0 ? currentWeekDays[0].month : ""}{" "}
                  {currentWeekDays.length > 0 ? currentWeekDays[0].date : ""}
                  {" - "}
                  {currentWeekDays.length > 0
                    ? currentWeekDays[currentWeekDays.length - 1].month
                    : ""}{" "}
                  {currentWeekDays.length > 0
                    ? currentWeekDays[currentWeekDays.length - 1].date
                    : ""}
                  {", "}
                  {currentWeekDays.length > 0 ? currentWeekDays[0].year : ""}
                </span>
                <Button
                  color="white"
                  className="p-1 btn waves-effect"
                  onClick={handleNextWeek}
                >
                  <i className="bx bx-right-arrow mt-1 text-white" />
                </Button>
              </Col>
              <Col lg={1} className="text-center my-2">
                <p className="font-size-12 mb-1">BILLABLE</p>
                <h6 className="m-0 text-white">{billablehours}</h6>
              </Col>
              <Col lg={2} className="text-center my-2">
                <p className="font-size-12 mb-1">NON BILLABLE</p>
                <h6 className="m-0 text-white">{nonbillablehours}</h6>
              </Col>
              <Col lg={1} className="text-center my-2">
                <p className="font-size-12 mb-1">TOTAL</p>
                <h6 className="m-0 text-white">
                  {billablehours + nonbillablehours}
                </h6>
              </Col>
              {/* <Col lg={2} className="text-center my-2">
                <p className="font-size-12 mb-1">TIME OFF HOURS</p>
                <h6 className="m-0 text-white">0.00</h6>
              </Col> */}
              <Col lg={4} className="text-center my-2">
                {statusMessage && (
                  <Badge
                    color={
                      statusMessage === "pending"
                        ? "warning rounded-pill"
                        : statusMessage === "rejected"
                          ? "danger rounded-pill"
                          : statusMessage === "approved"
                            ? "success rounded-pill"
                            : statusMessage === "partially_submitted"
                              ? "secondary rounded-pill"
                              : ""
                    }
                    className="my-3 font-size-12"
                  >
                    {statusMessage === "pending"
                      ? "Pending Approval"
                      : statusMessage === "rejected"
                        ? "Rejected"
                        : statusMessage === "approved"
                          ? "Approved"
                          : statusMessage === "partially_submitted"
                            ? "Not Submitted"
                            : ""}
                  </Badge>
                )}
              </Col>
            </Row>
          </tr>
        </thead>
      </Table>
      <form>
        <div className="table-responsive">
          {/* Actual timesheet section */}
          <Table className="table-striped table-bordered mb-0 text-center">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="col-lg-3 text-start bg-primary text-white py-3"
                >
                  Projects
                </th>
                {currentWeekDays.map((day, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="col-lg-1 font-size-12 bg-primary text-white"
                  >
                    <p className="m-0">
                      {day.month} {day.date < 10 ? `0${day.date}` : day.date}
                    </p>
                    <p className="m-0">
                      {day.day.substring(0, 3).toUpperCase()}
                    </p>
                  </th>
                ))}
                <th
                  scope="col"
                  className="col-lg-2 font-size-12 bg-primary text-white"
                >
                  <p className="m-0">TASK TOTAL</p>
                  <p className="m-0">HRS/ WEEK</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {selectedProject &&
              selectedProject.map((project, projectIndex) => (
                <tr key={projectIndex}>
                  <th scope="row" className="text-start p-3">
                    <Link>{project}</Link>
                  </th>
                  {projectTimes[projectIndex].map((time, timeIndex) => (
                    <td key={timeIndex} className="p-2">
                      <Col sm={12}>
                        <UncontrolledDropdown>
                          <DropdownToggle className="" type="button" tag="a">
                            <Input
                              type="text"
                              id={`timeInput-${timeIndex}`}
                              value={time}
                              onChange={(e) =>
                                handleTimeChangeForProject(
                                  projectIndex,
                                  timeIndex,
                                  e.target.value
                                )
                              }
                              pattern="\d*"
                              maxLength={5}
                              className="border-0"
                              placeholder="0"
                            />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem to="#">
                              <Input type="text" />
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </td>
                  ))}
                  <td className="m-auto">
                    <p className="text-center m-auto">
                      {calculateTotalTime(projectTimes[projectIndex])}
                    </p>
                  </td>
                </tr>
              ))} */}

              {/* Section to display the selected project */}
              {selectedProject &&
                selectedProject.map((project, projectIndex) => (
                  <tr key={projectIndex}>
                    <th scope="row" className="text-start p-3">
                      <div className="d-flex">
                        <div className="col-sm-6">
                          <Link> {project}</Link>
                        </div>
                        <div className="col-sm-6 text-end">
                          <Link
                            onClick={() => handleDeleteProject(projectIndex)}
                            hidden={
                              isDisabled ||
                              statusMessage === "pending" ||
                              statusMessage === "approved" ||
                              statusMessage === "rejected"
                            }
                          >
                            <i
                              className="mdi mdi-delete-outline fs-5"
                              title="Delete Task and Hours"
                            ></i>{" "}
                          </Link>
                        </div>
                      </div>
                    </th>
                    {projectTimes[projectIndex].map((time, timeIndex) => (
                      <td key={timeIndex} className="p-2">
                        <Col sm={12}>
                          <UncontrolledDropdown>
                            <DropdownToggle className="" type="button" tag="a">
                              <Input
                                type="text"
                                id={`timeInput-${timeIndex}`}
                                value={time}
                                onChange={(e) =>
                                  handleTimeChangeForProject(
                                    projectIndex,
                                    timeIndex,
                                    e.target.value,
                                  )
                                }
                                pattern="\d*"
                                maxLength={5}
                                className="border-0"
                                placeholder="0"
                                disabled={
                                  isDisabled || statusMessage === "pending"
                                }
                                title={
                                  isDisabled || statusMessage === "pending"
                                    ? projectComments[projectIndex]?.[timeIndex]
                                    : ""
                                }
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <textarea
                                type="text"
                                placeholder="Add comment(required)"
                                value={
                                  projectComments[projectIndex]?.[timeIndex]
                                }
                                required
                                // onClick={(e) => e.stopPropagation()}
                                onChange={(e) =>
                                  handleCommentChangeForProject(
                                    projectIndex,
                                    timeIndex,
                                    e.target.value,
                                  )
                                }
                              />
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Col>
                      </td>
                    ))}
                    <td className="m-auto">
                      <p className="text-center m-auto">
                        {calculateTotalTime(projectTimes[projectIndex])}
                      </p>
                    </td>
                  </tr>
                ))}

              {/* Section to display the add task button  */}
              <tr hidden={isDisabled || statusMessage === "pending"}>
                <th scope="row" className="text-start">
                  <Col sm={6}>
                    <UncontrolledDropdown>
                      <DropdownToggle className="" type="button" tag="a">
                        + Add Task{" "}
                        {/* <i className="mdi mdi-chevron-down"></i> */}
                      </DropdownToggle>
                      <DropdownMenu>
                        {contractdata && contractdata.length > 0 ? (
                          contractdata.map((contract, index) => (
                            <DropdownItem
                              key={index}
                              to="#"
                              onClick={() =>
                                handleProjectSelect(
                                  contract?.JD?.title,
                                  contract?.guid,
                                )
                              }
                            >
                              {contract?.JD?.title}
                            </DropdownItem>
                          ))
                        ) : (
                          <DropdownItem to="#" disabled>
                            No Contract Available
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">
                  <h6 className="m-0 text-end">Total hours/ day</h6>
                </th>
                {(() => {
                  const totalTimes = [];
                  if (projectTimes.length > 0) {
                    // Transpose projectTimes
                    const numColumns = projectTimes[0].length;
                    for (let i = 0; i < numColumns; i++) {
                      totalTimes.push(
                        calculateOverallTotalTime(projectTimes, i),
                      );
                    }
                  }
                  const totalSum = totalTimes.reduce(
                    (acc, curr) => acc + curr,
                    0,
                  );
                  return (
                    <>
                      {totalTimes.length > 0 ? (
                        totalTimes.map((total, index) => (
                          <>
                            <td key={index}>
                              <p className="text-start m-auto">{total}</p>
                            </td>
                            {index === 6 && (
                              <td>
                                <p className="text-center m-auto">{totalSum}</p>
                              </td>
                            )}
                          </>
                        ))
                      ) : (
                        <>
                          <td className="text-start">0</td>
                          <td className="text-start">0</td>
                          <td className="text-start">0</td>
                          <td className="text-start">0</td>
                          <td className="text-start">0</td>
                          <td className="text-start">0</td>
                          <td className="text-start">0</td>
                          <td className="text-center">0</td>
                        </>
                      )}
                    </>
                  );
                })()}
              </tr>
              <tr hidden={isDisabled}>
                <td colSpan={9}>
                  <div className="d-flex flex-row-reverse gap-1">
                    <div>
                      <Button
                        color="primary"
                        onClick={handleWeeklySubmitTimesheet}
                        disabled={
                          selectedProject.length === 0 ||
                          statusMessage === "pending"
                        }
                      >
                        Submit weekly timesheet
                      </Button>
                    </div>
                    <div>
                      <Button
                        disabled={
                          selectedProject.length === 0 ||
                          statusMessage === "pending"
                        }
                        onClick={handleSubmitTimesheet}
                        color="primary"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </form>
      <div className="mx-3">
        {selectedProject.length > 0 && (
          <>
            {" "}
            <Row className="my-1">
              <h5 className="mt-4 px-0">Comment Summary</h5>
            </Row>
            <Row className="my-0">
              <Col lg={4} className="border bg-primary p-0">
                <h6 className="text-white m-2">Project - Task</h6>
              </Col>
              <Col lg={8} className="border bg-primary p-0">
                <h6 className="text-white m-2">Comment</h6>
              </Col>
            </Row>
          </>
        )}
        {selectedProject.map((project, index) => (
          <Row key={index} className="my-0">
            <Col lg={4} className="border">
              <h6 className="m-2">{project}</h6>
            </Col>
            <Col lg={8} className="p-0">
              {projectComments[index].map((comment, commentindex) => (
                <Row key={commentindex} className="border m-0">
                  <div className="m-2">
                    <p className="fw-bold mb-1">
                      <span className="font-size-12">
                        {currentWeekDays[commentindex].month}{" "}
                        {currentWeekDays[commentindex].date}{" "}
                        {currentWeekDays[commentindex].day
                          .substring(0, 3)
                          .toUpperCase()}{" "}
                      </span>
                      <span className="font-size-12">
                        <i className="fas fa-clock mx-1"></i>
                        {projectTimes[index][commentindex] || "-"} hrs
                      </span>
                    </p>
                    <p className="m-0">{comment ? comment : "-"}</p>
                  </div>
                </Row>
              ))}
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
}

export default Timesheetcomponent;

import React, { useEffect, useState } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import upload from "../../src/assets/images/companyBenchImage/icons8-invoice.png";
import { Iguid, Pguid } from "../apiConfig/axiosDev";
import { getPartnerGuid } from "../apiConfig/globalvariables";

import { useDispatch, useSelector } from "react-redux";
import { log_debug } from "../apiHelpers/logger";
import { formatDate } from "../helpers/date";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EditInvoice = () => {
  const breadcrumbValues = ["Invoice", "Update Invoice"];
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);

  const [namefield, setname] = useState([]);
  log_debug("namefield", namefield);
  const [
    createInvoicecandidateropdowndata,
    setcreateInvoicecandidateropdowndata,
  ] = useState([]);
  log_debug(
    "createInvoicecandidateropdowndata",
    createInvoicecandidateropdowndata,
  );

  const [AutoFieldData, setAutoFieldData] = useState([]);
  log_debug("AutoFieldData", AutoFieldData);
  const BillingOption = ["manually", "monthly", "quarterly", "annual"];
  const [billingCycleinfo, setbillingCycleinfo] = useState([]);
  const [billingCycle, setBillingCycle] = useState("");
  const [daysdata, setDaysdata] = useState("");
  const navigate = useNavigate();

  //   const [isDaysEditable, setIsDaysEditable] = useState(true); // Set to true initially
  //   let totalAmountvalue = (
  //     AutoFieldData?.contract_value * daysdata * 0.18 +
  //     AutoFieldData?.contract_value * daysdata
  //   ).toFixed(2)
  //   let sub_total_data = AutoFieldData ? (  AutoFieldData?.contract_value * daysdata
  //   ).toFixed(2) : 0
  //   let Gst_data = AutoFieldData ? (
  //     AutoFieldData?.contract_value *
  //     daysdata *
  //     0.18
  //   ).toFixed(2) : 0
  //   let start_date_data = AutoFieldData ? formatDate(AutoFieldData?.started_at) : ''
  //   let end_date_data = AutoFieldData ? formatDate(AutoFieldData?.ended_at) : ''
  //   let rate_data = AutoFieldData ? AutoFieldData?.contract_value : ''
  // // Assuming AutoFieldData.started_at is your date

  // const [invoicedatefield, setinvoicedatefield] = useState([])
  // const [rolefield, setrolefield] = useState([])
  // const [startdatefield, setstartdatefield] = useState([])
  // const [enddatefield, setenddatefield] = useState([])
  // const [duedatefield, setduedatefield] = useState([])
  // const [amountfield, setamountfield] = useState([])
  // const [billingCyclefield, setbillingCyclefield] = useState([])
  // const [termsfield, settermsfield] = useState([])
  // const [ratefields, setratefields] = useState([])
  // const [streetfields, setstreetfields] = useState([])
  // const [cityfield, setcityfield] = useState([])
  // const [countryfield, setcountryfield] = useState([])

  const onSubmit = (values, { resetForm }) => {
    const rate = parseFloat(values.rate);
    dispatch(
      CreateInvoice({
        invoice: {
          partner_guid: getPartnerGuid() || Pguid,
          email_from: values.email_from,
          email_to: values.email_to,
          Invoice_date: values.Invoice_date,
          due_date: values.due_date,
          terms: values.terms,
          name: namefield.full_name,
          email: namefield.email,
          role: AutoFieldData && AutoFieldData?.individuals?.designation,
          start_date: start_date_data,
          end_date: end_date_data,
          rate: rate_data,
          billing_cycle: billingCycle,
          billing_days: daysdata,
          sub_total: sub_total_data,
          gst: Gst_data,
          amount: totalAmountvalue,
        },
      }),
    );
    resetForm();
    toast.success("Invoice updated successfully");
    setTimeout(() => {
      if (AutoFieldData) {
        navigate("/invoice");
      }
    }, 2000);
  };

  const formik = useFormik({
    initialValues: {
      email_from: "lucky_dubey@yopmail.com",
      email_to: "contact@techilaservices.com",
      Invoice_date: "",
      due_date: "",
      terms: "",
      name: "",
      email: "",
      role: "",
      start_date: "",
      end_date: "",
      rate: "",
      billing_cycle: "",
      billing_days: "",
      sub_total: "",
      gst: "",
      amount: "",
    },
    validationSchema: Yup.object({
      // candidateName: Yup.string().required("Required"),
      // email: Yup.string().email("Invalid email address").required("Required"),
      // Invoice_date: Yup.string().required("Required"),
      // start_date: Yup.string().required("Required"),
      // end_date: Yup.string().required("Required"),
      // role: Yup.string().required("Required"),
      // billing_cycle: Yup.string().required("Required"),
      // terms: Yup.string().required("Required"),
      // due_date: Yup.string().required("Required"),
      // amount: Yup.string().required("Required"),
      // rate: Yup.string().required("Required"),
      // street: Yup.string().required("Required"),
      // city: Yup.string().required("Required"),
      // country: Yup.string().required("Required"),
      // days: Yup.number()
      //   .integer("Please enter a whole number") // Ensure value is an integer
      //   .required("Required") // Make the field required
      //   .min(1, "Minimum value is 1") // Set minimum value
      //   .max(365, "Maximum value is 365"), // Set maximum value
    }),
    onSubmit,
  });

  // const handleBillingCycleChange = (e) => {
  //   const selectedBillingCycle = e.target.value;
  //   setBillingCycle(selectedBillingCycle);

  //   // Update days based on selected billing cycle
  //   switch (selectedBillingCycle) {
  //     // case "manually":
  //     //   setDaysdata(1);
  //     //   break;
  //     case "monthly":
  //       setDaysdata(30);
  //       break;
  //     case "quarterly":
  //       setDaysdata(90);
  //       break;
  //     case "annual":
  //       setDaysdata(365);
  //       break;
  //     default:
  //       setDaysdata(""); // Clear days if none selected
  //       break;
  //   }
  //   // Enable days field if billing cycle is selected
  //   setIsDaysEditable(true);
  // };

  // const handleDaysChange = (e) => {
  //   // Update days value
  //   setDaysdata(e.target.value);
  // };

  // const calculateTotalAmount = () => {
  //   const rate = AutoFieldData?.contract_value;
  //   const days = daysdata;
  //   const gstPercentage = formik.values.gstPercentage;

  //   if (rate && days && gstPercentage) {
  //     const gstAmount = (rate * gstPercentage) / 100;
  //     const totalAmount = rate * days + gstAmount;
  //     formik.setFieldValue("amount", totalAmount.toFixed(2));
  //   }
  // };

  // useEffect(() => {
  //   calculateTotalAmount();
  // }, [AutoFieldData?.contract_value, daysdata, formik.values.gstPercentage]);

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div>
              <div className="col-lg-12">
                <CustomBreadcrumb values={breadcrumbValues} />
                <Col className="col-lg-12 col-md-12 col-sm-12 ">
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card
                        style={{
                          width: "100%",
                          height: "auto",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <CardHeader className="text-center bg-primary-subtle font-size-22">
                          <img
                            src={upload}
                            style={{ width: "60px" }}
                            alt="Dropzone Image"
                          />{" "}
                          <strong className="text-white">Update Invoice</strong>
                        </CardHeader>
                        <CardBody>
                          <form onSubmit={formik.handleSubmit}>
                            {/* <p className="pb-1">
                              <strong>To -</strong>
                            </p> */}

                            <Row className="border-bottom pb-2">
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="email_from">Email From</label>
                                <input
                                  id="email_from"
                                  name="email_from"
                                  type="email"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email_from}
                                  disabled
                                />
                                {formik.touched.email_from &&
                                formik.errors.email_from ? (
                                  <div className="text-danger">
                                    {formik.errors.email_from}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="email_to">Email To</label>
                                <input
                                  id="email_to"
                                  name="email_to"
                                  type="email"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={"contact@techilaservices.com"}
                                  disabled
                                />
                                {formik.touched.email_to &&
                                formik.errors.email_to ? (
                                  <div className="text-danger">
                                    {formik.errors.email_to}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="Invoice_date">
                                  Invoice Date
                                </label>
                                <input
                                  id="Invoice_date"
                                  name="Invoice_date"
                                  type="date"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.Invoice_date}
                                />
                                {formik.touched.Invoice_date &&
                                formik.errors.Invoice_date ? (
                                  <div className="text-danger">
                                    {formik.errors.Invoice_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="due_date">Due date</label>
                                <input
                                  id="due_date"
                                  name="due_date"
                                  type="date"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.due_date}
                                />
                                {formik.touched.due_date &&
                                formik.errors.due_date ? (
                                  <div className="text-danger">
                                    {formik.errors.due_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="terms">Terms</label>
                                <input
                                  id="terms"
                                  name="terms"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.terms}
                                  disabled
                                />
                                {formik.touched.terms && formik.errors.terms ? (
                                  <div className="text-danger">
                                    {formik.errors.terms}
                                  </div>
                                ) : null}
                              </div>
                            </Row>
                            <p className="pb-1">
                              <strong>Candidate details -</strong>
                            </p>
                            <Row className="border-bottom pb-2">
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="candidateName">Name</label>
                                <input
                                  id="candidateName"
                                  name="candidateName"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={namefield.candidateName}
                                  disabled
                                />
                                {/* {formik.touched.candidateName && formik.errors.candidateName ? (
                                  <div className="text-danger">
                                    {formik.errors.candidateName}
                                  </div>
                                ) : null} */}
                              </div>

                              <div className="form-group col-4 mb-2">
                                <label htmlFor="email">Email</label>
                                <input
                                  id="email"
                                  name="email"
                                  type="email"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={namefield.email}
                                  disabled
                                />
                                {/* {formik.touched.email && formik.errors.email ? (
                                  <div className="text-danger">
                                    {formik.errors.email}
                                  </div>
                                ) : null} */}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="role">Role</label>
                                <input
                                  id="role"
                                  name="role"
                                  type="text"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={
                                    AutoFieldData
                                      ? AutoFieldData?.individuals?.designation
                                      : ""
                                  }
                                  disabled
                                />
                                {formik.touched.role && formik.errors.role ? (
                                  <div className="text-danger">
                                    {formik.errors.role}
                                  </div>
                                ) : null}
                              </div>
                            </Row>
                            <p className="pb-1">
                              <strong>Contract Details -</strong>
                            </p>
                            <Row className="border-bottom pb-2">
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="start_date">Start Date</label>
                                <input
                                  id="start_date"
                                  name="start_date"
                                  type="test"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={""}
                                  disabled
                                />
                                {formik.touched.start_date &&
                                formik.errors.start_date ? (
                                  <div className="text-danger">
                                    {formik.errors.start_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="end_date">End Date</label>
                                <input
                                  id="end_date"
                                  name="end_date"
                                  type="test"
                                  className="form-control bg-light"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={""}
                                  disabled
                                />
                                {formik.touched.end_date &&
                                formik.errors.end_date ? (
                                  <div className="text-danger">
                                    {formik.errors.end_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="rate">Rate</label>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i className="mdi mdi-currency-inr"></i>
                                  </span>

                                  <input
                                    id="rate"
                                    name="rate"
                                    type="text"
                                    className="form-control bg-light"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={""}
                                    disabled
                                  />
                                </div>

                                {formik.touched.rate && formik.errors.rate ? (
                                  <div className="text-danger">
                                    {formik.errors.rate}
                                  </div>
                                ) : null}
                              </div>

                              <div className="form-group col-4 mb-2">
                                <label htmlFor="billing_cycle">
                                  Billing Cycle
                                </label>
                                <select
                                  type="select"
                                  name="billing_cycle"
                                  required
                                  className="form-control"
                                  value={""}
                                  onChange={""}
                                >
                                  <option value="" hidden>
                                    Select Billing Cycle
                                  </option>
                                  {BillingOption.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group col-4 mb-2">
                                <label htmlFor="days">Billing Days</label>
                                <input
                                  id="days"
                                  name="days"
                                  type="number"
                                  min="1" // Minimum number of days
                                  max="365" // Maximum number of days
                                  step="1"
                                  className="form-control"
                                  onChange={""} // Use handleDaysChange to update days value
                                  value={""}
                                  disabled={
                                    billingCycle == "monthly" ||
                                    billingCycle == "quarterly" ||
                                    billingCycle == "annual"
                                  } // Disable if billingCycle has a value
                                />
                                {formik.touched.days && formik.errors.days ? (
                                  <div className="text-danger">
                                    {formik.errors.days}
                                  </div>
                                ) : null}
                              </div>
                              {/* <div className="form-group col-4 mb-2">
                                <label htmlFor="sub_total">Sub-Total</label>
                                <input
                                  id="sub_total"
                                  name="sub_total"
                                  type="number"
                                  min="1"
                                  max="365"
                                  step="1"
                                  className="form-control bg-light"
                                  onChange={""} // Use handleDaysChange to update days value
                                  value={AutoFieldData?.contract_value * daysdata}
                                  disabled
                                />
                                {formik.touched.sub_total &&
                                formik.errors.sub_total ? (
                                  <div className="text-danger">
                                    {formik.errors.sub_total}
                                  </div>
                                ) : null}
                              </div> */}
                            </Row>
                            <p className="pb-1">
                              <strong>Amount Details -</strong>
                            </p>
                            <Row className=" pb-2">
                              {/* <div className="form-group col-4 mb-2">
                                <label htmlFor="gstPercentage">GST 18%</label>
                                <select
                                  id="gstPercentage"
                                  name="gstPercentage"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.gstPercentage}
                                >
                                  <option value="" hidden>
                                    Select GST Percentage
                                  </option>
                                  <option value="5">5</option>
                                  <option value="12">12</option>
                                  <option value="18">18</option>
                                  <option value="28">28</option>
                                </select>
                                {formik.touched.gstPercentage &&
                                formik.errors.gstPercentage ? (
                                  <div className="text-danger">
                                    {formik.errors.gstPercentage}
                                  </div>
                                ) : null}
                              </div> */}
                              <Row>
                                <div className="form-group row mb-2">
                                  <label
                                    htmlFor="sub_total"
                                    className="col-2 col-form-label"
                                  >
                                    Sub-total
                                  </label>
                                  <div className="col-sm-6">
                                    <div className="input-group">
                                      <span className="input-group-text">
                                        <i className="mdi mdi-currency-inr"></i>
                                      </span>
                                      <input
                                        id="sub_total"
                                        name="sub_total"
                                        type="text"
                                        className="form-control bg-light"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={""} // Add .toFixed(2) to format the number to 2 decimal places
                                        disabled
                                      />
                                    </div>
                                    {formik.touched.sub_total &&
                                    formik.errors.sub_total ? (
                                      <div className="text-danger">
                                        {formik.errors.sub_total}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Row>

                              <Row>
                                <div className="form-group row mb-2">
                                  <label
                                    htmlFor="gst"
                                    className="col-2 col-form-label"
                                  >
                                    IGST18 (18%){" "}
                                  </label>
                                  <div className="col-sm-6">
                                    <div className="input-group">
                                      <span className="input-group-text">
                                        <i className="mdi mdi-currency-inr"></i>
                                      </span>
                                      <input
                                        id="gst"
                                        name="gst"
                                        type="text"
                                        className="form-control bg-light"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={""}
                                        disabled
                                      />
                                    </div>
                                    {formik.touched.gst && formik.errors.gst ? (
                                      <div className="text-danger">
                                        {formik.errors.gst}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                <div className="row">
                                  <div className="col-2 mb-2">
                                    <label htmlFor="amount">Total</label>
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <div className="input-group">
                                      <span className="input-group-text">
                                        <i className="mdi mdi-currency-inr"></i>
                                      </span>
                                      <input
                                        id="amount"
                                        name="amount"
                                        type="number"
                                        className="form-control bg-light"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={""}
                                        disabled
                                      />
                                    </div>
                                    {formik.touched.amount &&
                                    formik.errors.amount ? (
                                      <div className="text-danger">
                                        {formik.errors.amount}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Row>
                            </Row>
                            <div className="text-center">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default EditInvoice;

import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Offcanvas,
  TabContent,
  OffcanvasHeader,
  OffcanvasBody,
  TabPane,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import { log_debug } from "../../apiHelpers/logger";

function AssessmentDetailsMCQCanvas({ isOpen, toggle, onClose, mcqDetails }) {
  log_debug("mcqDetailsmcqDetails", mcqDetails);
  const offcanvasStyle = {
    width: `40%`,
    zoom: "90%",
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          {/* <p className="text-light">Candidate Details</p> */}
          <h5 className="text-primary">
            MCQ Details &nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Present Mcq details for the
              Selected Question.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col sm="12">
              <div className="flex-grow-1 mb-3 ">
                <div className="border border-2 px-2 py-2">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="font-size-15 text-truncate">
                        <a href="#" className="text-body">
                          {mcqDetails.question}
                        </a>
                      </h5>
                      {/* <p className="font-size-13 text-muted mb-0">{moment(mcqDetails.createdAt).format("DD MMM , YYYY")}</p> */}
                    </div>
                  </div>
                  <div className="pt-1">
                    <div className="hstack mb-2">
                      <ul className="list-inline p-0 m-0">
                        {mcqDetails &&
                          mcqDetails.skills &&
                          mcqDetails.skills.map((skill, index) => (
                            <li className="list-inline-item me-3" key={index}>
                              <a
                                href="javascript: void(0);"
                                className="text-muted"
                              >
                                <i className="bx bx-purchase-tag-alt align-middle text-muted me-1" />{" "}
                                {skill}
                              </a>
                            </li>
                          ))}

                        {/* <li className="list-inline-item me-3">
                                  <a href="javascript: void(0);" className="text-muted">
                                    <i className="bx bx-purchase-tag-alt align-middle text-muted me-1" />{" "}
                                    Skill 2
                                  </a>
                                </li> */}
                      </ul>
                      <span className="badge bg-primary">
                        {mcqDetails.expertise}
                      </span>
                    </div>

                    {mcqDetails &&
                      mcqDetails.options &&
                      mcqDetails.options.map((option, index) => (
                        <span key={index} className="hstack">
                          <i className="bx bx-radio-circle align-middle text-muted mx-1 mb-3" />{" "}
                          <p
                            className={`badge bg-${
                              mcqDetails.answers[0] === option
                                ? "success"
                                : mcqDetails.result === 0 &&
                                    mcqDetails.submitted_answer === option
                                  ? "danger"
                                  : ""
                            }-subtle text-muted ms-1`}
                          >
                            {option}
                          </p>
                        </span>
                      ))}

                    <div></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default AssessmentDetailsMCQCanvas;

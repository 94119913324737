import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

const StatusBadge = ({ status, type }) => {
  const getColor = (status) => {
    if (
      status === "active" ||
      status === "selected" ||
      status === "accepted" ||
      status === "completed" ||
      status === "contracted" ||
      status === "approved"
    ) {
      return "success";
    } else if (
      status === "applied" ||
      status === "on_bench" ||
      status === "started" ||
      status === "pending"
    ) {
      return "warning";
    } else if (status === "inactive" || status === "rejected") {
      return "danger";
    } else {
      return "primary";
    }
    // switch (status) {
    //   case "active":
    //     return "success";
    //   case "inactive":
    //     return "danger";
    //   default:
    //     return "light";
    // }
  };

  return (
    <Badge color={getColor(status)} className="rounded-pill px-3 p-1">
      {type && type}
      {status === "on_bench" ? "On Bench" : status}
    </Badge>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.oneOf(["active", "inactive"]).isRequired,
};

export default StatusBadge;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  sendGetRestRequest,
  sendPostRestRequestAppJson,
} from "../../apiConfig/axiosInstance";
import { messages } from "../../common/data";
import { getIndividualGuid } from "../../apiConfig/globalvariables";
import { Iguid } from "../../apiConfig/axiosDev";
import { log_debug } from "../../apiHelpers/logger";

const initialState = {
  data: {},
  status: "idle",
  loading: false,
  error: "",
};

//Get partner user contracts
export const getPartnerIndividualContracts = createAsyncThunk(
  "PartnerIndividual/Contracts",
  async ({ filtervalue }) => {
    const response = await sendGetRestRequest(
      `v1/individual/${
        getIndividualGuid() || Iguid
      }/fetch_contracts?searchText=${filtervalue}`,
    );
    return response.data;
  },
);

//Get partner user timesheets
export const getPartnerIndividualTimesheet = createAsyncThunk(
  "PartnerIndividual/Timesheet",
  async ({ weeknumber, year }) => {
    const response = await sendGetRestRequest(
      `v1/timesheet/individual-timesheet/${
        getIndividualGuid() || Iguid
      }?week=${weeknumber}&year=${year}`,
    );
    return response.data;
  },
);

//Save  timesheet
export const postPartnerIndividualTimesheet = createAsyncThunk(
  "PartnerIndividual/PostTimesheet",
  async (timesheetdata) => {
    const response = await sendPostRestRequestAppJson(
      `v1/timesheet`,
      timesheetdata,
    );
    return response.data;
  },
);

//Submit weekly timesheet
export const postPartnerIndividualWeeklyTimesheet = createAsyncThunk(
  "PartnerIndividual/PostWeeklyTimesheet",
  async (timesheetdata) => {
    const response = await sendPostRestRequestAppJson(
      `v1/timesheet/submit_weekly_timesheet`,
      timesheetdata,
    );
    return response.data;
  },
);

//Get contracts based on contract id
export const getPartnerIndividualContract = createAsyncThunk(
  "PartnerIndividual/Contract",
  async ({ contractid, guid }) => {
    const response = await sendGetRestRequest(
      `v1/partnercontract/${contractid}/individual_contract_details/${guid}`,
    );
    return response.data;
  },
);

//Get contracts based on contract id
export const getPartnerIndividualApprovedTimesheet = createAsyncThunk(
  "PartnerIndividual/ApprovedTimesheet",
  async (status) => {
    const response = await sendGetRestRequest(
      `v1/timesheet/individual-timesheets/${
        getIndividualGuid() || Iguid
      }/status/${status}`,
    );
    return response.data;
  },
);

//Submit rejected timesheet
export const postPartnerIndividualRejectedTimesheet = createAsyncThunk(
  "PartnerIndividual/PostRejectedTimesheet",
  async ({ timesheetdata, timesheetid }) => {
    log_debug("recieved timesheet data", timesheetdata);
    const response = await sendPostRestRequestAppJson(
      `v1/timesheet/${timesheetid}`,
      timesheetdata,
    );
    return response.data;
  },
);

const PartnerIndividualSlice = createSlice({
  name: "PartnerIndividual",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartnerIndividualContracts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPartnerIndividualContracts.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.partnerindividualcontracts = action.payload;
      })
      .addCase(getPartnerIndividualContracts.rejected, (state, action) => {
        state.status = "idle";
        state.data.partnerindividualcontracts = {
          status: "failed",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getPartnerIndividualTimesheet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPartnerIndividualTimesheet.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.partnerindividualtimesheet = action.payload;
      })
      .addCase(getPartnerIndividualTimesheet.rejected, (state, action) => {
        state.status = "idle";
        state.data.partnerindividualtimesheet = {
          status: "failed",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(postPartnerIndividualTimesheet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postPartnerIndividualTimesheet.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.partnerindividualtimesheetdata = action.payload;
      })
      .addCase(postPartnerIndividualTimesheet.rejected, (state, action) => {
        state.status = "idle";
        state.data.partnerindividualtimesheetdata = {
          status: "failed",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getPartnerIndividualContract.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPartnerIndividualContract.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.partnerindividualcontract = action.payload;
      })
      .addCase(getPartnerIndividualContract.rejected, (state, action) => {
        state.status = "idle";
        state.data.partnerindividualcontract = {
          status: "failed",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getPartnerIndividualApprovedTimesheet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getPartnerIndividualApprovedTimesheet.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.data.partnerindividualapprovedtimesheet = action.payload;
        },
      )
      .addCase(
        getPartnerIndividualApprovedTimesheet.rejected,
        (state, action) => {
          state.status = "idle";
          state.data.partnerindividualapprovedtimesheet = {
            status: "failed",
            message: `${action.error.name} : ${action.error.message}`,
          };
        },
      )

      .addCase(postPartnerIndividualWeeklyTimesheet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        postPartnerIndividualWeeklyTimesheet.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.data.partnerindividualweeklytimesheet = action.payload;
        },
      )
      .addCase(
        postPartnerIndividualWeeklyTimesheet.rejected,
        (state, action) => {
          state.status = "idle";
          state.data.partnerindividualweeklytimesheet = {
            status: "failed",
            message: `${action.error.name} : ${action.error.message}`,
          };
        },
      )

      .addCase(postPartnerIndividualRejectedTimesheet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        postPartnerIndividualRejectedTimesheet.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.data.partnerindividualrejectedtimesheet = action.payload;
        },
      )
      .addCase(
        postPartnerIndividualRejectedTimesheet.rejected,
        (state, action) => {
          state.status = "idle";
          state.data.partnerindividualrejectedtimesheet = {
            status: "failed",
            message: `${action.error.name} : ${action.error.message}`,
          };
        },
      );
  },
});

export default PartnerIndividualSlice.reducer;

import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

//import images
import avatar from "../assets/images/users/user-dummy-img.jpg";
import avatar1 from "../assets/images/users/avatar-1.jpg";
import avatar3 from "../assets/images/users/avatar-3.jpg";
import CustomTable from "../customComponents/reactTable/CustomTable";
import classnames from "classnames";
import ProfileTab2 from "../pages/Contacts/ContactsProfile/Profiletab2";
import ProfileTab3 from "../pages/Contacts/ContactsProfile/Profiletab3";
import ProfileTab1 from "../pages/Contacts/ContactsProfile/ProfileTab1";
import TIQcard from "../customComponents/offcanvas/offcanvasComponents/TIQcard";
import { useSelector, useDispatch } from "react-redux";
import {
  getIndividualAssmntScore,
  getIndividualProfileDetails,
} from "./individualSlice";
import { or_unique } from "../helpers/utils";
import CanvasEditIndiEducation from "./Components/pagesComponents/CanvasEditIndiEducations";
import moment from "moment/moment";
import { FiEdit, FiTrash2, FiUserPlus } from "react-icons/fi";
import { getIndividualAssessments } from "./individualSlice";
import IndividualEditDetailsCanvas from "./Components/pagesComponents/IndividualEditDetailsCanvas";
import { log_debug } from "../apiHelpers/logger";
import AddIndividualEducationCanvas from "./Components/pagesComponents/AddIndividualEducationCavnas";
import AddIndividualWorkExpCanvas from "./Components/pagesComponents/AddIndividualWorkExpCanvas";
import CanvasEditIndiWorkExp from "./Components/pagesComponents/CanvasEditIndiWorkExp";
import NoDataFound from "../customComponents/utilities/NoData";
import GenerateResumeCanvas from "../customComponents/offcanvas/GenerateResumeCanvas";
import { getIndividualGuid } from "../apiConfig/globalvariables";
import { Iguid } from "../apiConfig/axiosDev";

const IndividualProfileDetails = () => {
  const [activeTab, toggleTab] = useState("1");
  const dispatch = useDispatch();
  const IndividualStore = useSelector((state) => state.individualSlice);
  const [IsAddOpenWorkTab4, setIsAddOpenWorkTab4] = useState(false);
  const [IsEditOpenWorkTab4, setIsEditOpenWorkTab4] = useState(false);

  const [profileData, setProfileData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [allIndiAssmnts, setIndiAllAssmnts] = useState({});
  const [resumeData, setResumeData] = useState({});
  const allIndiAssessments = useSelector((state) => state.individualSlice);
  const [IsEditOpenEducationTab4, setIsEditOpenEducationTab4] = useState(false);
  const [IsAddOpenEducationTab1, setIsAddOpenEducationTab1] = useState(false);

  const [isGenerateResumeCanvasOpen, setisGenerateResumeCanvasOpen] =
    useState(false);
  const [SelectedCandidateResume, setSelectedCandidateResume] = useState([]);
  const [DownloadActiveStatus, setDownloadActiveStatus] = useState(false);

  log_debug("Resume data", resumeData);

  const handleEditEducationTab4 = () => {
    setIsEditOpenEducationTab4(true);
  };

  const handleEditWorkTab4 = () => {
    setIsEditOpenWorkTab4(true);
  };

  let indiAssmntGuid = "";

  const closeOffcanvas = () => {
    setIsOpen(false);
  };
  const handleIndividual = (row) => {
    setIsOpen(true);
  };
  //Education details
  const handleAddEducationTab1 = () => {
    setIsAddOpenEducationTab1(true);
  };
  const handleAddWorkTab4 = () => {
    setIsAddOpenWorkTab4(true);
  };

  useEffect(() => {
    dispatch(getIndividualProfileDetails());
  }, []);
  useEffect(() => {
    if (IndividualStore.status === "idle") {
      if (IndividualStore?.data?.ProfileDetails?.success === true) {
        setProfileData(IndividualStore?.data?.ProfileDetails?.data?.individual);
      }
    }
  }, [IndividualStore]);
  useEffect(() => {
    if (IndividualStore.status === "idle") {
      if (IndividualStore?.data?.ProfileDetails?.success === true) {
        setResumeData(IndividualStore?.data?.ProfileDetails?.data?.resume);
      }
    }
  }, [IndividualStore]);

  const getInitials = (fullName) => {
    return fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  };
  useEffect(() => {
    dispatch(getIndividualAssessments({ filtervalue: "" }));
  }, []);

  useEffect(() => {
    if (allIndiAssessments.status === "idle") {
      if (allIndiAssessments.data.assessmentData?.success === true) {
        indiAssmntGuid =
          allIndiAssessments.data.assessmentData?.data?.assessments[0]?.guid;
      }
      if (allIndiAssessments.data.assessmentData?.success === true) {
        setIndiAllAssmnts(
          allIndiAssessments?.data?.IndiAssmntScore?.data?.assessmentsession,
        );

        // log_debug(" allIndiAssessments.data.assessmentData?.data?.assessments", allIndiAssessments.data.IndiAssmntScore.data.assessmentsession);
      }
      // setloading(false);
    }
  }, [allIndiAssessments]);
  useEffect(() => {
    dispatch(getIndividualAssmntScore(indiAssmntGuid));
  }, [indiAssmntGuid]);

  const closeGenerateResumeCanvas = () => {
    setisGenerateResumeCanvasOpen(false);
  };

  const handleDownloadResume = () => {
    setisGenerateResumeCanvasOpen(true);
    setSelectedCandidateResume({
      guid: getIndividualGuid() || Iguid,
    });
    setDownloadActiveStatus(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl={6} lg={6}>
                      <div className="col-sm order-2 order-sm-1">
                        <div className="d-flex align-items-start mt-3 mb-4 mt-sm-0">
                          {/* <div className="flex-shrink-0">
                            <div className="avatar-xl me-3">
                              <img
                                src={avatar}
                                alt=""
                                className="img-fluid rounded-circle d-block"
                              />
                            </div>
                          </div> */}
                          <div className="avatar-xm mx-auto mb-0 me-3 mt-1">
                            <span
                              className="avatar-title bg-primary text-white font-size-24 p-3 display-3 m-0 rounded-circle"
                              style={{
                                width: "65px",
                                height: "65px",
                              }}
                            >
                              {getInitials(profileData?.full_name || "-")}
                            </span>
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-16 mb-1">
                                {profileData?.full_name || "-"}
                              </h5>
                              <p className="text-muted font-size-13 mb-1">
                                {profileData?.role || "-"}
                              </p>
                              <p className="text-muted mb-0">
                                <div className="d-flex flex-wrap gap-1 font-size-15">
                                  {profileData &&
                                    profileData.skills &&
                                    profileData.skills.length > 0 &&
                                    or_unique(profileData.skills).map(
                                      (skills, index) => (
                                        <span key={index}>
                                          <Link
                                            to="#"
                                            className="badge bg-primary rounded-pill"
                                          >
                                            {skills}
                                          </Link>
                                        </span>
                                      ),
                                    )}
                                </div>
                              </p>

                              <div className=" align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                {/* <div>
                                  <i className="mdi mdi-circle-medium me-1 text-primary align-middle"></i>
                                  {profileData?.designation || 'Development'}
                                </div> */}
                                <div>
                                  {/* <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                  {profileData?.email || 'phyllisgatlin@minia.com'} */}
                                  {
                                    <>
                                      {" "}
                                      <i className="mdi mdi-email me-1 text-primary align-middle" />{" "}
                                      {profileData?.email || "-"}
                                    </>
                                  }
                                </div>
                                <div>
                                  {/* <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                  {profileData?.contact_number || '1154145542'} */}
                                  <>
                                    <i className="mdi mdi-phone me-1 text-primary align-middle" />
                                    {profileData?.contact_number || "-"}
                                  </>
                                </div>
                                <div>
                                  {/* <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                  {profileData?.contact_number || '1154145542'} */}
                                  {profileData?.address?.street ||
                                  profileData?.address?.city ||
                                  profileData?.address?.street ? (
                                    <>
                                      <i className="mdi mdi-google-maps me-1 text-primary align-middle" />
                                      {profileData?.address?.street +
                                        "," +
                                        profileData?.address?.city +
                                        "," +
                                        profileData?.address?.country || "-"}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <Button
                                  color="primary"
                                  className="p-1 mt-1"
                                  onClick={() => handleDownloadResume()}
                                >
                                  Download Resume{" "}
                                  <i className="fas fa-download ms-1" />
                                </Button>
                                <div>
                                  {/* <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                  {profileData?.experience || '1' + ' yrs'} */}
                                  <>
                                    {" "}
                                    {profileData &&
                                    profileData.experience &&
                                    profileData.experience > 0 ? (
                                      <>
                                        {" "}
                                        <i className="mdi mdi-briefcase me-1 text-primary align-middle" />
                                        <p>
                                          {profileData?.experience + " yrs"}
                                        </p>{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </>
                                </div>
                              </div>
                              {/* <div className="d-flex gap-2 py-3">
                                <Link className="btn btn-primary p-1">
                                  Generate Resume{" "}
                                  <i className="fas fa-file-alt mx-1" />
                                </Link>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={1}>
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-outline-primary p-1 ps-2 pe-2 rounded"
                          onClick={() => {
                            handleIndividual();
                          }}
                        >
                          <i className="fas fa-edit me-1"></i>
                          Edit
                        </button>
                      </div>
                    </Col> */}
                    <Col xl={5}>
                      <div className="">
                        {(allIndiAssmnts &&
                          allIndiAssmnts.score &&
                          allIndiAssmnts.score.score > 0) ||
                        (allIndiAssmnts &&
                          allIndiAssmnts.score &&
                          allIndiAssmnts.score > 0) ? (
                          <TIQcard
                            data={allIndiAssmnts}
                            //menuOption="assessment"
                            mcq={allIndiAssmnts?.score?.mcq}
                            coding={allIndiAssmnts?.score?.coding}
                            behavioural={allIndiAssmnts?.score?.behavioural}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Nav className="nav-tabs-custom card-header-tabs border-top mb-4 mt-1">
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames(
                          {
                            active: activeTab === "1",
                          },
                          "px-3",
                        )}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames(
                          {
                            active: activeTab === "2",
                          },
                          "px-3",
                        )}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        Education
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames(
                          {
                            active: activeTab === "3",
                          },
                          "px-3",
                        )}
                        onClick={() => {
                          toggleTab("3");
                        }}
                      >
                        Work Experience
                      </NavLink>
                    </NavItem>
                    <pre> </pre>
                    {activeTab === "1" ? (
                      <Col lg={1}>
                        <div className="float-end">
                          <button
                            type="button"
                            className="btn btn-primary p-1 ps-2 pe-2 mt-3 rounded"
                            onClick={() => {
                              handleIndividual();
                            }}
                          >
                            <i className="fas fa-edit me-1"></i>
                            Edit
                          </button>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {activeTab === "2" && resumeData ? (
                      <Col lg={1}>
                        <div className="float-end">
                          <button
                            type="button"
                            className="btn btn-primary p-1 ps-2 pe-2 mt-3 rounded"
                            onClick={() => {
                              handleAddEducationTab1();
                            }}
                          >
                            <i className="fas fa-plus me-1"></i>
                            Add
                          </button>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    {resumeData &&
                    resumeData.educational_qualifications &&
                    resumeData.educational_qualifications.length > 0 &&
                    activeTab === "2" ? (
                      <Col lg={1}>
                        <div className="float-end">
                          <button
                            type="button"
                            className="btn btn-primary p-1 ps-2 pe-2 mt-3 rounded"
                            onClick={() => {
                              handleEditEducationTab4();
                            }}
                          >
                            <i className="fas fa-edit me-1"></i>
                            Edit
                          </button>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {activeTab === "3" && resumeData ? (
                      <Col lg={1}>
                        <div className="float-end">
                          <button
                            type="button"
                            className="btn btn-primary p-1 ps-2 pe-2 mt-3 rounded"
                            onClick={() => {
                              handleAddWorkTab4();
                            }}
                          >
                            <i className="fas fa-plus me-1"></i>
                            Add
                          </button>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    {resumeData &&
                    resumeData.work_experiences &&
                    resumeData.work_experiences.length > 0 &&
                    activeTab === "3" ? (
                      <Col lg={1}>
                        <div className="float-end">
                          <button
                            type="button"
                            className="btn btn-primary p-1 ps-2 pe-2 mt-3 rounded"
                            onClick={() => {
                              handleEditWorkTab4();
                            }}
                          >
                            <i className="fas fa-edit me-1"></i>
                            Edit
                          </button>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col xl={9} lg={8}>
                          {/* <ProfileTab1 /> */}

                          <div className="row">
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label htmlFor="progresspill-firstname-input">
                                  {" "}
                                  Date Of Birth
                                </label>

                                <p className="text-muted mb-0">
                                  {" "}
                                  {(profileData &&
                                    profileData.date_of_birth != null) ||
                                  ""
                                    ? moment(profileData?.date_of_birth).format(
                                        "DD MMM , YYYY",
                                      )
                                    : "-"}
                                </p>
                              </div>
                            </div>
                            {/* <div className="col-lg-3">
                              <div className="mb-3">
                                <label htmlFor="progresspill-firstname-input">
                                  {" "}
                                  Marital Status
                                </label>

                                <p className="text-muted mb-0">
                                  {" "}
                                  {profileData?.marital_status}
                                </p>
                              </div>
                            </div> */}
                            <div className="col-lg-3">
                              <div className="mb-3">
                                <label htmlFor="progresspill-email-input">
                                  Availability
                                </label>

                                <p className="text-muted mb-0">
                                  {profileData?.availability}
                                </p>
                              </div>
                            </div>
                            {/* <div className="col-lg-3">
                              <div className="mb-3">
                                <label htmlFor="progresspill-email-input">
                                  Proficiency in english
                                </label>

                                <p className="text-muted mb-0">
                                  {profileData?.english_proficiency}
                                </p>
                              </div>
                            </div> */}
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label htmlFor="progresspill-address-input">
                                Summary
                              </label>

                              <p className="text-muted mb-0">
                                {profileData?.summary}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            {/* <div className="mb-3">
                          <label htmlFor="progresspill-address-input">
                            Skills
                          </label>

                          <p className="text-muted mb-0">
                            <div className="d-flex flex-wrap gap-2 font-size-16">
                              {profileData?.skills.map((skills, index) => (
                                <span key={index}>
                                  <Link
                                    to="#"
                                    className="badge bg-primary-subtle text-primary"
                                  >
                                    {skills}
                                  </Link>
                                </span>
                              ))}

                            </div>
                          </p>
                        </div> */}
                          </div>
                        </Col>
                        <Col xl={3} lg={3}>
                          <Card>
                            <CardBody>
                              <h5 className="mb-3 card-title">Portfolio</h5>

                              <div>
                                <ul className="list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to={profileData?.linkedin_url}
                                      className="py-2 d-block text-muted"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="mdi mdi-linkedin text-primary me-1"></i>{" "}
                                      LinkedIn
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        {/* <Col>
                        <button
                            type="button"
                            className="btn btn-primary p-1 ps-2 pe-2 rounded"
                            onClick={() => {
                              handleIndividual();
                            }}
                          >
                            <i className="fas fa-edit"></i>
                          
                          </button>
                        </Col> */}
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      {/* <ProfileTab2 /> */}

                      <div className="mt-4 mb-4">
                        {/* <h6 className="font-size-13">
                          Education Qualifications :
                        </h6> */}
                        <ul className="list-unstyled mb-0">
                          {resumeData &&
                          resumeData.educational_qualifications &&
                          resumeData.educational_qualifications.length > 0 ? (
                            resumeData.educational_qualifications.map(
                              (education, index) => (
                                <li className="font-size-14" key={index}>
                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                  <strong className="text-muted ">
                                    {education.degree_name}
                                    &nbsp;&nbsp;
                                  </strong>
                                  <p className="mx-3 font-size-14">
                                    - {education.college_or_university_name}{" "}
                                    &nbsp;&nbsp;{" "}
                                    <em>
                                      {new Date(
                                        education.from_date,
                                      ).toLocaleDateString()}
                                      <strong className="text-primary">
                                        {" "}
                                        -{" "}
                                      </strong>
                                      {new Date(
                                        education.to_date,
                                      ).toLocaleDateString()}
                                    </em>
                                  </p>
                                </li>
                              ),
                            )
                          ) : (
                            //
                            <NoDataFound />
                          )}
                          {/* <Button
                            color="primary"
                            type="button"
                            className="text-white my-2 rounded"
                            // onClick={(e) => {
                            //   handleEditEducationTab2(e);
                            // }}
                          >
                            <FiEdit className="mx-1" />
                          </Button> */}
                          {/* <CustomTable data={educational_qualifications} columns={education_cols}/> */}
                        </ul>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      {/* <ProfileTab3 /> */}
                      <div className="mt-4 mb-4">
                        {/* <h6 className="font-size-13">
                      Work Experience :
                    </h6> */}
                        <ul className="list-unstyled mb-0">
                          {resumeData &&
                          resumeData.work_experiences &&
                          resumeData.work_experiences.length > 0 ? (
                            resumeData.work_experiences.map(
                              (work_exp, index) => (
                                <li className="font-size-14" key={index}>
                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                  <strong className="text-muted ">
                                    {work_exp.role}
                                    &nbsp;&nbsp;
                                  </strong>
                                  <p className="mx-3 font-size-14">
                                    - {work_exp.company_name} &nbsp;&nbsp;{" "}
                                    <em>
                                      {new Date(
                                        work_exp.from_date,
                                      ).toLocaleDateString()}
                                      <strong className="text-primary">
                                        {" "}
                                        -{" "}
                                      </strong>
                                      {new Date(
                                        work_exp.to_date,
                                      ).toLocaleDateString()}
                                    </em>
                                  </p>
                                </li>
                              ),
                            )
                          ) : (
                            <NoDataFound />
                          )}
                        </ul>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isOpen && (
          <IndividualEditDetailsCanvas
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            onClose={closeOffcanvas}
            data={profileData}
          />
        )}
        {IsEditOpenEducationTab4 && (
          <CanvasEditIndiEducation
            isOpen={IsEditOpenEducationTab4}
            data={resumeData}
            // editedData={editedData}
            // handleSave={(editedData) =>
            //   handleSaveChangesEducationTab1(
            //     editedData,
            //   )
            // }
            // formData={editedData}
            toggle={() => setIsEditOpenEducationTab4(!IsEditOpenEducationTab4)}
          />
        )}
        {IsAddOpenEducationTab1 && (
          <AddIndividualEducationCanvas
            dataEdu={resumeData}
            // handleSave={
            //   handleAddEducationDetailsTab1
            // }
            isOpen={IsAddOpenEducationTab1}
            toggle={() => setIsAddOpenEducationTab1(!IsAddOpenEducationTab1)}
          />
        )}
        {IsAddOpenWorkTab4 && (
          <AddIndividualWorkExpCanvas
            // handleSave={
            //   handleAddWorkExperienceTab4
            // }
            data={resumeData}
            isOpen={IsAddOpenWorkTab4}
            toggle={() => setIsAddOpenWorkTab4(!IsAddOpenWorkTab4)}
          />
        )}
        {IsEditOpenWorkTab4 && (
          <CanvasEditIndiWorkExp
            isOpen={IsEditOpenWorkTab4}
            data={resumeData}
            // editedData={editedData}
            // handleSave={(editedData) =>
            //   handleSaveChangesWorkTab4(
            //     editedData,
            //   )
            // }
            // formData={editedData}
            toggle={() => setIsEditOpenWorkTab4(!IsEditOpenWorkTab4)}
          />
        )}
        {isGenerateResumeCanvasOpen && (
          <GenerateResumeCanvas
            width={60}
            isOpen={isGenerateResumeCanvasOpen}
            toggle={() =>
              setisGenerateResumeCanvasOpen(!isGenerateResumeCanvasOpen)
            }
            onClose={closeGenerateResumeCanvas}
            data={SelectedCandidateResume}
            downloadstatus={DownloadActiveStatus}
            usertype={"individual"}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default IndividualProfileDetails;

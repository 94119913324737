import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import partnerSliceReducer from "../Partner/PartnerSlice";
import IndividualSliceReducer from "../individual/individualSlice";
import clientSliceReducer from "../Client/ClientSlice";
import AuthenticationReducer from "../AuthenticationSlice/AuthenticationSlice";
import PartnerIndividualSliceReducer from "../Partner_Individual/PartnerIndividualSlice/PartnerIndividualSlice";
import { common } from "../../src/apiConfig/axiosDev";

const store = configureStore({
  //  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  middleware: (getDefaultMiddleware) =>
    common.log_enabled
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware(),
  reducer: {
    partnerSlice: partnerSliceReducer,
    individualSlice: IndividualSliceReducer,
    clientSlice: clientSliceReducer,
    authenticationSlice: AuthenticationReducer,
    partnerindividualslice: PartnerIndividualSliceReducer,
  },
});

export default store;

import React, { useEffect, useState } from "react";
import DropdownWithTwoField from "../DropdownWithTwoField";
import { useDispatch, useSelector } from "react-redux";
import { getJobRoles, getLocations } from "../../Client/ClientSlice";

function AdvanceFilter({ onFilterChange }) {
  const clientstore = useSelector((state) => state.clientSlice);
  const dispatch = useDispatch();
  const [Locations, setLocations] = useState([]);
  const [JobRoles, setJobRoles] = useState([]);

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.locations?.success === true) {
        setLocations(clientstore.data.locations?.data?.enumcitys);
      } else {
        dispatch(getLocations());
      }
    }
  }, [clientstore.data.locations]);

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.jobroles?.success === true) {
        setJobRoles(clientstore.data.jobroles?.data?.enumskills);
      } else {
        dispatch(getJobRoles());
      }
    }
  }, [clientstore.data.jobroles]);

  const jobroleOptions = JobRoles.map((item) => ({
    value: item.code,
    label: item.title,
  }));

  const locationOptions = Locations.map((item) => ({
    value: item.code,
    label: item.title,
  }));

  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedJobRole, setSelectedJobRole] = useState("");

  const handleLocationChange = (selectedOptions) => {
    setSelectedLocation(selectedOptions);
    //onFilterChange({ location: selectedLocation, jobRole: selectedJobRole });
  };

  const handleJobRoleChange = (selectedOptions) => {
    setSelectedJobRole(selectedOptions);
    //onFilterChange({ location: selectedLocation, jobRole: selectedJobRole });
  };

  useEffect(() => {
    onFilterChange({ location: selectedLocation, jobRole: selectedJobRole });
  }, [selectedLocation, selectedJobRole]);

  return (
    <>
      <div className="col-12 col-lg-6 col-md-6 d-sm-flex">
        <div className="col-12 col-lg-6">
          <DropdownWithTwoField
            options={jobroleOptions}
            placeholder="Select Technology"
            onSelectChange={handleJobRoleChange}
          />
        </div>{" "}
        &nbsp;
        <div className="col-12 col-lg-6">
          <DropdownWithTwoField
            // label="Option 1"
            options={locationOptions}
            placeholder="Select locations"
            onSelectChange={handleLocationChange}
          />
        </div>
      </div>
    </>
  );
}

export default AdvanceFilter;

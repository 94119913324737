import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
} from "reactstrap";

import CustomTable from "../customComponents/reactTable/CustomTable";
import {
  getPartnerIndividualApprovedTimesheet,
  getPartnerIndividualContracts,
} from "./PartnerIndividualSlice/PartnerIndividualSlice";
import { log_debug } from "../apiHelpers/logger";
import { dateyyyymmdd_tohtml } from "../helpers/date";
import { Link } from "react-router-dom";
import StatusBadge from "../customComponents/utilities/StatusBadge";
import ContractDetailCanvas from "../Partner/pagesComponents/ContractDetailCanvas";
import DisplayDates from "./Components/DisplayDates";
// import ContractDetailCanvas from "../customComponents/offcanvas/ContractDetailCanvas";

function PartnerIndividualSubmittedTimesheets() {
  const dispatch = useDispatch();
  const partnerindividualstore = useSelector(
    (state) => state.partnerindividualslice,
  );

  const [ApprovedData, setApprovedData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [contractinfo, setContractinfo] = useState({});

  const handleContract = (row) => {
    setContractinfo(row);
    setIsOpen(true);
  };

  const closeOffcanvas = () => {
    setIsOpen(false);
  };

  const columns = [
    {
      Header: "Timesheet Period",
      accessor: "guid",
      Cell: ({ row }) => <DisplayDates row={row.original} />,
    },
    {
      Header: "Date Submitted",
      accessor: "createdAt",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Billable",
      accessor: "",
    },
    {
      Header: "Non-Billable",
      accessor: "",
      //Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Total",
      accessor: "",
      //Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex gap-1">
          {/* <p className="font-size-12 m-0"></p> */}
          <Badge color="warning" className="rounded-pill">
            {row.values.status}
          </Badge>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    try {
      dispatch(getPartnerIndividualApprovedTimesheet("pending")).then((res) => {
        log_debug("approved timesheet data", res.payload);
        if (res.payload.success === true) {
          setIsLoading(false);
          const data = res.payload.data?.timesheets;
          setApprovedData(data);
        } else if (res.payload.success === false) {
          setIsLoading(false);
          setApprovedData([]);
        }
      });
    } catch (error) {
      log_debug("Error loading data", error);
    }
  }, []);

  const handlePageChange = (newPage) => {
    setcurrentPage(newPage);
  };

  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <CustomTable
                    tableDatas={ApprovedData}
                    columns={columns}
                    data={ApprovedData}
                    hideCheckbox={true}
                    totalCount={totalCount}
                    isLoading={isLoading}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PartnerIndividualSubmittedTimesheets;

import React, { useEffect, useState } from "react";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Container,
  CardHeader,
  Badge,
  Button,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import addcandidateimage from "../../src/assets/images/companyBenchImage/undraw_interview_re_e5jn.svg";

import Breadcrumbs from "../components/Common/Breadcrumb";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import CustomTable from "../customComponents/reactTable/CustomTable.js";
import {
  mycandidates,
  addCandidatesSkills,
  addCandidatesLocation,
} from "../Partner/PartnerSlice.js";
import "../Partner/partner.css";
import "../../src/assets/css/styleCompanyBench.css";
import { Link, useHistory, useNavigate } from "react-router-dom";
// import ComanOffcanvasMyCandi from "../customComponents/ComanOffcanvasMyCandi.js";
import CanvasCandidate from "../customComponents/offcanvas/CanvasCandidate.js";
import { log_debug } from "../apiHelpers/logger.js";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol.js";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar.js";
import { dateyyyymmdd_tohtml } from "../helpers/date.js";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb.js";
import CandidateStatusBadge from "../customComponents/utilities/CandidateStatusBadge.js";

// import Paper from "@material-ui/core/Paper";
// import {
//   Chart,
//   PieSeries,
//   Tooltip,
// } from "@devexpress/dx-react-chart-material-ui";

// import { EventTracker } from "@devexpress/dx-react-chart";

function RecruiteeCandidate() {
  // const [data, setdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [AddCandidateSkills, setAddCandidateSkills] = useState([]);
  const [AddCandidateLocation, setAddCandidateLocation] = useState([]);
  const [MyCandidateList, setMyCandidateList] = useState([]);
  const [MyCandidateListCount, setMyCandidateListCount] = useState([]);
  const [Candidatejobsloading, setCandidatejobsloading] = useState(true);

  const [selectedTechnology, setSelectedTechnology] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const partnerStore = useSelector((state) => state.partnerSlice);

  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [filtervalue, setFiltervalue] = useState("");
  const [isRight, setIsRight] = useState(false);

  const experienceData = [
    { value: 4, argument: "Technical Skills" },
    { value: 3, argument: "Soft Skills" },
    { value: 2, argument: "Team Work" },
    { value: 5, argument: "Management" },
  ];

  const candidateName = (guid) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedCandidate(guid);
    setOffcanvasOpen(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  // const dispatch = useDispatch();
  // const partnerDataRes = useSelector((state) => state.partnerSlice?.data.partnerdata);
  // console.warn("partnerDataRes",partnerDataRes);
  // function capitalizeWords(str) {
  //   return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  // }
  const [isChecked, setChecked] = useState(true);

  const handleToggle = () => {
    setChecked(!isChecked);
  };
  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateName(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar fullName={row.values.full_name} />
            <span>
              {row.values.full_name.length > 10
                ? `${row.values.full_name.slice(0, 12)}...`
                : row.values.full_name}
            </span>
          </div>
        </Link>
      ),
    },

    {
      Header: "Email",
      accessor: "email",
      width: 100,
    },

    {
      Header: "Skills",
      accessor: "skills",
      width: 100,
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    {
      Header: "Location",
      accessor: "prefered_locations",
      Cell: ({ row }) => (
        <div className="">
          {row?.original?.location?.length >= 1
            ? row?.original?.location.join(", ")
            : "-"}
        </div>
      ),
      // width: 100,
      // Cell: ({ value }) => (
      //   <>
      //     <div>{value?.length >= 1 ? value : "-"}</div>
      //   </>
      // ),
    },

    {
      Header: "Status",
      accessor: "status",
      // Cell: ({ value }) => value.join(", "),
      Cell: ({ value }) => <CandidateStatusBadge status={value} />,
    },
    // {
    //   Header: "Active/Inactive",
    //   accessor: "isEnabled",
    //   width: 100,

    //   Cell: ({ value }) => (
    //     <>
    //       <div className="form-check form-switch text-center">
    //         <input
    //           className="form-check-input mx-auto"
    //           style={{ width: "40px", height: "24px" }}
    //           type="checkbox"
    //           id="toggleSwitch"
    //           checked={isChecked}
    //           onChange={handleToggle}
    //         />
    //         {/* <label className="form-check-label" htmlFor="toggleSwitch">
    //       {isChecked ? 'Enabled' : 'Disabled'}
    //     </label> */}
    //       </div>
    //     </>
    //   ),
    // },
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: (props) => {
        // const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
        return <span>{dateyyyymmdd_tohtml(props?.value)}</span>;
      },
    },
    {
      Header: "Action",
      accessor: "action",
      width: 100,

      Cell: ({ row }) => (
        <>
          <div className="align-items-start">
            <Link
              to={"/candidatedetails"}
              color="primary"
              type="button"
              className=" btn btn-primary text-white rounded"
              onClick={(e) => {
                handleAction(e, row.original);
              }}
            >
              <FiEdit className="mx-1" />
              {/* Edit */}
            </Link>
          </div>
        </>
      ),
    },

    // Add more columns as needed
  ];

  const handleAction = (e, rowData) => {
    // Assuming 'value' contains the data you want to pass
    const dataToPass = rowData && rowData;
    // Use 'to' and 'state' props to navigate and pass data
    e.preventDefault();
    navigate("/candidatedetails", { state: { individualGuid: rowData.guid } });
  };

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data.CandidatesSkills?.success === true &&
        partnerStore.data.CandidatesLocation?.success === true
      ) {
        setAddCandidateSkills(
          partnerStore.data.CandidatesSkills?.data?.enumskills,
        );
        setAddCandidateLocation(
          partnerStore.data.CandidatesLocation?.data?.enumcitys,
        );
      } else {
        dispatch(addCandidatesSkills());
        dispatch(addCandidatesLocation());
      }
    }
  }, []);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore?.data?.partnerdata?.success === true) {
        setCandidatejobsloading(false);
        setMyCandidateList(partnerStore?.data?.partnerdata?.data?.individuals);
        setMyCandidateListCount(
          partnerStore?.data?.partnerdata?.data?.totalCount,
        );
      }
      if (partnerStore?.data?.partnerdata?.success === false) {
        setCandidatejobsloading(false);
      }
      if (partnerStore?.data?.CandidatesSkills?.success === true) {
        setAddCandidateSkills(
          partnerStore?.data?.CandidatesSkills?.data?.enumskills,
        );
      }
      if (partnerStore?.data?.CandidatesLocation?.success === true) {
        setAddCandidateLocation(
          partnerStore?.data?.CandidatesLocation?.data?.enumcitys,
        );
      }
    }
  }, [partnerStore]);

  const skillOptions = AddCandidateSkills?.map((item) => ({
    value: item?.code,
    label: item?.title,
  }));

  const locationOptions = AddCandidateLocation?.map((item) => ({
    value: item?.code,
    label: item?.title,
  }));
  // log_debug("List Length:", MyCandidateList?.length);
  // const count = MyCandidateList?.length;
  // const MyCandidateList = {

  // }
  // const handleSelectChange = (selectedOptions) => {
  //   // Your logic when the selection changes
  // };
  const handleTechnologyChange = (selectedOptions) => {
    const selectedValues =
      selectedOptions && selectedOptions?.map((option) => option?.label);
    setSelectedTechnology(selectedValues);
  };
  const handleLocationChange = (selectedOptions) => {
    const selectedValues =
      selectedOptions && selectedOptions?.map((option) => option?.label);
    setSelectedLocations(selectedValues);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        mycandidates({
          skills: selectedTechnology ? selectedTechnology : "",
          locations: selectedLocations ? selectedLocations : "",
          currentPage: currentPage ? currentPage : currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [selectedTechnology, selectedLocations, currentPage, filtervalue]);

  useEffect(() => {
    dispatch(
      mycandidates({
        skills: selectedTechnology ? selectedTechnology : "",
        locations: selectedLocations ? selectedLocations : "",
        currentPage: currentPage ? currentPage : currentPage,
      }),
    );
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleValueChange = (newValue) => {
    setCandidatejobsloading(true);
    setFiltervalue(newValue);
  };

  const breadcrumbValues = ["Candidates"];
  return (
    <React.Fragment>
      <div className="page-content" style={{ zoom: "100%" }}>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="row col-lg-12">
            <div className="col-lg-12">
              <CustomBreadcrumb values={breadcrumbValues} />
            </div>
            <div className="col-lg-12">
              <Row>
                <div className="col-lg-12">
                  <div>
                    <Row>
                      <div className="col-12 col-lg-6 col-md-6 d-sm-flex">
                        <div className="col-12 col-lg-6">
                          <DropdownWithTwoField
                            // label="Option 1"
                            options={skillOptions}
                            placeholder="Select Technololy"
                            onSelectChange={handleTechnologyChange}
                          />
                        </div>{" "}
                        &nbsp;
                        <div className="col-12 col-lg-6">
                          <DropdownWithTwoField
                            options={locationOptions}
                            placeholder="Select locations"
                            onSelectChange={handleLocationChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 col-md-6">
                        <Col md={12}>
                          <CardTitle className="page-title text-muted">
                            <span>Total count : </span>
                            <strong>
                              {MyCandidateListCount ? MyCandidateListCount : 0}
                            </strong>

                            {/* <strong>{"197"}</strong> */}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Card>
                        <CardBody>
                          <CustomTable
                            showaddcandidate={true}
                            totalCount={MyCandidateListCount}
                            columns={columns}
                            data={
                              MyCandidateList?.length >= 1
                                ? MyCandidateList
                                : []
                            }
                            jobStatus={true}
                            hideCheckbox={true}
                            isLoading={Candidatejobsloading}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            onChange={handleValueChange}
                            globalsearch={true}
                          />
                        </CardBody>
                      </Card>
                    </Row>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <CanvasCandidate
          usertype={"recruitment"}
          placement="end"
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={selectedCandidate}
        />
      )}
    </React.Fragment>
  );
}

export default RecruiteeCandidate;

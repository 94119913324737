import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Container,
  CardHeader,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb.js";
import { Iguid, Pguid } from "../apiConfig/axiosDev";
import { getPartnerGuid } from "../apiConfig/globalvariables";
import CustomTable from "../customComponents/reactTable/CustomTable.js";
import {
  Amount,
  BillingName,
  InvoiceId,
  Status,
} from "../customComponents/utilities/Invoicelistcol.js";
import { FiTrash2, FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import InvoiceDetailsCanvas from "../customComponents/invoice/InvoiceDetailsCanvas.js";
import { useDispatch, useSelector } from "react-redux";
import { InvoiceListTable, getFilterInvoice } from "./PartnerSlice.js";
import { log_debug } from "../apiHelpers/logger.js";
import { dateyyyymmdd_tohtml } from "../helpers/date.js";

const PartnerInvoice = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [InvoideData, setInvoideData] = useState([]);
  const [InvoiceDataCount, setInvoiceDataCount] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [InvoiceList, setInvoiceList] = useState([]);
  const [filtervalue, setFiltervalue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  log_debug("InvoiceList", InvoiceList);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);

  const toggleModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        InvoiceListTable({
          currentPage: currentPage ? currentPage : 0,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [currentPage, filtervalue]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore?.data?.InvoiceListTabledata?.success === true) {
        setisLoading(false);
        setInvoiceList(
          partnerStore?.data?.InvoiceListTabledata?.data?.invoices,
        );
        setInvoiceDataCount(
          partnerStore?.data?.InvoiceListTabledata?.data?.totalCount,
        );
      } else if (partnerStore?.data?.InvoiceListTabledata?.success === false) {
        setInvoiceList([]);
        setInvoiceDataCount(0);
      }
    } else if (partnerStore.status === "loading") {
      setisLoading(true);
    }
  }, [partnerStore?.data?.InvoiceListTabledata]);

  const columns = [
    {
      Header: "Invoice ID",
      accessor: "short_guid",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleInvoice(row.original);
          }}
        >
          {row.original?.short_guid}
        </Link>
      ),
    },
    {
      Header: "Candidate Name",
      accessor: "name",
    },
    {
      Header: "Designation",
      accessor: "designation",
    },
    {
      Header: "Invoice Date",
      accessor: "Invoice_date",
      Cell: (props) => {
        // const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
        return <span>{dateyyyymmdd_tohtml(props?.value)}</span>;
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      filterable: true,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <i className=" mdi mdi-currency-inr"></i> <Amount {...cellProps} />
          </>
        );
      },
    },
    {
      Header: "Due Date",
      accessor: "due_date",
      Cell: (props) => {
        // const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
        return <span>{dateyyyymmdd_tohtml(props?.value)}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (cellProps) => {
        return <Status {...cellProps} />;
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <>
          {row.original.status === "rejected" && (
            <div className="align-items-start">
              <Link
                to={"/edit-invoice"}
                color="primary"
                type="button"
                className=" btn btn-primary text-white rounded"
                // onClick={(e) => {
                //   handleAction(e, row.original);
                // }}
              >
                <FiEdit className="mx-1" />
              </Link>
              <Link
                to={"#"}
                color="danger"
                type="button"
                className=" btn btn-danger mx-1 text-white rounded"
                onClick={(e) => {
                  toggleModal(e, row.original.id);
                }}
              >
                <FiTrash2 className="mx-1" />
              </Link>
            </div>
          )}
        </>
      ),
    },
  ];

  const handleValueChange = (newValue) => {
    setisLoading(true);
    setFiltervalue(newValue);
  };

  const breadcrumbValues = ["Invoices"];

  const handleInvoice = (row) => {
    setIsOpen(true);
    setInvoideData(row);
  };

  const closeOffcanvas = () => {
    setIsOpen(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const updateData = {
    partner_guid: getPartnerGuid() || Pguid,
    email_from: "",
    email_to: "",
    Invoice_date: "",
    due_date: "",
    terms: "",
    name: "",
    email: "",
    role: "",
    start_date: "",
    end_date: "",
    rate: "",
    billing_cycle: "",
    billing_days: "",
    sub_total: "",
    gst: "",
    amount: "",
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="row col-lg-12">
            <div className="col-lg-12">
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <Row>
                        <div className="col-12 col-lg-6 col-md-6 d-sm-flex">
                          <div className="col-12 col-lg-6">
                            {/* <DropdownWithTwoField
                                // label="Option 1"
                                options={skillOptions}
                                placeholder="Select Technology"
                              /> */}
                            <div className="col-lg-12">
                              <CustomBreadcrumb values={breadcrumbValues} />
                            </div>
                          </div>{" "}
                          &nbsp;
                          {/* <div className="col-12 col-lg-6">
                              <DropdownWithTwoField
                                options={locationOptions}
                                placeholder="Select Locations"
                              />
                            </div> */}
                        </div>

                        <div className="col-12 col-lg-6 col-md-6">
                          <Col md={12}>
                            <CardTitle className="page-title text-muted">
                              <span>
                                Total count :{" "}
                                <span className="fw-bold">
                                  {InvoiceDataCount ? InvoiceDataCount : 0}
                                </span>
                              </span>
                              <strong>
                                {/* {AllContractcount ? AllContractcount : 0} */}
                              </strong>
                            </CardTitle>
                          </Col>
                        </div>
                      </Row>
                    </div>
                    <Row>
                      <Col lg="12">
                        <Card>
                          <CardBody>
                            <CustomTable
                              columns={columns}
                              data={InvoiceList}
                              hideCheckbox={true}
                              InvoiceStatus={true}
                              updateData={updateData && updateData}
                              totalCount={InvoiceDataCount}
                              isLoading={isLoading}
                              globalsearch={true}
                              onChange={handleValueChange}
                              onPageChange={handlePageChange}
                              currentPage={currentPage}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            </div>
          </div>
        </Container>
      </div>
      {isOpen && (
        <InvoiceDetailsCanvas
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          onClose={closeOffcanvas}
          data={InvoideData}
        />
      )}
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          style={{ maxWidth: "30%" }}
        >
          <ModalBody className="py-3 px-5">
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <i
                    className="mdi mdi-alert-circle-outline"
                    style={{ fontSize: "9em", color: "orange" }}
                  />
                  <h2>Are you sure?</h2>
                  <h4>{"You won't be able to revert this!"}</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center mt-3">
                  <button
                    type="button"
                    className="btn btn-success btn-lg ms-2"
                    onClick={() => setModalOpen(false)}
                  >
                    Yes, delete it!
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-lg ms-2"
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default PartnerInvoice;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

import store from "../src/store/store.js";
import { ToastContainer } from "react-toastify";
// import store from './store'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <Provider store={stores}> */}

    <React.Fragment>
      <BrowserRouter>
        <App />
        <ToastContainer />
      </BrowserRouter>
    </React.Fragment>
    {/* </Provider> */}
  </Provider>,
);

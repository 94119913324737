import React from "react";
import CustomTable from "../../../customComponents/reactTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getCandidateInvitedJobs,
  getClientDashboardCount,
  getInvitedCandidates,
  getJobMatchingCandidates,
  postClientInviteMultipleCandidatesJob,
} from "../../ClientSlice";
import { useState } from "react";
import { Tags } from "../../../pages/Contacts/ContactList/contactlistCol";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { log_debug } from "../../../apiHelpers/logger";
import { FiInbox } from "react-icons/fi";
import Preloader from "../../../components/Common/Preloader";
import { toast, ToastContainer } from "react-toastify";
import CustomAvatar from "../../../customComponents/CustomAvatar/CustomAvatar";
import CanvasCandidate from "../../../customComponents/offcanvas/CanvasCandidate";
import dayjs from "dayjs";
import CandidateStatusBadge from "../../../customComponents/utilities/CandidateStatusBadge";
import truncateName from "../../../helpers/truncate_name";
import moment from "moment";

function MatchingCandidates({
  data,
  jobguid,
  RecordCount,
  currentPage,
  handlePageChange,
}) {
  const [CandidateData, setCandidateData] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  const candidatedetails = (data) => {
    setCandidateData(data);
    setOffcanvasOpen(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    log_debug("recieved table job guid", jobguid);
  }, [jobguid]);
  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidatedetails(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar fullName={row.values.full_name.charAt(0)} />
            <span style={{ marginTop: "5px" }}>
              {" "}
              {truncateName(row.values.full_name)}
            </span>
          </div>
        </Link>
      ),
      //   Cell: ({ row }) => (
      //     <Link
      //       to={"#"}
      //       onClick={() => {
      //         Job(row.original);
      //       }}
      //     >
      //       {row.values.title}
      //     </Link>
      //   ),
    },

    {
      Header: "Prefered Location",
      accessor: "prefered_locations",
      Cell: ({ value }) => <span>{value.join(", ")}</span>,
    },
    {
      Header: "Created on",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      // Cell: ({ row }) => (
      //   <Badge
      //     color={
      //       row.values.status === "shortlisted" ||
      //       row.values.status === "contracted"
      //         ? "success rounded-pill"
      //         : row.values.status === "applied" ||
      //             row.values.status === "on_bench"
      //           ? "warning rounded-pill"
      //           : "danger rounded-pill"
      //     }
      //   >
      //     {row.values.status}
      //   </Badge>
      // ),
      Cell: ({ value }) => <CandidateStatusBadge status={value} />,
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <>
          <Link to={"#"} className="text-white">
            {/* <Button
              color="primary"
              variant="primary"
              className=""
              id="applyMultipleJobButton"
              onClick={() => {
                candidateName2(row.original);
                
              }}
            >
              Apply
            </Button> */}
            <Button
              color="primary"
              className="px-1 "
              // color={appliedRows.includes(row.id) ? "success" : "primary"}
              // disabled={appliedRows.includes(row.id)}
              onClick={() => handleButtonClick(row)}
            >
              {/* <FiInbox /> */}
              <i className="mdi mdi-email-send mx-1 btn-lg"></i>
              Invite &nbsp;
              {/* {appliedRows.includes(row.id) ? "Applied" : "Apply"} */}
            </Button>
          </Link>
        </>
      ),
    },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  const handleButtonClick = (data) => {
    setloading(true);
    dispatch(
      postClientInviteMultipleCandidatesJob({
        formdata: {
          individual_guid: [data.original.guid],
          job_guids: jobguid,
        },
      }),
    ).then((response) => {
      if (response.payload?.success === true) {
        dispatch(getJobMatchingCandidates({ guid: jobguid, offset: 0 }));

        setTimeout(() => {
          dispatch(getInvitedCandidates({ guid: jobguid, offset: 0 }));
        }, 2000);
        setloading(false);

        dispatch(
          getClientDashboardCount({
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
          }),
        );

        const data = response.payload.data;
        if (data.length != 0) {
          toast.success("Successfully Invited the Candidate", {
            position: "top-right",
            autoClose: 4000, // Close the toast after 5 seconds
            // hideProgressBar: true,
          });
        }
      }
    });
  };

  return (
    <>
      {loading && <Preloader />}
      <CustomTable
        tableDatas={data}
        headerData={[]}
        columns={columns}
        data={data}
        multipleInvite={true}
        ClientJobGuid={jobguid}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalCount={RecordCount}
      />

      {isOffcanvasOpen && (
        <CanvasCandidate
          usertype="client"
          menuOption="overview"
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={CandidateData}
        />
      )}
    </>
  );
}

export default MatchingCandidates;

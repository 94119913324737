import React, { useState, useEffect } from "react";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = (error) => {
      console.error("Error caught by ErrorBoundary:", error);
      setHasError(true);
    };

    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []); // Run effect only once during component mount

  if (hasError) {
    return (
      <div>
        <p className="text-muted">
          {" "}
          Oops! Something unexpected occurred. Please refresh the page and try
          again. If the issue persists, our team is working to fix it. We
          apologize for any inconvenience.
        </p>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { log_debug } from "../../../apiHelpers/logger";
import { AuthContext } from "../../../App";
import CustomAvatar from "../../../customComponents/CustomAvatar/CustomAvatar";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const environment = window.location.hostname;
  const [menu, setMenu] = useState(false);

  const { userData } = useContext(AuthContext);
  const [username, setusername] = useState("");

  useEffect(() => {
    log_debug("context data", userData);
    setusername(userData.full_name);
  }, [userData]);

  const persona = userData.parent_type || localStorage.getItem("persona");

  const capitalized_persona =
    persona.charAt(0).toUpperCase() + persona.slice(1);

  // Define a logout function in your component
  const handleLogout = () => {
    // Remove items from localStorage
    localStorage.removeItem("userguid");
    localStorage.removeItem("userdata");
    localStorage.removeItem("lastVisitedUrl");
    localStorage.removeItem("guid");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("persona");

    // Redirect user to login page or perform any other action
    if (environment.includes("localhost")) {
      window.location.href = "http://localhost:5173/c/signin";
    } else if (environment.includes("app.companybench.in")) {
      window.location.href = "https://companybench.in/c/signin";
    } else if (environment.includes("app.companybench.com")) {
      window.location.href = "https://companybench.com/c/signin";
    } else if (environment.includes("cb-webapp-dev.netlify.app")) {
      window.location.href = "https://cb-website-dev.netlify.app/c/signin";
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <Row className="mt-1">
            <Col md={1} className="mt-2">
              <span className="">
                {/* <i className="bx bxs-user-circle fs-1 text-primary"></i> */}

                <CustomAvatar fullName={username} />
              </span>
            </Col>
            <Col md={username && username.length > 3 ? 10 : 8}>
              <span className="d-none d-xl-inline-block fw-bold mt-1 text-primary">
                {username}
                <br />
              </span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
              <p className="text-muted fw-bold font-size-12 m-0 text-start ms-3">
                {capitalized_persona === "Recruiting_Firm"
                  ? "Recruiting Firm"
                  : capitalized_persona}
              </p>
            </Col>
          </Row>

          {/* <span className="col rounded-circle header-profile-user bg-white text-dark">
          <i className="bx bxs-user-circle fs-3"></i>
          </span>
          <span className=" col d-none d-xl-inline-block ms-1 fw-medium mb-2">
            {username}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          
           */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile"> */}
          {/* <Link to={"/profile"} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
            {("Profile")}{" "}
          </Link>{" "}

          <Link to={"/contacts-profile"} className="dropdown-item">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {("Settings")}
          </Link>

          <Link to="/page-lock-screen" className="dropdown-item">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {("Lock screen")}
          </Link>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{("Logout")}</span>
          </Link> */}
          {(userData.parent_type === "individual" ||
            userData.parent_type === "partner_individual") && (
            <Link to={"/individual-profile"} className="dropdown-item">
              <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
              {"Profile"}{" "}
            </Link>
          )}{" "}
          {/* <Link to={"/individual-profile"} className="dropdown-item">
              <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
              {"Profile"}{" "}
            </Link> */}
          {userData.parent_type === "client" && (
            <Link to={"/client/profile"} className="dropdown-item">
              <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
              {"Profile"}{" "}
            </Link>
          )}{" "}
          {userData.parent_type === "partner" && (
            <Link to={"/partner-profile"} className="dropdown-item">
              <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
              {"Profile"}{" "}
            </Link>
          )}{" "}
          {userData.parent_type === "Recruiting_Firm" && (
            <Link to={"/partner-profile"} className="dropdown-item">
              <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
              {"Profile"}{" "}
            </Link>
          )}{" "}
          {/* <Link to={"#"} className="dropdown-item">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {"Settings"}
          </Link>
          <Link to="#" className="dropdown-item">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {"Lock screen"}
          </Link> */}
          <div className="dropdown-divider" />
          <Link to="#" onClick={handleLogout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{"Logout"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

// ProfileMenu.propTypes = {
//   success: PropTypes.any,
//   // t: PropTypes.any
// };

// const mapStatetoProps = state => {
//   const { error, success } = state.Profile;
//   return { error, success };
// };

export default ProfileMenu;

import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const CustomBreadcrumb = ({ values }) => {
  return (
    <Breadcrumb style={{ margin: "0px" }}>
      {values.map((value, index) => (
        <BreadcrumbItem key={index} active={index === values.length - 1}>
          {index === values.length - 1 ? (
            value
          ) : (
            <a href={`/${value.toLowerCase()}`}>{value}</a>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;

import React from "react";

function CustomLoading() {
  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center"
      style={{ height: "40vh", width: "100vw" }}
    >
      {/* You can replace the following spinner with your preferred loading indicator */}
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden text-center">Loading...</span>
      </div>
    </div>
  );
}

export default CustomLoading;

import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Badge,
} from "reactstrap";
import { FaRegQuestionCircle } from "react-icons/fa";

import classNames from "classnames";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../customComponents/reactTable/CustomTable";
import { IndividualDetail, ContractHistory, Contract } from "../PartnerSlice";
import CanvasCandidate from "../../customComponents/offcanvas/CanvasCandidate";
import TimelineComponent from "../../customComponents/timelineComponents/TimeLineComponent";

import OfferDetailsCanvas from "./OfferDetailsCanvas";
import { log_debug } from "../../apiHelpers/logger";
import joblogo from "../../assets/images/companyBenchImage/jobgray1.jpg";
import { comma_formatter } from "../../helpers/utils";
import ContractView from "../../customComponents/ContractView";
import Timesheetcomponent from "../../customComponents/timesheet/timesheet";

const ContractDetailCanvas = ({ isOpen, toggle, onClose, data, userType }) => {
  log_debug("usertype", userType);
  log_debug("ContractHistory", data);
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);

  const [headerTab, setHeaderTab] = useState("1");
  const [contractData, setContractData] = useState({});
  const [contractHistory, setContractHistory] = useState({});
  const [historyinfo, sethistoryinfo] = useState([]);
  const [individualDetail, setIndividualDetail] = useState({});
  const [offerDetail, setOfferDetail] = useState({});
  const [isIndividualCanvas, setIsIndividualCanvas] = useState(false);
  const [isOfferCanvas, setIsOfferCanvas] = useState(false);

  const [partnerContracts, setPartnerContracts] = useState([]);

  const getInitials = (fullName) => {
    return fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  };

  const handleIndividual = (e, indv) => {
    e.preventDefault();
    dispatch(IndividualDetail(indv.guid));
    setIsIndividualCanvas(true);
  };

  const handleOffer = (e, con) => {
    e.preventDefault();
    setOfferDetail(con);
    setIsOfferCanvas(true);
  };

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  const closeIndCanvas = () => {
    setIsIndividualCanvas(false);
  };

  const closeOfferCanvas = () => {
    setIsOfferCanvas(false);
  };

  function handleContractCanvas() {
    setIsIndividualCanvas(false);
    toggle();
  }

  useEffect(() => {
    dispatch(ContractHistory(data.contract_id));
  }, [data]);

  useEffect(() => {
    dispatch(
      Contract({
        currentPage: 0,
      }),
    );
  }, []);

  useEffect(() => {
    setContractData(data);
    return () => {};
  }, [data]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data?.IndividualDetail?.success &&
        partnerStore.data.IndividualDetail.data?.individual
      ) {
        setIndividualDetail(
          partnerStore.data.IndividualDetail.data?.individual,
        );
      }
      if (
        partnerStore.data?.ContractHistory?.success &&
        partnerStore.data.ContractHistory.data?.contract_history
      ) {
        let con_hist = partnerStore.data.ContractHistory.data?.contract_history;
        let contractHistArray = [];
        con_hist.map((history) => {
          let tempVar = {};
          (tempVar.status = history.status),
            (tempVar.createdAt = history.createdAt);
          contractHistArray.push(tempVar);
        });
        setContractHistory(
          partnerStore.data.ContractHistory.data?.contract_history,
        );
        // setIsIndividualCanvas(true);
      }
    }
  }, [partnerStore]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.ContractHistorydata?.success === true) {
        sethistoryinfo(
          partnerStore.data.ContractHistorydata?.data?.contract_history,
        );
      }
      if (partnerStore?.data?.Contract?.data?.contracts === true) {
        setPartnerContracts(partnerStore.data.Contract.data.contracts);
      }
    }
  }, [partnerStore]);

  const offcanvasStyle = {
    width: "65%", // You can set a default width or use the provided width
  };

  const columns = [
    {
      Header: "Contract",
      accessor: "contract_id",
      // Cell: ({ row }) => (
      //   <Link
      //     to={"#"}
      //     onClick={() => {
      //       handleContract(row.original);
      //     }}
      //   >
      //     {row.original?.contract_id?.split("-")[0].toUpperCase()}-
      //     {row.original?.contract_id?.split("-")[1].toUpperCase()}
      //   </Link>
      // ),
    },
    {
      Header: "Candidate",
      accessor: "individuals",
      // width: 300,
      // Cell: ({ value }) => (
      //   <div>
      //     {value &&
      //       value.map((candidate, index) => (
      //         <div key={index} style={{ display: "flex", alignItems: "top" }}>
      //           <CustomAvatar fullName={candidate.full_name} />
      //           <span>
      //             {candidate.full_name.length > 10
      //               ? `${candidate.full_name.slice(0, 12)}...`
      //               : candidate.full_name}
      //           </span>
      //         </div>
      //       ))}
      //   </div>
      // ),
    },
    // {
    //   Header: "Value",
    //   accessor: "contract_value",
    //   Cell: ({ value }) => (
    //     <div>
    //       <i className=" mdi mdi-currency-inr"></i>
    //       {comma_formatter(value)}
    //     </div>
    //   ),
    // },
    // {
    //   Header: "Billing Cycle",
    //   accessor: "billing_cycle",
    // },
    {
      Header: "Designation",
      accessor: "Designation",
    },
    // {
    //   Header: "Work Location",
    //   accessor: "Work_Location",
    // },
    {
      Header: "Start Date",
      accessor: "started_at",
      width: 100,
      Cell: (props) => {
        return <span>{dateyyyymmdd_tohtml(props?.value)}</span>;
      },
      // Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
      // Cell: ({ row }) => {row.original?.started_at ? (dayjs(row.original.started_at).format("DD-MM-YYYY")) : '-'}
    },
    {
      Header: "End Date",
      accessor: "end_date",
      width: 100,
      Cell: (props) => {
        return <span>{dateyyyymmdd_tohtml(props?.value)}</span>;
      },
      // Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),

      // Cell: ({ value }) => dayjs(value).format("DD-MM-YYYY")
    },
    {
      Header: "Status",
      accessor: "status",
      // Cell: ({ value }) => <OfferAcceptRejectBadge status={value} />,
    },
    {
      Header: "Action",
      accessor: "action",
      width: 100,
    },
  ];

  const consultant = {
    name: "John Doe",
    designation: "Software Engineer",
    workLocation: "Bangalore",
    term: "Contractor",
    startDate: "2024-03-01",
    endDate: "2024-06-01",
    rate: 50000,
    contractId: "CON123",
    billingCycle: "Monthly",
    status: "Active",
  };

  return (
    <>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={handleContractCanvas}
        toggle={handleContractCanvas}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom " toggle={toggle}>
          <h5 className="text-primary">
            Contract Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> Present comprehensive details
              for the specifically selected contract.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col xl={12}>
              <div>
                <div className="card p-3">
                  <div className="col-sm order-2 order-sm-1">
                    <div className="d-flex align-items-start mt-sm-0">
                      <div className="flex-shrink-0">
                        <div className="avatar-xl me-3">
                          <img
                            src={joblogo}
                            alt=""
                            className="img-fluid rounded-circle d-block "
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <div className="col-sm-auto order-1 order-sm-2">
                          <Badge
                            className={`p-1 me-2 text-white font-size-12 float-end ${
                              contractData?.status === "started"
                                ? "bg-warning"
                                : contractData?.status === "accepted"
                                  ? "bg-success"
                                  : contractData?.status === "rejected"
                                    ? "bg-danger"
                                    : "" // Default color if status is not recognized
                            }`}
                          >
                            {contractData?.status}
                          </Badge>
                        </div>
                        <div>
                          <h5 className="font-size-14 mb-2">
                            {contractData?.contract_id
                              ?.split("-")[0]
                              .toUpperCase()}
                            -
                            {contractData?.contract_id
                              ?.split("-")[1]
                              .toUpperCase()}
                          </h5>

                          <h5 className="text-muted font-size-13 mb-1">
                            <i className="mdi mdi-calendar me-1 text-primary align-middle"></i>{" "}
                            {dayjs(contractData?.started_at).format(
                              "YYYY-MM-DD",
                            )}
                          </h5>

                          {userType !== "partnerIndividual" && (
                            <h5 className="text-muted font-size-13 mb-1">
                              <i className=" mdi mdi-currency-inr me-1 text-primary align-middle"></i>
                              {comma_formatter(contractData?.contract_value)}
                            </h5>
                          )}

                          {userType !== "partnerIndividual" && (
                            <h5 className="text-muted font-size-13 mb-1">
                              <i className="mdi mdi-calendar-month-outline me-1 text-primary align-middle"></i>{" "}
                              {contractData?.billing_cycle}
                            </h5>
                          )}

                          <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14 mt-2">
                            <div>
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CardHeader className="align-items-center d-flex my-2">
                  {/* <h4 className="card-title mb-0 flex-grow-1">
                    Card Header Tabs
                  </h4> */}
                  <div className="flex-shrink-0">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classNames({
                            active: headerTab === "1",
                          })}
                          onClick={() => {
                            toggleHeader("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Contract details
                          </span>
                        </NavLink>
                      </NavItem>
                      {userType !== "partnerIndividual" && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classNames({
                              active: headerTab === "2",
                            })}
                            onClick={() => {
                              toggleHeader("2");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">Candidate</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {userType !== "partnerIndividual" && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classNames({
                              active: headerTab === "3",
                            })}
                            onClick={() => {
                              toggleHeader("3");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">Timesheet</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {userType !== "partnerIndividual" && (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classNames({
                              active: headerTab === "4",
                            })}
                            onClick={() => {
                              toggleHeader("4");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">History</span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </div>
                </CardHeader>

                <div className="card-body">
                  <TabContent className="text-muted" activeTab={headerTab}>
                    {/* <TabPane tabId="3">
                      <Card>
                        <CardBody>
                          <Row>
                            <div className="col-lg-12 col-md-12">
                              <div className="d-flex justify-content-between">
                                <h5 className="font-size-14">Client:</h5>
                                <p>{contractData?.company_name}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h5 className="font-size-14">Billing Cycle:</h5>
                                <p>{contractData?.billing_cycle}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h5 className="font-size-14">Start Date:</h5>
                                <p>
                                  {dayjs(contractData?.started_at).format(
                                    "YYYY-MM-DD",
                                  )}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h5 className="font-size-14">Field:</h5>
                                <p>-</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6"></div>
                          </Row>
                        </CardBody>
                      </Card>
                    </TabPane> */}
                    <TabPane tabId="1">
                      {/* <CustomTable
                           
                            columns={columns}
                            data={partnerContracts}
                            hideCheckbox={true}
                            // jobStatus={true}
                            // isLoading={contractdata}
                            // currentPage={currentPage}
                            // onPageChange={handlePageChange}
                          /> */}
                      <ContractView
                        consultant={data && data}
                        userType={userType}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Card>
                        <CardBody className="p-2">
                          {/* <h5 className="mb-3 card-title">Similar Profiles</h5> */}
                          <div className="list-group list-group-flush">
                            {contractData &&
                            contractData?.individuals?.length > 0 ? (
                              contractData?.individuals?.map((indv, index) => (
                                <div
                                  key={index}
                                  className="list-group-item list-group-item-action"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="avatar-xm mx-auto mb-0 me-3 mt-1">
                                      <span
                                        className="avatar-title bg-primary text-white font-size-24 p-3 display-3 m-0 rounded-circle"
                                        style={{
                                          width: "65px",
                                          height: "65px",
                                        }}
                                      >
                                        {getInitials(indv.full_name)}
                                      </span>
                                    </div>
                                    <div className="flex-grow-1 mt-0">
                                      <div className="d-flex justify-content-between mt-1">
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            handleIndividual(e, indv);
                                          }}
                                          className="font-size-14"
                                        >
                                          {indv.full_name}
                                        </Link>
                                        <span className="badge bg-success font-size-13 me-1">
                                          {indv.offer_status}
                                        </span>
                                      </div>
                                      <div className="mt-2">
                                        <h5 className="text-muted font-size-13 mb-0">
                                          <i className="mdi mdi-calendar me-1 text-primary align-middle"></i>{" "}
                                          {dayjs(indv?.offer_createdAt).format(
                                            "YYYY-MM-DD",
                                          )}
                                        </h5>
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            handleOffer(e, indv);
                                          }}
                                          className="font-size-12"
                                        >
                                          <i className="mdi mdi-briefcase-variant me-1 text-primary align-middle"></i>{" "}
                                          {indv.offer_guid
                                            .substring(0, 8)
                                            .toUpperCase()}
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>No Candidates found.</p>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                      {/* <div className="card">
                    <label htmlFor="choices-single-default" className="mb-2 card-title">Recommended Jobs</label>
                    <div className="list-group list-group-flush">
                        { contractData && contractData?.individuals.length > 0 && (
                          contractData.individuals.map((con, index) => (
                            <div key={index} className="list-group-item list-group-item-action bg-gradient p-2 rounded-2 shadow-md">
                              <div className="d-flex align-items-center">
                                <div className="avatar-xm mx-auto mb-0">
                                  <span className={"avatar-title bg-light-subtle text-light display-4 m-0 rounded-circle"}>
                                  <i className="bx bxs-user-circle"></i> </span>
                                </div>
                                <div className="flex-grow-1">
                                  <div>
                                    <h5 className="font-size-14 mb-1">{con.full_name}</h5>
                                    <p className="font-size-13 mb-0">{con.offer_status}</p>
                                  </div>
                                  <div className='d-flex justify-content-between'>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                          )}
                      </div>
                    </div> */}
                      {/* <CustomTable  /> */}
                    </TabPane>
                    <TabPane tabId="3">
                      <Timesheetcomponent
                        persona={"partner_admin"}
                        individualguid={data?.individuals[0]?.guid}
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <TimelineComponent historydata={historyinfo} />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
      {isIndividualCanvas && (
        <CanvasCandidate
          usertype={"partner"}
          placement="end"
          width={60}
          isOpen={isIndividualCanvas}
          toggle={() => closeIndCanvas()}
          onClose={closeIndCanvas}
          data={individualDetail}
        />
      )}
      {isOfferCanvas && (
        <OfferDetailsCanvas
          placement="end"
          isOpen={isOfferCanvas}
          toggle={() => setIsOfferCanvas(!isOfferCanvas)}
          onClose={closeOfferCanvas}
          data={offerDetail}
        />
      )}
    </>
  );
};

export default ContractDetailCanvas;

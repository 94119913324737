import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Container,
  Modal,
  ModalBody,
  CardHeader,
  Badge,
} from "reactstrap";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { log_debug } from "../apiHelpers/logger";
import dayjs from "dayjs";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import CustomTable from "../customComponents/reactTable/CustomTable.js";
import {
  Contract,
  addCandidatesSkills,
  addCandidatesLocation,
  getFilterContract,
} from "./PartnerSlice.js";
import "./partner.css";
import ContractDetailCanvas from "./pagesComponents/ContractDetailCanvas";
import NodataFoundTable from "../customComponents/utilities/NodataFoundTable.js";
import { dateyyyymmdd_tohtml } from "../helpers/date.js";
import { comma_formatter } from "../helpers/utils.js";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar.js";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb.js";
import OfferAcceptRejectBadge from "../customComponents/utilities/OfferAcceptRejectBadge.js";

const PartnerContract = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const partnerStore = useSelector((state) => state.partnerSlice);
  const [contractdata, setcontractdata] = useState(false);
  // const [AddCandidateSkills, setAddCandidateSkills] = useState([]);
  // const [AddCandidateLocation, setAddCandidateLocation] = useState([]);
  const [partnerContracts, setPartnerContracts] = useState([]);
  const [AllContractcount, setAllContractcount] = useState([]);
  const [filtervalue, setFiltervalue] = useState("");
  const count = partnerContracts.length;

  const [contractinfo, setContractinfo] = useState({});

  const handleContract = (row) => {
    setContractinfo(row);
    setIsOpen(true);
  };

  const closeOffcanvas = () => {
    setIsOpen(false);
  };

  // useEffect(() => {
  //   // dispatch(addCandidatesSkills());
  //   // dispatch(addCandidatesLocation());
  //   dispatch(Contract());
  // }, []);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data?.Contract?.success === true) {
        setcontractdata(false);
        setPartnerContracts(partnerStore.data.Contract?.data?.contracts);
        setAllContractcount(partnerStore.data.Contract?.data?.totalCount);
      } else if (partnerStore.data?.Contract?.success === false) {
        setcontractdata(false);
        setPartnerContracts([]);
        setAllContractcount(0);
      }
      // if (
      //   partnerStore.data.CandidatesSkills &&
      //   partnerStore.data.CandidatesSkills.data &&
      //   partnerStore.data.CandidatesSkills.data.enumskills &&
      //   partnerStore.data.CandidatesSkills.success &&
      //   partnerStore.data.CandidatesSkills.success === true
      // ) {
      //   setAddCandidateSkills(
      //     partnerStore.data.CandidatesSkills.data.enumskills,
      //   );
      // }
      // if (
      //   partnerStore.data.CandidatesLocation &&
      //   partnerStore.data.CandidatesLocation.data &&
      //   partnerStore.data.CandidatesLocation.data.enumlocations &&
      //   partnerStore.data.CandidatesLocation.success &&
      //   partnerStore.data.CandidatesLocation.success === true
      // ) {
      //   setAddCandidateLocation(
      //     partnerStore.data.CandidatesLocation.data.enumlocations,
      //   );
      // }
    } else {
      setcontractdata(true);
    }
  }, [partnerStore.data?.Contract]);

  // const skillOptions = AddCandidateSkills.map((item) => ({
  //   value: item.code,
  //   label: item.title,
  // }));

  // const locationOptions = AddCandidateLocation.map((item) => ({
  //   value: item.code,
  //   label: item.title,
  // }));

  const columns = [
    {
      Header: "Contract ID",
      accessor: "contract_id",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleContract(row.original);
          }}
        >
          {row.original?.contract_id?.split("-")[0].toUpperCase()}-
          {row.original?.contract_id?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    {
      Header: "Candidate",
      accessor: "individuals",
      width: 300,
      Cell: ({ value }) => (
        <div>
          {value &&
            value.map((candidate, index) => (
              <div key={index} style={{ display: "flex", alignItems: "top" }}>
                <CustomAvatar fullName={candidate.full_name} />
                <span>
                  {candidate.full_name.length > 10
                    ? `${candidate.full_name.slice(0, 12)}...`
                    : candidate.full_name}
                </span>
              </div>
            ))}
        </div>
      ),
    },
    // {
    //   Header: "Value",
    //   accessor: "contract_value",
    //   Cell: ({ value }) => (
    //     <div>
    //       <i className=" mdi mdi-currency-inr"></i>
    //       {comma_formatter(value)}
    //     </div>
    //   ),
    // },
    // {
    //   Header: "Billing Cycle",
    //   accessor: "billing_cycle",
    // },
    {
      Header: "Location",
      accessor: "work_location",
      Cell: ({ row }) => (
        <div className="">
          {row.original.work_location.length >= 1
            ? row.original.work_location.join(", ")
            : "-"}
        </div>
      ),
    },
    // {
    //   Header: "Work Location",
    //   accessor: "Work_Location",
    // },
    {
      Header: "Start Date",
      accessor: "started_at",
      width: 100,
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
      // Cell: ({ row }) => {row.original?.started_at ? (dayjs(row.original.started_at).format("DD-MM-YYYY")) : '-'}
    },
    {
      Header: "End Date",
      accessor: "end_date",
      width: 100,
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),

      // Cell: ({ value }) => dayjs(value).format("DD-MM-YYYY")
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <OfferAcceptRejectBadge status={value} />,
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   width: 100,

    //   Cell: ({ row }) => (
    //     <>
    //       <div className="align-items-start">
    //         <Link
    //           to={"#"}
    //           color="primary"
    //           type="button"
    //           className=" btn btn-primary text-white rounded"
    //           // onClick={(e) => {
    //           //   handleAction(e, row.original);
    //           // }}
    //         >
    //           <FiEdit className="mx-1" />
    //           {/* Edit */}
    //         </Link>
    //         <Link
    //           to={"#"}
    //           color="danger"
    //           type="button"
    //           className=" btn btn-danger mx-1 text-white rounded"
    //           onClick={(e) => {
    //             toggleModal(e, row.original.id);
    //           }}
    //         >
    //           <FiTrash2 className="mx-1" />
    //           {/* Edit */}
    //         </Link>
    //       </div>
    //     </>
    //   ),
    // },
  ];
  const toggleModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        Contract({
          currentPage: currentPage ? currentPage : currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [currentPage, filtervalue]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setcontractdata(true);
    setFiltervalue(newValue);
  };

  const breadcrumbValues = ["Contract"];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="row col-lg-12">
            <div className="col-lg-12">
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <Row>
                        <div className="col-12 col-lg-6 col-md-6 d-sm-flex">
                          <div className="col-12 col-lg-6">
                            {/* <DropdownWithTwoField
                                // label="Option 1"
                                options={skillOptions}
                                placeholder="Select Technology"
                              /> */}
                            <div className="col-lg-12">
                              <CustomBreadcrumb values={breadcrumbValues} />
                            </div>
                          </div>{" "}
                          &nbsp;
                          {/* <div className="col-12 col-lg-6">
                              <DropdownWithTwoField
                                options={locationOptions}
                                placeholder="Select Locations"
                              />
                            </div> */}
                        </div>

                        <div className="col-12 col-lg-6 col-md-6">
                          <Col md={12}>
                            <CardTitle className="page-title text-muted">
                              <span>Total count : </span>
                              <strong>
                                {AllContractcount ? AllContractcount : 0}
                              </strong>
                            </CardTitle>
                          </Col>
                        </div>
                      </Row>
                    </div>
                    <div>
                      <Card>
                        <CardBody>
                          <CustomTable
                            // tableDatas={MyCandidateList}
                            // headerData={yourDynamicHeaderData}
                            addcontract={true}
                            totalCount={AllContractcount}
                            columns={columns}
                            data={partnerContracts}
                            // jobStatus={true}
                            hideCheckbox={true}
                            isLoading={contractdata}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            globalsearch={true}
                            onChange={handleValueChange}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </Container>
      </div>
      {isOpen && (
        <ContractDetailCanvas
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          onClose={closeOffcanvas}
          data={contractinfo}
        />
      )}
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          style={{ maxWidth: "30%" }}
        >
          <ModalBody className="py-3 px-5">
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <i
                    className="mdi mdi-alert-circle-outline"
                    style={{ fontSize: "9em", color: "orange" }}
                  />
                  <h2>Are you sure?</h2>
                  <h4>{"You won't be able to revert this!"}</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center mt-3">
                  <button
                    type="button"
                    className="btn btn-success btn-lg ms-2"
                    onClick={() => setModalOpen(false)}
                  >
                    Yes, delete it!
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-lg ms-2"
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default PartnerContract;

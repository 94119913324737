import React, { useState, useEffect } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DetailsCanvas from "../customComponents/offcanvas/CanvasJobdescription";
import { toast, ToastContainer } from "react-toastify";

import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Button,
  Badge,
} from "reactstrap";

import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
// import DataTable from "../customComponents/DataTable";
// import { lineStyle, dropdownOptions } from "../data/Data";
// import DropDownHeader from "../customComponents/DropDownHeader";
import { useSelector, useDispatch } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";

import {
  machingJob,
  addCandidatesSkills,
  addCandidatesLocation,
  machingJobApplyJob,
  JobHistorydata,
  getFilterCandididates,
  getFilterMatchingJobs,
} from "./PartnerSlice";
import CustomTable from "../customComponents/reactTable/CustomTable";
import dayjs from "dayjs";
import "../../src/assets/css/styleCompanyBench.css";
import { Link } from "react-router-dom";
import { log_debug } from "../apiHelpers/logger";
import CanvasPartnerJobDetails from "../customComponents/offcanvas/CanvasPartnerJobDetails";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import NodataFoundTable from "../customComponents/utilities/NodataFoundTable";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import JobsBadge from "../customComponents/utilities/JobsBadge";

function PartnerMatchingJob() {
  //pagination
  const [currentPage, setCurrentPage] = useState(0);

  const [MatchingJobList, setMatchingjobTabledata] = useState([]);
  const [setAllmatchcount, setsetAllmatchcount] = useState([]);
  const count = MatchingJobList && MatchingJobList?.length;
  const [Matchingjobsloading, setMatchingjobsloading] = useState(true);
  log_debug("Matchingjobsloading", Matchingjobsloading);
  const [AddCandidateSkills, setAddCandidateSkills] = useState([]);
  const [AddCandidateLocation, setAddCandidateLocation] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  log_debug("selectedCandidate", selectedCandidate);

  const [selectedTechnology, setSelectedTechnology] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [filtervalue, setFiltervalue] = useState("");

  const jobDescriptions = MatchingJobList.map(
    (job) => job.matching_job_descriptions,
  );

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.machingJobRecords?.success === true) {
        setMatchingjobsloading(false);
        setMatchingjobTabledata(
          partnerStore.data.machingJobRecords?.data?.matchingJobs,
        );
        setsetAllmatchcount(
          partnerStore.data.machingJobRecords?.data?.totalCount,
        );
      } else if (partnerStore.data.machingJobRecords?.success === false) {
        setMatchingjobsloading(false);
        setMatchingjobTabledata([]);
        setsetAllmatchcount(0);
      }

      if (partnerStore.data.CandidatesSkills?.success === true) {
        setAddCandidateSkills(
          partnerStore.data.CandidatesSkills?.data?.enumskills,
        );
      }
      if (partnerStore.data.CandidatesLocation?.success === true) {
        setAddCandidateLocation(
          partnerStore.data.CandidatesLocation?.data?.enumcitys,
        );
      }
    }
  }, [
    partnerStore.data.machingJobRecords,
    partnerStore.data.CandidatesSkills,
    partnerStore.data.CandidatesLocation,
  ]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data.CandidatesSkills?.success === true &&
        partnerStore.data.CandidatesLocation?.success === true
      ) {
        setAddCandidateSkills(
          partnerStore.data.CandidatesSkills?.data?.enumskills,
        );
        setAddCandidateLocation(
          partnerStore.data.CandidatesLocation?.data?.enumcitys,
        );
      } else {
        dispatch(addCandidatesSkills());
        dispatch(addCandidatesLocation());
      }
    }
    dispatch(addCandidatesLocation());
  }, []);

  const skillOptions = AddCandidateSkills?.map((item) => ({
    value: item?.code,
    label: item?.title,
  }));
  log_debug("skillOptions", skillOptions);

  const locationOptions = AddCandidateLocation?.map((item) => ({
    value: item?.code,
    label: item?.title,
  }));

  const columns = [
    // {
    //   Header: 'ID',
    //   accessor: 'guid',
    // },
    {
      Header: "Job Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateName(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },

    // {
    //   Header: "Date",
    //   accessor: "updatedAt",
    //   Cell: (props) => {
    //     const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
    //     return <span>{tidyDate}</span>;
    //   },
    // },
    {
      Header: "Skills",
      accessor: "skills",
      // Cell: ({ value }) => value.join(", "),
      // Cell: ({ value }) => (
      //   <div>
      //     {/* <div style={{ whiteSpace: "normal", height: "auto" }}> */}
      //     {value.map((skill, index) => (
      //       <Badge
      //         key={index}
      //         color="primary"
      //         className="bg-primary text-white m-1"
      //       >
      //         {skill}
      //       </Badge>
      //     ))}
      //   </div>
      // ),
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ row }) => (
        <div className="">
          {row.original.location?.length >= 1
            ? row.original.location.join(", ")
            : "-"}
        </div>
      ),
    },
    {
      Header: "Created Date",
      accessor: "createdAt ",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <JobsBadge status={value} />,
    },

    // Add more columns as needed
  ];

  const candidateName = (guid) => {
    setSelectedCandidate(guid);
    setOffcanvasOpen(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
    // setisOffcanvasOpenBGV(false);
  };
  const handleTechnologyChange = (selectedOptions) => {
    const selectedValues =
      selectedOptions && selectedOptions?.map((option) => option?.label);
    setSelectedTechnology(selectedValues);
  };

  const handleLocationChange = (selectedOptions) => {
    const selectedValues =
      selectedOptions && selectedOptions?.map((option) => option?.label);
    setSelectedLocations(selectedValues);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        machingJob({
          skills: selectedTechnology ? selectedTechnology : "",
          locations: selectedLocations ? selectedLocations : "",
          currentPage: currentPage ? currentPage : currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [selectedTechnology, selectedLocations, currentPage, filtervalue]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setMatchingjobsloading(true);
    setFiltervalue(newValue);
  };

  const breadcrumbValues = ["Matching Job"];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CustomBreadcrumb values={breadcrumbValues} />

          <div className="col-lg-12">
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    {/* <Card
                    style={{
                      backgroundColor: "#5156BE",
                      paddingTop: "15px",
                    }}
                  >
                    <CardBody> */}
                    <Row>
                      <div className="col-12 col-lg-6 col-md-6 d-sm-flex">
                        <div className="col-12 col-lg-6">
                          <DropdownWithTwoField
                            // label="Option 1"
                            options={skillOptions}
                            placeholder="Select Technololy"
                            onSelectChange={handleTechnologyChange}
                          />
                        </div>{" "}
                        &nbsp;
                        <div className="col-12 col-lg-6">
                          <DropdownWithTwoField
                            options={locationOptions}
                            placeholder="Select locations"
                            onSelectChange={handleLocationChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 col-md-6">
                        <Col md={12}>
                          <CardTitle className="page-title text-muted">
                            <span>Total count : </span>
                            <strong>
                              {setAllmatchcount ? setAllmatchcount : 0}
                            </strong>
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                    {/* </CardBody> */}
                    {/* </Card> */}
                  </div>
                  <div>
                    <Card>
                      <CardBody>
                        <CustomTable
                          totalCount={setAllmatchcount && setAllmatchcount}
                          columns={columns}
                          data={MatchingJobList}
                          hideCheckbox={true}
                          // jobStatus={true}
                          isLoading={Matchingjobsloading}
                          currentPage={currentPage}
                          onPageChange={handlePageChange}
                          onChange={handleValueChange}
                          globalsearch={true}
                        />
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <CanvasPartnerJobDetails
          Matchingstatus={true}
          width={50}
          placement="end"
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onclose={closeOffcanvas}
          data={selectedCandidate}
        />
      )}
    </React.Fragment>
  );
}

export default PartnerMatchingJob;

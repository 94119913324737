import React, { useEffect } from "react"; // Make sure to import React
import { log_debug } from "../../apiHelpers/logger";
import { useNavigate } from "react-router-dom";
import Preloader from "../../components/Common/Preloader";

const Authmiddleware = ({
  children,
  persona,
  isAuthenticated,
  lastVisitedUrl,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    log_debug("middleware authenticated status", isAuthenticated);
    log_debug("middleware persona", persona);
    log_debug("middleware url", lastVisitedUrl);
    if (isAuthenticated) {
      if (lastVisitedUrl && lastVisitedUrl != "/") {
        //log_debug("middleware url",lastVisitedUrl)
        navigate(lastVisitedUrl);
      } else {
        if (persona === "individual") {
          navigate("/individualdashboard");
        } else if (persona === "partner") {
          navigate("/dashboard");
        } else if (persona === "client") {
          navigate("/client/dashboard");
        } else if (persona === "partner_individual") {
          navigate("/partner/individual/contracts");
        } else if (persona === "Recruiting_Firm") {
          navigate("/recruitee-dashboard");
        }else if(persona === "cb_accounts") {
          navigate("/accounts/dashboard");
        }
      }
    } else {
      <Preloader />;
    }
  }, [persona, isAuthenticated]);

  return <>{children}</>;
};

export default Authmiddleware;

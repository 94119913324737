export const or_get_ist = function () {
  var current_time = new Date();
  var current_offset = current_time.getTimezoneOffset();
  var ist_offset = 330;
  var ist_time = new Date(
    current_time.getTime() + (ist_offset + current_offset) * 60000,
  );
  return (
    ist_time.getHours().toString() + ":" + ist_time.getMinutes().toString()
  );
};

import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

//import images
import img1 from "../../../assets/images/small/img-3.jpg";
import img2 from "../../../assets/images/small/img-1.jpg";
import img3 from "../../../assets/images/small/img-5.jpg";

const ProfileTab1 = () => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="card-title text-start mb-0 col-xl-11 col-md-10">
              Overview
            </h5>
            <Link className="text-end col-xl-1 col-md-2 btn btn-outline-primary w-auto p-1">
              <i className="bx bx-list-plus mx-1" />
              Add
            </Link>
          </div>
        </CardHeader>
        <CardBody>
          <div>
            <div className="pb-3">
              <Row>
                <Col xl={2}>
                  <div>
                    <h5 className="font-size-15">Summary :</h5>
                  </div>
                </Col>
                <div className="col-xl-8">
                  <div className="text-muted">
                    <p className="mb-2">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at it has a more-or-less normal distribution of letters
                    </p>
                  </div>
                </div>
                <Col xl={2} className="text-end">
                  <Link>
                    <i className="fas fa-edit" />
                  </Link>
                </Col>
              </Row>
            </div>

            <div className="py-3">
              <Row>
                <Col xl={2}>
                  <div>
                    <h5 className="font-size-15">Skills :</h5>
                  </div>
                </Col>
                <div className="col-xl-8">
                  <div className="d-flex flex-wrap gap-2 font-size-16">
                    <Link
                      to="#"
                      className="badge bg-primary-subtle text-primary"
                    >
                      Photoshop
                    </Link>
                    <Link
                      to="#"
                      className="badge bg-primary-subtle text-primary"
                    >
                      illustrator
                    </Link>
                    <Link
                      to="#"
                      className="badge bg-primary-subtle text-primary"
                    >
                      HTML
                    </Link>
                    <Link
                      to="#"
                      className="badge bg-primary-subtle text-primary"
                    >
                      CSS
                    </Link>
                    <Link
                      to="#"
                      className="badge bg-primary-subtle text-primary"
                    >
                      Javascript
                    </Link>
                    <Link
                      to="#"
                      className="badge bg-primary-subtle text-primary"
                    >
                      Php
                    </Link>
                    <Link
                      to="#"
                      className="badge bg-primary-subtle text-primary"
                    >
                      Python
                    </Link>
                  </div>
                </div>
              </Row>
            </div>

            <div className="py-3">
              <Row>
                <Col xl={2}>
                  <div>
                    <h5 className="font-size-15">Experience :</h5>
                  </div>
                </Col>
                <div className="col-xl-8">
                  <div className="text-muted">
                    <ul className="list-unstyled mb-0">
                      <li className="">
                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                        Donec vitae sapien ut libero venenatis faucibus
                      </li>
                      <li className="py-1">
                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                        Quisque rutrum aenean imperdiet
                      </li>
                      <li className="py-1">
                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                        Integer ante a consectetuer eget
                      </li>
                      <li className="py-1">
                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                        Phasellus nec sem in justo pellentesque
                      </li>
                    </ul>
                  </div>
                </div>
                <Col xl={2} className="text-end">
                  <Link>
                    <i className="fas fa-edit" />
                  </Link>
                </Col>
              </Row>
            </div>

            <div className="py-3">
              <Row>
                <Col xl={2}>
                  <div>
                    <h5 className="font-size-15">Education Qualification :</h5>
                  </div>
                </Col>
                <div className="col-xl-8">
                  <div className="text-muted">
                    <ul className="list-unstyled mb-0">
                      <li className="">
                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                        Donec vitae sapien ut libero venenatis faucibus
                      </li>
                      <li className="py-1">
                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                        Quisque rutrum aenean imperdiet
                      </li>
                      <li className="py-1">
                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                        Integer ante a consectetuer eget
                      </li>
                      <li className="py-1">
                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                        Phasellus nec sem in justo pellentesque
                      </li>
                    </ul>
                  </div>
                </div>
                <Col xl={2} className="text-end">
                  <Link>
                    <i className="fas fa-edit" />
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ProfileTab1;

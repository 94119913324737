// import { common } from "../config/dev";

export const is_json = function (str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const is_equivalent = function (a, b) {
  a = a || {};
  b = b || {};
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const or_get_ist = function () {
  var current_time = new Date();
  var current_offset = current_time.getTimezoneOffset();
  var ist_offset = 330;
  var ist_time = new Date(
    current_time.getTime() + (ist_offset + current_offset) * 60000,
  );
  return (
    ist_time.getHours().toString() + ":" + ist_time.getMinutes().toString()
  );
};

// export const or_guid = function () {
//   return common.guid_format.replace(/[xy]/g, function (c) {
//     var r = (Math.random() * 16) | 0,
//       v = c == "x" ? r : (r & 0x3) | 0x8;
//     return v.toString(16);
//   });
// };

// export const or_guid_short = function () {
//   return common.short_guid_format.replace(/[xy]/g, function (c) {
//     var r = (Math.random() * 16) | 0,
//       v = c == "x" ? r : (r & 0x3) | 0x8;
//     return v.toString(16);
//   });
// };

export const or_unique = (arr) => {
  var u = {},
    a = [];
  for (var i = 0, l = arr.length; i < l; ++i) {
    if (!u.hasOwnProperty(arr[i])) {
      a.push(arr[i]);
      u[arr[i]] = 1;
    }
  }
  return a;
};

export const n_formatter = function (num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const comma_formatter = (num) => {
  return (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const get_url_parameter = function getUrlParameter(url, sParam) {
  var sPageURL = url.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
};

// search_url = window.location.search
export const get_url_parameter_from_search_location = (search_url, param) => {
  const urlParams = new URLSearchParams(search_url);
  return urlParams.get(param);
};

export const arr_diff = function (a1, a2) {
  var a = [],
    diff = [];
  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }
  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }
  for (var k in a) {
    diff.push(k);
  }
  return diff;
};

// Insert or Update an array based on unique guid in the Object
export const list_upsert = function (array, element) {
  const i = array.findIndex((_element) => _element.guid === element.guid);
  if (i > -1) array[i] = element;
  else array.push(element);
};

export const csv_to_json = function (csv) {
  var lines = csv.split("\n");
  var result = [];

  // NOTE: If your columns contain commas in their values, you'll need
  // to deal with those before doing the next step
  // (you might convert them to &&& or something, then covert them back later)
  // jsfiddle showing the issue https://jsfiddle.net/
  var headers = lines[0].split(",");
  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(",");
    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }
    result.push(obj);
  }
  return result; //JSON
};

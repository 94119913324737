import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = (props) => {
  const persona = localStorage.getItem("persona");
  return (
    <Row>
      <Col xs="6">
        <div className="page-title-box align-items-center ">
          <div className="page-title-left">
            <ol className="breadcrumb mt-2">
              <BreadcrumbItem>
                <Link
                  to={
                    persona === "individual"
                      ? "/individualdashboard"
                      : persona === "partner"
                        ? "/dashboard"
                        : persona === "client"
                          ? "/client/dashboard"
                          : "#"
                  }
                >
                  {props.title}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
            </ol>
          </div>
          {/* <h4 className="mb-0 text-primary font-size-18">{props.Name}</h4> */}
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;

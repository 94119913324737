// import { Form } from 'formik'
import React, { useState, useEffect, useContext } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DropdownWithTwoField from "../../customComponents/DropdownWithTwoField";
import { useDispatch, useSelector } from "react-redux";
import {
  ClientAllJobs,
  getLocations,
  getSkills,
  postJob,
  postJobDescription,
} from "../ClientSlice";
import { log_debug } from "../../apiHelpers/logger";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../App";
import Preloader from "../../components/Common/Preloader";
import { getClientGuid } from "../../apiConfig/globalvariables";
import { ClientGuid } from "../../apiConfig/axiosDev";

function ClientPostJobForm() {
  const [enablesubmit, setenablesubmit] = useState(false);
  const clientstore = useSelector((state) => state.clientSlice);
  const dispatch = useDispatch();
  const [Locations, setLocations] = useState([]);
  const [Skills, setSkills] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [jobdescription, setjobdescription] = useState([]);
  const [responsibilities, setresponsibilities] = useState([]);
  const [summary, setsummary] = useState(null);
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disableGenerate, setdisableGenerate] = useState(false);
  const [description,setdescription] = useState("");

  const handlegenerate = (e) => {
    if(description === ""){
      toast.error("Please fill all the details");
    }else{
    setdisableGenerate(true);
    e.preventDefault();
    const formdata = {
      title: validation.values.title,
      location: selectedLocation,
      skills: selectedSkills,
    };
    log_debug("form data", formdata);
    dispatch(postJobDescription(JSON.stringify(formdata))).then((response) => {
      if (response.payload.success === true) {
        setenablesubmit(true);
        setHidden(true);
      } else if (response.payload.success === false) {
        toast.error(response.payload?.error?.error);
        setdisableGenerate(false);
      }
    });
  }
  };

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getSkills());
  }, []);

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.locations?.success === true) {
        setLocations(clientstore.data.locations?.data?.enumcitys);
      }
      if (clientstore.data.skills?.success === true) {
        setSkills(clientstore.data.skills?.data?.enumskills);
      }
      if (clientstore.data.jobdescription?.success === true) {
        setjobdescription(
          clientstore.data.jobdescription?.data?.jobdescription,
        );
      }
      setloading(false);
    } else if (clientstore.status === "loading") {
      setloading(true);
    }
  }, [clientstore]);

  useEffect(() => {
    setresponsibilities(jobdescription.responsibilities);
    setsummary(jobdescription.summary);
  }, [jobdescription]);

  const locationOptions = Locations.map((item) => ({
    value: item.code,
    label: item.title,
  }));

  const skillOptions = Skills.map((item) => ({
    value: item.code,
    label: item.title,
  }));

  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedSkills, setSelectedSkills] = useState("");

  const handleLocationChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.label);
    log_debug("selected location", selectedOptions);
    setSelectedLocation(selectedValues);
  };

  const handleSkillChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.label);

    setSelectedSkills(selectedValues);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Job Title"),
    }),
  });

  const [updateresponsibilities, setResponsibilities] = useState([]);

  const [newResponsibility, setNewResponsibility] = useState("");
  useEffect(() => {
    if (responsibilities) {
      setResponsibilities(responsibilities);
    }
  }, [responsibilities]);

  const handleDelete = (index) => {
    const updatedResponsibilities = [...updateresponsibilities];
    updatedResponsibilities.splice(index, 1);
    setResponsibilities(updatedResponsibilities);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    if (newResponsibility.trim() !== "") {
      const updatedResponsibilities = [
        ...updateresponsibilities,
        newResponsibility,
      ];
      setResponsibilities(updatedResponsibilities);
      setNewResponsibility("");
    }
  };

  const handleInputChange = (index, value) => {
    const updatedResponsibilities = [...updateresponsibilities];
    updatedResponsibilities[index] = value;
    setResponsibilities(updatedResponsibilities);
  };

  const handleSummaryChange = (e) => {
    setsummary(e.target.value);
  }

  const handleDescriptionChange = (e) => {
    setdescription(e.target.value);
  }

  const handlesubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const jobdescription = {
      jobdescription: {
        client_guid: getClientGuid() || ClientGuid,
        description: description,
        title: validation.values.title,
        skills: selectedSkills,
        location: selectedLocation,
        requirements: updateresponsibilities,
        summary: summary,
      },
    };
    dispatch(postJob(jobdescription)).then((response) => {
      if (response) {
        log_debug("response", response.payload.success);
        if (response.payload.success === true) {
          toast.success(`Job Posted successfully. Redirecting to your jobs`, {
            position: "top-right",
            background: "green",
            autoClose: 2000, //Close the toast after 2 seconds
            hideProgressBar: true,
          });
          dispatch(
            ClientAllJobs({
              limit: 100,
              offset: 0,
            }),
          );
          setTimeout(() => {
            navigate("/client/jobs");
          }, 3000);
        } else if (response.payload.success === false) {
          setDisabled(false);
          toast.error(`Unable to post the Job`, {
            position: "top-right",
            background: "red",
            autoClose: 2000, //Close the toast after 2 seconds
            hideProgressBar: true,
          });
        }
      }
    });
  };

  return (
    <>
      <Form className="needs-validation">
        <Row>
          {loading && <Preloader />}
          <Col lg={12}>
            <FormGroup>
              <Label htmlFor="title">
                {/* <i className="fas fa-graduation-cap mx-1" /> */}
                Job Title
              </Label>
              <Input
                name="title"
                id="title"
                placeholder="Job Title"
                type="text"
                className="form-control mb-3"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.title && validation.errors.title
                    ? true
                    : false
                }
                required
              />
              {validation.touched.title && validation.errors.title ? (
                <FormFeedback type="invalid">
                  {validation.errors.title}
                </FormFeedback>
              ) : null}
              <Label htmlFor="location">
                {/* <i className="fas fa-map-pin mx-1" /> */}
                Location
              </Label>
              <DropdownWithTwoField
                options={locationOptions}
                placeholder="Select Location"
                onSelectChange={handleLocationChange}
              />

              <Label htmlFor="skills">
                {/* <i className="fas fa-brain mx-1" /> */}
                Skills
              </Label>
              <DropdownWithTwoField
                options={skillOptions}
                placeholder="Select Skills"
                onSelectChange={handleSkillChange}
              />

              <Label htmlFor="description">
                {/* <i className="fas fa-map-pin mx-1" /> */}
                Description
              </Label>
              <textarea
              className="form-control"
              type="text"
              placeholder="Please enter Job Description"
              onChange={handleDescriptionChange}
              required
              />

              {hidden && (
                <>
                  <Label htmlFor="responsibilities">
                    {/* <i className="fas fa-clock mx-1" /> */}
                    Responsibilities
                  </Label>
                  {updateresponsibilities.map((responsibility, index) => (
                    <div key={index} className="d-flex mb-1">
                      <Input
                        className="form-control"
                        value={responsibility}
                        type="text"
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                      <span
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                        onClick={() => handleDelete(index)}
                      >
                        &#10006; {/* This is the delete icon (×) */}
                      </span>
                    </div>
                  ))}
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control mb-3"
                      value={newResponsibility}
                      onChange={(e) => setNewResponsibility(e.target.value)}
                      placeholder="Add responsibility"
                    />
                    <Link
                      onClick={handleAdd}
                      style={{ height: "35px" }}
                      className="btn btn-primary mx-2"
                    >
                      Add
                    </Link>
                  </div>

                  <Label htmlFor="summary">
                    {/* <i className="fas fa-clock mx-1" /> */}
                    Summary
                  </Label>
                  <textarea
                    name="summary"
                    id="summary"
                    type="text"
                    className="form-control mb-3"
                    style={{ height: "150px" }}
                    value={summary}
                    onChange={handleSummaryChange}
                  />
                </>
              )}
            </FormGroup>
          </Col>
          {/* <Col lg={6} className='border  p-3 m-auto'>
            <Col lg={12} className='d-flex '>

                <FormGroup className='w-100 mx-1'>
                    <Label htmlFor='budget'><i className=' fas fa-dollar-sign mx-1'/>Monthly Budget</Label>
                    <Input
                    name='budget'
                    id='budget'
                    placeholder='Monthly Budget'
                    type='number'
                    min={0}
                    className='form-control'
                    />
                </FormGroup>
                <FormGroup className='w-100 mx-1'>
                    <Label htmlFor='budget'><i className='fas fa-star mx-1'/>Minimum Experience</Label>
                    <Input
                    name='budget'
                    id='budget'
                    placeholder='Minimum Experience(in years)'
                    type='number'
                    min={0}
                    className='form-control'
                    />
                </FormGroup>
            </Col>
            <Col lg={12} className='d-flex '>

                <FormGroup className='w-100 mx-1'>
                    <Label htmlFor='budget'><i className='fas fa-calendar-alt mx-1'/>Project Start Date</Label>
                    <Input
                    name='budget'
                    id='budget'
                    placeholder='Monthly Budget'
                    type='date'
                    className='form-control'
                    />
                </FormGroup>
                <FormGroup className='w-100 mx-1'>
                    <Label htmlFor='budget'>Duration in Months</Label>
                    <Input
                    name='budget'
                    id='budget'
                    placeholder='Project duration in Months'
                    type='number'
                    min={0}
                    className='form-control'
                    />
                </FormGroup>
            </Col>
            <Col lg={12} className='d-flex '>

                <FormGroup className='w-100 mx-1'>
                    <Label htmlFor='budget'>Status</Label>
                    <Input
                    name='budget'
                    id='budget'
                    placeholder='Status'
                    type='number'
                    className='form-control'
                    />
                </FormGroup>
                <FormGroup className='w-100 mx-1'>
                    <Label htmlFor='budget'>Open Positions</Label>
                    <Input
                    name='budget'
                    id='budget'
                    placeholder='Enter number of positions'
                    type='number'
                    min={0}
                    className='form-control'
                    />
                </FormGroup>
            </Col>
            </Col> */}
          <Col lg={12} className="pb-2">
            <Button
              color="primary"
              type="submit"
              onClick={handlegenerate}
              hidden={enablesubmit}
              data-toggle="tooltip"
              data-placement="top"
              title="Click to generate summary"
              disabled={disableGenerate}
            >
              Generate
              <i className="fas fa-magic ms-1" />
            </Button>
          </Col>
          <Col lg={12}>
            <Button
              color="primary"
              type="submit"
              hidden={!enablesubmit}
              disabled={disabled}
              onClick={handlesubmit}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
      <ToastContainer />
    </>
  );
}

export default ClientPostJobForm;

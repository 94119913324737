import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

const JobsBadge = ({ status }) => {
  const getColor = (status) => {
    switch (status) {
      case "active":
        return "success";
      case "inactive":
        return "danger";
      default:
        return "light";
    }
  };

  return (
    <Badge color={getColor(status)} className="rounded-pill px-3 p-1">
      {status}
    </Badge>
  );
};

JobsBadge.propTypes = {
  status: PropTypes.oneOf(["active", "inactive"]).isRequired,
};

export default JobsBadge;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
} from "reactstrap";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import { lineStyle, dropdownOptions, columns, jobs } from "../data/Data";
import DropDownHeader from "../customComponents/DropDownHeader";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../customComponents/reactTable/CustomTable";
import { Link } from "react-router-dom";

import dayjs from "dayjs";
import AdvanceFilter from "../customComponents/advance filter/AdvanceFilter";
import CanvasCandidate from "../customComponents/offcanvas/CanvasCandidate";
import { getAllCandidates, getClientDashboardCount } from "./ClientSlice";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import Preloader from "../components/Common/Preloader";
import { log_debug } from "../apiHelpers/logger";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar";
import CandidateStatusBadge from "../customComponents/utilities/CandidateStatusBadge";
import StatusBadge from "../customComponents/utilities/StatusBadge";
import moment from "moment";

function AllCandidates() {
  const dispatch = useDispatch();
  const clientstore = useSelector((state) => state.clientSlice);
  const [allCandidates, setallCandidates] = useState([]);
  const [CandidateData, setCandidateData] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [RecordCount, setRecordCount] = useState(0);
  const [Location, setLocation] = useState([]);
  const [JobRole, setJobRole] = useState([]);
  const [filtervalue, setFiltervalue] = useState("");
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD",
    ),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  useEffect(() => {
    dispatch(
      getClientDashboardCount({
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }),
    );
  }, []);

  const handleFilterChange = (option) => {
    if (option && option.location) {
      const locationLabels = option.location.map((location) => location.label);
      setLocation(locationLabels);
    }

    if (option && option.jobRole) {
      const jobRoleLabels = option.jobRole.map((jobRole) => jobRole.label);
      setJobRole(jobRoleLabels);
    }
  };
  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  const yourDynamicHeaderData = {
    title: "Job Title",
    location: "Location",
    skills: "Required skills",
    status: "Status",
  };

  const candidatedetails = (data) => {
    setCandidateData(data);
    setOffcanvasOpen(true);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidatedetails(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar fullName={row.values.full_name.charAt(0)} />
            <span style={{ marginTop: "5px" }}> {row.values.full_name}</span>
          </div>
        </Link>
      ),
    },
    {
      Header: "Prefered Location",
      accessor: "prefered_locations",
      Cell: ({ value }) => value.join(", "),
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: (props) =>
      //   const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
      //   return <span>{tidyDate}</span>;
      // },
    },
    {
      Header: "Created on",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      // Cell: ({ row }) => (
      //   <Badge
      //     color={
      //       row.values.status === "contracted"
      //         ? "success text-white rounded-pill"
      //         : "warning text-white rounded-pill"
      //     }
      //   >
      //     {row.values.status}
      //   </Badge>
      // ),
      Cell: ({ value }) => <StatusBadge status={value} />,
    },
    // {
    //   Header: "Actions",
    //   accessor: "",
    // },
    // {
    //   Header: 'Discriptions',
    //   accessor: 'description',
    // },
    // Add more columns as needed
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        getAllCandidates({
          skills: JobRole ? JobRole : "",
          locations: Location ? Location : "",
          offset: currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);
    return () => clearTimeout(timer);
  }, [currentPage, Location, JobRole, filtervalue]);

  // useEffect(() => {
  //   dispatch(
  //     getAllCandidates({
  //       limit: 100,
  //       offset: currentPage ? currentPage : 0,
  //     }),
  //   );
  // }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (clientstore.status === "idle") {
      if (clientstore.data.allcandidates?.success === true) {
        setallCandidates(clientstore.data.allcandidates?.data?.individuals);
        setRecordCount(clientstore.data.allcandidates?.data?.totalCount);
        log_debug("all candidates", allCandidates);
      }
      setisLoading(false);
    } else if (clientstore.status === "loading") {
      setisLoading(true);
    }
  }, [clientstore.data.allcandidates]);

  const handleValueChange = (newValue) => {
    setisLoading(true);
    setFiltervalue(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Preloader />}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Home"
            breadcrumbItem="All Candidates"
            Name="All Candidates"
          />
          {isLoading && <Preloader status={true} />}
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <Card
                    className=" pt-3"
                  > */}
                  <CardBody>
                    <Row>
                      <AdvanceFilter onFilterChange={handleFilterChange} />
                      <div className="col-12 col-lg-6 col-md-6">
                        <Col md={12}>
                          <CardTitle className="text-end fs-6 pt-3">
                            Total Candidates : {RecordCount}
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                  {/* </Card> */}
                </div>
                <div>
                  <Card className="">
                    <CardBody>
                      <CustomTable
                        tableDatas={allCandidates}
                        headerData={yourDynamicHeaderData}
                        columns={columns}
                        data={allCandidates}
                        isLoading={isLoading}
                        hideCheckbox={true}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        ShowPagination={
                          allCandidates && allCandidates.length > 10
                            ? true
                            : false
                        }
                        totalCount={RecordCount}
                        onChange={handleValueChange}
                        globalsearch={true}
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <CanvasCandidate
          usertype="client"
          menuOption="allcandidates"
          width={60}
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={CandidateData}
        />
      )}
    </React.Fragment>
  );
}

export default AllCandidates;

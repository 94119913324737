import React, { useState } from "react";
import {
  Card,
  Container,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

//import images
// import { avatar1 } from "../assets/images";
// import { avatar2 } from "../assets/images";
// import { avatar3 } from "../assets/images";
import { avatar1 } from "../../assets/images";
import CanvasEditDetail from "../offcanvas/CanvasEditDetail";

import classnames from "classnames";
import { log_debug } from "../../apiHelpers/logger";

const ProfileDetail = ({ profileData }) => {
  log_debug("profile details", profileData.website);
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, toggleTab] = useState("1");
  const website = profileData && profileData.website;
  const getInitials = (fullName) => {
    return fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  };

  const handleContract = (row) => {
    setIsOpen(true);
  };

  const closeOffcanvas = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={9} lg={8}>
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div className="flex-shrink-0">
                          <div className="avatar-xm mx-auto mb-0">
                            {profileData.company_name != null || "" ? (
                              <span
                                className="avatar-title bg-primary text-white font-size-22 display-3 m-0 rounded-circle"
                                style={{ width: "55px", height: "55px" }}
                              >
                                {getInitials(profileData.company_name)}
                              </span>
                            ) : (
                              <span className="avatar-title bg-light-subtle text-light display-4 m-0 rounded-circle">
                                <i className="bx bxs-user-circle"></i>{" "}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              {profileData.company_name}
                            </h5>
                            {/* <p className="text-muted font-size-13">
                              {profileData.email_id}
                            </p> */}
                            <div>
                              {/* <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                  {profileData?.email || 'phyllisgatlin@minia.com'} */}
                              {
                                <>
                                  {" "}
                                  <i className="mdi mdi-email me-1 text-primary align-middle" />{" "}
                                  {profileData?.email_id || "-"}
                                </>
                              }
                            </div>

                            {
                              <div className=" flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                <div>
                                  <i className="dripicons-location me-1 text-primary align-middle"></i>
                                  {profileData.address.address_line_1},{" "}
                                  {profileData.address.address_line_2}
                                </div>
                                <div>
                                  <i className="me-1 text-primary align-middle"></i>
                                  {profileData.address.city},{" "}
                                  {profileData.address.country},{" "}
                                  {profileData.address.zip}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-outline-primary p-1 ps-2 pe-2 rounded"
                            onClick={() => {
                              handleContract();
                            }}
                          >
                            <i className="fas fa-edit me-1"></i>
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames(
                          {
                            active: activeTab === "1",
                          },
                          "px-3",
                        )}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        Overview
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardBody>

                <TabContent activeTab={activeTab} className="p-3">
                  <TabPane tabId="1">
                    <form>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label htmlFor="progresspill-firstname-input">
                              {" "}
                              Contact Person
                            </label>

                            <p className="text-muted mb-0">
                              {" "}
                              {profileData.contact_person}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label htmlFor="progresspill-email-input">
                              Contact Number
                            </label>

                            <p className="text-muted mb-0">
                              {profileData.contact_number}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-3">
                          <label htmlFor="progresspill-address-input">
                            Summary
                          </label>

                          <p className="text-muted mb-0">
                            {profileData.summary}
                          </p>
                        </div>
                      </div>
                    </form>
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
            <Col xl={3} lg={4}>
              <Card>
                <CardBody>
                  <h5 className="mb-3 card-title">Portfolio</h5>

                  <div>
                    <ul className="list-unstyled mb-0">
                      <li>
                        <Link
                          to={`//${website}`}
                          className="py-2 d-block text-muted"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="mdi mdi-web text-primary me-1"></i>{" "}
                          Website
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={profileData.linkedIn}
                          className="py-2 d-block text-muted"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="mdi mdi-linkedin text-primary me-1"></i>{" "}
                          LinkedIn
                        </Link>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isOpen && (
          <CanvasEditDetail
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            onClose={closeOffcanvas}
            data={profileData}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ProfileDetail;

import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import classnames from "classnames";
import CustomTable from "../customComponents/reactTable/CustomTable";
import { useDispatch } from "react-redux";
import { getPartnerTimesheets } from "./PartnerSlice";
import { log_debug } from "../apiHelpers/logger";
import Preloader from "../components/Common/Preloader";
import { Link } from "react-router-dom";
import JobsBadge from "../customComponents/utilities/JobsBadge";
import StatusBadge from "../customComponents/utilities/StatusBadge";
import {
  dateyyyymmdd_tohtml,
  getDatesOfWeek,
  getFirstAndLastDayOfWeek,
} from "../helpers/date";
import TimesheetCanvas from "../customComponents/offcanvas/EditTimesheetCanvas";

function PartnerTimesheets() {
  const breadcrumbValues = ["Timesheets"];

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [timesheetdata, setTimesheetData] = useState([]);
  const [selectedTimesheet, setSelectedTimesheet] = useState([]);
  const [Firstday, setFirstday] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const closeOffcanvas = () => {
    setIsOpen(false);
  };

  const handleRejectedTimesheet = (data) => {
    const weekdetails = getDatesOfWeek(data.week, data.year);
    const { firstDay, lastDay } = getFirstAndLastDayOfWeek(weekdetails);
    log_debug("firstday", firstDay);
    setFirstday(firstDay);
    //setLastday(lastDay);
    setSelectedTimesheet(data);
    setIsOpen(true);
  };

  const columns = [
    // {
    //   Header: 'ID',
    //   accessor: 'guid',
    // },
    {
      Header: "Timesheet Id",
      accessor: "guid",
      Cell: ({ row }) => (
        <Link to={"#"} onClick={() => handleRejectedTimesheet(row.original)}>
          {row.original?.guid?.split("-")[0].toUpperCase()}-
          {row.original?.guid?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    // {
    //   Header: "Contract Id",
    //   accessor: "partnerContract",
    //   Cell: ({ row }) => (
    //     <Link
    //       to={"#"}
    //       // onClick={() => {
    //       //   handleCanvasOffer(row.original);
    //       // }}
    //     >
    //       {row.original?.partnerContract?.split("-")[0].toUpperCase()}-
    //       {row.original?.partnerContract?.split("-")[1].toUpperCase()}
    //     </Link>
    //   ),
    // },
    {
      Header: "Total Hours",
      accessor: "totalHours",
    },
    {
      Header: "Submitted Date",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dateyyyymmdd_tohtml(props.value);
        return <span>{tidyDate}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <StatusBadge status={value} />,
    },

    // Add more columns as needed
  ];

  useEffect(() => {
    setIsLoading(true);
    dispatch(getPartnerTimesheets()).then((response) => {
      try {
        if (response.payload.success === true) {
          setIsLoading(false);
          const data = response.payload?.data?.timesheets;
          setTimesheetData(data);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        log_debug("error", error);
      }
    });
  }, []);

  return (
    <React.Fragment>
      {isLoading && <Preloader />}
      <div className="page-content">
        <Container fluid>
          <CustomBreadcrumb values={breadcrumbValues} />
          <div className="col-lg-12">
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <div>
                      <Card>
                        <CardBody>
                          <CustomTable
                            //totalCount={setAllmatchcount && setAllmatchcount}
                            columns={columns}
                            data={timesheetdata}
                            hideCheckbox={true}
                            // jobStatus={true}
                            isLoading={isLoading}
                            totalCount={0}
                            currentPage={0}
                            //currentPage={}
                            //onPageChange={handlePageChange}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Container>
      </div>
      {isOpen && (
        <TimesheetCanvas
          userType={"partner_admin"}
          width={70}
          placement="end"
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          onclose={closeOffcanvas}
          data={selectedTimesheet}
          monday={Firstday}
        />
      )}
    </React.Fragment>
  );
}

export default PartnerTimesheets;
